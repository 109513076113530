import React from 'react';
import styles from './css/index.module.css';
import { Input } from '@fluentui/react-components';
import { Search20Regular } from '@fluentui/react-icons';
import { GetCurrentProjectData, PatchProjectData, selectProject } from '../../redux/projectDataSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { useNavigate } from 'react-router';
const AddLinkedinProfiles = ({ setSelectedView, createdProjectData }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { projectDetailButtonLoading } = useSelector(state => state.projectData);
    const [formData, setFormData] = React.useState({
        builtwith: '',
        securitytrails: '',
        similarweb: '',
        gtmetrix: '',
    });

    const handleChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleSubmit = async () => {
        let response = await dispatch(
            PatchProjectData({
                data: formData,
                id: createdProjectData?.id,
            }),
        );
        if (response?.type === 'projectData/patchProjectData/fulfilled') {
            dispatch(selectProject(response.payload?.data));
            navigate('/dataroom');
        }
    };
    const handleSkip = () => {
        dispatch(GetCurrentProjectData());
        navigate('/dataroom');
    };
    const handleSearchOnWeb = platform => {
        let withoutSpecialChracterString = createdProjectData?.company_name.replace(/[^a-zA-Z0-9\s]/g, '');
        const searchQuery = (withoutSpecialChracterString + ' ' + platform).replace(' ', '+');
        window.open(`https://www.google.com/search?q=${searchQuery}`, '_blank');
    };

    return (
        <div className={styles.componentCard}>
            <h5 className={styles.componentTitle + ' mb-0'}>Add technology details</h5>
            <p className={styles.subHeading}>or find on the web</p>

            <div className="mb-4">
                <div className="d-flex align-items-center justify-content-between">
                    <p className={styles.fieldLabel}>GTMetrix link</p>
                    <Search20Regular onClick={() => handleSearchOnWeb('GTMetrix link')} role="button" color="var(--Brand-Secondary)" />
                </div>
                <Input className={styles.inputField} value={formData?.gtmetrix} onChange={handleChange} name="gtmetrix" />
            </div>
            <div className="mb-4">
                <div className="d-flex align-items-center justify-content-between">
                    <p className={styles.fieldLabel}>BuiltWith link</p>
                    <Search20Regular onClick={() => handleSearchOnWeb('BuiltWith link')} role="button" color="var(--Brand-Secondary)" />
                </div>
                <Input className={styles.inputField} value={formData?.builtwith} onChange={handleChange} name="builtwith" />
            </div>
            <div className="mb-4">
                <div className="d-flex align-items-center justify-content-between">
                    <p className={styles.fieldLabel}>Security Trails link</p>
                    <Search20Regular onClick={() => handleSearchOnWeb('Security Trails link')} role="button" color="var(--Brand-Secondary)" />
                </div>
                <Input className={styles.inputField} value={formData?.securitytrails} onChange={handleChange} name="securitytrails" />
            </div>
            <div className="mb-5">
                <div className="d-flex align-items-center justify-content-between">
                    <p className={styles.fieldLabel}>SimilarWeb link</p>
                    <Search20Regular onClick={() => handleSearchOnWeb('SimilarWeb link')} role="button" color="var(--Brand-Secondary)" />
                </div>
                <Input className={styles.inputField} value={formData?.similarweb} onChange={handleChange} name="similarweb" />
            </div>
            <div className="d-flex align-items-center justify-content-between">
                <button onClick={handleSubmit} className={styles.submitButton}>
                    Done {projectDetailButtonLoading && <AiOutlineLoading3Quarters className="ms-2 rotateLoader" />}
                </button>
                <span onClick={handleSkip} className={styles.linkBtn}>
                    Skip
                </span>
            </div>
        </div>
    );
};

export default AddLinkedinProfiles;
