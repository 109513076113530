import Cookies from 'js-cookie';
import store from '../../redux/store';
class TokenService {
    getLocalRefreshToken() {
        const refresh = Cookies.get('refresh');
        return refresh;
    }

    getLocalAccessToken() {
        const user = JSON.parse(localStorage.getItem('user'));
        return user?.access;
    }

    updateLocalAccessToken(token) {
        let user = JSON.parse(localStorage.getItem('user'));
        user.access = token;

        localStorage.setItem('user', JSON.stringify(user));
    }

    getUser() {
        return JSON.parse(localStorage.getItem('user'));
    }

    setUser(user) {
        Cookies.set('refresh', user.refresh);

        // localStorage.setItem('user', JSON.stringify(user.access))
    }

    removeUser() {
        localStorage.clear();
    }
}

export default new TokenService();
