import Layout from '../components/Layout';
import TasksInterface from '../components/TasksInterface';
import Pageheader from '../utils/headerHelment';

const TasksInterfaceView = () => {
    return (
        <Layout container={true}>
            <Pageheader title="LENS - Tasks Interface" />
            <TasksInterface />
        </Layout>
    );
};

export default TasksInterfaceView;
