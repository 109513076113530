import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../apiServices/axios';
import { DOC_PROJECT, SAS_API, PROJECT_ADD_INVESTOR, NDA_DOC, COMPANY_SEARCH, COMPANY_DETAIL } from '../apiServices/api_routes';
import { capitalizeFirstLetter, isObject } from '../utils/commonUtils';
import { toast } from 'react-toastify';
import store from './store';

export const GetProjectData = createAsyncThunk('projectData/getProjectData', async () => {
    const data = await axiosInstance.get(DOC_PROJECT);
    return data;
});
export const PostProjectData = createAsyncThunk('projectData/postProjectData', async (body, { rejectWithValue }) => {
    try {
        const data = await axiosInstance.post(DOC_PROJECT, body.body);
        body.callback();
        return data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const PatchProjectData = createAsyncThunk('projectData/patchProjectData', async (body, { rejectWithValue }) => {
    try {
        const data = await axiosInstance.patch(DOC_PROJECT + body?.id + '/', body?.data);
        return data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const GetCurrentProjectData = createAsyncThunk('projectData/getCurrentProjectData', async () => {
    const data = await axiosInstance.get(DOC_PROJECT + localStorage['project_id']);
    return data;
});
export const DeleteProjectData = createAsyncThunk('DELETE_DOC_PROJECT', async ({ id }) => {
    const data = await axiosInstance.delete(DOC_PROJECT + id);
    return data;
});

export const FetchLogo = createAsyncThunk('projectData/Logo', async (arg, { getState }) => {
    const logo = await getState()?.projectData?.selectedProject?.logo;
    if (logo) {
        return await axiosInstance.post(SAS_API, null, {
            params: { ProjectImg: logo },
        });
    }
});
export const AddNda = createAsyncThunk('POST_NDA_DOC', async (body, { rejectWithValue }) => {
    try {
        const data = await axiosInstance.post(NDA_DOC + localStorage['project_id'] + '/', body);
        return data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const DeleteNda = createAsyncThunk('DELETE_NDA_DOC', async (id, { rejectWithValue }) => {
    try {
        const data = await axiosInstance.delete(NDA_DOC + localStorage['project_id'] + '/' + id + '/');
        return data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const GetCompanyId = createAsyncThunk('GET_COMPANY_SEARCH', async (website, { rejectWithValue }) => {
    try {
        const data = await axiosInstance.get(COMPANY_SEARCH + website);
        return data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const GetCompanyDetail = createAsyncThunk('GET_COMPANY_DETAIL', async (id, { rejectWithValue }) => {
    try {
        const data = await axiosInstance.get(COMPANY_DETAIL + id);
        return data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const checkStatus = selectedData => {
    if (isObject(selectedData) && Object?.keys(selectedData)?.length > 0) {
        if (
            !selectedData?.business_type ||
            !selectedData?.logo ||
            !selectedData?.deadline ||
            !selectedData?.website ||
            !selectedData?.sector ||
            selectedData?.revenue == 0 ||
            selectedData?.employees == 0 ||
            !selectedData?.description
        ) {
            return 'pending';
        } else {
            return 'completed';
        }
    }
};
export const projectDataSlice = createSlice({
    name: 'projectDataSlice',
    initialState: {
        loading: true,
        logo: '',
        data: [],
        logoLoading: true,
        status: 'pending',
        errorMessage: '',
        selectedProject: null,
        error: false,
        projectDetailButtonLoading: false,
        ndaButtonLoading: false,
    },
    reducers: {
        selectProject: (state, { payload }) => {
            localStorage.setItem('project_id', payload.id);
            const index = state?.data?.findIndex(i => i.id == payload.id);
            const selectedData = state?.data[index];
            state.selectedProject = selectedData;
            state.status = checkStatus(selectedData);
            if (payload?.callback) {
                payload?.callback();
                // if (payload?.userType === 'Investor') {
                //     if (selectedData?.progress_check?.projectscope_pending === true && selectedData?.progress_check?.projectscope_approved === false) {
                //         payload?.callback(true);
                //     } else payload?.callback(false);
                // } else {
                //     if (selectedData?.progress_check?.projectscope_pending === false && selectedData?.progress_check?.projectscope_approved === false) {
                //         payload?.callback(true);
                //     } else payload?.callback(false);
                // }
            }
        },
        changeProjectDeal: (state, { payload }) => {
            state.data = state.data?.map(item => {
                if (item?.id === localStorage['project_id']) {
                    return {
                        ...item,
                        progress_check: {
                            ...item?.progress_check,
                            project_status: payload,
                        },
                    };
                } else return item;
            });
            state.selectedProject = {
                ...state.selectedProject,
                progress_check: {
                    ...state.selectedProject?.progress_check,
                    project_status: payload,
                },
            };
        },

        updateProject: (state, { payload }) => {
            const i = state?.data?.findIndex(_element => _element.id === payload?.id);
            if (i > -1) {
                const UpdatedData = state?.data.map(obj => {
                    if (obj?.id === payload?.id) {
                        return { ...obj, ...payload };
                    }
                    return obj;
                });
                UpdatedData?.sort((a, b) => (a?.project_name > b?.project_name ? 1 : b?.project_name > a?.project_name ? -1 : 0));
                return {
                    ...state,
                    status: checkStatus(payload),
                    selectedProject: payload,
                    data: [...UpdatedData],
                };
            } else {
                return {
                    ...state,
                    selectedProject: payload,
                    status: checkStatus(payload),
                    data: [...state.data, payload],
                };
            }
        },
        setProjectImg: (state, { payload }) => {
            return { ...state, logo: payload, logoLoading: true };
        },
        resetProjectData: (state, { payload }) => {
            return {
                loading: true,
                logo: '',
                // data: [],
                logoLoading: true,
                status: 'pending',
                errorMessage: '',
                selectedProject: null,
                error: false,
                projectDetailButtonLoading: false,
                ndaButtonLoading: false,
                data: state?.data,
            };
        },
    },
    extraReducers: {
        [GetProjectData.fulfilled]: (state, { payload }) => {
            state.data = payload?.data;
            if (payload?.data?.length > 0) {
                const sortedata = (state.data = payload?.data?.sort((a, b) => (a?.project_name > b?.project_name ? 1 : b?.project_name > a?.project_name ? -1 : 0)));
                state.data = sortedata;
            }
            state.error = false;
            state.errorMessage = '';
            state.loading = false;
        },
        [GetProjectData.rejected]: (state, { error }) => {
            state.error = true;
            state.errorMessage = error?.message;
            state.data = [];
            state.loading = false;
        },
        [FetchLogo.fulfilled]: (state, { payload }) => {
            state.logoLoading = false;
            state.logo = payload?.data?.image;
        },
        [FetchLogo.rejected]: (state, action) => {
            state.logoLoading = false;
            state.isError = true;
            state.logo = '';
            state.errorMessage = action;
        },
        //DeleteProjectData
        [DeleteProjectData.fulfilled]: (state, action) => {
            toast.success('Deleted Successfully');
            state.data = state.data?.filter(item => {
                if (item?.id !== action?.meta?.arg?.id) {
                    return item;
                }
            });
        },
        //GetCurrentProjectData
        [GetCurrentProjectData.fulfilled]: (state, action) => {
            state.selectedProject = action?.payload?.data;
        },
        //PatchProjectData
        [PatchProjectData.pending]: (state, action) => {
            state.projectDetailButtonLoading = true;
        },
        [PatchProjectData.fulfilled]: (state, { meta, payload }) => {
            console.log(meta?.arg);
            if ('archive' in meta?.arg?.data) {
                toast.success(meta?.arg?.data?.archive ? 'Project Archived' : 'Project Unarchived');
            } else if ('soft_delete' in meta?.arg?.data) {
                toast.success('Project Deleted');
            } else if (meta?.arg?.governance_index_state || meta?.arg?.maturity_index_state || meta?.arg?.security_index_state) {
                //do nothing
            } else toast.success('Project Saved');
            if (state.selectedProject) {
                state.selectedProject = payload?.data;
            }
            state.projectDetailButtonLoading = false;
            state.data = state.data?.map(item => {
                if (item?.id === payload?.data?.id) return payload?.data;
                else return item;
            });
        },
        [PatchProjectData.rejected]: (state, { payload }) => {
            Object.keys(payload).map(key => {
                payload[key].map(msg => toast.error(key + ' : ' + msg));
            });
            state.projectDetailButtonLoading = false;
        },
        // PostProjectData
        [PostProjectData.pending]: (state, action) => {
            state.projectDetailButtonLoading = true;
        },
        [PostProjectData.fulfilled]: (state, { payload }) => {
            toast.success('Project Created');
            localStorage.setItem('project_id', payload?.data?.id);
            state.projectDetailButtonLoading = false;
            // state.selectedProject = payload?.data;
            state.data.push(payload?.data);
        },
        [PostProjectData.rejected]: (state, { payload }) => {
            Object.keys(payload).map(key => {
                payload[key].map(msg => toast.error(capitalizeFirstLetter(key) + ' : ' + msg));
            });
            state.projectDetailButtonLoading = false;
        },
        //AddNda
        [AddNda.pending]: (state, action) => {
            state.status = 'loading';
            state.ndaButtonLoading = true;
        },
        [AddNda.fulfilled]: (state, action) => {
            toast.success('NDA Added');
            state.status = 'success';
            state.ndaButtonLoading = false;
        },
        [AddNda.rejected]: (state, { payload }) => {
            state.ndaButtonLoading = false;
            Object.keys(payload).map(key => {
                payload[key].map(msg => toast.error(key + ' : ' + msg));
            });
            state.status = 'rejected';
        },
        //DeleteNda
        [DeleteNda.pending]: (state, action) => {
            state.status = 'loading';
            state.ndaButtonLoading = true;
        },
        [DeleteNda.fulfilled]: (state, action) => {
            toast.success('NDA Deleted');
            state.status = 'success';
            state.ndaButtonLoading = false;
        },
        [DeleteNda.rejected]: (state, { payload }) => {
            state.ndaButtonLoading = false;
            try {
                if (payload) {
                    Object.keys(payload).map(key => {
                        payload[key].map(msg => toast.error(key + ' : ' + msg));
                    });
                }
            } catch (error) {
                console.log(error);
            }

            state.status = 'rejected';
        },
    },
});

export const { selectProject, updateProject, setProjectImg, changeProjectDeal, resetProjectData } = projectDataSlice.actions;
export default projectDataSlice.reducer;
