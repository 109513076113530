import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NDAPage from '../Views/NDA';
import NoProjectsPage from '../Views/NoProjects';
import OverviewPage from '../Views/Overview';
import Team from '../Views/Team';
import Messages from '../Views/Messages';
import LoginPage from '../Views/Login';
import RegisterPage from '../Views/Register';
import UploadNdaPage from '../Views/UploadNda';
import NDASuccessPage from '../Views/NDASuccess';
import ProtectedRoute from '../utils/ProtectedRoutes';
import DataroomPage from '../Views/Dataroom';
import ForgotPasswordView from '../Views/ForgotPassword';
import ConfirmMailView from '../Views/ConfirmMail';
import ResetPasswordView from '../Views/ResetPassword';
import ObservationsView from '../Views/Observations';
import Projects from '../Views/Projects';
import SecureAccountView from '../Views/SecureAccount';
import AuthenticateView from '../Views/Authenticate';
import IRLView from '../Views/IRL';
import TasksInterfaceView from '../Views/TasksInterface';
import RequestInviteView from '../Views/RequestInvite';
import PermissionsView from '../Views/Permissions';
import ProjectDetailspage from '../Views/ProjectDetails';
import SwotAnalysisView from '../Views/SwotAnalysis';
import QuestionBankView from '../Views/QuestionBank';
import React from 'react';
import WizardPage from '../Views/Wizard';
import MaturityIndexPage from '../Views/MaturityIndex';
import SecurityIndexPage from '../Views/SecurityIndex';
import TeamAnalyserPage from '../Views/TeamAnalyser';
import SecurityGovernancePage from '../Views/SecurityGovernance';
import EditProjectPage from '../Views/EditProject';
import SoftwareInventoryPage from '../Views/SoftwareInventory';
// import { toast } from 'react-toastify';

const Routers = () => {
    // const [isLowBandwidth, setIsLowBandwidth] = React.useState(false);
    // React.useEffect(() => {
    //     const checkNetworkSpeed = () => {
    //         const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    //         if (connection) {
    //             const downlink = connection.downlink;
    //             console.log({ connection });
    //             if (downlink < 1.5) {
    //                 setIsLowBandwidth(true);
    //             } else {
    //                 setIsLowBandwidth(false);
    //             }
    //         } else {
    //             console.log('Connection API not available in this browser.');
    //         }
    //     };
    //     const intervalId = setInterval(checkNetworkSpeed, 10000);
    //     return () => {
    //         clearInterval(intervalId);
    //     };
    // }, []);
    // React.useEffect(() => {
    //     if (isLowBandwidth)
    //         toast.warn('Internet speed is slow', {
    //             position: 'top-right',
    //             autoClose: false,
    //         });
    // }, [isLowBandwidth]);

    // React.useEffect(()=>{})
    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<LoginPage />} />
                <Route exact path="/*" element={<LoginPage />} />
                <Route path="/register/:key/:project?" element={<RegisterPage />} />
                <Route exact path="/forgot-password" element={<ForgotPasswordView />} />
                <Route exact path="/confirm-mail" element={<ConfirmMailView />} />
                <Route exact path="/secure" element={<SecureAccountView />} />
                <Route exact path="/authenticate" element={<AuthenticateView />} />
                <Route exact path="/request-invite" element={<RequestInviteView />} />
                <Route exact path="/reset-password/:uid/:token" element={<ResetPasswordView />} />
                <Route element={<ProtectedRoute allowedRoles={['Investor', 'Management']} />}>{/* <Route path="/overview" element={<OverviewPage />} /> */}</Route>
                <Route element={<ProtectedRoute allowedRoles={['Consultant']} />}>{/* <Route path="/observations" element={<ObservationsView />} /> */}</Route>
                <Route element={<ProtectedRoute allowedRoles={['Investor', 'Consultant']} />}>
                    <Route path="/noprojects" element={<NoProjectsPage />} />
                    <Route path="/NDASuccess" element={<NDASuccessPage />} />
                </Route>
                <Route element={<ProtectedRoute allowedRoles={['Investor', 'Management', 'Consultant', '']} />}>
                    <Route path="/overview" element={<OverviewPage />} />
                    <Route path="/project" element={<Projects />} />
                    <Route path="/irl" element={<IRLView />} />
                    <Route path="/observations" element={<ObservationsView />} />
                    <Route path="/swot" element={<SwotAnalysisView />} />
                    <Route path="/question-bank" element={<QuestionBankView />} />
                    <Route path="/dataroom" element={<DataroomPage />} />
                    <Route path="/wizard" element={<WizardPage />} />
                    <Route path="/security-governance" element={<SecurityGovernancePage />} />
                    <Route path="/maturity-index" element={<MaturityIndexPage />} />
                    <Route path="/security-index" element={<SecurityIndexPage />} />
                    <Route path="/team-analyser" element={<TeamAnalyserPage />} />
                    <Route path="/messages" element={<Messages />} />
                    <Route path="/uploadnda" element={<UploadNdaPage />} />
                    <Route path="/AddProjectDetails" element={<ProjectDetailspage />} />
                    <Route path="/EditProject" element={<EditProjectPage />} />
                    <Route path="/nda" element={<NDAPage />} />
                    <Route path="/tasks" element={<TasksInterfaceView />} />
                    <Route path="/team/:type" element={<Team />} />
                    <Route path="/software-inventory" element={<SoftwareInventoryPage />} />
                    {/* <Route path="/tc" element={<TermsConditionsView />} /> */}
                    <Route path="/permissions/:type/:id" element={<PermissionsView />} />
                </Route>
            </Routes>
        </Router>
    );
};

export default Routers;
