import React from 'react';
import { useSelector } from 'react-redux';
import MessageList from './MessageList';
import MessageDetail from './MessageDetail';

const Messages = () => {
    const { selectedThreadId, inboxMessages } = useSelector(state => state?.messages);
    const [isNewMessage, setIsNewMessage] = React.useState(false);

    React.useEffect(() => {
        if (inboxMessages?.length === 0) setIsNewMessage(true);
    }, [inboxMessages]);
    return (
        <div>
            <div style={{ margin: 0 }} className="row">
                <div className="col-sm-12 col-md-12  col-lg-4">
                    <MessageList setIsNewMessage={setIsNewMessage} />
                </div>
                <div className="col-sm-12 col-md-12  col-lg-8 ">
                    <MessageDetail isNewMessage={isNewMessage} setIsNewMessage={setIsNewMessage} threadId={selectedThreadId} />
                </div>
            </div>
        </div>
    );
};

export default Messages;
