import React from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { API_BASE_URL, FORGOT_PASSWORD } from '../../apiServices/api_routes';
import styles from './css/ForgotPassword.module.css';
import Logo from '../../assets/longLogoBright.png';
import { SpinnerIos16Regular } from '@fluentui/react-icons';

const ForgotPassword = () => {
    const [email, setEmail] = React.useState('');
    const [btnLoading, setBtnLoading] = React.useState(false);
    var promiseToast = null;
    const [response, setResponse] = React.useState({
        message:
            'An email with instructions on how to reset your password has been sent to your registered email address. Please check your inbox and follow the provided steps to regain access to your account.',
        visible: false,
    });
    const handleSubmit = async e => {
        e.preventDefault();
        setBtnLoading(true);
        if (email) {
            promiseToast = toast.loading('Sending ...', {
                closeButton: true,
            });
            try {
                await axios.post(`${API_BASE_URL}${FORGOT_PASSWORD}`, { email: email });
                setResponse({ ...response, visible: true });
                toast.update(promiseToast, {
                    render: 'Email sent',
                    type: 'success',
                    isLoading: false,
                    autoClose: 1000,
                });
            } catch (error) {
                const resp = error?.response?.data;
                if (Array.isArray(resp)) {
                    toast.update(promiseToast, {
                        render: resp[0],
                        type: 'error',
                        isLoading: false,
                        autoClose: 1000,
                    });
                } else {
                    Object.keys(resp).map(key =>
                        toast.update(promiseToast, {
                            render: resp[key][0],
                            type: 'error',
                            isLoading: false,
                            autoClose: 1000,
                        }),
                    );
                }
            }
        } else {
            toast.warn('Email Required');
        }
        setBtnLoading(false);
    };
    return (
        <div className={styles.body}>
            <div className={styles.dotedBg}>
                <div className={styles.formCard}>
                    <div className={styles.formHeader}>
                        <img src={Logo} width={73} alt="lens-logo" />
                    </div>
                    {response?.visible ? (
                        <div className={styles.formBody}>
                            <div className={styles.responseBody}>
                                <h5>Password Reset Email Sent</h5>
                                <p>{response?.message}</p>
                            </div>
                        </div>
                    ) : (
                        <form onSubmit={handleSubmit} className={styles.formBody}>
                            <h5 className={styles.formLabel}>Forgot Password</h5>
                            <p className={styles.formSubtitle}>Enter your email to reset your password. Instructions will be sent to your inbox.</p>
                            <div>
                                <div>
                                    <p className={styles.fieldLabel}>Registered email address</p>
                                    <input value={email} required name="email" onChange={e => setEmail(e.target.value)} className={'form-control ' + styles.inputField} />
                                </div>
                                <button disabled={btnLoading || !email} type="submit" className={styles.submitBtn}>
                                    Submit
                                    {btnLoading && <SpinnerIos16Regular className="rotateLoader ms-1" />}
                                </button>
                            </div>
                        </form>
                    )}
                </div>
            </div>
            <div className={styles.footer}>
                <a href="https://www.iubenda.com/terms-and-conditions/15531073" target="_blank" rel="noreferrer">
                    Terms of Use
                </a>
                <a href="https://www.iubenda.com/privacy-policy/15531073/legal?ifr=true&height=650&newmarkup=yes&an=no" target="_blank" rel="noreferrer">
                    Privacy & Cookies
                </a>
            </div>
        </div>
    );
};

export default ForgotPassword;
