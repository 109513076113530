import React, { useEffect, useRef, useMemo } from 'react';
import styles from './css/index.module.css';

import { Dismiss16Regular } from '@fluentui/react-icons';
import { PostIRLDocuments } from '../../redux/documentsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineClose } from 'react-icons/ai';
import { validExtensions } from '../../utils/misc';
import { toast } from 'react-toastify';
const AddDocumentModal = ({ setUploadDocumentSidebar, setShowSidebar, selectedFolders, workstream }) => {
    const selectedFolder = useMemo(() => {
        return selectedFolders?.length > 0 ? selectedFolders[selectedFolders.length - 1] : {};
    }, [selectedFolders]);
    const [uploadedFiles, setUploadedFiles] = React.useState([]);
    const fileInputRef = useRef();
    const { selectedProject } = useSelector(state => state?.projectData);
    const { projectUsersAll } = useSelector(state => state?.team);
    const { user } = useSelector(state => state?.user);
    const [buttonLoading, setButtonLoading] = React.useState(false);
    const dispatch = useDispatch();
    const handleDivClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = e => {
        const selectedFiles = e.target.files;
        // Handle the selected files as needed
        setUploadedFiles(Array.from(selectedFiles));
    };
    const handleDrop = e => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        // Handle the dropped files as needed
        setUploadedFiles(Array.from(files));
    };

    const handlePaste = e => {
        const items = e.clipboardData.items;
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (item.kind === 'file') {
                const file = item.getAsFile();
                // Handle the pasted file as needed
                // console.log(file);
            }
        }
    };

    const handleAddFile = async () => {
        setButtonLoading(true);
        const filePromiseToast = toast.loading(`Uploading ${uploadedFiles?.length} documents to ${selectedFolder?.name}`, { closeButton: true });
        const formData = new FormData();
        for (const item of uploadedFiles) {
            formData.append('document', item);
        }
        formData.append('ws_folder', parseInt(selectedFolder?.id));
        formData.append('project', selectedProject?.id);
        formData.append('vdr', selectedProject?.vdr?.id);
        if (selectedProject?.vdr?.vdr_admin !== user?.id) {
            formData.append('upload_by', projectUsersAll?.find(projectUser => projectUser?.value?.user_id === user?.id)?.value?.group_member_id);
        }
        try {
            let res = await dispatch(PostIRLDocuments({ body: formData, ws_id: workstream?.id }));

            if (res?.type === 'POST_IRL_DOC/rejected') {
                console.log(res);
                if (res?.payload?.detail) {
                    toast.update(filePromiseToast, {
                        render: res?.payload?.detail,
                        type: 'error',
                        isLoading: false,
                        autoClose: 2000,
                    });
                } else {
                    toast.update(filePromiseToast, {
                        render: `Uploading  Failed`,
                        type: 'error',
                        isLoading: false,
                        autoClose: 2000,
                    });
                }
            } else {
                // If the upload is successful, update the file-specific toast
                toast.update(filePromiseToast, {
                    render: `Uploaded successfully`,
                    type: 'success',
                    isLoading: false,
                    autoClose: 2000,
                });
            }
        } catch (uploadError) {
            console.error(`Error uploading`, uploadError);
            toast.update(filePromiseToast, {
                render: `Error uploading`,
                type: 'error',
                isLoading: false, // Make sure to update the loading state in case of an error
                autoClose: 2000,
            });
        }
        handleClose();
    };
    const handleClose = () => {
        setUploadedFiles([]);
        setShowSidebar(false);
        setUploadDocumentSidebar(false);
    };
    useEffect(() => {
        const div = document.getElementById('uploadDiv');
        if (div) {
            div.addEventListener('dragover', e => e.preventDefault());
            div.addEventListener('drop', handleDrop);
            div.addEventListener('paste', handlePaste);

            return () => {
                div.removeEventListener('dragover', e => e.preventDefault());
                div.removeEventListener('drop', handleDrop);
                div.removeEventListener('paste', handlePaste);
            };
        }
    }, []);

    return (
        <div className={styles.sidebarWhite}>
            <div className="d-flex align-items-center justify-content-between mb-5">
                <h5 className={styles.sidebarTitle}>Upload Files</h5>
                <Dismiss16Regular onClick={handleClose} role="button" color="var(--Neutral-Gray-6)" />
            </div>
            {/* <div className="d-flex align-items-center">
                <span>{selectedFolder?.name}</span>
                <ChevronDown20Regular className="ms-2" />
            </div> */}
            {uploadedFiles?.length > 0 ? (
                <div className={styles.uploadItemsList}>
                    {uploadedFiles?.map(item => (
                        <div className={styles.fileListItem} key={item?.name}>
                            <span>{item?.name}</span>
                            <AiOutlineClose size={14} onClick={() => setUploadedFiles(uploadedFiles?.filter(file => file?.name !== item?.name))} />
                        </div>
                    ))}
                </div>
            ) : (
                <div id="uploadDiv" className={styles.uploadBox} onClick={handleDivClick}>
                    <span>
                        Drag & Drop Files or <br /> Click to Upload
                    </span>
                    <input multiple type="file" accept={validExtensions.join(',')} ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
                </div>
            )}

            <button disabled={buttonLoading || uploadedFiles?.length === 0} onClick={handleAddFile} className={styles.saveButton}>
                Save
            </button>
        </div>
    );
};

export default AddDocumentModal;
