import React from 'react';
import Pageheader from '../utils/headerHelment';
import EditProject from '../components/EditProject';
import Header from '../components/CommonComponents/Header';
const EditProjectPage = () => {
    return (
        <>
            <Pageheader title="LENS - Edit Project" />
            <Header />
            <EditProject />
        </>
    );
};

export default EditProjectPage;
