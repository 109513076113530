import React from 'react';
import Pageheader from '../../utils/headerHelment';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
const NoProjects = () => {
    const email = useSelector(state => state.user.user.email);

    return (
        <div>
            <Pageheader title="LENS" />
            <div className="wrapper d-flex flex-column pt-2 pb-2 pt-lg-4 bg-light full-height-cls">
                {/* Header */}
                <header className="header flex-grow-0 mb-4 mb-lg-5">
                    <div className="container-fluid max-width">
                        <div className="row justify-content-center">
                            <div className="col-lg-11">
                                <a href className="navbar-brand">
                                    <img src="/assets/logo/logo.svg" alt="" className="w-100" />
                                </a>
                            </div>
                        </div>
                    </div>
                </header>
                {/* /Header */}
                {/* Section - Content */}
                <section className="content d-flex flex-column flex-grow-1 mb-lg-5 w-100">
                    <div className="container-fluid max-width">
                        <div className="row justify-content-center">
                            <div className="col-lg-5">
                                <h2 className="mb-5">You do not have any active projects.</h2>
                                {/* Form */}
                                <h2 className="mb-5">We will inform you on {email} when a new project is assigned to you.</h2>
                                {/* /Form */}
                                <div className="row">
                                    <div className="col-6 align-self-center">
                                        <Link to="/" onClick={() => localStorage.clear()}>
                                            <strong>Exit</strong>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* /Section - Content */}
            </div>
        </div>
    );
};

export default NoProjects;
