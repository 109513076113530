import React from 'react';
import styles from './css/index.module.css';
import { Input, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, Option, Select, Textarea } from '@fluentui/react-components';
import { ChevronDown16Regular, Search20Regular } from '@fluentui/react-icons';
import { validExtensions } from '../../utils/misc';
import { PatchProjectData } from '../../redux/projectDataSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
const AddBusinessDetails = ({ setSelectedView, createdProjectData }) => {
    const dispatch = useDispatch();

    const { projectDetailButtonLoading } = useSelector(state => state.projectData);
    const [formData, setFormData] = React.useState({
        product_website: '',
        currency: '',
        revenue: '',
        companieshouse: '',
        business_type: '',
    });

    const handleChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleSubmit = async () => {
        await dispatch(
            PatchProjectData({
                data: { ...formData, revenue: Number(formData?.revenue) },
                id: createdProjectData?.id,
            }),
        );

        setSelectedView('publicProfiles');
    };
    const handleSearchOnWeb = platform => {
        let withoutSpecialChracterString = createdProjectData?.company_name.replace(/[^a-zA-Z0-9\s]/g, '');
        const searchQuery = (withoutSpecialChracterString + ' ' + platform).replace(' ', '+');
        window.open(`https://www.google.com/search?q=${searchQuery}`, '_blank');
    };
    React.useEffect(() => {
        if (createdProjectData) {
            setFormData({
                currency: createdProjectData?.currency,
                revenue: createdProjectData?.linkedin,
                companieshouse: String(createdProjectData?.companieshouse),
                business_type: createdProjectData?.business_type,
            });
        }
    }, [createdProjectData]);
    console.log(formData);
    return (
        <div className={styles.componentCard}>
            <h5 className={styles.componentTitle}>Add business details</h5>

            <div className="mb-4">
                <p className={styles.fieldLabel}>Product website (if applicable)</p>
                <Input className={styles.inputField} value={formData?.product_website} onChange={handleChange} name="product_website" placeholder="" />
            </div>
            <div className="mb-4">
                <div className="d-flex align-items-center justify-content-between">
                    <p className={styles.fieldLabel}>Companies House ID</p>
                    <Search20Regular onClick={() => handleSearchOnWeb('Companies House ID')} role="button" color="var(--Brand-Secondary)" />
                </div>
                <Input className={styles.inputField} value={formData?.companieshouse} onChange={handleChange} name="companieshouse" />
            </div>
            <div className="mb-4">
                <p className={styles.fieldLabel}>Revenue</p>
                <div className="d-flex">
                    <Menu>
                        <MenuTrigger disableButtonEnhancement>
                            <div className={styles.currencyDiv}>
                                {['£ GBP', '$ USD', '€ EUR'].find(item => item?.includes(formData?.currency))?.split(' ')[1]} <ChevronDown16Regular className="ms-1" />
                            </div>
                        </MenuTrigger>
                        <MenuPopover>
                            <MenuList>
                                <MenuItem onClick={() => setFormData({ ...formData, currency: '£' })}>£ GBP</MenuItem>
                                <MenuItem onClick={() => setFormData({ ...formData, currency: '$' })}>$ USD</MenuItem>
                                <MenuItem onClick={() => setFormData({ ...formData, currency: '€' })}>€ EUR</MenuItem>
                            </MenuList>
                        </MenuPopover>
                    </Menu>
                    <Input className={styles.inputField} value={formData?.revenue} onChange={handleChange} name="revenue" type="number" placeholder="" />
                </div>
            </div>
            <div className="mb-5 w-50">
                <p className={styles.fieldLabel}>Business Type</p>
                <Select className={styles.selectField} value={formData?.business_type} onChange={handleChange} name="business_type" placeholder="">
                    <option>Select</option>
                    <option value="B2B">B2B</option>
                    <option value="B2C">B2C</option>
                </Select>
            </div>
            <div className="d-flex align-items-center justify-content-between">
                {' '}
                <button onClick={handleSubmit} className={styles.submitButton}>
                    Confirm {projectDetailButtonLoading && <AiOutlineLoading3Quarters className="ms-2 rotateLoader" />}
                </button>
                <span onClick={() => setSelectedView('publicProfiles')} className={styles.linkBtn}>
                    Skip
                </span>
            </div>
        </div>
    );
};

export default AddBusinessDetails;
