import React from 'react';
import styles from './css/MessageDetail.module.css';
import { GetThreadReplies, SendThreadReply, GetInboxMessages, SendNewMessage } from '../../redux/messagesSlice';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { FaPaperPlane } from 'react-icons/fa';
import ThreadDetail from './ThreadDetail';
import NewMessage from './NewMessage';
import TextEditor from './TextEditor';

const MessageDetail = ({ threadId, isNewMessage, setIsNewMessage }) => {
    const dispatch = useDispatch();
    const { threadReplies } = useSelector(state => state?.messages);

    const [content, setContent] = React.useState('');
    const [selectedReceiver, setSelectedReceiver] = React.useState();
    React.useEffect(() => {
        handleFetchReplies();
    }, [threadId]);
    const handleSendMessage = async () => {
        let formData = new FormData();
        formData.append('subject', 'Message Subject HardCoded');
        formData.append('message', content);
        let lastMessage = threadReplies[threadReplies?.length - 1];
        if (isNewMessage) {
            if (selectedReceiver && selectedReceiver.length > 0) {
                let idArray = selectedReceiver?.map(item => item.value?.id);

                await dispatch(
                    SendNewMessage({
                        body: formData,
                        toUserID: `${idArray.join(', ')}`,
                    }),
                );
                handleFetchReplies();
                setContent('');
            }
        } else {
            await dispatch(
                SendThreadReply({
                    body: formData,
                    threadId,
                    toUserID: lastMessage?.sender?.user_id,
                }),
            );
            dispatch(GetThreadReplies(threadId));
            dispatch(GetInboxMessages());
            setContent('');
        }
        toast.success('Message Sent');
    };

    const handleFetchReplies = async () => {
        if (threadId) {
            dispatch(GetThreadReplies(threadId));
        }
    };

    return (
        <div className={styles.detailBody}>
            {threadId && !isNewMessage && (
                <div className="w-100 text-end mb-3 ">
                    <button onClick={() => setIsNewMessage(true)} type="button" className={`btn btn-primary ${styles.messageButton}`}>
                        New Message
                    </button>
                </div>
            )}

            {isNewMessage || !threadId ? (
                <div className={styles.threadsBody}>
                    <NewMessage selectedReceiver={selectedReceiver} setSelectedReceiver={setSelectedReceiver} />
                </div>
            ) : (
                <div className={styles.threadsBody}>
                    {threadReplies?.map(item => (
                        <ThreadDetail data={item} />
                    ))}
                </div>
            )}
            <div className={styles.editorBody}>
                <TextEditor value={content} setValue={setContent} />
                <div className="text-end">
                    <button onClick={handleSendMessage} type="button" className={`btn btn-primary  ${styles.sendButton}`}>
                        <FaPaperPlane />
                        &nbsp; Send
                    </button>
                </div>
            </div>
        </div>
    );
};

export default MessageDetail;
