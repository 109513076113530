import React from 'react';
import Project from '../components/Project';
import Pageheader from '../utils/headerHelment';
import { toast } from 'react-toastify';
import { isMobile, isTablet, isDesktop } from 'react-device-detect';
import DoubleConfirmationModal from '../components/CommonComponents/DoubleConfirmationModal/DoubleConfirmationModal';
import { setProjectImg } from '../redux/projectDataSlice';
import Cookies from 'js-cookie';
import { setDocs } from '../redux/documentsSlice';
import { logout } from '../redux/usersSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { ReactInternetSpeedMeter } from 'react-internet-meter';
const ProjectPage = () => {
    console.log({ isMobile, isTablet, isDesktop });
    const triggerWarnModalRef = React.useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const mobileDeviceWarn = localStorage?.getItem('mobileDeviceWarn');

    console.log({ mobileDeviceWarn });
    React.useEffect(() => {
        if (isMobile || isTablet) {
            if (!mobileDeviceWarn) {
                if (triggerWarnModalRef?.current) {
                    console.log(triggerWarnModalRef?.current?.click());
                }
            }
        }
    }, [triggerWarnModalRef, mobileDeviceWarn]);
    const handleLogout = () => {
        localStorage.removeItem('persist:root');
        Cookies.set('refresh', '');
        dispatch(setProjectImg(''));
        dispatch(setDocs([]));
        dispatch(logout());
        navigate('/');
    };
    return (
        <>
            <Pageheader title="LENS - Dashboard" />
            <Project />{' '}
            <DoubleConfirmationModal
                handleAction={() => localStorage.setItem('mobileDeviceWarn', true)}
                modalOneBtnL="Continue"
                modalOneBtnR="Logout"
                modalTwoBtnR="Continue"
                firstModalActionBtn={handleLogout}
                message={
                    'Please note that Lens is best experienced on a desktop browser. Using a mobile or tablet may significantly impact functionality and your user experience.'
                }>
                <span ref={triggerWarnModalRef}></span>
            </DoubleConfirmationModal>
            <ReactInternetSpeedMeter
                txtSubHeading=""
                outputType="alert"
                customClassName={null}
                txtMainHeading=""
                pingInterval={60000} // milliseconds
                thresholdUnit="megabyte" // "byte" , "kilobyte", "megabyte"
                threshold={100}
                imageUrl="https://beyond-ma.com/wp-content/uploads/2023/07/Lens_Logo.png"
                downloadSize="1781287" //bytes
                callbackFunctionOnNetworkTest={speed => {
                    if (parseFloat(speed) <= 6.5) {
                        toast.warn('Internet speed is slow, it may impact the perfomance of application', {
                            position: 'top-right',
                            autoClose: 5000,
                        });
                    }
                }}
            />
        </>
    );
};

export default ProjectPage;
