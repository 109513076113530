import styles from './css/index.module.css';
import { AiOutlineCloseCircle, AiOutlineLoading3Quarters, AiOutlinePlusCircle } from 'react-icons/ai';
import { BsCaretDownFill, BsEye, BsFillCaretRightFill, BsFillCheckCircleFill, BsFolder2 } from 'react-icons/bs';
import { RiDeleteBinLine } from 'react-icons/ri';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getExtensionIcon } from '../../utils/commonUtils';
import { useNavigate, useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { GetGroupMemberPermissions, GetGroupPermissions, UpdateGroupPermissions, UpdateUserPermissions } from '../../redux/teamSlice';
import { GetWorkstreams, GetWorkstreamsDefaults } from '../../redux/dataroomSlice';
import { Modal } from 'react-bootstrap';
import { ImWarning } from 'react-icons/im';
const Permissions = props => {
    const navigate = useNavigate();
    const { id, type } = useParams();
    const params = useParams();
    const dispatch = useDispatch();
    const { groups, groupPermissions, selectedUserData, projectUsersAll, permissionsBtnLoading } = useSelector(state => state.team);
    const { selectedProject } = useSelector(state => state.projectData);
    const [mainData, setMainData] = React.useState();
    const [selectedData, setSelectedData] = React.useState({});
    const [permissionData, setPermissionData] = React.useState([]);
    const [confirmModal, setConfirmModal] = React.useState(false);
    const [confirmData, setConfirmData] = React.useState();
    const noAccessObj = { no_access: true, view_perm: false, add_perm: false, delete_perm: false, download_original: false, download_watermarked: false };
    console.log(permissionData);
    const handleSetValues = ({ value, data, key, type, isOld, parent, grandParent, parentFolderIds, workstream_id, parentPermission }) => {
        const updateChildsRecursively = updatedData => {
            if (updatedData?.children?.length > 0) {
                updatedData.children = updatedData?.children?.map(item => {
                    if (item?.children?.length > 0 || item?.files?.length > 0) {
                        updateChildsRecursively(item);
                    }
                    if (item?.permission?.length > 0) {
                        let newPerm = {
                            ...item?.permission[0],
                            isUpdated: true,
                        };
                        if (key === 'no_access' && value === true) {
                            newPerm = { ...newPerm, ...noAccessObj };
                        }
                        if (key?.includes('download')) {
                            if (value === false) {
                                newPerm[key] = value;
                            } else key === 'download_watermarked' ? (newPerm['download_original'] = !value) : (newPerm['download_watermarked'] = !value);
                        }
                        if (key !== 'no_access') newPerm = { ...newPerm, no_access: false };
                        return { ...item, permission: [{ ...newPerm, [key]: value }] };
                    } else return item;
                });
            }
            if (updatedData?.files?.length > 0) {
                updatedData.files = updatedData?.files?.map(item => {
                    if (item?.permission?.length > 0) {
                        let newPerm = {
                            ...item?.permission[0],
                            isUpdated: true,
                        };

                        if (key === 'no_access' && value === true) {
                            newPerm = { ...newPerm, ...noAccessObj };
                        }
                        if (key?.includes('download')) {
                            if (value === false) {
                                newPerm[key] = value;
                            } else key === 'download_watermarked' ? (newPerm['download_original'] = !value) : (newPerm['download_watermarked'] = !value);
                        }
                        if (key !== 'no_access') newPerm = { ...newPerm, no_access: false };
                        console.log({ file: item });
                        return { ...item, permission: [{ ...newPerm, [key]: value }] };
                    } else return item;
                });
            }
        };
        if (type === 'workstream') {
            console.log({ value, data, key, parentPermission, type, isOld });
            let updatedPermData = [...permissionData];
            const workstream = updatedPermData.find(ws => ws.workstream_id === data?.workstream_id);
            let permObj = {};
            if (isOld) {
                permObj = { ...data?.permission[0], isUpdated: true };
            } else {
                if (!isOld && data?.permission?.length > 0) {
                    permObj = { ...data?.permission[0], isNew: true };
                    permObj.id && delete permObj.id;
                    permObj.usingGroupPerm && delete permObj.usingGroupPerm;
                } else {
                    permObj = {
                        no_access: false,
                        view_perm: false,
                        add_perm: false,
                        delete_perm: false,
                        download_original: false,
                        download_watermarked: false,
                        ...parentPermission,
                        [key]: value,
                        isNew: true,
                    };
                    permObj.id && delete permObj.id;
                    permObj.usingGroupPerm && delete permObj.usingGroupPerm;
                }
            }
            if (key?.includes('download')) {
                if (value === false) {
                    permObj[key] = value;
                } else key === 'download_watermarked' ? (permObj['download_original'] = !value) : (permObj['download_watermarked'] = !value);
            }
            if (key === 'no_access' && value === true) {
                permObj = {
                    ...permObj,
                    ...noAccessObj,
                };
            }
            if (key !== 'no_access') permObj = { ...permObj, no_access: false };
            let updatedFolders = workstream?.folders.map(item => {
                let updatedData = { ...item };
                updateChildsRecursively(updatedData);
                if (updatedData?.permission?.length > 0) {
                    let newPerm = {
                        ...item?.permission[0],
                        isUpdated: true,
                    };
                    if (key === 'no_access' && value === true) {
                        newPerm = { ...newPerm, ...noAccessObj };
                    }
                    if (key !== 'no_access') newPerm = { ...newPerm, no_access: false };
                    if (key?.includes('download')) {
                        if (value === false) {
                            newPerm[key] = value;
                        } else key === 'download_watermarked' ? (newPerm['download_original'] = !value) : (newPerm['download_watermarked'] = !value);
                    }
                    updatedData = { ...updatedData, permission: [{ ...newPerm, [key]: value }] };
                }

                return { ...updatedData };
            });
            workstream.permission = [{ ...permObj, [key]: value }];
            workstream.folders = [...updatedFolders];
            setPermissionData([...updatedPermData]);
        }
        if (type === 'folder') {
            let updatedPermData = [...permissionData];
            const workstream = updatedPermData.find(ws => ws.workstream_id === workstream_id);
            if (workstream) {
                let currentLevel = workstream.folders;
                let currentLevelFolder = workstream;
                for (const parentId of parentFolderIds) {
                    const foundFolder = currentLevel.find(folder => folder.folder_id === parentId);
                    if (foundFolder) {
                        currentLevel = foundFolder.children || [];
                        currentLevelFolder = foundFolder;
                    } else {
                        console.error(`Parent folder with ID ${parentId} not found.`);
                        return;
                    }
                }
                let permObj = {};
                if (isOld) {
                    console.log('already exists');
                    permObj = { ...data?.permission[0], isUpdated: true };
                } else {
                    if (!isOld && data?.permission?.length > 0) {
                        permObj = { ...data?.permission[0], isNew: true };
                        permObj.id && delete permObj.id;
                        permObj.usingGroupPerm && delete permObj.usingGroupPerm;
                    } else {
                        permObj = { ...parentPermission, isNew: true };
                        permObj.id && delete permObj.id;
                        permObj.usingGroupPerm && delete permObj.usingGroupPerm;
                    }
                }
                if (key?.includes('download')) {
                    if (value === false) {
                        permObj[key] = value;
                    } else key === 'download_watermarked' ? (permObj['download_original'] = !value) : (permObj['download_watermarked'] = !value);
                }

                if (key === 'no_access' && value === true) {
                    permObj = {
                        ...permObj,
                        ...noAccessObj,
                    };
                }
                if (key !== 'no_access') permObj = { ...permObj, no_access: false };

                let updatedFolders = currentLevel.map(item => {
                    if (item?.folder_id === data?.folder_id) {
                        let updatedData = { ...item };
                        updateChildsRecursively(updatedData);
                        return { ...updatedData, permission: [{ ...permObj, [key]: value }] };
                    } else return item;
                });
                if (parentFolderIds?.length > 0) {
                    currentLevelFolder.children = [...updatedFolders];
                    setPermissionData([...updatedPermData]);
                } else {
                    currentLevelFolder.folders = [...updatedFolders];
                    setPermissionData([...updatedPermData]);
                }
            }
        }
        if (type === 'file') {
            let updatedPermData = [...permissionData];
            const workstream = updatedPermData.find(ws => ws.workstream_id === workstream_id);
            if (workstream) {
                let currentLevel = workstream.folders;
                let currentLevelFolder = {};
                for (const parentId of parentFolderIds) {
                    const foundFolder = currentLevel.find(folder => folder.folder_id === parentId);
                    if (foundFolder) {
                        currentLevel = foundFolder.children || [];
                        currentLevelFolder = foundFolder;
                    } else {
                        console.error(`Parent folder with ID ${parentId} not found.`);
                        return;
                    }
                }
                let permObj = {};
                if (isOld) {
                    console.log('already exists');
                    permObj = { ...data?.permission[0], isUpdated: true };
                } else {
                    if (!isOld && data?.permission?.length > 0) {
                        permObj = { ...data?.permission[0], isNew: true };
                        permObj.id && delete permObj.id;
                        permObj.usingGroupPerm && delete permObj.usingGroupPerm;
                    } else {
                        permObj = { ...parentPermission, isNew: true };
                        permObj.id && delete permObj.id;
                        permObj.usingGroupPerm && delete permObj.usingGroupPerm;
                    }
                }
                if (key?.includes('download')) {
                    if (value === false) {
                        permObj[key] = value;
                    } else key === 'download_watermarked' ? (permObj['download_original'] = !value) : (permObj['download_watermarked'] = !value);
                }
                let updatedFiles = currentLevelFolder.files?.map(fileData => {
                    if (fileData?.file_id === data?.file_id) {
                        if (key === 'no_access' && value === true) {
                            permObj = {
                                ...permObj,
                                isNew: true,
                                no_access: true,
                                view_perm: false,
                                add_perm: false,
                                delete_perm: false,
                                download_original: false,
                                download_watermarked: false,
                            };
                        } else {
                            permObj = { ...permObj, [key]: value, no_access: false };
                        }
                        return { ...fileData, permission: [{ ...permObj }] };
                    } else return fileData;
                });
                currentLevelFolder.files = [...updatedFiles];

                setPermissionData([...updatedPermData]);
            } else {
                // If the workstream is not found, exit or handle as needed
                console.error(`Workstream with ID ${workstream_id} not found.`);
            }
        }
    };
    const handleChange = ({ value, data, key, type, isOld, parent, grandParent, permission, parentFolderIds, workstream_id, parentPermission }) => {
        let showModal = false;
        const checkRecursively = (item, dont_check_folder_perm) => {
            if (item?.permission?.length > 0 && !dont_check_folder_perm) {
                showModal = true;
            }
            let isFilePerm = item?.files?.find(file_item => file_item?.permission?.length > 0);
            if (isFilePerm) showModal = true;
            if (item?.children?.length > 0) {
                item?.children?.map(child_item => checkRecursively(child_item));
            }
        };
        if (type === 'workstream') {
            data?.folders?.map(item => checkRecursively(item));
        }
        if (type === 'folder') {
            checkRecursively(data, true);
        }
        if (type === 'file') {
            handleSetValues({ value, data, key, type, isOld, parent, grandParent, permission, parentFolderIds, workstream_id, parentPermission });
        }
        if (showModal) {
            setConfirmModal(true);
            setConfirmData({ value, data, key, type, isOld, parent, grandParent, permission, parentFolderIds, workstream_id, parentPermission });
        } else handleSetValues({ value, data, key, type, isOld, parent, grandParent, permission, parentFolderIds, workstream_id, parentPermission });
        // handleSetValues({ value, data, key, type, isOld, parent, grandParent, permission, parentFolderIds, workstream_id, parentPermission });
    };
    const handleSubmit = async () => {
        let create = [];
        let update = [];
        function extractModifiedPermissions(data, type, id, selectedData) {
            const modifiedPermissions = [];
            function processItem(item) {
                if (item.permission && item.permission.length > 0) {
                    const modifiedPermission = { ...item.permission[0] };

                    // Remove group and user properties
                    delete modifiedPermission.group;
                    delete modifiedPermission.user;

                    // Add acl_id to modified permission
                    modifiedPermission.acl = item.acl_id;

                    // Conditionally set group or user
                    if (type === 'group') modifiedPermission.group = id;
                    else modifiedPermission.user = selectedData?.group_member_id;

                    if (modifiedPermission.isNew || modifiedPermission.isUpdated) {
                        modifiedPermissions.push(modifiedPermission);
                    }
                }

                if (item.folders) {
                    item.folders.forEach(processItem);
                }
                if (item.files) {
                    item.files.forEach(file => {
                        if (file.permission && file.permission.length > 0) {
                            const modifiedPermission = { ...file.permission[0] };

                            // Remove group and user properties
                            delete modifiedPermission.group;
                            delete modifiedPermission.user;

                            // Add acl_id to modified permission
                            modifiedPermission.acl = file.acl_id;

                            // Conditionally set group or user
                            if (type === 'group') modifiedPermission.group = id;
                            else modifiedPermission.user = selectedData?.group_member_id;

                            if (modifiedPermission.isNew || modifiedPermission.isUpdated) {
                                modifiedPermissions.push(modifiedPermission);
                            }
                        }
                    });
                }

                // Check for children recursively
                if (item.children) {
                    item.children.forEach(processItem);
                }
            }

            data.forEach(processItem);

            return modifiedPermissions;
        }

        // Usage
        const modifiedPermissionsArray = extractModifiedPermissions(permissionData, type, id, selectedData);
        modifiedPermissionsArray.forEach(permObj => {
            if (permObj?.isNew || permObj?.usingGroupPerm) {
                create.push(permObj);
            }
            if (!permObj?.isNew && permObj?.isUpdated && !permObj?.usingGroupPerm) {
                update.push(permObj);
            }
        });
        // Usage
        console.log({ update, create });
        if (type === 'group') {
            await dispatch(UpdateGroupPermissions({ update, create }));
        } else {
            update = update.filter(item => !item?.usingGroupPerm);
            console.log({ update, create });
            await dispatch(UpdateUserPermissions({ update, create }));
        }
        navigate('/team/vdr');
    };
    const handleExpandRow = ({ data, type, parent, parentFolder, parentFolderIds, workstream_id }) => {
        if (type === 'workstream') {
            setPermissionData(
                permissionData?.map(item => {
                    if (item?.workstream_id === data?.workstream_id) return { ...item, show: !data?.show };
                    else return item;
                }),
            );
        }
        if (type === 'folder') {
            function updateFolderWithIds(data, updatedFolder) {
                const workstream = data.find(ws => ws.workstream_id === workstream_id);

                if (workstream) {
                    let currentLevel = workstream.folders;

                    for (const parentId of parentFolderIds) {
                        const foundFolder = currentLevel.find(folder => folder.folder_id === parentId);

                        if (foundFolder) {
                            currentLevel = foundFolder.children || [];
                        } else {
                            // If the parent folder is not found, exit or handle as needed
                            console.error(`Parent folder with ID ${parentId} not found.`);
                            return;
                        }
                    }
                    const targetFolder = currentLevel.find(folder => folder.folder_id === updatedFolder.folder_id);
                    if (targetFolder) {
                        // Update the target folder properties
                        targetFolder.acl_id = updatedFolder.acl_id;
                        targetFolder.name = updatedFolder.name;
                        targetFolder.permission = updatedFolder.permission;
                        targetFolder.children = updatedFolder.children;
                        targetFolder.files = updatedFolder.files;
                        targetFolder.show = updatedFolder.show;
                    } else {
                        // If the target folder is not found, exit or handle as needed
                        console.error(`Target folder with ID ${updatedFolder.folder_id} not found.`);
                    }
                } else {
                    // If the workstream is not found, exit or handle as needed
                    console.error(`Workstream with ID ${workstream_id} not found.`);
                }
            }
            const newData = [...permissionData];
            updateFolderWithIds(newData, { ...data, show: !data?.show }, parentFolderIds);
            setPermissionData(newData);
        }
        // function updateFolder(data, updatedFolder) {
        //     // Iterate through the data array
        //     data.forEach(workstream => {
        //         workstream.folders.forEach(folder => {
        //             // Check if the current folder has the same folder_id as the updatedFolder
        //             if (folder.folder_id === updatedFolder.folder_id) {
        //                 // Update the folder properties
        //                 folder.acl_id = updatedFolder.acl_id;
        //                 folder.name = updatedFolder.name;
        //                 folder.permission = updatedFolder.permission;
        //                 folder.children = updatedFolder.children;
        //                 folder.files = updatedFolder.files;
        //                 folder.show = updatedFolder.show;
        //             }
        //             // If the current folder has children, recursively update them
        //             if (folder.children && folder.children.length > 0) {
        //                 updateChildFolders(folder.children, updatedFolder);
        //             }
        //         });
        //     });
        // }

        // // Recursively update child folders
        // function updateChildFolders(children, updatedFolder) {
        //     children.forEach(child => {
        //         if (child.folder_id === updatedFolder.folder_id) {
        //             child.acl_id = updatedFolder.acl_id;
        //             child.name = updatedFolder.name;
        //             child.permission = updatedFolder.permission;
        //             child.children = updatedFolder.children;
        //             child.files = updatedFolder.files;
        //             child.show = updatedFolder.show;
        //         }
        //         if (child.children && child.children.length > 0) {
        //             updateChildFolders(child.children, updatedFolder);
        //         }
        //     });
        // }

        // // Example usage

        // if (type === 'folder') {
        //     const newData = [...permissionData];
        //     updateFolder(newData, { ...data, show: !data?.show });
        //     setPermissionData(newData);
        // }
        // const recursiveFunction = ({ index, folders }) => {
        //     console.log({ index, folders });
        //     return folders?.map(item => {
        //         if (item?.folder_id === parentFolderIds[index]) {
        //             let updatedChildrens = item?.children?.map(c => {
        //                 if (c?.folder_id === data?.folder_id) {
        //                     return { ...c, show: !c?.show };
        //                 } else {
        //                     if (index + 1 === parentFolderIds?.length || c.children?.length === 0) return c;
        //                     else return { ...c, children: recursiveFunction({ index: index + 1, folders: c?.children }) };
        //                 }
        //             });
        //             console.log({ item, updatedChildrens });
        //             return { ...item, children: [...updatedChildrens] };
        //         } else return item;
        //     });
        // };
        // if (type === 'folder') {
        //     if (!parentFolder) {
        //         setPermissionData(
        //             permissionData?.map(item => {
        //                 if (item?.workstream_id === parent?.workstream_id) {
        //                     let updatedFolders = item?.folders?.map(folder => {
        //                         if (folder?.folder_id === data?.folder_id) return { ...folder, show: !data?.show };
        //                         else return folder;
        //                     });
        //                     return { ...item, folders: [...updatedFolders] };
        //                 } else return item;
        //             }),
        //         );
        //     } else {
        //         console.log(parentFolderIds, workstream_id);
        //         setPermissionData(
        //             permissionData?.map(item => {
        //                 if (item?.workstream_id === workstream_id) {
        //                     return { ...item, folders: recursiveFunction({ index: 0, folders: item?.folders }) };
        //                 } else return item;
        //             }),
        //         );
        //     }
        // }
    };

    const WorkstreamTableRow = ({ data }) => {
        let parentPermission = {};
        let usingGroupPermission = false;
        if (type === 'user' && data?.permission?.length === 0) {
            let groupWorkstream = groupPermissions?.workstreams?.find(item => item?.workstream_id === data?.workstream_id);
            if (groupWorkstream?.permission?.length > 0) {
                parentPermission = groupWorkstream?.permission[0];
                usingGroupPermission = true;
            }
        }
        const permission = data?.permission?.length > 0 ? data?.permission[0] : parentPermission;
        let isOld = Boolean(data?.permission?.length > 0);
        if (data?.permission?.length > 0 && data?.permission[0]?.usingGroupPerm) isOld = false;
        const [blueIcon, setBlueIcon] = React.useState({
            view_perm: false,
            no_access: false,
            add_perm: false,
            download_watermarked: false,
            download_original: false,
            delete_perm: false,
        });
        React.useEffect(() => {
            let referencePermissions = {
                view_perm: false,
                no_access: false,
                add_perm: false,
                download_watermarked: false,
                download_original: false,
                delete_perm: false,
            };
            const checkRecursively = folder => {
                folder?.files?.map(file => {
                    if (file?.permission?.length > 0) {
                        if (!referencePermissions?.view_perm) referencePermissions.view_perm = file?.permission[0].view_perm !== permission?.view_perm;
                        if (!referencePermissions?.no_access) referencePermissions.no_access = file?.permission[0].no_access !== permission?.no_access;
                        if (!referencePermissions?.add_perm) referencePermissions.add_perm = file?.permission[0].add_perm !== permission?.add_perm;
                        if (!referencePermissions?.download_watermarked)
                            referencePermissions.download_watermarked = file?.permission[0].download_watermarked !== permission?.download_watermarked;
                        if (!referencePermissions?.download_original)
                            referencePermissions.download_original = file?.permission[0].download_original !== permission?.download_original;
                        if (!referencePermissions?.delete_perm) referencePermissions.delete_perm = file?.permission[0].delete_perm !== permission?.delete_perm;
                    }
                });
                if (folder?.permission?.length > 0) {
                    if (!referencePermissions?.view_perm) referencePermissions.view_perm = folder?.permission[0].view_perm !== permission?.view_perm;
                    if (!referencePermissions?.no_access) referencePermissions.no_access = folder?.permission[0].no_access !== permission?.no_access;
                    if (!referencePermissions?.add_perm) referencePermissions.add_perm = folder?.permission[0].add_perm !== permission?.add_perm;
                    if (!referencePermissions?.download_watermarked)
                        referencePermissions.download_watermarked = folder?.permission[0].download_watermarked !== permission?.download_watermarked;
                    if (!referencePermissions?.download_original)
                        referencePermissions.download_original = folder?.permission[0].download_original !== permission?.download_original;
                    if (!referencePermissions?.delete_perm) referencePermissions.delete_perm = folder?.permission[0].delete_perm !== permission?.delete_perm;
                }
                if (folder?.children?.length > 0) {
                    folder?.children?.map(child => checkRecursively(child));
                }
            };
            data?.folders?.map(item => checkRecursively(item));
            setBlueIcon(referencePermissions);
        }, [data]);

        return (
            <>
                <tr key={data?.workstream_id} className={styles.tableRow} style={{ background: type === 'user' && permission?.usingGroupPerm && '#f0f4f8' }}>
                    <td onClick={() => handleExpandRow({ data, type: 'workstream' })} className="ps-2">
                        <span className={styles.wsName}>
                            {data?.show ? <BsCaretDownFill size={15} className="me-2" /> : <BsFillCaretRightFill size={15} className="me-2" />}
                            {data?.name}
                        </span>
                    </td>
                    <td>
                        <input
                            type="checkbox"
                            checked={permission?.no_access}
                            onChange={e =>
                                handleChange({
                                    parentPermission,
                                    value: e.target.checked,
                                    data,
                                    parent: { permission: [parentPermission] },
                                    key: 'no_access',
                                    type: 'workstream',
                                    isOld: isOld,
                                })
                            }
                        />
                        {blueIcon?.no_access && <BsFillCheckCircleFill className={styles.accentIcon} />}
                    </td>
                    <td>
                        <input
                            type="checkbox"
                            disabled={permission?.no_access}
                            checked={permission?.view_perm}
                            onChange={e =>
                                handleChange({
                                    parentPermission,
                                    value: e.target.checked,
                                    data,
                                    key: 'view_perm',
                                    parent: { permission: [parentPermission] },
                                    type: 'workstream',
                                    isOld: isOld,
                                })
                            }
                        />
                        {blueIcon?.view_perm && <BsFillCheckCircleFill className={styles.accentIcon} />}
                    </td>
                    <td>
                        <input
                            type="checkbox"
                            disabled={permission?.no_access}
                            checked={permission?.add_perm}
                            onChange={e =>
                                handleChange({
                                    parentPermission,
                                    value: e.target.checked,
                                    data,
                                    key: 'add_perm',
                                    parent: { permission: [parentPermission] },
                                    type: 'workstream',
                                    isOld: isOld,
                                })
                            }
                        />
                        {blueIcon?.add_perm && <BsFillCheckCircleFill className={styles.accentIcon} />}
                    </td>
                    <td>
                        <input
                            type="checkbox"
                            disabled={permission?.no_access}
                            className={styles.roundCheckbox}
                            checked={permission?.download_watermarked}
                            onChange={e => {
                                handleChange({
                                    parentPermission,
                                    value: e.target.checked,
                                    data,
                                    parent: { permission: [parentPermission] },
                                    key: 'download_watermarked',
                                    type: 'workstream',
                                    isOld: isOld,
                                });
                            }}
                        />
                        {blueIcon?.download_watermarked && <BsFillCheckCircleFill className={styles.accentIcon} />}
                    </td>
                    <td>
                        <input
                            type="checkbox"
                            disabled={permission?.no_access}
                            className={styles.roundCheckbox}
                            checked={permission?.download_original}
                            onChange={e =>
                                handleChange({
                                    parentPermission,
                                    value: e.target.checked,
                                    data,
                                    key: 'download_original',
                                    type: 'workstream',
                                    parent: { permission: [parentPermission] },
                                    isOld: isOld,
                                })
                            }
                        />
                        {blueIcon?.download_original && <BsFillCheckCircleFill className={styles.accentIcon} />}
                    </td>
                    <td>
                        <input
                            type="checkbox"
                            disabled={permission?.no_access}
                            checked={permission?.delete_perm}
                            onChange={e =>
                                handleChange({
                                    parentPermission,
                                    value: e.target.checked,
                                    data,
                                    parent: { permission: [parentPermission] },
                                    key: 'delete_perm',
                                    type: 'workstream',
                                    isOld: isOld,
                                })
                            }
                        />
                        {blueIcon?.delete_perm && <BsFillCheckCircleFill className={styles.accentIcon} />}
                    </td>
                </tr>
                {data?.show &&
                    data?.folders?.map((item, index) => (
                        <FolderTableRow
                            data={item}
                            index={index}
                            parent={data}
                            parentPermission={permission}
                            usingGroupPermission={usingGroupPermission}
                            workstream_id={data?.workstream_id}
                            key={item?.folder_id}
                        />
                    ))}
            </>
        );
    };
    const FolderTableRow = ({ data, parentPermission, parent, index, usingGroupPermission, workstream_id, nestedFolder = 0, parentFolder, parentFolderIds = [] }) => {
        let permission = data?.permission?.length > 0 ? data?.permission[0] : parentPermission;
        let padding = 20 + nestedFolder;

        const [blueIcon, setBlueIcon] = React.useState({
            view_perm: false,
            no_access: false,
            add_perm: false,
            download_watermarked: false,
            download_original: false,
            delete_perm: false,
        });
        let isOld = Boolean(data?.permission?.length > 0);
        if (data?.permission?.length > 0 && data?.permission[0]?.usingGroupPerm) isOld = false;
        // if (type === 'user' && usingGroupPermission && data?.permission?.length === 0) {
        //     let groupWorkstream = groupPermissions?.workstreams?.find(item => item?.workstream_id === workstream_id);
        //     let groupFolder = groupWorkstream?.folders?.find(f => f?.folder_id === data?.folder_id);
        //     if (groupFolder?.permission?.length > 0) {
        //         permission = groupFolder?.permission[0];
        //     }
        // }
        console.log({ usingGroupPermission, data });
        React.useEffect(() => {
            let referencePermissions = {
                view_perm: false,
                no_access: false,
                add_perm: false,
                download_watermarked: false,
                download_original: false,
                delete_perm: false,
            };
            const checkRecursively = folder => {
                folder?.files?.map(file => {
                    if (file?.permission?.length > 0) {
                        console.log({ file, perm: file?.permission[0] });
                        if (!referencePermissions?.view_perm) referencePermissions.view_perm = file?.permission[0].view_perm !== permission?.view_perm;
                        if (!referencePermissions?.no_access) referencePermissions.no_access = file?.permission[0].no_access !== permission?.no_access;
                        if (!referencePermissions?.add_perm) referencePermissions.add_perm = file?.permission[0].add_perm !== permission?.add_perm;
                        if (!referencePermissions?.download_watermarked)
                            referencePermissions.download_watermarked = file?.permission[0].download_watermarked !== permission?.download_watermarked;
                        if (!referencePermissions?.download_original)
                            referencePermissions.download_original = file?.permission[0].download_original !== permission?.download_original;
                        if (!referencePermissions?.delete_perm) referencePermissions.delete_perm = file?.permission[0].delete_perm !== permission?.delete_perm;
                    }
                });
                if (folder?.permission?.length > 0) {
                    if (!referencePermissions?.view_perm) referencePermissions.view_perm = folder?.permission[0].view_perm !== permission?.view_perm;
                    if (!referencePermissions?.no_access) referencePermissions.no_access = folder?.permission[0].no_access !== permission?.no_access;
                    if (!referencePermissions?.add_perm) referencePermissions.add_perm = folder?.permission[0].add_perm !== permission?.add_perm;
                    if (!referencePermissions?.download_watermarked)
                        referencePermissions.download_watermarked = folder?.permission[0].download_watermarked !== permission?.download_watermarked;
                    if (!referencePermissions?.download_original)
                        referencePermissions.download_original = folder?.permission[0].download_original !== permission?.download_original;
                    if (!referencePermissions?.delete_perm) referencePermissions.delete_perm = folder?.permission[0].delete_perm !== permission?.delete_perm;
                }
                if (folder?.children?.length > 0) {
                    folder?.children?.map(child => checkRecursively(child));
                }
            };
            checkRecursively(data);
            setBlueIcon(referencePermissions);
        }, [data]);
        return (
            <>
                <tr
                    className={styles.folderRow}
                    key={data?.folder_id}
                    style={{
                        borderBottom: index === parent?.folders?.length - 1 && '1px solid rgba(0, 49, 73, 0.5)',
                        background: type === 'user' && permission?.usingGroupPerm && '#f0f4f8',
                    }}>
                    <td onClick={() => handleExpandRow({ data, type: 'folder', parent, parentFolder, parentFolderIds, workstream_id })} style={{ paddingLeft: padding + 'px' }}>
                        <span className={styles.wsName}>
                            {data?.show ? <BsCaretDownFill size={15} className="me-2" /> : <BsFillCaretRightFill size={15} className="me-2" />}
                            <BsFolder2 size={16} className="me-2" />
                            {data?.name}
                        </span>
                    </td>
                    <td>
                        <input
                            type="checkbox"
                            checked={permission?.no_access}
                            onChange={e =>
                                handleChange({
                                    value: e.target.checked,
                                    data,
                                    parent,
                                    key: 'no_access',
                                    type: 'folder',
                                    isOld: isOld,
                                    workstream_id,
                                    parentPermission,
                                    parentFolderIds,
                                })
                            }
                        />
                        {blueIcon?.no_access && <BsFillCheckCircleFill className={styles.accentIcon} />}
                    </td>
                    <td>
                        <input
                            type="checkbox"
                            disabled={permission?.no_access}
                            checked={permission?.view_perm}
                            onChange={e =>
                                handleChange({
                                    value: e.target.checked,
                                    data,
                                    parent,
                                    key: 'view_perm',
                                    type: 'folder',
                                    isOld: isOld,
                                    workstream_id,
                                    parentPermission,
                                    parentFolderIds,
                                })
                            }
                        />
                        {blueIcon?.view_perm && <BsFillCheckCircleFill className={styles.accentIcon} />}
                    </td>
                    <td>
                        <input
                            type="checkbox"
                            disabled={permission?.no_access}
                            checked={permission?.add_perm}
                            onChange={e =>
                                handleChange({
                                    value: e.target.checked,
                                    data,
                                    parent,
                                    key: 'add_perm',
                                    type: 'folder',
                                    isOld: isOld,
                                    workstream_id,
                                    parentPermission,
                                    parentFolderIds,
                                })
                            }
                        />
                        {blueIcon?.add_perm && <BsFillCheckCircleFill className={styles.accentIcon} />}
                    </td>
                    <td>
                        <input
                            type="checkbox"
                            disabled={permission?.no_access}
                            className={styles.roundCheckbox}
                            checked={permission?.download_watermarked}
                            onChange={e =>
                                handleChange({
                                    value: e.target.checked,
                                    data,
                                    parent,
                                    key: 'download_watermarked',
                                    type: 'folder',
                                    isOld: isOld,
                                    workstream_id,
                                    parentPermission,
                                    parentFolderIds,
                                })
                            }
                        />
                        {blueIcon?.download_watermarked && <BsFillCheckCircleFill className={styles.accentIcon} />}
                    </td>
                    <td>
                        <input
                            type="checkbox"
                            disabled={permission?.no_access}
                            className={styles.roundCheckbox}
                            checked={permission?.download_original}
                            onChange={e =>
                                handleChange({
                                    value: e.target.checked,
                                    data,
                                    parent,
                                    key: 'download_original',
                                    type: 'folder',
                                    isOld: isOld,
                                    workstream_id,
                                    parentPermission,
                                    parentFolderIds,
                                })
                            }
                        />
                        {blueIcon?.download_original && <BsFillCheckCircleFill className={styles.accentIcon} />}
                    </td>
                    <td>
                        <input
                            type="checkbox"
                            disabled={permission?.no_access}
                            checked={permission?.delete_perm}
                            onChange={e =>
                                handleChange({
                                    value: e.target.checked,
                                    data,
                                    parent,
                                    key: 'delete_perm',
                                    type: 'folder',
                                    isOld: isOld,
                                    workstream_id,
                                    parentPermission,
                                    parentFolderIds,
                                })
                            }
                        />
                        {blueIcon?.delete_perm && <BsFillCheckCircleFill className={styles.accentIcon} />}
                    </td>
                </tr>
                {data?.show &&
                    data?.children?.length > 0 &&
                    data?.children?.map(subFolder => (
                        <FolderTableRow
                            data={subFolder}
                            index={index}
                            parent={data}
                            parentPermission={permission}
                            usingGroupPermission={usingGroupPermission}
                            workstream_id={workstream_id}
                            nestedFolder={nestedFolder + 20}
                            parentFolder={parentFolder ? parentFolder : data}
                            parentFolderIds={[...parentFolderIds, data?.folder_id]}
                            key={subFolder?.folder_id}
                        />
                    ))}
                {data?.show &&
                    data?.files?.map(item => (
                        <FileTableRow
                            data={item}
                            parentPermission={permission}
                            parent={data}
                            grandParent={parent}
                            usingGroupPermission={usingGroupPermission && data?.permission?.length === 0}
                            folder_id={data?.folder_id}
                            workstream_id={workstream_id}
                            padding={padding + 10}
                            parentFolderIds={[...parentFolderIds, data?.folder_id]}
                            key={item?.file_id}
                        />
                    ))}
            </>
        );
    };
    const FileTableRow = ({ data, parentPermission, parent, grandParent, usingGroupPermission, folder_id, workstream_id, padding, parentFolderIds = [] }) => {
        let permission = data?.permission?.length > 0 ? data?.permission[0] : parentPermission;
        // if (type === 'user' && usingGroupPermission && data?.permission?.length === 0) {
        //     let groupWorkstream = groupPermissions?.workstreams?.find(item => item?.workstream_id === workstream_id);
        //     let groupFolder = groupWorkstream?.folders?.find(f => f?.folder_id === folder_id);
        //     let groupFile = groupFolder?.files?.find(f => f?.file_id === data?.file_id);
        //     if (groupFile?.permission?.length > 0) {
        //         permission = groupFile?.permission[0];
        //     }
        // }
        let isOld = Boolean(data?.permission?.length > 0);
        if (data?.permission?.length > 0 && data?.permission[0]?.usingGroupPerm) isOld = false;
        return (
            <>
                <tr key={data?.file_id} className={styles.folderRow} style={{ background: type === 'user' && permission?.usingGroupPerm && '#f0f4f8' }}>
                    <td style={{ paddingLeft: padding + 'px' }}>
                        <span className={styles.wsName + ' d-flex'}>
                            <p className="mb-0 ms-4 me-2">{getExtensionIcon(data?.name?.split('.')[1], 15, 15)}</p>
                            {data?.name}
                        </span>
                    </td>
                    <td>
                        <input
                            type="checkbox"
                            checked={permission?.no_access}
                            onChange={e =>
                                handleChange({
                                    workstream_id,
                                    parentPermission,
                                    parentFolderIds,
                                    value: e.target.checked,
                                    permission: permission,
                                    data,
                                    parent,
                                    grandParent,
                                    key: 'no_access',
                                    type: 'file',
                                    isOld: isOld,
                                })
                            }
                        />
                    </td>
                    <td>
                        <input
                            type="checkbox"
                            disabled={permission?.no_access}
                            checked={permission?.view_perm}
                            onChange={e =>
                                handleChange({
                                    workstream_id,
                                    parentPermission,
                                    parentFolderIds,
                                    value: e.target.checked,
                                    permission: permission,
                                    data,
                                    parent,
                                    grandParent,
                                    key: 'view_perm',
                                    type: 'file',
                                    isOld: isOld,
                                })
                            }
                        />
                    </td>
                    <td>
                        {/* <input
                            type="checkbox"
                            disabled={permission?.no_access}
                            checked={permission?.add_perm}
                            onChange={e =>
                                handleChange({
                                    workstream_id,
                                    parentPermission,
                                    parentFolderIds,
                                    value: e.target.checked,
                                    permission:permission,
                                    data,
                                    parent,
                                    grandParent,
                                    key: 'add_perm',
                                    type: 'file',
                                    isOld: isOld
                                })
                            }
                        /> */}
                    </td>
                    <td>
                        <input
                            type="checkbox"
                            disabled={permission?.no_access}
                            className={styles.roundCheckbox}
                            checked={permission?.download_watermarked}
                            onChange={e =>
                                handleChange({
                                    workstream_id,
                                    parentPermission,
                                    parentFolderIds,
                                    value: e.target.checked,
                                    permission: permission,
                                    data,
                                    parent,
                                    grandParent,
                                    key: 'download_watermarked',
                                    type: 'file',
                                    isOld: isOld,
                                })
                            }
                        />
                    </td>
                    <td>
                        <input
                            type="checkbox"
                            disabled={permission?.no_access}
                            className={styles.roundCheckbox}
                            checked={permission?.download_original}
                            onChange={e =>
                                handleChange({
                                    workstream_id,
                                    parentPermission,
                                    parentFolderIds,
                                    value: e.target.checked,
                                    permission: permission,
                                    data,
                                    parent,
                                    grandParent,
                                    key: 'download_original',
                                    type: 'file',
                                    isOld: isOld,
                                })
                            }
                        />
                    </td>
                    <td>
                        <input
                            type="checkbox"
                            disabled={permission?.no_access}
                            checked={permission?.delete_perm}
                            onChange={e =>
                                handleChange({
                                    workstream_id,
                                    parentPermission,
                                    parentFolderIds,
                                    value: e.target.checked,
                                    permission: permission,
                                    data,
                                    parent,
                                    grandParent,
                                    key: 'delete_perm',
                                    type: 'file',
                                    isOld: isOld,
                                })
                            }
                        />
                    </td>
                </tr>
            </>
        );
    };
    function generatePermissionWarning(type) {
        if (type === 'workstream') {
            return 'Some Folders and Files in this Workstream have Custom Permissions, which will be overwritten if Workstream Permissions are changed.';
        } else if (type === 'folder') {
            return 'Some Files in this Folder have Custom Permissions, which will be overwritten if Folder Permissions are changed.';
        } else {
            return 'Invalid type specified.';
        }
    }
    React.useEffect(() => {
        const effectAsync = async () => {
            if (id) {
                if (type === 'group') {
                    setSelectedData(groups?.find(item => item?.id === parseInt(id)));
                    dispatch(GetGroupPermissions(id));
                }
                if (type === 'user') {
                    let projectUser = projectUsersAll?.find(item => item?.value['group_member_id'] === parseInt(id));
                    setSelectedData(projectUser?.value);
                    await dispatch(GetGroupPermissions(projectUser?.group?.split(':')[0]?.trim()));
                    dispatch(GetGroupMemberPermissions(id));
                }
            }
        };
        effectAsync();
    }, [dispatch, id, groups]);
    const setSubfoldersVisibility = folders => {
        if (!folders || folders.length === 0) {
            return [];
        }

        return folders.map(folder => ({
            ...folder,
            show: false,
            children: setSubfoldersVisibility(folder?.children),
        }));
    };
    React.useEffect(() => {
        if (groupPermissions && type === 'group') {
            setMainData(groupPermissions);
            setPermissionData(
                groupPermissions?.workstreams?.map(item => ({
                    ...item,
                    show: false,
                    folders: setSubfoldersVisibility(item?.folders),
                })),
            );
        }
    }, [groupPermissions]);

    React.useEffect(() => {
        if (selectedUserData && type === 'user') {
            let groupPermission = groups?.find(item => selectedUserData?.group_type === item?.id)?.permissions;
            setMainData({ ...selectedUserData, permission: groupPermission });

            const groupData = [...groupPermissions?.workstreams];
            const userData = JSON.parse(JSON.stringify(selectedUserData?.workstreams)).map(item => ({
                ...item,
                show: false,
                folders: setSubfoldersVisibility(item?.folders),
            }));
            console.log(userData);
            setPermissionData(mapGroupPermissionsToUserPermissions(groupData, userData));
        }
    }, [selectedUserData]);
    React.useEffect(() => {
        if (selectedProject?.id) {
            dispatch(GetWorkstreamsDefaults());
            dispatch(GetWorkstreams());
        }
    }, [selectedProject, dispatch]);

    function mapGroupPermissionsToUserPermissions(groupData, userData) {
        function mapPermissions(groupItem, userItem) {
            if (groupItem?.permission?.length > 0) {
                if (userItem?.permission?.length === 0) {
                    userItem.permission = [{ ...groupItem.permission[0], usingGroupPerm: true }];
                }
            }

            if (groupItem?.folders?.length > 0) {
                groupItem.folders.forEach((groupFolder, groupFolderIndex) => {
                    if (userItem?.folders?.[groupFolderIndex]) {
                        mapPermissions(groupFolder, userItem.folders[groupFolderIndex]);
                    }
                });
            }
            if (groupItem?.children?.length > 0) {
                groupItem.children.forEach((groupFolder, groupFolderIndex) => {
                    if (userItem?.children?.[groupFolderIndex]) {
                        mapPermissions(groupFolder, userItem.children[groupFolderIndex]);
                    }
                });
            }

            if (groupItem?.files?.length > 0) {
                groupItem.files.forEach((groupFile, groupFileIndex) => {
                    if (groupFile?.permission?.length > 0) {
                        if (userItem?.files?.[groupFileIndex]?.permission?.length === 0) {
                            let pass = true;

                            if (userItem?.folders?.[groupFileIndex]?.permission?.length > 0 && !userItem.folders[groupFileIndex].permission[0].usingGroupPerm) {
                                pass = false;
                            }
                            if (userItem?.permission?.length > 0 && !userItem.permission[0].usingGroupPerm) {
                                pass = false;
                            }

                            if (pass) {
                                userItem.files[groupFileIndex].permission = [{ ...groupFile.permission[0], usingGroupPerm: true }];
                            }
                        }
                    }
                });
            }
        }

        groupData?.forEach((groupWs, groupWsIndex) => {
            if (userData?.[groupWsIndex]) {
                mapPermissions(groupWs, userData[groupWsIndex]);
            }
        });

        return userData;
    }
    return (
        <div>
            <div className={styles.headerDiv}>
                <div>
                    {' '}
                    <h4 className="m-0">{selectedData?.name || selectedData?.full_name}</h4>
                    {type === 'user' && <i className={styles.regularFont}>{selectedData['job_title'] && selectedData['job_title'] + ' - ' + selectedData?.organisation}</i>}
                </div>
                <div className="d-flex align-items-center">
                    <p onClick={() => navigate(-1)} className={styles.orangeText + ' mb-0 me-5'} role="button">
                        Cancel
                    </p>
                    <button onClick={handleSubmit} className="btn btn-primary smallButton">
                        Save {permissionsBtnLoading && <AiOutlineLoading3Quarters className="rotateLoader" />}
                    </button>
                </div>
            </div>
            <div>
                <b>Set Data Room Permissions</b>
                {type === 'group' && (
                    <p className={styles.regularFont}>Setting Data Room Permissions for the Group overrides custom Data Room Permissions for the User in the Group</p>
                )}
            </div>
            <div>
                <table className="w-100 mt-3">
                    <thead className={styles.tableHeader}>
                        <tr>
                            <td className=""></td>
                            <td className={styles.smallCol}>
                                <AiOutlineCloseCircle data-tip={`No Access`} />
                                <ReactTooltip place="bottom" effect="float" backgroundColor="white" textColor="#33b5e5" />
                            </td>
                            <td className={styles.smallCol}>
                                <BsEye data-tip={`View`} />
                                <ReactTooltip place="bottom" effect="float" backgroundColor="white" textColor="#33b5e5" />
                            </td>
                            <td className={styles.smallCol}>
                                <AiOutlinePlusCircle data-tip={`Add`} />
                                <ReactTooltip place="bottom" effect="float" backgroundColor="white" textColor="#33b5e5" />
                            </td>

                            <td className={styles.smallCol}>
                                <svg data-tip={`Download Watermark`} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <g fill="#003149" fillRule="evenodd">
                                        <path d="M14.289 6.434V7.965c0 .365.18.549.543.55.474.001.947 0 1.421 0 .038 0 .077-.005.113-.008L14.29 6.434M6.82 12v5.632c0 .365.181.546.545.546h9.077c.363 0 .544-.182.544-.548v-7.75c0-.366-.18-.545-.546-.545-.537-.001-1.075.002-1.613-.001-.815-.005-1.358-.553-1.36-1.37V6.38c0-.39-.169-.56-.555-.56H7.391c-.401 0-.57.171-.57.576v5.605M7.119 5h6.152c.016.007.032.019.049.021.607.075 1.11.351 1.538.784.708.716 1.419 1.43 2.137 2.136.553.545.815 1.198.814 1.973-.005 2.56-.002 5.121-.002 7.682 0 .055 0 .11-.002.164a1.281 1.281 0 0 1-1.28 1.238c-3.081.004-6.161.003-9.241-.004-.177 0-.367-.043-.527-.118-.524-.246-.757-.68-.757-1.253V6.386c0-.05 0-.1.003-.15.04-.515.287-.894.756-1.117.113-.054.24-.08.36-.119" />
                                        <path d="M8.76 16.424c.323 0 .619.003.915-.004.04 0 .096-.045.113-.083.069-.163.126-.331.187-.498.072-.192.207-.293.415-.293h3.021c.214-.001.352.102.424.3.06.162.116.325.182.485.015.038.062.088.096.089.304.007.609.004.924.004-.012-.046-.018-.081-.03-.114-.851-2.29-1.702-4.58-2.55-6.871-.032-.087-.077-.106-.16-.105-.25.004-.502.01-.752-.002-.123-.005-.172.04-.212.151-.725 1.963-1.455 3.924-2.183 5.886l-.39 1.055m5.185-5.33.347.934h1.315c.243.002.406.138.43.353.032.264-.14.462-.41.466-.29.005-.582.001-.874.001h-.156c.115.31.222.603.336.894.01.023.066.04.102.04.195.004.392-.003.587.004.28.01.444.198.416.463a.381.381 0 0 1-.372.351c-.13.007-.26.002-.412.002l.052.16.684 1.842c.145.388-.03.64-.442.64h-1.695c-.289 0-.402-.079-.502-.348-.056-.149-.106-.3-.17-.446-.016-.037-.075-.08-.115-.081-.774-.005-1.549-.005-2.324 0-.04.001-.1.052-.12.093-.068.159-.124.322-.183.485a.42.42 0 0 1-.428.297c-.601.002-1.203.003-1.805 0-.34-.002-.523-.269-.406-.588.231-.632.467-1.262.701-1.893.017-.046.03-.092.053-.16-.137 0-.254.004-.37 0-.25-.007-.412-.161-.418-.393-.007-.24.151-.411.404-.423.2-.01.401 0 .601-.006.038 0 .098-.023.109-.05.112-.287.217-.577.33-.883-.357 0-.692.004-1.028 0-.28-.004-.456-.22-.41-.492.035-.198.198-.326.427-.327.387-.003.775 0 1.162 0h.155l.344-.928c-.06-.003-.108-.006-.155-.007-.496 0-.993.002-1.49 0-.279-.002-.455-.17-.45-.421.005-.237.173-.397.428-.398.606-.003 1.212-.005 1.818.002.12.001.176-.032.214-.148.084-.258.186-.511.29-.793h-.17c-.706 0-1.413.001-2.12-.001-.309-.001-.486-.186-.456-.464a.381.381 0 0 1 .332-.346c.058-.008.118-.01.177-.01h4.306c.333 0 .43.068.546.382.155.418.313.835.463 1.255.033.09.073.125.174.124.615-.005 1.23-.003 1.845-.002.235.001.4.14.43.355.035.253-.156.462-.434.464-.505.003-1.011.001-1.517.001h-.146" />
                                        <path d="m11.92 13.046-.033.002-.269.73h.573l-.271-.732m-.02 1.558c-.282 0-.565.003-.847 0-.334-.004-.518-.265-.404-.578.284-.772.572-1.543.86-2.314.075-.2.21-.295.399-.294.182.001.316.095.388.288.29.775.58 1.55.865 2.327.11.304-.075.566-.4.57-.286.004-.573 0-.86 0" />
                                        <g fillRule="nonzero">
                                            <path d="M19 8.25a3.25 3.25 0 1 0 0-6.5 3.25 3.25 0 0 0 0 6.5zm-1.427-3.323a.25.25 0 0 1 .353-.001l.824.817V3.656a.25.25 0 1 1 .5 0v2.087l.824-.817a.25.25 0 0 1 .352.355l-1.25 1.24a.25.25 0 0 1-.352 0l-1.25-1.24a.25.25 0 0 1-.002-.354z" />
                                        </g>
                                    </g>
                                </svg>
                                <ReactTooltip place="bottom" effect="float" backgroundColor="white" textColor="#33b5e5" />
                            </td>
                            <td className={styles.smallCol}>
                                <svg data-tip={`Download Original`} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <g fill="#003149" fillRule="evenodd">
                                        <path d="M16.443 18.18H7.366c-.364 0-.545-.182-.545-.547V6.397c0-.406.169-.577.57-.577h5.522c.386 0 .555.17.555.56v1.586c.002.816.545 1.364 1.36 1.369.538.003 1.076 0 1.613 0 .367.002.546.18.546.546v7.75c0 .366-.181.548-.544.548zM14.289 6.433l2.078 2.073c-.037.002-.076.008-.114.008-.474 0-.947.001-1.421 0-.362-.001-.543-.185-.543-.55V6.434zm3.52 3.48c.002-.775-.26-1.429-.814-1.973-.718-.706-1.429-1.42-2.137-2.136-.428-.433-.93-.709-1.538-.784-.017-.002-.033-.014-.049-.021H7.12c-.12.039-.247.065-.36.119-.468.223-.715.602-.756 1.117-.004.05-.002.1-.002.15L6 17.623c0 .573.233 1.007.757 1.253.16.074.35.118.527.118 3.08.007 6.16.007 9.24.003a1.28 1.28 0 0 0 1.281-1.237c.003-.055.002-.11.002-.164 0-2.56-.003-5.121.002-7.682z" />
                                        <path d="M8.173 9.332H12.7a.408.408 0 0 0 0-.817H8.173a.408.408 0 1 0 0 .817M15.638 10.267H8.173a.408.408 0 1 0 0 .816h7.465a.408.408 0 1 0 0-.816M15.638 12.018H8.173a.408.408 0 1 0 0 .817h7.465a.408.408 0 1 0 0-.817M15.638 13.77H8.173a.408.408 0 1 0 0 .816h7.465a.408.408 0 1 0 0-.816M15.638 15.521H8.173a.408.408 0 1 0 0 .817h7.465a.408.408 0 1 0 0-.817" />
                                        <g fillRule="nonzero">
                                            <path d="M19 8.25a3.25 3.25 0 1 0 0-6.5 3.25 3.25 0 0 0 0 6.5zm-1.427-3.323a.25.25 0 0 1 .353-.001l.824.817V3.656a.25.25 0 1 1 .5 0v2.087l.824-.817a.25.25 0 0 1 .352.355l-1.25 1.24a.25.25 0 0 1-.352 0l-1.25-1.24a.25.25 0 0 1-.002-.354z" />
                                        </g>
                                    </g>
                                </svg>
                                <ReactTooltip place="bottom" effect="float" backgroundColor="white" textColor="#33b5e5" />
                            </td>

                            <td className={styles.smallCol}>
                                <RiDeleteBinLine data-tip={`Delete`} />
                                <ReactTooltip place="bottom" effect="float" backgroundColor="white" textColor="#33b5e5" />
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        {permissionData?.map(item => (
                            <WorkstreamTableRow key={item?.workstream_id} data={item} />
                        ))}
                    </tbody>
                </table>
            </div>
            <DoubleConfirmationModal
                message={generatePermissionWarning(confirmData?.type)}
                handleAction={() => handleSetValues(confirmData)}
                confirmData={confirmData}
                firstModalVisible={confirmModal}
                setFirstModalVisible={setConfirmModal}
            />
        </div>
    );
};
const DoubleConfirmationModal = ({ children, message, handleAction, firstModalVisible, setFirstModalVisible }) => {
    const [secondModalVisible, setSecondModalVisible] = React.useState(false);
    const handleClick = () => {
        handleAction();
        setSecondModalVisible(false);
    };
    return (
        <>
            <div onClick={() => setFirstModalVisible(true)}>{children}</div>
            <Modal centered show={firstModalVisible} onHide={() => setFirstModalVisible(false)} className={styles.modalMainBody}>
                <Modal.Body className={styles.confirmationModalBody + ' d-flex'}>
                    <div className={styles.redBgWarnModal}>
                        <ImWarning size={50} />
                    </div>
                    <div className={styles.confirmationModalMsg}>
                        <h3>WARNING</h3>
                        <p>{message}</p>
                        <div className="d-flex justify-content-end align-items-center">
                            <span
                                onClick={() => {
                                    setFirstModalVisible(false);
                                    setSecondModalVisible(true);
                                }}
                                className={styles.orangeLink}>
                                Overwrite
                            </span>
                            <button onClick={() => setFirstModalVisible(false)} className="ms-5 btn btn-primary smallButton">
                                Cancel
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal centered show={secondModalVisible} onHide={() => setSecondModalVisible(false)} className={styles.modalMainBody}>
                <Modal.Body className={styles.confirmationModalBody}>
                    <div className={styles.confirmationModalMsg}>
                        <h3>Are you sure?</h3>
                        <p>This process cannot be undone.</p>
                        <div className="d-flex justify-content-between align-items-center">
                            <span
                                onClick={() => {
                                    setSecondModalVisible(false);
                                }}
                                className={styles.orangeLink}>
                                Cancel
                            </span>
                            <button onClick={handleClick} className=" btn btn-primary smallButton">
                                Update
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Permissions;
