import React, { useState } from 'react';
import styles from './css/index.module.css';
import { ArrowSortUp16Regular, ChevronDown20Regular, MoreVertical20Regular } from '@fluentui/react-icons';
import { Menu, MenuDivider, MenuItem, MenuList, MenuPopover, MenuTrigger } from '@fluentui/react-components';
import ReactApexChart from 'react-apexcharts';
import {
    DeleteTeamMemberAnalysis,
    GetCompanyValues,
    GetTeamMemberAnalysis,
    PatchCompanyValues,
    PatchTeamMemberAnalysis,
    PostCompanyValues,
    PostTeamMemberAnalysis,
} from '../../redux/teamSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import DoubleConfirmationModal from '../CommonComponents/DoubleConfirmationModal/DoubleConfirmationModal';
import { calculateTeamScore } from '../../utils/commonUtils';
const TeamAnalyser = () => {
    const { teamAnalysisMembers } = useSelector(state => state.team);
    const [selectedTeamMember, setSelectedTeamMember] = React.useState();
    const [selectedMenu, setSelectedMenu] = React.useState('company');
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(GetCompanyValues());
        dispatch(GetTeamMemberAnalysis());
    }, []);
    return (
        <div className={styles.viewBody}>
            <h3 className={styles.pageHeading}>Team Analyser</h3>
            <p className={styles.subTitle}>Evaluate your team members considering both alignment with the company's values and suitability for their roles.</p>
            <div className="d-flex">
                <p
                    onClick={() => {
                        setSelectedMenu('company');
                        setSelectedTeamMember();
                    }}
                    className={(selectedMenu === 'company' && styles.selectedMenu) + ' ' + styles.menuText}>
                    Company Values
                </p>
                <p
                    onClick={() => {
                        setSelectedMenu('member');
                        setSelectedTeamMember();
                    }}
                    className={(selectedMenu === 'member' && styles.selectedMenu) + ' ' + styles.menuText}>
                    Team Member
                </p>
                <p
                    onClick={() => setSelectedMenu('scores')}
                    style={{ pointerEvents: teamAnalysisMembers?.length === 0 && 'none', color: teamAnalysisMembers?.length === 0 && 'var(--Neutral-Gray-6)' }}
                    className={(selectedMenu === 'scores' && styles.selectedMenu) + ' ' + styles.menuText}>
                    Team Scores
                </p>
            </div>
            {selectedMenu === 'company' && <CompanyValueDetails setSelectedMenu={setSelectedMenu} />}
            {selectedMenu === 'member' && <TeamMember selectedTeamMember={selectedTeamMember} setSelectedTeamMember={setSelectedTeamMember} setSelectedMenu={setSelectedMenu} />}
            {selectedMenu === 'scores' && <TeamScores selectedTeamMember={selectedTeamMember} setSelectedTeamMember={setSelectedTeamMember} setSelectedMenu={setSelectedMenu} />}
        </div>
    );
};

const CompanyValueDetails = ({ setSelectedMenu }) => {
    const dispatch = useDispatch();
    const [buttonLoading, setButtonLoading] = React.useState(false);
    const { companyValues } = useSelector(state => state.team);
    const [formValues, setFormValues] = React.useState([
        {
            company_value: '',
            value_description: '',
        },
        {
            company_value: '',
            value_description: '',
        },
        {
            company_value: '',
            value_description: '',
        },
    ]);

    const handleChange = (e, index) => {
        setFormValues(
            formValues?.map((item, i) => {
                if (index === i) {
                    if (item?.id) {
                        return { ...item, [e.target.name]: e.target.value, isEdited: true };
                    } else return { ...item, [e.target.name]: e.target.value };
                } else return item;
            }),
        );
    };
    const handleSubmit = async () => {
        setButtonLoading(true);

        if (formValues?.[0]?.id) {
            let data = formValues?.filter(item => item?.isEdited);
            for (const item of data) {
                try {
                    // Dispatch the API call for each item
                    await dispatch(PatchCompanyValues(item));
                    console.log(`API call succeeded for item ${item}`);
                } catch (error) {
                    console.error(`API call failed for item ${item}:`, error);
                }
            }
        } else {
            let data = formValues?.map(item => {
                return { ...item, company: localStorage['project_id'] };
            });
            await dispatch(PostCompanyValues(data));
        }
        setButtonLoading(false);
        setSelectedMenu('member');
    };
    const checkSubmitButton = () => {
        return formValues?.find(item => !item?.company_value || !item?.value_description) ? true : false;
    };
    const handleClear = () => {
        setFormValues(
            formValues?.map(item => {
                return {
                    ...item,
                    company_value: '',
                    value_description: '',
                };
            }),
        );
    };
    React.useEffect(() => {
        if (companyValues?.length > 2) setFormValues(companyValues);
    }, [companyValues]);
    return (
        <div className={styles.whiteFullBody}>
            <div className={styles.cardHeader}>Add your three Company Values</div>
            <div className={styles.teamMemberBody} style={{ borderBottom: 0 }}>
                <div className="row">
                    <div className="col-sm-12 col-md-4">
                        <div className="mb-4">
                            <label className={styles.inputLabel}>Company Value #1</label>
                            <input value={formValues?.[0]?.company_value} name="company_value" onChange={e => handleChange(e, 0)} className={'form-control ' + styles.inputField} />
                        </div>
                        <div>
                            <label className={styles.inputLabel}>What does it mean in your organisation?</label>
                            <textarea
                                value={formValues?.[0]?.value_description}
                                name="value_description"
                                onChange={e => handleChange(e, 0)}
                                rows={5}
                                className={'form-control h-100' + styles.inputField}
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4">
                        {' '}
                        <div className="mb-4">
                            <label className={styles.inputLabel}>Company Value #2</label>
                            <input value={formValues?.[1]?.company_value} name="company_value" onChange={e => handleChange(e, 1)} className={'form-control ' + styles.inputField} />
                        </div>
                        <div>
                            <label className={styles.inputLabel}>What does it mean in your organisation?</label>
                            <textarea
                                value={formValues?.[1]?.value_description}
                                name="value_description"
                                onChange={e => handleChange(e, 1)}
                                rows={5}
                                className={'form-control h-100' + styles.inputField}
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4">
                        {' '}
                        <div className="mb-4">
                            <label className={styles.inputLabel}>Company Value #3</label>
                            <input value={formValues?.[2]?.company_value} name="company_value" onChange={e => handleChange(e, 2)} className={'form-control ' + styles.inputField} />
                        </div>
                        <div>
                            <label className={styles.inputLabel}>What does it mean in your organisation?</label>
                            <textarea
                                value={formValues?.[2]?.value_description}
                                name="value_description"
                                onChange={e => handleChange(e, 2)}
                                rows={5}
                                className={'form-control h-100' + styles.inputField}
                            />
                        </div>
                    </div>
                </div>
                <br />
                <div className="mt-5 d-flex align-items-center justify-content-between">
                    <button disabled={checkSubmitButton()} onClick={handleSubmit} className={styles.saveBtn}>
                        Save & Continue {buttonLoading && <AiOutlineLoading3Quarters className="rotateLoader" />}
                    </button>
                    <span onClick={handleClear} className={styles.textBtn}>
                        Clear
                    </span>
                </div>
            </div>
        </div>
    );
};
const TeamMember = ({ selectedTeamMember, setSelectedTeamMember, setSelectedMenu }) => {
    const { companyValues } = useSelector(state => state.team);
    const [buttonLoading, setButtonLoading] = React.useState(false);
    const { teamAnalysisMembers } = useSelector(state => state.team);
    const dispatch = useDispatch();
    const [formValues, setFormValues] = React.useState({
        member_name: '',
        member_department: '',
        member_role: '',
        grasp_alignment: '',
        desire_alignment: '',
        competency_alignment: '',
    });
    const [companyFormValues, setCompanyFormValues] = React.useState([]);
    const handleChange = e => {
        setFormValues({ ...formValues, [e?.target?.name]: e?.target?.value });
    };
    const handleChangeCompanyValue = (e, id) => {
        setCompanyFormValues(
            companyFormValues?.map(item => {
                if (item?.team_value === id) return { ...item, value_score: e?.target?.value };
                else return item;
            }),
        );
    };
    const handleSubmit = async () => {
        setButtonLoading(true);
        if (selectedTeamMember) {
            let body = {
                ...formValues,
                team_members_values: companyFormValues,
                company: localStorage['project_id'],
                id: selectedTeamMember?.id,
            };
            await dispatch(PatchTeamMemberAnalysis({ ...body, final_score: calculateTeamScore(body, true) }));
            setSelectedTeamMember();
            setSelectedMenu('scores');
        } else {
            let body = { ...formValues, team_members_values: companyFormValues, company: localStorage['project_id'] };
            await dispatch(
                PostTeamMemberAnalysis({
                    ...body,
                    final_score: calculateTeamScore(body, true),
                }),
            );
        }

        setButtonLoading(false);
        setFormValues({
            member_name: '',
            member_department: '',
            member_role: '',
            grasp_alignment: '',
            desire_alignment: '',
            competency_alignment: '',
        });
        setCompanyFormValues(
            companyValues?.map(item => {
                return {
                    value_score: '',
                    team_value: item?.id,
                };
            }),
        );
    };
    const checkButton = () => {
        if (
            !formValues?.member_name ||
            !formValues?.member_department ||
            !formValues?.member_role ||
            !formValues?.grasp_alignment ||
            !formValues?.desire_alignment ||
            !formValues?.competency_alignment
        ) {
            return true;
        } else if (companyFormValues?.find(item => !item?.value_score)) {
            return true;
        } else return false;
    };
    React.useEffect(() => {
        if (companyValues?.length > 2) {
            setCompanyFormValues(
                companyValues?.map(item => {
                    return {
                        value_score: '',
                        team_value: item?.id,
                    };
                }),
            );
        }
    }, [companyValues]);
    React.useEffect(() => {
        if (selectedTeamMember) {
            setFormValues({
                member_name: selectedTeamMember?.member_name,
                member_department: selectedTeamMember?.member_department,
                member_role: selectedTeamMember?.member_role,
                grasp_alignment: selectedTeamMember?.grasp_alignment,
                desire_alignment: selectedTeamMember?.desire_alignment,
                competency_alignment: selectedTeamMember?.competency_alignment,
            });
            setCompanyFormValues(selectedTeamMember?.team_members_values);
        }
    }, [selectedTeamMember]);
    return (
        <div className={styles.whiteFullBody}>
            <div className={styles.cardHeader}>Team Member Details</div>
            {companyValues?.length === 0 ? (
                <div className={styles.noCompanyValueText}>Please add Company Values prior to entering team member information.</div>
            ) : (
                <>
                    <div className={styles.teamMemberBody}>
                        <div className="row mb-4">
                            <div className="col-sm-12 col-md-6">
                                <label className={styles.inputLabel}>Full Name</label>
                                <input name="member_name" value={formValues?.member_name} onChange={handleChange} className={'form-control ' + styles.inputField} />
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-sm-12 col-md-6">
                                <label className={styles.inputLabel}>Department</label>
                                <input name="member_department" value={formValues?.member_department} onChange={handleChange} className={'form-control ' + styles.inputField} />
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className={styles.inputLabel}>Role</label>
                                <input name="member_role" value={formValues?.member_role} onChange={handleChange} className={'form-control ' + styles.inputField} />
                            </div>
                        </div>
                    </div>
                    <div className={styles.companyValuesBody}>
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <div className={styles.compValueHeader}>Company Values</div>
                                {companyValues?.map(item => (
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <span className={styles.selectLabel}>{item?.company_value}</span>
                                            <p className={styles.selectDesc}>{item?.value_description}</p>
                                        </div>
                                        <select
                                            onChange={e => handleChangeCompanyValue(e, item?.id)}
                                            value={companyFormValues?.find(cv => cv?.team_value === item?.id)?.value_score}
                                            className={'form-select ' + styles.selectInput}>
                                            <option>Select</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                            <option value="Unsure">Unsure</option>
                                        </select>
                                    </div>
                                ))}
                            </div>
                            <div className="col-sm-12 col-md-6 ps-5">
                                <div className={styles.compValueHeader}>Role Alignment</div>
                                <div className="d-flex">
                                    <div>
                                        <span className={styles.selectLabel}>Grasps it</span>
                                        <p className={styles.selectDesc}>
                                            Does the team member exhibit a thorough understanding of the intricacies and duties associated with their position?
                                        </p>
                                    </div>
                                    <select name="grasp_alignment" value={formValues?.grasp_alignment} onChange={handleChange} className={'form-select ' + styles.selectInput}>
                                        <option>Select</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                        <option value="Unsure">Unsure</option>
                                    </select>
                                </div>
                                <div className="d-flex">
                                    <div>
                                        <span className={styles.selectLabel}>Desires it</span>
                                        <p className={styles.selectDesc}>
                                            Is the team member motivated by a genuine, self-directed interest in performing their job effectively, regardless of external factors?
                                        </p>
                                    </div>
                                    <select name="desire_alignment" value={formValues?.desire_alignment} onChange={handleChange} className={'form-select ' + styles.selectInput}>
                                        <option>Select</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                        <option value="Unsure">Unsure</option>
                                    </select>
                                </div>
                                <div className="d-flex">
                                    <div>
                                        <span className={styles.selectLabel}>Has competency</span>
                                        <p className={styles.selectDesc}>
                                            Does the team member possess the necessary mental, physical, spiritual, time, knowledge, and emotional capability to fulfill the
                                            requirements of their role?
                                        </p>
                                    </div>
                                    <select
                                        name="competency_alignment"
                                        value={formValues?.competency_alignment}
                                        onChange={handleChange}
                                        className={'form-select ' + styles.selectInput}>
                                        <option>Select</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                        <option value="Unsure">Unsure</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="mt-3 d-flex align-items-center">
                            <button disabled={checkButton()} className={styles.saveBtn} onClick={handleSubmit}>
                                {selectedTeamMember ? 'Save' : 'Save and add another'}
                                {buttonLoading && <AiOutlineLoading3Quarters className="rotateLoader" />}
                            </button>
                            <span
                                className={styles.textBtn + ' ms-5'}
                                onClick={() => {
                                    setSelectedMenu('scores');
                                    setSelectedTeamMember();
                                }}
                                style={{ pointerEvents: teamAnalysisMembers?.length === 0 && 'none', color: teamAnalysisMembers?.length === 0 && 'var(--Neutral-Gray-6)' }}>
                                {selectedTeamMember ? 'Cancel' : 'View Team Scores'}
                            </span>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};
const TeamScores = ({ setSelectedTeamMember, setSelectedMenu, selectedTeamMember }) => {
    const { teamAnalysisMembers } = useSelector(state => state.team);
    const [departmentSort, setDepartmentSort] = React.useState('asc');
    const [chartMenu, setChartMenu] = React.useState('Company-Wide');
    const [scoreSort, setScoreSort] = React.useState('asc');
    const [filteredData, setFilteredData] = React.useState([]);
    const deleteBtnRef = React.useRef();
    const dispatch = useDispatch();
    const [chartData, setChartData] = useState({
        series: [0, 0, 0],
        options: {
            chart: {
                type: 'donut',
            },
            legend: {
                show: false, // Hide the legend
            },
            dataLabels: {
                enabled: false, // Hide data labels (text) inside the chart
            },
            colors: ['#198754', '#ffc107', '#b00020'],
        },
    });
    const renderRagCircle = color => {
        return (
            <div style={{ border: `1px solid ${color}` }} className={styles.ragCircle}>
                <div style={{ background: color }} className={styles.ragCircleInside}></div>
            </div>
        );
    };
    const sortByMemberDepartment = () => {
        // Toggle the sorting order
        const newSortOrder = departmentSort === 'asc' ? 'desc' : 'asc';
        setDepartmentSort(newSortOrder);
        // Sort the data based on the updated sorting order
        const data = [...teamAnalysisMembers];
        data.sort((a, b) => {
            const nameA = a?.member_department?.toUpperCase(); // ignore upper and lowercase
            const nameB = b?.member_department?.toUpperCase(); // ignore upper and lowercase
            if (departmentSort === 'asc') {
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            } else {
                if (nameA > nameB) {
                    return -1;
                }
                if (nameA < nameB) {
                    return 1;
                }
                return 0;
            }
        });
        setFilteredData([...data]);
    };
    const sortByMemberScore = () => {
        // Toggle the sorting order
        const newSortOrder = scoreSort === 'asc' ? 'desc' : 'asc';
        setScoreSort(newSortOrder);
        // Sort the data based on the updated sorting order
        const data = [...teamAnalysisMembers];
        data.sort((a, b) => {
            const nameA = a?.score?.toUpperCase(); // ignore upper and lowercase
            const nameB = b?.score?.toUpperCase(); // ignore upper and lowercase
            if (scoreSort === 'asc') {
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            } else {
                if (nameA > nameB) {
                    return -1;
                }
                if (nameA < nameB) {
                    return 1;
                }
                return 0;
            }
        });
        setFilteredData([...data]);
    };
    function getAllDepartments() {
        const departmentsSet = new Set(); // Using Set to ensure unique department names

        // Iterate over team members and collect department names
        teamAnalysisMembers.forEach(member => {
            departmentsSet.add(member.member_department);
        });

        return Array.from(departmentsSet); // Convert Set to array
    }
    React.useEffect(() => {
        // Filter data based on chartMenu
        const filteredData = chartMenu === 'Company-Wide' ? teamAnalysisMembers : teamAnalysisMembers.filter(item => item?.member_department === chartMenu);
        // Calculate scores for each category (Developmental, Adequate, Exemplary)
        const scores = filteredData.reduce(
            (acc, member) => {
                if (member.score === 'Developmental') {
                    acc[2]++;
                } else if (member.score === 'Adequate') {
                    acc[1]++;
                } else if (member.score === 'Exemplary') {
                    acc[0]++;
                }
                return acc;
            },
            [0, 0, 0],
        );

        // Generate tooltip content
        const tooltipContent = props => {
            const { seriesIndex } = props;
            const scoreLabels = ['Exemplary', 'Adequate', 'Developmental'];
            const selectedMenu = document.getElementById('selectedMenu').innerText;
            let membersArray = [];
            const elements = document?.getElementsByClassName('table_' + selectedMenu);
            for (let i = 0; i < elements.length; i++) {
                const element = elements[i];
                const memberScore = element?.id?.split('member_score_')[1];
                if (memberScore === scoreLabels[seriesIndex]) membersArray.push(element.innerText);
            }
            return `<div style='border-radius:4px;background:white;padding:8px 12px;color:black;width:153px'>
                <p style="color: var(--Brand-Primary); font-family: Inter; font-size: 12px; font-style: normal; font-weight: 700; line-height: 120%;margin-bottom:5px">${
                    scoreLabels[seriesIndex]
                }</p>
                ${membersArray
                    .map(
                        item =>
                            `<p style="color: var(--Brand-Primary, #1B2149); font-family: Inter; font-size: 12px; font-style: normal; font-weight: 400; line-height: 120%;margin-bottom:0px">${item}</p>`,
                    )
                    .join('')}
            </div>`;
        };

        setChartData({
            series: scores,
            options: {
                chart: {
                    type: 'donut',
                },
                legend: {
                    show: false,
                },
                dataLabels: {
                    enabled: false,
                },
                tooltip: {
                    custom: tooltipContent,
                },
                colors: ['#198754', '#ffc107', '#b00020'],
            },
        });
    }, [teamAnalysisMembers, chartMenu]);

    React.useEffect(() => {
        setFilteredData(teamAnalysisMembers);
    }, [teamAnalysisMembers]);
    return (
        <div className="row">
            <div className="col-sm-12 col-md-9">
                <div style={{ height: '100%' }} className={styles.whiteFullBody}>
                    <div className={styles.cardHeader}>Your Team Scores</div>
                    <table className="w-100">
                        <thead>
                            <tr className={styles.tableRow}>
                                <td className={styles.headerCell}>Name</td>
                                <td className={styles.headerCell}>Role</td>
                                <td role="button" onClick={sortByMemberDepartment} className={styles.headerCell}>
                                    Department <ArrowSortUp16Regular color="var(--Neutral-Gray-6)" />
                                </td>
                                <td onClick={sortByMemberScore} role="button" className={styles.headerCell}>
                                    Score <ArrowSortUp16Regular color="var(--Neutral-Gray-6)" />
                                </td>
                                <td style={{ width: 20 }}></td>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData?.map(item => (
                                <tr className={styles.tableRow}>
                                    <td className={'table_Company-Wide table_' + item?.member_department} id={'member_score_' + item?.score}>
                                        {item?.member_name}
                                    </td>
                                    <td>{item?.member_role}</td>
                                    <td>{item?.member_department}</td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            {renderRagCircle(
                                                (item?.score === 'Developmental' && 'var(--RAG-Red)') ||
                                                    (item?.score === 'Adequate' && 'var(--RAG-Amber)') ||
                                                    (item?.score === 'Exemplary' && 'var(--RAG-Green)'),
                                            )}
                                            <span>{item?.score}</span>
                                        </div>
                                    </td>
                                    <td style={{ width: 20 }}>
                                        <Menu style={{ marginTop: '20px' }}>
                                            <MenuTrigger disableButtonEnhancement>
                                                <MoreVertical20Regular role="button" className={styles.tableKebab} />
                                            </MenuTrigger>
                                            <MenuPopover>
                                                <MenuList>
                                                    <MenuItem
                                                        onClick={() => {
                                                            setSelectedTeamMember(item);
                                                            setSelectedMenu('member');
                                                        }}>
                                                        Edit Team Member
                                                    </MenuItem>
                                                    <MenuDivider />
                                                    <MenuItem
                                                        onClick={() => {
                                                            setSelectedTeamMember(item);
                                                            deleteBtnRef?.current?.click();
                                                        }}>
                                                        Delete Team Member
                                                    </MenuItem>
                                                </MenuList>
                                            </MenuPopover>
                                        </Menu>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="col-sm-12 col-md-3 mt-2">
                <div className={styles.blueCard}>
                    <Menu style={{ marginTop: '20px' }}>
                        <MenuTrigger disableButtonEnhancement>
                            <div className="d-flex align-items-center mb-4" role="button">
                                <span className={styles.chartMenuText} id="selectedMenu">
                                    {chartMenu}
                                </span>
                                <ChevronDown20Regular className="ms-2" color="white" />
                            </div>
                        </MenuTrigger>
                        <MenuPopover>
                            <MenuList>
                                <MenuItem onClick={() => setChartMenu('Company-Wide')}>Company-Wide</MenuItem>
                                {getAllDepartments()?.map(item => (
                                    <MenuItem onClick={() => setChartMenu(item)} key={item}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </MenuPopover>
                    </Menu>
                    <div>
                        <ReactApexChart height={216} options={chartData.options} series={chartData.series} type="donut" />
                    </div>
                </div>
                <div className={styles.blueCard}>
                    <div>
                        <div className="d-flex align-items-center mb-4">
                            {renderRagCircle('var(--RAG-Red)')}
                            <p className={styles.blueCardLabel}> Developmental</p>
                        </div>
                        <p className={styles.blueCardDesc}>
                            This stage indicates that the team member may have challenges aligning with company values and meeting role expectations. There might be areas that need
                            improvement or clarification. It is advisable to address these concerns and work on development areas.
                        </p>
                    </div>
                    <div>
                        <div className="d-flex align-items-center mb-4">
                            {renderRagCircle('var(--RAG-Amber)')}
                            <p className={styles.blueCardLabel}> Adequate</p>
                        </div>
                        <p className={styles.blueCardDesc}>
                            In this stage, the team member shows moderate alignment with company values and role expectations. They exhibit some positive aspects but may still have
                            areas for improvement. Regular feedback and guidance can help further enhance their performance.
                        </p>
                    </div>
                    <div>
                        <div className="d-flex align-items-center mb-4">
                            {renderRagCircle('var(--RAG-Green)')}
                            <p className={styles.blueCardLabel}> Exemplary</p>
                        </div>
                        <p className={styles.blueCardDesc + ' m-0'}>
                            Team members in this stage exhibit strong alignment with company values and demonstrate competence in their roles. They are likely to be valuable
                            contributors who understand and embody the organisation's values. Regular acknowledgment and support can help them continue their positive impact on the
                            team.
                        </p>
                    </div>
                </div>
            </div>
            <DoubleConfirmationModal
                isMenuChild={true}
                handleAction={() => {
                    dispatch(DeleteTeamMemberAnalysis(selectedTeamMember?.id));
                    setSelectedTeamMember();
                }}
                message={'Deleting a team member will also result in the permanent removal of all assessments associated with it.'}>
                <div ref={deleteBtnRef}></div>
            </DoubleConfirmationModal>
        </div>
    );
};

export default TeamAnalyser;
