import axiosInstance from '../apiServices/axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { WIZARD } from '../apiServices/api_routes';
import { toast } from 'react-toastify';
// import store from './store';

export const GetWizardData = createAsyncThunk('GET_WIZZARD', async () => {
    const data = await axiosInstance.get(WIZARD + localStorage['project_id']);
    return data;
});

export const wizardSlice = createSlice({
    name: 'wizardSlice',
    initialState: {
        wizardData: {},
    },
    reducers: {
        updateWizardData: (state, { payload }) => {
            return { wizardData: payload };
        },
    },
    extraReducers: {
        // GetWizardData
        [GetWizardData.pending]: (state, action) => {
            state.status = 'loading';
        },
        [GetWizardData.fulfilled]: (state, action) => {
            state.status = 'success';
            state.wizardData = action?.payload?.data;
            // state.documentNda = action.payload?.data;
        },
        [GetWizardData.rejected]: (state, action) => {
            state.status = 'rejected';
        },
    },
});
export const { updateWizardData } = wizardSlice.actions;
export default wizardSlice.reducer;
