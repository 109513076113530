import React, { useMemo } from 'react';
import styles from './css/index.module.css';
import { Dismiss16Regular } from '@fluentui/react-icons';
import { useSelector } from 'react-redux';
import { formatDateDifferenc, getExtensionIcon, getFileSize, getFileTypeByExtension } from '../../utils/commonUtils';
import { format } from 'date-fns';

const FileInfoSidebar = ({ setFileInfoSidebar, setShowSidebar, selectedFolders, selectedFile, setSelectedFile }) => {
    const { projectUsersAll } = useSelector(state => state?.team);
    const selectedFolder = useMemo(() => {
        return selectedFolders?.length > 0 ? selectedFolders[selectedFolders.length - 1] : {};
    }, [selectedFolders]);

    const handleClose = () => {
        setFileInfoSidebar(false);
        setShowSidebar(false);
        setSelectedFile();
    };
    const getUserDetail = id => {
        let addedBy = projectUsersAll?.find(item => item?.value?.group_member_id === id);
        return (
            <>
                <p className={styles.smallFont + ' m-0'}>{addedBy?.value?.full_name || 'Project Admin'} </p>
                <p className={styles.smallFont + ' m-0'}>{addedBy?.value?.organisation}</p>
            </>
        );
    };
    const getUserNameByGroupMemberId = id => {
        let user = projectUsersAll?.find(item => item?.value?.group_member_id === id);
        return <p className={styles.smallFont + ' m-0'}>{user?.value?.full_name}</p>;
    };
    return (
        <div className={styles.sidebarWhite + ' p-0'}>
            <div className={styles.fileSidebarHeader}>
                <div className="d-flex">
                    {getExtensionIcon((selectedFile?.document || selectedFile?.document_link)?.split('.').pop(), 20, 20, selectedFile?.document_link)}
                    <div className={styles.fileInfoName}>
                        <p className="mb-2">
                            {selectedFile?.ws_folder_prefix + '.'} {selectedFile?.item_num}{' '}
                        </p>
                        <span> {selectedFile?.document_name}</span>
                    </div>
                </div>
                <Dismiss16Regular onClick={handleClose} role="button" />
            </div>
            <div className={styles.fileSidebarBody}>
                <div>
                    <label className={styles.fileSidebarLabel}>Type</label>
                    <p>{getFileTypeByExtension((selectedFile?.document || selectedFile?.document_link)?.split('.').pop())}</p>
                </div>
                <div>
                    <label className={styles.fileSidebarLabel}>File Size</label>
                    <p>{getFileSize(selectedFile?.metadata_storage_size)}</p>
                </div>
                <div>
                    <label className={styles.fileSidebarLabel}>Location</label>
                    <p>{selectedFile?.filePath}</p>
                </div>
                <div>
                    <label className={styles.fileSidebarLabel}>Uploaded on</label>
                    <p>
                        {selectedFile?.created && format(new Date(selectedFile?.created), 'dd/MM/yyyy')}{' '}
                        {selectedFile?.created && <span className={styles.fileSidebarLabel}>{selectedFile?.created && formatDateDifferenc(selectedFile?.created)}</span>}
                    </p>
                </div>
                <div>
                    <label className={styles.fileSidebarLabel}>Posted by</label>
                    <p>{getUserDetail(selectedFile?.upload_by)}</p>
                </div>
                {selectedFile?.metadata_author && (
                    <div>
                        <label className={styles.fileSidebarLabel}>Author</label>
                        <p>{selectedFile?.metadata_author}</p>
                    </div>
                )}
                {selectedFile?.read_by?.length > 0 && (
                    <div>
                        <label className={styles.fileSidebarLabel}>Read by</label>
                        {selectedFile?.read_by?.map(item => (
                            <p className="m-0">{getUserNameByGroupMemberId(item)}</p>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default FileInfoSidebar;
