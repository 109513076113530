import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../css/index.module.css';
import 'react-datepicker/dist/react-datepicker.css';
import Pageheader from '../../../utils/headerHelment';
import Header from '../../CommonComponents/BasicHeader';
import { useSelector } from 'react-redux';

function NDASuccess() {
    const { user } = useSelector(state => state.user);
    const navigate = useNavigate();

    const handleContinue = e => {
        navigate({ pathname: '/AddProjectDetails' });
    };

    return (
        <div>
            <Pageheader title="LENS" />
            <Header />
            <div className="bg-light full-height-cls">
                <div className="container">
                    <div className={` ${styles.viewHeight}`}>
                        <div className={`${styles.formBody} `}>
                            <div className="row ">
                                <div className="col-lg-5">
                                    <div className={styles.titleBody}>
                                        <h2 style={{ marginTop: '15px', marginBottom: '-9px' }} className={styles.headingText}>
                                            Project Successfully Created
                                        </h2>
                                    </div>
                                    <p className="mb-5">We will inform you on {user?.email} when the NDA is duly signed by Beyond M&A.</p>
                                    <div className="row mt-2">
                                        <div className="col-6">
                                            <button type="submit" onClick={handleContinue} className="btn btn-primary w-100">
                                                Add Project Details
                                            </button>
                                        </div>
                                        <div className="col-6 align-self-center text-end">
                                            <a onClick={() => navigate('/project')} href="">
                                                <strong>Go to Dashboard</strong>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <script src="js/jquery-3.6.0.min.js" />
            <script src="js/bootstrap.bundle.min.js" />
            <script src="js/all.min.js" />
            <script src="js/bootstrap-datepicker.min.js" />
            <script src="js/app.js" />
        </div>
    );
}

export default NDASuccess;
