import React from 'react';

import styles from './css/MessageList.module.css';
import { ReadThread, UnReadThread } from './Thread';
import { useDispatch, useSelector } from 'react-redux';
import { GetInboxMessages } from '../../redux/messagesSlice';

const MessagesList = ({ setIsNewMessage }) => {
    const { inboxMessages } = useSelector(state => state?.messages);
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(GetInboxMessages());
    }, []);
    return (
        <div className={styles.listBody}>
            <h5 className={styles.title}>Messages</h5>
            <div className="input-group">
                <input type="text" placeholder="Search" className="form-control" aria-label="Dollar amount (with dot and two decimal places)" />
                <span className={`input-group-text ${styles.fieldIconBody}`}>
                    <span className="fa fa-search"></span>
                </span>
            </div>
            <div className="mt-4">
                {inboxMessages.length > 0 ? (
                    inboxMessages?.map(item => {
                        if (item?.total_unread > 0) return <UnReadThread setIsNewMessage={setIsNewMessage} data={item} />;
                        else return <ReadThread setIsNewMessage={setIsNewMessage} bookmark={true} data={item} />;
                    })
                ) : (
                    <p className="mt-2 ">
                        {' '}
                        <b>You have no messages.</b>
                    </p>
                )}
            </div>
        </div>
    );
};

export default MessagesList;
