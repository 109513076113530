import React, { Fragment } from 'react';
import { GetAllGroups } from '../../redux/teamSlice';
import { useDispatch, useSelector } from 'react-redux';
import styles from './css/index.module.css';
import { GetProjectUsers } from '../../redux/teamSlice';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { RiEditBoxLine } from 'react-icons/ri';
import { BsThreeDotsVertical } from 'react-icons/bs';

import { Offcanvas } from 'react-bootstrap';
import { GetWorkstreams, GetWorkstreamsDefaults } from '../../redux/dataroomSlice';
import { HiOutlineUserCircle } from 'react-icons/hi';

const Main = props => {
    const dispatch = useDispatch();
    const { selectedProject } = useSelector(state => state.projectData);
    const [addUserVisible, setAddUserVisible] = React.useState(false);
    const [editUserVisible, setEditUserVisible] = React.useState(false);

    React.useEffect(() => {
        if (selectedProject) {
            dispatch(GetProjectUsers());
            dispatch(GetAllGroups());
            dispatch(GetWorkstreams());
            dispatch(GetWorkstreamsDefaults());
        }
    }, [selectedProject, dispatch]);
    return (
        <div className={styles.container}>
            <div className={'d-flex justify-content-between align-items-center ' + styles.headerBody}>
                <h3 className={styles.targetHeading + ' m-0 page-title '}>{selectedProject?.project_name} Discovery Team </h3>
                <div>
                    <button className={'btn btn-primary  smallButton  btn-sm me-4 ' + styles.borderBtn}>Add NDA</button>
                    <button onClick={() => setAddUserVisible(true)} className={'btn btn-primary   btn-sm ' + styles.smallButton}>
                        Add New User
                    </button>
                </div>
            </div>
            {/* <p className={styles.noDataText}>There are no users in your team</p> */}
            <div className="row">
                {[1, 2, 3, 4, 5].map(item => (
                    <div className={' col-sm-12 col-md-4 mb-2'}>
                        <div className={styles.contentCard}>
                            <div className="d-flex ">
                                <HiOutlineUserCircle size={29} />

                                <div className="ms-3">
                                    <p className={styles.contentCardTitle}>johndoe@mail.com </p>
                                    <div className="m-0">
                                        {/* <span className={styles.invitedText}>Invited</span>
                                <span className={styles.invitedText}>Invitation Expired</span> */}
                                        <p className={styles.contentCardSub + ' m-0'}>Job Title </p>
                                        <p className={styles.contentCardSub + ' m-0'}>Organisation </p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles?.dropdown}>
                                <BsThreeDotsVertical color="var(--muted)" />
                                <div className={styles.hoverMenuContent}>
                                    <div
                                        style={{
                                            width: '224px',
                                            top: 20,
                                        }}
                                        className={styles.hoverMenuCont}>
                                        <div onClick={() => setEditUserVisible(true)} className={styles.wsChipMenuItem} style={{ color: 'var(--primary)' }}>
                                            <RiEditBoxLine size={15} className="me-2" />
                                            <span> Edit User</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <AddUserSidebar show={addUserVisible} setShow={setAddUserVisible} />
            <EditUserSidebar show={editUserVisible} setShow={setEditUserVisible} />
        </div>
    );
};
const AddUserSidebar = ({ show, setShow }) => {
    const { permissionsBtnLoading } = useSelector(state => state.team);
    const { selectedProject } = useSelector(state => state.projectData);
    const [emails, setEmails] = React.useState([]);
    const [emailInputValue, setEmailInputValue] = React.useState('');
    const emailInputRef = React.useRef(null);
    const [showEmailValidation, setShowEmailValidation] = React.useState(false);
    const [selectedRole, setSelectedRole] = React.useState('');
    const handleClose = () => {
        setShow(false);
        setEmails([]);
    };
    const handleSubmit = async () => {
        let body = {
            project: localStorage['project_id'],
            emails,
            // acls: group?.acl,
            vdr: selectedProject?.vdr?.id,
        };
        // await dispatch(InviteTeamMember(body));
        handleClose();
    };

    const handleInputChange = event => {
        setEmailInputValue(event.target.value);
    };

    const handleInputKeyDown = event => {
        if (event.key === 'Enter' || event.key === ',') {
            setShowEmailValidation(true);
            event.preventDefault();
            if (isEmail(emailInputValue.trim())) {
                setShowEmailValidation(true);
                const newEmails = [...emails, emailInputValue.trim()];
                setEmails(newEmails);
                setEmailInputValue('');
            }
        } else if (event.key === 'Backspace' && emailInputValue === '') {
            const newEmails = [...emails];
            newEmails.pop();
            setEmails(newEmails);
        }
    };

    const handleRemoveEmail = index => {
        const newEmails = [...emails];
        newEmails.splice(index, 1);
        setEmails(newEmails);
    };
    const handleFocusEmailField = () => {
        emailInputRef.current.focus();
    };
    const handleOnBlurEmailField = e => {
        setShowEmailValidation(false);
        if (e?.target?.value?.length > 0) {
            if (isEmail(e.target?.value.trim())) {
                setEmails([...emails, e.target?.value]);
            }
            setEmailInputValue('');
        }
    };

    const isEmail = str => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(str);
    };

    return (
        <>
            <Offcanvas placement="end" show={show} onHide={handleClose}>
                <Offcanvas.Body className="p-4 add-user-sidebar">
                    <h4 className="mt-3 mb-0">{selectedProject?.project_name} Discovery Team</h4>
                    <b className="font-weight-bold mb-5 ">Add User</b>
                    <div className="mt-4">
                        <div className="mt-4 ">
                            <label className={'form-check-label me-4 bold mb-1 ' + styles.labelAddQuestion} htmlFor="flexRadioDefault1">
                                Email Address
                            </label>

                            <div className={styles.emailInputContainer} onClick={handleFocusEmailField}>
                                {emails.map((email, index) => (
                                    <div key={index} className={styles.emailChip}>
                                        <span className="text-truncate">{email}</span>
                                        <button type="button" className={styles.removeEmailButton} onClick={() => handleRemoveEmail(index)}>
                                            &times;
                                        </button>
                                    </div>
                                ))}
                                <input
                                    type="email"
                                    className={styles.emailInput}
                                    placeholder="Use comma to separate addresses"
                                    value={emailInputValue}
                                    onChange={handleInputChange}
                                    onKeyDown={handleInputKeyDown}
                                    ref={emailInputRef}
                                    onBlur={handleOnBlurEmailField}
                                />
                            </div>
                            {emailInputValue !== '' && showEmailValidation && !isEmail(emailInputValue.trim()) && (
                                <span className={styles.emailError}>Enter Valid Email Address</span>
                            )}
                        </div>
                        <div className={styles.smallFont + ' mt-4'}>
                            <p>
                                <b className={styles.smallFont}>Select Role</b>
                            </p>
                            <di className="mt-4">
                                {['Lead Consultant', 'Consultant', 'Observer']?.map(item => (
                                    <div className={styles.permissionChecBody + ' justify-content-between'}>
                                        <span>{item}</span>
                                        <input type="checkbox" className={styles.roundCheckbox} checked={item === selectedRole} onClick={() => setSelectedRole(item)} />
                                    </div>
                                ))}
                            </di>
                        </div>

                        <div className="d-flex justify-content-between align-items-center mt-5 send-mail-step">
                            <button disabled={emails?.length === 0 || !selectedRole} onClick={handleSubmit} className={'btn btn-primary smallButton'}>
                                Add &nbsp;{permissionsBtnLoading && <AiOutlineLoading3Quarters className="rotateLoader" />}
                            </button>

                            <p className={styles.orangeBtnText + ' ' + styles.smallFont} onClick={handleClose}>
                                Cancel
                            </p>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};
const EditUserSidebar = ({ show, setShow }) => {
    const { permissionsBtnLoading } = useSelector(state => state.team);
    const { selectedProject } = useSelector(state => state.projectData);
    const [emails, setEmails] = React.useState([]);
    const [selectedRole, setSelectedRole] = React.useState('');
    const handleClose = () => {
        setShow(false);
        setEmails([]);
    };
    const handleSubmit = async () => {
        let body = {
            project: localStorage['project_id'],
            emails,
            // acls: group?.acl,
            vdr: selectedProject?.vdr?.id,
        };
        // await dispatch(InviteTeamMember(body));
        handleClose();
    };

    return (
        <>
            <Offcanvas placement="end" show={show} onHide={handleClose}>
                <Offcanvas.Body className="p-4 add-user-sidebar">
                    <div className="d-flex align-items-center justify-content-between  mb-5">
                        <b className="font-weight-bold ">Edit User</b>
                        <span className={styles.smallFont + ' ' + styles.orangeBtnText}>Delete User</span>
                    </div>
                    <div style={{ lineHeight: '18px' }}>
                        <p className="m-0">Full Name User</p>
                        <i className={styles.smallFont}>Job Title</i>
                        <p>
                            <i className={styles.smallFont}>Organisation</i>
                        </p>
                    </div>
                    <div className="mt-4">
                        <div className={styles.smallFont + ' mt-4'}>
                            <p>
                                <b className={styles.smallFont}>Select Role</b>
                            </p>
                            <di className="mt-4">
                                {['Lead Consultant', 'Consultant', 'Observer']?.map(item => (
                                    <div className={styles.permissionChecBody + ' justify-content-between'}>
                                        <span>{item}</span>
                                        <input type="checkbox" className={styles.roundCheckbox} checked={item === selectedRole} onClick={() => setSelectedRole(item)} />
                                    </div>
                                ))}
                            </di>
                        </div>

                        <div className="d-flex justify-content-between align-items-center mt-5 send-mail-step">
                            <button disabled={emails?.length === 0 || !selectedRole} onClick={handleSubmit} className={'btn btn-primary smallButton'}>
                                Add &nbsp;{permissionsBtnLoading && <AiOutlineLoading3Quarters className="rotateLoader" />}
                            </button>

                            <p className={styles.orangeBtnText + ' ' + styles.smallFont} onClick={handleClose}>
                                Cancel
                            </p>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

export default Main;
