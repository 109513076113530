import React from 'react';
import styles from './css/index.module.css';
import { Input } from '@fluentui/react-components';
import { PatchProjectData } from '../../redux/projectDataSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
const AddBrandDetails = ({ compareObjectsValues }) => {
    const dispatch = useDispatch();

    const { projectDetailButtonLoading, selectedProject } = useSelector(state => state.projectData);
    const fileInputRef = React.useRef();
    const [formData, setFormData] = React.useState({
        linkedin: '',
        base_logo: '',
    });
    const [uploadedFile, setUploadedFile] = React.useState();

    const handleChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleSubmit = async () => {
        let data = new FormData();
        if (uploadedFile) {
            data.append('logo', uploadedFile);
        }
        data.append('linkedin', formData?.linkedin);
        await dispatch(PatchProjectData({ data: data, id: selectedProject?.id }));
        setUploadedFile();
    };
    const handleDivClick = () => {
        fileInputRef.current.click();
    };
    const handleFileChange = e => {
        const selectedFiles = Array.from(e.target.files);
        console.log(selectedFiles);
        if (selectedFiles?.length > 0) {
            setUploadedFile(selectedFiles[0]);
            const reader = new FileReader();
            reader.onload = () => {
                const base64 = reader.result;
                setFormData({ ...formData, base_logo: base64 });
            };
            reader.readAsDataURL(selectedFiles[0]);
        }
    };
    // const handleUrlInputChange = async imageUrl => {
    //     try {
    //         const response = await fetch(imageUrl);
    //         const blob = await response.blob();
    //         // Get the content type from the response headers
    //         const contentType = response.headers.get('content-type');
    //         // Extract file extension from content type
    //         const extension = contentType.split('/')[1];
    //         // Create a unique filename
    //         const fileName = `image.${extension}`;
    //         const fetchedFile = new File([blob], fileName, { type: contentType });
    //         return fetchedFile;
    //     } catch (error) {
    //         console.error('Error fetching image:', error);
    //     }
    // };

    const handleSetFormValues = () => {
        setFormData({
            base_logo: selectedProject?.base_logo,
            linkedin: selectedProject?.linkedin,
        });
        setUploadedFile();
    };
    React.useEffect(() => {
        if (selectedProject) {
            handleSetFormValues();
        }
    }, [selectedProject]);

    return (
        <div className={styles.componentCard}>
            <h5 className={styles.componentTitle}>Brand details</h5>
            <div className="mb-4">
                <div className="d-flex align-items-center justify-content-between">
                    <p className={styles.fieldLabel}>Logo</p>
                    <span className={styles.linkBtn} onClick={handleDivClick}>
                        Change
                    </span>
                </div>
                <div onClick={handleDivClick} style={{ background: formData?.base_logo && 'white' }} className={styles.logoDiv + ' ' + styles.uploadBox}>
                    {formData?.base_logo ? (
                        <img className={styles.logoImage} alt={'logo'} src={formData?.base_logo} />
                    ) : (
                        <span>
                            Drag & drop file or
                            <br />
                            click to upload
                        </span>
                    )}

                    <input multiple type="file" accept={['jpeg', 'png', 'jpg'].join(',')} ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
                </div>
            </div>
            <div className="mb-4">
                <p className={styles.fieldLabel}>LinkedIn profile</p>
                <Input className={styles.inputField} value={formData?.linkedin} onChange={handleChange} name="linkedin" placeholder="www.linkedin.com/en-in/bootytraps" />
            </div>
            <div className={styles.buttonDiv}>
                {(!compareObjectsValues(selectedProject, formData) || uploadedFile) && (
                    <div className="d-flex align-items-center justify-content-between">
                        <button onClick={handleSubmit} className={styles.submitButton}>
                            Save
                            {projectDetailButtonLoading && <AiOutlineLoading3Quarters className="ms-2 rotateLoader" />}
                        </button>
                        <span onClick={handleSetFormValues} className={styles.linkBtn}>
                            Revert
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AddBrandDetails;
