import React from 'react';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import { Dropdown } from 'react-bootstrap';
import { BsCalendar2WeekFill } from 'react-icons/bs';
import { format } from 'date-fns';
const DatePicker = ({ value, onChange }) => {
    const [show, setShow] = React.useState(false);
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <div
            style={{
                width: '100%',
                border: '1px solid #ced4da',
                borderRadius: '4px',
                height: 38,
                background: 'white',
                display: 'flex',
                alignItems: 'center',
                // justifyContent: 'space-between',
                padding: 8,
            }}
            ref={ref}
            onClick={e => {
                e.preventDefault();
                setShow(true);
                onClick(e);
            }}>
            <BsCalendar2WeekFill color="rgba(130, 154, 177, 0.5)" />
            &nbsp;
            <span>{value && format(new Date(value), 'dd/MM/yyyy')}</span>
        </div>
    ));

    const handleChangeDate = e => {
        onChange(e);
        setShow(false);
    };
    return (
        <div className="date-picker-dropdown">
            <Dropdown onToggle={() => setShow(!show)} show={show}>
                <Dropdown.Toggle as={CustomToggle} id="dropdown-basic"></Dropdown.Toggle>
                <Dropdown.Menu style={{ transform: 'none' }} className="p-0 ">
                    <Calendar onChange={e => handleChangeDate(e)} value={value} />
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};
export default DatePicker;
