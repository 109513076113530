let wizardScreenData = [
    {
        folder: 'Business Overview',
        subFolders: [
            {
                name: 'Strategic Business plan',
                documentExamples: ['Executive Summary document', 'Business Model Canvas', 'Growth Strategy outline'],
                vdrLocation: 'IT01 - Business Overview',
                subFolder: 'Business Plan',
                preSeed: true,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Pitch Deck',
                documentExamples: ['Investor Presentation Slides', 'Competitive Landscape slides', 'Financial Projections slides'],
                vdrLocation: 'IT02 - Investment Rationale',
                subFolder: 'Pitch Deck',
                preSeed: true,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Company Overview / History',
                documentExamples: ['Timeline of Company Milestones', 'Press Release archives', 'Historical Financial Statements'],
                vdrLocation: 'IT01 - Business Overview',
                subFolder: 'Overview and History',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Registered office details',
                documentExamples: ['Lease or Rental Agreement', 'Utility Bill with Company Address', 'Property Tax Receipts'],
                vdrLocation: 'IT01 - Business Overview',
                subFolder: 'Registered Office',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Founder profiles',
                documentExamples: [
                    'Link to LinkedIn Profile, CV/Resume/Companies House Personal Appointments',
                    'Biographies and Accomplishments summaries',
                    'Press Articles or Interviews featuring Founders',
                    'Published Works or Patents by Founders',
                ],
                vdrLocation: 'IT01 - Business Overview',
                subFolder: 'Founder Profiles',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Group structure and list of subsidiaries',
                documentExamples: ['Companies House Filings', 'Endole profile', 'Regulatory Filings related to Structure'],
                vdrLocation: 'IT01 - Business Overview',
                subFolder: 'Group Structure',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Cap table information',
                documentExamples: ['Current and Historical Cap Tables', 'Investor Lists with Ownership Percentages', 'Option Pool Structures'],
                vdrLocation: 'IT01 - Business Overview',
                subFolder: 'Cap Table',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Past funding rounds including shareholder agreement and founder loan agreements',
                documentExamples: ['Investor Pitch and Feedback documents', 'Valuation Reports at Time of Funding', 'Due Diligence Reports from Past Rounds'],
                vdrLocation: 'IT01 - Business Overview',
                subFolder: 'Funding Rounds',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Shareholder agreements',
                documentExamples: ['Voting Rights Agreements', 'Pre-emptive Rights Clauses', 'Dividend Policy Documents'],
                vdrLocation: 'IT06 - Agreements',
                subFolder: 'Shareholder Agreements',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Consultancy agreements',
                documentExamples: ['NDAs, Signed Consultancy Agreements', 'Scope of Work documents', 'Service Level Agreements (SLAs)', 'Performance Review Records'],
                vdrLocation: 'IT06 - Agreements',
                subFolder: 'Consultancy Agreements',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Agreements with Advisors and other Connected Persons',
                documentExamples: [
                    'NDAs',
                    'Signed Contracts for Individuals, NEDs, Advisors etc.',
                    'Advisory Board Charter',
                    'Conflict of Interest Policies',
                    'Engagement and Exit Terms',
                ],
                vdrLocation: 'IT06 - Agreements',
                subFolder: 'Advisor Agreements',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Competitive Analysis',
                documentExamples: ['SWOT Analysis', 'Market Research', 'Industry Benchmarking Reports', 'Customer Segmentation Analysis', 'Product/Service Comparison Tables'],
                vdrLocation: 'C10 - Competitor Information',
                subFolder: 'Competitive Analysis',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Evidence of board minutes',
                documentExamples: [
                    'Evidence of regular board meetings',
                    'Evidence of Actions Taken (documents, Trello, Jira etc)',
                    'Signed and Dated Minute Books',
                    'Resolutions Passed by the Board',
                    'Board Meeting Attendance Records',
                ],
                vdrLocation: 'IT01 - Business Overview',
                subFolder: 'Board Meetings',
                preSeed: false,
                seriesA: false,
                corp: true,
            },
        ],
    },
    {
        folder: 'Product',
        subFolders: [
            {
                name: 'Product Demo',
                documentExamples: [
                    'Product Demo Videos/Links',
                    'Interactive Demo Environments',
                    'Customer Testimonials on Product Use',
                    'Feature Breakdown and Use Case Scenarios',
                ],
                vdrLocation: 'IT03 - Product Description',
                subFolder: 'Product Demo',
                preSeed: true,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Product Information',
                documentExamples: ['Sales material', 'Training Material', 'Product Specifications Sheets', 'User Guides and Manuals', 'Future Features and Enhancements Roadmap'],
                vdrLocation: 'IT03 - Product Description',
                subFolder: 'Product Information',
                preSeed: true,
                seriesA: true,
                corp: true,
            },
            {
                name: 'R&D Project Information',
                documentExamples: [
                    'R&D Project Management Assets',
                    'R&D Designs, Plans etc.',
                    'R&D Budgets and Allocation Reports',
                    'Patent Filings and Intellectual Property Records',
                    'Collaboration and Partnership Agreements on R&D',
                ],
                vdrLocation: 'IT13 - Innovation, R&D',
                subFolder: 'R&D Project Information',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'IP Rights',
                documentExamples: [
                    'IP Rights Assignments',
                    "Source Code Overview (location, repo's etc).",
                    'Software Licensing Agreements',
                    'Code Audit Reports',
                    'Compliance Certifications with Open Source Licenses',
                ],
                vdrLocation: 'IT06 - Agreements',
                subFolder: 'IP Rights',
                preSeed: true,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Application Architecture Overview',
                documentExamples: [
                    'Architecture Diagram, Open Source Components, Internal and External Interfaces, Third-party components. API Documentation and Specifications',
                    'Data Flow Diagrams',
                    'Security Protocol Descriptions',
                ],
                subFolder: 'Application Architecture Overview',
                vdrLocation: 'IT04 - Application Architecture',
                preSeed: true,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Infrastructure Architecture Overview',
                subFolder: 'Infrastructure Architecture Overview',
                documentExamples: [
                    'Infrastructure Diagram, DevOps Documentation, Hosting Information, Third Party Services, Performance, scaleability, resilience testing. Monitoring. Disaster Recovery Plans',
                    'Load Testing Results',
                    'Infrastructure as Code (IaC) Specifications',
                ],
                vdrLocation: 'IT05 - Infrastructure Architecture',
                preSeed: true,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Known Issues',
                documentExamples: [
                    'A document highlighted the known issues. Single Points of Failure. Capacity and Scaling Concerns. Risk Assessment Reports',
                    'Technical Debt Assessments',
                    'User Feedback and Issue Logs',
                ],
                vdrLocation: 'IT04 - Application Architecture',
                subFolder: 'Known Issues',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Public Cloud and Hosting Spend',
                documentExamples: [
                    'Export Costs from AWS, Google or Azure for the last 12 months. Detailed Monthly Billing Statements',
                    'Cost Optimization Analysis Reports',
                    'Breakdown of Costs by Service or Function',
                ],
                vdrLocation: 'IT17 - Budget & Spend',
                subFolder: 'Public Cloud and Hosting Spend',
                preSeed: true,
                seriesA: true,
                corp: true,
            },
        ],
    },
    {
        folder: 'Strategy & Planning',
        subFolders: [
            {
                name: 'Product and Technology Vision',
                documentExamples: [
                    'Document(s) highlighting your long term vision. Vision Statement and Charter',
                    "Founders' Letters or Interviews on Vision",
                    'Concept Videos or Illustrations depicting the Future Vision',
                ],
                vdrLocation: 'IT07- Strategy, plans & Process',
                subFolder: 'Product and Technology Vision',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Product and Technology Roadmap',
                documentExamples: [
                    'Screenshot of Kanban board, Formal Roadmap Presentation.  Historical Roadmap.  Screenshot from Roadmap products (e.g. Roadmunk/ProductBoard). Market Analysis and Trends Report influencing the Roadmap',
                    'Product Lifecycle Stages Documentation',
                    'Customer Feedback Summary influencing Product Development',
                ],
                vdrLocation: 'IT07- Strategy, plans & Process',
                subFolder: 'Product and Technology Roadmap',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Product and Technology Strategy',
                documentExamples: [
                    'Documentation of IT and Hosting Roadmap and Actions Taken. Technology Assessment and Platform Evaluation Reports',
                    'Competitive Technology Benchmarking',
                    'Technology Adoption Lifecycle Analysis',
                ],
                vdrLocation: 'IT07- Strategy, plans & Process',
                subFolder: 'Product and Technology Strategy',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Development Breakdown',
                documentExamples: [
                    'Any record of technology debt management, breakdown of effort (BAU/Innovation/Tech Debt). Quarterly or Annual Technology Health Checks',
                    'Code Refactoring Schedule and Impact Analysis',
                    'Technology Upgrade and Migration Plans',
                ],
                vdrLocation: 'IT07- Strategy, plans & Process',
                subFolder: 'Development Breakdown',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Project Plans',
                documentExamples: [
                    'Plans, Agile Ritual Documentation, Project Management assets, RAID, RACI. Detailed Sprint Schedules and Backlog Grooming Records',
                    'Change Management Process Documents',
                    'Cross-Functional Team Coordination Plans',
                ],
                vdrLocation: 'IT07- Strategy, plans & Process',
                subFolder: 'Project Plans',
                preSeed: false,
                seriesA: false,
                corp: true,
            },
        ],
    },
    {
        folder: 'Cyber Security',
        subFolders: [
            {
                name: 'Breach History',
                documentExamples: [
                    'Details and documentation of any cyber security breach, evidence of remediation, Reports to the ICO or other Governmental bodies. Legal documentation and correspondence.  Timeline of the breach events and response actions',
                    'Post-mortem analysis and future prevention plan',
                    'Communications to stakeholders regarding the breach',
                ],
                vdrLocation: 'IT09 - Security, DR & BCP',
                subFolder: 'Breach History',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Cyber Insurance',
                documentExamples: [
                    'Copies of current Cyber Insurance Policies. Policy coverage summaries and exclusions',
                    'Claims history and resolution outcomes',
                    'Insurance renewal discussions highlighting security improvements',
                ],
                vdrLocation: 'IT09 - Security, DR & BCP',
                subFolder: 'Cyber Insurance',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Pentests',
                documentExamples: [
                    'Copies of external PenTest Results and any Remediation undertaken. Executive summaries of PenTest findings',
                    'Risk prioritization and critical vulnerability reports',
                    'Historical PenTest trends over time',
                ],
                vdrLocation: 'IT09 - Security, DR & BCP',
                subFolder: 'Pentests',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Security Policies',
                documentExamples: [
                    'Information Security Policy.',
                    'Incident Response Plan.',
                    'Disaster Recovery and Business Continuity Plans.',
                    'Access Control Policies.',
                    'Data Encryption Policies.',
                ],
                vdrLocation: 'IT09 - Security, DR & BCP',
                subFolder: 'Security Policies',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Compliance',
                documentExamples: [
                    'ISO 27001, SOC 2, or other relevant certifications. Compliance roadmap and progress updates',
                    "Management's discussion and analysis on audit findings",
                    'Evidence of corrective actions taken post-audit',
                    'Audit reports (internal and third-party).',
                    'GDPR, CCPA, or other data privacy compliance documents.',
                ],
                vdrLocation: 'IT09 - Security, DR & BCP',
                subFolder: 'Compliance',
                preSeed: false,
                seriesA: false,
                corp: true,
            },
            {
                name: 'Risk Management',
                documentExamples: [
                    'Recent risk assessment reports. Asset and Data Classification Schemas',
                    'Threat Modeling Reports',
                    'Business Impact Analyses for Identified Risks',
                    'Risk management strategy documentation.',
                    'Vulnerability scans and penetration test results.',
                ],
                vdrLocation: 'IT09 - Security, DR & BCP',
                subFolder: 'Risk Management',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Employee Training',
                documentExamples: [
                    'Cybersecurity training materials. Phishing Simulation Statistics and Reports',
                    'Cybersecurity Newsletter or Bulletin',
                    'Security Champions Program Details',
                    'Records of employee training completion.',
                    'Security awareness campaign materials.',
                ],
                vdrLocation: 'IT09 - Security, DR & BCP',
                subFolder: 'Employee Training',
                preSeed: false,
                seriesA: false,
                corp: true,
            },
            {
                name: 'Security Configuration',
                documentExamples: [
                    'Security features of the SaaS product. Privacy Impact Assessment for the SaaS product',
                    'Third-Party Security Certifications for the Product',
                    'Security roadmap and future enhancement plans.',
                ],
                vdrLocation: 'IT09 - Security, DR & BCP',
                subFolder: 'Security Configuration',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Third Party Assessments',
                documentExamples: [
                    'Due diligence reports on critical vendors. Vendor Security Assessment Questionnaires and Responses',
                    'Third-Party Management Policy',
                    'Vendor Incident Response and Breach Notification Procedures',
                    'Contracts and SLAs with security clauses.',
                ],
                vdrLocation: 'IT09 - Security, DR & BCP',
                subFolder: 'Third Party Assessments',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
        ],
    },
    {
        folder: 'Data and Reporting',
        subFolders: [
            {
                name: 'Data Inventory and Mapping',
                documentExamples: [
                    'Comprehensive data maps showing what data is collected, stored, and processed.',
                    'Inventory lists detailing types and categories of data. E.g. ROPA.  Data monetization strategies, if data is re-sold or utilized externally. Data Processing Activity Logs',
                    'Third-party Data Sharing Agreements',
                    'Data Subject Access Request (DSAR) Logs',
                ],
                vdrLocation: 'IT10 - Data and reporting',
                subFolder: 'Data Inventory',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Databases and Storage',
                documentExamples: [
                    'Descriptions of the database technologies in use (e.g., SQL, NoSQL, In-Memory databases).',
                    'Architectural diagrams and explanations of database structures.',
                    'Details on data warehouses and data lakes, if applicable.  Database Performance Reports',
                    'Change Management Records for Database Technologies',
                ],
                vdrLocation: 'IT10 - Data and reporting',
                subFolder: 'Databases and Storage',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Analytics',
                documentExamples: [
                    'Licenses and configurations of tools used (such as Power BI, Tableau, etc.).',
                    'Information on custom-built internal tools or platforms.',
                    'Integration details with third-party services or APIs.',
                ],
                vdrLocation: 'IT10 - Data and reporting',
                subFolder: 'Analytics',
                preSeed: false,
                seriesA: false,
                corp: true,
            },
            {
                name: 'Quality',
                documentExamples: ['Policies and processes for ensuring data accuracy and integrity.', 'Records of data quality audits and clean-up efforts.'],
                vdrLocation: 'IT10 - Data and reporting',
                subFolder: 'Quality',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Compliance',
                documentExamples: [
                    'Data governance framework documentation.',
                    'Compliance with relevant data protection regulations (e.g., GDPR, HIPAA).',
                    'Data retention policies and procedures.',
                ],
                vdrLocation: 'IT10 - Data and reporting',
                subFolder: 'Compliance',
                preSeed: false,
                seriesA: false,
                corp: true,
            },
            {
                name: 'UAT',
                documentExamples: [
                    'Descriptions and protocols of UAT practices for data systems.',
                    'Documentation of the data flow between production and UAT environments.  Test Case Specifications and Results',
                    'Release Management Schedules',
                    'Environment Configuration and Management Documents',
                ],
                vdrLocation: 'IT10 - Data and reporting',
                subFolder: 'UAT',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Advanced Data Usage',
                documentExamples: [
                    'Security features of the SaaS product.',
                    'Security roadmap and future enhancement plans. Predictive Analytics Models and Performance Reports',
                    'Data Science Experimentation Frameworks',
                    'Machine Learning Model Governance Documents',
                ],
                vdrLocation: 'IT10 - Data and reporting',
                subFolder: 'Advanced Data Usage',
                preSeed: false,
                seriesA: false,
                corp: true,
            },
        ],
    },
    {
        folder: 'QA & Testing',
        subFolders: [
            {
                name: 'Quality Strategy',
                documentExamples: [
                    'Overviews of the company’s QA methodologies and the strategies employed. Quality Management System (QMS) Documentation',
                    'Risk-Based Testing Approach Documents',
                    'QA Scorecards and Metrics',
                ],
                vdrLocation: 'IT14 - QA & Testing',
                subFolder: 'Quality Strategy',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Testing Frameworks',
                documentExamples: [
                    'Descriptions of testing frameworks and environments used for software testing.  Test Suite Organization and Naming Conventions',
                    'Environment Setup and Configuration Guides',
                    'Integration and System Testing Guidelines',
                ],
                vdrLocation: 'IT14 - QA & Testing',
                subFolder: 'Testing Frameworks',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Test Plans',
                documentExamples: [
                    'Samples or references to comprehensive test plans and detailed test cases.  Traceability Matrix Linking Requirements to Test Cases',
                    'Test Case Prioritization and Scheduling',
                    'Defect Tracking and Resolution Workflows',
                ],
                vdrLocation: 'IT14 - QA & Testing',
                subFolder: 'Test Plans',
                preSeed: false,
                seriesA: false,
                corp: true,
            },
            {
                name: 'Automated Testing',
                documentExamples: [
                    'Documentation on automated testing processes and tools in use.  Automated Test Script Repository',
                    'Continuous Integration/Continuous Deployment (CI/CD) Pipeline Configurations',
                    'Automated Regression Test Results',
                ],
                vdrLocation: 'IT14 - QA & Testing',
                subFolder: 'Automated Testing',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Performance Testing',
                documentExamples: [
                    'Summaries of performance and load testing results. Stress Test Procedures and Thresholds',
                    'Scalability Test Reports',
                    'Benchmarking Against Industry Standards',
                ],
                vdrLocation: 'IT14 - QA & Testing',
                subFolder: 'Performance Testing',
                preSeed: false,
                seriesA: false,
                corp: true,
            },
            {
                name: 'UAT Testing',
                documentExamples: [
                    'Protocols for UAT and feedback incorporation from end-users. End-user Survey and Feedback Forms',
                    'UAT Sign-Off Documentation',
                    'UAT Scenario and Script Examples',
                ],
                vdrLocation: 'IT14 - QA & Testing',
                subFolder: 'UAT Testing',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
        ],
    },
    {
        folder: 'Change Management & DevOps',
        subFolders: [
            {
                name: 'Change Management',
                documentExamples: [
                    'Change Control Policy, Change Control Logs.  Rollback Procedures and Plans',
                    'Stakeholder Communication Plans during Changes',
                    'Post-Implementation Review Documents',
                ],
                vdrLocation: 'IT15 - Change Control & DevOps',
                subFolder: 'Change Management',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Incident Management',
                documentExamples: [
                    'Incident Management Process, Evidence of Incident Management Events, Lessons Learnt. Root Cause Analysis Reports',
                    'Incident Response Team Contact Lists and Roles',
                    'Service Restoration Procedures',
                ],
                vdrLocation: 'IT15 - Change Control & DevOps',
                subFolder: 'Incident Management',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Change Processes',
                documentExamples: [
                    'Application Development Process Description, Infrastructure Development Process Description, Approval Workflow Diagrams, Continuous Integration/Continuous Deployment (CI/CD) Process Details, Incident Management Process, Quality Assurance and Testing Procedures, Code Review Practices.  Deployment Plan Templates',
                    'Service Transition Plans and Checklists',
                    'Code Deployment Checklists and Standards',
                ],
                vdrLocation: 'IT15 - Change Control & DevOps',
                subFolder: 'Change Processes',
                preSeed: false,
                seriesA: false,
                corp: true,
            },
            {
                name: 'Risk Management',
                documentExamples: [
                    'Risk Management Policy, Risk Management Logs.  Risk Register and Risk Heat Maps',
                    'Risk Mitigation Strategies and Plans',
                    'Risk Reporting Templates and Examples',
                ],
                vdrLocation: 'IT15 - Change Control & DevOps',
                subFolder: 'Risk Management',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
        ],
    },
    {
        folder: 'Business Systems & IT',
        subFolders: [
            {
                name: 'IT Inventory',
                documentExamples: [
                    'Spreadsheet outlining IT hardware and Software in Use.  Asset Lifecycle Management Reports',
                    'Warranty and Service Contracts for IT Hardware',
                    'Software License Compliance Reports',
                ],
                vdrLocation: 'IT18 - BackOffice IT & Apps',
                subFolder: 'IT Inventory',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'IT Costs',
                documentExamples: [
                    'Spreadsheet outlining IT spend on hardware and software including Cloud Costs. Cost-Benefit Analysis of IT Investments',
                    'IT Cost Allocation to Various Departments',
                    'Historical IT Expenditure Trends',
                ],
                vdrLocation: 'IT17 - Budget & Spend',
                subFolder: 'IT Costs',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'IT Vendor Agreement Breakdown',
                documentExamples: [
                    "Spreadsheet of all current Back Office IT Vendor's Agreements. Vendor Performance and Service Level Review Reports",
                    'Vendor Renewal Strategy Documents',
                    'Vendor Relationship Management Policies',
                ],
                vdrLocation: 'IT17 - Budget & Spend',
                subFolder: 'IT Vendor Agreement Breakdown',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'IT Vendor Agreements and Invoices',
                documentExamples: [
                    'Master Service Agreements, Contract Amendments, Evidence of Spend (Invoices). Vendor Scorecards and Ratings',
                    'Vendor Negotiation and Onboarding Process Documents',
                    'Audit Reports of Vendor Billing',
                ],
                vdrLocation: 'IT18 - BackOffice IT & Apps',
                subFolder: 'IT Vendor Agreements and Invoices',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'IT Networking Information',
                documentExamples: [
                    'Network Architecture Diagrams, Configuration Documentation. Security Patch Management Schedules',
                    'Network Performance Reports and Analytics',
                    'Disaster Recovery Network Diagrams',
                ],
                vdrLocation: 'IT18 - BackOffice IT & Apps',
                subFolder: 'IT Networking Information',
                preSeed: false,
                seriesA: false,
                corp: true,
            },
            {
                name: 'End User Computing Provisioning & Management',
                documentExamples: [
                    'Laptop/Desktop Inventory including model, age and specification, Mobile Device Management Screenshots, Anti-Virus. Asset Provisioning and Decommissioning Policies',
                    'User Access Management Procedures',
                    'Technology Refresh Plans',
                ],
                vdrLocation: 'IT18 - BackOffice IT & Apps',
                subFolder: 'End User Computing Provisioning & Management',
                preSeed: false,
                seriesA: false,
                corp: true,
            },
            {
                name: 'Back Office and Order to Cash Systems',
                documentExamples: [
                    'Overviews of CRM, ERP, Accounts Payable Systems.  User Guides.  Integration Guides. System Integration Test Reports',
                    'Process Flow Diagrams for Order to Cash Cycle',
                    'Training Materials for Back Office Systems',
                ],
                vdrLocation: 'IT18 - BackOffice IT & Apps',
                subFolder: 'Back Office and Order to Cash Systems',
                preSeed: false,
                seriesA: false,
                corp: true,
            },
            {
                name: 'IT MSP Agreements and Invoices',
                documentExamples: [
                    'IT MSP proposal, IT MSP SLA, Invoices, IT MSP Support Documentation / User Guides. Service Catalogs detailing MSP offerings',
                    'Quarterly Business Review presentations from MSP',
                    'Incident and Request Fulfillment Reports',
                ],
                vdrLocation: 'IT06 - Agreements',
                subFolder: 'IT MSP Agreements and Invoices',
                preSeed: false,
                seriesA: false,
                corp: true,
            },
        ],
    },
    {
        folder: 'Commercial Matters',
        subFolders: [
            {
                name: 'Customer and Supplier Data',
                documentExamples: ['Customer Satisfaction Survey Reports', 'Client Retention Rates and Strategies', 'Customer Account Management Plans'],
                vdrLocation: 'C09 - Customer and Supplier Data',
                subFolder: 'Client Data',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Sales and Marketing Data',
                documentExamples: ['Forecast Accuracy Reports and Trend Analysis', 'Sales Conversion Rate Statistics', 'Sales Team Performance Dashboards'],
                vdrLocation: 'C02 - Sales and Marketing Data',
                subFolder: 'Sales Pipeline',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Client References',
                documentExamples: ['Testimonials and Case Studies', 'Reference Call Scripts and Outcomes', 'Client Award and Recognition Lists'],
                vdrLocation: 'C09 - Customer and Supplier Data',
                subFolder: 'Client References',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Partnerships',
                documentExamples: ['Joint Venture Agreements and Memorandums of Understanding', 'Co-Marketing and Co-Sell Agreement Documents', 'Partnership Performance Reviews'],
                vdrLocation: 'C02 - Sales and Marketing Data',
                subFolder: 'Partnerships',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Competitor Information',
                documentExamples: ['Competitive Benchmarking Studies', 'Market Share Analysis Reports', 'Competitor Product Feature Comparisons'],
                vdrLocation: 'C10 - Competitor Information',
                subFolder: 'Competitor Research and Data',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Compliance and Procedures',
                documentExamples: ['Change History of Terms and Conditions', 'Legal Compliance Checks on Terms of Business', 'Customer Feedback on Terms of Business'],
                vdrLocation: 'L01 - Compliance and Procedures',
                subFolder: 'Terms and Conditions',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
        ],
    },
    {
        folder: 'Financial Matters',
        subFolders: [
            {
                name: 'Financial model',
                documentExamples: ['Cash Flow Projections and Scenarios', 'Break-even Analysis', 'Profit Margin Analysis'],
                vdrLocation: 'F03 - Forecasts and Projections',
                subFolder: 'Financial Model',
                preSeed: true,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Use of funds',
                documentExamples: ['Detailed Budget Allocations', 'Capital Expenditure Reports', 'Return on Investment Analysis'],
                vdrLocation: 'F03 - Forecasts and Projections',
                subFolder: 'Use of Funds',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Management accounts',
                documentExamples: ['Monthly or Quarterly Performance Reports', 'Variance Analysis Reports', 'KPI Dashboards'],
                vdrLocation: 'F04 - Monthly Management Reports',
                subFolder: 'Management Accounts',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Statutory accounts',
                documentExamples: ['Audited Financial Statements', "Directors' Report and Strategic Report", 'Notes to the Accounts'],
                vdrLocation: 'F04 - Monthly Management Reports',
                subFolder: 'Statutory Accounts',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Bank statements, balance, and details',
                documentExamples: ['Reconciliation Statements', 'Cash Flow Statements', 'Financial Position Summaries'],
                vdrLocation: 'F04 - Monthly Management Reports',
                subFolder: 'Banks Statements',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Dividends policy',
                documentExamples: ['Historical Dividend Payout Records', 'Dividend Yield Calculations', 'Board Resolutions on Dividend Declarations'],
                vdrLocation: 'F04 - Monthly Management Reports',
                subFolder: 'Dividends',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Details of off-balance sheet arrangements and liabilities',
                documentExamples: ['Contingent Liability Notes', 'Commitments and Guarantees Disclosures', 'Lease Obligations Summary'],
                vdrLocation: 'F04 - Monthly Management Reports',
                subFolder: 'Off Balance Sheet',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Details of all debt and any intercompany arrangements',
                documentExamples: ['Loan Amortization Schedules', 'Intercompany Agreements and Transfer Pricing Documentation', 'Debt Covenants Compliance Reports'],
                vdrLocation: 'F04 - Monthly Management Reports',
                subFolder: 'Debt and Intercompany Arrangements',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Ageing list of debtors and creditors',
                documentExamples: ['Credit Risk Analysis Reports', 'Provision for Bad Debts Documentation', 'Receivable Turnover Ratios'],
                vdrLocation: 'F04 - Monthly Management Reports',
                subFolder: 'Debtors & Creditors',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'SEIS application and assurance',
                documentExamples: ['HMRC Correspondence on SEIS/EIS Approval', 'Investor Declarations and Compliance Certificates', 'SEIS/EIS Investment Summaries'],
                vdrLocation: 'IT02 - Investment Rationale',
                subFolder: 'SEIS',
                preSeed: true,
                seriesA: false,
                corp: false,
            },
            {
                name: 'EIS application and assurance',
                documentExamples: ['HMRC Correspondence on SEIS/EIS Approval', 'Investor Declarations and Compliance Certificates', 'SEIS/EIS Investment Summaries'],
                vdrLocation: 'IT02 - Investment Rationale',
                subFolder: 'EIS',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Tax (including PAYE/NIC) and VAT (including VAT number)',
                documentExamples: ['Tax Returns and Payment Records', 'VAT Reconciliation and Submission Documents', 'PAYE/NIC Settlement Summaries'],
                vdrLocation: 'IT01 - Business Overview',
                subFolder: 'Tax Registration Documents',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'List of R&D claims',
                documentExamples: ['R&D Expenditure Breakdown', 'HMRC R&D Tax Credit Claim Forms', 'R&D Tax Credit Calculation Worksheets'],
                vdrLocation: 'IT13 - Innovation, R&D',
                subFolder: 'R&D Claims',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Details of any grants',
                documentExamples: ['Grant Award Letters', 'Grant Use Compliance Reports', 'Grant Impact Assessments'],
                vdrLocation: 'IT13 - Innovation, R&D',
                subFolder: 'Grants',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Details of suppliers, service agreements, and invoices',
                documentExamples: ['SLA Performance Review Reports', 'Supplier Audit Results', 'Procurement Strategy Documents'],
                vdrLocation: 'IT06 - Agreements',
                subFolder: 'Suppliers',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Details of Vendors, service agreements, and invoices',
                documentExamples: ['Vendor Onboarding and Evaluation Procedures', 'Multi-Year Contract Summaries', 'Vendor Performance Improvement Plans'],
                vdrLocation: 'IT06 - Agreements',
                subFolder: 'Vendors',
                preSeed: true,
                seriesA: true,
                corp: true,
            },
        ],
    },
    {
        folder: 'People Matters',
        subFolders: [
            {
                name: 'Details of board of directors and employees',
                documentExamples: ['Board Meeting Attendance Records', 'Directorship History and Conflict of Interest Declarations', 'Employee Demographics Report'],
                vdrLocation: 'IT11 - Organisation Structure & People',
                subFolder: 'Employees',
                preSeed: true,
                seriesA: true,
                corp: true,
            },
            {
                name: 'All employment contracts, including of directors',
                documentExamples: ['Summary of Key Contractual Terms and Conditions', 'Non-disclosure and Non-compete Agreements', 'Director Indemnity Agreements'],
                vdrLocation: 'IT11 - Organisation Structure & People',
                subFolder: 'Employment Contracts',
                preSeed: true,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Details of employee benefits and bonuses',
                documentExamples: ['Total Rewards Statement Templates', 'Benefit Plan Enrollment and Utilization Reports', 'Performance Incentive Plan Descriptions'],
                vdrLocation: 'IT11 - Organisation Structure & People',
                subFolder: 'Employee Benefits',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Company policies, including bribery, fraud, and expenses',
                documentExamples: ['Training Records on Ethics and Compliance', 'Expense Reimbursement Guidelines', 'Whistleblower Protection Policies'],
                vdrLocation: 'IT11 - Organisation Structure & People',
                subFolder: 'Company Policies',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Organisational Chart, including any gaps',
                documentExamples: ['Workforce Planning and Succession Planning Documents', 'Skill Matrix and Competency Frameworks', 'Diversity and Inclusion Metrics'],
                vdrLocation: 'IT11 - Organisation Structure & People',
                subFolder: 'Org Chart',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Hiring plan',
                documentExamples: ['Recruitment Goals and Objectives', 'Talent Acquisition Strategy', 'Forecast of Hiring Needs by Department'],
                vdrLocation: 'IT11 - Organisation Structure & People',
                subFolder: 'Hiring - Overview',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Job adverts',
                documentExamples: ['Recruitment Campaign Analysis', 'Candidate Sourcing Channels and Effectiveness Report', 'Employer Branding Materials'],
                vdrLocation: 'IT11 - Organisation Structure & People',
                subFolder: 'Hiring - Job Adverts',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Biographies of management and advisors',
                documentExamples: ['Leadership Team Performance Reviews', 'Advisor Engagement and Impact Reports', 'Professional Development Plans for Management'],
                vdrLocation: 'IT11 - Organisation Structure & People',
                subFolder: 'Management and Advisor Profiles',
                preSeed: false,
                seriesA: false,
                corp: true,
            },
            {
                name: 'Any departed employees, with explanations',
                documentExamples: ['Exit Interview Summaries', 'Turnover Rates and Trends Analysis', 'Offboarding Checklist and Procedures'],
                vdrLocation: 'IT11 - Organisation Structure & People',
                subFolder: 'Departed Employees',
                preSeed: false,
                seriesA: false,
                corp: true,
            },
            {
                name: 'Pension and sales commission arrangements',
                documentExamples: ['Pension Scheme Governance Reports', 'Sales Commission Structure and Payout Histories', 'Deferred Compensation Agreements'],
                vdrLocation: 'IT11 - Organisation Structure & People',
                subFolder: 'Commission Arrangements',
                preSeed: false,
                seriesA: false,
                corp: true,
            },
            {
                name: 'Details of any employees subject to immigration control',
                documentExamples: ['Work Permit and Visa Compliance Audits', 'Right to Work Verification Records', 'Immigration Status Reports'],
                vdrLocation: 'IT11 - Organisation Structure & People',
                subFolder: 'Immigration Controls',
                preSeed: false,
                seriesA: false,
                corp: true,
            },
            {
                name: 'Details of any option schemes, including issued documents, HMRC valuations, and vesting schedules',
                documentExamples: ['Equity Grant Letters and Acceptance Forms', 'Share Option Ledger', 'Black-Scholes Valuation Models'],
                vdrLocation: 'IT11 - Organisation Structure & People',
                subFolder: 'Options Schemes',
                preSeed: false,
                seriesA: false,
                corp: true,
            },
            {
                name: 'Details of outsourcing arrangements',
                documentExamples: ['Service Level Agreement Compliance Reports', 'Outsourced Functions Performance Metrics', 'Vendor Management Frameworks'],
                vdrLocation: 'IT06 - Agreements',
                subFolder: 'Outsourced Resource',
                preSeed: true,
                seriesA: true,
                corp: true,
            },
        ],
    },
    {
        folder: 'Regulatory Compliance Matters',
        subFolders: [
            {
                name: 'Trademarks & Patents',
                documentExamples: [
                    'IP Portfolio Summary and Status Reports',
                    'Licensing Agreements and Royalty Statements',
                    'Cease and Desist Records related to IP Infringements',
                ],
                vdrLocation: 'IT02 - Investment Rationale',
                subFolder: 'Trademarks & Patents',
                preSeed: true,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Domain Names',
                documentExamples: ['Domain Registration Receipts and Renewal Notices', 'Domain Dispute Resolutions', 'Web Domain Strategy and Management Policies'],
                vdrLocation: 'L06 - Intellectual Property',
                subFolder: 'Domain Names',
                preSeed: true,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Regulatory Approvals',
                documentExamples: ['Certifications from Regulatory Bodies', 'Compliance Roadmaps with Current and Upcoming Regulations', 'Product Compliance Certificates'],
                vdrLocation: 'L06 - Intellectual Property',
                subFolder: 'Regulatory Approvals',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Competition Law',
                documentExamples: [
                    'Training Records on Antitrust and Competition Law',
                    'Minutes of Meetings Discussing Competition Law Compliance',
                    'Compliance Officer Appointment and Role Description',
                ],
                vdrLocation: 'L06 - Intellectual Property',
                subFolder: 'Competition Law',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Legal Proceedings',
                documentExamples: ['Litigation Case Summaries', 'Legal Reserve Documentation', 'Settlement Agreements and Court Orders'],
                vdrLocation: 'L04 - Litigation, Disputes and Complaints',
                subFolder: 'Legal Proceedings',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                name: 'GDPR',
                documentExamples: ['Data Protection Impact Assessments (DPIA)', 'Data Processing Agreements (DPA)', 'GDPR Training and Awareness Records'],
                vdrLocation: 'IT09 - Security, DR & BCP',
                subFolder: 'GDPR',
                preSeed: true,
                seriesA: true,
                corp: true,
            },
        ],
    },
    {
        folder: 'Press Kit',
        subFolders: [
            {
                name: 'Company Logos',
                documentExamples: ['Brand Guidelines Document', 'High-Resolution Logo Files in Various Formats', 'Usage and Misuse Examples of the Company Logo'],
                vdrLocation: 'IT01 - Business Overview',
                subFolder: 'Company Logos',
                preSeed: true,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Press Releases',
                documentExamples: ['Editorial Calendar for Planned Releases', 'Templates for Press Release Formats', 'Analytics Reports on Press Release Engagement'],
                vdrLocation: 'L06 - Intellectual Property',
                subFolder: 'Press Releases',
                preSeed: true,
                seriesA: true,
                corp: true,
            },
            {
                name: 'Social Media Information',
                documentExamples: [
                    'Social Media Strategy and Policy Documents',
                    'Profile Bios and Overview for Company Social Accounts',
                    'Performance Metrics of Social Media Campaigns',
                ],
                vdrLocation: 'L06 - Intellectual Property',
                subFolder: 'Social Media Information',
                preSeed: true,
                seriesA: true,
                corp: true,
            },
        ],
    },
];

export default wizardScreenData;
