import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BsChevronDown, BsThreeDotsVertical } from 'react-icons/bs';
import styles from './css/index.module.css';
import { Dropdown, Modal } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import {
    GetInformationRequests,
    PostInformationRequests,
    PostInformationResponse,
    DeleteInformationResponse,
    PatchInformationRequests,
    DeleteInformationRequests,
    PostIRLComment,
    setJoyRideStepIndex,
    UpdateBulkInformationRequest,
    UploadCSVFile,
    FetchIrlCopilotResults,
} from '../../redux/questionBankSlice';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { format } from 'date-fns';
import { formatDateDifferenc, htmlToPlainText, setPriorityColor } from '../../utils/commonUtils';
import { AiFillMinusCircle, AiFillPlusCircle, AiOutlineLoading3Quarters } from 'react-icons/ai';
import { TiArrowUnsorted } from 'react-icons/ti';
import { BiFilterAlt } from 'react-icons/bi';
import { RiDeleteBin6Line, RiEditBoxLine, RiShareBoxLine } from 'react-icons/ri';
import { GetDataroomItemInfo, GetWorkstreams } from '../../redux/dataroomSlice';
import { FaRegImages } from 'react-icons/fa';
import { FileUploader } from 'react-drag-drop-files';
import { GetIRLDoc, GetSingleIRLDoc } from '../../redux/documentsSlice';
import { PDFViewer } from '../Dataroom';
import DoubleConfirmationModal from '../CommonComponents/DoubleConfirmationModal/DoubleConfirmationModal';
import { HiOutlineLightBulb } from 'react-icons/hi';
import { useNavigate } from 'react-router';
import { IoChevronBackCircleSharp, IoChevronForwardCircleSharp } from 'react-icons/io5';
import { DrawerBody, OverlayDrawer } from '@fluentui/react-components';
import { Dismiss20Regular, ArrowClockwise16Regular } from '@fluentui/react-icons';
import { Tooltip } from '@fluentui/react-components';
import { Wand16Regular, Wand20Regular, Wand48Regular, Add16Regular, AddCircle16Regular } from '@fluentui/react-icons';
import { ObservationFormSidebar } from '../Observations';
const Main = ({ setRunJoyRide, informationRequestModal, setInformationRequestModal }) => {
    const [addMgmtVisible, setAddMgmtVisible] = React.useState(false);
    const [showPdf, setShowPdf] = React.useState(false);
    const [pdfUrl, setPdfUrl] = React.useState('');
    const { selectedProject } = useSelector(state => state.projectData);
    const dispatch = useDispatch();
    React.useEffect(() => {
        // dispatch(GetDataroomItemInfo());
        dispatch(GetInformationRequests());
        dispatch(GetWorkstreams());
    }, [dispatch, selectedProject]);
    React.useEffect(() => {
        // Check the initial URL to determine whether to show the PDF viewer
        if (window.location.pathname === '/irl/pdf-viewer') {
            setShowPdf(true);
        }
        const handleLocationChange = () => {
            // Check the current URL and update the state accordingly
            if (window.location.pathname === '/irl/pdf-viewer') {
                setShowPdf(true);
            } else {
                setShowPdf(false);
            }
        };

        // Add a popstate event listener to handle back button clicks
        window.addEventListener('popstate', handleLocationChange);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('popstate', handleLocationChange);
        };
    }, []);
    return (
        <div>
            <div className={styles.headerSection + ' d-flex align-items-center'}>
                <h5 className={styles.headerTitle + ' m-0 irl-text'}>Information Request List</h5>
                <div
                    className={styles.tourText + ' d-flex align-items-center ms-4 view-tour'}
                    onClick={() => {
                        dispatch(setJoyRideStepIndex(0));
                        setRunJoyRide(true);
                    }}>
                    <HiOutlineLightBulb size={15} />
                    &nbsp; View Tour
                </div>
            </div>

            {/* {showPdf ? (
                <PDFViewer setShowPDF={setShowPdf} selectedPDF={{ document: pdfUrl, document_name: pdfUrl?.split('/').pop() }} />
            ) : (
                <>
                    <div className={styles.menuButton}>REQUESTS</div>
                    <div className={styles.tabViewBody}>
                        <IRLTable
                            setRunJoyRide={setRunJoyRide}
                            setShowPdf={setShowPdf}
                            setPdfUrl={setPdfUrl}
                            informationRequestModal={informationRequestModal}
                            setInformationRequestModal={setInformationRequestModal}
                        />
                    </div>
                </>
            )} */}
            <div className={styles.menuButton}>REQUESTS</div>
            <div className={styles.tabViewBody}>
                <IRLTable
                    setRunJoyRide={setRunJoyRide}
                    setShowPdf={setShowPdf}
                    setPdfUrl={setPdfUrl}
                    informationRequestModal={informationRequestModal}
                    setInformationRequestModal={setInformationRequestModal}
                />
            </div>

            <FullScreenPdf showPdf={showPdf} setShowPdf={setShowPdf} pdfUrl={pdfUrl} />
            <AddManagementQuestionSidebar show={addMgmtVisible} setShow={setAddMgmtVisible} />
        </div>
    );
};

const IRLTable = ({ setShowPdf, setPdfUrl, setRunJoyRide, informationRequestModal, setInformationRequestModal }) => {
    const [columnState, setColumnState] = React.useState({
        number: { label: 'Number', value: true },
        status: { label: 'Status', value: true },
        workstream: { label: 'Workstream', value: true },
        tag: { label: 'Tag', value: true },
        request: { label: 'Request', value: true },
        priority: { label: 'Priority', value: true },
        requestedOn: { label: 'Requested On', value: true },
        addedBy: { label: 'Added By', value: true },
        response: { label: 'Response', value: true },
        respondedOn: { label: 'Responded On', value: true },
        respondent: { label: 'Respondent', value: true },
        dataroom: { label: 'Dataroom', value: true },
    });
    const navigate = useNavigate();
    const [selectedRow, setSelectedRow] = React.useState();
    const { workstreams } = useSelector(state => state?.dataroom);
    const { user } = useSelector(state => state?.user);
    const { projectUsersAll } = useSelector(state => state.team);
    const { informationRequests, informationRequestsFilters, bulkDeleteBtnLoading, uploadCSVBtnLoading } = useSelector(state => state.questionBank);
    const [responseDetailVisible, setResponseDetailVisible] = React.useState(false);
    const [tableData, setTableData] = React.useState([]);
    const [filteredData, setFilteredData] = React.useState([]);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [sortDirection, setSortDirection] = React.useState('asc');
    const [tableFilters, setTableFilters] = React.useState({});
    const hiddenFileInput = React.useRef(null);
    const [editIRModal, setEditIRModal] = React.useState(false);
    const dispatch = useDispatch();
    const openRowViewDetail = row => {
        setSelectedRow(row);
        setResponseDetailVisible(true);
    };
    const handleSelectRows = id => {
        if (selectedRows?.find(x => x === id)) {
            setSelectedRows(selectedRows.filter(x => x !== id));
        } else {
            setSelectedRows([...selectedRows, id]);
        }
    };
    const handleSelectAllFilter = val => {
        if (val) {
            setSelectedRows(tableData?.map(tab => tab?.id));
        } else setSelectedRows([]);
    };

    const handleDeleteRows = async () => {
        await dispatch(DeleteInformationResponse({ ids: selectedRows }));
        setSelectedRows([]);
    };
    const handleSortRequested = () => {
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        var data = [...filteredData];
        data.sort((a, b) => {
            let da = new Date(a.created),
                db = new Date(b.created);
            return sortDirection === 'asc' ? db - da : da - db;
        });
        setFilteredData(data);
    };
    const handleSortResponded = () => {
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        var data = [...filteredData];
        data.sort((a, b) => {
            let da = a?.irresponse?.created ? new Date(a?.irresponse?.created) : 0;
            let db = b?.irresponse?.created ? new Date(b?.irresponse?.created) : 0;
            return sortDirection === 'asc' ? db - da : da - db;
        });
        setFilteredData(data);
    };
    const handleSortNumber = () => {
        // Toggle the sort direction by creating a copy of the state
        const updatedSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
        setSortDirection(updatedSortDirection);

        const data = [...filteredData];
        data.sort((a, b) => {
            const numberA = `${a?.prefix}.${a?.item}.${a?.sub_item}`;
            const numberB = `${b?.prefix}.${b?.item}.${b?.sub_item}`;

            // Compare the entire strings directly
            return updatedSortDirection === 'asc' ? numberA.localeCompare(numberB) : numberB.localeCompare(numberA);
        });

        setFilteredData(data);
    };
    const handleSortReferencedFile = () => {
        setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        const data = [...filteredData];

        data.sort((a, b) => {
            const hasReferencedFileA = !!a?.referenced_file;
            const hasReferencedFileB = !!b?.referenced_file;

            if (hasReferencedFileA !== hasReferencedFileB) {
                return sortDirection === 'asc' ? (hasReferencedFileA ? 1 : -1) : hasReferencedFileA ? -1 : 1;
            }
            return 0;
        });

        setFilteredData(data);
    };
    const handleOpenDRFile = async item => {
        if (item?.referenced_file) {
            let response = await dispatch(GetSingleIRLDoc({ wsId: item?.workstream, docId: item?.referenced_file }));
            if (response?.type === 'IRLDOC/getSingleIRLDoc/fulfilled') {
                setPdfUrl(response?.payload?.data?.document);
                setShowPdf(true);
                window.history.pushState(null, '', '/irl/pdf-viewer');
            }
        }
    };
    const checkChild = React.useCallback(item => {
        if (item?.parent_request) {
            return checkChild(item?.parent);
        } else {
            return item;
        }
    }, []);

    const FilterIcon = React.forwardRef(({ onClick }, ref) => (
        <BiFilterAlt
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
            className="me-2"
            color="#829ab1"
            size={14}
            role="button"
        />
    ));
    const handleChangeFilters = (label, category, value) => {
        let data = tableFilters;
        data[category] = data[category]?.map(item => {
            if (item.label === label) {
                item.value = value;
            }
            return item;
        });
        setTableFilters({ ...data });
        handleUpdateFilters(data);
    };
    const handleUpdateFilters = updatedFilters => {
        let data = tableData.filter(item => item?.active);
        if (updatedFilters?.workstream && updatedFilters?.workstream.length > 0) {
            updatedFilters?.workstream?.forEach(item => {
                if (!item.value) {
                    let itemData = workstreams?.find(t => t?.name === item?.label);
                    data = data.filter(d => d.workstream !== itemData?.id);
                }
            });
        }
        if (updatedFilters?.status && updatedFilters?.status.length > 0) {
            updatedFilters?.status?.forEach(item => {
                if (!item.value) {
                    if (item.label === 'Responded') {
                        data = data.filter(d => d.irresponse);
                    } else if (item.label === 'Pending') {
                        data = data.filter(d => !d.irresponse);
                    }
                }
            });
        }
        if (updatedFilters?.tag && updatedFilters?.tag.length > 0) {
            updatedFilters?.tag?.forEach(item => {
                if (!item.value) {
                    data = data.filter(d => d.tag !== item?.label);
                }
            });
        }
        if (updatedFilters?.priority && updatedFilters?.priority.length > 0) {
            updatedFilters?.priority?.forEach(item => {
                if (!item.value) {
                    data = data.filter(d => d.priorities !== item?.label);
                }
            });
        }

        if (updatedFilters?.assigned_to && updatedFilters?.assigned_to.length > 0) {
            updatedFilters?.assigned_to?.forEach(item => {
                if (!item.value) {
                    data = data.filter(d => d?.assigned_to !== item?.label);
                }
            });
        }
        if (updatedFilters?.added_by && updatedFilters?.added_by.length > 0) {
            updatedFilters?.added_by?.forEach(item => {
                if (!item.value) {
                    data = data.filter(d => d?.added_by !== item?.label);
                }
            });
        }
        if (updatedFilters?.respondent && updatedFilters?.respondent.length > 0) {
            updatedFilters?.respondent?.forEach(item => {
                if (!item.value) {
                    if (item?.label === 'No response') data = data.filter(d => d?.irresponse);
                    else data = data.filter(d => d?.irresponse?.responded_by !== item?.label);
                }
            });
        }

        setFilteredData([...data]);
    };
    const handleGetProjectName = id => {
        // let user = projectUsersAll.find(item => item.value.id === id);
        let user = projectUsersAll.find(item => item.value.group_member_id === id);
        return (
            <span>
                {user?.value?.full_name} ({user?.organisation})
            </span>
        );
    };
    const handleChangeBulkPriority = val => {
        dispatch(
            UpdateBulkInformationRequest({
                ids: selectedRows,
                priority: val,
            }),
        );
    };

    const handleUploadCSV = async e => {
        if (e.target.files?.length > 0) {
            let formData = new FormData();
            formData.append('file', e.target.files[0]);
            await dispatch(UploadCSVFile(formData));
            dispatch(GetInformationRequests());
        }
    };

    const PriorityToggle = React.forwardRef(({ onClick }, ref) => (
        <div
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
            style={{ width: 90 }}
            className={styles.bulkSelector + ' me-2'}>
            <span>Priority</span>
            &nbsp; &nbsp;
            <BsChevronDown size={10} color="var(--Brand-Secondary)" />
        </div>
    ));
    React.useEffect(() => {
        if (informationRequests) {
            let data = informationRequests;
            let parentItems = [];
            let childItems = [];
            parentItems = data.filter(item => !item?.parent_request);
            childItems = data.filter(item => item?.parent_request);
            childItems?.forEach(item => {
                let check = checkChild(item);
                parentItems = parentItems?.map(parent => {
                    if (parent?.id === check?.id) {
                        return { ...parent, childrens: parent?.childrens ? [...parent?.childrens, item] : [item] };
                    } else return parent;
                });
            });
            let arr = parentItems?.filter(item => item?.active);
            setTableData(arr);
            setFilteredData(arr);
        }
    }, [checkChild, informationRequests]);
    React.useEffect(() => {
        if (selectedRow) {
            setSelectedRow(informationRequests?.find(item => item?.id === selectedRow?.id));
        }
    }, [informationRequests]);
    React.useEffect(() => {
        let nf = {};
        if (informationRequestsFilters) {
            Object.keys(informationRequestsFilters)?.forEach(key => {
                if (typeof informationRequestsFilters[key] === 'object') {
                    let temp = [];

                    // Check if the current key is "status"
                    if (key === 'status') {
                        // Define the label values for "status"
                        const statusLabels = ['Responded', 'Pending'];

                        // Iterate through the labels and create objects
                        statusLabels.forEach(label => {
                            temp.push({
                                label: label,
                                category: key,
                                value: true,
                            });
                        });
                    } else {
                        // For other keys, iterate through the original values
                        informationRequestsFilters[key].forEach(item => {
                            temp.push({
                                label: item,
                                category: key,
                                value: true,
                            });
                        });
                    }

                    nf = {
                        ...nf,
                        [key?.replace(' ', '_')]: temp,
                    };
                }
            });
            setTableFilters(nf);
        }
    }, [informationRequestsFilters]);
    return (
        <div>
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <ColumnFilters columnState={columnState} setColumnState={setColumnState} />
                    <p className={'ms-3 ' + styles.questionsLength}>
                        Viewing{' '}
                        <b>
                            {filteredData?.length} of {tableData.length}
                        </b>{' '}
                        Questions
                    </p>
                </div>
                <div>
                    {selectedRows?.length > 0 ? (
                        <div className="d-flex align-items-center">
                            <IRLCoPilotSidebar selectedRows={selectedRows} />
                            <Dropdown>
                                <Dropdown.Toggle as={PriorityToggle} id="dropdown-basic"></Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => handleChangeBulkPriority('HIGH')}>High</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleChangeBulkPriority('MEDIUM')}>Medium</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleChangeBulkPriority('LOW')}>Low</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                            <DoubleConfirmationModal
                                handleAction={handleDeleteRows}
                                message="Deleting an Information Request will permanently remove it from the Project and this cannot be undone.">
                                <button type="button" className={`btn btn-primary btn-sm ${styles.responseButton} ${styles.smallButton}`}>
                                    Delete {bulkDeleteBtnLoading && <AiOutlineLoading3Quarters className="rotateLoader" />}
                                </button>
                            </DoubleConfirmationModal>
                        </div>
                    ) : (
                        <>
                            {user?.has_diligence_tools && (
                                <button
                                    onClick={() => navigate('/question-bank')}
                                    type="button"
                                    className={`btn btn-primary btn-sm   ${styles.responseButton} ${styles.smallButton}`}>
                                    Question Bank
                                </button>
                            )}
                            <button onClick={() => hiddenFileInput?.current?.click()} type="button" className={`btn btn-primary btn-sm add-irl-button ms-4 ${styles.smallButton}`}>
                                Upload From CSV {uploadCSVBtnLoading && <AiOutlineLoading3Quarters className="rotateLoader" />}
                            </button>
                            <input type="file" style={{ display: 'none' }} onChange={handleUploadCSV} accept=".xlsx,.xls" ref={hiddenFileInput} />

                            <button
                                onClick={() => {
                                    setSelectedRow();
                                    setInformationRequestModal(true);
                                    setTimeout(() => {
                                        dispatch(setJoyRideStepIndex(2));
                                    }, 300);
                                }}
                                type="button"
                                className={`btn btn-primary btn-sm add-irl-button ms-4 ${styles.smallButton}`}>
                                Add New Request
                            </button>
                        </>
                    )}
                </div>
            </div>
            <div className="table-step">
                {informationRequests?.length !== 0 ? (
                    <div className={`mt-3  ${styles.tableContainer} `} id="style-3-scroll">
                        <table className="w-100 ">
                            <thead className={styles.tableHeader}>
                                <tr>
                                    <td className="ps-2" style={{ minWidth: 40 }}>
                                        <input
                                            type="checkbox"
                                            checked={selectedRows?.length === tableData.length ? true : false}
                                            onChange={e => handleSelectAllFilter(e.target.checked)}
                                        />
                                    </td>
                                    {columnState?.number.value && (
                                        <td className="ps-2 " style={{ minWidth: 150 }}>
                                            Number &nbsp;
                                            <TiArrowUnsorted onClick={handleSortNumber} color="#829ab1" role="button" />
                                        </td>
                                    )}
                                    {columnState?.status.value && (
                                        <td className="ps-2 " style={{ minWidth: 100 }}>
                                            Status &nbsp;
                                            <Dropdown className="d-inline">
                                                <Dropdown.Toggle as={FilterIcon} id="dropdown-basic"></Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <div style={{ width: 150 }} className={styles.filterBody}>
                                                        <div className="row">
                                                            {tableFilters?.status?.map(item => (
                                                                <div key={item?.label} className="col-sm-12 d-flex align-items-center mt-2">
                                                                    <input
                                                                        checked={item?.value}
                                                                        onChange={e => handleChangeFilters(item?.label, 'status', e.target.checked)}
                                                                        type="checkbox"
                                                                        id="flexCheckDefault"
                                                                        className=" me-3"
                                                                    />
                                                                    <label className={styles.checkLabel + ' ' + styles.smallFont}>
                                                                        {item?.label ? item?.label?.toUpperCase() : 'None'}
                                                                    </label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    )}
                                    {columnState?.workstream.value && (
                                        <td className="ps-2 " style={{ minWidth: 150 }}>
                                            Workstream &nbsp;
                                            <Dropdown className="d-inline">
                                                <Dropdown.Toggle as={FilterIcon} id="dropdown-basic"></Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <div style={{ width: 250 }} className={styles.filterBody}>
                                                        <div className="row">
                                                            {tableFilters?.workstream?.map(item => (
                                                                <div key={item?.label} className="col-sm-12 d-flex align-items-center mt-2">
                                                                    <input
                                                                        checked={item?.value}
                                                                        onChange={e => handleChangeFilters(item?.label, 'workstream', e.target.checked)}
                                                                        type="checkbox"
                                                                        id="flexCheckDefault"
                                                                        className=" me-3"
                                                                    />
                                                                    <label className={styles.checkLabel + ' ' + styles.smallFont}>
                                                                        {item?.label ? item?.label?.toUpperCase() : 'None'}
                                                                    </label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    )}
                                    {columnState?.workstream.value && (
                                        <td className="ps-2 " style={{ minWidth: 150 }}>
                                            Tag &nbsp;
                                            <Dropdown className="d-inline">
                                                <Dropdown.Toggle as={FilterIcon} id="dropdown-basic"></Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <div style={{ width: 300 }} className={styles.filterBody}>
                                                        <div className="row">
                                                            {tableFilters?.tag?.map(item => (
                                                                <div key={item?.label} className="col-sm-12 d-flex align-items-center mt-2">
                                                                    <input
                                                                        checked={item?.value}
                                                                        onChange={e => handleChangeFilters(item?.label, 'tag', e.target.checked)}
                                                                        type="checkbox"
                                                                        id="flexCheckDefault"
                                                                        className=" me-3"
                                                                    />
                                                                    <label className={styles.checkLabel + ' ' + styles.smallFont}>
                                                                        {item?.label ? item?.label?.toUpperCase() : 'None'}
                                                                    </label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    )}

                                    {columnState?.request.value && (
                                        <td className="ps-2 " style={{ minWidth: 600 }}>
                                            Request
                                        </td>
                                    )}

                                    {columnState?.priority.value && (
                                        <td className="ps-2 " style={{ minWidth: 85 }}>
                                            Priority &nbsp;
                                            <Dropdown className="d-inline">
                                                <Dropdown.Toggle as={FilterIcon} id="dropdown-basic"></Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <div style={{ width: 120 }} className={styles.filterBody}>
                                                        <div className="row">
                                                            {tableFilters?.priority?.map(item => (
                                                                <div key={item?.label} className="col-sm-12 d-flex align-items-center mt-2">
                                                                    <input
                                                                        checked={item?.value}
                                                                        onChange={e => handleChangeFilters(item?.label, 'priority', e.target.checked)}
                                                                        type="checkbox"
                                                                        id="flexCheckDefault"
                                                                        className=" me-3"
                                                                    />
                                                                    <label className={styles.checkLabel + ' ' + styles.smallFont}>
                                                                        {item?.label ? item?.label?.toUpperCase() : 'None'}
                                                                    </label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    )}
                                    {columnState?.requestedOn.value && (
                                        <td className="ps-2 " style={{ minWidth: 130 }}>
                                            Requested On &nbsp;
                                            <TiArrowUnsorted onClick={handleSortRequested} color="#829ab1" role="button" />
                                        </td>
                                    )}

                                    {columnState?.addedBy.value && (
                                        <td className="ps-2 " style={{ minWidth: 130 }}>
                                            Added By &nbsp;
                                            <Dropdown className="d-inline">
                                                <Dropdown.Toggle as={FilterIcon} id="dropdown-basic"></Dropdown.Toggle>
                                                {tableFilters?.added_by?.length > 0 && (
                                                    <Dropdown.Menu>
                                                        <div style={{ width: 250 }} className={styles.filterBody}>
                                                            <div className="row">
                                                                {tableFilters?.added_by?.map(item => (
                                                                    <div key={item?.label} className="col-sm-12 d-flex align-items-center mt-2">
                                                                        <input
                                                                            checked={item?.value}
                                                                            onChange={e => handleChangeFilters(item?.label, 'added_by', e.target.checked)}
                                                                            type="checkbox"
                                                                            id="flexCheckDefault"
                                                                            className=" me-3"
                                                                        />
                                                                        <label className={styles.checkLabel + ' ' + styles.smallFont}>{handleGetProjectName(item?.label)}</label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </Dropdown.Menu>
                                                )}
                                            </Dropdown>
                                        </td>
                                    )}
                                    {columnState?.response.value && (
                                        <td className="ps-2 " style={{ minWidth: 300 }}>
                                            Response
                                        </td>
                                    )}

                                    {columnState?.respondedOn.value && (
                                        <td className="ps-2 " style={{ minWidth: 130 }}>
                                            Responded On &nbsp;
                                            <TiArrowUnsorted onClick={handleSortResponded} color="#829ab1" role="button" />
                                        </td>
                                    )}
                                    {columnState?.respondent.value && (
                                        <td className="ps-2 " style={{ minWidth: 130 }}>
                                            Respondent &nbsp;
                                            <Dropdown className="d-inline">
                                                <Dropdown.Toggle as={FilterIcon} id="dropdown-basic"></Dropdown.Toggle>
                                                {tableFilters?.respondent?.length > 0 && (
                                                    <Dropdown.Menu>
                                                        <div style={{ width: 250 }} className={styles.filterBody}>
                                                            <div className="row">
                                                                {tableFilters?.respondent?.map(item => (
                                                                    <div key={item?.label} className="col-sm-12 d-flex align-items-center mt-2">
                                                                        <input
                                                                            checked={item?.value}
                                                                            onChange={e => handleChangeFilters(item?.label, 'respondent', e.target.checked)}
                                                                            type="checkbox"
                                                                            id="flexCheckDefault"
                                                                            className=" me-3"
                                                                        />
                                                                        <label className={styles.checkLabel + ' ' + styles.smallFont}>
                                                                            {item?.label !== 'No response' ? handleGetProjectName(item?.label) : item?.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </Dropdown.Menu>
                                                )}
                                            </Dropdown>
                                        </td>
                                    )}
                                    {columnState?.dataroom.value && (
                                        <td className="ps-2 " style={{ minWidth: 60 }}>
                                            DR &nbsp;
                                            <TiArrowUnsorted onClick={handleSortReferencedFile} color="#829ab1" role="button" />
                                        </td>
                                    )}

                                    <th style={{ minWidth: 40 }}> &nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredData?.map(
                                    (item, index) =>
                                        item?.id &&
                                        item?.active && (
                                            <TableRow
                                                item={item}
                                                selectedRows={selectedRows}
                                                handleSelectRows={handleSelectRows}
                                                columnState={columnState}
                                                openRowViewDetail={openRowViewDetail}
                                                setSelectedRow={setSelectedRow}
                                                setEditIRModal={setEditIRModal}
                                                handleOpenDRFile={handleOpenDRFile}
                                                index={index}
                                            />
                                        ),
                                )}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div className={`mt-3 ${styles.tableContainer} d-flex align-items-center justify-content-center `}>
                        <h5>There are 0 Information Requests in this project</h5>
                    </div>
                )}
            </div>

            {responseDetailVisible && (
                <ResponseDetailSidebar
                    selectedRow={selectedRow}
                    setFollowUpVisible={setInformationRequestModal}
                    show={responseDetailVisible}
                    setShow={setResponseDetailVisible}
                    setSelectedRow={setSelectedRow}
                    setShowPdf={setShowPdf}
                    setPdfUrl={setPdfUrl}
                    setEditIRModal={setEditIRModal}
                    filteredData={filteredData}
                />
            )}

            <PostInformationRequestSidebar show={informationRequestModal} setShow={setInformationRequestModal} selectedRow={selectedRow} setRunJoyRide={setRunJoyRide} />
            <PatchInformationRequestSidebar show={editIRModal} setShow={setEditIRModal} selectedRow={selectedRow} setSelectedRow={setSelectedRow} />
        </div>
    );
};
const TableRow = ({ item, selectedRows, handleSelectRows, columnState, openRowViewDetail, setSelectedRow, setEditIRModal, handleOpenDRFile, index }) => {
    const dispatch = useDispatch();
    const { workstreams } = useSelector(state => state?.dataroom);
    const { projectUsersAll } = useSelector(state => state.team);
    const [show, setShow] = React.useState(false);
    const PriorityRowItem = ({ item }) => {
        const PriorityToggle = React.forwardRef((prop, ref) => {
            const { onClick } = prop;
            return (
                <span
                    ref={ref}
                    onClick={e => {
                        e.preventDefault();
                        onClick(e);
                    }}
                    role="button"
                    style={{ color: setPriorityColor(item?.priorities), textTransform: 'capitalize' }}>
                    {item?.priorities.toLowerCase()}
                </span>
            );
        });
        return (
            <td className="p-2">
                <Dropdown>
                    <Dropdown.Toggle as={PriorityToggle} id="dropdown-basic">
                        {/* HIGH */}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="pt-0 pb-0">
                        <Dropdown.Item style={{ fontSize: 12, color: '#ff0000' }}>High</Dropdown.Item>
                        <Dropdown.Item style={{ fontSize: 12, color: '#ffbb33' }}>Medium</Dropdown.Item>
                        <Dropdown.Item style={{ fontSize: 12, color: '#33b5e5' }}>Low</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </td>
        );
    };
    return (
        <>
            <tr key={item?.id} role="button" className={styles.tableRow}>
                <td className="p-2">
                    <input checked={selectedRows?.find(x => x === item?.id)} onChange={e => handleSelectRows(item?.id, e.target.checked)} type="checkbox" />
                </td>

                {columnState?.number.value && (
                    <td className="p-2">
                        <span onClick={() => openRowViewDetail(item)}>
                            {' '}
                            {item?.prefix}.{item?.item}.{item?.sub_item}
                        </span>{' '}
                        &nbsp;{' '}
                        {item?.childrens && (
                            <span onClick={() => setShow(!show)}>
                                {!show ? <AiFillPlusCircle size={14} color="var(--Brand-Secondary)" /> : <AiFillMinusCircle color="var(--Brand-Secondary)" size={14} />}
                            </span>
                        )}
                    </td>
                )}

                {columnState?.status.value && (
                    <td onClick={() => openRowViewDetail(item)} className="p-2" style={{ color: item?.irresponse ? 'var(--success)' : '#ff0000' }}>
                        {item?.irresponse ? 'Responded' : 'Pending'}
                    </td>
                )}
                {columnState?.workstream.value && (
                    <td onClick={() => openRowViewDetail(item)} className="p-2" style={{ color: '#33b5e5' }}>
                        {/* {items?.find(irl => irl?.id === item?.irl_category)?.name} */}
                        {workstreams?.find(ws => ws?.id === item?.workstream)?.name}
                    </td>
                )}
                {columnState?.tag.value && (
                    <td onClick={() => openRowViewDetail(item)} className="p-2" style={{ color: '#33b5e5' }}>
                        {item?.tag}
                    </td>
                )}
                {columnState?.request.value && (
                    <td onClick={() => openRowViewDetail(item)} className="p-2">
                        <div className={styles?.dropdown}>
                            <div style={{ maxWidth: '600px' }}>
                                <p className=" m-0">{htmlToPlainText(item?.request || '')}</p>
                            </div>
                            {/* <div style={{ maxWidth: '600px' }}>
                                <p className="text-truncate m-0">{htmlToPlainText(item?.request || '')}</p>
                            </div> */}
                            {/* <div className={styles.hoverMenuContent}>
                                <div
                                    style={{
                                        width: '600px',
                                    }}
                                    className={styles.hoverMenuCont}>
                                    {htmlToPlainText(item?.request || '')}
                                </div>
                            </div> */}
                        </div>
                    </td>
                )}

                {columnState?.priority.value && <PriorityRowItem item={item} />}

                {columnState?.requestedOn.value && (
                    <td onClick={() => openRowViewDetail(item)} className="p-2">
                        {item?.created && format(new Date(item?.created), 'dd/MM/yyyy')}
                    </td>
                )}
                {columnState?.addedBy.value && (
                    <td onClick={() => openRowViewDetail(item)} className="p-2">
                        {projectUsersAll?.find(u => u?.value?.group_member_id === item?.added_by)?.value?.full_name || item?.added_by_offline}
                    </td>
                )}
                {columnState?.response.value && (
                    <td onClick={() => openRowViewDetail(item)} className="p-2">
                        <div className={styles?.dropdown}>
                            <div style={{ maxWidth: '300px' }}>
                                <p className=" m-0">{htmlToPlainText(item?.irresponse?.answer || '')}</p>
                            </div>
                            {/* <div className={styles.hoverMenuContent}>
                                <div
                                    style={{
                                        width: '300px',
                                    }}
                                    className={styles.hoverMenuCont}>
                                    {htmlToPlainText(item?.irresponse?.answer || '')}
                                </div>
                            </div> */}
                        </div>
                    </td>
                )}
                {columnState?.respondedOn.value && (
                    <td onClick={() => openRowViewDetail(item)} className="p-2">
                        {item?.irresponse?.created && format(new Date(item?.irresponse?.created), 'dd/MM/yyyy')}
                    </td>
                )}
                {columnState?.respondent.value && (
                    <td onClick={() => openRowViewDetail(item)} className="p-2">
                        {projectUsersAll?.find(u => u?.value?.group_member_id === item?.irresponse?.responded_by)?.value?.full_name}
                    </td>
                )}
                {columnState?.dataroom.value && <td className="p-2">{item?.referenced_file && <RiShareBoxLine onClick={() => handleOpenDRFile(item)} color="#33b5e5" />}</td>}

                <td className="p-2">
                    <div className={styles?.dropdown}>
                        <BsThreeDotsVertical />
                        <div className={styles.hoverMenuContent}>
                            <div
                                style={{
                                    width: '120px',
                                }}
                                className={styles.hoverMenuCont}>
                                {!item?.irresponse && (
                                    <>
                                        <div
                                            onClick={() => {
                                                setSelectedRow(item);
                                                setEditIRModal(true);
                                            }}
                                            className={styles.wsChipMenuItem}>
                                            <RiEditBoxLine className="me-2" />
                                            Edit
                                        </div>
                                        <hr className="mt-2 mb-2" />
                                    </>
                                )}

                                <DoubleConfirmationModal
                                    handleAction={() => dispatch(DeleteInformationRequests(item?.id))}
                                    message="Deleting a Information Request will also delete ALL follow-up requests present.">
                                    <div className={styles.wsChipMenuItem} style={{ color: 'var(--Brand-Secondary)' }}>
                                        <RiDeleteBin6Line className="me-2" />
                                        Delete
                                    </div>
                                </DoubleConfirmationModal>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            {show &&
                item?.childrens?.map((child, childIndex) => (
                    <tr key={item?.id} style={{ backgroundColor: '#f3fdff' }} role="button" className={styles.tableRow}>
                        <td className="p-2">
                            <input checked={selectedRows?.find(x => x === child?.id)} onChange={e => handleSelectRows(child?.id, e.target.checked)} type="checkbox" />
                        </td>

                        {columnState.number.value && (
                            <td className="p-2">
                                <span onClick={() => openRowViewDetail(child)}>
                                    {' '}
                                    {child?.prefix}.{child?.item}.{child?.sub_item}
                                </span>{' '}
                            </td>
                        )}

                        {columnState.status.value && (
                            <td onClick={() => openRowViewDetail(child)} className="p-2" style={{ color: child?.irresponse ? 'var(--success)' : '#ff0000' }}>
                                {child?.irresponse ? 'Responded' : 'Pending'}
                            </td>
                        )}
                        {columnState.workstream.value && (
                            <td onClick={() => openRowViewDetail(child)} className="p-2" style={{ color: '#33b5e5' }}>
                                {workstreams?.find(ws => ws?.id === child?.workstream)?.name}
                            </td>
                        )}
                        {columnState.tag.value && (
                            <td onClick={() => openRowViewDetail(child)} className="p-2" style={{ color: '#33b5e5' }}>
                                {child?.tag}
                            </td>
                        )}
                        {columnState.request.value && (
                            <td onClick={() => openRowViewDetail(child)} className="p-2" style={{ maxWidth: '17vw' }}>
                                <div className={styles?.dropdown}>
                                    <div style={{ maxWidth: '300px' }}>
                                        <p className="text-truncate m-0">{htmlToPlainText(child?.request || '')}</p>
                                    </div>
                                    <div className={styles.hoverMenuContent}>
                                        <div
                                            style={{
                                                width: '300px',
                                            }}
                                            className={styles.hoverMenuCont}>
                                            {htmlToPlainText(child?.request || '')}
                                        </div>
                                    </div>
                                </div>
                            </td>
                        )}

                        {columnState.priority.value && <PriorityRowItem item={child} />}

                        {columnState.requestedOn.value && (
                            <td onClick={() => openRowViewDetail(child)} className="p-2">
                                {child?.created && format(new Date(child?.created), 'dd/MM/yyyy')}
                            </td>
                        )}
                        {columnState.addedBy.value && (
                            <td onClick={() => openRowViewDetail(child)} className="p-2">
                                {projectUsersAll?.find(u => u?.value?.group_member_id === child?.added_by)?.value?.full_name || child?.added_by_offline}
                            </td>
                        )}
                        {columnState.response.value && (
                            <td onClick={() => openRowViewDetail(child)} className="p-2" style={{ maxWidth: '17vw' }}>
                                <div className={styles?.dropdown}>
                                    <div style={{ maxWidth: '300px' }}>
                                        <p className="text-truncate m-0">{htmlToPlainText(child?.irresponse?.answer || '')}</p>
                                    </div>
                                    <div className={styles.hoverMenuContent}>
                                        <div
                                            style={{
                                                width: '300px',
                                            }}
                                            className={styles.hoverMenuCont}>
                                            {htmlToPlainText(child?.irresponse?.answer || '')}
                                        </div>
                                    </div>
                                </div>
                            </td>
                        )}

                        {columnState.respondedOn.value && (
                            <td onClick={() => openRowViewDetail(child)} className="p-2">
                                {child?.irresponse?.created && format(new Date(child?.irresponse?.created), 'dd/MM/yyyy')}
                            </td>
                        )}
                        {columnState.respondent.value && (
                            <td onClick={() => openRowViewDetail(child)} className="p-2">
                                {projectUsersAll?.find(u => u?.value?.group_member_id === child?.irresponse?.responded_by)?.value?.full_name}
                            </td>
                        )}
                        {columnState.dataroom.value && (
                            <td className="p-2">{child?.referenced_file && <RiShareBoxLine onClick={() => handleOpenDRFile(child)} color="#33b5e5" />}</td>
                        )}

                        <td className="p-2">
                            <div className={styles?.dropdown}>
                                <BsThreeDotsVertical />
                                <div className={styles.hoverMenuContent}>
                                    <div
                                        style={{
                                            width: '120px',
                                        }}
                                        className={styles.hoverMenuCont}>
                                        <div
                                            onClick={() => {
                                                setSelectedRow(child);
                                                setEditIRModal(true);
                                            }}
                                            className={styles.wsChipMenuItem}>
                                            <RiEditBoxLine className="me-2" />
                                            Edit
                                        </div>
                                        <hr className="mt-2 mb-2" />
                                        <DoubleConfirmationModal
                                            handleAction={() => dispatch(DeleteInformationRequests(child?.id))}
                                            message="Deleting a Request will permanently remove it from the Project along with associated responses, comments and attachments.">
                                            <div className={styles.wsChipMenuItem} style={{ color: 'var(--Brand-Secondary)' }}>
                                                <RiDeleteBin6Line className="me-2" />
                                                Delete
                                            </div>
                                        </DoubleConfirmationModal>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                ))}
        </>
    );
};
const ColumnFilters = ({ columnState, setColumnState }) => {
    const DropdownToggleCol = React.forwardRef(({ children, onClick }, ref) => (
        <div
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
            className={styles.filterSelector}>
            <span>Column View</span>
            &nbsp; &nbsp;
            <BsChevronDown size={10} color="#829ab1" />
        </div>
    ));

    const handleChangeFilter = (label, key, value) => {
        var data = { ...columnState };
        data[key].value = value;
        setColumnState(data);
    };
    const handleClickAll = value => {
        var data = { ...columnState };
        Object.keys(columnState).forEach(key => {
            data[key].value = value;
        });
        setColumnState(data);
    };
    const handleGetAllValue = () => {
        return Object.keys(columnState)?.find(key => columnState[key]?.value === false) ? false : true;
    };

    return (
        <div>
            <Dropdown>
                <Dropdown.Toggle as={DropdownToggleCol} id="dropdown-basic"></Dropdown.Toggle>

                <Dropdown.Menu>
                    <div style={{ width: 350 }} className={styles.filterBody}>
                        <div className="col-sm-12 col-md-6 d-flex align-items-center mt-2">
                            <input checked={handleGetAllValue()} onChange={e => handleClickAll(e.target.checked)} type="checkbox" id="flexCheckDefault" className=" me-3" />
                            <label className={styles.checkLabel}>View All</label>
                        </div>
                        <hr className="mb-2 mt-3" />
                        <div className="row">
                            {Object.keys(columnState)?.map(item => (
                                <div key={columnState[item]?.id} className="col-sm-12 col-md-6 d-flex align-items-center mt-2">
                                    <input
                                        checked={columnState[item]?.value}
                                        onChange={e => handleChangeFilter(columnState[item]?.label, item, e.target.checked)}
                                        type="checkbox"
                                        id="flexCheckDefault"
                                        className=" me-3"
                                    />
                                    <label className={styles.checkLabel + ' ' + styles.smallFont}>{columnState[item]?.label}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};
const FullScreenPdf = ({ showPdf, setShowPdf, pdfUrl }) => {
    return (
        <Modal show={showPdf} fullscreen={true} onHide={() => setShowPdf(false)}>
            <Modal.Header style={{ height: 61 }} closeButton>
                {/* <Modal.Title>Modal</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
                <PDFViewer setShowPDF={setShowPdf} selectedPDF={{ document: pdfUrl, document_name: pdfUrl?.split('/').pop() }} />
            </Modal.Body>
        </Modal>
    );
};

export const PostInformationRequestSidebar = ({ show, setShow, selectedRow, defaultRequest, setDefaultData }) => {
    const dispatch = useDispatch();
    const { workstreams } = useSelector(state => state.dataroom);
    const { projectUsersAll } = useSelector(state => state.team);
    const { items } = useSelector(state => state?.dataroom);
    const { selectedProject } = useSelector(state => state.projectData);
    const { customRequestLoading } = useSelector(state => state.questionBank);
    const { data } = useSelector(state => state.documents);
    const { user } = useSelector(state => state.user);
    const [request, setRequest] = React.useState('');
    const isProjectAdmin = selectedProject?.vdr?.vdr_admin === user?.id;
    const [formData, setFormData] = React.useState({
        priority: 'NONE',
        category: '',
        workstream: '',
        folder: '',
        reference: '',
    });
    const handleSubmit = async () => {
        let body = {
            priorities: formData.priority,
            request,
            vdr: selectedProject?.vdr?.id,
            tag: formData.folder,
            workstream: formData.workstream,
            referenced_file: formData?.reference,
            request_class: 'Custom',
            added_by: projectUsersAll?.find(item => item?.value?.user_id === user?.id)?.value?.group_member_id,
        };
        if (isProjectAdmin && !body?.added_by) {
            body.added_by_offline = 'Project Admin';
            delete body.added_by;
        }
        if (selectedRow) body.parent_request = selectedRow?.id;

        await dispatch(PostInformationRequests({ body }));
        dispatch(setJoyRideStepIndex(3));
        handleClose();
    };

    const handleClose = () => {
        setShow(false);
        setFormData({
            priority: 'HIGH',
            category: '',
        });
        setRequest('');
        setDefaultData && setDefaultData();
    };

    const SelectToggle = React.forwardRef(({ children, onClick }, ref) => {
        const selectedFile = data?.find(item => item?.id === formData?.reference);
        return (
            <div
                ref={ref}
                onClick={e => {
                    e.preventDefault();
                    onClick(e);
                }}
                className={styles.selectBox + ' ' + (!formData?.workstream && !formData?.folder && styles.selectBoxDisabled)}>
                <span className="text-truncate">
                    {formData.reference ? (
                        items?.find(file => file?.name === formData?.folder)?.prefix + '.' + selectedFile?.item_num + ' ' + selectedFile?.document_name
                    ) : (
                        <span style={{ color: 'var(--Neutral-Gray-6)' }}>None</span>
                    )}
                </span>
                &nbsp; &nbsp;
                <BsChevronDown size={10} color="#829ab1" />
            </div>
        );
    });
    React.useEffect(() => {
        if (show) {
            dispatch(GetWorkstreams());
        }
    }, [show, dispatch]);
    React.useEffect(() => {
        if (formData?.workstream && show) {
            dispatch(GetIRLDoc(formData?.workstream));
            dispatch(GetDataroomItemInfo(formData?.workstream));
        }
    }, [formData?.workstream, show, dispatch]);
    React.useEffect(() => {
        if (defaultRequest) setRequest(defaultRequest);
    }, [defaultRequest]);
    return (
        <>
            <OverlayDrawer style={{ minWidth: 400 }} type="overlay" position="end" open={show} onOpenChange={(_, { open }) => handleClose(open)}>
                <DrawerBody style={{ padding: 16 }}>
                    <div className="">
                        <div className="d-flex justify-content-between">
                            <p className={styles.sidebarTitle}>Add Information Request</p>
                            <Dismiss20Regular role="button" onClick={handleClose} color="var(--Neutral-Gray-6)" />
                        </div>
                        <div>
                            <div className="mt-1 mb-4">
                                <label className={styles.fieldLabel}>
                                    Workstream <span style={{ color: 'var(--danger)' }}>*</span>
                                </label>
                                <select
                                    name="workstream"
                                    value={formData.workstream}
                                    onChange={e =>
                                        setFormData({
                                            ...formData,
                                            workstream: e.target.value,
                                        })
                                    }
                                    style={{ color: !formData?.workstream && 'var(--Neutral-Gray-6)' }}
                                    className={'form-select ' + styles.customSelect}
                                    placeholder="Select Workstream"
                                    aria-label=".form-select-sm example">
                                    <option value="">None</option>
                                    {workstreams?.map(item => (
                                        <option key={item?.id} value={item?.id}>
                                            {item?.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-4">
                                <label className={styles.fieldLabel}>
                                    Tag / Folder <span style={{ color: 'var(--danger)' }}>*</span>
                                </label>
                                <select
                                    name="folder"
                                    value={formData.folder}
                                    onChange={e =>
                                        setFormData({
                                            ...formData,
                                            folder: e.target.value,
                                        })
                                    }
                                    className={'form-select ' + styles.customSelect}
                                    placeholder="Select Workstream"
                                    disabled={!formData?.workstream}
                                    style={{ color: !formData?.folder && 'var(--Neutral-Gray-6)' }}
                                    aria-label=".form-select-sm example">
                                    <option value="">None</option>
                                    {items?.map(item => (
                                        <option key={item?.id} value={item?.name}>
                                            {item?.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className={styles.customReactQuill + ' mb-4 position-relative'}>
                                <p style={{ marginBottom: -32 }} className={styles.fieldLabel}>
                                    Information Request <span style={{ color: 'var(--danger)' }}>*</span>
                                </p>
                                {/* {!showQuillTools && <TextEditStyle20Regular onClick={() => setShowQuillTools(true)} className={styles.quillShowToolbar} />} */}
                                <ReactQuill
                                    placeholder={''}
                                    modules={{
                                        toolbar: [['bold', 'italic', 'underline'], [{ list: 'ordered' }, { list: 'bullet' }], ['link']],
                                    }}
                                    theme="snow"
                                    className="mt-1"
                                    defaultValue={request}
                                    value={request || ''}
                                    onChange={t => setRequest(t)}
                                />
                            </div>
                            <div className="mb-4">
                                <label className={styles.fieldLabel}>Dataroom Reference</label>
                                <Dropdown>
                                    <Dropdown.Toggle as={SelectToggle} id="dropdown-basic"></Dropdown.Toggle>
                                    <Dropdown.Menu style={{ width: '100%' }}>
                                        {data?.map(
                                            item =>
                                                item?.ws_folder === formData?.folder && (
                                                    <div className={styles?.dropdown + ' w-100'}>
                                                        <Dropdown.Item
                                                            key={item?.id}
                                                            data-tip={item?.document_name}
                                                            onClick={() =>
                                                                setFormData({
                                                                    ...formData,
                                                                    reference: item?.id,
                                                                })
                                                            }
                                                            className="text-truncate">
                                                            {items?.find(file => file?.name === formData?.folder)?.prefix + '.' + item?.item_num + ' ' + item?.document_name}
                                                        </Dropdown.Item>
                                                        <div className={styles.hoverMenuContent}>
                                                            <div
                                                                style={{
                                                                    width: '100%',
                                                                    right: 0,
                                                                    top: 40,
                                                                    color: 'var(--info)',
                                                                }}
                                                                className={styles.hoverMenuCont}>
                                                                {item?.document_name}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ),
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 col-md-6">
                                    <div className="">
                                        <label className={styles.fieldLabel}>Priority</label>
                                        <select
                                            name="priority"
                                            value={formData.priority}
                                            onChange={e =>
                                                setFormData({
                                                    ...formData,
                                                    priority: e.target.value,
                                                })
                                            }
                                            className={'form-select ' + styles.customSelect}
                                            placeholder="Select "
                                            style={{ color: formData?.priority === 'NONE' && 'var(--Neutral-Gray-6)' }}
                                            aria-label=".form-select-sm example">
                                            <option value="NONE">None</option>
                                            <option value="HIGH">High</option>
                                            <option value="MEDIUM">Medium</option>
                                            <option value="LOW">Low</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-5 d-flex justify-content-between align-items-center">
                                <button
                                    disabled={!Boolean(request && formData?.workstream && formData?.folder)}
                                    type="button"
                                    onClick={handleSubmit}
                                    className={`btn btn-primary btn-sm ${styles.saveButton}`}>
                                    Save {customRequestLoading && <AiOutlineLoading3Quarters className="rotateLoader" />}
                                </button>
                            </div>
                        </div>
                    </div>
                </DrawerBody>
            </OverlayDrawer>
        </>
    );
};
const PatchInformationRequestSidebar = ({ show, setShow, selectedRow, setSelectedRow }) => {
    const dispatch = useDispatch();
    const { workstreams } = useSelector(state => state.dataroom);
    const { items } = useSelector(state => state?.dataroom);
    const { customRequestLoading } = useSelector(state => state.questionBank);
    const { data } = useSelector(state => state.documents);
    const [request, setRequest] = React.useState('');
    const [formData, setFormData] = React.useState({
        priority: 'HIGH',
        category: '',
        workstream: '',
        folder: '',
        reference: '',
    });
    const handleSubmit = async () => {
        let body = {
            priorities: formData.priority,
            request,
            tag: formData.folder,
            workstream: formData.workstream,
            referenced_file: formData?.reference,
            id: selectedRow?.id,
        };

        await dispatch(PatchInformationRequests({ body }));
        handleClose();
    };

    const handleClose = () => {
        setShow(false);
        setFormData({
            priority: 'HIGH',
            category: '',
        });
        setRequest('');
    };

    const SelectToggle = React.forwardRef(({ children, onClick }, ref) => {
        const selectedFile = data?.find(item => item?.id === formData?.reference);
        return (
            <div
                ref={ref}
                onClick={e => {
                    e.preventDefault();
                    onClick(e);
                }}
                className={styles.selectBox + ' ' + (!formData?.workstream && !formData?.folder && styles.selectBoxDisabled)}>
                <span className="text-truncate">
                    {formData.reference ? items?.find(file => file?.name === formData?.folder)?.prefix + '.' + selectedFile?.item_num + ' ' + selectedFile?.document_name : 'None'}
                </span>
                &nbsp; &nbsp;
                <BsChevronDown size={10} color="#829ab1" />
            </div>
        );
    });
    React.useEffect(() => {
        if (formData?.workstream) {
            dispatch(GetIRLDoc(formData?.workstream));
            dispatch(GetDataroomItemInfo(formData?.workstream));
        }
    }, [formData?.workstream]);

    React.useEffect(() => {
        if (selectedRow) {
            setFormData({
                workstream: selectedRow?.workstream,
                folder: selectedRow?.tag,
                reference: selectedRow?.referenced_file,
                priority: selectedRow?.priorities,
            });
            setRequest(selectedRow?.request);
        }
    }, [selectedRow, show]);

    return (
        <>
            <Offcanvas placement="end" show={show} onHide={handleClose}>
                <Offcanvas.Body>
                    <div className="p-3">
                        <p className="font-weight-bold mb-5 ">Edit Information Request</p>
                        <div>
                            <div className="mt-1 mb-3">
                                <label className={'form-check-label me-4 bold mb-1 ' + styles.smallFont} htmlFor="flexRadioDefault1">
                                    Workstream
                                </label>
                                <select
                                    name="workstream"
                                    value={formData.workstream}
                                    onChange={e =>
                                        setFormData({
                                            ...formData,
                                            workstream: e.target.value,
                                        })
                                    }
                                    className="form-select "
                                    placeholder="Select Workstream"
                                    aria-label=".form-select-sm example">
                                    <option value="">None</option>
                                    {workstreams?.map(item => (
                                        <option key={item?.id} value={item?.id}>
                                            {item?.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label className={'form-check-label me-4 bold mb-1 ' + styles.smallFont} htmlFor="flexRadioDefault1">
                                    Folder / Topic
                                </label>
                                <select
                                    name="folder"
                                    value={formData.folder}
                                    onChange={e =>
                                        setFormData({
                                            ...formData,
                                            folder: e.target.value,
                                        })
                                    }
                                    className="form-select "
                                    placeholder="Select Workstream"
                                    disabled={!formData?.workstream}
                                    aria-label=".form-select-sm example">
                                    <option value="">None</option>
                                    {items?.map(item => (
                                        <option key={item?.id} value={item?.name}>
                                            {item?.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label className={'form-check-label me-4 bold mb-1 ' + styles.smallFont} htmlFor="flexRadioDefault1">
                                    Reference File (Optional)
                                </label>
                                <Dropdown>
                                    <Dropdown.Toggle as={SelectToggle} id="dropdown-basic"></Dropdown.Toggle>
                                    <Dropdown.Menu style={{ width: '100%' }}>
                                        {data?.map(
                                            item =>
                                                item?.ws_folder === formData?.folder && (
                                                    <div className={styles?.dropdown + ' w-100'}>
                                                        <Dropdown.Item
                                                            key={item?.id}
                                                            data-tip={item?.document_name}
                                                            onClick={() =>
                                                                setFormData({
                                                                    ...formData,
                                                                    reference: item?.id,
                                                                })
                                                            }
                                                            className="text-truncate">
                                                            {items?.find(file => file?.name === formData?.folder)?.prefix + '.' + item?.item_num + ' ' + item?.document_name}
                                                        </Dropdown.Item>
                                                        <div className={styles.hoverMenuContent}>
                                                            <div
                                                                style={{
                                                                    width: '100%',
                                                                    right: 0,
                                                                    top: 40,
                                                                    color: 'var(--info)',
                                                                }}
                                                                className={styles.hoverMenuCont}>
                                                                {item?.document_name}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ),
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="">
                                <p htmlFor style={{ marginBottom: -36 }} className={'quilLabel ' + styles.smallFont}>
                                    Request / Question
                                </p>
                                <ReactQuill
                                    placeholder={'Type here'}
                                    modules={{
                                        toolbar: [['bold', 'italic', 'underline'], [{ list: 'ordered' }, { list: 'bullet' }], ['link']],
                                    }}
                                    theme="snow"
                                    className="mt-1"
                                    defaultValue={request}
                                    onChange={t => setRequest(t)}
                                />
                            </div>

                            <div className="row">
                                <div className="col-sm-12 col-md-6">
                                    <div className="mt-3">
                                        <label className={'form-check-label me-4 bold mb-1 ' + styles.smallFont} htmlFor="flexRadioDefault1">
                                            Priority
                                        </label>
                                        <select
                                            name="priority"
                                            value={formData.priority}
                                            onChange={e =>
                                                setFormData({
                                                    ...formData,
                                                    priority: e.target.value,
                                                })
                                            }
                                            className="form-select "
                                            placeholder="Select Impact"
                                            aria-label=".form-select-sm example">
                                            <option value="HIGH">High</option>
                                            <option value="MEDIUM">Medium</option>
                                            <option value="LOW">Low</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-5 d-flex justify-content-between align-items-center">
                                <button style={{ width: 90 }} type="button" onClick={handleSubmit} className={`btn btn-primary btn-sm ${styles.smallButton}`}>
                                    Save {customRequestLoading && <AiOutlineLoading3Quarters className="rotateLoader" />}
                                </button>

                                <p onClick={handleClose} className={styles.orangeBtnText}>
                                    Cancel
                                </p>
                            </div>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};
const AddManagementQuestionSidebar = ({ show, setShow }) => {
    const { projectUsersAll } = useSelector(state => state.team);
    const [task, setTask] = React.useState('');
    const [formData, setFormData] = React.useState({
        deadline: '',
        assigned_to: '',
        project: localStorage['project_id'],
        status: 'PENDING',
        priority: 'HIGH',
        scope: '',
    });

    const handleSubmit = async () => {
        handleClose();
    };

    const handleClose = () => {
        setShow(false);
        setFormData({
            deadline: '',
            assigned_to: '',
            project: localStorage['project_id'],
            status: 'PENDING',
            priority: 'HIGH',
            scope: '',
        });
        setTask('');
    };

    return (
        <>
            <Offcanvas placement="end" show={show} onHide={handleClose}>
                <Offcanvas.Body>
                    <div className="p-3">
                        <p className="font-weight-bold mb-5 ">Add Management Question</p>
                        <div>
                            <div className="mt-1 mb-4">
                                <label className={'form-check-label me-4 bold mb-1 ' + styles.smallFont} htmlFor="flexRadioDefault1">
                                    Category
                                </label>
                                <select
                                    name="assigned_to"
                                    value={formData.assigned_to}
                                    onChange={e =>
                                        setFormData({
                                            ...formData,
                                            assigned_to: e.target.value,
                                        })
                                    }
                                    className="form-select "
                                    placeholder="Select Impact"
                                    aria-label=".form-select-sm example">
                                    <option value="">None</option>
                                    {projectUsersAll?.map(item => (
                                        <option key={item?.value?.id} value={item?.value?.id}>
                                            {item?.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="">
                                <p htmlFor style={{ marginBottom: -36 }} className={'quilLabel ' + styles.smallFont}>
                                    Question
                                </p>
                                <ReactQuill
                                    placeholder={'Type here'}
                                    modules={{
                                        toolbar: [['bold', 'italic', 'underline'], [{ list: 'ordered' }, { list: 'bullet' }], ['link']],
                                    }}
                                    theme="snow"
                                    className="mt-1"
                                    defaultValue={task}
                                    onChange={t => setTask(t)}
                                />
                            </div>

                            <div className="mt-3">
                                <label className={'form-check-label me-4 bold mb-1 ' + styles.smallFont} htmlFor="flexRadioDefault1">
                                    Assigned to
                                </label>
                                <select
                                    name="assigned_to"
                                    value={formData.assigned_to}
                                    onChange={e =>
                                        setFormData({
                                            ...formData,
                                            assigned_to: e.target.value,
                                        })
                                    }
                                    className="form-select "
                                    placeholder="Select Impact"
                                    aria-label=".form-select-sm example">
                                    <option value="">None</option>
                                    {projectUsersAll?.map(item => (
                                        <option key={item?.value?.id} value={item?.value?.id}>
                                            {item?.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="mt-4 d-flex justify-content-between align-items-center">
                                <button style={{ width: 90 }} type="button" onClick={handleSubmit} className={`btn btn-primary btn-sm ${styles.smallButton}`}>
                                    Save
                                </button>

                                <p onClick={handleClose} className={styles.orangeBtnText}>
                                    Cancel
                                </p>
                            </div>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};
const ResponseDetailSidebar = ({ show, setShow, setFollowUpVisible, selectedRow, setSelectedRow, setShowPdf, setPdfUrl, handleOpenDRFile, setEditIRModal, filteredData }) => {
    const { workstreams } = useSelector(state => state?.dataroom);
    const { projectUsersAll } = useSelector(state => state.team);
    const { user } = useSelector(state => state.user);
    const { selectedProject } = useSelector(state => state.projectData);
    const { irlCommentButtonLoading, informationRequests } = useSelector(state => state.questionBank);
    const [supportingFile, setSupportingFile] = React.useState('');
    const [comment, setComment] = React.useState('');
    const [response, setResponse] = React.useState('');
    const [previewImage, setPreviewImage] = React.useState();
    const [files, setFiles] = React.useState({ files: null });
    const dispatch = useDispatch();
    const expandedData = expandChildrens(filteredData);
    const selectedIndex = expandedData.findIndex(item => item.id === selectedRow?.id);

    const handleClose = () => {
        setResponse('');
        setShow(false);
        setSupportingFile();
        setFiles({ files: [] });
        setPreviewImage();
        setPdfUrl('');
    };
    const handleOpenFollowUp = () => {
        setShow(false);
        setFollowUpVisible(true);
    };
    const handleSubmit = async () => {
        let body = {
            information_request: selectedRow?.id,
            comment,
            commented_by: projectUsersAll?.find(item => item?.value?.user_id === user?.id)?.value?.group_member_id,
        };
        dispatch(PostIRLComment({ body }));
        setComment('');
    };
    const handleGetProjectName = (id, onlyName) => {
        let userData = projectUsersAll.find(item => item.value.group_member_id === id);
        return <span>{userData?.value?.full_name}</span>;
    };
    const handleBlur = () => {
        if (response === '<p><br></p>') {
            setResponse('');
        }
    };

    const handleAddFile = async file => {
        setFiles({ files: file });
        var reader = new FileReader();
        reader.onloadend = function () {
            setPreviewImage(reader.result);
        };
        reader.readAsDataURL(file);
    };

    function encodeImageFileAsURL(element) {
        var file = element.files[0];
        var reader = new FileReader();
        reader.onloadend = function () {
            setPreviewImage(reader.result);
        };
        reader.readAsDataURL(file);
    }
    const handlePasteFile = e => {
        if (e.clipboardData.files.length) {
            encodeImageFileAsURL(e.clipboardData);
            const fileObject = e.clipboardData.files[0];
            setFiles({ files: fileObject });
        } else {
            alert('No image data was found in your clipboard. Copy an image first or take a screenshot.');
        }
    };
    const handleSubmitResponse = () => {
        // const isProjectAdmin = selectedProject?.vdr?.vdr_admin === user?.id;
        let respondedBy = projectUsersAll?.find(item => item?.value?.user_id === user?.id)?.value?.group_member_id;
        var formData = new FormData();
        respondedBy && formData.append('responded_by', respondedBy);
        formData.append('answer', response);
        formData.append('information_request', selectedRow?.id);
        formData.append('vdr', selectedProject?.vdr?.id);
        if (files?.files) {
            formData.append('attachment', files.files);
        }
        dispatch(PostInformationResponse({ body: formData }));
        setResponse('');
        setFiles({ files: [] });
        setSupportingFile();
        setPreviewImage();
        // handleClose();
    };
    const handleNavigateIRL = dir => {
        if (expandedData?.length > 0)
            if (dir === 'back' && selectedIndex > 0) {
                const previousRow = expandedData[selectedIndex - 1];
                setSelectedRow(previousRow);
            } else if (dir === 'forward' && selectedIndex < expandedData?.length - 1) {
                const nextRow = expandedData[selectedIndex + 1];
                setSelectedRow(nextRow);
            }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => {
        const effectAsync = async () => {
            // if (selectedRow?.referenced_file) {
            //     let response = await dispatch(GetSingleIRLDoc({ wsId: selectedRow?.workstream, docId: selectedRow?.referenced_file }));
            //     if (response?.type === 'IRLDOC/getSingleIRLDoc/fulfilled') {
            //         setReferencedFile(response?.payload?.data?.document);
            //     }
            // }
            // if (!selectedRow?.referenced_file) setReferencedFile('');
            if (selectedRow?.irresponse?.attachment) setSupportingFile(selectedRow?.irresponse?.attachment);
            if (!selectedRow?.irresponse?.attachment) setSupportingFile('');
        };
        effectAsync();
    }, [selectedRow, show]);
    React.useEffect(() => {
        const effectAsync = async () => {
            if (selectedRow?.referenced_file) {
                let response = await dispatch(GetSingleIRLDoc({ wsId: selectedRow?.workstream, docId: selectedRow?.referenced_file }));
                if (response?.type === 'IRLDOC/getSingleIRLDoc/fulfilled') {
                    setPdfUrl(response?.payload?.data?.document);
                }
            } else setPdfUrl('');
        };
        effectAsync();
    }, [selectedRow, show]);
    React.useEffect(() => {
        if (show) {
            setSelectedRow(informationRequests?.find(item => item?.id === selectedRow?.id));
        }
    }, [informationRequests]);
    function expandChildrens(data) {
        const expandedData = [];

        data.forEach(item => {
            expandedData.push(item);

            if (item.childrens && item.childrens.length > 0) {
                const expandedChildrens = expandChildrens(item.childrens);
                expandedData.push(...expandedChildrens);
            }
        });

        return expandedData;
    }
    return (
        <>
            <Offcanvas placement="end" show={show} onHide={handleClose}>
                <Offcanvas.Body>
                    <div className="p-3">
                        <div className="mb-4 d-flex justify-content-between">
                            <div className={styles.blueText}>
                                <b className={styles.regularText}>{workstreams?.find(irl => irl?.id === selectedRow?.workstream)?.name}</b>
                                <p className={styles.regularText}>{selectedRow?.tag}</p>
                            </div>
                            <div className={styles.arrowsDiv}>
                                <IoChevronBackCircleSharp
                                    size={24}
                                    role="button"
                                    color={selectedIndex === 0 ? 'var(--muted)' : 'var(--Brand-Secondary)'}
                                    onClick={() => handleNavigateIRL('back')}
                                />
                                <IoChevronForwardCircleSharp
                                    size={24}
                                    role="button"
                                    color={selectedIndex === expandedData.length - 1 ? 'var(--muted)' : 'var(--Brand-Secondary)'}
                                    onClick={() => handleNavigateIRL('forward')}
                                />
                            </div>
                        </div>
                        <div>
                            <div className=" d-flex justify-content-between">
                                <div className={styles.smallBlueLabel + ' ' + styles.smallFont}>
                                    {selectedRow?.prefix}.{selectedRow?.item}.{selectedRow?.sub_item}
                                </div>
                                {!selectedRow?.irresponse && (
                                    <span
                                        className={styles.orangeBtnText}
                                        onClick={() => {
                                            setEditIRModal(true);
                                        }}>
                                        Edit
                                    </span>
                                )}
                            </div>
                            <p className={styles.regularText} dangerouslySetInnerHTML={{ __html: selectedRow?.request }}></p>
                            {selectedRow?.referenced_file && (
                                <span onClick={() => handleOpenDRFile(selectedRow)} role="button" className="d-flex align-items-center ">
                                    <p className={styles.blueText + ' m-0 me-2 ' + styles.regularText}>Reference file</p>
                                    <RiShareBoxLine color="#33b5e5" />
                                </span>
                            )}
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between">
                            <div>
                                <p className={styles.smallBlueLabel + ' m-0'}>Added by</p>
                                <p className={styles.smallFont}>
                                    {selectedRow?.added_by
                                        ? projectUsersAll?.find(u => u?.value?.group_member_id === selectedRow?.added_by)?.value?.full_name
                                        : selectedRow?.added_by_offline}{' '}
                                    <br />
                                    {selectedRow?.created && format(new Date(selectedRow?.created), 'dd/MM/yyyy')} &nbsp;{' '}
                                    <span style={{ color: 'rgba(0, 49, 73, 0.5)' }}>{selectedRow?.created && formatDateDifferenc(selectedRow?.created)}</span>
                                </p>
                            </div>
                            <div>
                                {selectedRow?.irresponse ? <p className={styles.greenText + ' m-0 mt-3'}>Responded</p> : <p className={styles.redText + ' m-0 mt-3'}>Pending</p>}

                                <p className={styles.smallFont} style={{ textTransform: 'capitalize' }}>
                                    {selectedRow?.priorities.toLowerCase()} Priority
                                </p>
                            </div>
                        </div>
                        <hr />
                        {selectedRow?.irresponse ? (
                            <>
                                <div>
                                    <p className={styles.smallBlueLabel + ' m-0'}>Response</p>
                                    <p className={styles.regularText} dangerouslySetInnerHTML={{ __html: selectedRow?.irresponse?.answer }}></p>

                                    {supportingFile && (
                                        <span
                                            onClick={() => {
                                                setPdfUrl(supportingFile);
                                                setShowPdf(true);
                                                window.history.pushState(null, '', '/irl/pdf-viewer');
                                            }}
                                            role="button"
                                            className="d-flex align-items-center">
                                            <p className={styles.blueText + ' m-0 me-2 ' + styles.regularText}>Supporting Files</p>
                                            <RiShareBoxLine color="#33b5e5" />
                                        </span>
                                    )}
                                </div>

                                {selectedRow?.irresponse && (
                                    <div>
                                        <hr />
                                        <p className={styles.smallBlueLabel + ' m-0'}>Responded by</p>
                                        <p className={styles.smallFont}>
                                            {projectUsersAll?.find(u => u?.value?.group_member_id === selectedRow?.irresponse?.responded_by)?.value?.full_name || 'Project Admin'}
                                            <br />
                                            {selectedRow?.irresponse?.created && format(new Date(selectedRow?.irresponse?.created), 'dd/MM/yyyy')} &nbsp;{' '}
                                            <span style={{ color: 'rgba(0, 49, 73, 0.5)' }}>
                                                {' '}
                                                {selectedRow?.irresponse?.created && formatDateDifferenc(selectedRow?.irresponse?.created)}
                                            </span>
                                        </p>
                                    </div>
                                )}

                                <hr />
                                {selectedRow && selectedRow?.irlcomment?.length > 0 && (
                                    <div className="mt-4">
                                        <p className={styles.blueLabel}>Comments</p>
                                        {selectedRow?.irlcomment?.map(item => (
                                            <div key={item?.id} className="mb-4">
                                                <p className={styles.regularText + ' m-0'}>{item?.comment}</p>
                                                <div className={styles.smallFont + ' ' + styles.lightGray}>
                                                    Added on {item?.created && format(new Date(item?.created), 'dd/MM/yyyy')} by {handleGetProjectName(item?.commented_by, true)}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                <div className="">
                                    <div className="d-flex justify-content-between">
                                        <label className={'form-check-label me-4 bold mb-1 ' + styles.smallFont} htmlFor="flexRadioDefault1">
                                            Comment
                                        </label>
                                        <span onClick={handleOpenFollowUp} role={'button'} style={{ color: 'var(--Brand-Secondary)' }} className={styles.smallFont}>
                                            Add Follow-up Request
                                        </span>
                                    </div>

                                    <input value={comment} onChange={e => setComment(e.target?.value)} type="text" className="form-control commonInput" placeholder="Type here" />
                                    <button
                                        onClick={handleSubmit}
                                        disabled={!comment}
                                        type="button"
                                        className={`mt-3 btn btn-primary btn-sm  ${styles.responseButton} ${styles.smallButton} ${!comment && styles.disabledSaveBtn}`}>
                                        Save &nbsp;
                                        {irlCommentButtonLoading && <AiOutlineLoading3Quarters className="rotateLoader" />}
                                    </button>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="">
                                    <p htmlFor style={{ marginBottom: -36 }} className={'quilLabel ' + styles.smallFont}>
                                        Response
                                    </p>
                                    <ReactQuill
                                        placeholder={'Type here'}
                                        modules={{
                                            toolbar: [['bold', 'italic', 'underline'], [{ list: 'ordered' }, { list: 'bullet' }], ['link']],
                                        }}
                                        theme="snow"
                                        className="mt-1"
                                        onBlur={handleBlur}
                                        // value={response}
                                        onChange={t => setResponse(t)}
                                    />
                                </div>

                                <div className="mt-3">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <label className={'form-check-label me-4 bold mb-1 ' + styles.smallFont} htmlFor="flexRadioDefault1">
                                            Add Supporting Files (Optional)
                                        </label>
                                        <div>{previewImage && <ImageModal image={previewImage} />}</div>
                                    </div>
                                    <div className="d-flex">
                                        <div className={styles.fileUploadBody} onPaste={handlePasteFile}>
                                            <FileUploader children="Choose File" handleChange={handleAddFile} name="file" />
                                            {files?.files?.name ? files?.files?.name : ' Click Here and Paste'}
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4 d-flex justify-content-between align-items-center">
                                    <button
                                        disabled={!response || response === '<p><br></p>'}
                                        onClick={handleSubmitResponse}
                                        style={{ width: 90 }}
                                        type="button"
                                        className={`btn btn-primary btn-sm ${styles.smallButton}`}>
                                        Save
                                    </button>

                                    <p onClick={handleClose} className={styles.orangeBtnText}>
                                        Cancel
                                    </p>
                                </div>
                            </>
                        )}
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

const ImageModal = ({ image }) => {
    const [modalVisible, setModalVisible] = React.useState(false);

    return (
        <>
            <FaRegImages onClick={() => setModalVisible(true)} size={20} />
            <Modal
                size="lg"
                centered
                show={modalVisible}
                onHide={() => {
                    setModalVisible(false);
                }}>
                <Modal.Body>
                    <img src={image} style={{ width: '100%' }} alt={image} />
                </Modal.Body>
            </Modal>
        </>
    );
};

const IRLCoPilotSidebar = ({ selectedRows }) => {
    const [show, setShow] = React.useState(false);
    const { informationRequests } = useSelector(state => state.questionBank);
    const [currentColorIndex, setCurrentColorIndex] = React.useState(0);
    const [response, setResponse] = React.useState(``);
    const { irlCopilotLoading } = useSelector(state => state.questionBank);
    const [showObsSidebar, setShowObsSidebar] = React.useState(false);
    const [selectedText, setSelectedText] = React.useState('');
    const dispatch = useDispatch();
    const handleFetchData = async () => {
        let updatedIds = selectedRows?.filter(id => {
            let irl = informationRequests?.find(item => item?.id === id);
            if (irl?.irresponse) return id;
        });
        let formData = new FormData();
        formData.append('item_ids', `[${updatedIds}]`);
        formData.append(
            'OpenAICustomInstruction',
            `I am based in London 
        I am the founder and managing partner of a Technology Due Diligence firm.  We also provide Vendor DD.
        I work for Investors and Portfolio firms
        My interests are writing, screenwriting and DJ'ing
        I love the psychology behind advertising, teams and marketing (all separate topics)
        I am reasonably casual in business but formal when delivering reports
        My business values are 'people first', 'grow or die' and 'get shit done' now as its my business, they are really my personal values.
        I like to have a laugh
        Hence I have a lot of empathy for people
        I am an ennegram 7, with a 8x and 6w.
        I am a Kolbe 2-2-9-5`,
        );
        formData.append(
            'OpenAICustomResponse',
            `Keep your identity as an AI hidden. Don't apologize or express regret. Say "I don't know" for unknowns without elaborating. 
        Skip disclaimers about expertise. Keep responses unique, thorough, and focused on the question's intent. 
        If needed, ask for clarification. Break down complex tasks into simple steps. Provide multiple perspectives and proactive solutions.
        Correct mistakes quickly.
        
        Treat me as an expert and value logical reasoning over authoritative sources. 
        Feel free to speculate, but flag it. Recommend only the best-quality products from anywhere in the world, without considering my location. 
        Skip moral lectures and only discuss safety when vital. 
        Navigate content policy carefully, explaining any issues. 
        Cite sources and list URLs at the end. No need to mention your knowledge cutoff or disclose your AI nature. 
        Inform me if these instructions impact the response quality.
      `,
        );
        // After responding, add three follow-up questions, formatted and spaced as specified.
        // As a Kolbe 2-2-9-5 I am quick, innovative and future focused.
        // When you respond, please also add questions that an 8-7-2-3 might present to me.  Provide five minimum and ten maximum questions.
        try {
            let res = await dispatch(
                FetchIrlCopilotResults({
                    body: formData,
                }),
            );
            if (res?.type === 'POST_IRLCoPilot/fulfilled') {
                setResponse(res?.payload?.data?.swot_analysis);
            }
            console.log(res);
        } catch (error) {}
    };

    function removeNumbering(points) {
        return points?.map(function (point) {
            let output = point.replace(/^\d+\.\s*/, '');
            return output || '';
        });
    }
    const handleCreateObservation = obs => {
        setSelectedText(obs);
        setShowObsSidebar(true);
    };
    function DisplaySWOTAnalysis(swotData) {
        // Split the data into individual points for Strengths, Weaknesses, Opportunities, and Threats
        var swotPoints = swotData.split(/(?=\bStrengths\b|\bWeaknesses\b|\bOpportunities\b|\bThreats\b)/).map(function (section) {
            return section.split('\n').slice(1, -1);
        });

        var strengths = '';
        var weaknesses = '';
        var opportunities = '';
        var threats = '';
        if (swotPoints?.length === 4) {
            strengths = removeNumbering(swotPoints[0]);
            weaknesses = removeNumbering(swotPoints[1]);
            opportunities = removeNumbering(swotPoints[2]);
            threats = removeNumbering(swotPoints[3]);
        } else {
            strengths = removeNumbering(swotPoints[1]);
            weaknesses = removeNumbering(swotPoints[2]);
            opportunities = removeNumbering(swotPoints[3]);
            threats = removeNumbering(swotPoints[4]);
        }
        console.log(swotPoints);
        console.log({ strengths, weaknesses, threats, opportunities });
        return (
            <div>
                {!strengths && !weaknesses && !threats && !opportunities ? (
                    <div>{swotData}</div>
                ) : (
                    <div>
                        <p className={styles.swotLabel}>Strengths:</p>
                        <div>
                            {strengths?.map(
                                item =>
                                    item && (
                                        <div key={item} className={styles.swotDetail}>
                                            <div style={{ width: 20 }}>
                                                <Tooltip positioning="below" withArrow content="Create Observation">
                                                    <AddCircle16Regular
                                                        onClick={() => handleCreateObservation(item)}
                                                        style={{ marginRight: 10, marginTop: -1 }}
                                                        role="button"
                                                        color="var(--Brand-Secondary)"
                                                    />
                                                </Tooltip>
                                            </div>
                                            <span>{item} </span>
                                        </div>
                                    ),
                            )}
                        </div>
                        <p className={styles.swotLabel}>Weaknesses:</p>
                        <div>
                            {weaknesses?.map(
                                item =>
                                    item && (
                                        <div key={item} className={styles.swotDetail}>
                                            <div style={{ width: 20 }}>
                                                <Tooltip positioning="below" withArrow content="Create Observation">
                                                    <AddCircle16Regular
                                                        onClick={() => handleCreateObservation(item)}
                                                        style={{ marginRight: 10, marginTop: -1 }}
                                                        role="button"
                                                        color="var(--Brand-Secondary)"
                                                    />
                                                </Tooltip>
                                            </div>
                                            <span>{item} </span>
                                        </div>
                                    ),
                            )}
                        </div>
                        <p className={styles.swotLabel}>Opportunities:</p>
                        <div>
                            {opportunities?.map(
                                item =>
                                    item && (
                                        <div key={item} className={styles.swotDetail}>
                                            <div style={{ width: 20 }}>
                                                <Tooltip positioning="below" withArrow content="Create Observation">
                                                    <AddCircle16Regular
                                                        onClick={() => handleCreateObservation(item)}
                                                        style={{ marginRight: 10, marginTop: -1 }}
                                                        role="button"
                                                        color="var(--Brand-Secondary)"
                                                    />
                                                </Tooltip>
                                            </div>
                                            <span>{item} </span>
                                        </div>
                                    ),
                            )}
                        </div>
                        <p className={styles.swotLabel}>Threats:</p>
                        <div>
                            {threats?.map(
                                item =>
                                    item && (
                                        <div key={item} className={styles.swotDetail}>
                                            <div style={{ width: 20 }}>
                                                <Tooltip positioning="below" withArrow content="Create Observation">
                                                    <AddCircle16Regular
                                                        onClick={() => handleCreateObservation(item)}
                                                        style={{ marginRight: 10, marginTop: -1 }}
                                                        role="button"
                                                        color="var(--Brand-Secondary)"
                                                    />
                                                </Tooltip>
                                            </div>
                                            <span>{item} </span>
                                        </div>
                                    ),
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }
    React.useEffect(() => {
        if (show) {
            //Run API call to fetch co pilot data
            handleFetchData();
        }
    }, [show]);
    React.useEffect(() => {
        let intervalId;

        if (irlCopilotLoading) {
            // Start cycling through colors when loading is true
            intervalId = setInterval(() => {
                setCurrentColorIndex(prevIndex => (prevIndex === 2 ? 0 : prevIndex + 1));
            }, 1000); // Change color every 1 second (adjust as needed)
        } else {
            // Reset color index when loading is false
            setCurrentColorIndex(0);
            clearInterval(intervalId); // Clear interval when loading becomes false
        }

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [irlCopilotLoading]);

    const colors = ['#8E2DE2', '#F8047E', '#192BC2'];
    const currentColor = colors[currentColorIndex];
    // console.log(response);
    return (
        <>
            <button onClick={() => setShow(true)} type="button" className={`btn btn-primary btn-sm add-irl-button me-2 d-flex align-items-center ${styles.smallButton}`}>
                <Wand16Regular className="me-1" /> Lens AI Analysis
            </button>
            <OverlayDrawer style={{ minWidth: 648 }} type="overlay" position="end" open={show} onOpenChange={(_, { open }) => setShow(open)}>
                <DrawerBody className="position-relative" style={{ padding: 16 }}>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <Wand20Regular color="var(--Brand-Secondary)" className="me-1" /> <span className={styles.aiTitle}>Lens AI Analysis</span>
                            {!irlCopilotLoading && (
                                <div onClick={handleFetchData} className="d-flex align-items-center ms-4" role="button">
                                    <ArrowClockwise16Regular color="var(--Brand-Secondary)" className="me-1" />
                                    <span className={styles?.regenerateText}>Regenerate</span>
                                </div>
                            )}
                        </div>
                        <Dismiss20Regular onClick={() => setShow(false)} role="button" color="var(--Neutral-Gray-6)" />
                    </div>
                    {irlCopilotLoading ? (
                        <div className={styles.copilotLoader}>
                            <Wand48Regular
                                style={{
                                    transition: 'color 0.3s ease', // Add transition for color change
                                    color: irlCopilotLoading ? currentColor : 'inherit', // Change color based on loading state
                                }}
                            />
                            <p>Loading results</p>
                        </div>
                    ) : (
                        response && (
                            <div id="swotAnalysis">
                                <p className={styles.swotAnalysis}>SWOT Analysis</p>
                                {DisplaySWOTAnalysis(response)}
                            </div>
                        )
                    )}
                </DrawerBody>
            </OverlayDrawer>
            <ObservationFormSidebar show={showObsSidebar} setShow={setShowObsSidebar} setDefaultData={setSelectedText} defaultObservation={selectedText} />
        </>
    );
};

export default Main;
