import React from 'react';
import TeamAnalyser from '../components/TeamAnalyser';
import Layout from '../components/Layout';
import Pageheader from '../utils/headerHelment';

const TeamAnalyserPage = () => {
    return (
        <>
            <Pageheader title="LENS - Team Analyser" />
            <Layout>
                <TeamAnalyser />
            </Layout>
        </>
    );
};

export default TeamAnalyserPage;
