import axiosInstance from '../apiServices/axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { MATURITY_INDEX } from '../apiServices/api_routes';
import { toast } from 'react-toastify';
// import store from './store';

export const GetMaturityData = createAsyncThunk('GET_MATURITY_INDEX', async () => {
    const data = await axiosInstance.get(MATURITY_INDEX + localStorage['project_id']);
    return data;
});
export const PatchMaturityData = createAsyncThunk('PATCH_MATURITY_INDEX', async ({ id, body }) => {
    const data = await axiosInstance.patch(MATURITY_INDEX + localStorage['project_id'] + '/' + id + '/', body);
    return data;
});

export const maturitySlice = createSlice({
    name: 'maturitySlice',
    initialState: {
        maturityData: {},
        maturityProjectStage: 'preSeed',
    },
    reducers: {},
    extraReducers: {
        // GetMaturityData
        [GetMaturityData.pending]: (state, action) => {
            state.status = 'loading';
        },
        [GetMaturityData.fulfilled]: (state, action) => {
            state.status = 'success';
            if (action?.payload?.data?.length > 0) {
                state.maturityData = action?.payload?.data[0];
                state.maturityProjectStage = action?.payload?.data[0]?.project_stage || 'preSeed';
            }
        },
        [GetMaturityData.rejected]: (state, action) => {
            state.status = 'rejected';
        },
        // PatchMaturityData
        [PatchMaturityData.pending]: (state, action) => {
            state.status = 'loading';
        },
        [PatchMaturityData.fulfilled]: (state, { payload }) => {
            state.status = 'success';
            // state.maturityData = payload?.data;
            state.maturityProjectStage = payload?.data?.project_stage;
        },
        [PatchMaturityData.rejected]: (state, action) => {
            state.status = 'rejected';
            toast.error('Something went wrong');
        },
    },
});
export const {} = maturitySlice.actions;
export default maturitySlice.reducer;
