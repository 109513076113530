import React from 'react';
import Dataroom from '../components/Dataroom';
import Layout from '../components/Layout';
import Pageheader from '../utils/headerHelment';
import ReactJoyride from 'react-joyride';
import { AiOutlineClose } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { setJoyRideStepIndex } from '../redux/dataroomSlice';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { ReactInternetSpeedMeter } from 'react-internet-meter';
import Dataroom2 from '../components/Dataroom2.0';

// import styles from '../components/Dataroom/css/index.module.css';

const DataroomView = () => {
    const dispatch = useDispatch();
    const { joyRideStepIndex } = useSelector(state => state?.dataroom);
    const [runJoyRide, setRunJoyRide] = React.useState(false);

    const [showWsModal, setShowWsModal] = React.useState(false);
    const [addFolderModal, setAddFolderModal] = React.useState(false);
    const [joyRideSteps] = React.useState([
        {
            // Workstream
            target: '.workstream-chip-step',
            content: (
                <div className="ps-2 pe-2 regularFont">
                    <div className="text-end">
                        <AiOutlineClose color="var(--Brand-Secondary)" onClick={() => setRunJoyRide(false)} role="button" />
                    </div>
                    <div className="text-start">
                        {' '}
                        Welcome to Lens VDR. We have included a few standard Workstreams to your project to get you started. You can delete or rename them at any point using the
                        respective menu.
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-3">
                        <span style={{ color: 'var(--muted)' }} role="button"></span>
                        <button onClick={() => dispatch(setJoyRideStepIndex(1))} className="btn btn-primary smallButton">
                            Next
                        </button>
                    </div>
                </div>
            ),
            hideFooter: true,
            disableBeacon: true,
            disableOverlayClose: true,
        },
        {
            // Add New Workstream Button
            target: '.add-workstream-step',
            content: (
                <div className="ps-2 pe-2 regularFont">
                    <div className="text-end">
                        <AiOutlineClose color="var(--Brand-Secondary)" onClick={() => setRunJoyRide(false)} role="button" />
                    </div>
                    <div className="text-start">
                        You can add more Workstreams to your project. <b style={{ color: 'var(--Brand-Secondary)' }}>Click on the above button</b> to add a new workstream to the
                        project.
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-3">
                        <span style={{ color: 'var(--muted)' }} role="button" onClick={() => dispatch(setJoyRideStepIndex(0))}>
                            Previous
                        </span>
                    </div>
                </div>
            ),
            hideFooter: true,
            disableBeacon: true,
            disableOverlayClose: true,
            spotlightClicks: true,
        },
        {
            //Select Workstream
            target: '.create-workstream-step',
            content: (
                <div className="ps-2 pe-2 regularFont">
                    <div className="text-end">
                        <AiOutlineClose color="var(--Brand-Secondary)" onClick={() => setRunJoyRide(false)} role="button" />
                    </div>
                    <div className="text-start">You can add pre-defined Workstreams to your project, or you can also add your own custom Workstream.</div>
                    <div className="d-flex align-items-center justify-content-between mt-3">
                        <span
                            style={{ color: 'var(--muted)' }}
                            role="button"
                            onClick={() => {
                                dispatch(setJoyRideStepIndex(1));
                                setShowWsModal(false);
                            }}>
                            Previous
                        </span>{' '}
                        <button onClick={() => dispatch(setJoyRideStepIndex(3))} className="btn btn-primary smallButton">
                            Next
                        </button>
                    </div>
                </div>
            ),
            hideFooter: true,
            disableBeacon: true,
            disableOverlayClose: true,
            spotlightClicks: true,
            styles: {
                options: {
                    zIndex: 10000,
                },
            },
        },
        {
            //Close Add Workstream modal
            target: '.done-ws-modal-step',
            content: (
                <div className="ps-2 pe-2 regularFont">
                    <div className="text-end">
                        <AiOutlineClose color="var(--Brand-Secondary)" onClick={() => setRunJoyRide(false)} role="button" />
                    </div>
                    <div className="text-start">
                        <b style={{ color: 'var(--Brand-Secondary)' }}>Click on Done above</b> once you have added all the Workstreams to your project. Remember, you can add more
                        Workstreams or edit their respective labels anytime. Clicking Done also returns you to the Data Room.
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-3">
                        {/* <button onClick={() => dispatch(setJoyRideStepIndex(0))} className="btn btn-primary smallButton">
                            Next
                        </button> */}
                        <span style={{ color: 'var(--muted)' }} role="button" onClick={() => dispatch(setJoyRideStepIndex(2))}>
                            Previous
                        </span>
                    </div>
                </div>
            ),
            hideFooter: true,
            disableBeacon: true,
            disableOverlayClose: true,
            spotlightClicks: true,
            styles: {
                options: {
                    zIndex: 10000,
                },
            },
        },
        {
            //Add Folder Button
            target: '.add-folder-button-step',
            content: (
                <div className="ps-2 pe-2 regularFont">
                    <div className="text-end">
                        <AiOutlineClose color="var(--Brand-Secondary)" onClick={() => setRunJoyRide(false)} role="button" />
                    </div>
                    <div className="text-start">
                        After selecting a Workstream, click Add Folder to add folders to the respective workstream.
                        <b style={{ color: 'var(--Brand-Secondary)' }}> Click on the above button now to add folders.</b>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-3">
                        {/* <button onClick={() => dispatch(setJoyRideStepIndex(0))} className="btn btn-primary smallButton">
                            Next
                        </button> */}
                        <span
                            style={{ color: 'var(--muted)' }}
                            role="button"
                            onClick={async () => {
                                await setShowWsModal(true);
                                setTimeout(() => {
                                    dispatch(setJoyRideStepIndex(3));
                                }, 300);
                            }}>
                            Previous
                        </span>
                    </div>
                </div>
            ),
            hideFooter: true,
            disableBeacon: true,
            disableOverlayClose: true,
            spotlightClicks: true,
            styles: {
                options: {
                    zIndex: 10000,
                },
            },
        },
        {
            //Select Folders to Add
            target: '.create-folder-step',
            content: (
                <div className="ps-2 pe-2 regularFont">
                    <div className="text-end">
                        <AiOutlineClose color="var(--Brand-Secondary)" onClick={() => setRunJoyRide(false)} role="button" />
                    </div>
                    <div className="text-start">
                        You can add pre-defined Folders to your Workstream. You can add more folders or edit their labels and description anytime.{' '}
                        <b style={{ color: 'var(--Brand-Secondary)' }}>Click on any folder you would like to add to the workstream.</b>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-3">
                        <span
                            style={{ color: 'var(--muted)' }}
                            role="button"
                            onClick={() => {
                                setAddFolderModal(false);
                                dispatch(setJoyRideStepIndex(4));
                            }}>
                            Previous
                        </span>
                        {/* {items?.length > 0 && (
                            <button onClick={() => dispatch(setJoyRideStepIndex(6))} className="btn btn-primary smallButton">
                                Next
                            </button>
                        )} */}
                    </div>
                </div>
            ),
            hideFooter: true,
            disableBeacon: true,
            disableOverlayClose: true,
            spotlightClicks: true,
            styles: {
                options: {
                    zIndex: 10000,
                },
            },
        },
        {
            //Close Add Folder Button
            target: '.done-add-folder-step',
            content: (
                <div className="ps-2 pe-2 regularFont">
                    <div className="text-end">
                        <AiOutlineClose color="var(--Brand-Secondary)" onClick={() => setRunJoyRide(false)} role="button" />
                    </div>
                    <div className="text-start">
                        <b style={{ color: 'var(--Brand-Secondary)' }}> Click on Done</b> once you have added all the folders to your workstream. Clicking Done also returns you to
                        the Data Room where you can start adding files to your folders.
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-3">
                        <span style={{ color: 'var(--muted)' }} role="button" onClick={() => dispatch(setJoyRideStepIndex(5))}>
                            Previous
                        </span>
                    </div>
                </div>
            ),
            hideFooter: true,
            disableBeacon: true,
            disableOverlayClose: true,
            spotlightClicks: true,
            styles: {
                options: {
                    zIndex: 10000,
                },
            },
        },
        {
            //Select  Folder
            target: '.select-folder-step',
            content: (
                <div className="ps-2 pe-2 regularFont">
                    <div className="text-end">
                        <AiOutlineClose color="var(--Brand-Secondary)" onClick={() => setRunJoyRide(false)} role="button" />
                    </div>
                    <div className="text-start">
                        Each folder has a numbering system for easy reference. On the right side you can find the number of files in that folder.
                        <b style={{ color: 'var(--Brand-Secondary)' }}> Click on the folder above </b>to start adding files.
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-3">
                        <span
                            style={{ color: 'var(--muted)' }}
                            role="button"
                            onClick={async () => {
                                await setAddFolderModal(true);
                                setTimeout(() => {
                                    dispatch(setJoyRideStepIndex(6));
                                }, 300);
                            }}>
                            Previous
                        </span>
                    </div>
                </div>
            ),
            hideFooter: true,
            disableBeacon: true,
            disableOverlayClose: true,
            spotlightClicks: true,
            styles: {
                options: {
                    zIndex: 10000,
                },
            },
        },
        {
            //Select  Folder
            target: '.add-file-step',
            content: (
                <div className="ps-2 pe-2 regularFont">
                    <div className="text-end">
                        <AiOutlineClose color="var(--Brand-Secondary)" onClick={() => setRunJoyRide(false)} role="button" />
                    </div>
                    <div className="text-start">
                        You can click 'Add Files' to add your files to this folder or you can simply drag and drop the files on this icon. Files will be listed on this panel once
                        upload is complete.
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-3">
                        <span style={{ color: 'var(--muted)' }} role="button" onClick={() => dispatch(setJoyRideStepIndex(7))}>
                            Previous
                        </span>
                        <button onClick={() => dispatch(setJoyRideStepIndex(9))} className="btn btn-primary smallButton">
                            Next
                        </button>
                    </div>
                </div>
            ),
            hideFooter: true,
            disableBeacon: true,
            disableOverlayClose: true,
            spotlightClicks: true,
            styles: {
                options: {
                    zIndex: 10000,
                },
            },
        },
        {
            //view-tour
            target: '.view-tour',
            content: (
                <div className="ps-2 pe-2 regularFont">
                    <div className="text-end">
                        <AiOutlineClose color="var(--Brand-Secondary)" onClick={() => setRunJoyRide(false)} role="button" />
                    </div>
                    <div className="text-start">This completes the tour of the Data Room. You can always access feature tours using this View Tour button.</div>
                    <div className="d-flex align-items-center justify-content-between mt-3">
                        <span style={{ color: 'var(--muted)' }} role="button" onClick={() => dispatch(setJoyRideStepIndex(8))}>
                            Previous
                        </span>
                        <button onClick={() => setRunJoyRide(false)} className="btn btn-primary smallButton">
                            Exit
                        </button>
                    </div>
                </div>
            ),
            hideFooter: true,
            disableBeacon: true,
            disableOverlayClose: true,
            spotlightClicks: true,
            styles: {
                options: {
                    zIndex: 10000,
                },
            },
        },
    ]);

    React.useEffect(() => {
        const isFirstTimeUser = !Cookies.get('dataroomJoyride');
        if (isFirstTimeUser) {
            dispatch(setJoyRideStepIndex(0));
            setRunJoyRide(true);
            Cookies.set('dataroomJoyride', true);
        }
    }, []);
    return (
        <Layout container={true}>
            <Pageheader title="LENS - Data Room" />
            {/* {runJoyRide && (
                <ReactJoyride
                    run={runJoyRide}
                    steps={joyRideSteps}
                    stepIndex={joyRideStepIndex}
                    continuous
                    hideCloseButton
                    spotlightPadding={5}
                    styles={{
                        tooltip: {
                            padding: 0,
                        },
                    }}
                />
            )}
            <Dataroom
                setRunJoyRide={setRunJoyRide}
                setShowWsModal={setShowWsModal}
                addFolderModal={addFolderModal}
                setAddFolderModal={setAddFolderModal}
                showWsModal={showWsModal}
            /> */}

            <Dataroom2 />
            <ReactInternetSpeedMeter
                txtSubHeading=""
                outputType="alert"
                customClassName={null}
                txtMainHeading=""
                pingInterval={60000} // milliseconds
                thresholdUnit="megabyte" // "byte" , "kilobyte", "megabyte"
                threshold={100}
                imageUrl="https://beyond-ma.com/wp-content/uploads/2023/07/Lens_Logo.png"
                downloadSize="1781287" //bytes
                callbackFunctionOnNetworkDown={speed => console.log(`Internet speed is down ${speed}`)}
                callbackFunctionOnNetworkTest={speed => {
                    if (parseFloat(speed) <= 6.5) {
                        toast.warn('Internet speed is slow, it may impact the perfomance of application', {
                            position: 'top-right',
                            autoClose: 5000,
                        });
                    }
                }}
            />
        </Layout>
    );
};

export default DataroomView;
