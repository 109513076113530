import axios from 'axios';
import Cookies from 'js-cookie';
import TokenService from './tokenservices/token.service';
import { API_BASE_URL, TOKEN_REFRESH } from './api_routes';
import { setAccessToken } from '../redux/usersSlice';
import jwt_decode from 'jwt-decode';
import dayjs from 'dayjs';
const baseUrl = API_BASE_URL;
let store;

axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';

export const injectStore = _store => {
    store = _store;
};

const axiosInstance = axios.create({
    baseURL: baseUrl,
    timeout: 900000,
    headers: {
        'Content-Type': 'application/json',
        accept: 'application/json',
    },
});

// axiosInstance.interceptors.request.use(
//     config => {
//         const token = store?.getState()?.user?.access;
//         if (token) {
//             config.headers = {
//                 Authorization: `Bearer ${token}`,
//             };
//         }
//         return config;
//     },
//     error => {
//         return Promise.reject(error);
//     },
// );
axiosInstance.interceptors.request.use(async req => {
    const token = store?.getState()?.user?.access;
    if (token) {
        req.headers = {
            Authorization: `Bearer ${token}`,
        };
    }

    const user = jwt_decode(token);
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;
    if (!isExpired) return req;
    try {
        const response = await axios.post(`${API_BASE_URL}/${TOKEN_REFRESH}`, {
            refresh: Cookies.get('refresh'),
        });
        const { access } = response?.data;
        store.dispatch(setAccessToken(access));
        localStorage.setItem('authTokens', JSON.stringify(response.data));
        req.headers.Authorization = `Bearer ${access}`;
    } catch (err) {
        store.dispatch(setAccessToken(''));
        Cookies.set('refresh', '');
        window.location.href = '/';
    }

    return req;
});

export default axiosInstance;
