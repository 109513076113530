import React from 'react';
import styles from './css/index.module.css';

import {
    ArrowSortUp16Regular,
    Folder20Filled,
    MoreVertical16Regular,
    Delete16Regular,
    Info16Regular,
    DataScatter20Regular,
    MoreVertical20Regular,
    TextEditStyle16Regular,
    CloudArrowUp48Regular,
    Eye20Regular,
} from '@fluentui/react-icons';
import { Menu, MenuDivider, MenuItem, MenuList, MenuPopover, MenuTrigger, Tooltip } from '@fluentui/react-components';
import { DeleteBulkFiles, PostIRLDocuments } from '../../redux/documentsSlice';

import { useDispatch, useSelector } from 'react-redux';
import { getExtensionIcon, isValidExtensionToUpload } from '../../utils/commonUtils';
import { format } from 'date-fns';

import DoubleConfirmationModal from '../CommonComponents/DoubleConfirmationModal/DoubleConfirmationModal';
import { DeleteWSFolder } from '../../redux/dataroomSlice';
import { toast } from 'react-toastify';

const FolderDetailPanel = ({
    selectedFolders,
    setSelectedFolders,
    workstream,
    handleOpenFileInfoSidebar,
    handleOpenFile,
    selectedFolder,
    folderFiles,
    handleOpenFolderSidebar,
    selectedFile,
    showSidebar,
    setSelectedFile,
    setShowAnalytics,
    selectedRows,
    setSelectedRows,
    handleOpenEditFolder,
}) => {
    const { projectUsersAll } = useSelector(state => state?.team);
    const { user } = useSelector(state => state?.user);
    const { selectedProject } = useSelector(state => state?.projectData);
    const [uploadedSort, setUploadedSort] = React.useState('asc');
    const [isDragging, setDragging] = React.useState(false);
    const [sortedFiles, setSortedFiles] = React.useState(folderFiles);
    const dispatch = useDispatch();
    const { items } = useSelector(state => state?.dataroom);
    const deleteFileRef = React.useRef();
    const deleteFolderRef = React.useRef();
    const getUserDetail = id => {
        let addedBy = projectUsersAll?.find(item => item?.value?.group_member_id === id);
        return addedBy?.value?.full_name || 'Project Admin';
    };
    const handleSelectRows = id => {
        if (selectedRows?.find(x => x === id)) {
            setSelectedRows(selectedRows.filter(x => x !== id));
        } else {
            setSelectedRows([...selectedRows, id]);
        }
    };
    const handleSelectAllFilter = val => {
        if (val) {
            let ids = [];
            folderFiles?.forEach(doc => {
                if (doc?.ws_folder_id === selectedFolder?.id) ids.push(doc?.id);
            });
            setSelectedRows(ids);
        } else setSelectedRows([]);
    };

    const handleSortAdded = () => {
        setUploadedSort(uploadedSort === 'asc' ? 'desc' : 'asc');
        var data = [...folderFiles];
        data.sort((a, b) => {
            let da = new Date(a?.created),
                db = new Date(b?.created);
            return uploadedSort === 'asc' ? db - da : da - db;
        });
        setSortedFiles([...data]);
    };

    const handleSortUploadedBy = () => {
        setUploadedSort(uploadedSort === 'asc' ? 'desc' : 'asc');
        const data = [...folderFiles];
        data.sort((a, b) => {
            const nameA = getUserDetail(a?.upload_by); // ignore upper and lowercase
            const nameB = getUserDetail(b?.upload_by); // ignore upper and lowercase
            if (uploadedSort === 'asc') {
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            } else {
                if (nameA > nameB) {
                    return -1;
                }
                if (nameA < nameB) {
                    return 1;
                }
                return 0;
            }
        });
        setSortedFiles([...data]);
    };
    const handleDragEnter = e => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(true);
    };

    const handleDragLeave = e => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
    };

    const handleDragOver = e => {
        e.preventDefault();
        e.stopPropagation();
    };
    const handleDrop = async e => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(false);
        if (selectedFolders?.length > 0) {
            const filesArray = Array.from(e.dataTransfer.files).filter(file => {
                const extension = file.name.split('.').pop().toLowerCase();
                return isValidExtensionToUpload(extension);
            });
            const filePromiseToast = toast.loading(`Uploading ${filesArray?.length} documents to ${selectedFolder?.name}`, { closeButton: true });
            const formData = new FormData();
            for (const item of filesArray) {
                formData.append('document', item);
            }
            formData.append('ws_folder', parseInt(selectedFolder?.id));
            formData.append('project', selectedProject?.id);
            formData.append('vdr', selectedProject?.vdr?.id);
            if (selectedProject?.vdr?.vdr_admin !== user?.id) {
                formData.append('upload_by', projectUsersAll?.find(item => item?.value?.user_id === user?.id)?.value?.group_member_id);
            }
            try {
                let res = await dispatch(PostIRLDocuments({ body: formData, ws_id: workstream?.id }));
                // dispatch(setIRLItemsRecieved({ id: selectedFolder?.id, flag: true }));
                if (res?.type === 'POST_IRL_DOC/rejected') {
                    console.log(res);
                    if (res?.payload?.detail) {
                        toast.update(filePromiseToast, {
                            render: res?.payload?.detail,
                            type: 'error',
                            isLoading: false,
                            autoClose: 2000,
                        });
                    } else {
                        toast.update(filePromiseToast, {
                            render: `Uploading  Failed`,
                            type: 'error',
                            isLoading: false,
                            autoClose: 2000,
                        });
                    }
                } else {
                    // If the upload is successful, update the file-specific toast
                    toast.update(filePromiseToast, {
                        render: `Uploaded successfully`,
                        type: 'success',
                        isLoading: false,
                        autoClose: 2000,
                    });
                }
            } catch (uploadError) {
                console.error(`Error uploading`, uploadError);
                toast.update(filePromiseToast, {
                    render: `Error uploading`,
                    type: 'error',
                    isLoading: false, // Make sure to update the loading state in case of an error
                    autoClose: 2000,
                });
            }
        }
    };

    const handleDeleteFolder = () => {
        if (selectedFolders?.length === 1) {
            dispatch(DeleteWSFolder(selectedFolder));
            setSelectedFolders([]);
        } else {
            dispatch(DeleteWSFolder(selectedFolder));
            let newFoldersArray = selectedFolders?.filter(item => item?.id !== selectedFolder?.id);
            newFoldersArray = newFoldersArray?.map((item, index) => {
                if (index === Number(newFoldersArray?.length - 1)) {
                    return {
                        ...item,
                        children: item?.children?.filter(child => child?.id !== selectedFolder?.id),
                    };
                } else return item;
            });
            setSelectedFolders(newFoldersArray);
        }
    };
    const getUserNameByGroupMemberId = id => {
        let user = projectUsersAll?.find(item => item?.value?.group_member_id === id);
        return <p className={styles.smallFont + ' m-0'}>{user?.value?.full_name}</p>;
    };
    React.useEffect(() => {
        setSortedFiles([...folderFiles]);
    }, [folderFiles]);
    return (
        <div>
            <div style={{ height: 124, overflow: 'auto' }} className={styles.whiteBackground + ' p-0 mb-1'}>
                {workstream && items.length === 0 && (
                    <div onClick={handleOpenFolderSidebar} className={styles.noFolderText}>
                        Add or Upload Folders to the Workstream
                    </div>
                )}
                {items?.length > 0 && selectedFolder && (
                    <div className={styles.folderDetailTop}>
                        <div className="d-flex justify-content-between">
                            <div className="">
                                <h5 className={styles.folderName}>{selectedFolders?.[0]?.name}</h5>
                                <p className={styles.fodlerDesc}>{selectedFolders?.[0]?.description}</p>
                            </div>{' '}
                            <div className="">
                                <Menu>
                                    <MenuTrigger disableButtonEnhancement>
                                        <MoreVertical20Regular className={styles.folderKebab} color="var(--Neutral-Gray-6)" />
                                    </MenuTrigger>
                                    <MenuPopover>
                                        <MenuList>
                                            <MenuItem onClick={handleOpenEditFolder} icon={<TextEditStyle16Regular />}>
                                                Edit Label
                                            </MenuItem>
                                            <MenuDivider />
                                            <MenuItem
                                                onClick={() => {
                                                    setSelectedFolders(selectedFolders?.slice(0, 1));
                                                    setShowAnalytics(true);
                                                }}
                                                icon={<DataScatter20Regular />}>
                                                Analytics
                                            </MenuItem>
                                            <MenuDivider />
                                            <MenuItem onClick={() => deleteFolderRef?.current?.click()} icon={<Delete16Regular />}>
                                                Delete Folder
                                            </MenuItem>
                                        </MenuList>
                                    </MenuPopover>
                                </Menu>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                className={styles.whiteBackground + ' p-0 position-relative'}>
                {selectedFolders?.length > 0 && isDragging && (
                    <div onDragEnter={handleDragEnter} className={styles.dragBG}>
                        <div className={styles.dropMessageBody}>
                            <CloudArrowUp48Regular className="me-3" color="white" />
                            <div>
                                <p>Drop files to upload them to</p>
                                <h5>{selectedFolder?.ws_name}</h5>
                            </div>
                        </div>
                    </div>
                )}

                {!isDragging && items?.length > 0 && selectedFolder && (
                    <table style={{ pointerEvents: isDragging && 'none' }} className="w-100">
                        <thead>
                            <tr className={styles.tableRow + ' border-0'}>
                                <td className={styles.tableHeadItem}>
                                    <div className="d-flex align-items-center">
                                        <div style={{ visibility: selectedRows?.length > 0 && 'visible' }} className={styles.headerTableInput}>
                                            <input
                                                type="checkbox"
                                                checked={
                                                    selectedRows?.length > 0 && selectedRows?.length === folderFiles?.filter(d => d?.ws_folder_id === selectedFolder?.id).length
                                                        ? true
                                                        : false
                                                }
                                                onChange={e => handleSelectAllFilter(e.target.checked)}
                                            />
                                        </div>
                                        <span>Name</span>
                                    </div>
                                </td>
                                <td style={{ width: 40 }} className={styles.tableHeadItem}></td>
                                <td onClick={handleSortUploadedBy} style={{ width: 150 }} role="button" className={styles.tableHeadItem}>
                                    Posted by <ArrowSortUp16Regular className="ms-1" />
                                </td>
                                <td style={{ width: 109 }} onClick={handleSortAdded} role="button" className={styles.tableHeadItem}>
                                    <div style={{ width: 109 }} className="d-flex">
                                        Uploaded on
                                        <ArrowSortUp16Regular className="ms-1" />
                                    </div>
                                </td>
                                <td style={{ width: 30 }} className={styles.tableHeadItem}></td>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedFolder?.children?.map(subFolder => (
                                <tr className={styles.tableRow}>
                                    <td className={styles.tableItem}>
                                        <div role="button" onClick={() => setSelectedFolders([...selectedFolders, subFolder])} className="d-flex align-items-center">
                                            <Folder20Filled color="#FFD800" className="me-2" />
                                            {subFolder?.prefix} {subFolder?.name}
                                        </div>
                                    </td>
                                    <td onClick={() => setSelectedFolders([...selectedFolders, subFolder])} className={styles.tableItem}></td>
                                    <td onClick={() => setSelectedFolders([...selectedFolders, subFolder])} className={styles.tableItem}></td>
                                    <td onClick={() => setSelectedFolders([...selectedFolders, subFolder])} className={styles.tableItem}></td>
                                    <td className={styles.tableItem}>
                                        <Menu>
                                            <MenuTrigger disableButtonEnhancement>
                                                <MoreVertical16Regular className={styles.tableKebabMenu} color="var(--Neutral-Gray-6)" />
                                            </MenuTrigger>
                                            <MenuPopover>
                                                <MenuList>
                                                    <MenuItem onClick={handleOpenEditFolder} icon={<TextEditStyle16Regular />}>
                                                        Edit Label
                                                    </MenuItem>
                                                    <MenuDivider />
                                                    <MenuItem
                                                        onClick={() => {
                                                            setSelectedFolders([...selectedFolders, subFolder]);
                                                            setShowAnalytics(true);
                                                        }}
                                                        icon={<DataScatter20Regular />}>
                                                        Analytics
                                                    </MenuItem>
                                                    <MenuDivider />
                                                    <MenuItem
                                                        onClick={() => {
                                                            setSelectedFolders([...selectedFolders, subFolder]);
                                                            deleteFolderRef?.current?.click();
                                                        }}
                                                        icon={<Delete16Regular />}>
                                                        Delete Folder
                                                    </MenuItem>
                                                </MenuList>
                                            </MenuPopover>
                                        </Menu>
                                    </td>
                                </tr>
                            ))}

                            {sortedFiles?.map((file, index) => (
                                <tr className={styles.tableRow}>
                                    <td role="button" className={styles.tableItem + ' pt-0 pb-0'}>
                                        <div className="d-flex align-items-center">
                                            <div className={styles.tableRolloverItem}>
                                                <span style={{ visibility: selectedRows?.find(x => x === file?.id) && 'hidden' }}>
                                                    {getExtensionIcon((file?.document || file?.document_link)?.split('.').pop(), 20, 21, file?.document_link)}
                                                </span>
                                                <input
                                                    type={'checkbox'}
                                                    style={{ visibility: selectedRows?.find(x => x === file?.id) && 'visible' }}
                                                    checked={selectedRows?.find(x => x === file?.id)}
                                                    onChange={e => handleSelectRows(file?.id, e.target.checked)}
                                                    className={styles.rowCheckbox}
                                                />
                                            </div>
                                            <div onClick={() => handleOpenFile(file, index)} className={styles.tableFirstRowItem}>
                                                <Tooltip positioning="below" withArrow content={selectedFolder?.prefix + '.' + file?.item_num + ' ' + file?.document_name}>
                                                    <span style={{ maxWidth: showSidebar && '280px' }} className="ms-2 text-truncate">
                                                        {selectedFolder?.prefix + '.'}
                                                        {file?.item_num} {file?.document_name}
                                                    </span>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </td>
                                    <td onClick={() => handleOpenFile(file, index)} className={styles.tableItem}>
                                        <div className="position-relative">
                                            {file?.read_by?.length > 0 && (
                                                <>
                                                    <Eye20Regular className={styles.readByIcon} />

                                                    <div className={styles.readBody}>
                                                        {file?.read_by?.map(reader => (
                                                            <p key={reader}>{getUserNameByGroupMemberId(reader)}</p>
                                                        ))}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </td>
                                    <td onClick={() => handleOpenFile(file, index)} className={styles.tableItem}>
                                        <div style={{ width: 150 }} className="text-truncate">
                                            {' '}
                                            {getUserDetail(file?.upload_by)}
                                        </div>
                                    </td>
                                    <td onClick={() => handleOpenFile(file, index)} className={styles.tableItem}>
                                        {file?.created && format(new Date(file?.created), 'dd/MM/yyyy')}
                                    </td>
                                    <td className={styles.tableItem}>
                                        <div style={{ width: 20 }}>
                                            {' '}
                                            <Menu positioning="below-start">
                                                <MenuTrigger disableButtonEnhancement>
                                                    <MoreVertical16Regular className={styles.tableKebabMenu} color="var(--Neutral-Gray-6)" />
                                                </MenuTrigger>
                                                <MenuPopover>
                                                    <MenuList>
                                                        <MenuItem onClick={() => handleOpenFileInfoSidebar(file)} icon={<Info16Regular />}>
                                                            Get Info
                                                        </MenuItem>
                                                        <MenuDivider />
                                                        <MenuItem
                                                            onClick={() => {
                                                                setSelectedFile(file);
                                                                deleteFileRef?.current?.click();
                                                            }}
                                                            icon={<Delete16Regular />}>
                                                            Delete File
                                                        </MenuItem>
                                                    </MenuList>
                                                </MenuPopover>
                                            </Menu>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
            <DoubleConfirmationModal
                isMenuChild={true}
                handleAction={() => {
                    dispatch(DeleteBulkFiles({ items: [selectedFile?.id], ws_id: workstream?.id }));
                    setSelectedFile();
                }}
                message={'Deleting a file permanently removes it from the Project, and this is irreversible.'}>
                <div ref={deleteFileRef}></div>
            </DoubleConfirmationModal>
            <DoubleConfirmationModal handleAction={handleDeleteFolder} message={'Deleting a Folder will also delete all files in the folder'}>
                <div ref={deleteFolderRef}></div>
            </DoubleConfirmationModal>
        </div>
    );
};
export default FolderDetailPanel;
