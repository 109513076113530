import React from 'react';
import styles from './css/index.module.css';
import { Input } from '@fluentui/react-components';
import { PatchProjectData } from '../../redux/projectDataSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
const AddCompetitors = () => {
    const dispatch = useDispatch();

    const { projectDetailButtonLoading, selectedProject } = useSelector(state => state.projectData);
    const [formData, setFormData] = React.useState(['']);

    const handleChange = (e, index) => {
        let data = [...formData];
        data[index] = e.target.value;
        setFormData(data);
    };
    const handleSubmit = async () => {
        await dispatch(
            PatchProjectData({
                data: {
                    competitors: formData,
                },
                id: selectedProject?.id,
            }),
        );
        // setFormData([]);
    };
    const handleSetFormValues = () => {
        setFormData(selectedProject?.competitors || []);
    };
    const compareObjectsValues = (arr1 = [], arr2 = []) => {
        if (arr1?.length !== arr2?.length) {
            return false;
        }
        for (let i = 0; i < arr1?.length; i++) {
            if (arr1[i] !== arr2[i]) {
                return false;
            }
        }
        return true;
    };
    React.useEffect(() => {
        if (selectedProject) {
            handleSetFormValues();
        }
    }, [selectedProject]);
    console.log(formData);
    return (
        <div className={styles.componentCard}>
            <h5 className={styles.componentTitle}>Competitors</h5>
            <div className="mb-4">
                {formData?.map((item, index) => (
                    <div className="mb-2">
                        <p className={styles.fieldLabel}>Competitor website</p>
                        <Input placeholder="Type here" className={styles.inputField + ' mb-2'} value={item} onChange={e => handleChange(e, index)} />
                    </div>
                ))}
                <p onClick={() => setFormData([...formData, ''])} className={styles.linkBtn}>
                    Add another
                </p>
            </div>
            <div className={styles.buttonDiv}>
                {!compareObjectsValues(selectedProject?.competitors, formData) && (
                    <div className="d-flex align-items-center justify-content-between">
                        <button onClick={handleSubmit} className={styles.submitButton}>
                            Save {projectDetailButtonLoading && <AiOutlineLoading3Quarters className="ms-2 rotateLoader" />}
                        </button>
                        <span onClick={handleSetFormValues} className={styles.linkBtn}>
                            Revert
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AddCompetitors;
