export const monthName = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const dayName = ['Monday', 'Tuesday', 'Wednessday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
export const validExtensions = [
    'pdf',
    'fdf',
    'xfdf',
    'doc',
    'docx',
    'xls',
    'csv',
    'xlsx',
    'ppt',
    'pptx',
    'pub',
    'dwg',
    'dxf',
    'dgn',
    'rvt',
    'dwf',
    'rtf',
    'odt',
    'ods',
    'odp',
    'wpf',
    'bmp',
    'wmf',
    'emf',
    'gif',
    'hdp',
    'jpg',
    'jp2',
    'jpc',
    'png',
    'tif',
    'tiff',
    'html',
    'htm',
    'mht',
    'svg',
    'mp4',
    'ogg',
    'webm',
    'msg',
    'txt',
    'ifc',
    'rvt',
    'xps',
    'oxps',
    'xml',
    'xod',
    'xaml',
    'avi',
    'mov',
    'mpg',
    'mpeg',
    'flv',
    'wmv',
    'mkv',
    '3gp',
    'm4v',
    'mp3',
    'wav',
    'aac',
    'flac',
    'ogg',
    'wma',
    'm4a',
];

export const ndaExtensions = ['pdf', 'docx', 'doc', 'jpeg', 'png', 'jpg'];
