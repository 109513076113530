import React from 'react';
import store from './redux/store';
import HttpsRedirect from 'react-https-redirect';
import './index.css';
import './custom.scss';
import Routers from './Routers';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import { injectStore } from './apiServices/axios';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { FluentProvider, teamsLightTheme } from '@fluentui/react-components';
import { createRoot } from 'react-dom/client';
// import theme from './theme';
injectStore(store);
let persistor = persistStore(store);
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <Provider store={store}>
        <HttpsRedirect>
            <PersistGate loading={null} persistor={persistor}>
                <FluentProvider theme={teamsLightTheme}>
                    <Routers />
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        style={{ zIndex: 1000000 }}
                        hideProgressBar
                        newestOnTop={false}
                        rtl={false}
                        closeOnClick
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </FluentProvider>
            </PersistGate>
        </HttpsRedirect>
    </Provider>,
);
