import React from 'react';
import styles from './css/index.module.css';
import { Input, Textarea } from '@fluentui/react-components';
import { Delete20Regular } from '@fluentui/react-icons';
import { validExtensions } from '../../utils/misc';
import { PatchProjectData } from '../../redux/projectDataSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

const AddOrganisationDetail = ({ compareObjectsValues }) => {
    const dispatch = useDispatch();
    const { projectDetailButtonLoading, selectedProject } = useSelector(state => state.projectData);
    const [formData, setFormData] = React.useState({
        sector: '',
        established_year: '',
        description: '',
        employees: '',
        company_size: '',
    });
    const handleChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleSubmit = async () => {
        await dispatch(
            PatchProjectData({
                data: formData,
                id: selectedProject?.id,
            }),
        );
    };

    const handleSetFormValues = () => {
        setFormData({
            sector: selectedProject?.sector,
            established_year: selectedProject?.established_year,
            description: selectedProject?.description,
            company_size: selectedProject?.company_size,
            employees: selectedProject?.employees,
        });
    };
    React.useEffect(() => {
        if (selectedProject) {
            handleSetFormValues();
        }
    }, [selectedProject]);
    return (
        <div className={styles.componentCard}>
            <h5 className={styles.componentTitle}>Organisation details</h5>
            <div>
                <div className="mb-4">
                    <p className={styles.fieldLabel}>Sector</p>
                    <Input placeholder="Software Development" className={styles.inputField} value={formData?.sector} onChange={handleChange} name="sector" />
                </div>
                <div className="row m-0 p-0">
                    <div className="col-sm-12 col-md-6 ms-0 ps-0">
                        <div className="mb-4">
                            <p className={styles.fieldLabel}>Company size</p>
                            <Input className={styles.inputField} placeholder="180" value={formData?.company_size} onChange={handleChange} name="company_size" />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 me-0 pe-0">
                        <div className="mb-4">
                            <p className={styles.fieldLabel}>Number of employees</p>
                            <Input className={styles.inputField} placeholder="180" value={formData?.employees} onChange={handleChange} name="employees" />
                        </div>
                    </div>
                </div>
                <div className="mb-4">
                    <p className={styles.fieldLabel}>About the company</p>
                    <Textarea
                        placeholder="Arthur Miller was an American playwright who redefined the boundaries of modern drama and theatre. His works are studied in schools and universities around the world and are continually performed at the highest level."
                        rows={6}
                        className={styles.textareaField}
                        value={formData?.description}
                        onChange={handleChange}
                        name="description"
                    />
                </div>
                <div className="mb-4 w-50">
                    <p className={styles.fieldLabel}>Year of establishment</p>
                    <Input className={styles.inputField} value={formData?.established_year} onChange={handleChange} name="established_year" />
                </div>
                <div className={styles.buttonDiv}>
                    {!compareObjectsValues(selectedProject, formData) && (
                        <div className="d-flex align-items-center justify-content-between">
                            <button onClick={handleSubmit} className={styles.submitButton}>
                                Save {projectDetailButtonLoading && <AiOutlineLoading3Quarters className="ms-2 rotateLoader" />}
                            </button>
                            <span onClick={handleSetFormValues} className={styles.linkBtn}>
                                Revert
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddOrganisationDetail;
