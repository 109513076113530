import axiosInstance from '../apiServices/axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { DELETE_BULK_TASK, GET_DOCUMENT_NDA, TASK_INTERFACE, TASK_INTERFACE_COMMENTS } from '../apiServices/api_routes';
import { toast } from 'react-toastify';

export const GetDocumentNDA = createAsyncThunk('GET_DOCUMENT_NDA', async id => {
    const data = await axiosInstance.get(GET_DOCUMENT_NDA + id);
    return data;
});
export const PostTask = createAsyncThunk('POST_TASK_INTERFACE', async (body, { rejectWithValue }) => {
    try {
        const data = await axiosInstance.post(TASK_INTERFACE + localStorage['project_id'] + '/', body);
        return data;
    } catch (error) {
        // console.log(error.response);
        return rejectWithValue(error.response.data);
    }
});
export const PatchTask = createAsyncThunk('PATCH_TASK_INTERFACE', async ({ body, id }) => {
    const data = await axiosInstance.patch(TASK_INTERFACE + localStorage['project_id'] + '/' + id + '/', body);
    return data;
});
export const DeleteTask = createAsyncThunk('DELETE_TASK_INTERFACE', async ({ id }) => {
    const data = await axiosInstance.delete(TASK_INTERFACE + localStorage['project_id'] + '/' + id + '/');
    return data;
});
export const DeleteBulkTask = createAsyncThunk('DELETE_BULK_TASK', async body => {
    const data = await axiosInstance.delete(DELETE_BULK_TASK + localStorage['project_id'] + '/', { data: body });
    return data;
});
export const UpdateBulkTask = createAsyncThunk('UPDATE_BULK_TASK', async (body, obj) => {
    const data = await axiosInstance.put(DELETE_BULK_TASK + localStorage['project_id'] + '/', body);
    return data;
});
export const GetTask = createAsyncThunk('GET_TASK_INTERFACE', async () => {
    const data = await axiosInstance.get(TASK_INTERFACE + localStorage['project_id'] + '/');
    return data;
});
export const PostTaskComment = createAsyncThunk('POST_TASK_INTERFACE_COMMENTS', async ({ body }) => {
    const data = await axiosInstance.post(TASK_INTERFACE_COMMENTS + body?.task + '/' + localStorage['project_id'] + '/', body);
    return data;
});
export const GetTaskComment = createAsyncThunk('GET_TASK_INTERFACE_COMMENTS', async ({ taskId }) => {
    const data = await axiosInstance.get(TASK_INTERFACE_COMMENTS + taskId + '/' + localStorage['project_id'] + '/' + '/');
    return data;
});

export const taskSlice = createSlice({
    name: 'taskSlice',
    initialState: {
        taskData: [],
        taskComments: [],
        taskFilters: null,
        taskButtonLoading: false,
        taskCommentBtnLoading: false,
    },
    reducers: {
        resetTaskData: () => {
            return {
                taskData: [],
                taskComments: [],
                taskFilters: null,
                taskButtonLoading: false,
                taskCommentBtnLoading: false,
            };
        },
    },
    extraReducers: {
        //GetTask
        [GetTask.pending]: (state, action) => {
            state.status = 'loading';
        },
        [GetTask.fulfilled]: (state, { payload }) => {
            state.status = 'success';
            state.taskData = payload?.data?.tasks;
            let obj = payload?.data?.filters;
            obj.priority = ['HIGH', 'MEDIUM', 'LOW'];
            state.taskFilters = obj;
            // state.signeesData = action.payload?.data;
        },
        [GetTask.rejected]: (state, action) => {
            state.status = 'rejected';
        },
        //PostTask
        [PostTask.pending]: (state, action) => {
            state.status = 'loading';
            state.taskButtonLoading = true;
        },
        [PostTask.fulfilled]: (state, { payload }) => {
            state.status = 'success';
            toast.success('Success');
            state.taskData.push(payload?.data);
            state.taskButtonLoading = false;
            // state.signeesData = action.payload?.data;
        },
        [PostTask.rejected]: (state, action) => {
            Object.keys(action.payload).map(key => action.payload[key].map(item => toast.error(key.toUpperCase() + ' : ' + item)));
            state.status = 'rejected';
            state.taskButtonLoading = false;
        },
        //PatchTask
        [PatchTask.pending]: (state, action) => {
            state.status = 'loading';
            state.taskButtonLoading = true;
        },
        [PatchTask.fulfilled]: (state, { payload }) => {
            state.status = 'success';
            toast.success('Success');
            state.taskButtonLoading = false;
            state.taskData = state.taskData?.map(item => {
                if (item?.id === payload?.data?.id) {
                    return payload?.data;
                } else return item;
            });
        },
        [PatchTask.rejected]: (state, action) => {
            toast.error('Failed');
            state.taskButtonLoading = false;
            state.status = 'rejected';
        },
        //DeleteTask
        [DeleteTask.pending]: (state, action) => {
            state.status = 'loading';
        },
        [DeleteTask.fulfilled]: (state, { meta }) => {
            state.status = 'success';
            toast.success('Success');
            state.taskData = state.taskData?.filter(item => item?.id !== meta?.arg?.id);
        },
        [DeleteTask.rejected]: (state, action) => {
            toast.error('Failed');
            state.status = 'rejected';
        },
        //GetTaskComment
        [GetTaskComment.pending]: (state, action) => {
            state.status = 'loading';
        },
        [GetTaskComment.fulfilled]: (state, { payload }) => {
            state.status = 'success';
            state.taskComments = payload?.data;
            // state.signeesData = action.payload?.data;
        },
        [GetTaskComment.rejected]: (state, action) => {
            state.status = 'rejected';
        },
        //PostTaskComment
        [PostTaskComment.pending]: (state, action) => {
            state.status = 'loading';
            state.taskCommentBtnLoading = true;
        },
        [PostTaskComment.fulfilled]: (state, { payload }) => {
            state.status = 'success';
            toast.success('Success');
            state.taskComments.push(payload?.data);
            state.taskCommentBtnLoading = false;
        },
        [PostTaskComment.rejected]: (state, action) => {
            toast.error('Failed');
            state.status = 'rejected';
            state.taskCommentBtnLoading = false;
        },

        //DeleteBulkTask
        [DeleteBulkTask.fulfilled]: (state, action) => {
            const ids = action?.meta?.arg?.ids;
            state.taskData = state.taskData.filter(item => {
                let check = ids.find(id => id === item?.id);
                if (!check) return item;
            });
            state.status = 'rejected';
            toast.success('Success');
        },
        [DeleteBulkTask.rejected]: (state, action) => {
            state.status = 'rejected';
            toast.error('Error');
        },
        //UpdateBulkTask
        [UpdateBulkTask.fulfilled]: (state, { payload, meta }) => {
            console.log(payload);
            let newData = state.taskData;
            Object.keys(payload?.data).map(id => {
                newData = newData.map(item => {
                    if (item?.id == id) {
                        let obj = {};
                        Object.keys(payload?.data[id]).map(key => {
                            if (key === 'scope_id' || key === 'assigned_to_id') {
                                obj[key.replace('_id', '')] = payload?.data[id][key];
                            } else {
                                obj[key] = payload?.data[id][key];
                            }
                        });
                        console.log(obj);
                        return { ...item, ...obj };
                    } else return item;
                });
            });
            state.taskData = newData;
            state.status = 'rejected';
            toast.success('Success');
        },
        [UpdateBulkTask.rejected]: (state, action) => {
            state.status = 'rejected';
            toast.error('Error');
        },
    },
});
export const { resetTaskData } = taskSlice.actions;
export default taskSlice.reducer;
