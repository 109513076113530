import React from 'react';
import { GetObservationsByScope, DeleteObservations, PostSchedulerObservations, PatchObservations } from '../../redux/meetingSlice';
import { useDispatch, useSelector } from 'react-redux';
import { BsChevronDown, BsThreeDotsVertical, BsPlus, BsCheckCircleFill, BsChevronUp, BsFillEyeFill } from 'react-icons/bs';
import styles from './css/observation.module.css';
import { FaRegImages } from 'react-icons/fa';
import { Modal, Dropdown, Offcanvas } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import { AiOutlineClose, AiOutlineInfoCircle, AiOutlineLoading3Quarters } from 'react-icons/ai';
import { TiArrowUnsorted } from 'react-icons/ti';
import { BiFilterAlt } from 'react-icons/bi';
import { GetIRLDoc } from '../../redux/documentsSlice';
import { GetDataroomItemInfo, GetFolderDefaults, GetWorkstreams, GetWorkstreamsDefaults, PostWorkstream } from '../../redux/dataroomSlice';
import { FileUploader } from 'react-drag-drop-files';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { RxCross2 } from 'react-icons/rx';
import { format } from 'date-fns';
import { GetProjectUsers } from '../../redux/teamSlice';
import { formatDateDifferenc, htmlToPlainText } from '../../utils/commonUtils';
import DoubleConfirmationModal from '../CommonComponents/DoubleConfirmationModal/DoubleConfirmationModal';
import Select from 'react-select/creatable';
import { useNavigate } from 'react-router';
import { Dismiss20Regular, Circle20Filled } from '@fluentui/react-icons';
import { DrawerBody, Menu, MenuDivider, MenuItem, MenuList, MenuPopover, MenuTrigger, OverlayDrawer } from '@fluentui/react-components';
import { IoChevronBackCircleSharp, IoChevronForwardCircleSharp } from 'react-icons/io5';

const Main = () => {
    const dispatch = useDispatch();
    const { scopeObservations } = useSelector(state => state.meeting);
    const { selectedProject } = useSelector(state => state.projectData);
    const { workstreams } = useSelector(state => state.dataroom);
    const [filteredData, setFilteredData] = React.useState(scopeObservations);
    const [observationFormModal, setObservationFormModal] = React.useState(false);
    const [selectedObservation, setSelectedObservation] = React.useState();
    const [selectedWorkstream, setSelectedWorkstream] = React.useState();
    const [selectedRows, setSelectedRows] = React.useState([]);
    const navigate = useNavigate();
    React.useEffect(() => {
        if (selectedProject) {
            dispatch(GetObservationsByScope('all'));
            dispatch(GetWorkstreams());
            dispatch(GetWorkstreamsDefaults());
            dispatch(GetProjectUsers());
        }
    }, [selectedProject, dispatch]);

    React.useEffect(() => {
        if (scopeObservations && selectedWorkstream) {
            setFilteredData(scopeObservations?.filter(item => item?.workstream_repr === selectedWorkstream?.name));
        }
    }, [scopeObservations, selectedWorkstream]);
    React.useEffect(() => {
        if (workstreams?.length > 0) {
            if (localStorage['workstream']) {
                const wsFromLocal = workstreams?.find(item => item?.id === parseInt(localStorage['workstream']));
                if (wsFromLocal) setSelectedWorkstream(wsFromLocal);
            } else setSelectedWorkstream(workstreams[0]);
        }
    }, [workstreams]);
    const enabledSwotBtn = selectedWorkstream && filteredData?.find(item => item?.swot);
    return (
        <div>
            <div className={styles.headerSection}>
                <h5 className={styles.headerTitle}>Observations</h5>
            </div>
            <div className="mb-3">
                <div className="d-flex align-items-center  ">
                    <div style={{ width: 250 }} className="me-5">
                        {' '}
                        <WorkstreamSelect selectedOption={selectedWorkstream} setSelectedOption={setSelectedWorkstream} />
                    </div>

                    <p className={styles.orangeBtnText} style={{ color: !enabledSwotBtn && 'var(--muted)' }} onClick={() => enabledSwotBtn && navigate('/swot')}>
                        View SWOT Analysis
                    </p>
                </div>
            </div>

            <>
                <div className="d-flex align-items-center justify-content-between">
                    <div className={styles.menuButton}>
                        {selectedWorkstream?.name} <BsThreeDotsVertical />
                    </div>
                    {/* {selectedRows?.length > 0 ? (
                        <button
                            onClick={() => {
                                // setSelectedObservation();
                                // setObservationFormModal(true);
                            }}
                            type="button"
                            className={`btn btn-primary btn-sm ms-4 ${styles.smallButton}`}>
                            Delete
                        </button>
                    ) : (
                        <button
                            onClick={() => {
                                setSelectedObservation();
                                setObservationFormModal(true);
                            }}
                            type="button"
                            className={`btn btn-primary btn-sm ms-4 ${styles.smallButton}`}>
                            Add Observation <BsPlus size={15} />
                        </button>
                    )} */}
                    <button
                        onClick={() => {
                            setSelectedObservation();
                            setObservationFormModal(true);
                        }}
                        type="button"
                        className={`btn btn-primary btn-sm ms-4 ${styles.smallButton}`}>
                        Add Observation <BsPlus size={15} />
                    </button>
                </div>
                <div className={styles.tabViewBody}>
                    <ObservationTable
                        selectedWorkstream={selectedWorkstream}
                        filteredData={filteredData}
                        setFilteredData={setFilteredData}
                        selectedObservation={selectedObservation}
                        setSelectedObservation={setSelectedObservation}
                        setObservationFormModal={setObservationFormModal}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                    />
                </div>
            </>
            <ObservationFormSidebar
                show={observationFormModal}
                setShow={setObservationFormModal}
                selectedObservation={selectedObservation}
                setSelectedObservation={setSelectedObservation}
            />
        </div>
    );
};

const ObservationTable = ({
    filteredData,
    setFilteredData,
    selectedObservation,
    setSelectedObservation,
    setObservationFormModal,
    selectedWorkstream,
    selectedRows,
    setSelectedRows,
}) => {
    const dispatch = useDispatch();
    const { projectUsersAll } = useSelector(state => state.team);
    const { scopeObservations } = useSelector(state => state.meeting);
    const [observationFilters, setObservationFilters] = React.useState({});
    const [viewObservationModal, setViewObservationModal] = React.useState(false);
    const [addedOnSort, setAddedOnSort] = React.useState('asc');
    const [claimSort, setClaimSort] = React.useState('asc');
    const [tableFilters, setTableFilters] = React.useState({});

    const [columnState, setColumnState] = React.useState({
        number: { label: 'Number', value: true },
        tags: { label: 'Tags', value: true },
        observation: { label: 'Observation', value: true },
        significance: { label: 'Significance', value: true },
        rag: { label: 'Rag', value: true },
        swot: { label: 'Swot', value: true },
        claim: { label: 'Claim', value: true },
        addedOn: { label: 'Added on', value: true },
        addedBy: { label: 'Added By', value: true },
    });
    React.useEffect(() => {
        let nf = {};
        if (observationFilters) {
            Object.keys(observationFilters)?.map(key => {
                if (typeof observationFilters[key] === 'object') {
                    let temp = [];
                    observationFilters[key].map(item => {
                        temp.push({
                            label: item,
                            category: key,
                            value: true,
                        });
                    });
                    nf = {
                        ...nf,
                        [key?.replace(' ', '_')]: temp,
                    };
                } else {
                    nf = {
                        ...nf,
                        answered: true,
                    };
                }
            });
            setTableFilters(nf);
        }
    }, [observationFilters]);

    const handleChangeFilters = (label, category, value) => {
        let data = tableFilters;
        data[category] = data[category]?.map(item => {
            if (item.label === label) {
                item.value = value;
            }
            return item;
        });
        setTableFilters({ ...data });
        handleUpdateFilters(data);
    };
    const handleUpdateFilters = updatedFilters => {
        let data = scopeObservations?.filter(item => item?.workstream_repr === selectedWorkstream?.name);
        if (updatedFilters?.tags && updatedFilters?.tags.length > 0) {
            updatedFilters?.tags?.map(item => {
                if (!item.value) {
                    data = data.filter(d => d.tag_repr !== item?.label);
                }
            });
        }
        if (updatedFilters?.rag && updatedFilters?.rag.length > 0) {
            updatedFilters?.rag?.map(item => {
                if (!item.value) {
                    data = data.filter(d => d.assessment !== item?.label);
                }
            });
        }
        if (updatedFilters?.swot && updatedFilters?.swot.length > 0) {
            updatedFilters?.swot?.map(item => {
                if (!item.value) {
                    data = data.filter(d => d.swot !== item?.label);
                }
            });
        }

        setFilteredData([...data]);
    };

    const FilterIcon = React.forwardRef(({ onClick }, ref) => (
        <BiFilterAlt
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
            className="me-2"
            color="#829ab1"
            size={14}
            role="button"
        />
    ));
    const filterItemsWithValueFalse = obj => {
        const filteredItems = {};

        for (const key in obj) {
            if (Array.isArray(obj[key])) {
                const filteredArray = obj[key].filter(item => item.value === false);
                if (filteredArray.length > 0) {
                    filteredItems[key] = filteredArray;
                }
            }
        }

        return filteredItems;
    };
    const setAllValuesToTrue = (obj, key) => {
        if (obj.hasOwnProperty(key) && Array.isArray(obj[key])) {
            obj[key].forEach(item => {
                item.value = true;
            });
        }
        handleUpdateFilters(obj);
        setTableFilters({ ...obj });
    };
    const handleSortAdded = () => {
        setAddedOnSort(addedOnSort === 'asc' ? 'desc' : 'asc');
        var data = [...filteredData];

        data.sort((a, b) => {
            let da = new Date(a.created),
                db = new Date(b.created);
            return addedOnSort === 'asc' ? db - da : da - db;
        });
        setFilteredData(data);
    };
    const handleSortClaim = () => {
        setClaimSort(claimSort === 'asc' ? 'desc' : 'asc');
        const data = [...filteredData];
        data.sort((a, b) => {
            if (a?.claim && !b?.claim) {
                return claimSort === 'asc' ? -1 : 1;
            } else if (!a?.claim && b?.claim) {
                return claimSort === 'asc' ? 1 : -1;
            } else {
                return 0;
            }
        });
        setFilteredData(data);
    };

    const handleOpenViewObservation = item => {
        setSelectedObservation(item);
        setViewObservationModal(true);
    };
    const handleSelectAllFilter = val => {
        if (val) {
            let ids = [];
            filteredData?.forEach(doc => {
                ids.push(doc?.id);
            });
            setSelectedRows(ids);
        } else setSelectedRows([]);
    };
    const handleSelectRows = id => {
        if (selectedRows?.find(x => x === id)) {
            setSelectedRows(selectedRows.filter(x => x !== id));
        } else {
            setSelectedRows([...selectedRows, id]);
        }
    };
    React.useEffect(() => {
        setSelectedRows([]);
    }, [selectedWorkstream]);
    const tableActiveFilters = filterItemsWithValueFalse(tableFilters);

    const generateFiltersFromObservations = (observations, filters) => {
        observations.forEach(observation => {
            filters.workstreams.add(observation.workstream_repr);
            filters.tags.add(observation.tag_repr);
            if (observation.observation_type) {
                filters.obs_types.add(observation.observation_type);
            }
            filters.rag.add(observation.assessment);
            filters.swot.add(observation.swot);
            if (observation.claim) {
                filters.claims = true;
            }
        });
        filters.workstreams = Array.from(filters.workstreams);
        filters.tags = Array.from(filters.tags);
        filters.obs_types = Array.from(filters.obs_types);
        filters.rag = Array.from(filters.rag);
        filters.swot = Array.from(filters.swot);
        filters.length = observations?.length;
        return filters;
    };
    React.useEffect(() => {
        if (scopeObservations?.length > 0) {
            setObservationFilters(
                generateFiltersFromObservations(
                    scopeObservations?.filter(item => item?.workstream_repr === selectedWorkstream?.name),
                    {
                        workstreams: new Set(),
                        tags: new Set(),
                        obs_types: new Set(),
                        rag: new Set(),
                        swot: new Set(),
                        claims: false,
                        length: 0,
                    },
                ),
            );
        }
    }, [scopeObservations, selectedWorkstream]);
    return (
        <div>
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <ColumnFilters columnState={columnState} setColumnState={setColumnState} />
                    <p className={'ms-3 ' + styles.questionsLength}>
                        Viewing{' '}
                        <b>
                            {filteredData?.length} of {observationFilters?.length}
                        </b>{' '}
                        Observations
                    </p>
                </div>
                <div className="d-flex flex-wrap">
                    {Object?.keys(tableActiveFilters).map(key => (
                        <div className={styles.filterTag} onClick={() => setAllValuesToTrue(tableFilters, key)}>
                            {key}&nbsp; <RxCross2 />
                        </div>
                    ))}
                </div>
            </div>
            <div className={`mt-3 ${styles.tableContainer} `} id="style-3-scroll">
                <table className="w-100">
                    <thead className={styles.tableHeader}>
                        <tr>
                            <td className="ps-2" style={{ minWidth: 40 }}>
                                <input
                                    type="checkbox"
                                    checked={selectedRows?.length === filteredData.length ? true : false}
                                    onChange={e => handleSelectAllFilter(e.target.checked)}
                                />
                            </td>
                            {columnState?.number.value && (
                                <td className="ps-2 " style={{ minWidth: 150 }}>
                                    #
                                </td>
                            )}
                            {columnState?.tags.value && (
                                <td className="ps-2 " style={{ minWidth: 150 }}>
                                    <div className="d-flex">
                                        {' '}
                                        Tags &nbsp;
                                        <Dropdown>
                                            <Dropdown.Toggle as={FilterIcon} id="dropdown-basic"></Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <div style={{ width: 300 }} className={styles.filterBody}>
                                                    <div className="row">
                                                        {tableFilters?.tags?.map(item => (
                                                            <div key={item?.label} className="col-sm-12 d-flex align-items-center mt-2">
                                                                <input
                                                                    checked={item?.value}
                                                                    onChange={e => handleChangeFilters(item?.label, 'tags', e.target.checked)}
                                                                    type="checkbox"
                                                                    id="flexCheckDefault"
                                                                    className=" me-3"
                                                                />
                                                                <label className={styles.checkLabel + ' ' + styles.smallFont}>{item?.label?.toUpperCase() || 'N/A'}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </td>
                            )}
                            {columnState?.observation.value && (
                                <td className="ps-2 " style={{ minWidth: 300 }}>
                                    Observation
                                </td>
                            )}
                            {columnState?.significance.value && (
                                <td className="ps-2 " style={{ minWidth: 300 }}>
                                    Significance
                                </td>
                            )}
                            {columnState?.rag.value && (
                                <td className="ps-2 " style={{ minWidth: 70 }}>
                                    <div className="d-flex">
                                        {' '}
                                        RAG&nbsp;
                                        <Dropdown>
                                            <Dropdown.Toggle as={FilterIcon} id="dropdown-basic"></Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <div style={{ width: 150 }} className={styles.filterBody}>
                                                    <div className="row">
                                                        {tableFilters?.rag?.map(item => (
                                                            <div key={item?.label} className="col-sm-12 d-flex align-items-center mt-2">
                                                                <input
                                                                    checked={item?.value}
                                                                    onChange={e => handleChangeFilters(item?.label, 'rag', e.target.checked)}
                                                                    type="checkbox"
                                                                    id="flexCheckDefault"
                                                                    className=" me-3"
                                                                />
                                                                <label className={styles.checkLabel + ' ' + styles.smallFont}>{item?.label?.toUpperCase()}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </td>
                            )}
                            {columnState?.swot.value && (
                                <td className="ps-2 " style={{ minWidth: 120 }}>
                                    <div className="d-flex">
                                        {' '}
                                        SWOT&nbsp;
                                        <Dropdown>
                                            <Dropdown.Toggle as={FilterIcon} id="dropdown-basic"></Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <div style={{ width: 150 }} className={styles.filterBody}>
                                                    <div className="row">
                                                        {tableFilters?.swot?.map(item => (
                                                            <div key={item?.label} className="col-sm-12 d-flex align-items-center mt-2">
                                                                <input
                                                                    checked={item?.value}
                                                                    onChange={e => handleChangeFilters(item?.label, 'swot', e.target.checked)}
                                                                    type="checkbox"
                                                                    id="flexCheckDefault"
                                                                    className=" me-3"
                                                                />
                                                                <label className={styles.checkLabel + ' ' + styles.smallFont}>
                                                                    {' '}
                                                                    {item?.label === 'S' && 'Strength'}
                                                                    {item?.label === 'W' && 'Weakness'}
                                                                    {item?.label === 'O' && 'Opportunity'}
                                                                    {item?.label === 'T' && 'Threat'}
                                                                    {item?.label === '' && 'N/A'}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </td>
                            )}
                            {columnState?.claim.value && (
                                <td className="ps-2 " style={{ minWidth: 80 }}>
                                    Claim &nbsp;
                                    <TiArrowUnsorted onClick={handleSortClaim} color="var(--muted)" role="button" />
                                </td>
                            )}
                            {columnState?.addedOn.value && (
                                <td className="ps-2 " style={{ minWidth: 120 }}>
                                    Added On &nbsp;
                                    <TiArrowUnsorted color="var(--muted)" onClick={handleSortAdded} role="button" />
                                </td>
                            )}
                            {columnState?.addedBy.value && (
                                <td className="ps-2 " style={{ minWidth: 120 }}>
                                    Added By &nbsp;
                                    <TiArrowUnsorted color="var(--muted)" role="button" />
                                </td>
                            )}
                            <td style={{ minWidth: 40 }}></td>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData?.map(item => (
                            <tr role="button" className={styles.tableRow}>
                                <td className="p-2">
                                    <input
                                        className={styles.checkBoxItem}
                                        style={{ display: selectedRows?.find(x => x === item?.id) && 'block' }}
                                        checked={selectedRows?.find(x => x === item?.id)}
                                        onChange={e => handleSelectRows(item?.id, e.target.checked)}
                                        type="checkbox"
                                    />
                                </td>
                                <td onClick={() => handleOpenViewObservation(item)} className="p-2">
                                    <span className={styles.blueText}>ITDD.1.1231</span>
                                </td>
                                <td onClick={() => handleOpenViewObservation(item)} className="p-2">
                                    <span className={styles.blueText}>{item?.tag_repr}</span>
                                </td>
                                <td onClick={() => handleOpenViewObservation(item)} className="p-2 ">
                                    {item?.observation && (
                                        <>
                                            <div className={styles?.dropdown}>
                                                <div style={{ maxWidth: '300px' }}>
                                                    <p className="text-truncate m-0">{htmlToPlainText(item?.observation || '')}</p>
                                                </div>
                                                <div className={styles.hoverMenuContent}>
                                                    <div
                                                        style={{
                                                            width: '300px',
                                                        }}
                                                        className={styles.hoverMenuCont}>
                                                        {htmlToPlainText(item?.observation || '')}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className={styles.tableEditorText} dangerouslySetInnerHTML={{ __html: item?.observation }} /> */}
                                        </>
                                    )}
                                </td>
                                <td onClick={() => handleOpenViewObservation(item)} className="p-2">
                                    {item?.comments && (
                                        <>
                                            <div className={styles?.dropdown}>
                                                <div style={{ maxWidth: '300px' }}>
                                                    <p className="text-truncate m-0">{htmlToPlainText(item?.comments || '')}</p>
                                                </div>
                                                <div className={styles.hoverMenuContent}>
                                                    <div
                                                        style={{
                                                            width: '300px',
                                                        }}
                                                        className={styles.hoverMenuCont}>
                                                        {htmlToPlainText(item?.comments || '')}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </td>
                                <td onClick={() => handleOpenViewObservation(item)} className="p-2">
                                    <div
                                        className={styles.ragBadge}
                                        style={{
                                            backgroundColor:
                                                (item?.assessment === 'RED' && '#da2a2a') ||
                                                (item?.assessment === 'AMBER' && '#fc9c24') ||
                                                (item?.assessment === 'GREEN' && '#006400'),
                                        }}></div>
                                </td>
                                <td onClick={() => handleOpenViewObservation(item)} className="p-2">
                                    <span
                                        className={styles.ragBadge}
                                        style={{
                                            color: (item?.impact === 'HIGH' && '#da2a2a') || (item?.impact === 'MEDIUM' && '#fc9c24') || (item?.impact === 'LOW' && '#006400'),
                                        }}>
                                        {item?.swot === 'S' && 'Strength'}
                                        {item?.swot === 'W' && 'Weakness'}
                                        {item?.swot === 'O' && 'Opportunity'}
                                        {item?.swot === 'T' && 'Threat'}
                                    </span>
                                </td>
                                <td onClick={() => handleOpenViewObservation(item)} className="p-2">
                                    {item?.claim && <BsCheckCircleFill size={16} />}
                                </td>
                                <td onClick={() => handleOpenViewObservation(item)} className="p-2">
                                    {item?.created && format(new Date(item?.created), 'dd/MM/yyyy')}
                                </td>
                                <td onClick={() => handleOpenViewObservation(item)} className="p-2">
                                    {' '}
                                    {projectUsersAll?.find(u => u?.value?.user_id === item?.added_by)?.value?.full_name || <i>Project Admin</i>}
                                </td>
                                <td className="p-2">
                                    <div className={styles?.dropdown}>
                                        <BsThreeDotsVertical />
                                        <div className={styles.hoverMenuContent}>
                                            <div
                                                style={{
                                                    width: '170px',
                                                }}
                                                className={styles.hoverMenuCont}>
                                                <div className={styles.wsChipMenuItem} onClick={() => handleOpenViewObservation(item)}>
                                                    <AiOutlineInfoCircle className="me-2" />
                                                    Get Info
                                                </div>
                                                {/* <hr className="mt-2 mb-2" />
                                                <div className={styles.wsChipMenuItem}>
                                                    <FiShare className="me-2" />
                                                    Share Observation
                                                </div> */}
                                                <hr className="mt-2 mb-2" />
                                                <DoubleConfirmationModal
                                                    handleAction={() => dispatch(DeleteObservations(item?.id))}
                                                    message="Deleting an Observation will permanently remove it from the DD Tool.">
                                                    <div className={styles.wsChipMenuItem} style={{ color: 'var(--Brand-Secondary)' }}>
                                                        <RiDeleteBin6Line className="me-2" />
                                                        Delete Observation
                                                    </div>
                                                </DoubleConfirmationModal>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <ObservationViewSidebar
                setObservationFormModal={setObservationFormModal}
                selectedRow={selectedObservation}
                setSelectedRow={setSelectedObservation}
                show={viewObservationModal}
                setShow={setViewObservationModal}
                allData={filteredData}
            />
        </div>
    );
};

const ColumnFilters = ({ columnState, setColumnState }) => {
    const DropdownToggleCol = React.forwardRef(({ children, onClick }, ref) => (
        <div
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
            className={styles.colFilterSelector}>
            <span>Column View</span>
            &nbsp; &nbsp;
            <BsChevronDown size={10} color="#829ab1" />
        </div>
    ));

    const handleChangeFilter = (label, key, value) => {
        var data = { ...columnState };
        data[key].value = value;
        setColumnState(data);
    };
    const handleClickAll = value => {
        var data = { ...columnState };
        Object.keys(columnState).forEach(key => {
            data[key].value = value;
        });
        setColumnState(data);
    };
    const handleGetAllValue = () => {
        return Object.keys(columnState)?.find(key => columnState[key]?.value === false) ? false : true;
    };

    return (
        <div>
            <Dropdown>
                <Dropdown.Toggle as={DropdownToggleCol} id="dropdown-basic"></Dropdown.Toggle>
                <Dropdown.Menu>
                    <div style={{ width: 350 }} className={styles.filterBody}>
                        <div className="col-sm-12 col-md-6 d-flex align-items-center mt-2">
                            <input checked={handleGetAllValue()} onChange={e => handleClickAll(e.target.checked)} type="checkbox" id="flexCheckDefault" className=" me-3" />
                            <label className={styles.smallFont}>View All</label>
                        </div>
                        <hr className="mb-2 mt-3" />
                        <div className="row">
                            {Object.keys(columnState)?.map(item => (
                                <div key={columnState[item]?.id} className="col-sm-12 col-md-6 d-flex align-items-center mt-2">
                                    <input
                                        checked={columnState[item]?.value}
                                        onChange={e => handleChangeFilter(columnState[item]?.label, item, e.target.checked)}
                                        type="checkbox"
                                        id="flexCheckDefault"
                                        className=" me-3"
                                    />
                                    <label className={styles.smallFont + ' ' + styles.smallFont}>{columnState[item]?.label}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};
export const ObservationFormSidebar = ({ show, setShow, selectedObservation, defaultObservation, setDefaultData }) => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user);
    const [imageModalVisible, setImageModalVisible] = React.useState(false);
    const { workstreams, default_folders } = useSelector(state => state.dataroom);
    const { items } = useSelector(state => state?.dataroom);
    const { obsButtonLoading } = useSelector(state => state.meeting);
    const [observation, setObservation] = React.useState('');
    const [significance, setSignificance] = React.useState('');
    const [previewImage, setPreviewImage] = React.useState();
    const [files, setFiles] = React.useState({ files: null });
    const [tagOptions, setTagOptions] = React.useState([]);
    const [formData, setFormData] = React.useState({
        assessment: '',
        swot: '',
        impact: 'HIGH',
        tag: null,
        claim: false,
        workstream: '',
    });
    const handleChange = e => {
        if (e.target?.name === 'swot') {
            if (e.target?.value === 'S' || e.target?.value === 'O') setFormData({ ...formData, swot: e.target.value, assessment: 'GREEN' });
            if (e.target?.value === 'W' || e.target?.value === 'T') setFormData({ ...formData, swot: e.target.value, assessment: 'AMBER' });
            else setFormData({ ...formData, swot: e.target.value });
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };
    const handleSubmit = async () => {
        let body = new FormData();
        // formData?.scope && body.append('scope', formData?.scope);
        body.append('workstream_repr', workstreams?.find(item => item?.id === Number(formData?.workstream)).name);
        body.append('tag_repr', formData?.tag?.value);
        body.append('observation', observation);
        body.append('comments', significance);
        body.append('consultant', user?.id);
        formData?.assessment && body.append('assessment', formData?.assessment);
        body.append('swot', formData?.swot);
        body.append('claim', formData?.claim);
        body.append('added_by', user?.id);
        body.append('project', localStorage['project_id']);
        files?.files && body.append('obs_image', files?.files);
        if (formData.swot === 'T' || formData.swot === 'W') {
            body.append('impact', formData?.impact);
        }
        if (formData.swot === 'S' || formData.swot === 'O') {
            body.append('impact', '');
        }
        if (selectedObservation) {
            await dispatch(PatchObservations({ id: selectedObservation?.id, body }));
        } else await dispatch(PostSchedulerObservations({ id: 0, body, type: 'observation' }));
        handleClose();
    };
    const handleClose = () => {
        setShow(false);
        setFormData({
            assessment: '',
            swot: '',
            impact: 'HIGH',
            claim: false,
            workstream: '',
        });
        setSignificance('');
        setObservation('');
        setFiles({ files: null });
        setDefaultData && setDefaultData();
    };
    const handleAddFile = async file => {
        setFiles({ files: file });
        var reader = new FileReader();
        reader.onloadend = function () {
            setPreviewImage(reader.result);
        };
        reader.readAsDataURL(file);
    };
    function encodeImageFileAsURL(element) {
        var file = element.files[0];
        var reader = new FileReader();
        reader.onloadend = function () {
            setPreviewImage(reader.result);
        };
        reader.readAsDataURL(file);
    }
    const handlePasteFile = e => {
        if (e.clipboardData.files.length) {
            encodeImageFileAsURL(e.clipboardData);
            const fileObject = e.clipboardData.files[0];
            setFiles({ files: fileObject });
        } else {
            alert('No image data was found in your clipboard. Copy an image first or take a screenshot.');
        }
    };
    const renderRagCircle = () => {
        const colorCode = (formData?.assessment === 'AMBER' && '#FFC107') || (formData?.assessment === 'RED' && '#B00020') || (formData?.assessment === 'GREEN' && '#198754');
        return (
            <Menu style={{ marginTop: '20px' }}>
                <MenuTrigger disableButtonEnhancement>
                    <div style={{ borderColor: colorCode }} className={styles.ragCircle}>
                        <div style={{ background: colorCode }} className={styles.ragCircleInside}></div>
                    </div>
                </MenuTrigger>
                <MenuPopover>
                    <MenuList>
                        <MenuItem onClick={() => setFormData({ ...formData, assessment: 'RED' })} className="d-flex align-items-center">
                            <Circle20Filled color="#B00020" className="me-2" />
                            Red
                        </MenuItem>
                        <MenuDivider />
                        <MenuItem onClick={() => setFormData({ ...formData, assessment: 'AMBER' })} className="d-flex align-items-center">
                            <Circle20Filled color="#FFC107" className="me-2" />
                            Amber
                        </MenuItem>
                        <MenuDivider />
                        <MenuItem onClick={() => setFormData({ ...formData, assessment: 'GREEN' })} className="d-flex align-items-center">
                            <Circle20Filled color="#198754" className="me-2" />
                            Green
                        </MenuItem>
                    </MenuList>
                </MenuPopover>
            </Menu>
        );
    };
    React.useEffect(() => {
        if (formData?.workstream && show) {
            dispatch(GetIRLDoc(formData?.workstream));
            dispatch(GetDataroomItemInfo(formData?.workstream));
            dispatch(GetFolderDefaults(workstreams?.find(item => item?.id === parseInt(formData?.workstream))?.name));
        }
    }, [formData?.workstream, show]);

    React.useEffect(() => {
        if (show && !defaultObservation) {
            if (selectedObservation) {
                setFormData({
                    workstream: workstreams?.find(item => item?.name === selectedObservation?.workstream_repr).id,
                    tag: { label: selectedObservation?.tag_repr, value: selectedObservation?.tag_repr },
                    assessment: selectedObservation?.assessment,
                    impact: selectedObservation?.impact,
                    swot: selectedObservation?.swot,
                    claim: selectedObservation?.claim,
                });
                setObservation(selectedObservation?.observation);
                setSignificance(selectedObservation?.comments);
                if (selectedObservation?.obs_image) {
                    const fetchImage = async () => {
                        setPreviewImage(selectedObservation?.obs_image);
                    };
                    fetchImage();
                }
            } else {
                setFormData({
                    assessment: '',
                    swot: '',
                    impact: 'HIGH',
                    claim: false,
                    workstream: '',
                    tag: '',
                });
                setObservation('');
                setSignificance('');
                setPreviewImage('');
            }
        }
    }, [selectedObservation, show]);

    React.useEffect(() => {
        if (show) {
            const combinedOptions = [...new Set([...default_folders.map(folder => folder?.ws_folder), ...items.map(item => item?.name)])].map(label => ({ label, value: label }));
            setTagOptions(combinedOptions);
        }
    }, [default_folders, items, show]);

    React.useEffect(() => {
        if (defaultObservation) setObservation(defaultObservation);
    }, [defaultObservation]);
    return (
        <div>
            <OverlayDrawer style={{ minWidth: 400 }} type="overlay" position="end" open={show} onOpenChange={(_, { open }) => handleClose(open)}>
                <DrawerBody style={{ padding: 16 }}>
                    <div className={styles.sidebarBody}>
                        <div className="d-flex  justify-content-between">
                            <b className={styles.sidebarTitle}>{selectedObservation ? 'Edit' : 'Add'} New Observation</b>
                            <Dismiss20Regular role="button" onClick={handleClose} color="var(--Neutral-Gray-6)" />
                        </div>
                        <div className="">
                            <div style={{ marginBottom: 24 }} className="">
                                <label className={styles.fieldLabel} htmlFor="flexRadioDefault1">
                                    Workstream <span style={{ color: 'var(--danger)' }}>*</span>
                                </label>
                                <select
                                    name="workstream"
                                    value={formData.workstream}
                                    onChange={e =>
                                        setFormData({
                                            ...formData,
                                            workstream: e.target.value,
                                            tag: null,
                                        })
                                    }
                                    style={{ color: !formData?.workstream && 'var(--Neutral-Gray-6)' }}
                                    className={'form-select ' + styles.customSelect}
                                    placeholder="Select Workstream"
                                    aria-label=".form-select-sm example">
                                    <option value="">None</option>
                                    {workstreams?.map(item => (
                                        <option key={item?.id} value={item?.id}>
                                            {item?.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div style={{ marginBottom: 24 }}>
                                <div className="d-flex align-items-center justify-content-between">
                                    <label className={styles.fieldLabel} htmlFor="flexRadioDefault1">
                                        Tag <span style={{ color: 'var(--danger)' }}>*</span>
                                    </label>
                                </div>

                                <Select
                                    name="selectedAssignee"
                                    options={tagOptions}
                                    isDisabled={!formData?.workstream}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    value={formData?.tag}
                                    onChange={e => setFormData({ ...formData, tag: e })}
                                    placeholder="Type here"
                                    styles={{
                                        control: (provided, state) => ({
                                            ...provided,
                                            borderColor: 'var(--Neutral-Gray-5)',
                                            borderBottomColor: 'var(--Neutral-Gray-6)',
                                            maxHeight: '32px',
                                            minHeight: '32px',
                                            fontSize: 14,
                                        }),
                                        valueContainer: (provided, state) => ({
                                            ...provided,
                                            height: '32px',
                                            padding: '0 6px',
                                        }),

                                        input: (provided, state) => ({
                                            ...provided,
                                            margin: '0px',
                                        }),
                                        indicatorSeparator: state => ({
                                            display: 'none',
                                        }),
                                        indicatorsContainer: (provided, state) => ({
                                            ...provided,
                                            height: '30px',
                                        }),
                                    }}
                                    // maxMenuHeight={1000}
                                    isClearable
                                    // menuShouldScrollIntoView
                                />
                            </div>

                            <div style={{ marginBottom: 24 }} className={styles.customReactQuill + ' mb-4 position-relative'}>
                                <p style={{ marginBottom: -26 }} className={styles.fieldLabel}>
                                    Observation <span style={{ color: 'var(--danger)' }}>*</span>
                                </p>
                                {/* {!showQuillTools && <TextEditStyle20Regular onClick={() => setShowQuillTools(true)} className={styles.quillShowToolbar} />} */}
                                <ReactQuill
                                    modules={{ toolbar: [['bold', 'italic', 'underline'], [{ list: 'ordered' }, { list: 'bullet' }], ['link']] }}
                                    placeholder=""
                                    theme="snow"
                                    defaultValue={observation || ''}
                                    value={observation || ''}
                                    onChange={v => setObservation(v)}
                                />
                            </div>
                            <div style={{ marginBottom: 24 }} className="row  ">
                                <div className="col-sm-12 col-md-6">
                                    <div className="d-flex align-items-center">
                                        {' '}
                                        {renderRagCircle()}
                                        <label className={styles.fieldLabel + ' m-0'} htmlFor="flexRadioDefault1">
                                            Assessment <span style={{ color: 'var(--danger)' }}>*</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <div className="d-flex align-items-center justify-content-end">
                                        <input
                                            type="checkbox"
                                            checked={formData?.claim}
                                            onChange={e => setFormData({ ...formData, claim: e.target.checked })}
                                            className={styles.claimCheckbox + ' me-3'}
                                        />
                                        <label className={styles.fieldLabel + ' m-0 me-2'} htmlFor="flexRadioDefault1">
                                            Claim
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div style={{ marginBottom: 24 }} className={styles.customReactQuill + ' mb-4'}>
                                <p style={{ marginBottom: -26 }} className={styles.fieldLabel}>
                                    Significance
                                </p>

                                <ReactQuill
                                    modules={{
                                        toolbar: [['bold', 'italic', 'underline'], [{ list: 'ordered' }, { list: 'bullet' }], ['link']],
                                    }}
                                    placeholder=""
                                    theme="snow"
                                    defaultValue={significance || ''}
                                    value={significance || ''}
                                    onChange={v => setSignificance(v)}
                                />
                            </div>

                            <div style={{ marginBottom: 24 }} className="row ">
                                <div className="col-sm-12 col-md-6 ">
                                    <label className={styles.fieldLabel} htmlFor="flexRadioDefault1">
                                        SWOT Analysis
                                    </label>
                                    <select
                                        name="swot"
                                        value={formData.swot}
                                        onChange={handleChange}
                                        className={'form-select ' + styles.customSelect}
                                        placeholder="Select Impact"
                                        style={{ color: !formData?.swot && 'var(--Neutral-Gray-6)' }}
                                        aria-label=". example">
                                        <option value="">None</option>
                                        <option value="S">Strength</option>
                                        <option value="W">Weakness</option>
                                        <option value="O">Opportunity</option>
                                        <option value="T">Threat</option>
                                    </select>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    {(formData.swot === 'T' || formData.swot === 'W') && (
                                        <div className="">
                                            <label className={styles.fieldLabel} htmlFor="flexRadioDefault1">
                                                Impact
                                            </label>
                                            <select
                                                name="impact"
                                                style={{ color: !formData?.impact && 'var(--Neutral-Gray-6)' }}
                                                value={formData.impact}
                                                onChange={handleChange}
                                                className={'form-select ' + styles.customSelect}
                                                placeholder="Select Impact"
                                                aria-label=". example">
                                                <option value="HIGH">High</option>
                                                <option value="MEDIUM">Medium</option>
                                                <option value="LOW">Low</option>
                                            </select>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div style={{ marginBottom: 24 }} className="">
                                <div className="d-flex justify-content-between align-items-center">
                                    <label className={styles.fieldLabel} htmlFor="flexRadioDefault1">
                                        Reference
                                    </label>
                                    <div>{previewImage && <FaRegImages onClick={() => setImageModalVisible(true)} size={15} />}</div>
                                </div>
                                <div className="d-flex">
                                    <div className={styles.fileUploadBody} onPaste={handlePasteFile}>
                                        <FileUploader children="Choose File" handleChange={handleAddFile} name="file" />
                                        {files?.files?.name ? files?.files?.name : ' or paste screenshot'}
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 d-flex justify-content-between align-items-center">
                                <button
                                    disabled={!Boolean(observation && formData?.workstream && formData?.tag && formData?.assessment && observation !== '<p><br></p>')}
                                    onClick={handleSubmit}
                                    type="button"
                                    className={`btn btn-primary btn-sm ${styles.saveButton}`}>
                                    {selectedObservation ? 'Save' : 'Add'} &nbsp;
                                    {obsButtonLoading && <AiOutlineLoading3Quarters className="rotateLoader" />}
                                </button>
                                {/* <p onClick={handleClose} className={styles.orangeBtnText}>
                                    Cancel
                                </p> */}
                            </div>
                        </div>
                        {previewImage && <ImageModal image={previewImage} setModalVisible={setImageModalVisible} modalVisible={imageModalVisible} />}
                    </div>{' '}
                </DrawerBody>
            </OverlayDrawer>
        </div>
    );
};

export const ObservationViewSidebar = ({ show, setShow, selectedRow, setSelectedRow, setObservationFormModal, allData }) => {
    const dispatch = useDispatch();
    const [showAddedBy, setShowAddedBy] = React.useState(true);
    const [previewImage, setPreviewImage] = React.useState();
    const [selectedIndex, setIndex] = React.useState();
    const [imageModalVisible, setImageModalVisible] = React.useState(false);
    const { projectUsersAll } = useSelector(state => state.team);
    const { selectedProject } = useSelector(state => state.projectData);
    const handleClose = () => {
        setShow(false);
        setPreviewImage(false);
    };
    const handleOpenImage = async img => {
        setPreviewImage(img);
        setImageModalVisible(true);
    };
    const handleNavigate = dir => {
        if (allData?.length > 0)
            if (dir === 'back' && selectedIndex > 0) {
                const previousRow = allData[selectedIndex - 1];
                setSelectedRow(previousRow);
            } else if (dir === 'forward' && selectedIndex < allData?.length - 1) {
                const nextRow = allData[selectedIndex + 1];
                setSelectedRow(nextRow);
            }
    };
    const addedBy = projectUsersAll?.find(u => u?.value?.user_id === selectedRow?.added_by);
    React.useEffect(() => {
        if (selectedRow) {
            setIndex(allData?.findIndex(item => item?.id === selectedRow?.id));
        }
    }, [allData, selectedRow]);
    return (
        <div>
            <Offcanvas placement="end" show={show} onHide={handleClose}>
                <Offcanvas.Body className={styles.sidebarBody}>
                    <div>
                        <div className="d-flex justify-content-between mb-4">
                            <div>
                                <p className={styles.blueText + ' font-weight-bold mb-0'}>{selectedRow?.workstream_repr}</p>
                                <span className={styles.blueText}>ITDD04.01</span>
                            </div>
                            <div className={styles.arrowsDiv}>
                                <IoChevronBackCircleSharp
                                    size={24}
                                    role="button"
                                    color={selectedIndex === 0 ? 'var(--muted)' : 'var(--Brand-Secondary)'}
                                    onClick={() => handleNavigate('back')}
                                />
                                <IoChevronForwardCircleSharp
                                    size={24}
                                    role="button"
                                    color={selectedIndex === allData.length - 1 ? 'var(--muted)' : 'var(--Brand-Secondary)'}
                                    onClick={() => handleNavigate('forward')}
                                />
                            </div>
                            {/* <AiOutlineClose role="button" onClick={handleClose} color="var(--Brand-Secondary)" /> */}
                        </div>
                        <div className="d-flex flex-wrap">
                            {selectedRow?.tag_repr && <div className={styles.tagChip}>{selectedRow?.tag_repr}</div>}

                            {/* <div className={styles.tagChip}>Security</div> */}
                        </div>
                        <div className={styles.viewObsSection}>
                            <span className={styles.blueText}>Observation</span>
                            <p className={styles.regularText + ' mb-0 ' + styles.richText} dangerouslySetInnerHTML={{ __html: selectedRow?.observation }}></p>
                        </div>
                        <div className={styles.viewObsSectios}>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex">
                                    <div
                                        className={styles.ragBadge + ' me-3'}
                                        style={{
                                            backgroundColor:
                                                (selectedRow?.assessment === 'RED' && '#da2a2a') ||
                                                (selectedRow?.assessment === 'AMBER' && '#fc9c24') ||
                                                (selectedRow?.assessment === 'GREEN' && '#006400'),
                                        }}></div>
                                    {selectedRow?.claim && <span className={styles.blueText + ' font-weight-bold'}>PROJECT CLAIM</span>}
                                </div>
                                {selectedRow?.swot && (
                                    <div
                                        className={styles.swotBadge}
                                        style={{
                                            background:
                                                (selectedRow?.impact === 'HIGH' && '#da2a2a') ||
                                                (selectedRow?.impact === 'MEDIUM' && '#fc9c24') ||
                                                (selectedRow?.impact === 'LOW' && '#006400'),
                                        }}>
                                        {selectedRow?.swot === 'S' && 'Strength'}
                                        {selectedRow?.swot === 'W' && 'Weakness'}
                                        {selectedRow?.swot === 'O' && 'Opportunity'}
                                        {selectedRow?.swot === 'T' && 'Threat'}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={styles.viewObsSection}>
                            <span className={styles.blueText}>Significance</span>
                            <p className={styles.regularText + ' mb-0'} dangerouslySetInnerHTML={{ __html: selectedRow?.comments }}></p>
                        </div>
                        <div className={styles.viewObsSection2}>
                            <div onClick={() => setShowAddedBy(!showAddedBy)} className="d-flex align-items-center justify-content-between mb-3">
                                <span className={styles.blueText}>Added by</span>
                                {showAddedBy ? <BsChevronUp size={14} color="var(--Brand-Secondary)" /> : <BsChevronDown size={14} color="var(--Brand-Secondary)" />}
                            </div>
                            {showAddedBy && (
                                <div className={styles.accordonText}>
                                    <p>{addedBy?.value?.full_name || 'Beyond'}</p>
                                    <p>{addedBy?.organisation || 'Beyond M&A'}</p>
                                    {selectedRow?.created && (
                                        <p>
                                            {format(new Date(selectedRow?.created), 'dd/MM/yyyy')} <i> {formatDateDifferenc(selectedRow?.created)}</i>
                                        </p>
                                    )}
                                </div>
                            )}
                        </div>
                        {/* <div className={styles.viewObsSection2}>
                            <div onClick={() => setShowSource(!showSource)} className="d-flex align-items-center justify-content-between mb-3">
                                <span className={styles.blueText}>Source</span>
                                {showSource ? <BsChevronUp size={14} color="var(--Brand-Secondary)" /> : <BsChevronDown size={14} color="var(--Brand-Secondary)" />}
                            </div>
                            {showSource && (
                                <div className={styles.accordonText}>
                                    <p>{selectedProject?.project_name}</p>
                                    <p>{selectedRow?.workstream_repr}</p>
                                    <p>{selectedRow?.tag_repr}</p>
                                    <p>{addedBy?.organisation || 'Beyond M&A'}</p>
                                    {selectedRow?.created && (
                                        <p>
                                            {format(new Date(selectedRow?.created), 'dd/MM/yyyy')} <i>{formatDateDifferenc(selectedRow?.created)}</i>
                                        </p>
                                    )}
                                </div>
                            )}
                        </div> */}
                        {selectedRow?.obs_image && (
                            <div className={styles.viewObsSection2}>
                                <span className={styles.blueText}>Attachments</span>
                                <div className={styles.smallFont + ' d-flex align-items-center justify-content-between'}>
                                    {selectedRow?.obs_image?.substring(selectedRow?.obs_image.lastIndexOf('/') + 1)}
                                    <BsFillEyeFill onClick={() => handleOpenImage(selectedRow?.obs_image)} color="var(--muted)" size={15} />
                                </div>
                            </div>
                        )}
                        <div className="d-flex justify-content-between align-items-center mt-4">
                            <button
                                onClick={() => {
                                    setObservationFormModal(true);
                                    setShow(false);
                                }}
                                className=" btn btn-primary smallButton">
                                Edit Observation
                            </button>
                            <DoubleConfirmationModal
                                handleAction={() => {
                                    dispatch(DeleteObservations(selectedRow?.id));
                                    setShow(false);
                                    setSelectedRow();
                                }}
                                message="Deleting an Observation will permanently remove it from the DD Tool.">
                                <span className={styles.orangeLink}>Delete</span>
                            </DoubleConfirmationModal>
                        </div>
                    </div>
                    {previewImage && <ImageModal image={previewImage} setModalVisible={setImageModalVisible} modalVisible={imageModalVisible} />}
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
};
export const WorkstreamSelect = ({ selectedOption, setSelectedOption, disabled }) => {
    const { default_workstreams, workstreams } = useSelector(state => state.dataroom);
    const { user } = useSelector(state => state.user);
    const { selectedProject } = useSelector(state => state.projectData);
    const isProjectAdmin = selectedProject?.vdr?.vdr_admin === user?.id;
    const dispatch = useDispatch();
    const [show, setShow] = React.useState(false);
    const DropdownToggleCol = React.forwardRef(({ children, onClick }, ref) => (
        <div
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
            className={styles.filterSelector + ' ' + styles.workstreamSelect}>
            <div className=" d-flex flex-wrap">
                {/* Default placement of the menu in relation to the control. 'auto' will flip when there isn't enough space below the control. */}
                {selectedOption?.name || 'Select'}
            </div>
            &nbsp; &nbsp;
            <BsChevronDown size={10} color="#829ab1" />
        </div>
    ));
    const handleAddWorkstream = async item => {
        await dispatch(PostWorkstream({ name: item?.workstream, project: localStorage['project_id'] }));
    };

    return (
        <Dropdown onToggle={() => setShow(!show)} show={show} className={(disabled && styles.disabledClass) + '  ' + styles.wsDropdown}>
            <Dropdown.Toggle as={DropdownToggleCol} id="dropdown-basic"></Dropdown.Toggle>
            {/* <br/> */}
            <Dropdown.Menu className="w-100">
                {workstreams?.length === 0 && <div className="text-center">Add workstreams</div>}
                {workstreams?.map(item => (
                    <div
                        onClick={() => {
                            setSelectedOption(item);
                            localStorage.setItem('workstream', item?.id);
                            setShow(!show);
                        }}
                        key={item?.id}
                        className={(selectedOption?.id === item?.id && styles.disabledWsOption) + ' d-flex justify-content-between align-items-center ' + styles.cosWSOption}>
                        {item?.name}
                    </div>
                ))}
                {isProjectAdmin && (
                    <div className="">
                        <hr className="mb-2 mt-2" />
                        {default_workstreams?.map(
                            item =>
                                !workstreams?.find(w => w?.name === item.workstream) && (
                                    <div key={item?.id} className={styles.addOptionsWS}>
                                        <span>{item?.workstream}</span>
                                        <span className={styles.orangeBtnText} onClick={() => handleAddWorkstream(item)}>
                                            Add
                                        </span>
                                    </div>
                                ),
                        )}
                    </div>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
};
export const ImageModal = ({ image, modalVisible, setModalVisible }) => {
    return (
        <>
            <Modal
                size="lg"
                centered
                show={modalVisible}
                onHide={() => {
                    setModalVisible(false);
                }}>
                <Modal.Body>
                    <img src={image} style={{ width: '100%' }} alt={image} />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Main;
