import React from 'react';
import Wizard from '../components/Wizard';
import Layout from '../components/Layout';
import Pageheader from '../utils/headerHelment';

const WizardPage = () => {
    return (
        <>
            <Pageheader title="LENS - Wizard" />
            <Layout>
                <Wizard />
            </Layout>
        </>
    );
};

export default WizardPage;
