import React, { useEffect } from 'react';
import Pageheader from '../../utils/headerHelment';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { API_BASE_URL, AUTH_CODE_CONFIRMATION, AUTH_CODE_CONFIRMATION_MOB, AUTH_LOGIN_CODE } from '../../apiServices/api_routes';
import AuthService from '../../apiServices/tokenservices/auth.service';
import styles from './css/Authenticate.module.css';
import { GetUserData, setAccessToken } from '../../redux/usersSlice';
import { setProjectImg } from '../../redux/projectDataSlice';
import { SpinnerIos16Regular } from '@fluentui/react-icons';

import { setDocs } from '../../redux/documentsSlice';
import Logo from '../../assets/longLogoBright.png';
import Cookies from 'js-cookie';

const Authenticate = () => {
    var promiseToast = null;
    const location = useLocation();
    const [buttonLoading, setButtonLoading] = React.useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = React.useState(true);
    const config = {
        headers: {
            Authorization: `Bearer ${location?.state?.access}`,
        },
    };
    const user = useSelector(state => state?.user);
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const [ephToken, setEphToken] = React.useState(location?.state?.ephemeral_token);
    const [rememberMe, setRememberMe] = React.useState(false);
    const type = location?.state?.type;
    const [otp, setOTP] = React.useState(['', '', '', '', '', '']);
    const inputRefs = React.useRef([]);
    console.log({ location });
    const handleSubmit = async () => {
        setButtonLoading(true);
        const verificationCode = otp.join('');
        promiseToast = toast.loading('Verifying Code...', {
            closeButton: true,
        });

        if (location?.state?.ephemeral_token) {
            handleAuth();
        } else {
            try {
                if (type === 'sms_twilio') {
                    await axios.post(`${API_BASE_URL}${AUTH_CODE_CONFIRMATION_MOB}`, { code: verificationCode }, config);
                } else {
                    await axios.post(`${API_BASE_URL}${AUTH_CODE_CONFIRMATION}`, { code: verificationCode }, config);
                }
            } catch (error) {
                toast.update(promiseToast, {
                    render: 'Invalid Code or expired',
                    type: 'error',
                    isLoading: false,
                    autoClose: 1000,
                });
                setButtonLoading(false);
            }
            try {
                const { email, password } = location?.state;
                const res = await AuthService.login(email, password);
                if (res?.ephemeral_token) {
                    setEphToken(res?.ephemeral_token);
                    handleAuth(res?.ephemeral_token);
                }
            } catch (error) {
                toast.update(promiseToast, {
                    render: 'Invalid Code or expired',
                    type: 'error',
                    isLoading: false,
                    autoClose: 1000,
                });
                setButtonLoading(false);
            }
        }
    };
    const handlePaste = event => {
        const pastedData = event.clipboardData.getData('Text');
        if (pastedData.length === 6 && inputRefs.current[0] === document.activeElement) {
            // If pasted data length is 6 and the first input is focused, update the OTP
            const newOTP = pastedData.split('').slice(0, 6);
            setOTP(newOTP);

            // Focus on the next input field
            inputRefs.current[1].focus();
        }
    };
    const handleAuth = async token => {
        const verificationCode = otp.join('');
        try {
            const res = await axios.post(`${API_BASE_URL}${AUTH_LOGIN_CODE}`, {
                code: verificationCode,
                ephemeral_token: token || ephToken,
                remember_me: rememberMe,
            });
            setButtonLoading(false);
            dispatch(setAccessToken(res?.data?.access));
            Cookies.set('refresh', res?.data?.refresh);

            await dispatch(GetUserData());
            toast.update(promiseToast, {
                render: 'Done',
                type: 'success',
                isLoading: false,
                autoClose: 1000,
            });
            navigate('/project');
        } catch (error) {
            toast.update(promiseToast, {
                render: 'Invalid Code or expired',
                type: 'error',
                isLoading: false,
                autoClose: 1000,
            });
            setButtonLoading(false);
        }
    };

    const logoutUser = () => {
        localStorage.removeItem('persist:root');
        Cookies.set('refresh', '');
        localStorage.clear();
        dispatch(setProjectImg(''));
        dispatch(setDocs([]));
        console.log('logoutUser function runs');
        navigate('/');
    };
    const isFilled = otp.every(digit => digit !== '');
    const handleChange = (index, event) => {
        const newOTP = [...otp];
        newOTP[index] = event.target.value;
        setOTP(newOTP);

        // Move to the next input field
        if (event.target.value && index < 5) {
            inputRefs.current[index + 1].focus();
        }
    };
    const handleKeyDown = (index, event) => {
        // Move to the previous input field if backspace is pressed and the current input field is empty
        if (event.key === 'Backspace' && !otp[index] && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    const handleResendCode = async () => {
        const { email, password } = location?.state;
        // Handle button click
        try {
            const res = await AuthService.login(email?.toLowerCase(), password);
            setIsButtonDisabled(true);
            toast.success('Code sent successfully');
        } catch (error) {}

        // Add logic for whatever onClick function you want to run
        // For example, you can send a code resend request here
    };
    useEffect(() => {
        user?.isLoggedIn && navigate('/project');
    }, []);
    useEffect(() => {
        const timer = setTimeout(() => {
            logoutUser();
            console.log('logout timer starts');
        }, 240000);
        return () => clearTimeout(timer);
    }, []);
    useEffect(() => {
        // Enable the button after 5 minutes
        const timer = setTimeout(() => {
            setIsButtonDisabled(false);
        }, 5 * 60 * 1000); // 5 minutes in milliseconds

        return () => clearTimeout(timer);
    }, []); // Empty dependency array means this effect runs only once, similar to componentDidMount
    return (
        <div className={styles.body}>
            <div className={styles.dotedBg}>
                <div className={styles.formCard}>
                    <div className={styles.formHeader}>
                        <img src={Logo} width={73} alt="lens-logo" />
                    </div>
                    <div className={styles.formBody}>
                        <h5 className={styles.formLabel}>2FA Authentication</h5>
                        <p className={styles.formSubtitle}>
                            {type === 'sms_twilio' ? 'Enter 2FA code sent to your registered mobile number.' : 'Enter 2FA code from your authenticator app.'}
                        </p>
                        <div className={styles.otpField}>
                            {otp.map((digit, index) => (
                                <input
                                    key={index}
                                    type="text"
                                    maxLength="1"
                                    value={digit}
                                    onChange={event => handleChange(index, event)}
                                    onKeyDown={event => handleKeyDown(index, event)}
                                    ref={ref => (inputRefs.current[index] = ref)}
                                    onPaste={event => handlePaste(event)}
                                />
                            ))}
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <div onClick={() => setRememberMe(!rememberMe)} role="button" className={styles.rememberMeSection}>
                                <input checked={rememberMe} type="checkbox" />
                                <label className={styles.rememberBeText}>Remember this device for 15 days</label>
                            </div>
                            {type === 'sms_twilio' && (
                                <div>
                                    <span
                                        style={{ color: isButtonDisabled && 'var(--Neutral-Gray-6)', pointerEvents: isButtonDisabled && 'none' }}
                                        onClick={handleResendCode}
                                        className={styles.secondaryBtnLink}>
                                        Resend code
                                    </span>
                                </div>
                            )}
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                            <button disabled={buttonLoading || !isFilled} onClick={handleSubmit} className={styles.submitBtn}>
                                Sign in
                                {buttonLoading && <SpinnerIos16Regular className="rotateLoader ms-1" />}
                            </button>
                            <a href="mailto:duediligence@beyond-ma.com">
                                <span role="button" className={styles.secondaryBtnLink}>
                                    Trouble signing in?
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.footer}>
                <a href="https://www.iubenda.com/terms-and-conditions/15531073" target="_blank" rel="noreferrer">
                    Terms of Use
                </a>
                <a href="https://www.iubenda.com/privacy-policy/15531073/legal?ifr=true&height=650&newmarkup=yes&an=no" target="_blank" rel="noreferrer">
                    Privacy & Cookies
                </a>
            </div>
        </div>
    );
    // return (
    //     <div className=" full-height-cls  bg-light">
    //         <Pageheader title="LENS - Authenticate" />
    //         <header className="header ">
    //             <div className={styles.navbarbrand}>
    //                 <Link to="/">
    //                     <img src={Logo} alt="logo" width="100px" height="54px" />
    //                 </Link>
    //             </div>
    //         </header>
    //         <div className="container">
    //             <div className={`row ${styles.viewHeight}`}>
    //                 <div className="col-sm-12 col-md-5">
    //                     <div className={`${styles.formBody} `}>
    //                         <div className={styles.rightContent}>
    //                             <h2 className={styles.headingText}>Enter the Authentication code</h2>
    //                             <p className={styles.emailText}>
    //                                 {type === 'sms'
    //                                     ? `We have texted you the code on (Mobile number). Please enter the code to sign in.`
    //                                     : `Please enter the code displayed on your authenticator application from your device.`}
    //                             </p>
    //                             {/* <br /> */}
    //                             <div className="row">
    //                                 <div className="col-sm-12 col-md-6">
    //                                     <input
    //                                         className="form-control commonInput"
    //                                         placeholder="Code"
    //                                         value={verificationCode}
    //                                         onChange={e => setVerificationCode(e.target.value)}
    //                                     />
    //                                 </div>
    //                                 <div className="mt-2">
    //                                     <input checked={rememberMe} onChange={e => setRememberMe(e.target.checked)} type="checkbox" id="exampleCheck1" />
    //                                     <small className="form-check-label ms-2" htmlFor="exampleCheck1 ">
    //                                         Don’t ask again for 15 days
    //                                     </small>
    //                                 </div>
    //                                 <div className="mt-4 mb-4">
    //                                     <small>
    //                                         Having trouble?{' '}
    //                                         <span
    //                                             role="button"
    //                                             style={{ color: 'var(--Brand-Secondary)' }}
    //                                             onClick={() =>
    //                                                 navigate('/secure', {
    //                                                     state: {
    //                                                         access: location?.state?.access,
    //                                                         email: location?.state?.email,
    //                                                         password: location?.state?.password,
    //                                                     },
    //                                                 })
    //                                             }>
    //                                             {' '}
    //                                             Sign in another way.
    //                                         </span>
    //                                     </small>
    //                                 </div>
    //                                 <div className="d-flex ">
    //                                     <button onClick={handleSubmit} style={{ width: '120px' }} className="btn btn-primary">
    //                                         Verify
    //                                     </button>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>

    //         </div>
    //     </div>
    // );
};
export default Authenticate;
