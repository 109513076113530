import React, { useState, useEffect } from 'react';
import styles from './css/Login.module.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import '../../../node_modules/font-awesome/css/font-awesome.min.css';
import { isObject } from '../../utils/commonUtils';
import { useDispatch, useSelector } from 'react-redux';
import { setProjectImg } from '../../redux/projectDataSlice';
import { GetUserData, logout, setAccessToken } from '../../redux/usersSlice';
import AuthService from '../../apiServices/tokenservices/auth.service';
import Cookies from 'js-cookie';
import { Eye16Regular, EyeOff16Regular, SpinnerIos16Regular } from '@fluentui/react-icons';
import { setDocs } from '../../redux/documentsSlice';
import Logo from '../../assets/longLogoBright.png';

function Login() {
    const user = useSelector(state => state?.user);
    const location = useLocation();
    const [rememberMe, setRememberMe] = React.useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loginLoading, setLoginLoading] = React.useState(false);
    const [showpassword, setShowpassword] = useState(false);
    const [responseMessage] = React.useState({
        nature: null,
        message: null,
    });
    const [formData, setFormData] = React.useState({
        email: '',
        password: '',
    });
    React.useLayoutEffect(() => {
        if (!Cookies.get('refresh')) {
            logoutUser();
        }
    }, [Cookies.get('refresh')]);

    useEffect(() => {
        user?.isLoggedIn && navigate('/project');
    }, []);

    const handleSubmit = async e => {
        e.preventDefault();

        setLoginLoading(true);
        try {
            const res = await AuthService.login(formData?.email?.toLowerCase(), formData?.password, rememberMe);
            if (res?.access) {
                if (res?.method === 'remembered') {
                    dispatch(setAccessToken(res?.access));
                    Cookies.set('refresh', res?.refresh);
                    await dispatch(GetUserData());
                    setLoginLoading(false);
                    navigate('/project');
                    toast.success('Success');
                }
                // else {
                //     setLoginLoading(false);
                //     navigate('/secure', {
                //         state: {
                //             email: formData?.email?.toLowerCase(),
                //             access: res?.access,
                //             password: formData?.password,
                //         },
                //     });
                // }
            } else {
                setLoginLoading(false);
                navigate('/authenticate', {
                    state: { ephemeral_token: res?.ephemeral_token, type: res?.method, email: formData?.email, password: formData?.password },
                });
            }
        } catch (err) {
            setLoginLoading(false);
            const resp = err?.response?.data;
            if (isObject(resp)) {
                Object.entries(resp).forEach(([key, value]) => {
                    toast.error(
                        <span
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                height: '20px',
                            }}>
                            Error : <pre> </pre>
                            {key !== 'error' && <span style={{ textTransform: 'capitalize' }}>{String(key)}, </span>}
                            {String(value)}
                        </span>,
                    );
                });
            }
        }
    };
    const handleChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const logoutUser = () => {
        localStorage.removeItem('persist:root');
        Cookies.set('refresh', '');
        dispatch(setProjectImg(''));
        dispatch(setDocs([]));
        dispatch(logout());
        navigate('/');
    };

    return (
        <div className={styles.body}>
            <div className={styles.dotedBg}>
                <div className={styles.formCard}>
                    <div className={styles.formHeader}>
                        <img src={Logo} width={73} alt="lens-logo" />
                    </div>
                    <div className={styles.formBody}>
                        <h5 className={styles.formLabel}>Sign in</h5>
                        <div>
                            <div>
                                <p className={styles.fieldLabel}>Email address</p>
                                <input value={formData?.email} name="email" onChange={handleChange} type="email" className={'form-control ' + styles.inputField} />
                            </div>
                            <div>
                                <p className={styles.fieldLabel}>Password</p>
                                <div className="input-field">
                                    <input
                                        value={formData?.password}
                                        name="password"
                                        type={showpassword ? 'text' : 'password'}
                                        onChange={handleChange}
                                        className={'form-control ' + styles.inputField}
                                    />
                                    {showpassword ? (
                                        <Eye16Regular onClick={() => setShowpassword(!showpassword)} className="icon icon-right svg-icons" />
                                    ) : (
                                        <EyeOff16Regular onClick={() => setShowpassword(!showpassword)} className="icon icon-right svg-icons" />
                                    )}
                                </div>{' '}
                            </div>
                            <div onClick={() => setRememberMe(!rememberMe)} role="button" className={styles.rememberMeSection}>
                                <input checked={rememberMe} type="checkbox" />
                                <label className={styles.rememberBeText}>Stay signed in</label>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <button disabled={loginLoading} onClick={handleSubmit} className={styles.submitBtn}>
                                    Next
                                    {loginLoading && <SpinnerIos16Regular className="rotateLoader ms-1" />}
                                </button>
                                <span role="button" onClick={() => navigate('/forgot-password')} className={styles.secondaryBtnLink}>
                                    Forgot Password
                                </span>
                            </div>
                            <p className={styles.noAccountText}>
                                No account?{' '}
                                <span role="button" onClick={() => navigate('/request-invite')} className={styles.secondaryBtnLink}>
                                    Request invite
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.footer}>
                <a href="https://www.iubenda.com/terms-and-conditions/15531073" target="_blank" rel="noreferrer">
                    Terms of Use
                </a>
                <a href="https://www.iubenda.com/privacy-policy/15531073/legal?ifr=true&height=650&newmarkup=yes&an=no" target="_blank" rel="noreferrer">
                    Privacy & Cookies
                </a>
            </div>
        </div>
    );
}
export default Login;
