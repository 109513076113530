import React, { useEffect, useRef, useMemo } from 'react';
import styles from './css/index.module.css';

import { ChevronDown20Regular, Dismiss16Regular } from '@fluentui/react-icons';
import { PostFolderDocuments } from '../../redux/documentsSlice';
import { setIRLItems } from '../../redux/dataroomSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineClose } from 'react-icons/ai';
import { validExtensions } from '../../utils/misc';
import { toast } from 'react-toastify';

const AddFolderDocModal = ({ setUploadFolderSidebar, setShowSidebar, selectedFolders, workstream }) => {
    const selectedFolder = useMemo(() => {
        return selectedFolders?.length > 0 ? selectedFolders[selectedFolders.length - 1] : {};
    }, [selectedFolders]);
    const [uploadedFiles, setUploadedFiles] = React.useState([]);
    const fileInputRef = useRef();
    const [folderDirectory, setFolderDirectory] = React.useState('');
    const { selectedProject } = useSelector(state => state?.projectData);
    const { items } = useSelector(state => state?.dataroom);
    const dispatch = useDispatch();
    const handleDivClick = () => {
        fileInputRef.current.click();
    };
    console.log(uploadedFiles);
    const handleFileChange = e => {
        const selectedFiles = e.target.files;
        // Handle the selected files as needed
        if (selectedFiles?.length > 0) {
            setFolderDirectory(selectedFiles?.[0]?.webkitRelativePath.split('/')[0]);
            setUploadedFiles(Array.from(selectedFiles));
        }
    };
    const handleDrop = e => {
        e.preventDefault();
        // const items = e.dataTransfer.items;
        // const allFiles = [];
        // const traverseDirectory = directory => {
        //     const reader = directory.createReader();
        //     reader.readEntries(entries => {
        //         let remainingEntries = entries.length;
        //         entries.forEach(entry => {
        //             if (entry.isFile) {
        //                 // Handle file
        //                 allFiles.push(entry);
        //             } else if (entry.isDirectory) {
        //                 // Handle nested directory
        //                 traverseDirectory(entry); // Recursive call to fetch files in nested directory
        //             }
        //             remainingEntries--;
        //             if (remainingEntries === 0) {
        //                 setUploadedFiles(Array.from(allFiles));
        //             }
        //         });
        //     });
        // };

        // for (let i = 0; i < items.length; i++) {
        //     const item = items[i].webkitGetAsEntry();
        //     if (item.isFile) {
        //         // Handle file
        //         allFiles.push(item);
        //     } else if (item.isDirectory) {
        //         // Handle directory
        //         setFolderDirectory(item?.name);
        //         traverseDirectory(item);
        //     }
        // }
    };
    const handlePaste = e => {
        const items = e.clipboardData.items;
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (item.kind === 'file') {
                // const file = item.getAsFile();
                // Handle the pasted file as needed
                // console.log(file);
            }
        }
    };
    const handleClose = () => {
        setUploadedFiles([]);
        setShowSidebar(false);
        setUploadFolderSidebar(false);
        setFolderDirectory('');
    };
    const handleBulkFolderUpload = async folderStructure => {
        let allRequestsSucceeded = true;
        try {
            const formData = new FormData();

            for (const [folderPath, files] of Object.entries(folderStructure)) {
                for (const file of files) {
                    formData.append('document', file);
                    formData.append('path', folderPath); // Assuming backend handles paths
                    formData.append('document_name', file.name);
                    formData.append('project', selectedProject?.id);
                    formData.append('vdr', selectedProject?.vdr?.id);
                    // if (selectedFolder?.id) formData.append('parent', selectedProject?.vdr?.id);
                }
            }
            let res = await dispatch(PostFolderDocuments({ body: formData, ws_id: workstream?.id }));
            if (res?.type === 'POST_IRL_DOC/rejected') {
                allRequestsSucceeded = false;
            } else {
                let folders = res?.payload.map(item => {
                    return {
                        name: item?.ws_folder,
                        id: item?.ws_folder_id,
                        parent: item?.parent_folder_id,
                        ws_id: res?.meta?.arg?.ws_id,
                        ws_name: item?.workstream,
                        prefix: item?.ws_folder_prefix,
                    };
                });
                console.log(folders);
                function transformFolders(flatFolders) {
                    const foldersMap = new Map();
                    const nestedFolders = [];

                    // Create a map for quick access to folders by ID and remove duplicates
                    const uniqueFolders = flatFolders.reduce((acc, folder) => {
                        if (!foldersMap.has(folder?.id)) {
                            const folderCopy = { ...folder, children: [] };
                            foldersMap.set(folder?.id, folderCopy);
                            acc.push(folderCopy);
                        }
                        return acc;
                    }, []);

                    // Build the hierarchy by iterating through each folder
                    uniqueFolders.forEach(folder => {
                        const currentFolder = foldersMap.get(folder?.id);

                        if (folder?.parent === null) {
                            // Top-level folder
                            nestedFolders.push(currentFolder);
                        } else {
                            // Child folder, add it to its parent's children array
                            const parentFolder = foldersMap.get(folder.parent);
                            if (parentFolder) {
                                parentFolder.children.push(currentFolder);
                            }
                        }
                    });

                    return nestedFolders;
                }
                const nestedFolders = transformFolders(folders);
                dispatch(setIRLItems([...items, ...nestedFolders]));
            }
        } catch (error) {
            allRequestsSucceeded = false;
        }
        return allRequestsSucceeded;
    };
    const handleAddFiles = () => {
        const files = uploadedFiles;
        const folderStructure = {};
        const promiseToast = toast.loading('Uploading...', { closeButton: true });
        // Iterate over all selected files
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            // Skip dot files
            if (file.name.startsWith('.')) {
                continue;
            }
            const fullPath = file.webkitRelativePath; // Get the full path of the file
            const folderPath = fullPath.substring(0, fullPath.lastIndexOf('/')); // Extract the folder path

            if (!folderStructure[folderPath]) {
                folderStructure[folderPath] = [];
            }
            folderStructure[folderPath].push(file);
        }
        handleBulkFolderUpload(folderStructure).then(success => {
            if (success) {
                toast.update(promiseToast, {
                    render: 'Folder uploaded successfully',
                    type: 'success',
                    isLoading: false,
                    autoClose: 1000,
                });
                handleClose();
            } else {
                toast.update(promiseToast, {
                    render: 'Error in uploading folder',
                    type: 'error',
                    isLoading: false,
                    autoClose: 1000,
                });
            }
        });
    };
    console.log(folderDirectory);
    useEffect(() => {
        const div = document.getElementById('uploadDiv');
        if (div) {
            div.addEventListener('dragover', e => e.preventDefault());
            div.addEventListener('drop', handleDrop);
            div.addEventListener('paste', handlePaste);

            return () => {
                div.removeEventListener('dragover', e => e.preventDefault());
                div.removeEventListener('drop', handleDrop);
                div.removeEventListener('paste', handlePaste);
            };
        }
    }, []);
    return (
        <div className={styles.sidebarWhite}>
            <div className="d-flex align-items-center justify-content-between mb-5">
                <h5 className={styles.sidebarTitle}>Upload Folder</h5>
                <Dismiss16Regular onClick={handleClose} role="button" color="var(--Neutral-Gray-6)" />
            </div>
            {/* <div className="d-flex align-items-center">
                <span>{selectedFolder?.name || workstream?.name}</span>
                <ChevronDown20Regular className="ms-2" />
            </div> */}
            {folderDirectory ? (
                <div className={styles.uploadItemsList}>
                    <div className={styles.fileListItem}>
                        <span>{folderDirectory}</span>
                        <AiOutlineClose
                            size={14}
                            onClick={() => {
                                setFolderDirectory('');
                                setUploadedFiles([]);
                            }}
                        />
                    </div>
                </div>
            ) : (
                <div id="uploadDiv" className={styles.uploadBox} onClick={handleDivClick}>
                    <span>Click to Upload</span>
                    <input
                        multiple
                        type="file"
                        accept={validExtensions.join(',')}
                        webkitdirectory="true"
                        directory="true"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
                </div>
            )}

            <button onClick={handleAddFiles} className={styles.saveButton}>
                Save
            </button>
        </div>
    );
};
export default AddFolderDocModal;
