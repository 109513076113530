import axios from 'axios';
import TokenService from './token.service';
import { API_BASE_URL } from '../api_routes';

const baseUrl = API_BASE_URL;
// const baseUrl = 'http://127.0.0.1:8000/'

class AuthService {
    login(email, password, rememberMe) {
        let body = {
            username: email,
            password,
        };
        if (rememberMe) body.remember = 30;
        return axios.post(baseUrl + 'accounts/auth/login/', body).then(response => {
            if (response?.data?.access) {
                TokenService?.setUser(response.data);
            }

            return response.data;
        });
    }
    logout() {
        TokenService.removeUser();
    }

    register(email, password, full_name, job_title, types) {
        return axios.post(baseUrl + 'accounts/newuser/', {
            email,
            password,
            full_name,
            job_title,
            types,
        });
    }

    getCurrentUser() {
        return TokenService.getUser();
    }
}

export default new AuthService();
