const maturityIndexData = [
    {
        label: 'Roadmap and Recommendations',
        description: 'Suggestions for improvements and a strategic roadmap for future development initiatives.',
        preSeed: true,
        seriesA: true,
        value: 0,
        corp: true,
        key: 'roadmap_recommendations',
    },
    {
        label: 'Talent Management and Organizational Capability',
        description:
            'Assessment of technical talent and leadership within the organization. Plans for skill development and scaling the tech team in alignment with growth strategies.',
        preSeed: false,
        seriesA: true,
        value: 0,
        corp: true,
        key: 'talent_mgmt',
    },
    {
        label: 'Code Quality Metrics',
        description:
            "These should include measures of complexity, maintainability, readability, and performance. The CTO should be able to quantify these aspects using tools and benchmarks and explain their significance to the project's goals.",
        preSeed: false,
        seriesA: true,
        value: 0,
        corp: true,
        key: 'code_quality',
    },
    {
        label: 'Security Review',
        description: 'An overview of any security audits performed, potential vulnerabilities identified, and the steps taken to mitigate risks.',
        preSeed: true,
        seriesA: true,
        value: 0,
        corp: true,
        key: 'security_review',
    },
    {
        label: 'Scalability and Performance Analysis',
        description: 'Insights into how the codebase will perform under load and how it can scale to meet future demands.',
        preSeed: false,
        seriesA: false,
        value: 0,
        corp: true,
        key: 'scalability',
    },
    {
        label: 'Technical Debt Assessment',
        description: 'An honest appraisal of technical debt, its impact on the project, and a plan for addressing it.',
        preSeed: false,
        seriesA: true,
        value: 0,
        corp: true,
        key: 'tech_debt',
    },
    {
        label: 'Compliance and Standards Adherence',
        description: 'Confirmation that the code adheres to industry standards and regulatory requirements specific to the project domain.',
        preSeed: false,
        seriesA: false,
        value: 0,
        corp: true,
        key: 'compliance',
    },
    {
        label: 'Testing and Quality Assurance',
        description: 'A report on testing coverage, the types and numbers of tests conducted (unit tests, integration tests, etc.), and the quality assurance processes in place.',
        preSeed: true,
        seriesA: true,
        value: 0,
        corp: true,
        key: 'testing_quality',
    },
    {
        label: 'Documentation Quality',
        description: 'The state of the project documentation, including code comments, API documentation, and system architecture diagrams, which are crucial for maintainability.',
        preSeed: false,
        seriesA: true,
        value: 0,
        corp: true,
        key: 'documentation_quality',
    },
    {
        label: 'Integration and Dependencies',
        description: 'An analysis of how the code integrates with other systems and the management of its dependencies.',
        preSeed: false,
        seriesA: false,
        value: 0,
        corp: true,
        key: 'integration_dependancies',
    },
    {
        label: 'Development Practices and Processes',
        description:
            'A review of the development methodologies in place, continuous integration and delivery pipelines, and how they contribute to the overall health of the codebase.',
        preSeed: false,
        seriesA: true,
        value: 0,
        corp: true,
        key: 'development_practises',
    },
    {
        label: 'Resource Utilization',
        description: 'How effectively the project utilizes resources, both human (development team) and computational (hardware, third-party services).',
        preSeed: false,
        seriesA: false,
        value: 0,
        corp: true,
        key: 'resource_utilisation',
    },
    {
        label: 'Key Achievements and Challenges',
        description: 'Highlight significant milestones reached and challenges faced during the development cycle.',
        preSeed: true,
        seriesA: true,
        value: 0,
        corp: true,
        key: 'key_achievements',
    },
    {
        label: 'Stakeholder Feedback',
        description: 'Incorporation of feedback from stakeholders and how it has been or will be addressed in the development process.',
        preSeed: false,
        seriesA: false,
        value: 0,
        corp: true,
        key: 'stakeholder_feedback',
    },
    {
        label: 'Financial Performance and Forecasting',
        description: "Evaluation of how technology impacts the company's financial performance. Projections on technology-driven revenue growth or cost savings.",
        preSeed: false,
        seriesA: false,
        value: 0,
        corp: true,
        key: 'financial_performance',
    },
    {
        label: 'M&A Technology Integration',
        description: 'Strategies for integrating technology during mergers and acquisitions. Assessing compatibility and integration risks with other systems.',
        preSeed: false,
        seriesA: false,
        value: 0,
        corp: true,
        key: 'tech_integrations',
    },
    {
        label: 'Ecosystem and Partnership Strategy',
        description: 'Analysis of the technology ecosystem and strategic partnerships. How partnerships contribute to technology advancement and market positioning.',
        preSeed: false,
        seriesA: false,
        value: 0,
        corp: true,
        key: 'ecosystem_partner',
    },
];

export const stages = [
    { title: 'Not Planned', description: 'Idea formation stage.' },
    { title: 'Planned', description: 'Defined plans not yet in action.' },
    { title: 'Concept', description: 'Active early-stage work.' },
    { title: 'Created', description: 'Plan in operation.' },
    { title: 'Operational', description: 'Implemented and functioning.' },
    { title: 'Optimized', description: 'Refinement and efficiency improvements stage.' },
];

export default maturityIndexData;
