import React, { useState } from 'react';
import styles from './css/index.module.css';
import { Info16Regular } from '@fluentui/react-icons';
import Slider, { Range } from 'rc-slider';
import './css/rc-slider.css';
import maturityInitialData, { stages } from './maturityIndexData.js';
import { useDispatch, useSelector } from 'react-redux';
import { GetMaturityData, PatchMaturityData } from '../../redux/maturitySlice.js';
import { PatchProjectData } from '../../redux/projectDataSlice.js';

const MaturityIndex = () => {
    const dispatch = useDispatch();
    const [maturityIndexData, setMaturityIndexData] = React.useState(maturityInitialData);
    const { selectedProject } = useSelector(state => state.projectData);
    const { maturityData } = useSelector(state => state.maturity);
    const renderMaturityScore = () => {
        let type =
            (selectedProject?.maturity_index_state === 'preSeed' && 'Pre Seed') ||
            (selectedProject?.maturity_index_state === 'seriesA' && 'Seed / Series A+') ||
            (selectedProject?.maturity_index_state === 'corp' && 'Private Equity / Corporate');
        let filteredData = maturityIndexData?.filter(item => item[selectedProject?.maturity_index_state]);

        let total = Number(filteredData?.length) * 5;
        const sumOfValues = filteredData.reduce((acc, item) => acc + item.value, 0);
        const percentage = (sumOfValues / total) * 100;
        return (
            <div className={styles.maturityScoreBoard}>
                Your Tech Maturity Index for a {type} Funding Round
                <div style={{ borderColor: calculateColor(parseInt(percentage)) }} className={styles.roundCirlcle}>
                    {parseInt(percentage)}
                </div>
            </div>
        );
    };
    const handleSelectSelectedType = value => {
        dispatch(
            PatchProjectData({
                data: {
                    maturity_index_state: value,
                },
                id: selectedProject?.id,
            }),
        );
        dispatch(
            PatchMaturityData({
                id: maturityData?.id,
                body: {
                    total_score: handleGetPercentage(maturityIndexData, value),
                },
            }),
        );
    };

    const calculateColor = value => {
        if (value <= 15) {
            return '#B00020';
        }
        if (value >= 16 && value <= 35) {
            return '#FF4E11';
        }
        if (value >= 36 && value <= 50) {
            return '#FF8E15';
        }
        if (value >= 51 && value <= 65) {
            return '#FAB733';
        }
        if (value >= 66 && value <= 80) {
            return '#ACB334';
        }
        if (value >= 81 && value <= 100) {
            return '#69B34C';
        }
    };
    React.useEffect(() => {
        if (maturityData?.id) {
            let data = maturityInitialData?.map(item => {
                if (maturityData?.[item?.key]) {
                    return {
                        ...item,
                        value: maturityData[item?.key],
                    };
                } else return item;
            });
            setMaturityIndexData(data);
            // getMaturityScore(data);
        }
    }, [maturityData]);

    React.useEffect(() => {
        dispatch(GetMaturityData());
    }, []);
    const handleGetPercentage = (data, type) => {
        let filteredData = data?.filter(item => item[type]);
        let total = Number(filteredData?.length) * 5;
        const sumOfValues = filteredData.reduce((acc, item) => acc + item.value, 0);
        const percentage = (sumOfValues / total) * 100;
        return parseInt(percentage);
    };

    return (
        <div className={styles.viewBody}>
            <h3 className={styles.pageHeading}>Tech Maturity Index</h3>
            <p className={styles.subTitle}>Choose your funding round and review scope items' implementation status for your Tech Maturity Index.</p>

            <div className="d-flex flex-wrap ">
                <button
                    onClick={() => handleSelectSelectedType('preSeed')}
                    className={styles.headerSimpleButton + ' ' + (selectedProject?.maturity_index_state === 'preSeed' && styles.selectedBtn)}>
                    Pre Seed
                </button>
                <button
                    onClick={() => handleSelectSelectedType('seriesA')}
                    className={styles.headerSimpleButton + ' ' + (selectedProject?.maturity_index_state === 'seriesA' && styles.selectedBtn)}>
                    Seed / Series A+
                </button>
                <button
                    onClick={() => handleSelectSelectedType('corp')}
                    className={styles.headerSimpleButton + ' ' + (selectedProject?.maturity_index_state === 'corp' && styles.selectedBtn)}>
                    Private Equity / Corporate
                </button>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-9">
                    <div className={styles.maturityIndexBody}>
                        <table className="w-100">
                            <thead>
                                <tr className={styles.tableRow}>
                                    <td className={styles.headerItem}>Scope Item</td>
                                    <td className={styles.headerItem}>
                                        <div className="d-flex align-items-center position-relative">
                                            <span className={styles.statusHeading}> Select Current Status</span> &nbsp;
                                            <div className={styles.showInfoMenu}>
                                                <Info16Regular color="var(--Neutral-Gray-6)" />
                                                <div className={styles.infoMenu}>
                                                    {stages?.map(item => (
                                                        <div className={styles.infoMenuItem}>
                                                            <p> {item?.title}</p>
                                                            <span>{item?.description}</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                {maturityIndexData?.map(
                                    item =>
                                        item[selectedProject?.maturity_index_state] && (
                                            <tr key={item?.label} className={styles.tableRow}>
                                                <td className={styles.tableItem}>
                                                    <p>{item?.label}</p>
                                                    <span>{item?.description}</span>
                                                </td>
                                                <td className="text-center ps-3 pe-5">
                                                    <TableSlider
                                                        maturityIndexData={maturityIndexData}
                                                        handleGetPercentage={handleGetPercentage}
                                                        item={item}
                                                        setMaturityIndexData={setMaturityIndexData}
                                                    />
                                                </td>
                                            </tr>
                                        ),
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-sm-12 col-md-3">{renderMaturityScore()}</div>
            </div>
        </div>
    );
};

const TableSlider = ({ item, setMaturityIndexData, handleGetPercentage, maturityIndexData }) => {
    const { maturityData } = useSelector(state => state.maturity);
    const { selectedProject } = useSelector(state => state.projectData);
    const [sliderValue, setSliderValue] = useState(item?.value);
    const [showMenu, setShowMenu] = React.useState(false);
    const dispatch = useDispatch();
    const calculateColor = value => {
        const startColor = [176, 0, 32]; // #B00020
        const middleColor = [250, 183, 51]; // #FAB733
        const endColor = [105, 179, 76]; // #69B34C
        const interpolate = (start, end, percent) => {
            return start + (end - start) * percent;
        };
        if (value <= 2) {
            const red = interpolate(startColor[0], middleColor[0], value / 2);
            const green = interpolate(startColor[1], middleColor[1], value / 2);
            const blue = interpolate(startColor[2], middleColor[2], value / 2);
            return `rgb(${red}, ${green}, ${blue})`;
        } else {
            const red = interpolate(middleColor[0], endColor[0], (value - 2) / 3);
            const green = interpolate(middleColor[1], endColor[1], (value - 2) / 3);
            const blue = interpolate(middleColor[2], endColor[2], (value - 2) / 3);
            return `rgb(${red}, ${green}, ${blue})`;
        }
    };
    const getStageContent = value => {
        const stage = stages[value];
        return stage ? (
            <div className={styles.sliderMenu}>
                <h6>{stage.title}</h6>
                <p>{stage.description}</p>
            </div>
        ) : null;
    };
    const handleSliderChange = value => {
        let updatedData = maturityIndexData?.map(data => {
            if (data?.key === item?.key) {
                return {
                    ...data,
                    value: value,
                };
            } else return data;
        });
        dispatch(
            PatchMaturityData({
                id: maturityData?.id,
                body: {
                    [item?.key]: value,
                    total_score: handleGetPercentage(updatedData, selectedProject?.maturity_index_state),
                },
            }),
        );
        setMaturityIndexData(updatedData);
        setSliderValue(value);
    };

    React.useEffect(() => {
        if (item) {
            setSliderValue(item?.value);
        }
    }, [item]);
    return (
        <div className="position-relative">
            <Slider
                value={sliderValue}
                onChange={handleSliderChange}
                step={null}
                max={5}
                onFocus={() => setShowMenu(true)}
                onBlur={() => setShowMenu(false)}
                marks={{ 0: <span />, 1: <span />, 2: <span />, 3: <span />, 4: <span />, 5: <span /> }}
                min={0}
                railStyle={{
                    background: 'var(--Neutral-Gray-5)',
                    borderRadius: 0,
                }}
                trackStyle={{
                    background: calculateColor(sliderValue),
                }}
                handleStyle={{
                    border: `1px solid ${calculateColor(sliderValue)}`,
                    background: calculateColor(sliderValue),
                    boxShadow: 'none',
                    opacity: 1,
                }}
                dotStyle={{
                    background: `var(--Neutral-Gray-5)`,
                    border: 'none',
                    borderRadius: 4,
                    width: 2,
                }}
                activeDotStyle={{
                    border: `1px solid ${calculateColor(sliderValue)}`,
                    background: calculateColor(sliderValue),
                }}
            />
            {showMenu && getStageContent(sliderValue)}
        </div>
    );
};

export default MaturityIndex;
