import React from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function MyComponent({ value, setValue }) {
    return (
        <ReactQuill
            placeholder="Write your message.."
            modules={{
                toolbar: [
                    ['bold', 'italic', 'underline'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    ['link', 'image', 'imagecustom'],
                ],
            }}
            theme="snow"
            defaultValue={value}
            onChange={setValue}
        />
    );
}
export default MyComponent;
