/* eslint-disable array-callback-return */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../apiServices/axios';
import {
    INFORMATION_REQUESTS,
    INFORMATION_RESPONSE,
    INFORMATION_REQUESTS_DELETE,
    BULK_INFO_REQ,
    STOCK_REQUEST,
    IRL_COMMENT,
    DEFAULT_QUESTION_BANK,
    IRLCoPilot,
} from '../apiServices/api_routes';
import { toast } from 'react-toastify';
import { capitalizeFirstLetter } from '../utils/commonUtils';

export const GetInformationRequests = createAsyncThunk('GET_INFORMATION_REQUESTS', async () => {
    const data = await axiosInstance.get(INFORMATION_REQUESTS + localStorage['project_id'] + '/');
    return data;
});
export const GetDefaultQuestions = createAsyncThunk('GET_DEFAULT_QUESTION_BANK', async workstream => {
    const data = await axiosInstance.get(DEFAULT_QUESTION_BANK + workstream + '/');
    return data;
});
export const PostInformationRequests = createAsyncThunk('POST_INFORMATION_REQUESTS', async ({ body }, { rejectWithValue }) => {
    try {
        const data = await axiosInstance.post(INFORMATION_REQUESTS + localStorage['project_id'] + '/', body);
        return data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const DeleteInformationRequests = createAsyncThunk('DELETE_INFORMATION_REQUESTS', async id => {
    const data = await axiosInstance.delete(INFORMATION_REQUESTS + localStorage['project_id'] + '/' + id + '/');
    return data;
});
export const PatchInformationRequests = createAsyncThunk('PATCH_INFORMATION_REQUESTS', async ({ body }) => {
    const data = await axiosInstance.patch(INFORMATION_REQUESTS + localStorage['project_id'] + '/' + body?.id + '/', body);
    return data;
});
export const PostInformationResponse = createAsyncThunk('POST_INFORMATION_RESPONSE', async ({ body }) => {
    const data = await axiosInstance.post(INFORMATION_RESPONSE + localStorage['project_id'] + '/', body);
    return data;
});
export const DeleteInformationResponse = createAsyncThunk('DELETE_INFORMATION_REQUESTS_DELETE', async body => {
    const data = await axiosInstance.delete(INFORMATION_REQUESTS_DELETE + localStorage['project_id'] + '/', { data: body });
    return data;
});
export const UpdateBulkInformationRequest = createAsyncThunk('UPDATE_BULK_INFO_REQ', async body => {
    const data = await axiosInstance.put(BULK_INFO_REQ + localStorage['project_id'] + '/', body);
    return data;
});
export const UploadCSVFile = createAsyncThunk('UPLOAD_CSV_FILE', async body => {
    const data = await axiosInstance.post(INFORMATION_REQUESTS + localStorage['project_id'] + '/bulk_upload_csv/', body);
    return data;
});
export const GetStockRequest = createAsyncThunk('GET_STOCK_REQUEST', async () => {
    const data = await axiosInstance.get(STOCK_REQUEST + '/');
    return data;
});
export const AddBulkInformationRequests = createAsyncThunk('POST_INFORMATION_REQUESTS_BULK_ADD', async body => {
    const data = await axiosInstance.post(INFORMATION_REQUESTS + localStorage['project_id'] + '/bulk_add/', body);
    return data;
});
export const PostIRLComment = createAsyncThunk('POST_IRL_COMMENT', async ({ body }) => {
    const data = await axiosInstance.post(IRL_COMMENT + localStorage['project_id'] + '/', body);
    return data;
});
export const FetchIrlCopilotResults = createAsyncThunk('POST_IRLCoPilot', async ({ body }) => {
    const data = await axiosInstance.post(IRLCoPilot + localStorage['project_id'] + '/swot-analysis/', body);
    return data;
});
export const questionBankSlice = createSlice({
    name: 'questionBankSlice',
    initialState: {
        questionsData: [],
        questionsFilters: {},
        informationRequests: [],
        managementResponses: [],
        customRequestLoading: false,
        addMgmtLoading: false,
        addtoIRLBtnLoading: false,
        informationRequestsFilters: {},
        stockRequests: [],
        bulkDeleteBtnLoading: false,
        irlCommentButtonLoading: false,
        joyRideStepIndex: 0,
        questionBank: [],
        uploadCSVBtnLoading: false,
        irlCopilotLoading: false,
    },
    reducers: {
        updateFilters: (state, { payload }) => {
            return { ...state, questionsFilters: payload };
        },
        setJoyRideStepIndex: (state, { payload }) => {
            return { ...state, joyRideStepIndex: payload };
        },
        resetQestionBankData: (state, { payload }) => {
            return {
                questionsData: [],
                questionsFilters: {},
                informationRequests: [],
                managementResponses: [],
                customRequestLoading: false,
                addMgmtLoading: false,
                addtoIRLBtnLoading: false,
                informationRequestsFilters: {},
                stockRequests: [],
                bulkDeleteBtnLoading: false,
                irlCommentButtonLoading: false,
                joyRideStepIndex: 0,
                questionBank: [],
            };
        },
    },
    extraReducers: {
        //GetInformationRequests
        [GetInformationRequests.pending]: (state, action) => {
            state.status = 'loading';
        },
        [GetInformationRequests.fulfilled]: (state, { payload }) => {
            state.status = 'success';
            state.informationRequests = payload?.data['information requests'];
            state.informationRequestsFilters = payload?.data['filters'];
        },
        [GetInformationRequests.rejected]: (state, action) => {
            state.status = 'rejected';
            toast.error('Error');
        },
        //PostInformationRequests
        [PostInformationRequests.pending]: (state, action) => {
            state.status = 'loading';
            state.customRequestLoading = true;
        },
        [PostInformationRequests.fulfilled]: (state, { payload }) => {
            state.status = 'success';
            toast.success('Success');
            state.informationRequests.push(payload?.data);
            state.customRequestLoading = false;
        },
        [PostInformationRequests.rejected]: (state, { payload }) => {
            state.customRequestLoading = false;
            try {
                Object.keys(payload).map(key => {
                    payload[key].map(msg => toast.error(capitalizeFirstLetter(key) + ' : ' + msg));
                });
            } catch (error) {
                console.log(error);
            }
            state.status = 'rejected';
        },
        //PatchInformationRequests
        [PatchInformationRequests.pending]: (state, action) => {
            state.status = 'loading';
            state.customRequestLoading = true;
        },
        [PatchInformationRequests.fulfilled]: (state, { payload }) => {
            state.status = 'success';
            toast.success('Success');
            state.informationRequests = state.informationRequests?.map(item => {
                if (item?.id === payload?.data?.id) return payload?.data;
                else return item;
            });
            state.customRequestLoading = false;
        },

        [PatchInformationRequests.rejected]: (state, action) => {
            state.status = 'rejected';
            toast.error('Error');
            state.customRequestLoading = false;
        },
        // DeleteInformationRequests
        [DeleteInformationRequests.fulfilled]: (state, { meta }) => {
            state.status = 'success';
            toast.success('Success');
            state.informationRequests = state.informationRequests?.filter(item => item?.id !== meta?.arg);
            // state.informationRequests = state.informationRequests?.map(item => {
            //     if (item?.id === payload?.data?.id) return payload?.data;
            //     else return item;
            // });
            state.customRequestLoading = false;
        },
        //PostInformationResponse
        [PostInformationResponse.pending]: (state, action) => {
            state.status = 'loading';
        },
        [PostInformationResponse.fulfilled]: (state, { payload }) => {
            state.status = 'success';
            toast.success('Success');
            console.log(payload);
            state.informationRequests = state.informationRequests?.map(item => {
                if (payload?.data?.information_request === item?.id) {
                    return { ...item, irresponse: payload?.data };
                } else return item;
            });
        },
        [PostInformationResponse.rejected]: (state, action) => {
            state.status = 'rejected';
            toast.error('Error');
        },

        //DeleteInformationResponse
        [DeleteInformationResponse.pending]: (state, action) => {
            state.status = 'loading';
            state.bulkDeleteBtnLoading = true;
        },
        [DeleteInformationResponse.fulfilled]: (state, action) => {
            const ids = action?.meta?.arg?.ids;
            state.informationRequests = state.informationRequests.filter(item => {
                let check = ids.find(id => id === item?.id);
                if (!check) return item;
            });
            state.status = 'rejected';
            state.bulkDeleteBtnLoading = false;
            toast.success('Success');
        },
        [DeleteInformationResponse.rejected]: (state, action) => {
            state.status = 'rejected';
            toast.error('Error');
            state.bulkDeleteBtnLoading = false;
        },

        //UpdateBulkInformationRequest
        [UpdateBulkInformationRequest.pending]: (state, action) => {
            state.status = 'loading';
            state.addtoIRLBtnLoading = true;
        },
        [UpdateBulkInformationRequest.fulfilled]: (state, action) => {
            const ids = action?.meta?.arg?.ids;
            console.log({ ids, action });
            state.informationRequests = state.informationRequests.map(item => {
                let updated = action?.payload?.data?.find(ir => ir?.id === item?.id);
                if (updated) return updated;
                else return item;
            });
            state.status = 'success';
            toast.success('Success');
            state.addtoIRLBtnLoading = false;
        },
        [UpdateBulkInformationRequest.rejected]: (state, action) => {
            state.status = 'rejected';
            toast.error('Error');
            state.addtoIRLBtnLoading = false;
        },
        //GetStockRequest
        [GetStockRequest.pending]: (state, action) => {
            state.status = 'loading';
        },
        [GetStockRequest.fulfilled]: (state, action) => {
            state.status = 'success';
            state.stockRequests = action?.payload.data;
        },
        [GetStockRequest.rejected]: (state, action) => {
            state.status = 'rejected';
        },
        //AddBulkInformationRequests
        [AddBulkInformationRequests.pending]: (state, action) => {
            state.addtoIRLBtnLoading = true;
        },
        [AddBulkInformationRequests.fulfilled]: (state, action) => {
            toast.success('Success');
            state.addtoIRLBtnLoading = false;
            state.informationRequests = [...state.informationRequests, ...action.payload.data];
            // state.stockRequests = action?.payload.data;
        },
        [AddBulkInformationRequests.rejected]: (state, action) => {
            state.addtoIRLBtnLoading = false;
            toast.error('Rejected');
        },
        //PostIRLComment
        [PostIRLComment.pending]: (state, action) => {
            state.status = 'loading';
            state.irlCommentButtonLoading = true;
        },
        [PostIRLComment.fulfilled]: (state, { payload }) => {
            state.status = 'success';
            toast.success('Success');
            state.informationRequests = state.informationRequests?.map(item => {
                if (item?.id === payload?.data?.information_request) {
                    return {
                        ...item,
                        irlcomment: item?.irlcomment?.length > 0 ? [...item?.irlcomment, payload?.data] : [payload?.data],
                    };
                } else return item;
            });
            state.irlComments = state?.irlComments?.length > 0 ? [...state.irlComments, payload?.data] : [payload?.data];
            state.irlCommentButtonLoading = false;
        },
        [PostIRLComment.rejected]: (state, action) => {
            toast.error('Failed');
            state.status = 'rejected';
            state.irlCommentButtonLoading = false;
        },
        //GetDefaultQuestions
        [GetDefaultQuestions.fulfilled]: (state, action) => {
            state.questionBank = action?.payload?.data;
        },
        //UploadCSVFile
        [UploadCSVFile.pending]: (state, action) => {
            // state.questionBank = action?.payload?.data;
            state.uploadCSVBtnLoading = true;
        },
        [UploadCSVFile.fulfilled]: (state, action) => {
            // state.questionBank = action?.payload?.data;
            console.log({ action });
            state.uploadCSVBtnLoading = false;
            toast.success('CSV file uploaded');
        },
        [UploadCSVFile.rejected]: (state, action) => {
            // state.questionBank = action?.payload?.data;
            state.uploadCSVBtnLoading = false;
            toast.error('Upload Failed');
        },
        //FetchIrlCopilotResults
        [FetchIrlCopilotResults.pending]: (state, action) => {
            // state.questionBank = action?.payload?.data;
            state.irlCopilotLoading = true;
        },
        [FetchIrlCopilotResults.fulfilled]: (state, action) => {
            // state.questionBank = action?.payload?.data;
            state.irlCopilotLoading = false;
        },
        [FetchIrlCopilotResults.rejected]: (state, action) => {
            // state.questionBank = action?.payload?.data;
            state.irlCopilotLoading = false;
        },
    },
});
export const { updateFilters, setJoyRideStepIndex, resetQestionBankData } = questionBankSlice.actions;
export default questionBankSlice.reducer;
