import React from 'react';
import styles from './DoubleConfirmationModal.module.css';
import { Modal } from 'react-bootstrap';
import { Delete48Filled, Warning48Filled } from '@fluentui/react-icons';
const DoubleConfirmationModal = ({
    children,
    message,
    handleAction,
    isMenuChild,
    modalOneBtnL = 'Delete',
    modalOneBtnR = 'Cancel',
    modalTwoBtnL = 'Cancel',
    modalTwoBtnR = 'Delete',
    type = 'delete',
    firstModalActionBtn = () => {},
}) => {
    const [firstModalVisible, setFirstModalVisible] = React.useState(false);
    const [secondModalVisible, setSecondModalVisible] = React.useState(false);
    const handleClick = () => {
        handleAction();
        setSecondModalVisible(false);
    };
    return (
        <>
            <div role="button" className={isMenuChild && styles.menuStyle} onClick={() => setFirstModalVisible(true)}>
                {children}
            </div>
            <Modal centered show={firstModalVisible} onHide={() => setFirstModalVisible(false)} className={styles.modalMainBody}>
                <Modal.Body className={styles.confirmationModalBody}>
                    <div className={styles.confirmationModalMsg}>
                        <div className="mb-4 text-center">
                            {type === 'delete' ? <Delete48Filled color="var(--Brand-Primary)" /> : <Warning48Filled color="var(--Brand-Primary)" />}
                        </div>
                        <p className={styles.message}>{message}</p>
                        <div className="d-flex justify-content-between align-items-center">
                            <button
                                onClick={() => {
                                    setFirstModalVisible(false);
                                    firstModalActionBtn();
                                }}
                                className={styles.secondaryBtn}>
                                {modalOneBtnR}
                            </button>
                            <span
                                onClick={() => {
                                    setFirstModalVisible(false);
                                    setSecondModalVisible(true);
                                }}
                                className={styles.orangeLink}>
                                {modalOneBtnL}
                            </span>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal centered show={secondModalVisible} onHide={() => setSecondModalVisible(false)} className={styles.modalMainBody}>
                <Modal.Body className={styles.confirmationModalBody}>
                    <div className={styles.confirmationModalMsg}>
                        <div className="mb-4 text-center">
                            {type === 'delete' ? <Delete48Filled color="var(--Brand-Primary)" /> : <Warning48Filled color="var(--Brand-Primary)" />}
                        </div>

                        <p className={styles.message + ' mb-0'}>Are you sure?</p>
                        <p className={styles.message}>This process cannot be undone.</p>
                        <div className="d-flex justify-content-between align-items-center">
                            <span onClick={handleClick} className={styles.orangeLink}>
                                {modalTwoBtnR}
                            </span>
                            <button
                                onClick={() => {
                                    setSecondModalVisible(false);
                                }}
                                className={styles.secondaryBtn}>
                                {modalTwoBtnL}
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default DoubleConfirmationModal;
