import React, { useMemo } from 'react';
import styles from './css/index.module.css';

import { ChevronDown20Regular, Dismiss16Regular, Add20Regular, Info20Regular } from '@fluentui/react-icons';
import { PatchWSFolder, PostAllWSFolders, PostWSFolder } from '../../redux/dataroomSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { Tooltip } from '@fluentui/react-components';

const AddFolderSidebar = ({ setAddFolderSidebar, setShowSidebar, workstream, selectedFolders, isEditFolder, setSelectedFolders }) => {
    const dispatch = useDispatch();

    const selectedFolder = useMemo(() => {
        return selectedFolders?.length > 0 ? selectedFolders[selectedFolders.length - 1] : {};
    }, [selectedFolders]);

    const [showCreateView, setShowCreateView] = React.useState(false);
    const [formData, setFormData] = React.useState({
        folder_name: '',
        folder_desc: '',
    });
    const { default_folders, folderBtnLoading, addAllFolderLoading, items } = useSelector(state => state?.dataroom);
    const handleClose = () => {
        setShowSidebar(false);
        setAddFolderSidebar(false);
        setFormData({
            folder_name: '',
            folder_desc: '',
        });
    };
    const handleAddFolder = async data => {
        let body = {
            name: data?.ws_folder,
            description: data?.ws_folder_desc,
            requested: true,
            workstream: workstream?.id,
        };
        await dispatch(PostWSFolder(body));
    };
    const handleSubmit = async e => {
        if (isEditFolder) {
            let body = {
                name: formData?.folder_name,
                description: formData?.folder_desc,
                workstream: workstream?.id,
                id: selectedFolder?.id,
            };
            console.log({ body });
            // let res = await dispatch(PatchWSFolder(body));
            // if (res?.type === 'PATCH_IRL_ITEMS/fulfilled')
            //     setSelectedFolders(
            //         selectedFolders?.map(item => {
            //             if (item?.id === selectedFolder?.id) return res?.payload?.data;
            //             else return item;
            //         }),
            //     );
        } else {
            let body = {
                name: formData?.folder_name,
                description: formData?.folder_desc,
                requested: true,
                workstream: workstream?.id,
            };
            if (selectedFolder?.id) {
                body.parent = selectedFolder?.id;
            }
            await dispatch(PostWSFolder(body));
        }

        handleClose();
    };

    React.useEffect(() => {
        if (isEditFolder && selectedFolder) {
            setShowCreateView(true);
            setFormData({
                folder_name: selectedFolder?.name,
                folder_desc: selectedFolder?.description,
            });
            // setNewWorkstream(workstream?.name);
        }
    }, [isEditFolder, selectedFolder]);

    return (
        <div className={styles.sidebarWhite + ' ps-0 pe-0'}>
            <disv className="d-flex align-items-center justify-content-between ps-3 pe-3">
                <h5 className={styles.sidebarTitle}>{showCreateView ? (isEditFolder ? 'Edit Folder Label' : 'Create New Folder') : 'Add Folders'}</h5>
                <Dismiss16Regular onClick={handleClose} role="button" color="var(--Neutral-Gray-6)" />
            </disv>

            {showCreateView ? (
                <div className={styles.createFolderBody + ' ps-3 pe-3'}>
                    {!isEditFolder && (
                        <div className="d-flex align-items-center mb-4 ">
                            <span>{selectedFolder?.name || workstream?.name}</span>
                            <ChevronDown20Regular className="ms-2" />
                        </div>
                    )}

                    <div className="mb-2">
                        <p className={styles.fieldLabel}>
                            {isEditFolder ? 'Type New Label' : 'Folder Name'} <span style={{ color: '#B10E1C' }}>*</span>
                        </p>
                        <input
                            value={formData?.folder_name}
                            onChange={e => setFormData({ ...formData, folder_name: e.target.value })}
                            className={styles.inputField + ' form-control'}
                        />
                    </div>
                    <div className="mb-4">
                        <p className={styles.fieldLabel}>
                            {isEditFolder ? 'Type New Description' : 'Folder Description'} <span style={{ color: '#B10E1C' }}>*</span>
                        </p>
                        <input
                            value={formData?.folder_desc}
                            onChange={e => setFormData({ ...formData, folder_desc: e.target.value })}
                            className={styles.inputField + ' form-control'}
                        />
                    </div>
                    <button onClick={handleSubmit} disabled={!formData?.folder_desc || !formData?.folder_name || folderBtnLoading} className={styles.saveButton}>
                        Save {folderBtnLoading && <AiOutlineLoading3Quarters className="rotateLoader" />}
                    </button>
                </div>
            ) : (
                <div className="mt-5">
                    <div className={styles.addFolderLink}>
                        <p onClick={() => dispatch(PostAllWSFolders(workstream?.id))} className={styles.buttonLink + ' mb-3'}>
                            Add all {addAllFolderLoading && <AiOutlineLoading3Quarters className="rotateLoader" />}
                        </p>
                    </div>
                    <div>
                        {default_folders?.map(item => {
                            let check = items?.find(folder => folder?.name === item?.ws_folder);
                            if (!check) {
                                return (
                                    <div key={item?.id} className={styles.addWorkstreamList + ' create-workstream-step'}>
                                        <span style={{ color: 'var(--muted)' }}>
                                            <Tooltip positioning="before-top" withArrow content={item?.ws_folder_desc}>
                                                <Info20Regular className={styles.infoIcon} />
                                            </Tooltip>
                                            {item?.ws_folder}
                                        </span>
                                        <Add20Regular className={styles.addIcon} onClick={() => handleAddFolder(item)} role="button" color="var(--Brand-Secondary)" />
                                    </div>
                                );
                            }
                        })}
                    </div>
                    <div className={styles.addFolderLink + ' mt-3'}>
                        <p onClick={() => setShowCreateView(true)} className={styles.buttonLink}>
                            Create New
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};
export default AddFolderSidebar;
