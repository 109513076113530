const governanceData = [
    {
        topic: 'Cybersecurity Leadership & Risk Management',
        responses: [
            {
                question: 'Who owns cybersecurity and how defined are the risks that it poses to your business?',
                isSubmitted: false,
                answer: '',
                preSeed: true,
                seriesA: true,
                corp: true,
            },
            {
                question: 'Who has visibility of and quantifies the risks posed and how often are the results reviewed with the executive management team?',
                isSubmitted: false,
                answer: '',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
        ],
    },
    {
        topic: 'Cyber Education',
        responses: [
            {
                question: 'How do you educate and test your workforce on cybersecurity related threat and their responsibilities?',
                isSubmitted: false,
                answer: '',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
        ],
    },
    {
        topic: 'Security/Privacy Breaches',
        responses: [
            {
                question: 'Have you experienced any information / data security breaches during the past 2 years?',
                isSubmitted: false,
                isBoolean: true,
                answer: '',
                preSeed: true,
                seriesA: true,
                corp: true,
            },
            {
                question: 'If so, how did they occur, how were they responded to and what remedial actions were taken and what were the lessons learned?',
                isSubmitted: false,
                answer: '',
                preSeed: true,
                seriesA: true,
                corp: true,
            },
            {
                question: 'Was there any requirement for external notification ( for example to customers, the ICO etc)?',
                isSubmitted: false,
                answer: '',
                preSeed: true,
                seriesA: true,
                corp: true,
            },
        ],
    },
    {
        topic: 'Security Solutions',
        responses: [
            {
                question:
                    'Can you please give an overview of the technology solutions you have deployed to support the ability to protect, detect and respond to confirmed or suspected cyber-attack?',
                seriesA: true,
                corp: true,
                isSubmitted: false,
                answer: '',
            },
        ],
    },
    {
        topic: 'Penetration Testing',
        responses: [
            {
                question: 'Please define the scope and frequency of penetration testing and vulnerability scanning on you internal and publicly facing systems.',
                isSubmitted: false,
                answer: '',
                preSeed: true,
                seriesA: true,
                corp: true,
            },
        ],
    },
    {
        topic: 'Multi-Factor Authentication',
        responses: [
            {
                question: 'Do you have any services exposed to the internet which are not protected by multifactor authentication?',
                isSubmitted: false,
                answer: '',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
        ],
    },
    {
        topic: 'Asset Management and Patching',
        responses: [
            {
                question: 'Do you have an accurate and up to date inventory of hardware and software assets?',
                isSubmitted: false,
                answer: '',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                question: 'Please describe you approach to security Patching',
                isSubmitted: false,
                answer: '',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
        ],
    },
    {
        topic: 'Backup and Recovery',
        responses: [
            {
                question: 'Do you have immutable backups for critical systems and data?',
                isSubmitted: false,
                answer: '',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
            {
                question: 'How do you ensure that you can recover from a serious cyber incident (e.g. ransomware) in an acceptable time period?',
                isSubmitted: false,
                answer: '',
                preSeed: true,
                seriesA: true,
                corp: true,
            },
        ],
    },
    {
        topic: 'Secure Development',
        responses: [
            {
                question:
                    'Please describe your approach to secure development (SCLD) and associated tooling, such as static and dynamic testing. What is the level of expertise in Security within the Development Teams?',
                isSubmitted: false,
                answer: '',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
        ],
    },
    {
        topic: 'Security Policy Compliance',
        responses: [
            {
                question: 'Please describe your policy and approach to ensuring compliance with relevant data protection legislation.',
                isSubmitted: false,
                answer: '',
                preSeed: false,
                seriesA: true,
                corp: true,
            },
        ],
    },
];

export default governanceData;
