import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../apiServices/axios';
import { toast } from 'react-toastify';
import {
    INVITE_TEAM,
    PROJECT_USERS_PERMISSIONS,
    DATAROOM_GROUPS,
    GROUPS,
    GROUPS_PERMISSIONS,
    GROUPS_MEMBER_PERMISSIONS,
    GROUP_MEMBER,
    DOC_PROJECT,
    COMPANY_VALUES,
    TEAM_MEMBER_ANALYSIS,
} from '../apiServices/api_routes';
import { calculateTeamScore, setBgcolor } from '../utils/commonUtils';

export const InviteTeamMember = createAsyncThunk('INVITE_TEAM', async (body, { rejectWithValue }) => {
    try {
        const res = await axiosInstance.post(INVITE_TEAM + localStorage['project_id'] + '/create-send-invite/', body);
        return res;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const ResendInvite = createAsyncThunk('RESEND_INVITE', async (body, { rejectWithValue }) => {
    try {
        const res = await axiosInstance.post(INVITE_TEAM + localStorage['project_id'] + '/' + body.vdrinvitation_permission?.invitation + '/send-invite/', body);
        return res;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const PostDataroomGroup = createAsyncThunk('POST_DATAROOM_GROUPS', async (body, { rejectWithValue }) => {
    try {
        const res = await axiosInstance.post(DATAROOM_GROUPS + localStorage['project_id'] + '/create_group/', body);
        return res;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const PatchDataroomGroup = createAsyncThunk('PATCH_DATAROOM_GROUPS', async (body, { rejectWithValue }) => {
    try {
        const res = await axiosInstance.put(DATAROOM_GROUPS + localStorage['project_id'] + '/' + body?.id + '/update_group/', body);
        return res;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const GetAllGroups = createAsyncThunk('GET_GROUPS', async () => {
    const data = await axiosInstance.get(GROUPS + localStorage['project_id'] + '/');
    return data;
});
export const GetGroupPermissions = createAsyncThunk('GET_GROUP_PERMISSIONS', async id => {
    const data = await axiosInstance.get(GROUPS + localStorage['project_id'] + '/' + id + '/all_permissions/');
    return data;
});
export const GetGroupMembers = createAsyncThunk('GET_GROUP_MEMBERS', async () => {
    const data = await axiosInstance.get(GROUPS_MEMBER_PERMISSIONS + localStorage['project_id'] + '/');
    return data;
});
export const PatchGroupMember = createAsyncThunk('PATCH_GROUP_MEMBER', async body => {
    const data = await axiosInstance.patch(GROUPS_MEMBER_PERMISSIONS + localStorage['project_id'] + '/' + body?.id + '/', body);
    return data;
});

export const DeleteGroupMember = createAsyncThunk('DELETE_GROUP_MEMBER', async (id, { rejectWithValue }) => {
    try {
        const data = await axiosInstance.delete(GROUPS_MEMBER_PERMISSIONS + localStorage['project_id'] + '/' + id + '/');
        return data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const GetGroupMemberPermissions = createAsyncThunk('GET_GROUP_MEMBER_PERMISSIONS', async id => {
    const data = await axiosInstance.get(GROUPS_MEMBER_PERMISSIONS + localStorage['project_id'] + '/' + id + '/all_permissions/');
    return data;
});
export const UpdateGroupPermissions = createAsyncThunk('PUT_GROUPS_PERMISSIONS', async body => {
    const data = await axiosInstance.put(GROUPS_PERMISSIONS + localStorage['project_id'] + '/bulk_group_permissions/', body);
    return data;
});
export const UpdateUserPermissions = createAsyncThunk('PUT_USERS_PERMISSIONS', async body => {
    const data = await axiosInstance.put(GROUPS_PERMISSIONS + localStorage['project_id'] + '/bulk_user_permissions/', body);
    return data;
});
export const UpdateGroupMember = createAsyncThunk('PUT_GROUP_MEMBERS', async body => {
    const data = await axiosInstance.put(GROUP_MEMBER + localStorage['project_id'] + '/' + body?.id + '/update_member/', body);
    return data;
});
export const DeleteGroup = createAsyncThunk('DELETE_GROUPS', async id => {
    const data = await axiosInstance.delete(GROUPS + localStorage['project_id'] + '/' + id + '/');
    return data;
});
export const GetProjectUsers = createAsyncThunk('GET_PROJECT_USERS', async () => {
    const data = await axiosInstance.get(`${DOC_PROJECT + localStorage['project_id']}/get_vdr_users/`);
    return data;
});

export const RevokeUserInvite = createAsyncThunk('DELETE_USER_INVITE', async (id, { rejectWithValue }) => {
    try {
        const data = await axiosInstance.delete(INVITE_TEAM + localStorage['project_id'] + '/' + id + '/');
        return data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const GetProjectUserPermissions = createAsyncThunk('GET_PROJECT_USERS_PERMISSIONS', async (props, { rejectWithValue }) => {
    try {
        const data = await axiosInstance.get(PROJECT_USERS_PERMISSIONS + localStorage['project_id'] + '/');
        return data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const UpdateBulkTeamPermissions = createAsyncThunk('UPDATE_BULK_PROJECT_USERS_PERMISSIONS', async (body, { rejectWithValue }) => {
    try {
        const res = await axiosInstance.put(PROJECT_USERS_PERMISSIONS + localStorage['project_id'] + '/bulk_permissions/', body);
        return res;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const GetCompanyValues = createAsyncThunk('GET_COMPANY_VALUES', async () => {
    const data = await axiosInstance.get(COMPANY_VALUES + localStorage['project_id'] + '/');
    return data;
});
export const PostCompanyValues = createAsyncThunk('POST_COMPANY_VALUES', async body => {
    const data = await axiosInstance.post(COMPANY_VALUES + localStorage['project_id'] + '/', body);
    return data;
});
export const PatchCompanyValues = createAsyncThunk('PATCH_COMPANY_VALUES', async body => {
    const data = await axiosInstance.patch(COMPANY_VALUES + localStorage['project_id'] + '/' + body?.id + '/', body);
    return data;
});
export const PostTeamMemberAnalysis = createAsyncThunk('POST_TEAM_MEMBER_ANALYSIS', async body => {
    const data = await axiosInstance.post(TEAM_MEMBER_ANALYSIS + localStorage['project_id'] + '/', body);
    return data;
});
export const PatchTeamMemberAnalysis = createAsyncThunk('PATCH_TEAM_MEMBER_ANALYSIS', async body => {
    const data = await axiosInstance.patch(TEAM_MEMBER_ANALYSIS + localStorage['project_id'] + '/' + body?.id + '/', body);
    return data;
});
export const DeleteTeamMemberAnalysis = createAsyncThunk('DELETE_TEAM_MEMBER_ANALYSIS', async id => {
    const data = await axiosInstance.delete(TEAM_MEMBER_ANALYSIS + localStorage['project_id'] + '/' + id + '/');
    return data;
});
export const GetTeamMemberAnalysis = createAsyncThunk('GET_TEAM_MEMBER_ANALYSIS', async () => {
    const data = await axiosInstance.get(TEAM_MEMBER_ANALYSIS + localStorage['project_id'] + '/');
    return data;
});
export const teamSlice = createSlice({
    name: 'teamSlice',
    initialState: {
        state: '',
        teamFormBtnLoading: false,
        projectUsersAll: [],
        permissions: [],
        permissionsBtnLoading: false,
        groups: [],
        groupPermissions: [],
        selectedUserData: null,
        groupMembersAll: [],
        joyRideStepIndex: 0,
        companyValues: [],
        teamAnalysisMembers: [],
    },
    reducers: {
        clearSelectedUserData: (state, { payload }) => {
            return { ...state, selectedUserData: null };
        },

        setJoyRideStepIndex: (state, { payload }) => {
            return { ...state, joyRideStepIndex: payload };
        },
        resetTeamData: (state, { payload }) => {
            return {
                state: '',
                teamFormBtnLoading: false,
                projectUsersAll: [],
                permissions: [],
                permissionsBtnLoading: false,
                groups: [],
                groupPermissions: [],
                selectedUserData: null,
                groupMembersAll: [],
                joyRideStepIndex: 0,
            };
        },
    },
    extraReducers: {
        //InviteTeamMember
        [InviteTeamMember.pending]: (state, action) => {
            state.teamFormBtnLoading = true;
        },
        [InviteTeamMember.fulfilled]: (state, { payload, meta }) => {
            state.teamFormBtnLoading = false;
            function isStringifiedObject(str) {
                try {
                    JSON.parse(str);
                    console.log('pass');
                    return true;
                } catch (e) {
                    console.log('error', e);
                    return false;
                }
            }
            function preprocessString(string) {
                const modifiedString = string.replace(/'/g, '"');
                const processedString = modifiedString.replace(/([{,])(\s*)([A-Za-z0-9_\-]+?)\s*:/g, '$1"$3":');
                return processedString;
            }
            console.log(meta?.arg);
            meta?.arg?.emails?.map(email => {
                if (typeof payload?.data[email] === 'string') {
                    var processedString = preprocessString(payload?.data[email]);
                    if (isStringifiedObject(processedString)) {
                        const parsedData = JSON.parse(processedString);
                        parsedData?.message && toast.info(parsedData?.full_name + ' is already a Lens user,and ' + parsedData?.message);
                        let data = {
                            group: `Invited ${meta?.arg?.group_type}: ` + state?.groups?.find(item => item?.id === meta?.arg?.group_type)?.name,
                            value: {
                                expired: false,
                                invitation: true,
                                group_member_id: parsedData?.id,
                                email: parsedData?.email,
                            },
                        };
                        state.projectUsersAll.push(data);
                    } else {
                        toast.info(payload?.data[email]);
                    }
                }
                if (payload?.data[email]?.id) {
                    toast.success('Successfully Invited');
                    let data = {
                        group: `Invited ${meta?.arg?.group_type}: ` + state?.groups?.find(item => item?.id === meta?.arg?.group_type)?.name,
                        value: {
                            expired: false,
                            invitation: true,
                            invite_id: payload?.data[email]?.id,
                            email: payload?.data[email]?.email,
                        },
                    };
                    state.projectUsersAll.push(data);
                }
            });
        },
        [InviteTeamMember.rejected]: (state, action) => {
            state.teamFormBtnLoading = false;
            // toast.error(action?.payload?.details);
            // action?.payload?.email && toast.error('Email: ' + action?.payload?.email[0]);
            // action?.payload?.full_name && toast.error('Full Name: ' + action?.payload?.full_name[0]);
        },

        //GetProjectUsers
        [GetProjectUsers.pending]: (state, action) => {
            state.status = 'loading';
        },
        [GetProjectUsers.fulfilled]: (state, action) => {
            state.status = 'success';
            let data = action.payload?.data[`Project's Users`];
            let dataArr = [];
            let dataArrAll = [];
            let userId = localStorage.getItem('user_id');
            if (data) {
                Object.keys(data)?.map(key => {
                    if (Object.keys(data[key])?.length > 0) {
                        Object.keys(data[key])?.map(key2 => {
                            dataArrAll.push({
                                value: data[key][key2],
                                group: key,
                                color: setBgcolor(key),
                                organisation: data[key][key2]?.organisation,
                            });
                        });
                    }
                });
            }

            state.projectUsersAll = dataArrAll;
        },
        [GetProjectUsers.rejected]: (state, action) => {
            state.status = 'rejected';
        },
        //DeleteGroupMember
        [DeleteGroupMember.pending]: (state, action) => {},
        [DeleteGroupMember.fulfilled]: (state, { meta }) => {
            state.projectUsersAll = state.projectUsersAll.filter(item => item?.value?.group_member_id !== meta?.arg);
            toast.success('Removed Successfully');
        },
        [DeleteGroupMember.rejected]: (state, action) => {
            toast.error(action?.payload?.detail);
        },
        //RevokeUserInvite
        [RevokeUserInvite.pending]: (state, action) => {},
        [RevokeUserInvite.fulfilled]: (state, { meta }) => {
            state.projectUsersAll = state.projectUsersAll.filter(item => item?.value?.invite_id !== meta?.arg);
            toast.success('Removed Successfully');
        },
        [RevokeUserInvite.rejected]: (state, action) => {
            toast.error(action?.payload?.detail);
        },
        //GetProjectUserPermissions
        [GetProjectUserPermissions.pending]: (state, action) => {},
        [GetProjectUserPermissions.fulfilled]: (state, { payload }) => {
            state.permissions = payload.data;
        },
        [GetProjectUserPermissions.rejected]: (state, { payload }) => {
            toast.error(payload?.detail);
        },
        //UpdateBulkTeamPermissions
        [UpdateBulkTeamPermissions.pending]: (state, action) => {
            state.permissionsBtnLoading = true;
        },
        [UpdateBulkTeamPermissions.fulfilled]: (state, { payload }) => {
            state.permissions = payload.data;
            state.permissionsBtnLoading = false;
            toast.success('Successfully Edited');
        },
        [UpdateBulkTeamPermissions.rejected]: (state, action) => {
            state.permissionsBtnLoading = false;
            toast.error('Failed');
        },
        //PostDataroomGroup
        [PostDataroomGroup.pending]: (state, action) => {
            state.permissionsBtnLoading = true;
        },
        [PostDataroomGroup.fulfilled]: (state, { payload }) => {
            state.groups.push(payload.data);
            state.permissionsBtnLoading = false;
            toast.success('Successfully Created');
        },
        [PostDataroomGroup.rejected]: (state, action) => {
            state.permissionsBtnLoading = false;
            toast.error('Failed');
        },
        //PatchDataroomGroup
        [PatchDataroomGroup.pending]: (state, action) => {
            state.permissionsBtnLoading = true;
        },
        [PatchDataroomGroup.fulfilled]: (state, { payload }) => {
            state.groups = state.groups?.map(item => {
                if (item?.id === payload?.data?.id) return payload?.data;
                else return item;
            });
            state.permissionsBtnLoading = false;
            toast.success('Successfully Edited');
        },
        [PatchDataroomGroup.rejected]: (state, action) => {
            state.permissionsBtnLoading = false;
            toast.error('Failed');
        },
        //DeleteGroup
        [DeleteGroup.pending]: (state, action) => {
            // state.permissionsBtnLoading = true;
        },
        [DeleteGroup.fulfilled]: (state, { meta }) => {
            state.groups = state.groups?.filter(item => item?.id !== meta?.arg);
            toast.success('Successfully Deleted');
        },
        [DeleteGroup.rejected]: (state, action) => {
            // state.permissionsBtnLoading = false;
            toast.error('Failed');
        },
        //GetAllGroups

        [GetAllGroups.fulfilled]: (state, { payload }) => {
            state.groups = payload.data;
        },
        //GetGroupPermissions

        [GetGroupPermissions.fulfilled]: (state, { payload }) => {
            if (payload.data?.length > 0) state.groupPermissions = payload.data[0];
        },
        //GetGroupMemberPermissions

        [GetGroupMemberPermissions.fulfilled]: (state, { payload }) => {
            // console.log({ payload });
            state.selectedUserData = payload.data; //I added this line today
            // if (payload.data?.length > 0) state.selectedUserData = payload.data[0];   //This is the previous code which is expecting it as arra
        },
        //GetGroupMembers

        [GetGroupMembers.fulfilled]: (state, { payload }) => {
            state.groupMembersAll = payload?.data;
        },

        //UpdateGroupPermissions
        [UpdateGroupPermissions.pending]: (state, action) => {
            state.permissionsBtnLoading = true;
        },
        [UpdateGroupPermissions.fulfilled]: (state, { payload }) => {
            state.permissionsBtnLoading = false;

            if (payload?.data?.length > 0) state.groupPermissions = payload?.data[0];
            toast.success('Successfully Updated');
        },
        [UpdateGroupPermissions.rejected]: (state, action) => {
            state.permissionsBtnLoading = false;
            toast.error('Failed');
        },

        //UpdateUserPermissions
        [UpdateUserPermissions.pending]: (state, action) => {
            state.permissionsBtnLoading = true;
        },
        [UpdateUserPermissions.fulfilled]: (state, { payload }) => {
            state.permissionsBtnLoading = false;

            if (payload?.data?.length > 0) state.selectedUserData = payload?.data[0];
            toast.success('Successfully Updated');
        },
        [UpdateUserPermissions.rejected]: (state, action) => {
            state.permissionsBtnLoading = false;
            toast.error('Failed');
        },
        //PatchGroupMember
        [PatchGroupMember.pending]: (state, action) => {
            state.permissionsBtnLoading = true;
        },
        [PatchGroupMember.fulfilled]: (state, { payload }) => {
            state.permissionsBtnLoading = false;
            state.groupMembersAll = state.groupMembersAll.map(item => {
                if (item?.id === payload?.data?.id) return payload.data;
                else return item;
            });
            // if (payload?.data?.length > 0) state.selectedUserData = payload?.data[0];
            toast.success('Successfully Updated');
        },
        [PatchGroupMember.rejected]: (state, action) => {
            state.permissionsBtnLoading = false;
            toast.error('Failed');
        },
        //ResendInvite
        [ResendInvite.pending]: (state, action) => {
            // state.permissionsBtnLoading = true;
        },
        [ResendInvite.fulfilled]: (state, { meta }) => {
            state.projectUsersAll = state.projectUsersAll.map(item => {
                if (item?.value?.invite_id === meta?.arg?.id) {
                    return { ...item, value: { ...item.value, expired: false } };
                } else return item;
            });

            toast.success('Successfully Updated');
        },
        [ResendInvite.rejected]: (state, action) => {
            // state.permissionsBtnLoading = false;
            toast.error('Failed');
        },
        //UpdateGroupMember
        [UpdateGroupMember.pending]: (state, action) => {
            state.teamFormBtnLoading = true;
        },
        [UpdateGroupMember.fulfilled]: (state, { payload }) => {
            let groupData = state.groups?.find(group => group?.id === payload?.data?.group_type);
            let groupName = groupData?.id + ': ' + groupData?.name;
            state.projectUsersAll = state.projectUsersAll?.map(item => {
                if (item?.value['user_id'] === payload?.data?.user) {
                    return { ...item, group: groupName };
                } else return item;
            });
            state.teamFormBtnLoading = false;
            // if (payload?.data?.length > 0) state.groupPermissions = payload?.data[0];
            toast.success('Successfully Updated');
        },
        [UpdateGroupMember.rejected]: (state, action) => {
            state.teamFormBtnLoading = false;
            toast.error('Failed');
        },
        //GetCompanyValues
        [GetCompanyValues.fulfilled]: (state, { payload }) => {
            state.companyValues = payload?.data;
        },
        //PostCompanyValues

        [PostCompanyValues.fulfilled]: (state, { payload }) => {
            console.log(payload?.data);
            state.companyValues = payload?.data;
            toast.success('Successfully Updated');
        },
        [PostCompanyValues.rejected]: (state, action) => {
            toast.error('Failed');
        },
        //PatchCompanyValues
        [PatchCompanyValues.fulfilled]: (state, { payload }) => {
            state.companyValues = state.companyValues?.map(item => {
                if (item?.id === payload?.data?.id) {
                    return payload?.data;
                } else return item;
            });
            // toast.success('Successfully Updated');
        },
        [PatchCompanyValues.rejected]: (state, action) => {
            toast.error('Failed');
        },
        //PostTeamMemberAnalysis
        [PostTeamMemberAnalysis.fulfilled]: (state, { payload }) => {
            state.teamAnalysisMembers.push({ ...payload?.data, score: calculateTeamScore(payload?.data) });
            toast.success('Successfully Added');
        },
        [PostTeamMemberAnalysis.rejected]: (state, action) => {
            toast.error('Failed');
        },
        //GetTeamMemberAnalysis
        [GetTeamMemberAnalysis.fulfilled]: (state, { payload }) => {
            function calculateScore(teamMember) {
                let companyValuesScore = 0;
                for (const value of teamMember?.team_members_values) {
                    if (value.value_score === 'Yes') {
                        companyValuesScore += 3;
                    } else if (value.value_score === 'No') {
                        companyValuesScore -= 3;
                    }
                }
                let roleBasedScore = 0;
                if (teamMember?.grasp_alignment === 'Yes') {
                    roleBasedScore += 2;
                }
                if (teamMember?.desire_alignment === 'Yes') {
                    roleBasedScore += 2;
                }
                if (teamMember?.competency_alignment === 'Yes') {
                    roleBasedScore += 2;
                }
                const totalScore = companyValuesScore + roleBasedScore;
                let scoreRangeWord = '';
                if (totalScore >= -15 && totalScore <= 0) {
                    scoreRangeWord = 'Developmental';
                } else if (totalScore >= 1 && totalScore <= 7) {
                    scoreRangeWord = 'Adequate';
                } else if (totalScore >= 8 && totalScore <= 15) {
                    scoreRangeWord = 'Exemplary';
                }
                return scoreRangeWord;
            }
            state.teamAnalysisMembers = payload?.data?.map(item => {
                return {
                    ...item,
                    score: calculateScore(item),
                };
            });
        },
        //DeleteTeamMemberAnalysis
        [DeleteTeamMemberAnalysis.fulfilled]: (state, action) => {
            state.teamAnalysisMembers = state.teamAnalysisMembers?.filter(item => item?.id !== action?.meta?.arg);
            toast.success('Deleted');
        },
        [DeleteTeamMemberAnalysis.rejected]: (state, action) => {
            toast.error('Failed');
        },
        //PatchTeamMemberAnalysis
        [PatchTeamMemberAnalysis.fulfilled]: (state, { payload }) => {
            state.teamAnalysisMembers = state.teamAnalysisMembers?.map(item => {
                if (item?.id === payload?.data?.id) return { ...payload?.data, score: calculateTeamScore(payload?.data) };
                else return item;
            });
            toast.success('Success');
        },
        [PatchTeamMemberAnalysis.rejected]: (state, action) => {
            toast.error('Failed');
        },
    },
});
export const { clearSelectedUserData, setJoyRideStepIndex, resetTeamData } = teamSlice.actions;
export default teamSlice.reducer;
