import React, { useEffect } from 'react';
import styles from './css/index.module.css';
import WebViewer from '@pdftron/webviewer';
import { ChevronRight20Regular, MoreVertical16Regular, Delete16Regular, Info16Regular, Dismiss20Regular, ArrowDownload16Regular } from '@fluentui/react-icons';
import { Menu, MenuDivider, MenuItem, MenuList, MenuPopover, MenuTrigger, OverlayDrawer } from '@fluentui/react-components';
import { DeleteBulkFiles, GetIRLDocData, GetPopulateDownloadBy } from '../../redux/documentsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { getYouTubeId, isAudioFile } from '../../utils/commonUtils';
import DoubleConfirmationModal from '../CommonComponents/DoubleConfirmationModal/DoubleConfirmationModal';
import FileInfoSidebar from './FileInfoSidebar';
import { QuickAddMenu } from '../CommonComponents/Header';
import { NotepadSidebar } from '../CommonComponents/HeaderSidebars';
import { TaskFormSidebar } from '../TasksInterface';
import { PostInformationRequestSidebar } from '../IRL';
import { ObservationFormSidebar } from '../Observations';

const FileViewerModal = ({ openModal, setOpenModal, selectedFile, setSelectedFile, setSelectedFileIndex, folderFiles, selectedFileIndex, workstream }) => {
    const { selectedProject } = useSelector(state => state?.projectData);
    const { user } = useSelector(state => state.user);
    const { projectUsersAll } = useSelector(state => state.team);
    const [isVideo, setIsVideo] = React.useState(false);
    const [isAudio, setIsAudio] = React.useState(false);
    const [pdfLoading, setPdfLoading] = React.useState(false);
    const [fileUrl, setFileUrl] = React.useState(null);
    const [openFileInfo, setOpenFileInfo] = React.useState(false);
    const viewer = React.useRef(null);
    const instanceRef = React.useRef(null);
    const deleteModalRef = React.useRef();
    const dispatch = useDispatch();
    const isProjectAdmin = selectedProject?.vdr?.vdr_admin === user?.id;
    const filePath = selectedFile?.filePath?.split('/');
    const [notepadModal, setNotepadModal] = React.useState(false);
    const [questionModal, setQuestionModal] = React.useState(false);
    const [obsModal, setObsModal] = React.useState(false);
    const [taskModal, setTaskModal] = React.useState(false);
    const handleClose = () => {
        setOpenModal(false);
        setSelectedFile();
        setSelectedFileIndex && setSelectedFileIndex();
        setFileUrl(null);
        instanceRef.current = null;
        setOpenFileInfo(false);
    };
    const handleNavigateFiles = direction => {
        if (direction === 'prev') {
            let prevData = folderFiles[selectedFileIndex - 1];
            setSelectedFileIndex(selectedFileIndex - 1);
            setSelectedFile({ ...prevData, filePath: selectedFile?.filePath });
        } else {
            setSelectedFileIndex(selectedFileIndex + 1);
            let nextData = folderFiles[selectedFileIndex + 1];
            setSelectedFile({ ...nextData, filePath: selectedFile?.filePath });
        }
    };
    const handleCheckVideo = row => {
        if (row?.document_link?.includes('youtube') || row?.document_link?.includes('youtu.be')) {
            workstream?.id && handleReadDoc();
            return true;
        }
        let ext = row?.document_name?.split('.').pop();
        // eslint-disable-next-line default-case
        switch (ext?.toLowerCase()) {
            case 'm4v':
            case 'avi':
            case 'mpg':
            case 'mov':
            case 'mp5':
            case 'mp4':
                workstream?.id && handleReadDoc();
                return true;
        }
        return false;
    };

    const handleReadDoc = () => {
        const id = projectUsersAll?.find(item => item?.user_id === user?.id)?.value?.group_member_id;
        const isAlreadyPresent = selectedFile?.read_by?.find(item => item === id);
        if (!isAlreadyPresent) {
            dispatch(GetIRLDocData({ id: selectedFile?.id, workstream: workstream?.id }));
        }
    };
    const handleDownloadFile = () => {
        console.log({ instanceRef, selectedFile });
        if (selectedFile?.can_download_original || isProjectAdmin) {
            instanceRef?.current?.UI?.downloadPdf();
            dispatch(GetPopulateDownloadBy({ id: selectedFile?.id, wsId: workstream?.id }));
        }
        if (selectedFile?.can_download_watermarked) {
            handleDownloadWatermarkedFile();
            dispatch(GetPopulateDownloadBy({ id: selectedFile?.id, wsId: workstream?.id }));
        }
    };
    const handleDownloadWatermarkedFile = () => {
        const { Core } = instanceRef.current;
        console.log(Core.documentViewer);
        Core.documentViewer.setWatermark({
            diagonal: {
                fontSize: 40, // or even smaller size
                fontFamily: 'sans-serif',
                color: '#003149',
                opacity: 50, // from 0 to 100
                text: 'www.lens-dd.ai',
            },
        });
        instanceRef?.current?.UI?.downloadPdf();
    };
    const handleDeleteFile = async () => {
        await dispatch(DeleteBulkFiles({ items: [selectedFile?.id], ws_id: workstream?.id }));
        handleClose();
    };
    useEffect(() => {
        if (fileUrl) {
            if (!instanceRef?.current) {
                WebViewer(
                    {
                        webviewerServerURL: process.env.REACT_APP_PDF_WEBVIEWER_URL,
                        licenseKey: process.env.REACT_APP_PDF_EXPRESS_KEY,
                        path: '/webviewer/lib',
                        initialDoc: fileUrl,
                        extension: selectedFile?.document ? selectedFile?.document?.split('.').pop() : selectedFile?.document_link?.split('.').pop(),
                    },
                    viewer.current,
                )
                    .then(async instance => {
                        const { Core } = instance;
                        instanceRef.current = instance;
                        setPdfLoading(false);
                        instance.UI.disableElements(['toolsHeader']);
                        // instance.UI.disableElements(['toolbarGroup-Annotate']);
                        instance.UI.disableElements(['ribbons']);
                        instance.UI.disableElements(['toggleNotesButton']);
                        instance.UI.disableElements(['menuButton']);
                        Core.documentViewer.addEventListener('documentLoaded', () => {
                            setPdfLoading(false);
                            workstream && handleReadDoc();
                        });
                    })
                    .catch(err => {
                        setPdfLoading(false);
                    });
            } else {
                instanceRef.current?.UI?.loadDocument(fileUrl);
            }
        }
    }, [fileUrl]);

    useEffect(() => {
        if (selectedFile) {
            const efectFunction = async () => {
                setPdfLoading(true);
                setIsVideo(handleCheckVideo(selectedFile));
                if (selectedFile?.document_link?.includes('youtube')) {
                    setFileUrl(selectedFile?.document_link);
                } else {
                    if (selectedFile?.document_link) setFileUrl(selectedFile?.document_link);
                    else {
                        if (isAudioFile(selectedFile?.document)) setIsAudio(true);
                        setFileUrl(selectedFile?.document);
                    }
                }
            };
            efectFunction();
        }
    }, [selectedFile]);

    return (
        <>
            <DoubleConfirmationModal isMenuChild={true} handleAction={handleDeleteFile} message={'Deleting a Folder will also delete all files in the folder'}>
                <small ref={deleteModalRef}></small>
            </DoubleConfirmationModal>
            <OverlayDrawer size="full" position="bottom" open={openModal} onOpenChange={(_, state) => setOpenModal(state.open)}>
                {openModal && (
                    <>
                        <div className={styles.FVHeader}>
                            <div className="d-flex align-items-center">
                                {filePath?.length > 0 && (
                                    <p className={styles.FVfilePath}>
                                        {selectedFile?.document_name}
                                        <Menu positioning="below-start">
                                            <MenuTrigger disableButtonEnhancement>
                                                <MoreVertical16Regular className="ms-2" color="white" />
                                            </MenuTrigger>
                                            <MenuPopover>
                                                <MenuList>
                                                    <MenuItem onClick={() => setOpenFileInfo(true)} icon={<Info16Regular />}>
                                                        Get Info
                                                    </MenuItem>

                                                    {(isProjectAdmin || selectedFile?.can_download_original || selectedFile?.can_download_watermarked) && (
                                                        <>
                                                            <MenuDivider />
                                                            <MenuItem onClick={handleDownloadFile} icon={<ArrowDownload16Regular />}>
                                                                Download File
                                                            </MenuItem>
                                                        </>
                                                    )}

                                                    <MenuDivider />
                                                    <MenuItem onClick={() => deleteModalRef?.current?.click()} icon={<Delete16Regular />}>
                                                        Delete File
                                                    </MenuItem>
                                                </MenuList>
                                            </MenuPopover>
                                        </Menu>
                                    </p>
                                )}
                            </div>

                            <div className="d-flex align-items-center">
                                {folderFiles && (
                                    <div>
                                        <button disabled={selectedFileIndex === 0} onClick={() => handleNavigateFiles('prev')} className={styles.FVdirectionalBtn + ' ms-0'}>
                                            Previous
                                        </button>
                                        <span className={styles.FVfileCount}>
                                            {selectedFileIndex + 1} / {folderFiles?.length}
                                        </span>
                                        <button
                                            onClick={() => handleNavigateFiles('next')}
                                            disabled={selectedFileIndex === folderFiles?.length - 1}
                                            className={styles.FVdirectionalBtn}>
                                            Next
                                        </button>
                                    </div>
                                )}
                                {user?.has_diligence_tools && (
                                    <div className="me-4">
                                        <QuickAddMenu setObsModal={setObsModal} setQuestionModal={setQuestionModal} setTaskModal={setTaskModal} setNotepadModal={setNotepadModal} />
                                    </div>
                                )}

                                <Dismiss20Regular role="button" color="white" onClick={handleClose} />
                            </div>
                        </div>
                        <div className="row w-100 p-0 m-0">
                            <div className={`col-sm-12 col-md-${openFileInfo ? 9 : 12} p-0`}>
                                <div className={styles.docContainer}>
                                    {/* getYouTubeId */}
                                    {isVideo && (
                                        <div>
                                            {fileUrl?.includes('youtube') || fileUrl?.includes('youtu.be') ? (
                                                <iframe
                                                    width="100%"
                                                    height="70vh"
                                                    src={`https://www.youtube.com/embed/${getYouTubeId(fileUrl)}`}
                                                    frameborder="0"
                                                    title="youtube-vid"
                                                    allowfullscreen></iframe>
                                            ) : (
                                                <video
                                                    src={fileUrl}
                                                    controls
                                                    style={{
                                                        height: '70vh',
                                                        width: '100%',
                                                    }}
                                                />
                                            )}
                                        </div>
                                    )}
                                    {isAudio && fileUrl && (
                                        <div
                                            className="d-flex align-items-center justify-content-center"
                                            style={{
                                                height: '70vh',
                                                width: '100%',
                                            }}>
                                            <audio controls controlsList="nodownload">
                                                <source src={fileUrl} />
                                                Your browser does not support the audio element.
                                            </audio>
                                        </div>
                                    )}
                                    <div style={{ visibility: (isVideo || isAudio) && 'hidden' }} className={'webviewer'} ref={viewer}>
                                        {pdfLoading && (
                                            <div className={styles.loaderBody}>
                                                <AiOutlineLoading3Quarters size={50} className="rotateLoader" />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {openFileInfo && (
                                <div className="col-sm-12 col-md-3 p-0">
                                    <FileInfoSidebar setFileInfoSidebar={setOpenFileInfo} setShowSidebar={() => {}} selectedFile={selectedFile} setSelectedFile={() => {}} />
                                </div>
                            )}
                        </div>
                    </>
                )}
            </OverlayDrawer>
            <div style={{ zIndex: 1000000000000 }}>
                <NotepadSidebar show={notepadModal} setShow={setNotepadModal} />
                <ObservationFormSidebar show={obsModal} setShow={setObsModal} />
                <PostInformationRequestSidebar show={questionModal} setShow={setQuestionModal} />
                <TaskFormSidebar show={taskModal} setShow={setTaskModal} />
            </div>
        </>
    );
};
export default FileViewerModal;
