import React from 'react';
import Pageheader from '../../utils/headerHelment';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router';
import axios from 'axios';
import CountryCode from './CountryCode.json';
import QRCode from 'react-qr-code';
import { API_BASE_URL, AUTH_CODE_CONFIRMATION, AUTH_LOGIN_CODE, AUTH_QR_CODE, AUTH_SMS } from '../../apiServices/api_routes';
import styles from './css/SecureAccount.module.css';
import Logo from '../../assets/longLogoBright.png';
import { SpinnerIos16Regular } from '@fluentui/react-icons';
import AuthService from '../../apiServices/tokenservices/auth.service';
import { GetUserData, setAccessToken } from '../../redux/usersSlice';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
const SecureAccount = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [formData, setFormData] = React.useState({
        code: '',
        number: '',
    });
    const [showNumberAuth, setShowNumberAuth] = React.useState(false);

    const dispatch = useDispatch();
    let promiseToast = null;

    console.log(location);
    // React.useEffect(() => {
    //     const effectAsync = async () => {
    //         if (verificationOption !== 'sms') {
    //             // location?.state?.email
    //             const config = {
    //                 headers: {
    //                     Authorization: `Bearer ${location?.state?.access}`,
    //                 },
    //             };
    //             try {
    //                 const resp = await axios.post(`${API_BASE_URL}${AUTH_QR_CODE}`, { email: location?.state?.email }, config);
    //                 setQrData(resp?.data?.details);
    //             } catch (error) {}
    //         }
    //     };
    //     effectAsync();
    // }, [verificationOption]);

    // const handleSubmit = async () => {
    //     if (verificationOption === 'sms') {
    //         const config = {
    //             headers: {
    //                 Authorization: `Bearer ${location?.state?.access}`,
    //             },
    //         };
    //         try {
    //             const resp = await axios.post(`${API_BASE_URL}${AUTH_SMS}`, { phone_number: formData?.code + formData?.number }, config);
    //             if (resp?.data?.details) {
    //                 toast.success(resp?.data?.details);
    //                 navigate('/authenticate', {
    //                     state: {
    //                         type: 'sms',
    //                         access: location?.state?.access,
    //                         email: location?.state?.email,
    //                         password: location?.state?.password,
    //                     },
    //                 });
    //             }
    //         } catch (error) {
    //             const res = error?.response?.data;
    //             toast.error(res?.details);
    //         }
    //         //
    //     } else {
    //         navigate('/authenticate', {
    //             state: {
    //                 type: 'autheticator',
    //                 access: location?.state?.access,
    //                 email: location?.state?.email,
    //                 password: location?.state?.password,
    //             },
    //         });
    //     }
    // };

    return (
        <div className={styles.body}>
            <div className={styles.dotedBg}>
                <div className={styles.formCard}>
                    <div className={styles.formHeader}>
                        <img src={Logo} width={73} alt="lens-logo" />
                    </div>
                    <div className={styles.formBody}>
                        <h5 className={styles.formLabel}>Set up 2FA</h5>
                        <p className={styles.formSubtitle}>
                            For heightened security measures, add your mobile number below to receive verification code. We will use this method by default. Your mobile number will
                            only be used for account security. Standard SMS charges will apply.
                        </p>
                        <div className="d-flex">
                            <div style={{ width: '120px' }} className="me-2">
                                <div>
                                    <p className={styles.fieldLabel}>Code</p>
                                    {/* <input value={formData?.email} name="email" onChange={handleChange} type="email" className={'form-control ' + styles.inputField} /> */}
                                    <select
                                        name="swot"
                                        value={formData?.code}
                                        onChange={e =>
                                            setFormData({
                                                ...formData,
                                                code: e.target.value,
                                            })
                                        }
                                        className={'form-select ' + styles.inputField}
                                        aria-label=".form-select-sm example">
                                        <option value="">Please Select</option>
                                        {CountryCode?.map(item => (
                                            <option value={item?.dial_code}>{item?.dial_code}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="w-100">
                                <div>
                                    <p className={styles.fieldLabel}>Mobile Number</p>
                                    <input
                                        type="number"
                                        placeholder="Mobile Number"
                                        value={formData?.number}
                                        onChange={e =>
                                            setFormData({
                                                ...formData,
                                                number: e.target.value,
                                            })
                                        }
                                        className={'form-control ' + styles.inputField}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="d-flex align-items-center justify-content-between">
                            <button className={styles.submitBtn}>Activate account</button>
                            <span role="button" onClick={() => setShowNumberAuth(false)} className={styles.secondaryBtnLink}>
                                Back
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.footer}>
                <a href="https://www.iubenda.com/terms-and-conditions/15531073" target="_blank" rel="noreferrer">
                    Terms of Use
                </a>
                <a href="https://www.iubenda.com/privacy-policy/15531073/legal?ifr=true&height=650&newmarkup=yes&an=no" target="_blank" rel="noreferrer">
                    Privacy & Cookies
                </a>
            </div>
        </div>
    );
};

export default SecureAccount;
// return (
//     <div className=" full-height-cls  bg-light">
//         <Pageheader title="LENS - Secure Account" />
//         <header className="header ">
//             <div className={styles.navbarbrand}>
//                 <Link to="/">
//                     <img src={Logo} alt="logo" width="100px" height="54px" />
//                 </Link>
//             </div>
//         </header>
//         <div className="container">
//             <div className={`row ${styles.viewHeight}`}>
//                 <div className="col-sm-12 col-md-5">
//                     <div className={`${styles.formBody} `}>
//                         <div className={styles.rightContent}>
//                             <h2 className={styles.headingText}>Secure your Account & Data</h2>
//                             <p className={styles.emailText}>
//                                 When you sign in with your password, you are also required to respond from a registered device. This adds an additional security layer to your
//                                 account and associated data.
//                             </p>
//                             <h3 className={styles.subHeadingText}>What is your preferred option?</h3>
//                             <div>
//                                 <label className="form-check-label mb-1 me-4" htmlFor="flexRadioDefault1">
//                                     We will use this verification option by default
//                                 </label>
//                                 <div className="">
//                                     <select
//                                         name="swot"
//                                         value={verificationOption}
//                                         onChange={e => setVerificationOption(e.target.value)}
//                                         className="form-select "
//                                         aria-label=".form-select-sm example">
//                                         <option value="">Please Select</option>
//                                         <option value="sms">Send me the code via SMS</option>
//                                         <option value="authenticator">Send me the code via Authenticator app</option>
//                                     </select>
//                                 </div>
//                             </div>
//                             {verificationOption === 'sms' && (
//                                 <div className="mt-3">
//                                     <label className="form-check-label me-4 mb-1" htmlFor="flexRadioDefault1">
//                                         Please enter your mobile number
//                                     </label>
//                                     <div className="row">
//                                         <div className="col-sm-4">
//                                             <select
//                                                 name="swot"
//                                                 value={formData?.code}
//                                                 onChange={e =>
//                                                     setFormData({
//                                                         ...formData,
//                                                         code: e.target.value,
//                                                     })
//                                                 }
//                                                 className="form-select "
//                                                 aria-label=".form-select-sm example">
//                                                 <option value="">Please Select</option>
//                                                 {CountryCode?.map(item => (
//                                                     <option value={item?.dial_code}>
//                                                         {item?.name}({item?.dial_code}){' '}
//                                                     </option>
//                                                 ))}
//                                             </select>
//                                         </div>
//                                         <div className="col-sm-8">
//                                             <input
//                                                 type="number"
//                                                 className="form-control commonInput"
//                                                 placeholder="Mobile Number"
//                                                 value={formData?.number}
//                                                 onChange={e =>
//                                                     setFormData({
//                                                         ...formData,
//                                                         number: e.target.value,
//                                                     })
//                                                 }
//                                             />
//                                         </div>
//                                     </div>
//                                     <p className={styles.infoText}>
//                                         Your phone number will only be used for account security. <br /> Standard SMS charges will apply.
//                                     </p>
//                                     <div className="d-flex mt-4">
//                                         <button onClick={handleSubmit} style={{ width: '120px' }} className="btn btn-primary">
//                                             Next
//                                         </button>
//                                     </div>
//                                 </div>
//                             )}
//                             {verificationOption === 'authenticator' && (
//                                 <div className="mt-3">
//                                     <p className={styles.infoText}>
//                                         To use this verification method, you must set up either Google or Microsoft Authenticator app on your mobile phone. Please scan the QR
//                                         code on your preferred authenticator mobile application to register your device.
//                                     </p>
//                                     <p>Please scan the QR code on your preferred authenticator mobile application to register your device.</p>
//                                     <div>{qrData && <QRCode size={168} value={qrData} />}</div>
//                                     <div className="d-flex mt-4">
//                                         <button onClick={handleSubmit} style={{ width: '120px' }} className="btn btn-primary">
//                                             Next
//                                         </button>
//                                     </div>
//                                 </div>
//                             )}
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <footer>
//                 <p className={styles.copyright + ' ' + styles.rightContent}>
//                     © Beyond M&amp;A Ltd |{' '}
//                     <a className="privacy-link" href="https://www.iubenda.com/privacy-policy/15531073/legal?ifr=true&height=650&newmarkup=yes&an=no" target="_blank">
//                         Privacy
//                     </a>
//                     {` `} &amp; {` `}
//                     <a className="privacy-link" href="https://www.iubenda.com/terms-and-conditions/15531073" target="_blank">
//                         terms
//                     </a>
//                 </p>
//             </footer>
//         </div>

//         <div className={styles.rightImage}></div>
//     </div>
// );
