import React, { Fragment } from 'react';
import {
    PostDataroomGroup,
    GetAllGroups,
    DeleteGroup,
    PatchDataroomGroup,
    InviteTeamMember,
    GetGroupMemberPermissions,
    clearSelectedUserData,
    UpdateGroupPermissions,
    UpdateGroupMember,
    RevokeUserInvite,
    ResendInvite,
    DeleteGroupMember,
    setJoyRideStepIndex,
} from '../../redux/teamSlice';
import { useDispatch, useSelector } from 'react-redux';
import styles from './css/index.module.css';
import { GetProjectUsers } from '../../redux/teamSlice';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { RiDeleteBin6Line, RiEditBoxLine } from 'react-icons/ri';
import { BsChevronDown, BsFillPlusCircleFill, BsThreeDotsVertical } from 'react-icons/bs';

import { Dropdown, Modal, Offcanvas } from 'react-bootstrap';
import { GetWorkstreams, GetWorkstreamsDefaults, PostWorkstream } from '../../redux/dataroomSlice';
import { ImWarning } from 'react-icons/im';
import { HiOutlineLightBulb, HiOutlineMail, HiOutlineUserCircle } from 'react-icons/hi';
import { TiDeleteOutline } from 'react-icons/ti';
import { useNavigate } from 'react-router';
import { compareArrays, compareObjects, getInitials } from '../../utils/commonUtils';
import DoubleConfirmationModal from '../CommonComponents/DoubleConfirmationModal/DoubleConfirmationModal';
import { updateProject } from '../../redux/projectDataSlice';

const Main = props => {
    const dispatch = useDispatch();
    const { selectedProject } = useSelector(state => state.projectData);

    React.useEffect(() => {
        if (selectedProject) {
            dispatch(GetProjectUsers());
            dispatch(GetAllGroups());
            dispatch(GetWorkstreams());
            dispatch(GetWorkstreamsDefaults());
        }
    }, [selectedProject, dispatch]);
    return <Groups {...props} />;
};
const Groups = ({ setRunJoyRide, showAddGroupModal, setShowAddGroupModal, showAddUserSidebar, setShowAddUserSidebar }) => {
    const dispatch = useDispatch();
    const { selectedProject } = useSelector(state => state.projectData);
    const { groups } = useSelector(state => state.team);
    return (
        <div className={styles.container}>
            <div className="d-flex justify-content-between align-items-center mb-5">
                <div className="d-flex align-items-center mb-2">
                    <h3 className={styles.targetHeading + ' m-0 page-title '}>{selectedProject?.project_name} Team </h3>
                    <div
                        className={styles.tourText + ' d-flex align-items-center ms-4 view-tour'}
                        onClick={() => {
                            dispatch(setJoyRideStepIndex(0));
                            setRunJoyRide(true);
                        }}>
                        <HiOutlineLightBulb size={15} />
                        &nbsp; View Tour
                    </div>
                </div>
                <button
                    onClick={() => {
                        setShowAddGroupModal(true);
                        setTimeout(() => {
                            dispatch(setJoyRideStepIndex(2));
                        }, 300);
                    }}
                    className={'btn btn-primary add-group-btn  btn-sm ' + styles.smallButton}>
                    Add Group
                </button>
            </div>
            {groups?.length === 0 ? (
                <div
                    className={styles.addGroupBox + ' add-group-box'}
                    onClick={() => {
                        setShowAddGroupModal(true);
                        setTimeout(() => {
                            dispatch(setJoyRideStepIndex(2));
                        }, 300);
                    }}>
                    <BsFillPlusCircleFill size={30} color="var(--Brand-Secondary)" />
                    <h5 className="mt-2">Add New Group</h5>
                </div>
            ) : (
                <GroupListing showAddUserSidebar={showAddUserSidebar} setShowAddUserSidebar={setShowAddUserSidebar} />
            )}
            <AddGroupSidebar show={showAddGroupModal} setShow={setShowAddGroupModal} />
        </div>
    );
};
const GroupListing = ({ showAddUserSidebar, setShowAddUserSidebar }) => {
    const { projectUsersAll, groups } = useSelector(state => state.team);
    const { selectedProject } = useSelector(state => state.projectData);
    const userData = useSelector(state => state.user);
    const [selectedGroup, setSelectedGroup] = React.useState();

    const [showEditUserSidebar, setShowEditUserSidebar] = React.useState(false);
    const [selectedUser, setSelectedUser] = React.useState();
    const [addNdaModal, setAddNdaModal] = React.useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleResendInvite = (user, group) => {
        dispatch(
            ResendInvite({
                email: [user?.value?.email],
                group_type: user?.group?.split(':')[0]?.split(' ')[1],
                project: localStorage['project_id'],
                id: user?.value?.invite_id,
                vdr: selectedProject?.vdr?.id,
                acls: group?.acl,
                vdrinvitation_permission: {
                    perm_view: group?.permissions?.view,
                    perm_add: group?.permissions?.add,
                    perm_delete: group?.permissions?.delete,
                    perm_downWTR: group?.permissions?.download_watermarked,
                    perm_downOG: group?.permissions?.download_original,
                    invitation: user?.value?.invite_id,
                },
            }),
        );
    };
    const handleOpenAddUser = ({ item }) => {
        // if (selectedProject?.vdr?.nda) {
        setSelectedGroup(item);
        setShowAddUserSidebar(true);
        // } else setAddNdaModal(true);
    };
    const handleIsInvitedUser = data => {
        if (data?.invitation && !data?.expired) {
            return true;
        } else if (data?.group_member_id) {
            if (selectedProject?.vdr?.nda?.signees?.length === 0) return true;
            else {
                let user = selectedProject?.vdr?.nda?.signees?.find(item => item?.id === data?.group_member_id);
                if (user?.nda_signed === false || !user) return true;
                else return false;
            }
        } else return false;
    };
    const handleRemoveUserFromProject = async id => {
        await dispatch(DeleteGroupMember(id));
        console.log({
            ...selectedProject,
            vdr: {
                ...selectedProject?.vdr,
                nda: {
                    ...selectedProject?.vdr?.nda,
                    signees: selectedProject?.vdr?.nda?.signees?.filter(item => item?.id !== id),
                },
            },
        });
        dispatch(
            updateProject({
                ...selectedProject,
                vdr: {
                    ...selectedProject?.vdr,
                    nda: {
                        ...selectedProject?.vdr?.nda,
                        signees: selectedProject?.vdr?.nda?.signees?.filter(item => item?.id !== id),
                    },
                },
            }),
        );
    };
    return (
        <div className={styles.container}>
            <div className="overflow-auto">
                <div className={styles.groupsContainer}>
                    {groups?.map(item => (
                        <div className={styles.groupBody}>
                            <div className={styles.topCard}>
                                <span>{item?.name}</span>
                                {item?.name !== 'Admins' && item?.name !== 'Administrator' && (
                                    <div className="d-flex align-items-center">
                                        <svg
                                            role="button"
                                            width="32"
                                            height="32"
                                            viewBox="0 0 36 36"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="me-2 permission-icon-step"
                                            onClick={() => navigate('/permissions/group/' + item?.id)}>
                                            <g fill="var(--Brand-Secondary)" fillRule="nonzero">
                                                <path d="M18 17.25c1.223 0 2.23.932 2.25 2.12v.88a.75.75 0 0 1-1.5.015v-.859c0-.354-.322-.656-.75-.656-.422 0-.742.295-.75.643v.857a.75.75 0 0 1-1.5.015v-.859c0-1.205 1.015-2.156 2.25-2.156z" />
                                                <path d="M20.25 25.5h-4.5a1.5 1.5 0 0 1-1.5-1.5v-2.25a1.5 1.5 0 0 1 1.5-1.5h4.5a1.5 1.5 0 0 1 1.5 1.5V24a1.5 1.5 0 0 1-1.5 1.5z" />
                                                <path d="M17.379 7.5H12.75a3 3 0 0 0-3 3v15a3 3 0 0 0 3 3h10.5a3 3 0 0 0 3-3v-9.129a2.25 2.25 0 0 0-.659-1.59L18.97 8.159a2.25 2.25 0 0 0-1.591-.659zM12.75 9h4.629a.75.75 0 0 1 .53.22l6.621 6.621c.141.14.22.331.22.53V25.5a1.5 1.5 0 0 1-1.5 1.5h-10.5a1.5 1.5 0 0 1-1.5-1.5v-15a1.5 1.5 0 0 1 1.5-1.5z" />
                                                <path d="M18 7.635a.75.75 0 0 1 .75.735v5.88c0 .41.328.742.735.75h5.88a.75.75 0 0 1 .015 1.5H19.5a2.25 2.25 0 0 1-2.25-2.213V8.385a.75.75 0 0 1 .75-.75z" />
                                            </g>
                                        </svg>
                                        <EditGroupSidebar data={item} />
                                        <BsFillPlusCircleFill
                                            onClick={() => {
                                                setSelectedGroup(item);
                                                setShowAddUserSidebar(true);
                                            }}
                                            color="var(--Brand-Secondary)"
                                            role={'button'}
                                            size={17}
                                        />
                                    </div>
                                )}
                            </div>
                            <div>
                                {projectUsersAll
                                    ?.filter(user => user?.group.includes(item?.id))
                                    .map(user => (
                                        <div className={styles.contentCard}>
                                            <div className="d-flex ">
                                                {user?.value?.invitation ? (
                                                    <HiOutlineUserCircle size={29} />
                                                ) : (
                                                    <div className={styles.avatar}>{getInitials(user?.value?.full_name?.toUpperCase())}</div>
                                                )}

                                                <div className="ms-3">
                                                    <p className={styles.contentCardTitle}>{user?.value?.full_name || user?.value?.email} </p>
                                                    <div className="m-0">
                                                        {user?.value?.user_id === userData?.user?.id ? (
                                                            <>
                                                                {' '}
                                                                <span className={styles.contentCardSub}>Yourself</span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {handleIsInvitedUser(user?.value) && <span className={styles.invitedText}>Invited</span>}
                                                                {user?.value?.expired && <span className={styles.invitedText}>Invitation Expired</span>}
                                                                {!handleIsInvitedUser(user?.value) && user?.value?.['job_title'] && (
                                                                    <span className={styles.contentCardSub}>{user?.value?.['job_title']} </span>
                                                                )}
                                                                {!handleIsInvitedUser(user?.value) && user?.value?.organisation && (
                                                                    <p className={styles.contentCardSub + ' mb-0'}>{user?.value?.organisation}</p>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            {item?.name !== 'Admins' && item?.name !== 'Administrator' && (
                                                <div className={styles?.dropdown}>
                                                    <BsThreeDotsVertical color="var(--muted)" />
                                                    <div className={styles.hoverMenuContent}>
                                                        <div
                                                            style={{
                                                                width: '224px',
                                                                top: 20,
                                                            }}
                                                            className={styles.hoverMenuCont}>
                                                            {user?.value?.full_name && !user?.value?.invite_id && (
                                                                <>
                                                                    {!handleIsInvitedUser(user?.value) && (
                                                                        <>
                                                                            <div
                                                                                onClick={() => {
                                                                                    setSelectedGroup(item);
                                                                                    setSelectedUser(user?.value);
                                                                                    setShowEditUserSidebar(true);
                                                                                }}
                                                                                className={styles.wsChipMenuItem}
                                                                                style={{ color: 'var(--primary)' }}>
                                                                                <RiEditBoxLine size={15} className="me-2" />
                                                                                <span> Change Group</span>
                                                                            </div>

                                                                            <hr className="mt-1 mb-1" />
                                                                            <div
                                                                                onClick={() => navigate('/permissions/user/' + user?.value['group_member_id'])}
                                                                                className={styles.wsChipMenuItem}
                                                                                style={{ color: 'var(--primary)' }}>
                                                                                <svg width="24" height="24" viewBox="5 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                                    <g fill="#003149" fillRule="nonzero">
                                                                                        <path d="M12 11.5c.815 0 1.486.622 1.5 1.413V13.5a.5.5 0 0 1-1 .01V12.938c0-.237-.215-.438-.5-.438-.282 0-.495.196-.5.429V13.5a.5.5 0 0 1-1 .01V12.938c0-.804.676-1.438 1.5-1.438z" />
                                                                                        <path d="M13.5 17h-3a1 1 0 0 1-1-1v-1.5a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1V16a1 1 0 0 1-1 1z" />
                                                                                        <path d="M11.586 5H8.5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2v-6.086a1.5 1.5 0 0 0-.44-1.06l-4.414-4.415A1.5 1.5 0 0 0 11.586 5zM8.5 6h3.086a.5.5 0 0 1 .353.146l4.415 4.415a.5.5 0 0 1 .146.353V17a1 1 0 0 1-1 1h-7a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1z" />
                                                                                        <path d="M12 5.09a.5.5 0 0 1 .5.49V9.5a.5.5 0 0 0 .49.5h3.92a.5.5 0 0 1 .01 1H13a1.5 1.5 0 0 1-1.5-1.475V5.59a.5.5 0 0 1 .5-.5z" />
                                                                                    </g>
                                                                                </svg>
                                                                                Add Custom Permissions
                                                                            </div>
                                                                            <hr className="mt-1 mb-1" />
                                                                        </>
                                                                    )}
                                                                    <div className={styles.wsChipMenuItem} style={{ color: 'var(--primary)' }}>
                                                                        <HiOutlineMail size={15} className="me-2" />
                                                                        <a href={'mailto:' + user?.value?.email}>Email User</a>
                                                                    </div>
                                                                    <hr className="mt-1 mb-1" />

                                                                    <div
                                                                        onClick={() => handleRemoveUserFromProject(user?.value?.group_member_id)}
                                                                        className={styles.wsChipMenuItem}
                                                                        style={{ color: 'var(--Brand-Secondary)' }}
                                                                        size={15}>
                                                                        <RiDeleteBin6Line className="me-2" />
                                                                        {!handleIsInvitedUser(user?.value) ? ' Remove user from project' : '  Revoke Invitation'}
                                                                    </div>
                                                                </>
                                                            )}

                                                            {user?.value?.expired && (
                                                                <>
                                                                    <div
                                                                        onClick={() => handleResendInvite(user, item)}
                                                                        className={styles.wsChipMenuItem}
                                                                        style={{ color: 'var(--primary)' }}
                                                                        size={15}>
                                                                        <HiOutlineMail size={15} className="me-2" />
                                                                        Resend invitation
                                                                    </div>
                                                                    <hr className="mt-1 mb-1" />
                                                                    <div
                                                                        onClick={() => dispatch(RevokeUserInvite(user?.value?.invite_id))}
                                                                        className={styles.wsChipMenuItem}
                                                                        style={{ color: 'var(--Brand-Secondary)' }}
                                                                        size={15}>
                                                                        <RiDeleteBin6Line className="me-2" />
                                                                        Revoke Invitation
                                                                    </div>
                                                                </>
                                                            )}
                                                            {user?.value?.invitation && !user?.value?.expired && (
                                                                <div
                                                                    onClick={() => dispatch(RevokeUserInvite(user?.value?.invite_id))}
                                                                    className={styles.wsChipMenuItem}
                                                                    style={{ color: 'var(--Brand-Secondary)' }}
                                                                    size={15}>
                                                                    <RiDeleteBin6Line className="me-2" />
                                                                    Revoke Invitation
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                            </div>
                            {item?.name !== 'Admins' && item?.name !== 'Administrator' && (
                                <div className={styles.bottomCard + ' add-user-button'}>
                                    <div
                                        role={'button'}
                                        onClick={() => {
                                            handleOpenAddUser({ item });
                                            setTimeout(() => {
                                                dispatch(setJoyRideStepIndex(4));
                                            }, 300);
                                        }}
                                        className="d-flex align-items-center">
                                        <BsFillPlusCircleFill size={17} className="me-3" /> Add User
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <AddUserSidebar setShow={setShowAddUserSidebar} show={showAddUserSidebar} group={selectedGroup} setSelectedGroup={setSelectedGroup} />
            {selectedUser && (
                <EditUserSidebar
                    setShow={setShowEditUserSidebar}
                    show={showEditUserSidebar}
                    selectedGroup={selectedGroup}
                    setSelectedGroup={setSelectedGroup}
                    selectedUser={selectedUser}
                    setSelectedUser={setSelectedUser}
                />
            )}
            <AddNdaWarningModal modalVisible={addNdaModal} setModalVisible={setAddNdaModal} />
        </div>
    );
};
const AddGroupSidebar = ({ show, setShow }) => {
    const { workstreams } = useSelector(state => state.dataroom);
    const { selectedProject } = useSelector(state => state.projectData);
    const { permissionsBtnLoading } = useSelector(state => state.team);
    const dispatch = useDispatch();
    const [selectedWorkstream, setSelectedWorkstream] = React.useState([]);
    const permissionArray = [
        {
            detail: 'View, add, delete files/folders + download original',
            label: 'Editor',
        },
        {
            detail: 'View, add files/folders + download original',
            label: 'Contributor',
        },
        {
            detail: 'View files + download original',
            label: 'Reviewer Plus',
        },
        {
            detail: 'View files + download watermarked',
            label: 'Reviewer',
        },
        {
            detail: 'View files',
            label: 'Previewer',
        },
        {
            detail: 'Access not assigned',
            label: 'No Access',
        },
    ];

    const [formData, setFormData] = React.useState({
        name: '',
        permissionType: '',
    });
    const handleChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleClose = () => {
        setShow(false);
        setFormData({ name: '', noAccess: false, view: false, add: false, delete: false, download_watermarked: false, download_original: false });
        setSelectedWorkstream([]);
    };
    const getPermissionObject = type => {
        switch (type?.toLowerCase()) {
            case 'editor':
                return {
                    view_perm: true,
                    add_perm: true,
                    delete_perm: true,
                    download_watermarked: false,
                    download_original: true,
                };
            case 'contributor':
                return {
                    view_perm: true,
                    add_perm: true,
                    delete_perm: false,
                    download_watermarked: false,
                    download_original: true,
                };
            case 'reviewer plus':
                return {
                    view_perm: true,
                    add_perm: false,
                    delete_perm: false,
                    download_watermarked: false,
                    download_original: true,
                };
            case 'reviewer':
                return {
                    view_perm: true,
                    add_perm: false,
                    delete_perm: false,
                    download_watermarked: true,
                    download_original: false,
                };
            case 'previewer':
                return {
                    view_perm: true,
                    add_perm: false,
                    delete_perm: false,
                    download_watermarked: false,
                    download_original: false,
                };
            case 'no access':
                return {
                    view_perm: false,
                    add_perm: false,
                    delete_perm: false,
                    download_watermarked: false,
                    download_original: false,
                };
            default:
                return {};
        }
    };
    const handleSubmit = async () => {
        let body = {
            vdr: selectedProject?.vdr?.id,
            name: formData?.name,
            project: localStorage['project_id'],
            permissions: getPermissionObject(formData?.permissionType),
            acls: selectedWorkstream,
        };
        await dispatch(PostDataroomGroup(body));
        dispatch(setJoyRideStepIndex(3));
        handleClose();
    };

    const handleSelectWorkstream = (e, id) => {
        if (e.target.checked) {
            setSelectedWorkstream([...selectedWorkstream, id]);
        } else {
            setSelectedWorkstream([...selectedWorkstream?.filter(wsId => wsId !== id)]);
        }
    };
    const DropdownToggleCol = React.forwardRef(({ children, onClick }, ref) => (
        <div
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
            className={styles.filterSelector + ' ' + styles.workstreamSelect}>
            <div className=" d-flex flex-wrap">{formData?.permissionType || 'Select'}</div>
            &nbsp; &nbsp;
            <BsChevronDown size={10} color="#829ab1" />
        </div>
    ));

    return (
        <>
            <Offcanvas placement="end" show={show} onHide={handleClose}>
                <Offcanvas.Body className="p-4 ">
                    <p className="font-weight-bold mb-3 mt-3">Add New Group</p>
                    <div className="">
                        <div className=" ">
                            <label className={'form-check-label me-4 bold mb-1 ' + styles.labelAddQuestion} htmlFor="flexRadioDefault1">
                                Add Group Name
                            </label>
                            <input placeholder="Type here" type="text" name="name" value={formData?.name} onChange={handleChange} className="form-control commonInput" />
                        </div>
                        <br />
                        <div className="mt-2">
                            <p className="font-weight-bold">Set Permissions</p>
                            <span onClick={() => setSelectedWorkstream([...workstreams?.map(ws => ws?.acl)])} className={styles.orangeLink}>
                                Select All
                            </span>
                            <div className="mt-3">
                                {workstreams?.map(item => (
                                    <div key={item?.id} className={styles.permissionChecBody}>
                                        <input checked={selectedWorkstream?.includes(item?.acl)} onChange={e => handleSelectWorkstream(e, item?.acl)} type="checkbox" />
                                        <label className={styles.labelAddQuestion + ' ms-3'}>{item?.name}</label>
                                    </div>
                                ))}
                            </div>
                            <div className="mt-3">
                                <label className={'form-check-label me-4 bold mb-1 ' + styles.labelAddQuestion} htmlFor="flexRadioDefault1">
                                    Select Permission
                                </label>
                                <Dropdown>
                                    <Dropdown.Toggle as={DropdownToggleCol} id="dropdown-basic"></Dropdown.Toggle>
                                    <Dropdown.Menu className="p-0 w-100">
                                        {permissionArray?.map(item => (
                                            <Dropdown.Item onClick={() => setFormData({ ...formData, permissionType: item?.label })} className="p-0">
                                                <div className={styles.permissionDropdownMenu}>
                                                    <p className={styles.regularText + ' font-weight-bold m-0'}>{item?.label}</p>
                                                    <p className={styles.xSmallFont}>{item?.detail}</p>
                                                </div>
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <p className="mt-5 mb-0">
                                <i className={styles.smallFont}>You can further customise permissions on the Team Page</i>
                            </p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-5">
                            <button
                                style={{ width: 90 }}
                                disabled={!formData?.name || !formData?.permissionType || selectedWorkstream?.length === 0}
                                onClick={handleSubmit}
                                className={'btn btn-primary add-group-addBtn' + ' ' + styles.smallButton}>
                                Add &nbsp;{permissionsBtnLoading && <AiOutlineLoading3Quarters className="rotateLoader" />}
                            </button>

                            <p className={styles.orangeBtnText + ' ' + styles.smallFont} onClick={handleClose}>
                                Cancel
                            </p>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};
const EditGroupSidebar = ({ data }) => {
    const { permissionsBtnLoading } = useSelector(state => state.team);
    const [groupName, setGroupName] = React.useState('');
    const dispatch = useDispatch();
    const [show, setShow] = React.useState(false);
    const handleClose = () => {
        setShow(false);
        setGroupName('');
    };
    const handleSubmit = async () => {
        let body = {
            id: data?.id,
            name: groupName,
        };
        await dispatch(PatchDataroomGroup(body));
        handleClose();
    };
    React.useEffect(() => {
        if (show) {
            setGroupName(data?.name);
        }
    }, [show]);
    return (
        <>
            {' '}
            <RiEditBoxLine onClick={() => setShow(true)} color="var(--Brand-Secondary)" className="me-4" role="button" size={20} />
            <Offcanvas placement="end" show={show} onHide={handleClose}>
                <Offcanvas.Body className="p-4">
                    <div className="d-flex justify-content-between align-items-center  mb-5 mt-3">
                        <h5 className="font-weight-bold m-0">Rename Group</h5>
                        <DoubleConfirmationModal
                            message="Deleting a Group will also delete All Users in the Group."
                            handleAction={async () => {
                                await dispatch(DeleteGroup(data?.id));
                                handleClose();
                            }}>
                            <span className={styles.smallFont + ' ' + styles.orangeBtnText}>Delete Group</span>
                        </DoubleConfirmationModal>
                    </div>
                    <div className="mt-4">
                        <div className="mt-3 ">
                            <label className={'form-check-label me-4 bold mb-1 ' + styles.labelAddQuestion} htmlFor="flexRadioDefault1">
                                Group Name
                            </label>
                            <input
                                placeholder="Type here"
                                type="text"
                                name="name"
                                value={groupName}
                                onChange={e => setGroupName(e.target.value)}
                                className="form-control commonInput"
                            />
                        </div>
                        <br />

                        <div className="d-flex justify-content-between align-items-center mt-5">
                            <button style={{ width: 90 }} onClick={handleSubmit} className={'btn btn-primary' + ' ' + styles.smallButton}>
                                Save &nbsp;{permissionsBtnLoading && <AiOutlineLoading3Quarters className="rotateLoader" />}
                            </button>

                            <p className={styles.orangeBtnText + ' ' + styles.smallFont} onClick={() => setShow(false)}>
                                Cancel
                            </p>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};
const AddUserSidebar = ({ group, show, setShow, setSelectedGroup }) => {
    const { permissionsBtnLoading } = useSelector(state => state.team);
    const { selectedProject } = useSelector(state => state.projectData);
    const dispatch = useDispatch();
    const [emails, setEmails] = React.useState([]);
    const [emailInputValue, setEmailInputValue] = React.useState('');
    const emailInputRef = React.useRef(null);
    const [showEmailValidation, setShowEmailValidation] = React.useState(false);

    const handleClose = () => {
        setShow(false);
        setSelectedGroup();
        setEmails([]);
    };
    const handleSubmit = async () => {
        let body = {
            project: localStorage['project_id'],
            group_type: group?.id,
            emails,
            // acls: group?.acl,
            vdr: selectedProject?.vdr?.id,
        };
        await dispatch(InviteTeamMember(body));
        dispatch(setJoyRideStepIndex(5));
        handleClose();
    };

    const handleInputChange = event => {
        setEmailInputValue(event.target.value);
    };

    const handleInputKeyDown = event => {
        if (event.key === 'Enter' || event.key === ',') {
            setShowEmailValidation(true);
            event.preventDefault();
            if (isEmail(emailInputValue.trim())) {
                setShowEmailValidation(true);
                const newEmails = [...emails, emailInputValue.trim()];
                setEmails(newEmails);
                setEmailInputValue('');
            }
        } else if (event.key === 'Backspace' && emailInputValue === '') {
            const newEmails = [...emails];
            newEmails.pop();
            setEmails(newEmails);
        }
    };

    const handleRemoveEmail = index => {
        const newEmails = [...emails];
        newEmails.splice(index, 1);
        setEmails(newEmails);
    };
    const handleFocusEmailField = () => {
        emailInputRef.current.focus();
    };
    const handleOnBlurEmailField = e => {
        setShowEmailValidation(false);
        if (e?.target?.value?.length > 0) {
            if (isEmail(e.target?.value.trim())) {
                setEmails([...emails, e.target?.value]);
            }
            setEmailInputValue('');
        }
    };

    const isEmail = str => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(str);
    };

    return (
        <>
            <Offcanvas placement="end" show={show} onHide={handleClose}>
                <Offcanvas.Body className="p-4 add-user-sidebar">
                    <h4 className="mt-3 mb-0">{group?.name}</h4>
                    <b className="font-weight-bold mb-5 ">Add User</b>
                    <div className="mt-4">
                        <div className="mt-4 ">
                            <label className={'form-check-label me-4 bold mb-1 ' + styles.labelAddQuestion} htmlFor="flexRadioDefault1">
                                Email Address
                            </label>

                            <div className={styles.emailInputContainer} onClick={handleFocusEmailField}>
                                {emails.map((email, index) => (
                                    <div key={index} className={styles.emailChip}>
                                        <span className="text-truncate">{email}</span>
                                        <button type="button" className={styles.removeEmailButton} onClick={() => handleRemoveEmail(index)}>
                                            &times;
                                        </button>
                                    </div>
                                ))}
                                <input
                                    type="email"
                                    className={styles.emailInput}
                                    placeholder="Use comma to separate addresses"
                                    value={emailInputValue}
                                    onChange={handleInputChange}
                                    onKeyDown={handleInputKeyDown}
                                    ref={emailInputRef}
                                    onBlur={handleOnBlurEmailField}
                                />
                            </div>
                            {emailInputValue !== '' && showEmailValidation && !isEmail(emailInputValue.trim()) && (
                                <span className={styles.emailError}>Enter Valid Email Address</span>
                            )}
                        </div>
                        <div className={styles.smallFont + ' mt-5'}>
                            <i>
                                Invited users will inherit Group Permissions.
                                <br />
                                <br />
                                You can further customise individual user permissions from the Team Page
                            </i>
                        </div>

                        <div className="d-flex justify-content-between align-items-center mt-5 send-mail-step">
                            <button disabled={emails?.length === 0} onClick={handleSubmit} className={'btn btn-primary smallButton'}>
                                Send Email &nbsp;{permissionsBtnLoading && <AiOutlineLoading3Quarters className="rotateLoader" />}
                            </button>

                            <p className={styles.orangeBtnText + ' ' + styles.smallFont} onClick={handleClose}>
                                Cancel
                            </p>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};
const EditUserSidebar = ({ selectedGroup, show, setShow, setSelectedGroup, selectedUser, setSelectedUser }) => {
    const { selectedUserData, groups, teamFormBtnLoading } = useSelector(state => state.team);
    const dispatch = useDispatch();

    const handleClose = () => {
        setShow(false);
        dispatch(clearSelectedUserData());
        setSelectedUser();
        setSelectedGroup();
    };
    const handleSubmit = async () => {
        let body = {
            group_type: selectedGroup?.id,
            user: selectedUser['user_id'],
            id: selectedUserData?.id,
        };
        await dispatch(UpdateGroupMember(body));
        handleClose();
    };
    React.useEffect(() => {
        if (selectedUser && selectedUser['group_member_id']) {
            dispatch(GetGroupMemberPermissions(selectedUser['group_member_id']));
        }
    }, [selectedUser]);

    return (
        <>
            <Offcanvas placement="end" show={show} onHide={handleClose}>
                <Offcanvas.Body className="p-4">
                    <div className="d-flex align-items-center justify-content-between  mb-5">
                        <b className="font-weight-bold ">Change Group</b>
                        <span className={styles.smallFont + ' ' + styles.orangeBtnText}>Delete User</span>
                    </div>
                    <div style={{ lineHeight: '18px' }}>
                        <p className="m-0">{selectedUser?.full_name}</p>
                        <i className={styles.smallFont}>{selectedUser['job_title']}</i>
                        <p>
                            <i className={styles.smallFont}>{selectedUser?.organisation}</i>
                        </p>
                    </div>
                    <div className="mt-4">
                        <div className="mb-4">
                            <label className={styles.smallFont + ' mb-2'}>Change group</label>
                            <select
                                name="swot"
                                value={selectedGroup?.id}
                                onChange={e => setSelectedGroup(groups?.find(group => group?.id === Number(e.target?.value)))}
                                className="form-select"
                                placeholder="Select Impact"
                                aria-label=" example">
                                <option value="">None</option>
                                {groups?.map(group => (
                                    <option value={group?.id} key={group?.id}>
                                        {group?.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div>
                            <p className={styles.infoTextItalic}>Users will inherit the Permissions from the new group. All previous permissions will be deleted.</p>
                        </div>

                        <div className="d-flex justify-content-between align-items-center mt-5">
                            <button onClick={handleSubmit} className={'btn btn-primary smallButton'}>
                                Save &nbsp;{teamFormBtnLoading && <AiOutlineLoading3Quarters className="rotateLoader" />}
                            </button>

                            <p className={styles.orangeBtnText + ' ' + styles.smallFont} onClick={() => setShow(false)}>
                                Cancel
                            </p>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

const AddNdaWarningModal = ({ setModalVisible, modalVisible }) => {
    const navigate = useNavigate();
    return (
        <Modal centered show={modalVisible} onHide={() => setModalVisible(false)} className={styles.modalMainBody}>
            <Modal.Body className={styles.confirmationModalBody + ' d-flex'}>
                <div className={styles.redBgWarnModal}>
                    <ImWarning size={50} />
                </div>
                <div className={styles.confirmationModalMsg}>
                    <h3>NDA not found</h3>
                    <p>Before inviting users, you are required to upload a Non-Disclosure Agreement (NDA) to the project.</p>
                    <div className="d-flex justify-content-end align-items-center">
                        <span onClick={() => setModalVisible(false)} className={styles.orangeLink}>
                            Cancel
                        </span>
                        <button onClick={() => navigate('/uploadnda')} className="ms-5 btn btn-primary smallButton">
                            Upload NDA
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};
export default Main;
