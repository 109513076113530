import React from 'react';
import styles from './css/index.module.css';
import { Input, Tooltip } from '@fluentui/react-components';
import { GetCompanyDetail, GetCompanyId, PatchProjectData, PostProjectData } from '../../redux/projectDataSlice';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { Info12Filled } from '@fluentui/react-icons';

const AddProject = ({ compareObjectsValues }) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = React.useState({
        project_name: '',
        investor_name: '',
        website: '',
        project_deadline: '',
        company_name: '',
    });

    const { projectDetailButtonLoading, selectedProject } = useSelector(state => state.projectData);
    const handleSubmit = async e => {
        e.preventDefault();
        dispatch(
            PatchProjectData({
                data: { ...formData, project_deadline: format(new Date(formData?.project_deadline), 'dd-MM-yyyy') },
                id: selectedProject?.id,
            }),
        );
    };

    const handleChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleSetFormValues = () => {
        setFormData({
            project_name: selectedProject?.project_name,
            investor_name: selectedProject?.investor_name,
            website: selectedProject?.website,
            project_deadline: selectedProject?.project_deadline,
            company_name: selectedProject?.company_name,
        });
    };
    React.useEffect(() => {
        if (selectedProject) {
            handleSetFormValues();
        }
    }, [selectedProject]);
    return (
        <div className={styles.componentCard}>
            <h5 className={styles.componentTitle + ' mt-0'}>Project details</h5>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <p className={styles.fieldLabel}>
                        Project Name <span className={styles.required}>*</span>
                    </p>
                    <Input className={styles.inputField} required value={formData?.project_name} onChange={handleChange} name="project_name" />
                </div>
                <div className="mb-4">
                    <p className={styles.fieldLabel}>
                        Organisation Name<span className={styles.required}>*</span>
                    </p>
                    <Input className={styles.inputField} required value={formData?.company_name} onChange={handleChange} name="company_name" />
                </div>
                <div className="mb-4">
                    <div className="d-flex align-items-center justify-content-between">
                        <p className={styles.fieldLabel}>
                            Website <span className={styles.required}>*</span>
                        </p>
                        <Tooltip withArrow content="Please enter a valid company website address in the correct format, as it is used to gather additional data.">
                            <Info12Filled role="button" color="var(--Neutral-Gray-6)" />
                        </Tooltip>
                    </div>
                    <Input
                        className={styles.inputField}
                        required
                        type="url"
                        value={formData?.website}
                        onChange={handleChange}
                        name="website"
                        placeholder="Please enter a valid website address"
                    />
                </div>
                <div className="mb-4">
                    <p className={styles.fieldLabel}>Investor</p>
                    <Input className={styles.inputField} value={formData?.investor_name} onChange={handleChange} name="investor_name" />
                </div>
                <div className="mb-5">
                    <p className={styles.fieldLabel}>
                        Project Deadline <span className={styles.required}>*</span>
                    </p>
                    <Input className={styles.inputField + ' w-50'} required type="date" value={formData?.project_deadline} onChange={handleChange} name="project_deadline" />
                </div>
                <div className={styles.buttonDiv}>
                    {!compareObjectsValues(selectedProject, formData) && (
                        <div className="d-flex align-items-center justify-content-between">
                            <button
                                disabled={!formData?.company_name || !formData?.project_name || !formData?.project_deadline || !formData?.website}
                                type="submit"
                                className={styles.submitButton}>
                                Save
                                {projectDetailButtonLoading && <AiOutlineLoading3Quarters className="ms-2 rotateLoader" />}
                            </button>
                            <span onClick={handleSetFormValues} className={styles.linkBtn}>
                                Revert
                            </span>
                        </div>
                    )}
                </div>
            </form>
        </div>
    );
};

export default AddProject;
