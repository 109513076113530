import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BsChevronDown, BsThreeDotsVertical } from 'react-icons/bs';
import styles from './css/index.module.css';
import { Dropdown } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import { TiArrowUnsorted } from 'react-icons/ti';
import { BiFilterAlt } from 'react-icons/bi';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { format, differenceInDays, isAfter } from 'date-fns';
import { getProfileNameComponent, htmlToPlainText } from '../../utils/commonUtils';
import Select from 'react-select/creatable';
import { DeleteBulkTask, DeleteTask, GetTask, GetTaskComment, PatchTask, PostTask, PostTaskComment, UpdateBulkTask } from '../../redux/taskSlice';
import { GetProjectUsers } from '../../redux/teamSlice';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import DatePicker from 'react-datepicker';
import { GetDataroomItemInfo, GetWorkstreams, PostWorkstream } from '../../redux/dataroomSlice';
import { GetIRLDoc } from '../../redux/documentsSlice';
import { RxCross2 } from 'react-icons/rx';
import DoubleConfirmationModal from '../CommonComponents/DoubleConfirmationModal/DoubleConfirmationModal';
import { useNavigate } from 'react-router';
import { WorkstreamSelect } from '../Observations';
import { DrawerBody, OverlayDrawer } from '@fluentui/react-components';
import { Dismiss20Regular } from '@fluentui/react-icons';
const TaskInterface = () => {
    const dispatch = useDispatch();
    const { selectedProject } = useSelector(state => state.projectData);
    const { taskData } = useSelector(state => state.task);
    const { workstreams } = useSelector(state => state.dataroom);
    const [modalVisible, setModalVisible] = React.useState(false);
    const [viewModalVisible, setViewModalVisible] = React.useState(false);
    const [selectedRow, setSelectedRow] = React.useState(null);
    const [filteredData, setFilteredData] = React.useState(taskData || []);
    const [selectedWorkstream, setSelectedWorkstream] = React.useState();
    React.useEffect(() => {
        if (taskData && selectedWorkstream) {
            let arrayForSort = taskData?.filter(item => item?.workstream_repr === selectedWorkstream?.name);
            arrayForSort.sort((a, b) => {
                let da = new Date(a?.deadline),
                    db = new Date(b?.deadline);
                return da - db;
            });
            setFilteredData(arrayForSort || []);
        } else setFilteredData([]);
    }, [taskData, selectedWorkstream]);
    React.useEffect(() => {
        if (selectedProject) {
            console.log('task API');
            dispatch(GetTask());
            dispatch(GetProjectUsers(selectedProject?.id));
            dispatch(GetWorkstreams());
        }
    }, [selectedProject]);

    React.useEffect(() => {
        if (selectedWorkstream) {
            dispatch(GetIRLDoc(selectedWorkstream?.id));
            dispatch(GetDataroomItemInfo(selectedWorkstream?.id));
        }
    }, [selectedWorkstream]);

    React.useEffect(() => {
        if (workstreams?.length > 0) {
            if (localStorage['workstream']) {
                const wsFromLocal = workstreams?.find(item => item?.id === parseInt(localStorage['workstream']));
                if (wsFromLocal) setSelectedWorkstream(wsFromLocal);
            } else setSelectedWorkstream(workstreams[0]);
        }
    }, [workstreams]);
    return (
        <div>
            <div>
                <div className={styles.headerSection}>
                    <h5 className={styles.headerTitle}>Tasks</h5>

                    <div className="row mb-3">
                        <div className="col-sm-12 col-md-2">
                            <WorkstreamSelect selectedOption={selectedWorkstream} setSelectedOption={setSelectedWorkstream} />
                        </div>
                    </div>
                </div>

                <>
                    <div className="d-flex align-items-center justify-content-between">
                        {' '}
                        <div className={styles.menuButton}>TASK LIST</div>{' '}
                        <button onClick={() => setModalVisible(true)} type="button" className={`btn btn-primary btn-sm  ${styles.smallButton}`}>
                            Add New Task
                        </button>
                    </div>
                    <div className={styles.tabViewBody}>
                        <TaskTable
                            filteredData={filteredData}
                            setFilteredData={setFilteredData}
                            setModalVisible={setModalVisible}
                            setSelectedRow={setSelectedRow}
                            setViewModalVisible={setViewModalVisible}
                            selectedWorkstream={selectedWorkstream}
                        />
                    </div>
                </>
            </div>
            <TaskFormSidebar show={modalVisible} setShow={setModalVisible} selectedRow={selectedRow} setSelectedRow={setSelectedRow} />
            <TaskViewSidebar
                show={viewModalVisible}
                setShow={setViewModalVisible}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                setEditModalVisible={setModalVisible}
            />
        </div>
    );
};
const ColumnFilters = ({ columnState, setColumnState }) => {
    const DropdownToggleCol = React.forwardRef(({ onClick }, ref) => (
        <div
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
            className={styles.filterSelector}>
            <span>Column View</span>
            &nbsp; &nbsp;
            <BsChevronDown size={10} color="#829ab1" />
        </div>
    ));

    const handleChangeFilter = (label, key, value) => {
        var data = { ...columnState };
        data[key].value = value;
        setColumnState(data);
    };
    const handleClickAll = value => {
        var data = { ...columnState };
        Object.keys(columnState).forEach(key => {
            data[key].value = value;
        });
        setColumnState(data);
    };
    const handleGetAllValue = () => {
        return Object.keys(columnState)?.find(key => columnState[key]?.value === false) ? false : true;
    };

    return (
        <div>
            <Dropdown>
                <Dropdown.Toggle as={DropdownToggleCol} id="dropdown-basic"></Dropdown.Toggle>

                <Dropdown.Menu>
                    <div style={{ width: 350 }} className={styles.filterBody}>
                        <div className="col-sm-12 col-md-6 d-flex align-items-center mt-2">
                            <input checked={handleGetAllValue()} onChange={e => handleClickAll(e.target.checked)} type="checkbox" id="flexCheckDefault" className=" me-3" />
                            <label className={styles.checkLabel}>View All</label>
                        </div>
                        <hr className="mb-2 mt-3" />
                        <div className="row">
                            {Object.keys(columnState)?.map(item => (
                                <div key={columnState[item]?.id} className="col-sm-12 col-md-6 d-flex align-items-center mt-2">
                                    <input
                                        checked={columnState[item]?.value}
                                        onChange={e => handleChangeFilter(columnState[item]?.label, item, e.target.checked)}
                                        type="checkbox"
                                        id="flexCheckDefault"
                                        className=" me-3"
                                    />
                                    <label className={styles.checkLabel + ' ' + styles.smallFont}>{columnState[item]?.label}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};
const TaskTable = ({ filteredData, setModalVisible, setSelectedRow, setViewModalVisible, setFilteredData, selectedWorkstream }) => {
    const dispatch = useDispatch();

    const [columnState, setColumnState] = React.useState({
        priority: { label: 'Priority', value: true },
        task: { label: 'Task', value: true },
        assigned_to: { label: 'Assigned to', value: true },
        tag: { label: 'Tag', value: true },
        added_on: { label: 'Added on', value: true },
        deadline: { label: 'Deadline', value: true },
        status: { label: 'Status', value: true },
    });

    const { projectUsersAll } = useSelector(state => state.team);
    const { items } = useSelector(state => state.dataroom);
    const { taskData } = useSelector(state => state.task);
    const [deadlineSort, setDeadlinSort] = React.useState('asc');
    const [addedOnSort, setAddedOnSort] = React.useState('asc');
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [tableFilters, setTableFilters] = React.useState({});
    const [taskFilters, setTaskFilters] = React.useState();
    const handleSelectAllFilter = val => {
        if (val) {
            let ids = [];
            filteredData?.map(tab => {
                if (!tab?.active) return ids.push(tab?.id);
            });
            setSelectedRows(ids);
        } else setSelectedRows([]);
    };
    const PriorityToggle = React.forwardRef(({ onClick }, ref) => (
        <div
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
            style={{ width: 90 }}
            className={styles.bulkSelector + ' me-2'}>
            <span>Priority</span>
            &nbsp; &nbsp;
            <BsChevronDown size={10} color="var(--Brand-Secondary)" />
        </div>
    ));
    const AssinedToToggle = React.forwardRef(({ onClick }, ref) => (
        <div
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
            style={{ width: 114 }}
            className={styles.bulkSelector + ' me-2'}>
            <span>Assigned to</span>
            &nbsp; &nbsp;
            <BsChevronDown size={10} color="var(--Brand-Secondary)" />
        </div>
    ));
    const TagToggle = React.forwardRef(({ onClick }, ref) => (
        <div
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
            style={{ width: 68 }}
            className={styles.bulkSelector + ' me-2'}>
            <span>Tag</span>
            &nbsp; &nbsp;
            <BsChevronDown size={10} color="var(--Brand-Secondary)" />
        </div>
    ));
    const StatusToggle = React.forwardRef(({ onClick }, ref) => (
        <div
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
            style={{ width: 84 }}
            className={styles.bulkSelector + ' me-2'}>
            <span>Status</span>
            &nbsp; &nbsp;
            <BsChevronDown size={10} color="var(--Brand-Secondary)" />
        </div>
    ));

    const handleChangeFilters = (label, category, value) => {
        let data = tableFilters;
        data[category] = data[category]?.map(item => {
            if (item.label === label) {
                item.value = value;
            }
            return item;
        });
        setTableFilters({ ...data });
        handleUpdateFilters(data);
    };
    const handleUpdateFilters = updatedFilters => {
        let data = taskData?.filter(item => item?.workstream_repr === selectedWorkstream?.name);

        if (updatedFilters?.priority && updatedFilters?.priority.length > 0) {
            updatedFilters?.priority?.map(item => {
                if (!item.value) {
                    data = data.filter(d => d.priority !== item?.label);
                }
            });
        }
        if (updatedFilters?.tags && updatedFilters?.tags.length > 0) {
            updatedFilters?.tags?.map(item => {
                if (!item.value) {
                    data = data.filter(d => d.tag_repr !== item?.label);
                }
            });
        }
        if (updatedFilters?.status && updatedFilters?.status.length > 0) {
            updatedFilters?.status?.map(item => {
                if (!item.value) {
                    data = data.filter(d => d.status !== item?.label);
                }
            });
        }

        if (updatedFilters?.assigned_to && updatedFilters?.assigned_to.length > 0) {
            updatedFilters?.assigned_to?.map(item => {
                if (!item.value) {
                    data = data.filter(d => d?.assigned_to !== item?.label);
                }
            });
        }

        setFilteredData([...data]);
    };

    const FilterIcon = React.forwardRef(({ onClick }, ref) => (
        <BiFilterAlt
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
            className="me-2"
            color="#829ab1"
            size={14}
            role="button"
        />
    ));

    React.useEffect(() => {
        let nf = {};
        if (taskFilters) {
            Object.keys(taskFilters)?.map(key => {
                if (typeof taskFilters[key] === 'object') {
                    let temp = [];
                    taskFilters[key].map(item => {
                        temp.push({
                            label: item,
                            category: key,
                            value: true,
                        });
                    });
                    nf = {
                        ...nf,
                        [key?.replace(' ', '_')]: temp,
                    };
                } else {
                    nf = {
                        ...nf,
                        answered: true,
                    };
                }
            });
            setTableFilters(nf);
        }
    }, [taskFilters]);
    const handleSortAdded = () => {
        setAddedOnSort(addedOnSort === 'asc' ? 'desc' : 'asc');
        var data = [...filteredData];
        data.sort((a, b) => {
            let da = new Date(a.created),
                db = new Date(b.created);
            return addedOnSort === 'asc' ? db - da : da - db;
        });
        setFilteredData(data);
    };
    const handleSortDeadline = () => {
        setDeadlinSort(deadlineSort === 'asc' ? 'desc' : 'asc');
        var data = [...filteredData];
        data.sort((a, b) => {
            let da = new Date(a.deadline),
                db = new Date(b.deadline);
            return deadlineSort === 'asc' ? db - da : da - db;
        });
        setFilteredData(data);
    };

    const handleDeleteTasks = () => {
        dispatch(DeleteBulkTask({ ids: selectedRows }));
    };
    const handleChangeBulkPriority = val => {
        dispatch(
            UpdateBulkTask({
                ids: selectedRows,
                fields_and_values: [
                    {
                        priority: val,
                    },
                ],
            }),
        );
    };
    const handleChangeBulkAssigned = val => {
        dispatch(
            UpdateBulkTask({
                ids: selectedRows,
                fields_and_values: [
                    {
                        assigned_to_id: val,
                    },
                ],
            }),
        );
    };
    const handleChangeBulkTag = val => {
        dispatch(
            UpdateBulkTask({
                ids: selectedRows,
                fields_and_values: [
                    {
                        tag_repr: val,
                    },
                ],
            }),
        );
    };
    const handleChangeBulkStatus = val => {
        dispatch(
            UpdateBulkTask({
                ids: selectedRows,
                fields_and_values: [
                    {
                        status: val,
                    },
                ],
            }),
        );
    };
    const handleChangeBulkDeadline = val => {
        dispatch(
            UpdateBulkTask({
                ids: selectedRows,
                fields_and_values: [
                    {
                        deadline: format(new Date(val), 'yyyy-MM-dd'),
                    },
                ],
            }),
        );
    };
    const handleGetProjectName = id => {
        let user = projectUsersAll.find(item => item.value.user_id === id);
        return (
            <span>
                {user?.value?.full_name} ({user?.value?.organisation || 'N/A'})
            </span>
        );
    };

    const DeadlineDatePicker = React.forwardRef(({ onClick }, ref) => (
        <div
            ref={ref}
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
            style={{ width: 98 }}
            className={styles.bulkSelector + ' me-2'}>
            <span>Deadline</span>
            &nbsp; &nbsp;
            <BsChevronDown size={10} color="var(--Brand-Secondary)" />
        </div>
    ));
    const setAllValuesToTrue = (obj, key) => {
        if (obj.hasOwnProperty(key) && Array.isArray(obj[key])) {
            obj[key].forEach(item => {
                item.value = true;
            });
        }
        handleUpdateFilters(obj);
        setTableFilters({ ...obj });
    };
    const filterItemsWithValueFalse = obj => {
        const filteredItems = {};

        for (const key in obj) {
            if (Array.isArray(obj[key])) {
                const filteredArray = obj[key].filter(item => item.value === false);
                if (filteredArray.length > 0) {
                    filteredItems[key] = filteredArray;
                }
            }
        }

        return filteredItems;
    };
    const generateFiltersFromTasks = (tasks, filters) => {
        let data = tasks?.filter(item => item?.workstream_repr === selectedWorkstream?.name);
        console.log(data);
        data.forEach(task => {
            filters.status.add(task.status);
            filters.priority.add(task.priority);
            filters.workstreams.add(task.workstream_repr);
            filters.tags.add(task.tag_repr);
            filters.assigned_to.add(task.assigned_to);
            if (task.scope) {
                filters.scope.add(task.scope);
            }
        });

        filters.status = Array.from(filters.status);
        filters.priority = Array.from(filters.priority);
        filters.workstreams = Array.from(filters.workstreams);
        filters.tags = Array.from(filters.tags);
        filters.assigned_to = Array.from(filters.assigned_to);
        filters.scope = Array.from(filters.scope);
        filters.length = data?.length;

        return filters;
    };
    React.useEffect(() => {
        if (taskData?.length > 0) {
            setTaskFilters(
                generateFiltersFromTasks(taskData, {
                    status: new Set(),
                    priority: new Set(),
                    workstreams: new Set(),
                    tags: new Set(),
                    assigned_to: new Set(),
                    scope: new Set(),
                    length: 0,
                }),
            );
        }
    }, [taskData]);
    const tableActiveFilters = filterItemsWithValueFalse(tableFilters);
    return (
        <div>
            {selectedRows?.length > 0 ? (
                <div className={styles.dropdownsBody + ' d-flex justify-content-end me-2 flex-wrap align-items-center '}>
                    <Dropdown>
                        <Dropdown.Toggle as={PriorityToggle} id="dropdown-basic"></Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleChangeBulkPriority('HIGH')}>High</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleChangeBulkPriority('MEDIUM')}>Medium</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleChangeBulkPriority('LOW')}>Low</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown>
                        <Dropdown.Toggle as={AssinedToToggle} id="dropdown-basic"></Dropdown.Toggle>
                        <Dropdown.Menu>
                            {projectUsersAll?.map(item => (
                                <Dropdown.Item onClick={() => handleChangeBulkAssigned(item?.value?.user_id)} key={item?.value?.user_id} value={item?.value?.user_id}>
                                    {item?.value?.full_name}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown>
                        <Dropdown.Toggle as={TagToggle} id="dropdown-basic"></Dropdown.Toggle>
                        <Dropdown.Menu>
                            {items?.map(item => (
                                <Dropdown.Item onClick={() => handleChangeBulkTag(item?.name)} value={item?.name} key={item?.id}>
                                    {item?.name}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                    <DatePicker onChange={e => handleChangeBulkDeadline(e)} customInput={<DeadlineDatePicker />} />

                    <Dropdown>
                        <Dropdown.Toggle as={StatusToggle} id="dropdown-basic"></Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleChangeBulkStatus('COMPLETE')}>Complete</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleChangeBulkStatus('PENDING')}>Pending</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <DoubleConfirmationModal
                        message="Deleting a Task will permanently remove it from the Project."
                        handleAction={() => {
                            handleDeleteTasks();
                        }}>
                        <button type="button" className={`btn btn-primary btn-sm ${styles.deleteButton} ${styles.smallButton}`} style={{ height: 26 }}>
                            Delete
                        </button>
                    </DoubleConfirmationModal>
                </div>
            ) : (
                <div className="d-flex align-items-center mb-3 justify-content-between">
                    <div className="d-flex align-items-center  ">
                        <ColumnFilters columnState={columnState} setColumnState={setColumnState} />
                        <p className={'ms-3 ' + styles.questionsLength}>
                            Viewing{' '}
                            <b>
                                {filteredData.length} of {taskFilters?.length}
                            </b>{' '}
                            Tasks
                        </p>
                    </div>
                    <div className="d-flex flex-wrap">
                        {Object?.keys(tableActiveFilters).map(key => (
                            <div className={styles.filterTag} onClick={() => setAllValuesToTrue(tableFilters, key)}>
                                {key}&nbsp; <RxCross2 />
                            </div>
                        ))}
                    </div>
                </div>
            )}
            <div className={`mt-3 ${styles.tableContainer} `} id="style-3-scroll">
                <table className="w-100">
                    <thead className={styles.tableHeader}>
                        <tr>
                            <td className="ps-2 pt-2" style={{ minWidth: 40 }}>
                                <input
                                    type="checkbox"
                                    checked={selectedRows?.length === filteredData.length ? true : false}
                                    onChange={e => handleSelectAllFilter(e.target.checked)}
                                />
                            </td>
                            {columnState?.priority.value && (
                                <td style={{ minWidth: 85 }}>
                                    <div className="d-flex align-items-center">
                                        <span> Priority </span>
                                        &nbsp;
                                        <Dropdown>
                                            <Dropdown.Toggle as={FilterIcon} id="dropdown-basic"></Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <div style={{ width: 150 }} className={styles.filterBody}>
                                                    <div className="row">
                                                        {tableFilters?.priority?.map(item => (
                                                            <div key={item?.label} className="col-sm-12 d-flex align-items-center mt-2">
                                                                <input
                                                                    checked={item?.value}
                                                                    onChange={e => handleChangeFilters(item?.label, 'priority', e.target.checked)}
                                                                    type="checkbox"
                                                                    id="flexCheckDefault"
                                                                    className=" me-3"
                                                                />
                                                                <label className={styles.checkLabel + ' ' + styles.smallFont}>{item?.label?.toUpperCase() || 'N/A'}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </td>
                            )}
                            {columnState?.task.value && <td style={{ minWidth: 260 }}>Task</td>}
                            {columnState?.assigned_to.value && (
                                <td style={{ minWidth: 114 }}>
                                    <div className="d-flex align-items-center">
                                        <span> Assigned to </span>
                                        &nbsp;
                                        <Dropdown>
                                            <Dropdown.Toggle as={FilterIcon} id="dropdown-basic"></Dropdown.Toggle>
                                            {tableFilters?.assigned_to?.length > 0 && (
                                                <Dropdown.Menu>
                                                    <div style={{ width: 250 }} className={styles.filterBody}>
                                                        <div className="row">
                                                            {tableFilters?.assigned_to?.map(item => (
                                                                <div key={item?.label} className="col-sm-12 d-flex align-items-center mt-2">
                                                                    <input
                                                                        checked={item?.value}
                                                                        onChange={e => handleChangeFilters(item?.label, 'assigned_to', e.target.checked)}
                                                                        type="checkbox"
                                                                        id="flexCheckDefault"
                                                                        className=" me-3"
                                                                    />
                                                                    <label className={styles.checkLabel + ' ' + styles.smallFont}>{handleGetProjectName(item?.label)}</label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </Dropdown.Menu>
                                            )}
                                        </Dropdown>
                                    </div>
                                </td>
                            )}
                            {columnState?.tag.value && (
                                <td style={{ minWidth: 200 }}>
                                    <div className="d-flex align-items-center">
                                        <span> Tag</span>
                                        &nbsp;
                                        <Dropdown>
                                            <Dropdown.Toggle as={FilterIcon} id="dropdown-basic"></Dropdown.Toggle>
                                            {tableFilters?.tags?.length > 0 && (
                                                <Dropdown.Menu>
                                                    <div style={{ width: 250 }} className={styles.filterBody}>
                                                        <div className="row">
                                                            {tableFilters?.tags?.map(item => (
                                                                <div key={item?.label} className="col-sm-12 d-flex align-items-center mt-2">
                                                                    <input
                                                                        checked={item?.value}
                                                                        onChange={e => handleChangeFilters(item?.label, 'tags', e.target.checked)}
                                                                        type="checkbox"
                                                                        id="flexCheckDefault"
                                                                        className=" me-3"
                                                                    />
                                                                    <label className={styles.checkLabel + ' ' + styles.smallFont}>{item?.label?.toUpperCase()}</label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </Dropdown.Menu>
                                            )}
                                        </Dropdown>
                                    </div>
                                </td>
                            )}
                            {columnState?.added_on.value && (
                                <td style={{ minWidth: 100 }}>
                                    <div className="d-flex align-items-center">
                                        <span> Added on </span>
                                        &nbsp;
                                        <TiArrowUnsorted onClick={handleSortAdded} className="me-2" color="#829ab1" size={14} role="button" />
                                    </div>
                                </td>
                            )}
                            {columnState?.deadline.value && (
                                <td style={{ minWidth: 150 }}>
                                    <div className="d-flex align-items-center">
                                        <span> Deadline </span>
                                        &nbsp;
                                        <TiArrowUnsorted onClick={handleSortDeadline} className="me-2" color="#829ab1" size={14} role="button" />
                                    </div>
                                </td>
                            )}
                            {columnState?.status.value && (
                                <td style={{ minWidth: 102 }}>
                                    <div className="d-flex align-items-center">
                                        <span> Status</span>
                                        &nbsp;
                                        <div className={styles.kebabBody}>
                                            <Dropdown>
                                                <Dropdown.Toggle as={FilterIcon} id="dropdown-basic"></Dropdown.Toggle>
                                                {tableFilters?.status?.length > 0 && (
                                                    <Dropdown.Menu>
                                                        <div style={{ width: 120 }} className={styles.filterBody}>
                                                            <div className="row">
                                                                {tableFilters?.status?.map(
                                                                    item =>
                                                                        item?.label && (
                                                                            <div key={item?.label} className="col-sm-12 d-flex align-items-center mt-2">
                                                                                <input
                                                                                    checked={item?.value}
                                                                                    onChange={e => handleChangeFilters(item?.label, 'status', e.target.checked)}
                                                                                    type="checkbox"
                                                                                    id="flexCheckDefault"
                                                                                    className=" me-3"
                                                                                />
                                                                                <label className={styles.checkLabel + ' ' + styles.smallFont}>{item?.label?.toUpperCase()}</label>
                                                                            </div>
                                                                        ),
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Dropdown.Menu>
                                                )}
                                            </Dropdown>
                                        </div>
                                    </div>
                                </td>
                            )}

                            <th style={{ minWidth: 40 }}> &nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData?.map(item => (
                            <TaskRow
                                columnState={columnState}
                                setModalVisible={setModalVisible}
                                setSelectedRow={setSelectedRow}
                                item={item}
                                setViewModalVisible={setViewModalVisible}
                                setSelectedRows={setSelectedRows}
                                selectedRows={selectedRows}
                                filteredData={filteredData}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
const TaskRow = ({ item, setViewModalVisible, setSelectedRow, setModalVisible, columnState, selectedRows, setSelectedRows, filteredData }) => {
    const dispatch = useDispatch();
    const { projectUsersAll } = useSelector(state => state.team);
    const CustomToggle = React.forwardRef(({ onClick }, ref) => (
        <BsThreeDotsVertical
            ref={ref}
            color="rgba(130, 154, 177, 0.5)"
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
        />
    ));
    const handleSelectRows = id => {
        if (selectedRows?.find(x => x === id)) {
            setSelectedRows(selectedRows.filter(x => x !== id));
        } else {
            setSelectedRows([...selectedRows, id]);
        }
    };

    const handleGetProjectName = id => {
        let user = projectUsersAll.find(item => item.value.user_id === id);
        return (
            <div>
                {user?.value?.full_name}
                {/* <p className="m-0" style={{ color: '#829ab1' }}>
                    {user?.value?.organisation}
                </p> */}
            </div>
        );
    };

    const handleShowDeadline = item => {
        const check = isAfter(new Date(), new Date(item?.deadline));
        const days = Math.abs(differenceInDays(new Date(), new Date(item?.deadline)));
        if (days === 0 || days === 1) {
            return (
                <p
                    style={{
                        color: check ? '#da2a2a' : '#33b5e5',
                        fontWeight: check && 'bold',
                    }}
                    className={styles.smallFont + ' m-0'}>
                    {check ? '1 day overdue' : '1 day remaining'}
                </p>
            );
        } else {
            return (
                <p
                    style={{
                        color: check ? '#da2a2a' : '#33b5e5',
                        fontWeight: check && 'bold',
                    }}
                    className={styles.smallFont + ' m-0'}>
                    {check ? `${days} days overdue` : days + ' days remaining'}
                </p>
            );
        }
    };
    const handleShowCompleted = item => {
        const days = differenceInDays(new Date(item?.completed), new Date(item?.created));

        if (days === 0 || days === 1) {
            return `Completed in 1 day`;
        } else if (days > 1) {
            return `Completed in ${days} days`;
        }
    };
    const handleSelectRow = () => {
        setSelectedRow(item);
        setViewModalVisible(true);
    };
    return (
        <tr key={item?.id} className={styles.tableRow}>
            <td className={styles.rowItem + ' ps-2'}>
                <input
                    className={styles.rowCheck}
                    type="checkbox"
                    style={{ display: selectedRows?.find(x => x === item?.id) && 'block' }}
                    checked={selectedRows?.find(x => x === item?.id)}
                    onChange={e => handleSelectRows(item?.id, e.target.checked)}
                />
            </td>
            {columnState?.priority.value && (
                <td onClick={handleSelectRow} className={styles.rowItem}>
                    <span
                        style={{
                            color: (item?.priority === 'HIGH' && '#da2a2a') || (item?.priority === 'MEDIUM' && '#ffbb33') || (item?.priority === 'LOW' && '#33b5e5'),
                        }}>
                        {item?.priority}
                    </span>
                </td>
            )}
            {columnState?.task.value && (
                <td onClick={handleSelectRow} className={styles.rowItem}>
                    <div className={styles?.dropdown}>
                        <div style={{ minWidth: '400px', maxWidth: '400px' }}>
                            <p className="text-truncate m-0">{htmlToPlainText(item?.task || '')}</p>
                        </div>
                        <div className={styles.hoverMenuContent}>
                            <div
                                style={{
                                    width: '400px',
                                }}
                                className={styles.hoverMenuCont}>
                                {htmlToPlainText(item?.task || '')}
                            </div>
                        </div>
                    </div>
                </td>
            )}
            {columnState?.assigned_to.value && (
                <td onClick={handleSelectRow} className={styles.rowItem}>
                    {(item?.assigned_to && <div>{handleGetProjectName(item?.assigned_to)}</div>) || (item?.assigned_to_offline && <p>{item?.assigned_to_offline}</p>)}
                </td>
            )}
            {columnState?.tag.value && (
                <td onClick={handleSelectRow} className={styles.rowItem}>
                    <span>{item?.tag_repr}</span>
                </td>
            )}
            {columnState?.added_on.value && (
                <td onClick={handleSelectRow} className={styles.rowItem}>
                    <p className={styles.smallFont + ' m-0'}>{item?.created && format(new Date(item?.created), 'dd/MM/yyyy')}</p>
                </td>
            )}
            {columnState?.deadline.value && (
                <td onClick={handleSelectRow} className={styles.rowItem}>
                    <div style={{ opacity: item?.assigned_to || item?.assigned_to_offline ? 1 : 0.6, width: 150 }}>
                        {item?.status === 'COMPLETE' ? (
                            <>
                                <p className={styles.smallFont + ' m-0'}>{item?.deadline && format(new Date(item?.deadline), 'dd/MM/yyyy')}</p>
                                <p style={{ color: '#33b5e5' }} className={styles.smallFont + ' m-0'}>
                                    {item?.deadline && handleShowCompleted(item)}
                                </p>
                            </>
                        ) : (
                            <>
                                {' '}
                                <p className={styles.smallFont + ' m-0'}>{item?.deadline && format(new Date(item?.deadline), 'dd/MM/yyyy')}</p>
                                {item?.deadline && handleShowDeadline(item)}
                            </>
                        )}
                    </div>
                </td>
            )}
            {columnState?.status.value && (
                <td onClick={handleSelectRow} className={styles.rowItem}>
                    {item?.status === 'COMPLETE' ? <span style={{ color: '#00c851' }}>Completed</span> : <span style={{ color: '#ff0000' }}>Pending</span>}
                </td>
            )}

            <td className={styles.rowItem}>
                <div className={styles.kebabBody}>
                    <Dropdown>
                        <Dropdown.Toggle as={CustomToggle} id="dropdown-basic"></Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                onClick={() => {
                                    setSelectedRow(item);
                                    setModalVisible(true);
                                }}>
                                Edit
                            </Dropdown.Item>
                            <DoubleConfirmationModal
                                message="Deleting an Task will permanently remove it and all associated comments from the DD Tool."
                                handleAction={() => dispatch(DeleteTask({ id: item?.id }))}>
                                <Dropdown.Item>Delete</Dropdown.Item>
                            </DoubleConfirmationModal>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </td>
        </tr>
    );
};
export const TaskFormSidebar = ({ show, setShow, selectedRow, setSelectedRow, defaultTask, setDefaultData }) => {
    const dispatch = useDispatch();
    const { projectUsersAll } = useSelector(state => state.team);
    const { user } = useSelector(state => state.user);
    const { taskButtonLoading, taskCommentBtnLoading, taskComments } = useSelector(state => state.task);
    const [comment, setComment] = React.useState('');
    const [selectedAssignee, setSelectedAssignee] = React.useState();
    const [showComment, setShowComment] = React.useState(false);
    const [task, setTask] = React.useState('');
    const { workstreams, default_folders } = useSelector(state => state.dataroom);
    const { items } = useSelector(state => state?.dataroom);
    const [tagOptions, setTagOptions] = React.useState([]);
    const [formData, setFormData] = React.useState({
        deadline: '',
        project: localStorage['project_id'],
        status: 'PENDING',
        priority: '',
        workstream_repr: '',
        tag_repr: '',
    });
    React.useEffect(() => {
        setFormData({
            deadline: selectedRow?.deadline || '',
            project: localStorage['project_id'],
            status: 'PENDING',
            priority: selectedRow?.priority || '',
            workstream_repr: workstreams?.find(item => item?.name === selectedRow?.workstream_repr)?.id,
            tag_repr: { label: selectedRow?.tag_repr, value: selectedRow?.tag_repr },
        });
        setTask(selectedRow?.task || '');
        if (selectedRow?.assigned_to) {
            let assigneeData = projectUsersAll.find(item => item?.value?.user_id == selectedRow?.assigned_to);
            assigneeData = { label: assigneeData?.value?.full_name, value: assigneeData?.value };
            setSelectedAssignee(assigneeData);
        }

        if (selectedRow) dispatch(GetTaskComment({ taskId: selectedRow?.id }));
        setShowComment(false);
    }, [selectedRow]);

    const handleSubmit = async () => {
        if (selectedRow) {
            let obj = { ...formData, task, workstream_repr: workstreams?.find(item => item?.id === Number(formData?.workstream_repr))?.name, tag_repr: formData?.tag_repr?.value };
            if (!obj.created_by) obj.created_by = user?.id;
            if (selectedAssignee) {
                if (selectedAssignee?.__isNew__) obj.assigned_to_offline = selectedAssignee?.value;
                else obj.assigned_to = selectedAssignee?.value?.user_id;
            }
            obj.deadline === '' && delete obj.deadline;
            await dispatch(PatchTask({ body: obj, id: selectedRow?.id }));
        } else {
            let body = {
                ...formData,
                task,
                created_by: user?.id,
                workstream_repr: workstreams?.find(item => item?.id === Number(formData?.workstream_repr))?.name,
                tag_repr: formData?.tag_repr?.value,
            };
            !body?.deadline && delete body.deadline;
            if (selectedAssignee) {
                if (selectedAssignee?.__isNew__) body.assigned_to_offline = selectedAssignee?.value;
                else body.assigned_to = selectedAssignee?.value?.user_id;
            }
            body.deadline === '' && delete body.deadline;
            await dispatch(PostTask(body));
        }
        handleClose();
    };

    const handleClose = () => {
        setShow(false);
        setSelectedRow && setSelectedRow(null);
        setFormData({
            deadline: '',
            assigned_to: '',
            project: localStorage['project_id'],
            status: 'PENDING',
            priority: '',
            workstream: '',
            tag: '',
        });
        setSelectedAssignee();
        setComment('');
        setTask('');
        setDefaultData && setDefaultData();
    };
    const handleAddComment = async () => {
        if (comment) {
            let body = {
                task: selectedRow?.id,
                comment,
                created_by: user?.id,
            };
            await dispatch(PostTaskComment({ body }));
            setComment('');
            // handleClose();
        }
    };
    const handleGetProjectName = (id, onlyName) => {
        let userData = projectUsersAll.find(item => item.value.user_id === id);
        if (!userData && user?.id === id) {
            console.log(user);
            userData = {
                label: user?.full_name,
            };
        }
        return onlyName ? (
            <span> {userData?.label}</span>
        ) : (
            <div className="d-flex align-items-center">
                <div className={styles.smallAvatar} style={{ background: userData?.color }}>
                    {getProfileNameComponent(userData?.label)}
                </div>
                <span style={{ maxWidth: 120 }} className={styles.smallFont + ' m-0 ms-2 text-truncate'}>
                    {userData?.label}
                </span>
            </div>
        );
    };
    React.useEffect(() => {
        if (formData?.workstream_repr && show) {
            dispatch(GetIRLDoc(formData?.workstream_repr));
            dispatch(GetDataroomItemInfo(formData?.workstream_repr));
        }
    }, [formData?.workstream_repr, show, dispatch]);
    React.useEffect(() => {
        if (defaultTask) setTask(defaultTask);
    }, [defaultTask]);
    React.useEffect(() => {
        const combinedOptions = [...new Set([...default_folders.map(folder => folder?.ws_folder), ...items.map(item => item?.name)])].map(label => ({ label, value: label }));
        setTagOptions(combinedOptions);
    }, [default_folders, items]);

    return (
        <>
            <OverlayDrawer style={{ minWidth: 400 }} type="overlay" position="end" open={show} onOpenChange={(_, { open }) => handleClose(open)}>
                <DrawerBody style={{ padding: 16 }}>
                    <div className="">
                        <div className="d-flex justify-content-between">
                            <p className={styles.sidebarTitle}>{selectedRow ? 'Edit' : 'Add '} Task</p>
                            <Dismiss20Regular role="button" onClick={handleClose} color="var(--Neutral-Gray-6)" />
                        </div>

                        <div>
                            <div className="mt-1 mb-4">
                                <label className={styles.fieldLabel}>
                                    Workstream <span style={{ color: 'var(--danger)' }}>*</span>
                                </label>
                                <select
                                    name="workstream_repr"
                                    value={formData.workstream_repr}
                                    onChange={e =>
                                        setFormData({
                                            ...formData,
                                            workstream_repr: e.target.value,
                                            tag_repr: null,
                                        })
                                    }
                                    className={'form-select ' + styles.customSelect}
                                    placeholder="Select Workstream"
                                    aria-label=".form-select-sm example">
                                    <option value="">None</option>
                                    {workstreams?.map(item => (
                                        <option key={item?.id} value={item?.id}>
                                            {item?.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className=" mb-4">
                                <label className={styles.fieldLabel}>
                                    Tag <span style={{ color: 'var(--danger)' }}>*</span>
                                </label>

                                <Select
                                    name="selectedAssignee"
                                    options={tagOptions}
                                    isDisabled={!formData?.workstream_repr}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    value={formData?.tag_repr}
                                    onChange={e => setFormData({ ...formData, tag_repr: e })}
                                    maxMenuHeight={1000}
                                    placeholder="Type here"
                                    isClearable
                                    styles={{
                                        control: (provided, state) => ({
                                            ...provided,
                                            borderColor: 'var(--Neutral-Gray-5)',
                                            borderBottomColor: 'var(--Neutral-Gray-6)',
                                            maxHeight: '32px',
                                            minHeight: '32px',
                                            fontSize: 14,
                                        }),
                                        valueContainer: (provided, state) => ({
                                            ...provided,
                                            height: '32px',
                                            padding: '0 6px',
                                        }),

                                        input: (provided, state) => ({
                                            ...provided,
                                            margin: '0px',
                                        }),
                                        indicatorSeparator: state => ({
                                            display: 'none',
                                        }),
                                        indicatorsContainer: (provided, state) => ({
                                            ...provided,
                                            height: '30px',
                                        }),
                                    }}
                                />
                            </div>
                            <div className={styles.customReactQuill + ' mb-4'}>
                                <p style={{ marginBottom: -32 }} className={styles.fieldLabel}>
                                    Task Description <span style={{ color: 'var(--danger)' }}>*</span>
                                </p>
                                <ReactQuill
                                    placeholder={''}
                                    modules={{
                                        toolbar: [['bold', 'italic', 'underline'], [{ list: 'ordered' }, { list: 'bullet' }], ['link']],
                                    }}
                                    theme="snow"
                                    className="mt-1"
                                    defaultValue={task || ''}
                                    value={task || ''}
                                    onChange={t => setTask(t)}
                                />
                            </div>
                            <div className="mb-4">
                                <label className={styles.fieldLabel}>Assigned to</label>

                                <Select
                                    name="selectedAssignee"
                                    options={projectUsersAll?.map(item => {
                                        return { label: item?.value?.full_name, value: item?.value };
                                    })}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    value={selectedAssignee}
                                    onChange={e => setSelectedAssignee(e)}
                                    placeholder="Select team member"
                                    maxMenuHeight={1000}
                                    isClearable
                                    styles={{
                                        control: (provided, state) => ({
                                            ...provided,
                                            borderColor: 'var(--Neutral-Gray-5)',
                                            borderBottomColor: 'var(--Neutral-Gray-6)',
                                            maxHeight: '32px',
                                            minHeight: '32px',
                                            fontSize: 14,
                                        }),
                                        valueContainer: (provided, state) => ({
                                            ...provided,
                                            height: '32px',
                                            padding: '0 6px',
                                        }),

                                        input: (provided, state) => ({
                                            ...provided,
                                            margin: '0px',
                                        }),
                                        indicatorSeparator: state => ({
                                            display: 'none',
                                        }),
                                        indicatorsContainer: (provided, state) => ({
                                            ...provided,
                                            height: '30px',
                                        }),
                                    }}
                                />
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-6">
                                    <div className="">
                                        <label className={styles.fieldLabel}>Priority</label>
                                        <select
                                            name="priority"
                                            value={formData.priority}
                                            onChange={e =>
                                                setFormData({
                                                    ...formData,
                                                    priority: e.target.value,
                                                })
                                            }
                                            style={{ color: !formData?.priority && 'var(--Neutral-Gray-6)' }}
                                            className={'form-select ' + styles.customSelect}
                                            placeholder="Select Impact"
                                            aria-label=".form-select-sm example">
                                            <option value="">None</option>
                                            <option value="HIGH">High</option>
                                            <option value="MEDIUM">Medium</option>
                                            <option value="LOW">Low</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <div className="">
                                        <label className={styles.fieldLabel}>Deadline</label>
                                        <input
                                            type="date"
                                            name="deadline"
                                            value={formData?.deadline}
                                            onChange={e =>
                                                setFormData({
                                                    ...formData,
                                                    deadline: e.target.value,
                                                })
                                            }
                                            style={{ color: !formData?.deadline && 'var(--Neutral-Gray-6)' }}
                                            placeholder="Select"
                                            className={'form-control ' + styles.customSelect}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="mt-5 d-flex justify-content-between align-items-center">
                                <button
                                    disabled={!Boolean(task && formData?.workstream_repr && task !== '<p><br></p>')}
                                    onClick={handleSubmit}
                                    className={`btn btn-primary btn-sm ${styles.saveButton}`}>
                                    {selectedRow ? 'Update' : 'Add '} Task {taskButtonLoading && <AiOutlineLoading3Quarters className="rotateLoader" />}
                                </button>
                            </div>
                            {selectedRow && <div className={styles.dashLine}></div>}

                            {selectedRow && taskComments?.length > 0 && (
                                <div className="mt-4">
                                    <p className={styles.blueLabel}>Comments</p>
                                    {taskComments?.map(item => (
                                        <div key={item?.id} className="mb-4">
                                            <p
                                                className={styles.observationText + ' mb-2 ' + styles.regularText}
                                                dangerouslySetInnerHTML={{
                                                    __html: item?.comment,
                                                }}></p>
                                            <div className={styles.smallFont + ' ' + styles.lightGray}>
                                                Added on {item?.created && format(new Date(item?.created), 'dd/MM/yyyy')} by {handleGetProjectName(item?.created_by, true)}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {showComment ? (
                                <div className={styles.customReactQuill + ' mb-4 position-relative'}>
                                    <p style={{ marginBottom: -35 }} className={styles.fieldLabel}>
                                        Add comment
                                    </p>
                                    <ReactQuill
                                        placeholder={''}
                                        modules={{
                                            toolbar: [['bold', 'italic', 'underline'], [{ list: 'ordered' }, { list: 'bullet' }], ['link']],
                                        }}
                                        theme="snow"
                                        className="mt-1"
                                        defaultValue={comment}
                                        onChange={t => {
                                            if (t === '<p><br></p>') setComment('');
                                            else setComment(t);
                                        }}
                                    />
                                    <div className="mt-4 d-flex justify-content-between align-items-center">
                                        <button
                                            onClick={handleAddComment}
                                            disabled={!comment}
                                            type="button"
                                            className={`btn btn-primary btn-sm  ${styles.responseButton} ${styles.smallButton} ${!comment && styles.disabledSaveBtn}`}>
                                            Save &nbsp; {taskCommentBtnLoading && <AiOutlineLoading3Quarters className="rotateLoader" />}
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                selectedRow && (
                                    <button onClick={() => setShowComment(true)} type="button" className={`btn btn-primary btn-sm  ${styles.responseButton} ${styles.smallButton}`}>
                                        Add Comment
                                    </button>
                                )
                            )}
                        </div>
                    </div>
                </DrawerBody>
            </OverlayDrawer>
        </>
    );
};
const TaskViewSidebar = ({ show, setShow, selectedRow, setSelectedRow, setEditModalVisible }) => {
    const dispatch = useDispatch();
    const { taskComments, taskCommentBtnLoading } = useSelector(state => state.task);
    const { user } = useSelector(state => state.user);
    const { projectUsersAll } = useSelector(state => state.team);
    const [comment, setComment] = React.useState('');
    const [showComment, setShowComment] = React.useState(false);

    React.useEffect(() => {
        setShowComment(false);
        if (selectedRow) dispatch(GetTaskComment({ taskId: selectedRow?.id }));
    }, [selectedRow]);
    const handleSubmit = async () => {
        let body = {
            task: selectedRow?.id,
            comment,
            created_by: user?.id,
        };
        dispatch(PostTaskComment({ body }));
        setComment('');
        setShowComment(false);
    };

    const handleClose = () => {
        setShow(false);
        setComment('');
        setSelectedRow(null);
    };

    const handleMarkComplete = () => {
        let body = {
            project: localStorage['project_id'],
            status: 'COMPLETE',
            completed: format(new Date(), 'yyyy-MM-dd'),
        };
        dispatch(PatchTask({ body, id: selectedRow?.id }));
        handleClose();
    };

    const handleGetProjectName = (id, onlyName) => {
        let userData = projectUsersAll.find(item => item.value.user_id === id);
        if (!userData && user?.id === id) {
            console.log(user);
            userData = {
                value: { ...user },
            };
        }

        return onlyName ? (
            <span> {userData?.value?.full_name}</span>
        ) : (
            <div className="d-flex align-items-center">
                <div className={styles.smallAvatar} style={{ background: userData?.color }}>
                    {getProfileNameComponent(userData?.value?.full_name)}
                </div>
                <span style={{ maxWidth: 120 }} className={styles.smallFont + ' m-0 ms-2 text-truncate'}>
                    {userData?.value?.full_name}
                </span>
            </div>
        );
    };
    const handleGetProjectDetail = id => {
        let user = projectUsersAll.find(item => item.value.user_id === id);
        return (
            <>
                <b className={styles.regularText}>{user?.value?.full_name || selectedRow?.assigned_to_offline}</b>
                <p className={styles.smallFont + ' m-0 ' + styles.lightGray}>{user?.value?.organisation}</p>
            </>
        );
    };
    const CustomToggle = React.forwardRef(({ onClick }, ref) => (
        <BsThreeDotsVertical
            ref={ref}
            color="rgba(130, 154, 177, 0.5)"
            onClick={e => {
                e.preventDefault();
                onClick(e);
            }}
        />
    ));

    return (
        <>
            <Offcanvas placement="end" show={show} onHide={handleClose}>
                <Offcanvas.Body>
                    <div className="p-3">
                        <div>
                            <div className="d-flex justify-content-between align-items-center mb-4">
                                {selectedRow?.status === 'COMPLETE' ? (
                                    <div className={styles.completeDiv}>Completed</div>
                                ) : (
                                    <button onClick={handleMarkComplete} className={'btn btn-primary smallButton'}>
                                        Mark as Complete
                                    </button>
                                )}

                                <div className={styles.kebabBody}>
                                    <Dropdown>
                                        <Dropdown.Toggle as={CustomToggle} id="dropdown-basic"></Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                                onClick={() => {
                                                    // setSelectedRow(item);
                                                    // setModalVisible(true);
                                                    setShow(false);
                                                    setEditModalVisible(true);
                                                }}>
                                                Edit
                                            </Dropdown.Item>
                                            {/* <DeleteMenuModal
                                                handleAction={() => {
                                                    dispatch(DeleteTask({ id: selectedRow?.id }));
                                                    handleClose();
                                                }}
                                            /> */}
                                            <DoubleConfirmationModal
                                                message="Deleting an Task will permanently remove it and all associated comments from the DD Tool."
                                                handleAction={() => {
                                                    dispatch(DeleteTask({ id: selectedRow?.id }));
                                                    handleClose();
                                                }}>
                                                <Dropdown.Item>Delete</Dropdown.Item>
                                            </DoubleConfirmationModal>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                            <div className="mb-5">
                                <p
                                    className={styles.blueLabel}
                                    style={{
                                        color:
                                            (selectedRow?.priority === 'HIGH' && '#da2a2a') ||
                                            (selectedRow?.priority === 'MEDIUM' && '#fc9c24') ||
                                            (selectedRow?.priority === 'LOW' && '#33b5e5'),
                                    }}>
                                    {selectedRow?.priority?.toLowerCase()} priority
                                </p>
                                <p
                                    className={styles.regularText}
                                    dangerouslySetInnerHTML={{
                                        __html: selectedRow?.task,
                                    }}></p>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-6">
                                    <p className={styles.blueLabel + ' mb-1'}>Deadline</p>
                                    <div style={{ lineHeight: 1.2 }}>
                                        <b className={styles.medFont}>{selectedRow?.deadline && format(new Date(selectedRow?.deadline), 'dd/MM/yyyy')}</b>
                                        <p className={styles.smallFont + ' m-0 ' + styles.lightGray}>
                                            {selectedRow?.deadline && differenceInDays(new Date(selectedRow?.deadline), new Date())} days remaining
                                        </p>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <p className={styles.blueLabel + ' mb-1'}>Assigned to</p>
                                    <div style={{ lineHeight: 1.2 }}>{handleGetProjectDetail(selectedRow?.assigned_to)}</div>
                                </div>
                            </div>
                            <div className={styles.dashLine}></div>
                            <div className=" mt-4 mb-4">
                                <p className={styles.xsmallFont + ' m-0 ' + styles.lightGray}>Workstream </p>
                                <p className={styles.blueLabel + ' me-5'}>{selectedRow?.workstream_repr}</p>
                                <div className="d-flex mt-3">
                                    {' '}
                                    <div className={styles.tagChip}>{selectedRow?.tag_repr}</div>
                                </div>
                            </div>{' '}
                            <div className={styles.dashLine}></div>
                            {selectedRow?.created_by && (
                                <div className={styles.smallFont + ' ' + styles.lightGray}>
                                    Added on {selectedRow?.created && format(new Date(selectedRow?.created), 'dd/MM/yyyy')} by {handleGetProjectName(selectedRow?.created_by, true)}{' '}
                                </div>
                            )}
                            <div className={styles.dashLine}></div>
                            {taskComments?.length > 0 && (
                                <div className="mt-4">
                                    <p className={styles.blueLabel}>Comments</p>
                                    {taskComments?.map(item => (
                                        <div key={item?.id} className="mb-4">
                                            <p
                                                className={styles.observationText + ' mb-2 ' + styles.regularText}
                                                dangerouslySetInnerHTML={{
                                                    __html: item?.comment,
                                                }}></p>
                                            <div className={styles.smallFont + ' ' + styles.lightGray}>
                                                Added on {item?.created && format(new Date(item?.created), 'dd/MM/yyyy')} by {handleGetProjectName(item?.created_by, true)}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {showComment ? (
                                <>
                                    <div className="mt-5">
                                        <p htmlFor style={{ marginBottom: -36 }} className={'quilLabel ' + styles.smallFont}>
                                            Add comment
                                        </p>
                                        <ReactQuill
                                            placeholder={'Type here'}
                                            modules={{
                                                toolbar: [['bold', 'italic', 'underline'], [{ list: 'ordered' }, { list: 'bullet' }], ['link']],
                                            }}
                                            theme="snow"
                                            className="mt-1"
                                            defaultValue={comment}
                                            onChange={t => {
                                                if (t === '<p><br></p>') setComment('');
                                                else setComment(t);
                                            }}
                                        />
                                    </div>
                                    <div className="mt-4 d-flex justify-content-between align-items-center">
                                        <button
                                            onClick={handleSubmit}
                                            disabled={!comment}
                                            type="button"
                                            className={`btn btn-primary btn-sm  ${styles.responseButton} ${styles.smallButton} ${!comment && styles.disabledSaveBtn}`}>
                                            Save &nbsp; {taskCommentBtnLoading && <AiOutlineLoading3Quarters className="rotateLoader" />}
                                        </button>
                                    </div>
                                </>
                            ) : (
                                <button onClick={() => setShowComment(true)} type="button" className={`btn btn-primary btn-sm  ${styles.responseButton} ${styles.smallButton}`}>
                                    Add Comment
                                </button>
                            )}
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

export default TaskInterface;
