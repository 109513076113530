import React from 'react';
import { getExtensionIcon } from '../../utils/commonUtils';
import styles from './css/index.module.css';
import ApexCharts from 'react-apexcharts';
import ReactApexChart from 'react-apexcharts';
import { useDispatch, useSelector } from 'react-redux';
import { format, getYear, getMonth } from 'date-fns';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { GetIRLDocData } from '../../redux/documentsSlice';
import { GetDocumentUrl } from '../../redux/ndaSlice';
import { AiFillCloseCircle } from 'react-icons/ai';
import { ViewDocumentModal } from '.';
const Analytics = props => {
    const { data } = useSelector(state => state?.documents);
    const { files } = useSelector(state => state?.dataroom);
    const { user } = useSelector(state => state?.user);
    const [analyticFiles, setAnalyticFiles] = React.useState([]);
    const [filteredIrlData, setFilteredIrlData] = React.useState([]);
    const [showBottomComponent, setShowBottomComponent] = React.useState('barchart');
    const [uploadData, setUploadData] = React.useState({
        first: null,
        second: null,
    });

    React.useEffect(() => {
        if (files) {
            if (props?.selectedIRL) {
                let arr = [];
                let filteredData = data?.filter(doc => {
                    if (doc?.ws_folder_id === props?.selectedIRL?.id) {
                        arr.unshift(doc);
                        return doc;
                    }
                });
                setFilteredIrlData(filteredData);
                arr?.sort((objA, objB) => Number(new Date(objA?.metadata_creation_date || objA?.created)) - Number(new Date(objB?.metadata_creation_date || objB?.created)));
                arr.reverse();
                setAnalyticFiles(arr);
            } else {
                setAnalyticFiles(data);
                setFilteredIrlData(data);
            }
        }
    }, [files, props.selectedIRL, data]);

    const authorsArr = [...new Set(analyticFiles?.map(d => d?.metadata_author))];
    const handleOpenPDF = doc => {
        props.setShowPDF(true);
        props.setSelectedPDF(doc);
        window.history.pushState(null, '', '/dataroom/pdf-viewer');
    };

    React.useEffect(() => {
        const documentsWithMetadata = analyticFiles.filter(doc => doc.metadata_creation_date);
        documentsWithMetadata.sort((a, b) => new Date(a.metadata_creation_date) - new Date(b.metadata_creation_date));
        setUploadData({
            first: documentsWithMetadata[0],
            second: documentsWithMetadata[documentsWithMetadata.length - 1],
        });
    }, [analyticFiles]);
    return (
        <div>
            <div className="row m-0">
                <div className="col-sm-12 col-md-8">
                    <div className="row m-0">
                        <div className="col-sm-12 col-md-6">
                            <div style={{ height: 64 }} className={styles.blueBorderBox}>
                                <b className={styles.blueText}>
                                    First Upload - {uploadData?.first ? format(new Date(uploadData?.first?.metadata_creation_date), 'dd/MM/yyyy') : 'dd/mm/yyyy'}
                                </b>
                                {uploadData?.first && (
                                    <div role="button" className=" mt-2 text-truncate" onClick={() => handleOpenPDF(uploadData?.first)}>
                                        {getExtensionIcon(uploadData?.first?.document_name?.split('.').pop(), 15, 15)} &nbsp; {uploadData?.first?.document_name?.split('/').pop()}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div style={{ height: 64 }} className={styles.blueBorderBox}>
                                <b className={styles.blueText}>
                                    Latest Upload - {uploadData?.second ? format(new Date(uploadData?.second?.metadata_creation_date), 'dd/MM/yyyy') : 'dd/mm/yyyy'}
                                </b>
                                {uploadData?.second && (
                                    <div role="button" className=" mt-2 text-truncate" onClick={() => handleOpenPDF(uploadData?.second)}>
                                        {getExtensionIcon(uploadData?.second?.document_name?.split('.').pop(), 15, 15)} &nbsp; {uploadData?.second?.document_name?.split('/').pop()}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row ms-0 me-0 mb-0" style={{ marginTop: 12 }}>
                        <div className="col-sm-12 col-md-6">
                            <div style={{ height: 108 }} className={styles.blueBorderBox}>
                                <b className={styles.blueText}>Authors</b>
                                <div className="row m-0 mt-2">
                                    {authorsArr?.map(
                                        item =>
                                            item && (
                                                <div key={item} className="col-sm-12 col-md-6 p-0">
                                                    <span>{item}</span>
                                                </div>
                                            ),
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div style={{ height: 108 }} className={styles.blueBorderBox}>
                                <b className={styles.blueText}>Organizations</b>
                                <div className="mt-2 text-truncate">No organisation data found</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-4">
                    <div style={{ height: 184 }} className={styles.blueBorderBox}>
                        <b className={styles.blueText}>Read Count</b>
                        <div className="chart">
                            <ApexCharts
                                options={{
                                    chart: {
                                        type: 'donut',
                                    },
                                    dataLabels: {
                                        enabled: false,
                                    },
                                    labels: ['Read', 'Unread'],
                                    legend: {
                                        show: false,
                                    },
                                    tooltip: {
                                        // enabled: false,
                                    },
                                    colors: ['#006400', '#829ab1'],
                                    plotOptions: {
                                        pie: {
                                            startAngle: -90,
                                            endAngle: 90,
                                            offsetY: 10,
                                        },
                                    },
                                    grid: {
                                        padding: {
                                            bottom: -80,
                                        },
                                    },
                                }}
                                // series={[docCounts?.team_read_count, docCounts?.unread_count]}
                                series={[
                                    filteredIrlData?.filter(item => item?.read_by?.length > 0).length,
                                    filteredIrlData?.filter(item => !item?.read_by?.includes(user?.id)).length,
                                ]}
                                type="donut"
                                width={'100%'}
                                height={170}
                            />
                        </div>
                        <div className={styles.analyticPieChartText}>
                            <h5 className={styles.AnalyticsFileCount + ' mt-2 mb-1'}>{filteredIrlData?.length}</h5>
                            <p className={styles.blueText + ' mb-2 '}>Total Files</p>
                        </div>
                        <div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                                <span
                                // role={'button'}
                                //  onClick={() => setShowBottomComponent('team')}
                                >
                                    Team
                                </span>
                                <b>{filteredIrlData?.filter(item => item?.read_by?.length > 0).length}</b>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                                <span
                                //  role={'button'} onClick={() => setShowBottomComponent('user')}
                                >
                                    User
                                </span>
                                <b>{filteredIrlData?.filter(item => item?.read_by?.includes(user?.id)).length}</b>
                                {/* <b>{docCounts?.user_read_count}</b> */}
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                                <span
                                // role={'button'} onClick={() => setShowBottomComponent('unread')}
                                >
                                    Unread
                                </span>
                                <b>{filteredIrlData?.filter(item => !item?.read_by?.includes(user?.id)).length}</b>
                                {/* <b>{docCounts?.unread_count}</b> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row m-0">
                <div className="col-sm-12 p-0">
                    {showBottomComponent === 'barchart' ? (
                        <BarChart files={analyticFiles} {...props} />
                    ) : (
                        <FilesTable
                            files={analyticFiles}
                            showBottomComponent={showBottomComponent}
                            setShowBottomComponent={setShowBottomComponent}
                            filteredIrlData={filteredIrlData}
                            {...props}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

const BarChart = ({ files }) => {
    const [years, setYears] = React.useState([]);
    const [series, setSeries] = React.useState([]);
    const [selectedYearIndex, setSelectedYearIndex] = React.useState();

    React.useEffect(() => {
        const currentYear = getYear(new Date());
        let sYears = [];
        let prevYears = [];
        for (let i = currentYear; i >= currentYear - 6; i--) {
            sYears.unshift(i);
        }
        files?.map(file => {
            if (sYears[0] > getYear(new Date(file?.metadata_creation_date || file?.metadata_storage_last_modified))) {
                prevYears.push(getYear(new Date(file?.metadata_creation_date || file?.metadata_storage_last_modified)));
            }
        });
        prevYears = [...new Set(prevYears)];
        prevYears.sort();
        sYears = prevYears.concat(sYears);
        setSelectedYearIndex(sYears.findIndex(yr => yr === currentYear - 6));
        setYears(sYears);
        let dataArr = new Array(sYears.length).fill(0);
        var seriesData = [
            {
                data: [...dataArr],
                color: '#33b5e5',
                name: 'Jan',
            },
            {
                data: [...dataArr],
                color: '#33b5e5',
                name: 'Feb',
            },
            {
                data: [...dataArr],
                color: '#33b5e5',
                name: 'Mar',
            },
            {
                data: [...dataArr],
                color: '#33b5e5',
                name: 'Apr',
            },
            {
                data: [...dataArr],
                color: '#33b5e5',
                name: 'May',
            },
            {
                data: [...dataArr],
                color: '#33b5e5',
                name: 'Jun',
            },
            {
                data: [...dataArr],
                color: '#33b5e5',
                name: 'Jul',
            },
            {
                data: [...dataArr],
                color: '#33b5e5',
                name: 'Aug',
            },
            {
                data: [...dataArr],
                color: '#33b5e5',
                name: 'Sep',
            },
            {
                data: [...dataArr],
                color: '#33b5e5',
                name: 'Oct',
            },
            {
                data: [...dataArr],
                color: '#33b5e5',
                name: 'Nov',
            },
            {
                data: [...dataArr],
                color: '#33b5e5',
                name: 'Dec',
            },
        ];
        files?.forEach(file => {
            sYears?.forEach((yr, yrIndex) => {
                if (Number(yr) === getYear(new Date(file?.metadata_creation_date || file?.metadata_storage_last_modified))) {
                    seriesData[getMonth(new Date(file?.metadata_creation_date || file?.metadata_storage_last_modified))].data[yrIndex] =
                        seriesData[getMonth(new Date(file?.metadata_creation_date || file?.metadata_storage_last_modified))].data[yrIndex] + 1;
                }
            });
        });
        setSeries(seriesData);
    }, [files]);

    const handleNavigateChart = direction => {
        if (direction === 'prev') {
            if (selectedYearIndex > 0) setSelectedYearIndex(selectedYearIndex - 1);
        } else {
            const currentYear = getYear(new Date());
            const minIndex = years.findIndex(yr => yr === currentYear - 6);
            if (selectedYearIndex < years?.length && selectedYearIndex < minIndex) setSelectedYearIndex(selectedYearIndex + 1);
        }
    };

    const handleGetSeries = () => {
        return series.map(item => {
            let arr = [];
            for (let i = 0, j = 0; i < item?.data?.length; i++) {
                if (i >= selectedYearIndex && j < 7) {
                    arr.push(item?.data[i]);
                    j++;
                }
            }
            return { ...item, data: arr };
        });
    };
    const handleGetCategories = () => {
        let arr = [];
        for (let i = 0, j = 0; i < years?.length; i++) {
            if (i >= selectedYearIndex && j < 7) {
                arr.push(years[i]);
                j++;
            }
        }
        return arr;
    };

    return (
        <div className={styles.blueBorderBox + ' mt-2 ms-4 me-3'}>
            <div>
                {' '}
                <b className={styles.blueText}>Document Creation Date</b>
            </div>
            <div className="row m-0">
                <div className="col-sm-1 p-0 d-flex justify-content-center align-items-center">
                    <BsChevronLeft color={selectedYearIndex === 0 ? '#829ab1' : 'var(--Brand-Secondary)'} onClick={() => handleNavigateChart('prev')} size={20} role="button" />
                </div>
                <div className="col-sm-10 p-0">
                    <div id="chart ">
                        <ReactApexChart
                            options={{
                                chart: {
                                    type: 'bar',
                                },
                                plotOptions: {
                                    bar: {
                                        horizontal: false,
                                        columnWidth: '55%',
                                        endingShape: 'rounded',
                                    },
                                },
                                dataLabels: {
                                    enabled: false,
                                },
                                stroke: {
                                    show: true,
                                    width: 1,
                                    colors: ['transparent'],
                                },

                                xaxis: {
                                    // categories: years?.filter((yr, index) => index >= selectedYearIndex),
                                    categories: handleGetCategories(),
                                },
                                yaxis: {
                                    labels: {
                                        show: false,
                                    },
                                },
                                fill: {
                                    opacity: 1,
                                },

                                tooltip: {
                                    y: {
                                        formatter: function (val) {
                                            return val + ' Files';
                                        },
                                    },
                                },
                            }}
                            series={handleGetSeries()}
                            type="bar"
                            height={350}
                        />
                    </div>
                </div>
                <div className="col-sm-1 p-0 d-flex justify-content-center align-items-center">
                    <BsChevronRight
                        size={20}
                        onClick={() => handleNavigateChart('next')}
                        color={selectedYearIndex + 7 === years?.length ? '#829ab1' : 'var(--Brand-Secondary)'}
                        role="button"
                    />
                </div>
            </div>
        </div>
    );
};

const FilesTable = ({ setSelectedPDF, setShowPDF, setShowBottomComponent, showBottomComponent, files, filteredIrlData, workstream }) => {
    // const { data, docCounts } = useSelector(state => state?.documents);
    const { items } = useSelector(state => state?.dataroom);

    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user);

    const handleOpenPDF = doc => {
        setShowPDF(true);
        setSelectedPDF(doc);
    };

    const handleOpenSheet = async doc => {
        const isAlreadyPresent = doc?.read_by?.find(item => item === user?.id);
        const { payload, meta } = await dispatch(GetDocumentUrl(doc?.document || doc?.document_link));
        if (meta?.requestStatus === 'fulfilled') {
            window.open(payload?.data?.image, '_blank').focus();
            if (user?.types === 'Consultant' && !isAlreadyPresent) {
                dispatch(GetIRLDocData({ id: doc?.id, workstream }));
            }
        }
    };
    const handleCheckSheetExt = ext => {
        // eslint-disable-next-line default-case
        switch (ext?.toLowerCase()) {
            case 'xls':
            case 'xlsx':
            case 'csv':
            case 'ods':
                return true;
        }
        return false;
    };

    return (
        <div className="ps-4 pe-4">
            <div className={styles.tableButton}>
                {showBottomComponent === 'unread' && <> Unread Files ({filteredIrlData?.filter(item => !item?.read_by?.includes(user?.id)).length}) &nbsp;</>}
                {showBottomComponent === 'team' && <> Team Read Files ({filteredIrlData?.filter(item => item?.read_by?.length > 0).length}) &nbsp;</>}
                {showBottomComponent === 'user' && <> User Read Files ({filteredIrlData?.filter(item => item?.read_by?.includes(user?.id)).length}) &nbsp;</>}
                <AiFillCloseCircle onClick={() => setShowBottomComponent('barchart')} color="var(--Brand-Secondary)" size={13} role="button" />{' '}
            </div>
            <table className="w-100 ">
                <thead className={styles.tableHeader}>
                    <tr>
                        <td style={{ width: 80 }} className="ps-4">
                            #
                        </td>
                        <td className="">File Name</td>
                        <td className="" style={{ width: 150 }}>
                            Added on
                        </td>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    {filteredIrlData?.map(doc => {
                        if (
                            (showBottomComponent === 'unread' && !doc?.read_by?.includes(user?.id)) ||
                            (showBottomComponent === 'user' && doc?.read_by?.includes(user?.id)) ||
                            (showBottomComponent === 'team' && doc?.read_by?.length > 0)
                        ) {
                            let file_data = doc?.document || doc?.document_link;
                            let selectedFolder = items?.find(i => i?.id === doc?.ws_folder_id);
                            let file_meta = files?.find(file => file?.metadata_storage_path === file_data);
                            return (
                                <tr className={styles.tableRow}>
                                    <td className="ps-4 pt-1">{selectedFolder?.prefix + '.' + doc?.item_num + ' '}</td>
                                    <td
                                        className=""
                                        // style={{ fontWeight: !doc?.read_by?.includes(user?.id) && user?.types === 'Consultant' && 'bold' }}
                                    >
                                        <div
                                            role="button"
                                            onClick={() => (handleCheckSheetExt(file_data?.split('.').pop()) ? handleOpenSheet(doc) : handleOpenPDF(doc))}
                                            style={{ fontWeight: !doc?.read_by?.includes(user?.id) && user?.types === 'Consultant' && 'bold', height: 32 }}
                                            className="d-flex align-items-center pt-1 ">
                                            <div> {getExtensionIcon(file_data?.split('.').pop(), 15, 15)}</div>
                                            <div className="ms-2"> {doc?.document_name}</div>
                                        </div>
                                    </td>
                                    <td className="pt-1">{doc?.created && format(new Date(doc?.created), 'dd/MM/yyyy')}</td>
                                    {/* <td className="pt-1">{doc?.created && format(new Date(doc?.created), 'dd/MM/yyyy')}</td> */}

                                    <td style={{ width: 40 }}>
                                        <ViewDocumentModal workstream={workstream} doc={doc} selectedFolder={selectedFolder} file_data={file_data} file_meta={file_meta} />
                                    </td>
                                </tr>
                            );
                        }
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default Analytics;
