import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GetProjectUsers } from '../../redux/teamSlice';
import Select from 'react-select';
import styles from './css/MessageDetail.module.css';

const MessageDetail = ({ selectedReceiver, setSelectedReceiver }) => {
    const project = useSelector(state => state.projectData);
    const { projectUsersAll } = useSelector(state => state.team);
    const dispatch = useDispatch();
    const user = useSelector(state => state?.user);
    const [selectedProject, setselectedProject] = React.useState('Everyone'); //Consultants ,Investors , Management , Everyone
    const setBgcolor = type => {
        let color;
        if (type == 'Investor') {
            color = '#6f69ac';
        } else if (type == 'Consultant') {
            color = '#003149';
        } else if (type == 'Management') {
            color = '#ac69ac';
        }
        return color;
    };
    React.useEffect(() => {
        const effectAsync = async () => {
            if (project && project?.selectedProject && project?.selectedProject?.id) {
                dispatch(GetProjectUsers(project?.selectedProject?.id));
            }
        };
        effectAsync();
    }, []);

    const getProfileNameComponent = name => {
        let firstName,
            lastName,
            profileName = '',
            profileImage;

        if (name) {
            firstName = name.split(' ')[0];
            lastName = name.split(' ')[1];

            if (firstName) {
                profileName = firstName[0];
            }
            if (lastName) {
                profileName += lastName[0];
            }
        }

        profileImage = <div>{profileName ? profileName.toUpperCase() : ''}</div>;
        return profileImage;
    };

    const handleChangeProjectType = type => {
        setSelectedReceiver(projectUsersAll?.filter(item => type === 'Everyone' || item?.projectkey === type));
        setselectedProject(type);
    };

    const Option = props => {
        const {
            label,

            innerProps,
            value,
            data: { color, organisation },
        } = props;
        return (
            <div className={styles.optionWrapper} {...innerProps}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'centers',
                    }}>
                    <div style={{ backgroundColor: color }} className={styles.avatarStyle}>
                        {label ? getProfileNameComponent(label) : <div></div>}
                    </div>
                </div>
                <div className={styles.nameWrapper}>
                    <div className={styles.name}>{label}</div>
                    <div>
                        <p className={styles.position}>{organisation}</p>
                    </div>
                </div>
            </div>
        );
    };
    const closeIcon = () => {
        return <div></div>;
    };
    return (
        <div>
            <Select
                isMulti
                name="receiptents"
                options={projectUsersAll}
                className="basic-multi-select"
                classNamePrefix="select"
                value={selectedReceiver}
                onChange={e => setSelectedReceiver(e)}
                placeholder="Enter recipient or select group from below"
                components={{ Option: Option, MultiValueRemove: closeIcon }}
                // menuIsOpen={true}
            />
            <div className="mt-3">
                <span onClick={() => handleChangeProjectType('Everyone')} className={`badge  ${styles.everyoneBadge} `}>
                    Everyone
                </span>
                <span onClick={() => handleChangeProjectType('Consultants')} className={`badge  ${styles.consultantsbadge} `}>
                    Consultant Team
                </span>
                <span onClick={() => handleChangeProjectType('Management')} className={`badge ${styles.managementbadge} `}>
                    Project Team
                </span>
                <span onClick={() => handleChangeProjectType('Investors')} className={`badge ${styles.investorsbadge} `}>
                    Investment Team
                </span>
            </div>
        </div>
    );
};

export default MessageDetail;
