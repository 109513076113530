import React from 'react';
import { GetObservationsByScope } from '../../redux/meetingSlice';
import { useDispatch, useSelector } from 'react-redux';
import styles from './css/swot.module.css';
import { GetWorkstreams, GetWorkstreamsDefaults } from '../../redux/dataroomSlice';
import { ObservationViewSidebar, WorkstreamSelect } from '../Observations';
import { GetProjectUsers } from '../../redux/teamSlice';
import { htmlToPlainText } from '../../utils/commonUtils';
import { ObservationFormSidebar } from '../Observations/index';
import { useNavigate } from 'react-router';
import { BiSortAlt2 } from 'react-icons/bi';

const Main = () => {
    const dispatch = useDispatch();
    const { scopeObservations } = useSelector(state => state.meeting);
    const { selectedProject } = useSelector(state => state.projectData);
    const { workstreams } = useSelector(state => state.dataroom);
    const [selectedRow, setSelectedRow] = React.useState();
    const [viewObsModal, setViewObsModal] = React.useState(false);
    const [observationFormModal, setObservationFormModal] = React.useState(false);
    const [selectedWorkstream, setSelectedWorkstream] = React.useState();
    const [strengthsArr, setStrengthsArr] = React.useState([]);
    const [weaknessArr, setWeaknessArr] = React.useState([]);
    const [threatsArr, setThreatsArr] = React.useState([]);
    const [opportunitiesArr, setOpportunitiesArr] = React.useState([]);
    const [sortOptions, setSortOptions] = React.useState({
        strength: 'asc',
        weakness: 'asc',
        opportunity: 'asc',
        threat: 'asc',
    });
    const assessmentOrder = { RED: 1, AMBER: 2, GREEN: 3 };
    // const []
    const navigate = useNavigate();
    const handleOpenViewObservation = item => {
        setSelectedRow(item);
        setViewObsModal(true);
    };
    React.useEffect(() => {
        if (selectedProject) {
            dispatch(GetObservationsByScope('all'));
            dispatch(GetWorkstreams());
            dispatch(GetWorkstreamsDefaults());
            dispatch(GetProjectUsers());
        }
    }, [selectedProject, dispatch]);
    React.useEffect(() => {
        if (workstreams?.length > 0) {
            if (localStorage['workstream']) {
                const wsFromLocal = workstreams?.find(item => item?.id === parseInt(localStorage['workstream']));
                if (wsFromLocal) setSelectedWorkstream(wsFromLocal);
            } else setSelectedWorkstream(workstreams[0]);
        }
    }, [workstreams]);

    React.useEffect(() => {
        if (scopeObservations && selectedWorkstream) {
            let data = scopeObservations?.filter(item => item?.workstream_repr === selectedWorkstream?.name);
            data = data.sort((a, b) => {
                const assessmentA = a.assessment.toUpperCase();
                const assessmentB = b.assessment.toUpperCase();
                if (assessmentOrder[assessmentA] < assessmentOrder[assessmentB]) return -1;
                if (assessmentOrder[assessmentA] > assessmentOrder[assessmentB]) return 1;
                return 0;
            });
            let s = [];
            let w = [];
            let o = [];
            let t = [];
            data?.forEach(item => {
                if (item?.swot === 'S') s.push(item);
                if (item?.swot === 'W') w.push(item);
                if (item?.swot === 'O') o.push(item);
                if (item?.swot === 'T') t.push(item);
            });
            setStrengthsArr(s);
            setWeaknessArr(w);
            setOpportunitiesArr(o);
            setThreatsArr(t);
        }
    }, [scopeObservations, selectedWorkstream]);

    const handleSortStrengths = () => {
        // Clone the strengthsArr to avoid mutating the original state
        const sortedStrengthsArr = [...strengthsArr];
        // Determine the sorting order based on the current state
        const newSortOrder = sortOptions?.strength === 'asc' ? 'desc' : 'asc';
        // Sort the array based on the assessment order
        sortedStrengthsArr.sort((a, b) => {
            if (newSortOrder === 'asc') {
                return assessmentOrder[a.assessment] - assessmentOrder[b.assessment];
            } else {
                return assessmentOrder[b.assessment] - assessmentOrder[a.assessment];
            }
        });
        // Update the strengthsArr and sortOptions state variables
        setStrengthsArr(sortedStrengthsArr);
        setSortOptions({ ...sortOptions, strength: newSortOrder });
    };
    const handleSortWeakness = () => {
        const sortedWeaknessArr = [...weaknessArr];
        const newSortOrder = sortOptions?.weakness === 'asc' ? 'desc' : 'asc';
        sortedWeaknessArr.sort((a, b) => {
            if (newSortOrder === 'asc') {
                return assessmentOrder[a.assessment] - assessmentOrder[b.assessment];
            } else {
                return assessmentOrder[b.assessment] - assessmentOrder[a.assessment];
            }
        });
        setWeaknessArr(sortedWeaknessArr);
        setSortOptions({ ...sortOptions, weakness: newSortOrder });
    };

    const handleSortThreat = () => {
        const sortedThreatArr = [...threatsArr];
        const newSortOrder = sortOptions?.threat === 'asc' ? 'desc' : 'asc';
        sortedThreatArr.sort((a, b) => {
            if (newSortOrder === 'asc') {
                return assessmentOrder[a.assessment] - assessmentOrder[b.assessment];
            } else {
                return assessmentOrder[b.assessment] - assessmentOrder[a.assessment];
            }
        });
        setThreatsArr(sortedThreatArr);
        setSortOptions({ ...sortOptions, threat: newSortOrder });
    };

    const handleSortOpportunity = () => {
        const sortedOpportunityArr = [...opportunitiesArr];
        const newSortOrder = sortOptions?.opportunity === 'asc' ? 'desc' : 'asc';
        sortedOpportunityArr.sort((a, b) => {
            if (newSortOrder === 'asc') {
                return assessmentOrder[a.assessment] - assessmentOrder[b.assessment];
            } else {
                return assessmentOrder[b.assessment] - assessmentOrder[a.assessment];
            }
        });
        setOpportunitiesArr(sortedOpportunityArr);
        setSortOptions({ ...sortOptions, opportunity: newSortOrder });
    };

    return (
        <div>
            <div className={styles.headerSection}>
                <h5 className={styles.headerTitle}>SWOT Analysis</h5>
                <span className={styles.orangeBtnText} onClick={() => navigate('/observations')}>
                    Back to Observations
                </span>
            </div>
            <div className="row mb-3">
                <div className="col-sm-12 col-md-3">
                    <div className="d-flex align-items-center w-100 justify-content-between">
                        <div style={{ width: 250 }}>
                            {workstreams?.length > 1 && <WorkstreamSelect selectedOption={selectedWorkstream} setSelectedOption={setSelectedWorkstream} />}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-6">
                    <div className={styles.swotCard}>
                        <div className={styles.cardHeader}>
                            <span>Strengths ({strengthsArr?.length})</span>
                            <BiSortAlt2 onClick={handleSortStrengths} role="button" />
                        </div>
                        <div className={styles.cardBody}>
                            {strengthsArr?.map(item => (
                                <div className={styles.cardRow} role="button" onClick={() => handleOpenViewObservation(item)}>
                                    <p className={'text-truncate m-0 me-5 ' + styles.swotObs}>{htmlToPlainText(item?.observation || '')}</p>
                                    <div style={{ width: '100px' }}>
                                        <div
                                            className={styles.ragCircle}
                                            style={{
                                                backgroundColor:
                                                    (item?.assessment === 'RED' && '#da2a2a') ||
                                                    (item?.assessment === 'AMBER' && '#fc9c24') ||
                                                    (item?.assessment === 'GREEN' && '#006400'),
                                            }}></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={styles.swotCard}>
                        <div className={styles.cardHeader}>
                            <span>Opportunities ({opportunitiesArr?.length})</span>
                            <BiSortAlt2 onClick={handleSortOpportunity} role="button" />
                        </div>
                        <div className={styles.cardBody}>
                            {opportunitiesArr?.map(item => (
                                <div className={styles.cardRow} role="button" onClick={() => handleOpenViewObservation(item)}>
                                    <p className={'text-truncate m-0 me-5 ' + styles.swotObs}>{htmlToPlainText(item?.observation || '')}</p>
                                    <div style={{ width: '100px' }}>
                                        <div
                                            className={styles.ragCircle}
                                            style={{
                                                backgroundColor:
                                                    (item?.assessment === 'RED' && '#da2a2a') ||
                                                    (item?.assessment === 'AMBER' && '#fc9c24') ||
                                                    (item?.assessment === 'GREEN' && '#006400'),
                                            }}></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6">
                    <div className={styles.swotCard}>
                        <div className={styles.cardHeader} style={{ background: '#1278aa' }}>
                            <span>Weaknesses ({weaknessArr?.length})</span>
                            <BiSortAlt2 onClick={handleSortWeakness} role="button" />
                        </div>

                        <div className={styles.cardBody}>
                            {weaknessArr?.map(item => (
                                <div className={styles.cardRow} role="button" onClick={() => handleOpenViewObservation(item)}>
                                    <p className={'text-truncate m-0 me-5 ' + styles.swotObs}>{htmlToPlainText(item?.observation || '')}</p>
                                    <div style={{ width: '100px' }}>
                                        <div
                                            className={styles.ragCircle}
                                            style={{
                                                backgroundColor:
                                                    (item?.assessment === 'RED' && '#da2a2a') ||
                                                    (item?.assessment === 'AMBER' && '#fc9c24') ||
                                                    (item?.assessment === 'GREEN' && '#006400'),
                                            }}></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={styles.swotCard}>
                        <div className={styles.cardHeader} style={{ background: '#1278aa' }}>
                            <span>Threats ({threatsArr?.length})</span>
                            <BiSortAlt2 onClick={handleSortThreat} role="button" />
                        </div>

                        <div className={styles.cardBody}>
                            {threatsArr?.map(item => (
                                <div className={styles.cardRow} role="button" onClick={() => handleOpenViewObservation(item)}>
                                    <p className={'text-truncate m-0 me-5 ' + styles.swotObs}>{htmlToPlainText(item?.observation || '')}</p>
                                    <div style={{ width: '100px' }}>
                                        <div
                                            className={styles.ragCircle}
                                            style={{
                                                backgroundColor:
                                                    (item?.assessment === 'RED' && '#da2a2a') ||
                                                    (item?.assessment === 'AMBER' && '#fc9c24') ||
                                                    (item?.assessment === 'GREEN' && '#006400'),
                                            }}></div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <ObservationViewSidebar
                show={viewObsModal}
                setShow={setViewObsModal}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                setObservationFormModal={setObservationFormModal}
                allData={
                    (selectedRow?.swot === 'S' && strengthsArr) ||
                    (selectedRow?.swot === 'W' && weaknessArr) ||
                    (selectedRow?.swot === 'O' && opportunitiesArr) ||
                    (selectedRow?.swot === 'T' && threatsArr)
                }
            />
            <ObservationFormSidebar show={observationFormModal} setShow={setObservationFormModal} selectedObservation={selectedRow} />
        </div>
    );
};

export default Main;
