import React from 'react';
import styles from './css/MessageDetail.module.css';
import { FaReply } from 'react-icons/fa';
import { format } from 'date-fns';

const getProfileNameComponent = name => {
    let firstName,
        lastName,
        profileName = '',
        profileImage;

    if (name) {
        firstName = name.split(' ')[0];
        lastName = name.split(' ')[1];

        if (firstName) {
            profileName = firstName[0];
        }
        if (lastName) {
            profileName += lastName[0];
        }
    }

    profileImage = <div>{profileName ? profileName.toUpperCase() : ''}</div>;
    return profileImage;
};

const setBgcolor = type => {
    let color;
    if (type === 'Investor') {
        color = '#6f69ac';
    } else if (type === 'Consultant') {
        color = '#003149';
    } else if (type === 'Management') {
        color = '#ac69ac';
    }
    return color;
};
const MessageDetail = ({ data }) => {
    const [showDetail, setShowDetail] = React.useState(false);
    return (
        <>
            <div className={`${styles.threadBody} row align-items-center `} onClick={() => setShowDetail(!showDetail)}>
                <div className="col-8">
                    <div className="row align-items-center">
                        <div className="col-2">
                            {data?.sender?.is_user ? (
                                <FaReply className={styles.replyIcon} />
                            ) : (
                                <div style={{ backgroundColor: setBgcolor(data?.sender?.user_type) }} className={styles.avatarStyle}>
                                    {data?.sender?.display_name ? getProfileNameComponent(data?.sender?.display_name) : <div></div>}
                                </div>
                            )}
                        </div>
                        <div className="col-sm-12 col-md-9">
                            <div className={styles.threadDetail}>
                                <h5 className={styles.msgTitle}>{data?.sender?.is_user ? 'You' : data?.sender?.display_name}</h5>
                                <p className={`${styles.msgDescription} text-truncate`} dangerouslySetInnerHTML={{ __html: data?.content }}></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-4 ">
                    <div className="d-flex justify-content-end">
                        <p className={styles.msgTime}>{format(new Date(data?.sent_at), 'dd.MM.yyyy')}</p>
                        &nbsp; &nbsp;
                        <p className={styles.msgTime}>{format(new Date(data?.sent_at), 'kk:mm')}</p>
                    </div>
                </div>
            </div>
            {showDetail && <div className="mt-4 mb-4" dangerouslySetInnerHTML={{ __html: data?.content }}></div>}
        </>
    );
};

export default MessageDetail;
