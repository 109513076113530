import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './css/Overview.module.css';
import { FaPlusCircle } from 'react-icons/fa';
import { ImHourGlass } from 'react-icons/im';
import { FiEdit } from 'react-icons/fi';
import { useSelector, useDispatch } from 'react-redux';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { FetchLogo, GetCurrentProjectData } from '../../redux/projectDataSlice';
import { format } from 'date-fns';
import Pageheader from '../../utils/headerHelment';
import Chart from 'react-apexcharts';
import { fetchImageUrl, getExtensionIcon, processInput } from '../../utils/commonUtils';
import { GetIRLDoc } from '../../redux/documentsSlice';

const Dashboard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { selectedProject, logo } = useSelector(state => state.projectData);
    // const [projectLogo, setProjectLogo] = React.useState('');
    const { user } = useSelector(state => state.user);
    React.useEffect(() => {
        dispatch(GetCurrentProjectData());
        dispatch(GetIRLDoc());
        // dispatch(FetchLogo());
    }, []);

    const revenue = () => {
        return selectedProject?.revenue ? selectedProject?.revenue?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '--';
    };
    const handleNavigateEditScreen = () => {
        navigate('/EditProject');
    };
    const handleModifyUrl = url => {
        // Check if the URL starts with "http://" or "https://"
        if (url?.startsWith('http://') || url?.startsWith('https://')) {
            // It's already an absolute URL, so return it as is
            return url;
        } else if (url) {
            // It's a relative URL, so prepend "https://" and return
            return `https://${url}`;
        } else {
            return '';
        }
    };
    const userType = user?.types;

    // React.useEffect(() => {
    //     fetchImageUrl(selectedProject?.logo)
    //         .then(imageDataUri => {
    //             console.log('Image data URI:', imageDataUri);
    //             setProjectLogo(imageDataUri);
    //         })
    //         .catch(error => {
    //             // Handle errors
    //             console.error('Error:', error);
    //         });
    // }, [selectedProject]);

    return (
        <>
            <Pageheader title="LENS - Overview" />
            <div className={styles.viewHeight}>
                <div className="row pt-3">
                    <div className="col-sm-12 col-md-4">
                        <div className={styles.whiteCard}>
                            <FiEdit onClick={handleNavigateEditScreen} style={{ float: 'right' }} color="var(--Brand-Secondary)" />
                            <div onClick={handleNavigateEditScreen} style={{ border: selectedProject?.base_logo && 'none' }} className={styles.uploadBody}>
                                {selectedProject?.base_logo ? (
                                    <img className={styles?.logoImg} src={selectedProject?.base_logo} alt="projectLogo" />
                                ) : (
                                    <div className="text-center">
                                        <FaPlusCircle size={20} color="#dce4eb" />
                                        <p className={styles.uploadText}>Upload Logo</p>
                                    </div>
                                )}
                            </div>
                            <div className="text-center mb-4">
                                <h3 className={styles.projectName}>{selectedProject?.project_name || '--'}</h3>
                                <p className={styles.blueNormal}>{selectedProject?.investor_name || '--'}</p>
                                <a className={styles.website} target="_blank" href={selectedProject?.website} rel="noreferrer">
                                    {selectedProject?.website}
                                </a>
                            </div>
                            <div className={styles.dashedBody}>
                                <div className="d-flex justify-content-center">
                                    <div className="d-flex align-items-center">
                                        <ImHourGlass size={28} color="#33b5e5" />
                                        <div style={{ lineHeight: '16px' }} className="ms-3">
                                            <b className={styles.blueNormal + ' m-0'}>
                                                {selectedProject?.project_deadline ? format(new Date(selectedProject?.project_deadline), 'd LLLL, yyyy') : '-- '}
                                            </b>
                                            <p className={styles.smallGray + ' m-0'}>
                                                {' '}
                                                {selectedProject?.project_deadline ? formatDistanceToNow(new Date(selectedProject?.project_deadline), { addSuffix: true }) : '--'}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.dashedBody}>
                                <p className={styles.blueTitle}>Sector </p>
                                <span className={styles.detailTxt}>{processInput(selectedProject?.sector)}</span>
                            </div>
                            <div className={styles.dashedBody}>
                                <div className="row">
                                    <div className="col-sm-12 col-md-6">
                                        <p className={styles.blueTitle}>Business Type </p>
                                        <span className={styles.detailTxt}>{processInput(selectedProject?.business_type)}</span>
                                    </div>
                                    <div className="col-sm-12 col-md-6">
                                        <p className={styles.blueTitle}>Established In </p>
                                        <span className={styles.detailTxt}>
                                            {selectedProject?.established_in ? format(new Date(selectedProject?.established_in), 'dd/MM/yyyy') : '--'}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.dashedBody}>
                                <div className="row">
                                    <div className="col-sm-12 col-md-6">
                                        <p className={styles.blueTitle}>Company Size </p>
                                        <span className={styles.detailTxt}>{processInput(selectedProject?.employees)}</span>
                                    </div>
                                    <div className="col-sm-12 col-md-6">
                                        <p className={styles.blueTitle}>Revenue </p>
                                        <span className={styles.detailTxt}>
                                            {' '}
                                            {selectedProject?.currency}
                                            {revenue()}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.dashedBody}>
                                <p className={styles.blueTitle}>Company ID </p>
                                <span
                                    className={styles.detailTxt}
                                    onClick={() => window.open(`https://find-and-update.company-information.service.gov.uk/company/${selectedProject?.companieshouse}`, '_blank')}>
                                    {processInput(selectedProject?.companieshouse)}
                                </span>
                            </div>
                            <div className={styles.dashedBody}>
                                <p className={styles.blueTitle}>Project Description</p>
                                <p
                                    className={styles.paraText}
                                    dangerouslySetInnerHTML={{
                                        __html: selectedProject?.description?.replace(/\n/g, '<br />'),
                                    }}></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-8">
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                {' '}
                                <div className={styles.whiteCardSmall} style={{ height: 200 }}>
                                    <div className="row">
                                        <div className="col-sm-12 col-md-6">
                                            <b onClick={() => navigate('/tasks')} role="button">
                                                Tasks
                                            </b>
                                            <div className=" mt-2">
                                                <div className="d-flex mb-2 align-items-center">
                                                    <div className={styles.roundBadge}>{selectedProject?.projectprogress?.tasks_completed}</div>
                                                    <p className={styles.smallLabel}>Complete</p>
                                                </div>
                                                <div className="d-flex mb-2 align-items-center">
                                                    <div style={{ backgroundColor: '#1278aa' }} className={styles.roundBadge}>
                                                        {selectedProject?.projectprogress?.tasks_outstanding}
                                                    </div>
                                                    <p className={styles.smallLabel}>Outstanding</p>
                                                </div>
                                                <div className="d-flex mb-2 align-items-center">
                                                    <div style={{ backgroundColor: '#da2a2a' }} className={styles.roundBadge}>
                                                        {selectedProject?.projectprogress?.tasks_overdue}
                                                    </div>
                                                    <p className={styles.smallLabel}>Overdue</p>
                                                </div>
                                            </div>
                                            {/* <div className={styles.grayText}>
                                                <span>Last Update</span>
                                                <br />
                                                <span>
                                                    {' '}
                                                    {selectedProject?.projectprogress?.tasks_last_updated &&
                                                        format(new Date(selectedProject?.projectprogress?.tasks_last_updated), 'dd LLL, yyyy')}
                                                </span>
                                            </div> */}
                                        </div>
                                        <div className="col-sm-12 col-md-6 position-relative">
                                            <Chart
                                                options={{
                                                    dataLabels: {
                                                        enabled: false,
                                                    },

                                                    legend: {
                                                        show: false,
                                                    },
                                                    stroke: { width: 0 },
                                                    colors: ['#003149', '#1278aa', '#da2a2a'],
                                                    labels: ['', '', ''],
                                                    plotOptions: {
                                                        pie: {
                                                            donut: {
                                                                size: '80%',
                                                            },
                                                        },
                                                    },
                                                }}
                                                series={[
                                                    selectedProject?.projectprogress?.tasks_completed,
                                                    selectedProject?.projectprogress?.tasks_outstanding,
                                                    selectedProject?.projectprogress?.tasks_overdue,
                                                ]}
                                                height={170}
                                                // width={150}
                                                // height={'100%'}
                                                type="donut"
                                            />
                                            <div className={styles.chartText}>
                                                <p className={styles.chartBoldText}>
                                                    {selectedProject?.projectprogress?.tasks_completed +
                                                        selectedProject?.projectprogress?.tasks_outstanding +
                                                        selectedProject?.projectprogress?.tasks_overdue}
                                                </p>
                                                <p className={styles.chatSubText}>Tasks</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className={styles.whiteCardSmall} style={{ height: 200 }}>
                                    <div className="row">
                                        <div className="col-sm-12 col-md-6">
                                            <b onClick={() => navigate('/question-bank')} role="button">
                                                IRL
                                            </b>
                                            <div className=" mt-3">
                                                <div className="d-flex mb-2 align-items-center">
                                                    <div className={styles.roundBadge}>{selectedProject?.irl_completed}</div>
                                                    <p className={styles.smallLabel}>Complete</p>
                                                </div>

                                                <div className="d-flex mb-2 align-items-center">
                                                    <div style={{ backgroundColor: '#da2a2a' }} className={styles.roundBadge}>
                                                        {selectedProject?.irl_outstanding}
                                                    </div>
                                                    <p className={styles.smallLabel}>Outstanding</p>
                                                </div>
                                            </div>
                                            {/* <div className={styles.grayText} style={{ marginTop: 48 }}>
                                                <span>Last Update</span>
                                                <br />
                                                <span>
                                                    {' '}
                                                    {selectedProject?.projectprogress?.irl_last_updated &&
                                                        format(new Date(selectedProject?.projectprogress?.irl_last_updated), 'dd LLL, yyyy')}
                                                </span>
                                            </div> */}
                                        </div>
                                        <div className="col-sm-12 col-md-6 position-relative">
                                            <Chart
                                                options={{
                                                    dataLabels: {
                                                        enabled: false,
                                                    },

                                                    legend: {
                                                        show: false,
                                                    },
                                                    stroke: { width: 0 },
                                                    colors: ['#003149', '#da2a2a'],
                                                    labels: ['', '', ''],
                                                    plotOptions: {
                                                        pie: {
                                                            donut: {
                                                                size: '80%',
                                                            },
                                                        },
                                                    },
                                                }}
                                                series={[selectedProject?.irl_completed, selectedProject?.irl_outstanding]}
                                                height={170}
                                                // width={150}
                                                // height={'100%'}
                                                type="donut"
                                            />
                                            <div className={styles.chartText}>
                                                <p className={styles.chartBoldText}>{selectedProject?.irl_completed + selectedProject?.irl_outstanding || 0}</p>
                                                <p className={styles.chatSubText}>Requests</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`col-sm-12 col-md-${userType === 'Management' ? 12 : 6}`}>
                                <div className={styles.whiteCardSmall} style={{ height: userType === 'Management' ? 622 : 304 }}>
                                    <b onClick={() => navigate('/dataroom')} role="button">
                                        Dataroom
                                    </b>
                                    <p className={styles.blueTitle}>Total files uploaded to date: {selectedProject?.vdr?.documents_count} </p>
                                    <div className="mt-5">
                                        <b>
                                            <p className={styles.blueTitle}>Latest Uploads</p>
                                        </b>
                                        {selectedProject?.vdr?.documents_latest?.map(item => (
                                            <div
                                                onClick={() =>
                                                    navigate('/dataroom', {
                                                        state: {
                                                            doc: {
                                                                ...item,
                                                                document_name: item?.name,
                                                                document: item?.url,
                                                            },
                                                        },
                                                    })
                                                }
                                                role="button"
                                                className={styles.filesRow}>
                                                <div className="d-flex w-75 align-items-center">
                                                    {getExtensionIcon(item?.name?.split('.').pop(), 14, 14)}
                                                    &nbsp;
                                                    <p className={styles.rowItem + ' text-truncate mb-0'}>{item?.name}</p>
                                                </div>
                                                <p className={styles.rowItem}>{item?.created && format(new Date(item?.created), 'dd/MM/yyyy')}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                {userType !== 'Management' && (
                                    <div className={styles.whiteCardSmall} style={{ height: 304 }}>
                                        <b onClick={() => navigate('/observations')} role="button">
                                            Observations
                                        </b>
                                        <p className={styles.blueTitle}>
                                            Total number to date:{' '}
                                            {selectedProject?.projectprogress?.strengths +
                                                selectedProject?.projectprogress?.weaknesses +
                                                selectedProject?.projectprogress?.opportunities +
                                                selectedProject?.projectprogress?.threats}
                                        </p>
                                        <div className="d-flex flex-wrap mt-4">
                                            <div className={styles.priorityBadge} style={{ background: '#da2a2a' }}>
                                                {selectedProject?.projectprogress?.red_rags}
                                            </div>
                                            <div className={styles.priorityBadge} style={{ background: '#fc9c24' }}>
                                                {selectedProject?.projectprogress?.amber_rags}
                                            </div>
                                            <div className={styles.priorityBadge} style={{ background: '#006400' }}>
                                                {selectedProject?.projectprogress?.green_rags}
                                            </div>
                                        </div>
                                        <div className="d-flex flex-wrap mt-1">
                                            <div className={styles.swotBadge + ' ' + styles.SBadge}>{selectedProject?.projectprogress?.strengths}</div>
                                            <div className={styles.swotBadge + ' ' + styles.WBadge}>{selectedProject?.projectprogress?.weaknesses}</div>
                                            <div className={styles.swotBadge + ' ' + styles.OBadge}>{selectedProject?.projectprogress?.opportunities}</div>
                                            <div className={styles.swotBadge + ' ' + styles.TBadge}>{selectedProject?.projectprogress?.threats}</div>
                                        </div>
                                        {/* <div className={styles.grayText}>
                                            <span>Last Update</span>
                                            <br />
                                            <span>
                                                {' '}
                                                {selectedProject?.projectprogress?.observations_last_updated &&
                                                    format(new Date(selectedProject?.projectprogress?.observations_last_updated), 'dd LLL, yyyy')}
                                            </span>
                                        </div> */}
                                    </div>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                {' '}
                                {userType !== 'Management' && (
                                    <div className={styles.whiteCardSmall} style={{ height: 300 }}>
                                        <p className={styles.blueTitle}>Leadership Team Profiles </p>
                                        <div className="d-flex flex-wrap mt-2">
                                            <div
                                                className={styles.linkChip}
                                                style={{ backgroundColor: selectedProject?.ceo && selectedProject?.ceo !== 'NONE' && 'var(--primary)' }}>
                                                <a style={{ color: 'white' }} href={handleModifyUrl(selectedProject?.ceo)} target="_blank" rel="noreferrer">
                                                    CEO
                                                </a>
                                            </div>
                                            <div
                                                className={styles.linkChip}
                                                style={{ backgroundColor: selectedProject?.coo && selectedProject?.coo !== 'NONE' && 'var(--primary)' }}>
                                                <a style={{ color: 'white' }} href={handleModifyUrl(selectedProject?.coo)} target="_blank" rel="noreferrer">
                                                    COO
                                                </a>
                                            </div>
                                            <div
                                                className={styles.linkChip}
                                                style={{ backgroundColor: selectedProject?.cto && selectedProject?.cto !== 'NONE' && 'var(--primary)' }}>
                                                <a style={{ color: 'white' }} href={handleModifyUrl(selectedProject?.cto)} target="_blank" rel="noreferrer">
                                                    CTO/CIO
                                                </a>
                                            </div>
                                            <div
                                                className={styles.linkChip}
                                                style={{ backgroundColor: selectedProject?.cpo && selectedProject?.cpo !== 'NONE' && 'var(--primary)' }}>
                                                <a style={{ color: 'white' }} href={handleModifyUrl(selectedProject?.cpo)} target="_blank" rel="noreferrer">
                                                    CPO
                                                </a>
                                            </div>
                                        </div>
                                        <div className={styles.dashedLine + ' mt-3 mb-3'} />
                                        <p className={styles.blueTitle}>External Data </p>
                                        <div className="d-flex flex-wrap mt-2">
                                            <div
                                                className={styles.linkChip}
                                                style={{ backgroundColor: selectedProject?.linkedin && selectedProject?.linkedin !== 'NONE' && 'var(--primary)' }}>
                                                <a style={{ color: 'white' }} href={handleModifyUrl(selectedProject?.linkedin)} target="_blank" rel="noreferrer">
                                                    LinkedIn
                                                </a>
                                            </div>
                                            <div
                                                className={styles.linkChip}
                                                style={{ backgroundColor: selectedProject?.crunchbase && selectedProject?.crunchbase !== 'NONE' && 'var(--primary)' }}>
                                                <a style={{ color: 'white' }} href={handleModifyUrl(selectedProject?.crunchbase)} target="_blank" rel="noreferrer">
                                                    Crunchbase
                                                </a>
                                            </div>
                                            <div
                                                className={styles.linkChip}
                                                style={{ backgroundColor: selectedProject?.glassdoor && selectedProject?.glassdoor !== 'NONE' && 'var(--primary)' }}>
                                                <a style={{ color: 'white' }} href={handleModifyUrl(selectedProject?.glassdoor)} target="_blank" rel="noreferrer">
                                                    Glassdoor
                                                </a>
                                            </div>
                                            <div
                                                className={styles.linkChip}
                                                style={{ backgroundColor: selectedProject?.endole && selectedProject?.endole !== 'NONE' && 'var(--primary)' }}>
                                                <a style={{ color: 'white' }} href={handleModifyUrl(selectedProject?.endole)} target="_blank" rel="noreferrer">
                                                    Endole
                                                </a>
                                            </div>
                                            <div
                                                className={styles.linkChip}
                                                style={{ backgroundColor: selectedProject?.owler && selectedProject?.owler !== 'NONE' && 'var(--primary)' }}>
                                                <a style={{ color: 'white' }} href={handleModifyUrl(selectedProject?.owler)} target="_blank" rel="noreferrer">
                                                    Owler
                                                </a>
                                            </div>
                                            <div
                                                className={styles.linkChip}
                                                style={{ backgroundColor: selectedProject?.similarweb && selectedProject?.similarweb !== 'NONE' && 'var(--primary)' }}>
                                                <a style={{ color: 'white' }} href={handleModifyUrl(selectedProject?.similarweb)} target="_blank" rel="noreferrer">
                                                    SimilarWeb
                                                </a>
                                            </div>
                                            <div
                                                className={styles.linkChip}
                                                style={{ backgroundColor: selectedProject?.builtwith && selectedProject?.builtwith !== 'NONE' && 'var(--primary)' }}>
                                                <a style={{ color: 'white' }} href={handleModifyUrl(selectedProject?.builtwith)} target="_blank" rel="noreferrer">
                                                    BuiltWith
                                                </a>
                                            </div>
                                            <div
                                                className={styles.linkChip}
                                                style={{ backgroundColor: selectedProject?.securitytrails && selectedProject?.securitytrails !== 'NONE' && 'var(--primary)' }}>
                                                <a style={{ color: 'white' }} href={handleModifyUrl(selectedProject?.securitytrails)} target="_blank" rel="noreferrer">
                                                    SecurityTrails
                                                </a>
                                            </div>
                                            <div
                                                className={styles.linkChip}
                                                style={{ backgroundColor: selectedProject?.gtmetrix && selectedProject?.gtmetrix !== 'NONE' && 'var(--primary)' }}>
                                                <a style={{ color: 'white' }} href={handleModifyUrl(selectedProject?.gtmetrix)} target="_blank" rel="noreferrer">
                                                    GTMetrix
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                {userType !== 'Management' && (
                                    <div className={styles.whiteCardSmall} style={{ height: 300 }}>
                                        <p className={styles.blueTitle}>Key Competitors </p>
                                        <br />
                                        {selectedProject?.competitors?.length > 0 &&
                                            selectedProject?.competitors?.map(comp =>
                                                comp
                                                    ?.replaceAll('<li>', '')
                                                    .replaceAll('</li>', ',')
                                                    .replaceAll('<ol>', '')
                                                    .replaceAll('</ol>', '')
                                                    .split(',')
                                                    .map(
                                                        (url, i) =>
                                                            url && (
                                                                <p className={styles.paraText}>
                                                                    {' '}
                                                                    <span> {i + 1}.</span>&nbsp;
                                                                    <span className={styles.linkText} dangerouslySetInnerHTML={{ __html: url }}></span>
                                                                </p>
                                                            ),
                                                    ),
                                            )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
