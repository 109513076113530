//Base URL of API

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const AZ_SEARCH = process.env.REACT_APP_AZURE_SEARCH;

//Authentication & Registeration Endpoints

export const LOGIN = 'accounts/login/';
export const REGISTERUSER = 'accounts/newuser/'; //old
export const REGISTER_USER = 'accounts/register/'; //new
export const RESEND_ACTIVATION_LINK = 'accounts/auth/users/resend_activation/';
export const ACTIVATE_ACCOUNT = 'accounts/auth/users/activation/';
export const FORGOT_PASSWORD = 'accounts/users/reset_password/';
export const CHANGE_PASSWORD = 'accounts/users/reset_password_confirm/';
export const TOKEN_REFRESH = 'accounts/auth/login/refresh/';
export const REQUEST_INVITE = 'accounts/invitations/request_new_account/';
// export const TOKEN_REFRESH = 'accounts/login/refresh/';
export const DOC_PROJECT = 'projects/project/';
export const PROJECT_ADD_INVESTOR = '/projects/projects_investor/';
export const NDA_DOC = 'dataroom/documents_NDA/';
export const IRL_DOC = '/dataroom/documents_IRL/';
export const SAS_API = 'dataroom/sasAPI/';
export const NOTIFICATIONS = 'accounts/inbox/notifications/get_all';
export const REMOVE_NOTIFICATION = '/accounts/inbox/notifications/delete_one/';
export const READ_NOTIFICATION = '/accounts/inbox/notifications/read_one/';
export const PROJECTSCOPE = 'projectscopes/projectscope/';
export const USERMINFO = '/accounts/userinfo/';
export const PS_ITEMS_LIST = 'projectscopes/projectscopes_items_list';
export const PS_ITEMS = 'projectscopes/projectscopes_items/';
export const IRL_ITEMS = 'dataroom/workstream_folders/';
export const DATAROOM_WORKSTREAM = '/dataroom/workstream/';
export const ALERTS = 'projects/project/projects_alerts/';
export const NOTES = '/tools/notepad/';
export const NOTE = '/tools/notes/';
export const DEFAULT_QUESTION_BANK = '/defaults/default_question_bank/';
export const DEFAULT_WORKSTREAMS = '/defaults/default_workstreams';
export const DEFAULT_FOLDERS = '/defaults/default_ws_folders/';

// export const ALERTS = 'projects/alertsAPI/';

//Project_Propposal
export const PROJECT_PROPOSAL = '/projectscopes/proposal/';
//Journal
export const SCHEDULER_JOURNAL = '/scheduler/journal/';
export const SCHEDULER_JOURNAL_ENTRY = '/scheduler/journal_entry/';
export const SCHEDULER_JOURNAL_ADD_OBSERVATION = '/scheduler/entry_observation/';
export const SCHEDULER_JOURNAL_MANAGEMENT_RES = '/scheduler/question_response/'; // doesn't exist

//questionBank
export const SCHEDULER_QUESTION_BANK = '/scheduler/question_bank/'; // doesn't exist
export const ADD_MGMT_RESPONSE = '/scheduler/question_bank_bulk/'; // doesn't exist

// 2FA
export const AUTH_QR_CODE = 'accounts/auth/app/activate/';
export const AUTH_SMS = 'accounts/auth/sms_twilio/activate/';
export const AUTH_CODE_CONFIRMATION = '/accounts/auth/app/activate/confirm/';
export const AUTH_CODE_CONFIRMATION_MOB = 'accounts/auth/sms_twilio/activate/confirm/';
export const AUTH_LOGIN_CODE = '/accounts/auth/login/code/';

//Messages
export const GET_INBOX_MESSAGES = '/messages-drf/inbox/';
export const GET_THREAD_REPLIES = '/messages-drf/message/thread/';
export const POST_SEND_REPLY = '/messages-drf/message/thread/';
export const POST_SEND_NEW_MESSAGE = '/messages-drf/message/thread/';

//NDA

export const GET_DOCUMENT_NDA = '/dataroom/documents_NDA/?project_id=';
export const GET_DOCUMENT_URL = '/dataroom/sasAPI/?ProjectImg=';
export const GET_NDA_SIGNEES = '/dataroom/NDASigneesAPI/';
export const GET_PROJECT_CONSULTANT = '/projects/projects_consultant/';

//Meeting
export const GET_SCHEDULE_DATA = '/scheduler/meeting_schedule/';
export const MEETINGS_LIST = '/scheduler/meeting_item/';

export const ADD_ATTENDEE = '/scheduler/meeting_attendee/';
export const DELETE_ATTENDEE = '/scheduler/meeting_attendee/';
export const GET_ATTENDEES = '/scheduler/meeting_attendee/';

//ProjectScope
export const GET_PROJECT_DELEIVERABLES = '/projectscopes/theme_deliverables/';
export const GET_PROJECT_SCOPE_ITEMS = '/projectscopes/scope_items/';
export const GET_PROJECT_SCOPE_THEMES = '/projectscopes/ps_theme/';
export const DEFAULT_THEMES = '/defaults/default_themes/';

//Observations
export const SCHEDULER_OBSERVATIONS = '/tools/observations/';
export const SCHEDULER_OBSERVATION = '/tools/observation/';
export const SCHEDULER_OBSERVATIONS_TYPE = '/tools/observation_type/';
export const SCHEDULER_OBSERVATIONS_ALL = '/tools/observations_all/';

//Teams
export const INVITE_TEAM = '/dataroom/vdr_invitations/';
export const SEND_TEAM_EMAIL = '/accounts/invitations/create-send-invite/';
export const USER_INVITE = '/accounts/invitations/';
export const PROJECT_USERS_PERMISSIONS = '/projects/project_permissions/';
export const DATAROOM_GROUPS = '/dataroom/groups/';
export const GROUPS = '/dataroom/vdr_group/';
export const GROUPS_PERMISSIONS = '/dataroom/vdr_permissions/';
export const GROUPS_MEMBER_PERMISSIONS = '/dataroom/vdr_group_members/';
export const GROUP_MEMBER = '/dataroom/group_member/';

//Reports
export const GET_REPORT_INFO = '/reports/report_info/';
export const GET_REPORT_SCOPE_OBSERVATION = '/reports/scope_observation/';
export const GET_REPORT_THEME_OBSERVATION = '/reports/theme_observation/';
export const SCOPE_REPORT = '/reports/scope_report/';
export const THEME_REPORT = '/reports/theme_report/';
export const SWOT_REPORT = '/reports/update_swot/';
export const REMEDIATION_REPORT = '/reports/swot_remediation/';
export const PROJECT_REPORT = '/reports/project_report/';
export const CONSULTANT_PERSPECTIVE_REPORT = '/reports/consultant_perspective/';
export const UPDATE_PERSPECTIVE_REPORT = '/reports/update_perspectives/';

export const MNMGT_SUMMARY_REPORT = '/reports/management_summary/';
export const ASSESS_RESULT_REPORT = '/reports/assess_results/';
export const ASSESS_FINDINGS_REPORT = '/reports/assess_findings/';
export const SWOT_ANALYSIS_REPORT = '/reports/swot_item/';
export const HUNDRED_DAY_PLAN_REPORT = '/reports/hundred_dayplan/';
export const REPORTS_REPORT = '/reports/report/';

//Tasks interface APIS
export const TASK_INTERFACE = '/tools/tasks/';
export const DELETE_BULK_TASK = '/tools/tasks_bulk/';
export const TASK_INTERFACE_COMMENTS = '/tools/task_comments/';

export const INFORMATION_REQUESTS = '/dataroom/information_request/';
export const INFORMATION_REQUESTS_DELETE = '/dataroom/information_request_bulk/';
export const INFORMATION_RESPONSE = '/dataroom/irresponse/';
export const MANAGEMENT_QUESTION = '/dataroom/management_question/';
export const MANAGEMENT_RESPONSE = '/dataroom/management_response/';
export const DELETE_MANAGEMENT_RESPONSE = '/dataroom/management_question_bulk/';
export const BULK_INFO_REQ = '/dataroom/information_request_bulk/';
export const STOCK_REQUEST = '/defaults/default_irl/';
export const IRL_COMMENT = '/dataroom/irl_comment/';

//Wizzard
export const WIZARD = 'dataroom/readiness_wizard/';
// Maturity Index
export const MATURITY_INDEX = 'tools/maturity_index/';
// Security Index
export const SECURITY_INDEX = 'tools/securtiy_index/';
export const SECURITY_GOVERNANCE = 'tools/governance_index/';
export const SECURITY_GOVERNANCE_RESPONSE = 'tools/governance_index_response/';

//Team Analyser
export const COMPANY_VALUES = 'tools/company_values/';
export const TEAM_MEMBER_ANALYSIS = 'tools/team_member_analysis/';

//CoreSignal
export const COMPANY_DETAIL = 'tools/company_automation/get_info/?company_id=';
export const COMPANY_SEARCH = 'tools/company_automation/search/?website_url=';
// Copilot IRL
export const IRLCoPilot = '/tools/gpt_automation/';

//Software Inventory
export const SOFTWARE_INVENTORY = '/tools/software_inventory/';
