import Header from '../components/CommonComponents/Header';
import ProjectFlow from '../components/ProjectFlow';
import Pageheader from '../utils/headerHelment';

const ProjectDetailsPage = () => {
    return (
        <>
            <Pageheader title="LENS - Project Details" />
            <Header hideProjectItems={true} />
            <ProjectFlow />
        </>
    );
};

export default ProjectDetailsPage;
