import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GET_INBOX_MESSAGES, GET_THREAD_REPLIES, POST_SEND_REPLY, POST_SEND_NEW_MESSAGE } from '../apiServices/api_routes';
import axiosInstance from '../apiServices/axios';
import { setBgcolor } from '../utils/commonUtils';

export const GetInboxMessages = createAsyncThunk('GET_INBOX_MESSAGES', async () => {
    const data = await axiosInstance.get(GET_INBOX_MESSAGES);
    return data;
});
export const GetThreadReplies = createAsyncThunk('GET_THREAD_REPLIES', async id => {
    const data = await axiosInstance.get(GET_THREAD_REPLIES + id);
    return data;
});
export const SendThreadReply = createAsyncThunk('POST_SEND_REPLY', async ({ body, threadId, toUserID }) => {
    const data = await axiosInstance.post(`${POST_SEND_REPLY}${threadId}/${toUserID}/send/`, body);
    return data;
});
export const SendNewMessage = createAsyncThunk('POST_SEND_NEW_MESSAGE', async ({ body, toUserID }) => {
    const data = await axiosInstance.post(`${POST_SEND_NEW_MESSAGE}${toUserID}/send/`, body);
    return data;
});

export const messagesSlice = createSlice({
    name: 'messagesSlice',
    initialState: {
        status: null,
        inboxMessages: [],
        selectedThreadId: null,
        unreadCount: 0,
        isNewMessage: false,
        threadReplies: [],
    },
    reducers: {
        setMessages: (state, action) => {
            return { ...state, messages: action.payload };
        },
        setMessageId: (state, action) => {
            return { ...state, selectedThreadId: action.payload };
        },
        setUnreadCount: (state, action) => {
            return { ...state, unreadCount: action.payload };
        },
        setIsNewMessage: (state, action) => {
            return { ...state, isNewMessage: '123' };
        },
    },
    extraReducers: {
        //GetInboxMessages

        [GetInboxMessages.pending]: (state, action) => {
            state.status = 'loading';
        },
        [GetInboxMessages.fulfilled]: (state, action) => {
            state.status = 'success';
            state.inboxMessages = action.payload?.data?.results;
            state.unreadCount = action.payload?.data?.results?.reduce((prev, next) => {
                return prev + next?.total_unread;
            }, 0);
            state.isNewMessage = action.payload?.data?.results && action.payload?.data?.results?.length === 0 ? true : false;
        },
        [GetInboxMessages.rejected]: (state, action) => {
            state.status = 'rejected';
        },

        //GetThreadReplies
        [GetThreadReplies.pending]: (state, action) => {
            state.status = 'loading';
        },
        [GetThreadReplies.fulfilled]: (state, action) => {
            state.status = 'success';
            state.threadReplies = action.payload?.data?.messages;
        },
        [GetThreadReplies.rejected]: (state, action) => {
            state.status = 'rejected';
        },

        //SendThreadReply
        [SendThreadReply.pending]: (state, action) => {
            state.status = 'loading';
        },
        [SendThreadReply.fulfilled]: (state, action) => {
            state.status = 'success';
        },
        [SendThreadReply.rejected]: (state, action) => {
            state.status = 'rejected';
        },
        //SendNewMessage
        [SendNewMessage.pending]: (state, action) => {
            state.status = 'loading';
        },
        [SendNewMessage.fulfilled]: (state, action) => {
            state.status = 'success';
        },
        [SendNewMessage.rejected]: (state, action) => {
            state.status = 'rejected';
        },
    },
});

export const { setMessages, setMessageId, setUnreadCount, setIsNewMessage } = messagesSlice.actions;
export default messagesSlice.reducer;
