import axiosInstance from '../apiServices/axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GET_DOCUMENT_NDA, GET_DOCUMENT_URL, GET_NDA_SIGNEES, GET_PROJECT_CONSULTANT, NDA_DOC } from '../apiServices/api_routes';
import { toast } from 'react-toastify';
import store from './store';

export const GetDocumentNDA = createAsyncThunk('GET_DOCUMENT_NDA', async id => {
    const data = await axiosInstance.get(GET_DOCUMENT_NDA + id);
    return data;
});
export const GetDocumentUrl = createAsyncThunk('GET_DOCUMENT_URL', async url => {
    const data = await axiosInstance.post(GET_DOCUMENT_URL + url);
    return data;
});

export const GetProjectConsultant = createAsyncThunk('GET_PROJECT_CONSULTANT', async () => {
    const data = await axiosInstance.get(GET_PROJECT_CONSULTANT + localStorage['project_id'] + '/');
    return data;
});

export const ndaSlice = createSlice({
    name: 'ndaSlice',
    initialState: {
        ndaData: null,
        status: null,
        documentNda: [],
        pdfUrl: null,
        signeesData: [],
        ndaButtonLoading: false,
    },
    reducers: {
        setNDAData: (state, action) => {
            return { ...state, ndaData: action.payload };
        },
        resetNdaData: (state, action) => {
            return { ndaData: null, status: null, documentNda: [], pdfUrl: null, signeesData: [], ndaButtonLoading: false };
        },
    },
    extraReducers: {
        //GetDocumentNDA
        [GetDocumentNDA.pending]: (state, action) => {
            state.status = 'loading';
        },
        [GetDocumentNDA.fulfilled]: (state, action) => {
            state.status = 'success';
            state.documentNda = action.payload?.data;
        },
        [GetDocumentNDA.rejected]: (state, action) => {
            state.status = 'rejected';
        },
        //GetDocumentUrl
        [GetDocumentUrl.pending]: (state, action) => {
            state.status = 'loading';
            state.pdfUrl = null;
        },
        [GetDocumentUrl.fulfilled]: (state, action) => {
            state.status = 'success';
            state.pdfUrl = action.payload?.data?.image;
        },
        [GetDocumentUrl.rejected]: (state, action) => {
            state.status = 'rejected';
            state.pdfUrl = null;
        },

        //GetProjectConsultant
        [GetProjectConsultant.pending]: (state, action) => {
            state.status = 'loading';
        },
        [GetProjectConsultant.fulfilled]: (state, action) => {
            state.status = 'success';

            if (action.payload?.data?.length > 0) {
                let data = action.payload.data.find(item => item?.project == localStorage['project_id']);
                state.ndaData = data;
            }
        },
        [GetProjectConsultant.rejected]: (state, action) => {
            state.status = 'rejected';
        },
    },
});
export const { setNDAData, resetNdaData } = ndaSlice.actions;
export default ndaSlice.reducer;
