import Authenticate from '../components/Authenticate/index';
import Pageheader from '../utils/headerHelment';

const AuthenticateView = () => {
    return (
        <>
            <Pageheader title="LENS - Authenticate Account" />
            <Authenticate />
        </>
    );
};

export default AuthenticateView;
