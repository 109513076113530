import { configureStore, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import UsersSlice from './usersSlice';
import dashboardSlice from './dashboardSlice';
import dataroomSlice from './dataroomSlice';
import MessagesSlice from './messagesSlice';
import projectDataSlice from './projectDataSlice';
import documentsSlice from './documentsSlice';
import ndaSlice from './ndaSlice';
import meetingSlice from './meetingSlice';
import teamSlice from './teamSlice';
import questionBankSlice from './questionBankSlice';
import taskSlice from './taskSlice';
import wizardSlice from './wizardSlice';
import maturitySlice from './maturitySlice';
import securitySlice from './securitySlice';
import softwareSlice from './softwareSlice';

const combinedReducer = combineReducers({
    user: UsersSlice,
    projectData: projectDataSlice,
    dashboard: dashboardSlice,
    dataroom: dataroomSlice,
    documents: documentsSlice,
    messages: MessagesSlice,
    nda: ndaSlice,
    meeting: meetingSlice,
    team: teamSlice,
    questionBank: questionBankSlice,
    task: taskSlice,
    wizard: wizardSlice,
    maturity: maturitySlice,
    security: securitySlice,
    software: softwareSlice,
});
const rootReducer = (state, action) => {
    if (action.type === 'usersSlice/logout') {
        state = undefined;
    }
    return combinedReducer(state, action);
};

const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: [...getDefaultMiddleware({ serializableCheck: false })],
    devTools: process.env.NODE_ENV !== 'production',
});

export default store;
