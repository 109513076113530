import React, { useState } from 'react';
import styles from './css/index.module.css';
import { Button, Text, Input } from '@fluentui/react-components';
import { Delete16Regular, CheckmarkCircle32Filled } from '@fluentui/react-icons';
import { useDispatch, useSelector } from 'react-redux';
import wizardInitialData from './wizardData';
import { GetWizardData, updateWizardData } from '../../redux/wizardSlice';
import { GetWorkstreams, PostWSFolder, setIRLItemsRecieved } from '../../redux/dataroomSlice';
import { validExtensions } from '../../utils/misc';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { PostIRLDocuments } from '../../redux/documentsSlice';
import { GetProjectUsers } from '../../redux/teamSlice';
import { IRL_DOC } from '../../apiServices/api_routes';
import axiosInstance from '../../apiServices/axios';
import { CircularProgressbar } from 'react-circular-progressbar';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
const Wizard = () => {
    const dispatch = useDispatch();
    const { selectedProject } = useSelector(state => state.projectData);
    const { projectUsersAll } = useSelector(state => state.team);
    const { user } = useSelector(state => state.user);
    const { workstreams } = useSelector(state => state.dataroom);
    const { wizardData } = useSelector(state => state.wizard);
    const [selectedType, setSelectedType] = React.useState('preSeed');
    const [selectedChecklist, setSelectedChecklist] = React.useState('Business Overview');
    const [selectedSubFolder, setSelectedSubFolder] = React.useState();
    const [wizardScreenData, setWizardScreenData] = React.useState([]);
    const [uploadedFiles, setUploadedFiles] = React.useState([]);
    const [buttonLoading, setButtonLoading] = React.useState(false);
    const [document_link, set_document_link] = React.useState('');
    const [docName, setDocName] = React.useState('');

    const handleFileChange = e => {
        const selectedFiles = e.target.files;
        // Handle the selected files as needed
        setUploadedFiles(Array.from(selectedFiles));
    };
    const handleDrop = e => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        // Handle the dropped files as needed
        setUploadedFiles(Array.from(files));
    };
    const handleUploadFile = async () => {
        setButtonLoading(true);
        const wsName = getWorkstreamName(selectedSubFolder?.vdrLocation);
        const parentFolderName = getParentFolderName(selectedSubFolder?.vdrLocation);
        let workstreamData = workstreams?.find(item => item?.name === wsName);
        let parentFolder = null;
        let subFolder = null;
        Object.keys(wizardData?.[wsName]).forEach(parentFolderId => {
            if (wizardData?.[wsName]?.[parentFolderId]?.name === parentFolderName) parentFolder = wizardData?.[wsName]?.[parentFolderId];
        });
        if (!parentFolder) {
            let body = {
                name: parentFolderName,
                // description: data?.ws_folder_desc,
                requested: true,
                workstream: workstreamData?.id,
                description: '--',
            };
            let parentFolderRes = await dispatch(PostWSFolder(body));
            if (parentFolderRes?.type === 'POST_IRL_ITEMS/fulfilled') {
                parentFolder = parentFolderRes?.payload?.data;
            } else {
                toast.error('Something went wrong');
                setButtonLoading(false);
            }
        }
        if (parentFolder?.subfolders && !subFolder) {
            Object.keys(parentFolder?.subfolders)?.forEach(key => {
                if (parentFolder?.subfolders?.[key]?.name === selectedSubFolder?.subFolder) {
                    subFolder = {
                        id: Number(key),
                        name: parentFolder?.subfolders?.[key]?.name,
                        files: parentFolder?.subfolders?.[key]?.files,
                    };
                }
            });
        }
        if (parentFolder && !subFolder) {
            let body = {
                name: selectedSubFolder?.subFolder,
                requested: true,
                workstream: workstreamData?.id,
                description: '-- --',
                parent: parentFolder?.id,
            };
            let subFolderRes = await dispatch(PostWSFolder(body));
            console.log(subFolderRes);
            if (subFolderRes?.type === 'POST_IRL_ITEMS/fulfilled') {
                subFolder = subFolderRes?.payload?.data;
            } else {
                toast.error('Something went wrong');
                setButtonLoading(false);
            }
        }
        let newUploadedFiles = [];
        if (parentFolder && subFolder && workstreamData) {
            if (uploadedFiles?.length > 0) {
                newUploadedFiles = await handleAddFile(subFolder, workstreamData, parentFolder);
            } else if (document_link) {
                newUploadedFiles = await handleAddLinkAsFile(subFolder, workstreamData, parentFolder);
            }
        }
        let updatedWizardData = JSON.parse(JSON.stringify(wizardData));
        Object.keys(updatedWizardData)?.forEach(ws => {
            if (ws === wsName) {
                if (Object.keys(updatedWizardData[ws]).includes(String(parentFolder?.id))) {
                    Object.keys(updatedWizardData[ws])?.forEach(parentId => {
                        if (Number(parentId) === parentFolder?.id) {
                            if (Object.keys(updatedWizardData[ws][parentId].subfolders)?.includes(String(subFolder?.id))) {
                                Object.keys(updatedWizardData[ws][parentId].subfolders)?.forEach(sFId => {
                                    if (Number(sFId) === subFolder?.id) {
                                        if (updatedWizardData[ws][parentId].subfolders[sFId]?.files?.length > 0) {
                                            updatedWizardData[ws][parentId].subfolders[sFId].files = [
                                                ...updatedWizardData[ws][parentId].subfolders[sFId]?.files,
                                                ...newUploadedFiles,
                                            ];
                                        } else {
                                            updatedWizardData[ws][parentId].subfolders[sFId].files = newUploadedFiles; //updated Files
                                        }
                                    }
                                });
                            } else {
                                updatedWizardData[ws][parentId].subfolders = {
                                    ...updatedWizardData[ws][parentId].subfolders,
                                    [subFolder?.id]: {
                                        id: subFolder?.id,
                                        name: subFolder?.name,
                                        files: newUploadedFiles, //updated Files will be placed here
                                    },
                                };
                            }
                        }
                    });
                } else {
                    updatedWizardData[ws][parentFolder?.id] = {
                        id: parentFolder?.id,
                        name: parentFolder?.name,
                        subfolders: {
                            [subFolder?.id]: {
                                id: subFolder?.id,
                                name: subFolder?.name,
                                files: newUploadedFiles, //updated Files will be placed here
                            },
                        },
                    };
                }
            }
        });
        dispatch(updateWizardData(updatedWizardData));
        setSelectedSubFolder({ ...selectedSubFolder, files: [...selectedSubFolder?.files, ...newUploadedFiles] });
        setUploadedFiles([]);
        set_document_link('');
        setDocName('');

        console.log({ parentFolder, workstreamData, subFolder, newUploadedFiles });
    };

    const getWorkstreamName = string => {
        let result = string?.match(/([^\d]+)/)[1].trim();
        switch (result) {
            case 'IT':
                return 'Information Technology';
            case 'F':
                return 'Financial';
            case 'C':
                return 'Commercial';
            case 'L':
                return 'Legal';
            default:
                return '';
        }
    };
    const getParentFolderName = string => {
        const regex = /- (.+)/;

        // Use the regular expression to match and extract the business overview
        const match = string?.match(regex);

        // Check if a match is found and extract the business overview
        const parentName = match ? match[1] : null;
        return parentName;
    };
    const renderFilesCount = count => {
        if (count === 1) return '1 file';
        else return count + ' files';
    };
    const handleAddFile = async (selectedFolder, workstream, parentFolder) => {
        let newUploadedFiles = [];
        try {
            for (const item of uploadedFiles) {
                const filePromiseToast = toast.loading(`Uploading ${item.name} to ${selectedFolder?.name}`, { closeButton: true });
                const formData = new FormData();
                formData.append('document', item);
                formData.append('ws_folder', parseInt(selectedFolder?.id));
                formData.append('document_name', item.name);
                formData.append('project', selectedProject?.id);
                formData.append('vdr', selectedProject?.vdr?.id);
                if (selectedProject?.vdr?.vdr_admin !== user?.id) {
                    formData.append('upload_by', projectUsersAll?.find(projectUser => projectUser?.value?.user_id === user?.id)?.value?.group_member_id);
                }
                try {
                    let res = await dispatch(PostIRLDocuments({ body: formData, ws_id: workstream?.id }));

                    if (res?.type === 'POST_IRL_DOC/rejected') {
                        setButtonLoading(false);
                        if (res?.payload?.detail) {
                            toast.update(filePromiseToast, {
                                render: res?.payload?.detail,
                                type: 'error',
                                isLoading: false,
                                autoClose: 2000,
                            });
                        } else {
                            toast.update(filePromiseToast, {
                                render: `Uploading ${item.name} Failed`,
                                type: 'error',
                                isLoading: false,
                                autoClose: 2000,
                            });
                        }
                    } else {
                        newUploadedFiles = [...newUploadedFiles, ...res?.payload?.data];
                        // If the upload is successful, update the file-specific toast
                        toast.update(filePromiseToast, {
                            render: `${item.name} uploaded successfully`,
                            type: 'success',
                            isLoading: false,
                            autoClose: 2000,
                        });
                        setButtonLoading(false);
                    }
                } catch (uploadError) {
                    console.error(`Error uploading ${item.name}:`, uploadError);
                    setButtonLoading(false);
                    toast.update(filePromiseToast, {
                        render: `Error uploading ${item.name}`,
                        type: 'error',
                        isLoading: false, // Make sure to update the loading state in case of an error
                        autoClose: 2000,
                    });
                }
            }
        } catch (error) {
            console.error('Error adding file:', error);
            setButtonLoading(false);
        }

        return newUploadedFiles;
    };

    const handleAddLinkAsFile = async (selectedFolder, workstream, parentFolder) => {
        let newUploadedFiles = [];
        if (document_link) {
            let formData = new FormData();
            formData.append('ws_folder', selectedFolder?.id);
            formData.append('project', selectedProject?.id);
            formData.append('vdr', selectedProject?.vdr?.id);
            if (selectedProject?.vdr?.vdr_admin !== user?.id) {
                formData.append('upload_by', projectUsersAll?.find(item => item?.value?.user_id === user?.id)?.value?.group_member_id);
            }
            formData.append('document_link', document_link);
            formData.append('document_name', docName || document_link.split('/').pop());

            try {
                let res = await dispatch(PostIRLDocuments({ body: formData, ws_id: workstream?.id }));

                if (res?.type === 'POST_IRL_DOC/rejected') {
                    toast.error(res?.payload?.detail);
                    setButtonLoading(false);
                } else {
                    newUploadedFiles = [...newUploadedFiles, res?.payload?.data];

                    // If the upload is successful, update the file-specific toast
                    toast.success('Uploaded successfully');
                    setButtonLoading(false);
                }

                set_document_link('');
                setDocName('');
            } catch (error) {
                toast.error('Something went wrong');
                console.error('Error adding link as file:', error);
                setButtonLoading(false);
                set_document_link('');
                setDocName('');
            }
        }
        return newUploadedFiles;
    };
    const handleDeleteFile = file => {
        const wsName = getWorkstreamName(selectedSubFolder?.vdrLocation);
        let workstreamData = workstreams?.find(item => item?.name === wsName);
        let updatedFiles = selectedSubFolder?.files?.filter(item => item?.id !== file?.id);
        axiosInstance
            .delete(`${IRL_DOC}${localStorage['project_id']}/${workstreamData?.id}/${file?.id}/`)
            .then(res => {
                toast.success('Deleted');
                setSelectedSubFolder({ ...selectedSubFolder, files: [...updatedFiles] });
                setWizardScreenData(prevData => {
                    return prevData.map(item => {
                        if (item?.folder === selectedChecklist) {
                            return {
                                ...item,
                                subFolders: item?.subFolders?.map(sF => {
                                    if (sF?.name === selectedSubFolder?.name) {
                                        return {
                                            ...sF,
                                            files: [...updatedFiles],
                                        };
                                    }
                                    return sF;
                                }),
                            };
                        }
                        return item;
                    });
                });
            })
            .catch(err => {
                console.log({ err: err?.response });
                toast.error(err?.response?.data?.detail);
            });
    };
    const handleGetCircularProgressValue = data => {
        let filteredData = data?.subFolders?.filter(item => item[selectedType]);
        let availableValue = filteredData?.filter(item => item?.files?.length > 0)?.length;
        // Calculate the percentage
        const percentage = (Number(availableValue) / Number(filteredData?.length)) * 100;
        if (percentage === 0) {
            return <span></span>;
        }
        if (percentage > 0 && percentage < 100) {
            return (
                <CircularProgressbar
                    value={percentage}
                    styles={{
                        trail: {
                            stroke: 'var(--Neutral-Gray-3)',
                        },
                        path: {
                            stroke: '#198754',
                        },
                    }}
                />
            );
        }
        if (percentage === 100) {
            return <CheckmarkCircle32Filled className="checkListItemCompleted" color="#198754" />;
        }
    };
    const memoizedHandleGetTotalPercentage = React.useMemo(() => {
        const handleGetTotalPercentage = () => {
            let filteredFolders = wizardScreenData?.filter(item => {
                return item?.subFolders?.filter(sF => sF[selectedType])?.length > 0;
            });

            const markedItems = filteredFolders?.filter(item => {
                let emptyFiles = item?.subFolders?.find(sF => {
                    if (sF[selectedType]) {
                        return sF?.files?.length === 0;
                    }
                });
                if (!emptyFiles) {
                    return item;
                }
            });
            let percentage = 0;
            if (markedItems?.length > 0) {
                percentage = parseInt((Number(markedItems?.length) / Number(filteredFolders?.length)) * 100);
            }
            console.log({ markedItems, filteredFolders });
            switch (selectedType) {
                case 'preSeed':
                    return 'Your readiness for Pre Seed: ' + percentage + '%';
                case 'seriesA':
                    return 'Your readiness for Seed/Series A+: ' + percentage + '%';
                case 'corp':
                    return 'Your readiness for Private Equity/Corporate: ' + percentage + '%';
                default:
                    return '';
            }
        };

        return handleGetTotalPercentage;
    }, [wizardScreenData, selectedType]);

    React.useEffect(() => {
        if (selectedProject) {
            dispatch(GetWorkstreams());
            dispatch(GetWizardData());
            dispatch(GetProjectUsers(selectedProject?.id));
        }
    }, [selectedProject]);

    React.useEffect(() => {
        if (wizardData) {
            wizardScreenData?.forEach(item => {
                item?.subFolders?.map(subFolder => {
                    // console.log(subFolder);
                    let files = [];
                    const wsName = getWorkstreamName(subFolder?.vdrLocation);
                    if (wizardData?.[wsName]) {
                        Object.keys(wizardData?.[wsName])?.forEach(key => {
                            // if (wizardData?.[wsName]?.[key]?.name === item?.folder) {
                            Object?.keys(wizardData?.[wsName]?.[key]?.subfolders)?.forEach(subFolderKey => {
                                if (wizardData?.[wsName]?.[key]?.subfolders?.[subFolderKey]?.name === subFolder?.subFolder) {
                                    files = wizardData?.[wsName]?.[key]?.subfolders?.[subFolderKey]?.files;
                                }
                            });
                            // }
                        });
                    }
                    subFolder.files = files;
                });
            });
            setWizardScreenData([...wizardInitialData]);
        }
    }, [wizardData]);

    return (
        <div className={styles.viewBody}>
            <h3 className={styles.pageHeading}>Founder Readiness Wizard</h3>
            <p className={styles.pageSubHeading}>Choose your funding round and upload required info in each checklist.</p>
            <div className="row mt-4 mb-4 align-items-center">
                <div className="col-sm-12 col-md-7">
                    <div className="d-flex flex-wrap">
                        <button onClick={() => setSelectedType('preSeed')} className={styles.headerSimpleButton + ' ' + (selectedType === 'preSeed' && styles.selectedBtn)}>
                            Pre Seed
                        </button>
                        <button onClick={() => setSelectedType('seriesA')} className={styles.headerSimpleButton + ' ' + (selectedType === 'seriesA' && styles.selectedBtn)}>
                            Seed / Series A+
                        </button>
                        <button onClick={() => setSelectedType('corp')} className={styles.headerSimpleButton + ' ' + (selectedType === 'corp' && styles.selectedBtn)}>
                            Private Equity / Corporate
                        </button>
                    </div>
                </div>
                <div className="col-sm-12 col-md-5 text-end">
                    <Text as="p" font="Inter" className={styles.infoReadtText}>
                        {memoizedHandleGetTotalPercentage()}
                    </Text>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-3">
                    <div className={styles.whiteCard}>
                        <p className={styles.cardTitle}>Checklist</p>
                        {wizardScreenData?.map(item => {
                            if (item?.subFolders?.filter(sF => sF[selectedType]).length > 0) {
                                return (
                                    <div
                                        key={item?.folder}
                                        onClick={() => {
                                            setSelectedChecklist(item?.folder);
                                            setSelectedSubFolder();
                                        }}
                                        className={styles.checkListItem}
                                        style={{ background: selectedChecklist === item?.folder && (!selectedSubFolder ? 'rgba(142, 45, 226, 0.05)' : 'var(--Neutral-Gray-2)') }}>
                                        <p className="m-0">{item?.folder}</p>
                                        <div style={{ width: 32, height: 32 }}>{handleGetCircularProgressValue(item)}</div>
                                    </div>
                                );
                            }
                        })}
                    </div>
                </div>
                <div className="col-sm-12 col-md-6">
                    {' '}
                    <div className={styles.whiteCard}>
                        <p className={styles.cardTitle}>Requisite Information</p>
                        {wizardScreenData
                            ?.find(item => item?.folder === selectedChecklist)
                            ?.subFolders?.map(
                                item =>
                                    item[selectedType] && (
                                        <div
                                            style={{ background: selectedSubFolder?.name === item?.name && 'rgba(142, 45, 226, 0.05)' }}
                                            onClick={() => setSelectedSubFolder(item)}
                                            className={styles.checkListItem}
                                            key={item?.name}>
                                            <span>{item?.name}</span>
                                            <span className={styles.fileCount}>{item?.files?.length > 0 && renderFilesCount(item?.files?.length)}</span>
                                        </div>
                                    ),
                            )}
                    </div>
                </div>
                <div className="col-sm-12 col-md-3">
                    {' '}
                    <div className={styles.whiteCard}>
                        <p className={styles.cardTitle}>Upload Files</p>
                        {selectedSubFolder ? (
                            <div className={styles.fileCardBody}>
                                <div>
                                    <p className={styles.infoExample}>Information Examples</p>
                                    <ul className={styles.documentExamples}>
                                        {selectedSubFolder?.documentExamples?.map(item => (
                                            <li key={item}>{item}</li>
                                        ))}
                                    </ul>
                                </div>
                                {uploadedFiles?.length > 0 ? (
                                    <div className="mt-4 mb-4">
                                        {uploadedFiles?.map(item => (
                                            <div keu={item?.name} className={styles.fileListItem}>
                                                <span>{item?.name}</span>
                                                <Delete16Regular
                                                    onClick={() => setUploadedFiles(uploadedFiles?.filter(file => file?.name !== item?.name))}
                                                    color="var(--Neutral-Gray-5)"
                                                    role="button"
                                                />
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <UploadDiv handleFileChange={handleFileChange} handleDrop={handleDrop} />
                                )}

                                <div>
                                    <div>
                                        <p className={styles.fieldLabel}>Or Add a Link to the File</p>
                                        <Input value={document_link} onChange={e => set_document_link(e.target?.value)} className={styles.inputField} />
                                    </div>
                                    <div>
                                        <p className={styles.fieldLabel}>Add Text to Display</p>
                                        <Input value={docName} onChange={e => setDocName(e.target?.value)} className={styles.inputField} />
                                    </div>
                                    <button
                                        // disabled={buttonLoading}
                                        onClick={handleUploadFile}
                                        className={styles.saveBtn}>
                                        Save {buttonLoading && <AiOutlineLoading3Quarters className="rotateLoader" />}
                                    </button>
                                </div>
                                <div>
                                    {selectedSubFolder?.files?.length > 0 && <p className={styles.uploadFilesTitle}>Uploaded files</p>}

                                    {selectedSubFolder?.files?.map(item => (
                                        <div className={styles.fileBody}>
                                            <div>
                                                <p className={styles.fileTitle}>{item?.document_name}</p>
                                                <p className={styles.fileSubtext}>Uploaded by {item?.uploaded_by_name}</p>
                                                <p className={styles.fileSubtext}>on {item?.created && format(new Date(item?.created), 'dd/MM/yyyy hh:mm')}</p>
                                            </div>
                                            <Delete16Regular onClick={() => handleDeleteFile(item)} className={styles.deleteIcon} color="var(--Neutral-Gray-5)" />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className={styles.noFolderSelected}>Select an Item from the Requisite Information List to Upload Files</div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
const UploadDiv = ({ handleFileChange, handleDrop }) => {
    const fileInputRef = React.useRef();
    const handleDivClick = () => {
        fileInputRef.current.click();
    };
    React.useEffect(() => {
        const div = document.getElementById('uploadDiv');
        if (div) {
            div.addEventListener('dragover', e => e.preventDefault());
            div.addEventListener('drop', handleDrop);
            // div.addEventListener('paste', handlePaste);

            return () => {
                div.removeEventListener('dragover', e => e.preventDefault());
                div.removeEventListener('drop', handleDrop);
                // div.removeEventListener('paste', handlePaste);
            };
        }
    }, []);
    return (
        <div id="uploadDiv" className={styles.dragDropBody} onClick={handleDivClick}>
            <span>
                Drag & Drop Files or <br /> Click to Upload
            </span>
            <input multiple type="file" accept={validExtensions.join(',')} ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
        </div>
    );
};

export default Wizard;
