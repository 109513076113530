import SecureAccount from '../components/SecureAccount/index';
import Pageheader from '../utils/headerHelment';

const SecureAccountView = () => {
    return (
        <>
            <Pageheader title="LENS - Secure Account" />
            <SecureAccount />
        </>
    );
};

export default SecureAccountView;
