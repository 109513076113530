import React from 'react';
import Layout from '../components/Layout';
import Observations from '../components/Observations';
import Pageheader from '../utils/headerHelment';

const ObservationsView = props => {
    return (
        <Layout container={true}>
            <Pageheader title="LENS - Observations" />
            <Observations />
        </Layout>
    );
};

export default ObservationsView;
