import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../apiServices/axios';
import { IRL_DOC } from '../apiServices/api_routes';
import { toast } from 'react-toastify';
const checkStatus = Data => {
    if (Data?.length === 0) {
        return 'pending';
    }
    return 'completed';
};
export const GetIRLDoc = createAsyncThunk('IRLDOC/getIRLDoc', async id => id && (await axiosInstance.get(`${IRL_DOC}${localStorage['project_id']}/${id}`)));
export const GetPopulateDownloadBy = createAsyncThunk(
    'IRLDOC/GetPopulateDownloadBy',
    async ({ id, wsId }) => await axiosInstance.get(`${IRL_DOC}${localStorage['project_id']}/${wsId}/${id}/download`),
);

export const GetSingleIRLDoc = createAsyncThunk('IRLDOC/getSingleIRLDoc', async ({ wsId, docId }) => {
    const data = await axiosInstance.get(IRL_DOC + localStorage['project_id'] + '/' + wsId + '/' + docId);
    return data;
});
export const GetIRLDocData = createAsyncThunk('IRLDOC/getIRLDocData', async ({ id, workstream }) => {
    const data = await axiosInstance.get(IRL_DOC + localStorage['project_id'] + '/' + workstream + '/' + id + '/');
    return data;
});

export const PostIRLDocuments = createAsyncThunk('POST_IRL_DOC', async ({ body, ws_id }, { rejectWithValue }) => {
    try {
        const data = await axiosInstance.post(IRL_DOC + localStorage['project_id'] + '/' + ws_id + '/', body);
        return data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const PostFolderDocuments = createAsyncThunk('POST_FOLDER_DOC', async ({ body, ws_id }, { rejectWithValue }) => {
    try {
        const projectId = localStorage.getItem('project_id');
        const url = `${IRL_DOC}${projectId}/${ws_id}/bulk_folder_upload/`;
        const response = await axiosInstance.post(url, body);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const PatchRLDocuments = createAsyncThunk('PATCH_IRL_DOC', async body => {
    const data = await axiosInstance.patch(IRL_DOC + body?.id + '/?project_id=' + localStorage['project_id'], body);
    return data;
});
export const DeleteBulkFiles = createAsyncThunk('DELETE_BULK_IRL_DOC', async ({ ws_id, items }) => {
    const data = await axiosInstance.post(IRL_DOC + localStorage['project_id'] + '/' + ws_id + '/bulk_delete/', { items });
    return data;
});

export const documentsSlice = createSlice({
    name: 'documentsSlice',
    initialState: {
        loading: true,
        data: [],
        status: '',
        errorMessage: '',
        error: false,
        docCounts: null,
        bulkFilesLoading: false,
    },
    reducers: {
        setDocs: (state, { payload }) => {
            return { ...state, status: checkStatus(payload), data: payload };
        },
        resetDocumentData: (state, { payload }) => {
            return { loading: true, data: [], status: '', errorMessage: '', error: false, docCounts: null, bulkFilesLoading: false };
        },
        addDoc: (state, { payload }) => {
            return {
                ...state,
                status: checkStatus([payload]),
                data: [...state.data, payload],
            };
        },
    },
    extraReducers: {
        [GetIRLDoc.fulfilled]: (state, { payload }) => {
            state.data = payload?.data?.documents || [];
            state.docCounts = payload?.data?.doc_count;
            state.status = checkStatus(payload?.data);
            state.loading = false;
        },
        [GetIRLDoc.rejected]: (state, action) => {
            state.error = true;
            state.errorMessage = action?.error?.message;
            state.data = [];
            state.loading = false;
        },

        //GetIRLDocData
        [GetIRLDocData.fulfilled]: (state, { payload }) => {
            state.data = state.data?.map(item => {
                if (item?.id === payload?.data?.id) return payload?.data;
                else return item;
            });
        },
        [GetIRLDocData.rejected]: (state, action) => {
            console.log({ action });
        },

        // PostIRLDocuments
        [PostIRLDocuments.fulfilled]: (state, { payload, meta }) => {
            state.loading = false;
            let data = payload?.data?.document_link ? [payload.data] : payload?.data;
            if (state?.data && state?.data?.length > 0) state.data = [...state.data, ...data];
            else state.data = data;
        },
        [PostIRLDocuments.rejected]: (state, { payload }) => {
            // Object.values(payload).map(value => {
            //     value?.forEach(msg => toast.error(msg));
            // });
            state.error = true;
        },
        // PatchRLDocuments
        [PatchRLDocuments.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.data = state?.data?.map(item => {
                if (item?.id === payload?.data?.id) return payload?.data;
                else return item;
            });
        },
        [PatchRLDocuments.rejected]: (state, action) => {
            state.error = true;
        },
        //DeleteBulkFiles
        [DeleteBulkFiles.pending]: (state, action) => {
            state.status = 'loading';
            state.bulkFilesLoading = true;
        },
        [DeleteBulkFiles.fulfilled]: (state, action) => {
            console.log(action);
            const ids = action?.meta?.arg?.items;
            state.data = state.data.filter(item => {
                let check = ids.find(id => Number(id) === item?.id);
                if (!check) return item;
            });
            state.status = 'success';
            state.bulkFilesLoading = false;
            toast.success('Successfully Deleted');
            // console.log(payload);
        },
        [DeleteBulkFiles.rejected]: (state, action) => {
            state.status = 'rejected';
            state.bulkFilesLoading = false;
            toast.error('Failed');
        },
        // GetPopulateDownloadBy
        [GetPopulateDownloadBy.fulfilled]: (state, action) => {
            console.log({ action });
        },
        // PostFolderDocuments
        [PostFolderDocuments.fulfilled]: (state, action) => {
            if (state.data?.length > 0) {
                state.data = [...state.data, ...action?.payload];
            } else {
                state.data = action.payload;
            }
        },
    },
});

export const { setDocs, addDoc, resetDocumentData } = documentsSlice.actions;
export default documentsSlice.reducer;
