import Layout from '../components/Layout';
import IRL from '../components/IRL';
import Pageheader from '../utils/headerHelment';
import ReactJoyride from 'react-joyride';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import Cookies from 'js-cookie';
import { setJoyRideStepIndex } from '../redux/questionBankSlice';
import { AiOutlineClose } from 'react-icons/ai';

const IRLView = () => {
    const dispatch = useDispatch();
    const { joyRideStepIndex } = useSelector(state => state?.questionBank);
    const [runJoyRide, setRunJoyRide] = React.useState(false);
    const [informationRequestModal, setInformationRequestModal] = React.useState(false);
    const [joyRideSteps] = React.useState([
        {
            // Add Group Button
            target: '.irl-text',
            content: (
                <div className="ps-2 pe-2 regularFont">
                    <div className="text-end">
                        <AiOutlineClose color="var(--Brand-Secondary)" onClick={() => setRunJoyRide(false)} role="button" />
                    </div>
                    <div className="text-start">Welcome to Information Request List (IRL) Management.</div>
                    <div className="d-flex align-items-center justify-content-between mt-3">
                        <span style={{ color: 'var(--muted)' }} role="button"></span>
                        <button onClick={() => dispatch(setJoyRideStepIndex(1))} className="btn btn-primary smallButton">
                            Next
                        </button>
                    </div>
                </div>
            ),
            hideFooter: true,
            disableBeacon: true,
            disableOverlayClose: true,
            spotlightClicks: true,
        },
        {
            // Add Group Button
            target: '.add-irl-button',
            content: (
                <div className="ps-2 pe-2 regularFont">
                    <div className="text-end">
                        <AiOutlineClose color="var(--Brand-Secondary)" onClick={() => setRunJoyRide(false)} role="button" />
                    </div>
                    <div className="text-start">
                        <b style={{ color: 'var(--Brand-Secondary)' }}> Click on the above button</b> to add a new request.
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-3">
                        <span style={{ color: 'var(--muted)' }} role="button" onClick={() => dispatch(setJoyRideStepIndex(0))}>
                            Previous
                        </span>
                    </div>
                </div>
            ),
            hideFooter: true,
            disableBeacon: true,
            disableOverlayClose: true,
            spotlightClicks: true,
        },
        {
            // Add Group ADDBTN
            target: '.sidebar-addBtn-step',
            content: (
                <div className="ps-2 pe-2 regularFont">
                    <div className="text-end">
                        <AiOutlineClose color="var(--Brand-Secondary)" onClick={() => setRunJoyRide(false)} role="button" />
                    </div>
                    <div className="text-start">
                        Let us add your first request. Select the respective Work Stream and folder first to add a new request. You can also link a file in the Data Room for
                        reference. After you set the priority for the request <b style={{ color: 'var(--Brand-Secondary)' }}>click on the above button to save</b> the request to
                        the IRL.
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-3">
                        <span
                            style={{ color: 'var(--muted)' }}
                            role="button"
                            onClick={() => {
                                setInformationRequestModal(false);
                                dispatch(setJoyRideStepIndex(1));
                            }}>
                            Previous
                        </span>
                    </div>
                </div>
            ),
            hideFooter: true,
            disableBeacon: true,
            disableOverlayClose: true,
            spotlightClicks: true,
            disableOverlay: true,

            styles: {
                options: {
                    zIndex: 10000,
                    // width: 900,
                },
            },
        },
        {
            // Add Group ADDBTN
            target: '.table-step',
            content: (
                <div className="ps-2 pe-2 regularFont">
                    <div className="text-end">
                        <AiOutlineClose color="var(--Brand-Secondary)" onClick={() => setRunJoyRide(false)} role="button" />
                    </div>
                    <div className="text-start">
                        All Information Requests will be listed here. You can respond to a request by clicking on it and adding your response in the panel. You can also link
                        supporting documents to substantiate your answer.
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-3">
                        <span
                            style={{ color: 'var(--muted)' }}
                            role="button"
                            onClick={() => {
                                setInformationRequestModal(true);
                                setTimeout(() => {
                                    dispatch(setJoyRideStepIndex(2));
                                }, 300);
                            }}>
                            Previous
                        </span>
                        <button onClick={() => dispatch(setJoyRideStepIndex(4))} className="btn btn-primary smallButton">
                            Next
                        </button>
                    </div>
                </div>
            ),
            hideFooter: true,
            disableBeacon: true,
            styles: {
                options: {
                    zIndex: 10000,
                    // width: 900,
                },
            },
        },
        {
            //view-tour
            target: '.view-tour',
            content: (
                <div className="ps-2 pe-2 regularFont">
                    <div className="text-end">
                        <AiOutlineClose color="var(--Brand-Secondary)" onClick={() => setRunJoyRide(false)} role="button" />
                    </div>
                    <div className="text-start">This completes the tour of the IRL. You can always access feature tours using the View Tour button.</div>
                    <div className="d-flex align-items-center justify-content-between mt-3">
                        <span style={{ color: 'var(--muted)' }} role="button" onClick={() => dispatch(setJoyRideStepIndex(3))}>
                            Previous
                        </span>
                        <button onClick={() => setRunJoyRide(false)} className="btn btn-primary smallButton">
                            Exit
                        </button>
                    </div>
                </div>
            ),
            hideFooter: true,
            disableBeacon: true,
            disableOverlayClose: true,
            spotlightClicks: true,
            styles: {
                options: {
                    zIndex: 10000,
                },
            },
        },
    ]);

    React.useEffect(() => {
        const isFirstTimeUser = !Cookies.get('irlJoyride');
        if (isFirstTimeUser) {
            dispatch(setJoyRideStepIndex(0));
            setRunJoyRide(true);
            Cookies.set('irlJoyride', true);
        }
    }, []);
    return (
        <Layout container={true}>
            <Pageheader title="LENS - IRL" />
            {runJoyRide && (
                <ReactJoyride
                    run={runJoyRide}
                    steps={joyRideSteps}
                    stepIndex={joyRideStepIndex}
                    continuous
                    hideCloseButton
                    spotlightPadding={5}
                    styles={{
                        tooltip: {
                            padding: 0,
                        },
                    }}
                />
            )}
            <IRL setRunJoyRide={setRunJoyRide} informationRequestModal={informationRequestModal} setInformationRequestModal={setInformationRequestModal} />
        </Layout>
    );
};

export default IRLView;
