import React from 'react';
import styles from './css/index.module.css';
import { Input, Textarea } from '@fluentui/react-components';
import { Delete20Regular } from '@fluentui/react-icons';
import { validExtensions } from '../../utils/misc';
import { PatchProjectData, selectProject } from '../../redux/projectDataSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { useNavigate } from 'react-router';
const AddBrandDetails = ({ setSelectedView, createdProjectData }) => {
    const dispatch = useDispatch();

    const { projectDetailButtonLoading } = useSelector(state => state.projectData);
    const fileInputRef = React.useRef();
    const navigate = useNavigate();
    const [formData, setFormData] = React.useState({
        linkedin: '',
        logoToShow: '',
    });
    const [uploadedFile, setUploadedFile] = React.useState();

    const handleChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleSubmit = async ({ isFinished }) => {
        let data = new FormData();
        if (uploadedFile) {
            data.append('logo', uploadedFile);
        } else if (createdProjectData?.logo) {
            let file = await handleUrlInputChange(createdProjectData?.logo);
            data.append('logo', file);
        }
        data.append('linkedin', formData?.linkedin);
        let response = await dispatch(PatchProjectData({ data: data, id: createdProjectData?.id }));
        if (isFinished) {
            if (response?.type === 'projectData/patchProjectData/fulfilled') {
                // response.payload?.data
                dispatch(selectProject(response.payload?.data));
                navigate('/dataroom');
            }
        } else setSelectedView('businessDetails');
    };
    const handleDivClick = () => {
        fileInputRef.current.click();
    };
    const handleFileChange = e => {
        const selectedFiles = Array.from(e.target.files);
        console.log(selectedFiles);
        if (selectedFiles?.length > 0) {
            setUploadedFile(selectedFiles[0]);
            const reader = new FileReader();
            reader.onload = () => {
                const base64 = reader.result;
                setFormData({ ...formData, logoToShow: base64 });
            };
            reader.readAsDataURL(selectedFiles[0]);
        }
    };
    const handleUrlInputChange = async imageUrl => {
        try {
            const response = await fetch(imageUrl);
            const blob = await response.blob();
            // Get the content type from the response headers
            const contentType = response.headers.get('content-type');
            // Extract file extension from content type
            const extension = contentType.split('/')[1];
            // Create a unique filename
            const fileName = `image.${extension}`;
            const fetchedFile = new File([blob], fileName, { type: contentType });
            return fetchedFile;
        } catch (error) {
            console.error('Error fetching image:', error);
        }
    };
    React.useEffect(() => {
        if (createdProjectData) {
            setFormData({
                logoToShow: createdProjectData?.logo,
                linkedin: createdProjectData?.linkedin,
            });
        }
    }, [createdProjectData]);

    return (
        <div className={styles.componentCard}>
            <h5 className={styles.componentTitle}>Confirm brand details</h5>
            <div className="mb-4">
                <div className="d-flex align-items-center justify-content-between">
                    <p className={styles.fieldLabel}>Logo</p>
                    <span className={styles.linkBtn} onClick={handleDivClick}>
                        Change
                    </span>
                </div>
                <div onClick={handleDivClick} style={{ background: formData?.logoToShow && 'white' }} className={styles.logoDiv + ' ' + styles.uploadBox}>
                    {formData?.logoToShow ? (
                        <img className={styles.logoImage} alt={'logo'} src={formData?.logoToShow} />
                    ) : (
                        <span>
                            Drag & drop file or
                            <br />
                            click to upload
                        </span>
                    )}

                    <input multiple type="file" accept={['jpeg', 'png', 'jpg'].join(',')} ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
                </div>
            </div>
            <div className="mb-4">
                <p className={styles.fieldLabel}>LinkedIn profile</p>
                <Input className={styles.inputField} value={formData?.linkedin} onChange={handleChange} name="linkedin" placeholder="www.linkedin.com/en-in/bootytraps" />
            </div>
            <div className="d-flex align-items-center justify-content-between">
                {' '}
                <button disabled={projectDetailButtonLoading} onClick={() => handleSubmit({ isFinished: true })} className={styles.submitButton}>
                    Confirm
                    {/* {projectDetailButtonLoading && <AiOutlineLoading3Quarters className="ms-2 rotateLoader" />} */}
                </button>
                <span onClick={() => handleSubmit({ isFinished: false })} className={styles.linkBtn}>
                    Add more details
                </span>
            </div>
        </div>
    );
};

export default AddBrandDetails;
