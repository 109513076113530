import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styles from './css/index.module.css';
import { MdOutlineAddCircle } from 'react-icons/md';
import { FaUsers } from 'react-icons/fa';
import { AiFillWarning } from 'react-icons/ai';
import { BsThreeDotsVertical, BsChevronDown, BsChevronRight, BsServer } from 'react-icons/bs';
import { DeleteProjectData, GetProjectData, PatchProjectData, selectProject, setProjectImg } from '../../redux/projectDataSlice';
import { setDocs } from '../../redux/documentsSlice';
import { Dropdown } from 'react-bootstrap';
import 'react-circular-progressbar/dist/styles.css';
import { getFileSize, numFormatter, processInput } from '../../utils/commonUtils';
import { GetProjectUsers } from '../../redux/teamSlice';
import DoubleConfirmationModal from '../CommonComponents/DoubleConfirmationModal/DoubleConfirmationModal';
import Header from '../CommonComponents/Header';
import { Add16Regular, MoreVertical20Regular, PersonStarburst20Filled } from '@fluentui/react-icons';
import { Menu, MenuDivider, MenuItem, MenuList, MenuPopover, MenuTrigger } from '@fluentui/react-components';
import ReactApexChart from 'react-apexcharts';
import DonutChart from 'react-donut-chart';
const Project = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { data } = useSelector(state => state.projectData);
    const { projectUsersAll } = useSelector(state => state.team);
    const [showArchive, setShowArchive] = React.useState(false);
    const [showDeleted, setShowDeleted] = React.useState(false);
    const { user } = useSelector(state => state.user);
    const userType = user?.types;
    const deleteModalRef = React.useRef();
    const [selectedTile, setTile] = React.useState();
    React.useEffect(() => {
        dispatch(GetProjectData());
    }, []);

    const handleChangeProject = async id => {
        localStorage.setItem('project_id', id);
        dispatch(
            selectProject({
                id,
                callback: () => handleNavigate(id),
                userType: userType,
            }),
        );
        const res = await dispatch(GetProjectUsers());
        // if(res?.type==='GET_PROJECT_USERS/fulfilled'){

        // }
    };
    const handleNavigate = id => {
        const currentProject = data?.find(item => item?.id === id);
        if (user?.has_diligence_tools) {
            navigate('/overview');
            // if (currentProject?.project_deadline) navigate('/overview');
            // else navigate('/AddProjectDetails', { state: { page: 'db', navigateTo: '/overview' } });
        } else {
            const userData = currentProject?.vdr?.nda?.signees?.find(item => item?.user === user?.id);
            if (userData?.nda_signed === false) {
                navigate('/nda');
            }
            // else if (userData?.tandc === false) navigate('/tc');
            else {
                navigate('/dataroom');
            }
        }
    };
    const handleEditProject = async id => {
        localStorage.setItem('project_id', id);
        dispatch(selectProject({ id }));
        dispatch(setProjectImg(''));
        navigate('/EditProject');
    };
    const handleDeleteProject = async id => {
        // await dispatch(DeleteProjectData({ id }));
        await dispatch(PatchProjectData({ data: { soft_delete: true }, id: id }));
        setTile();
    };
    const handleAddProject = () => {
        navigate('/AddProjectDetails');
        dispatch(setProjectImg(''));
        dispatch(selectProject({ id: null }));
        dispatch(setDocs([]));
    };
    const handleArchiveProject = async item => {
        dispatch(PatchProjectData({ data: { archive: !item?.archive }, id: item?.id }));
    };

    let currentUser = projectUsersAll?.find(item => item?.value?.id === user?.id);

    function getTotalFileCount(data) {
        let totalFiles = 0;
        for (const workstream in data) {
            totalFiles += data?.[workstream]?.files;
        }
        return totalFiles;
    }
    const calculateColorSM = value => {
        if (value === 0) {
            return 'var(--Neutral-Gray-6)';
        }
        if (value > 0 && value <= 15) {
            return '#B00020';
        }
        if (value >= 16 && value <= 35) {
            return '#FF4E11';
        }
        if (value >= 36 && value <= 50) {
            return '#FF8E15';
        }
        if (value >= 51 && value <= 65) {
            return '#FAB733';
        }
        if (value >= 66 && value <= 80) {
            return '#ACB334';
        }
        if (value >= 81 && value <= 100) {
            return '#69B34C';
        }
    };
    const calculateScoreGovernance = value => {
        // let colorsScore;
        // if (value === 'RED') {
        //     colorsScore = 0;
        // }
        // if (value === 'AMBER') {
        //     colorsScore = 5;
        // }
        // if (value === 'GREEN') {
        //     colorsScore = 10;
        // }
        return 'var(--Neutral-Gray-6)';
    };

    const ProjectCard = ({ item }) => {
        return (
            <div key={item?.id} className="col-sm-12 col-md-6 col-lg-3 mb-4 position-relative">
                {item?.vdr?.vdr_admin === user?.id && (
                    <div className={styles.menuDiv}>
                        <PersonStarburst20Filled color="#FFC107" />
                        <Menu>
                            <MenuTrigger disableButtonEnhancement>
                                <MoreVertical20Regular role="button" color="var(--Neutral-Gray-6)" />
                            </MenuTrigger>
                            <MenuPopover>
                                <MenuList>
                                    <MenuItem onClick={() => handleEditProject(item?.id)}>Edit Project</MenuItem>
                                    <MenuItem onClick={() => handleArchiveProject(item)}>{item?.archive ? 'Unarchive' : 'Archive'}</MenuItem>
                                    <MenuItem
                                        onClick={() => {
                                            setTile(item);
                                            deleteModalRef?.current?.click();
                                        }}>
                                        Delete Project
                                    </MenuItem>
                                </MenuList>
                            </MenuPopover>
                        </Menu>
                    </div>
                )}
                <div role="button" onClick={() => handleChangeProject(item?.id)} className={styles.projectCard}>
                    {/* <div className={styles.workstreamDetails}>
        <p className={styles.wsTitle}>Workstream</p>
        <div>
            {item?.vdr?.workstreams &&
                Object.keys(item?.vdr?.workstreams)?.map(key => (
                    <div className="d-flex align-items-center justify-content-between">
                        <div className={styles.wsDetailText}>{key}</div>
                        <div className={styles.wsDetailText}>{item?.vdr?.workstreams[key]?.files}</div>
                    </div>
                ))}
        </div>
    </div> */}
                    <div className="d-flex  justify-content-between">
                        <div>
                            <p className={styles.projectTitle}>{item?.project_name}</p>
                            <p className={styles.companyName}>{item?.company_name}</p>
                        </div>
                    </div>
                    <div>
                        <div className={styles.ragDiv}>
                            <div className="d-flex align-items-center">
                                <div className="me-4">
                                    <p className={styles.cardLabel}>Users</p>
                                    <p className={styles.boldText}>{item?.vdr?.users || 0}</p>
                                </div>
                                <div>
                                    <p className={styles.cardLabel}>Uploads</p>
                                    <p className={styles.boldText}>{getTotalFileCount(item?.vdr?.workstreams)}</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <div style={{ background: '#CC0000' }} className={styles.rag}>
                                    {item?.projectprogress?.red_rags || 0}
                                </div>
                                <div style={{ background: '#FFC107' }} className={styles.rag}>
                                    {item?.projectprogress?.amber_rags || 0}
                                </div>
                                <div style={{ background: '#198754' }} className={styles.rag}>
                                    {item?.projectprogress?.green_rags || 0}
                                </div>
                            </div>
                            {/* #989BAD */}
                        </div>
                        <div className="d-flex  justify-content-between">
                            <div>
                                <div style={{ borderColor: calculateColorSM(parseInt(item?.projectprogress?.maturity_score)) }} className={styles.maturitySecurity}>
                                    {item?.projectprogress?.maturity_score}
                                </div>
                                <p className={styles.cardLabel + ' text-center'}>Maturity</p>
                            </div>
                            <div>
                                <div style={{ borderColor: calculateColorSM(parseInt(item?.projectprogress?.security_score)) }} className={styles.maturitySecurity}>
                                    {item?.projectprogress?.security_score}
                                </div>
                                <p className={styles.cardLabel + ' text-center'}>Security</p>
                            </div>
                            <div>
                                <div
                                    style={{
                                        borderColor: calculateScoreGovernance(parseInt(item?.projectprogress?.governance_score)),
                                    }}
                                    className={styles.maturitySecurity}>
                                    {item?.projectprogress?.governance_score}
                                    {/* let type =
(selectedProject?.governance_index_state === 'preSeed' && 'Pre Seed') ||
(selectedProject?.governance_index_state === 'seriesA' && 'Seed / Series A+') ||
(selectedProject?.governance_index_state === 'corp' && 'Private Equity / Corporate'); */}
                                </div>
                                <p className={styles.cardLabel + ' text-center'}>Sec. Gov.</p>
                            </div>
                            <div className="position-relative">
                                <DonutChart
                                    data={[
                                        {
                                            value: item?.projectprogress?.development_score,
                                        },
                                        {
                                            value: item?.projectprogress?.adequate_score,
                                        },
                                        {
                                            value: item?.projectprogress?.exemplary_score,
                                        },
                                    ]}
                                    height={53}
                                    width={53}
                                    legend={false}
                                    outerRadius={0.9}
                                    formatValues={(values, total) => ``}
                                    clickToggle={false}
                                    interactive={false}
                                    colors={
                                        item?.projectprogress?.development_score + item?.projectprogress?.adequate_score + item?.projectprogress?.exemplary_score === 0
                                            ? ['var(--Neutral-Gray-6)']
                                            : ['var(--RAG-Red)', 'var(--RAG-Amber)', 'var(--RAG-Green)']
                                    }
                                />
                                <span className={styles.chartText}>
                                    {item?.projectprogress?.development_score + item?.projectprogress?.adequate_score + item?.projectprogress?.exemplary_score}
                                </span>
                                <p className={styles.cardLabel + ' text-center'}>Team</p>
                            </div>
                            <div className="position-relative">
                                <DonutChart
                                    data={[
                                        {
                                            value: item?.irl_outstanding,
                                        },
                                        {
                                            value: item?.irl_completed,
                                        },
                                    ]}
                                    height={53}
                                    width={53}
                                    legend={false}
                                    outerRadius={0.9}
                                    formatValues={(values, total) => ``}
                                    clickToggle={false}
                                    interactive={false}
                                    colors={['#989BAD', '#198754']}
                                />
                                <div className={styles.chartText}>{item?.irl_outstanding + item?.irl_completed}</div>
                                <p className={styles.cardLabel + ' text-center'}>IRL</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <span className={styles.lastUpdatedText}>Last updated 3 hours ago</span> */}
            </div>
        );
    };
    console.log({ showDeleted, showArchive });
    console.log(data);
    return (
        <div>
            <Header hideProjectItems={true} />
            <div className={styles.body}>
                <div className="d-flex align-items-center mt-4 justify-content-between">
                    {showArchive || showDeleted ? (
                        <div>
                            <p className={styles.nonActiveLabel}>{showDeleted ? 'Deleted' : 'Archived'} Projects</p>
                            {showDeleted && <p className={styles.deletedText}>Projects in this folder will be permanently removed every 15 days.</p>}
                        </div>
                    ) : (
                        <button onClick={handleAddProject} className={styles.addProjectBtn}>
                            <Add16Regular className="me-1" />
                            New Project
                        </button>
                    )}

                    <div>
                        <span
                            onClick={() => {
                                setShowArchive(!showArchive);
                                setShowDeleted(false);
                            }}
                            className={styles.archiveBtnText + ' me-5'}>
                            View {showArchive ? 'Active' : 'Archived'} Projects
                        </span>
                        <span
                            onClick={() => {
                                setShowDeleted(!showDeleted);
                                setShowArchive(false);
                            }}
                            className={styles.archiveBtnText}>
                            View {showDeleted ? 'Active' : 'Deleted'} Projects
                        </span>
                    </div>
                </div>
                <div className="row mt-5">
                    {data?.map(item => {
                        // Condition: Show all projects with soft_delete === true if showDeleted is true
                        if (showDeleted && item?.soft_delete === true) {
                            return <ProjectCard item={item} key={item?.id} />;
                        }
                        // Condition: Show all projects with archive === true except those with soft_delete === true if showArchive is true
                        else if (showArchive && item?.archive === true && item?.soft_delete !== true) {
                            return <ProjectCard item={item} key={item?.id} />;
                        }
                        // Condition: Show the card if neither showDeleted nor showArchive is true
                        else if (!showDeleted && !showArchive && !item?.archive && !item?.soft_delete) {
                            return <ProjectCard item={item} key={item?.id} />;
                        }
                        // Return null for other cases
                        return null;
                    })}
                </div>
            </div>
            <DoubleConfirmationModal
                handleAction={() => handleDeleteProject(selectedTile?.id)}
                message={'Deleting a Project will REMOVE all associated Files, Data Room content, Information Requests, Comments, Users, PERMANENTLY from Lens.'}>
                <div ref={deleteModalRef} />
            </DoubleConfirmationModal>
        </div>
    );
};

export default Project;
