import styles from './css/index.module.css';
import React from 'react';
import AddProject from './AddProject';
import AddNda from './AddNda';
import AddOrganisationDetail from './AddOrganisationDetail';
import AddBrandDetails from './AddBrandDetails';
import AddBusinessDetails from './AddBusinessDetails';
import AddPublicProfiles from './AddPublicProfiles';
import AddCompetitors from './AddCompetitors';
import AddLinkedinProfiles from './AddLinkedinProfiles';
import AddTechnologyDetails from './AddTechnologyDetails';

const Main = () => {
    const [selectedView, setSelectedView] = React.useState('createProject');
    const [createdProjectData, setCreatedProjectData] = React.useState();
    console.log(createdProjectData);
    console.log(createdProjectData);
    return (
        <div className={styles.body}>
            {selectedView === 'createProject' && <AddProject setCreatedProjectData={setCreatedProjectData} setSelectedView={setSelectedView} />}
            {selectedView === 'uploadNda' && <AddNda createdProjectData={createdProjectData} setCreatedProjectData={setCreatedProjectData} setSelectedView={setSelectedView} />}
            {selectedView === 'organisationDetail' && <AddOrganisationDetail createdProjectData={createdProjectData} setSelectedView={setSelectedView} />}

            {selectedView === 'brandDetails' && (
                <AddBrandDetails createdProjectData={createdProjectData} setCreatedProjectData={setCreatedProjectData} setSelectedView={setSelectedView} />
            )}
            {selectedView === 'businessDetails' && <AddBusinessDetails createdProjectData={createdProjectData} setSelectedView={setSelectedView} />}
            {selectedView === 'publicProfiles' && <AddPublicProfiles createdProjectData={createdProjectData} setSelectedView={setSelectedView} />}
            {selectedView === 'competitors' && <AddCompetitors createdProjectData={createdProjectData} setSelectedView={setSelectedView} />}
            {selectedView === 'linkedinProfiles' && <AddLinkedinProfiles createdProjectData={createdProjectData} setSelectedView={setSelectedView} />}
            {selectedView === 'technologyDetails' && <AddTechnologyDetails createdProjectData={createdProjectData} setSelectedView={setSelectedView} />}
            {/* {selectedView === 'createProject' && <AddProject setSelectedView={setSelectedView} />} */}
        </div>
    );
};

export default Main;
