import React, { useState } from 'react';
import styles from './css/index.module.css';
import { CheckmarkCircle20Filled, MoreVertical20Regular, ErrorCircle20Regular, Circle20Filled } from '@fluentui/react-icons';
import { useDispatch, useSelector } from 'react-redux';
import governanceInitialData from './governanceData';
import { Menu, MenuDivider, MenuItem, MenuList, MenuPopover, MenuTrigger } from '@fluentui/react-components';
import { GetGovernanceData, PatchGovernanceResponse, PatchGovernanceTopic, PostGovernanceResponse, PostGovernanceTopic, setGovernanceDataRedux } from '../../redux/securitySlice';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { PatchProjectData } from '../../redux/projectDataSlice';
const SecurityGovernance = () => {
    const [selectedCategory, setSelectedCategory] = React.useState(governanceInitialData[0]?.topic);
    const [governanceData, setGovernanceData] = React.useState(governanceInitialData);
    const [btnLoading, setBtnLoading] = React.useState(false);
    const dispatch = useDispatch();
    const { selectedProject } = useSelector(state => state.projectData);
    const { user } = useSelector(state => state.user);
    const { governanceDataRedux } = useSelector(state => state.security);
    const renderRagCircle = item => {
        const colorCode = (item?.rag === 'AMBER' && '#FFC107') || (item?.rag === 'RED' && '#B00020') || (item?.rag === 'GREEN' && '#198754');
        return user?.has_diligence_tools ? (
            <Menu style={{ marginTop: '20px' }}>
                <MenuTrigger disableButtonEnhancement>
                    <div style={{ borderColor: colorCode }} className={styles.ragCircle}>
                        <div style={{ background: colorCode }} className={styles.ragCircleInside}></div>
                    </div>
                </MenuTrigger>
                <MenuPopover>
                    <MenuList>
                        <MenuItem onClick={() => handleUpdateRag('RED', item)} className="d-flex align-items-center">
                            <Circle20Filled color="#B00020" className="me-2" />
                            Red
                        </MenuItem>
                        <MenuDivider />
                        <MenuItem onClick={() => handleUpdateRag('AMBER', item)} className="d-flex align-items-center">
                            <Circle20Filled color="#FFC107" className="me-2" />
                            Amber
                        </MenuItem>
                        <MenuDivider />
                        <MenuItem onClick={() => handleUpdateRag('GREEN', item)} className="d-flex align-items-center">
                            <Circle20Filled color="#198754" className="me-2" />
                            Green
                        </MenuItem>
                    </MenuList>
                </MenuPopover>
            </Menu>
        ) : (
            item?.rag && (
                <div style={{ borderColor: colorCode }} className={styles.ragCircle}>
                    <div style={{ background: colorCode }} className={styles.ragCircleInside}></div>
                </div>
            )
        );
    };
    const handleUpdateRag = (color, item) => {
        dispatch(
            PatchGovernanceTopic({
                rag: color,
                id: item?.id,
            }),
        );
    };
    const handleChange = (e, item) => {
        if (selectedCategory === 'Security/Privacy Breaches') {
        } else {
        }
        setGovernanceData(
            governanceData?.map(cat => {
                if (cat?.topic === selectedCategory) {
                    return {
                        ...cat,
                        responses: cat?.responses?.map(ques => {
                            if (ques?.question === item?.question) {
                                return {
                                    ...ques,
                                    answer: e.target.value,
                                };
                            } else {
                                if (item?.question === 'Have you experienced any information / data security breaches during the past 2 years?' && e.target.value === 'No') {
                                    return {
                                        ...ques,
                                        answer: '',
                                    };
                                } else return ques;
                            }
                        }),
                    };
                } else return cat;
            }),
        );
    };
    const handleUpdateSubmitedField = async item => {
        setBtnLoading(true);
        if (item?.id) {
            let updatedResponses = [];
            for (const res of item?.responses) {
                if (res?.id) {
                    let questionRes = await dispatch(PatchGovernanceResponse({ body: { ...res, isSubmitted: res?.answer ? true : false } }));
                    if (questionRes?.type === 'PATCH_SECURITY_GOVERNANCE_RESPONSE/fulfilled') {
                        console.log(questionRes);
                        updatedResponses.push(questionRes?.payload?.data);
                    }
                } else {
                    let resBody = {
                        ...res,
                        govern_index: item?.id,
                        project: selectedProject?.id,
                        topic: item?.topic,
                        isSubmitted: res?.answer ? true : false,
                    };
                    let questionRes = await dispatch(PostGovernanceResponse({ body: resBody }));
                    if (questionRes?.type === 'POST_SECURITY_GOVERNANCE_RESPONSE/fulfilled') {
                        updatedResponses.push(questionRes?.payload?.data);
                    }
                }
            }
            await dispatch(setGovernanceDataRedux({ responses: updatedResponses, topic: item }));
            dispatch(
                PatchGovernanceTopic({
                    rag: '',
                    id: item?.id,
                }),
            );
        } else {
            let body = {
                project: selectedProject?.id,
                topic: item?.topic,
            };
            let govTopic = await dispatch(PostGovernanceTopic({ body }));
            console.log(govTopic);
            if (govTopic?.type === 'POST_SECURITY_GOVERNANCE/fulfilled') {
                // govTopic?.payload?.data?.id
                let newResponses = [];
                for (const res of item?.responses) {
                    let resBody = {
                        ...res,
                        govern_index: govTopic?.payload?.data?.id,
                        project: selectedProject?.id,
                        topic: item?.topic,
                        isSubmitted: res?.answer ? true : false,
                    };
                    let questionRes = await dispatch(PostGovernanceResponse({ body: resBody }));
                    console.log(questionRes);
                    if (questionRes?.type === 'POST_SECURITY_GOVERNANCE_RESPONSE/fulfilled') {
                        console.log(questionRes);
                        newResponses.push(questionRes?.payload?.data);
                    }
                }
                dispatch(setGovernanceDataRedux({ responses: newResponses, topic: govTopic?.payload?.data }));
            }
        }
        setBtnLoading(false);
    };
    const handleTurnEditMode = (val, topic) => {
        let category = topic ? topic : selectedCategory;
        setGovernanceData(
            governanceData?.map(cat => {
                if (cat?.topic === category) {
                    return {
                        ...cat,
                        responses: cat?.responses?.map(ques => {
                            return {
                                ...ques,
                                isSubmitted: !ques?.answer ? ques?.isSubmitted : !val,
                            };
                        }),
                    };
                } else return cat;
            }),
        );
    };
    const handleSelectTopic = item => {
        setSelectedCategory(item?.topic === selectedCategory ? '' : item?.topic);
        if (item?.topic === selectedCategory) {
            handleTurnEditMode(false);
        }
    };
    const handleClearResponse = async item => {
        setGovernanceData(
            governanceData?.map(cat => {
                if (cat?.topic === item?.topic) {
                    return {
                        ...cat,
                        responses: cat?.responses?.map(ques => {
                            return {
                                ...ques,
                                answer: '',
                                isSubmitted: false,
                            };
                        }),
                    };
                } else return cat;
            }),
        );
        handleUpdateSubmitedField({
            ...item,
            responses: item?.responses?.map(ques => {
                return {
                    ...ques,
                    answer: '',
                    isSubmitted: false,
                };
            }),
        });
    };
    const handleSelectSelectedType = value => {
        dispatch(
            PatchProjectData({
                data: {
                    governance_index_state: value,
                },
                id: selectedProject?.id,
            }),
        );
    };
    const calculateColor = value => {
        if (value <= 15) {
            return '#B00020';
        }
        if (value >= 16 && value <= 35) {
            return '#FF4E11';
        }
        if (value >= 36 && value <= 50) {
            return '#FF8E15';
        }
        if (value >= 51 && value <= 65) {
            return '#FAB733';
        }
        if (value >= 66 && value <= 80) {
            return '#ACB334';
        }
        if (value >= 81 && value <= 100) {
            return '#69B34C';
        }
    };
    const renderMaturityScore = () => {
        let type =
            (selectedProject?.governance_index_state === 'preSeed' && 'Pre Seed') ||
            (selectedProject?.governance_index_state === 'seriesA' && 'Seed / Series A+') ||
            (selectedProject?.governance_index_state === 'corp' && 'Private Equity / Corporate');
        let filteredData = governanceData?.filter(item => item?.rag);
        let colorsScore = 0;
        governanceData?.forEach(item => {
            if (item?.rag === 'RED') {
                colorsScore = colorsScore + 0;
            }
            if (item?.rag === 'AMBER') {
                colorsScore = colorsScore + 5;
            }
            if (item?.rag === 'GREEN') {
                colorsScore = colorsScore + 10;
            }
        });
        const score = (colorsScore / (parseInt(governanceData?.length) * 10)) * 100;
        return (
            <div className={styles.maturityScoreBoard}>
                {filteredData?.length > 0 ? `Your Security Governance Index for a ${type} Funding Round` : 'Your Security Governance has not been assessed'}

                <div style={{ borderColor: score === 0 ? 'var(--Neutral-Gray-6)' : calculateColor(score) }} className={styles.roundCirlcle}>
                    {parseInt(score)}
                </div>
            </div>
        );
    };
    React.useEffect(() => {
        if (selectedProject) {
            dispatch(GetGovernanceData());
        }
    }, []);
    React.useEffect(() => {
        let data = governanceInitialData?.map(category => {
            let catData = governanceDataRedux?.find(item => item?.topic === category?.topic);
            if (catData) {
                return {
                    ...catData,
                    responses: category?.responses?.map(res => {
                        let resData = catData?.responses?.find(item => item?.question === res?.question);
                        if (resData) {
                            return resData;
                        } else return res;
                    }),
                };
            } else return category;
        });
        // console.log(data);
        let filteredData = data
            ?.map(item => {
                let filteredRes = item?.responses?.filter(res => res[selectedProject?.governance_index_state]);
                if (filteredRes?.length > 0) {
                    return {
                        ...item,
                        responses: filteredRes,
                    };
                }
            })
            ?.filter(item => item);
        setGovernanceData([...filteredData]);
    }, [governanceDataRedux, selectedProject?.governance_index_state]);
    return (
        <div className={styles.viewBody}>
            <h3 className={styles.pageHeading}>Security Governance</h3>
            <p className={styles.subTitle}>Choose your funding round and proceed to complete the questionnaire for a consultant evaluation.</p>

            <div className="d-flex flex-wrap ">
                <button
                    onClick={() => handleSelectSelectedType('preSeed')}
                    className={styles.headerSimpleButton + ' ' + (selectedProject?.governance_index_state === 'preSeed' && styles.selectedBtn)}>
                    Pre Seed
                </button>
                <button
                    onClick={() => handleSelectSelectedType('seriesA')}
                    className={styles.headerSimpleButton + ' ' + (selectedProject?.governance_index_state === 'seriesA' && styles.selectedBtn)}>
                    Seed / Series A+
                </button>
                <button
                    onClick={() => handleSelectSelectedType('corp')}
                    className={styles.headerSimpleButton + ' ' + (selectedProject?.governance_index_state === 'corp' && styles.selectedBtn)}>
                    Private Equity / Corporate
                </button>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-9">
                    {' '}
                    <div>
                        {governanceData?.map(item => {
                            const isUndone =
                                item?.topic === 'Security/Privacy Breaches' && item?.responses?.[0]?.answer === 'No' && item?.responses?.[0]?.isSubmitted
                                    ? false
                                    : item?.responses?.find(ques => !ques?.isSubmitted);
                            return (
                                <div key={item?.topic} className={styles.questionBody}>
                                    <div style={{ padding: '0px 16px' }} className="d-flex align-items-center justify-content-between position-relative" role="button">
                                        <div onClick={() => handleSelectTopic(item)} style={{ height: '64px' }} className="d-flex align-items-center w-100">
                                            {!isUndone ? <CheckmarkCircle20Filled color="var(--Brand-Primary)" /> : <ErrorCircle20Regular color="var(--Brand-Primary)" />}
                                            <span className={styles.categoryName}>{item?.topic}</span>
                                        </div>
                                        <div className={styles.rowMenu}>
                                            {!isUndone && renderRagCircle(item)}
                                            <Menu style={{ marginTop: '20px' }}>
                                                <MenuTrigger disableButtonEnhancement>
                                                    <MoreVertical20Regular />
                                                </MenuTrigger>
                                                <MenuPopover>
                                                    <MenuList>
                                                        <MenuItem onClick={() => setSelectedCategory(item?.topic === selectedCategory ? '' : item?.topic)}>
                                                            {item?.topic === selectedCategory ? 'Close' : 'View'} Response
                                                        </MenuItem>

                                                        <MenuDivider />
                                                        <MenuItem
                                                            onClick={() => {
                                                                setSelectedCategory(item?.topic);
                                                                handleTurnEditMode(true, item?.topic);
                                                            }}>
                                                            Edit Response
                                                        </MenuItem>
                                                    </MenuList>
                                                </MenuPopover>
                                            </Menu>
                                        </div>
                                    </div>
                                    {item?.topic === selectedCategory && (
                                        <div className={styles.expandedCategory}>
                                            <div className={styles.expandedBody}>
                                                {item?.responses?.map(question => {
                                                    return question?.isSubmitted && question?.answer ? (
                                                        <div key={question?.question}>
                                                            <p className={styles.ansLabel}> {question?.question}</p>
                                                            <p className={styles.answer}>{question?.answer}</p>
                                                        </div>
                                                    ) : item?.topic === 'Security/Privacy Breaches' ? (
                                                        question?.isBoolean ? (
                                                            <div key={question?.question} className="d-flex justify-content-between mb-5">
                                                                <p className={styles.question}> {question?.question}</p>
                                                                <div className="d-flex align-items-center">
                                                                    <div onClick={() => handleChange({ target: { value: 'Yes' } }, question)} className={styles.inputCheckbox}>
                                                                        <div className={styles.ragCircle}>
                                                                            <div
                                                                                style={{ background: question?.answer === 'Yes' && 'var(--Brand-Primary)' }}
                                                                                className={styles.ragCircleInside}></div>
                                                                        </div>
                                                                        <span className="me-3"> Yes</span>
                                                                    </div>
                                                                    <div onClick={() => handleChange({ target: { value: 'No' } }, question)} className={styles.inputCheckbox}>
                                                                        <div className={styles.ragCircle}>
                                                                            <div
                                                                                style={{ background: question?.answer === 'No' && 'var(--Brand-Primary)' }}
                                                                                className={styles.ragCircleInside}></div>
                                                                        </div>
                                                                        <span> No</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            item?.responses?.[0]?.answer === 'Yes' && (
                                                                <div key={question?.question}>
                                                                    <p className={styles.question}> {question?.question}</p>
                                                                    <textarea
                                                                        value={question?.answer}
                                                                        onChange={e => handleChange(e, question)}
                                                                        className={styles.textarea + ' form-control'}
                                                                        rows={3}
                                                                    />
                                                                </div>
                                                            )
                                                        )
                                                    ) : (
                                                        <div key={question?.question}>
                                                            <p className={styles.question}> {question?.question}</p>
                                                            <textarea
                                                                value={question?.answer}
                                                                onChange={e => handleChange(e, question)}
                                                                className={styles.textarea + ' form-control'}
                                                                rows={3}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                                {isUndone && (
                                                    <div style={{ marginBottom: 32 }} className="d-flex align-items-center justify-content-between">
                                                        {item?.topic === 'Security/Privacy Breaches' ? (
                                                            <>
                                                                <button
                                                                    onClick={() => handleUpdateSubmitedField(item)}
                                                                    disabled={item?.responses?.[0]?.answer === 'No' ? false : item?.responses?.find(ques => !ques?.answer)}
                                                                    className={styles.submitBtn}>
                                                                    Submit {btnLoading && <AiOutlineLoading3Quarters className="rotateLoader" />}
                                                                </button>
                                                            </>
                                                        ) : (
                                                            <button
                                                                onClick={() => handleUpdateSubmitedField(item)}
                                                                disabled={item?.responses?.find(ques => !ques?.answer)}
                                                                className={styles.submitBtn}>
                                                                Submit {btnLoading && <AiOutlineLoading3Quarters className="rotateLoader" />}
                                                            </button>
                                                        )}

                                                        <span onClick={() => handleClearResponse(item)} className={styles.clearText}>
                                                            Clear
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="col-sm-12 col-md-3">{renderMaturityScore()}</div>
            </div>
        </div>
    );
};

export default SecurityGovernance;
