import React from 'react';
import styles from './css/index.module.css';
import { Input } from '@fluentui/react-components';
import { ChevronDown16Regular, Search20Regular } from '@fluentui/react-icons';
import { PatchProjectData } from '../../redux/projectDataSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
const AddLinkedinProfiles = ({ setSelectedView, createdProjectData }) => {
    const dispatch = useDispatch();

    const { projectDetailButtonLoading } = useSelector(state => state.projectData);
    const [formData, setFormData] = React.useState({
        ceo: '',
        coo: '',
        cto: '',
        cpo: '',
    });

    const handleChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleSubmit = async () => {
        await dispatch(
            PatchProjectData({
                data: formData,
                id: createdProjectData?.id,
            }),
        );

        setSelectedView('technologyDetails');
    };
    const handleSearchOnWeb = platform => {
        let withoutSpecialChracterString = createdProjectData?.company_name.replace(/[^a-zA-Z0-9\s]/g, '');
        const searchQuery = (withoutSpecialChracterString + ' ' + platform).replace(' ', '+');
        window.open(`https://www.google.com/search?q=${searchQuery}`, '_blank');
    };

    return (
        <div className={styles.componentCard}>
            <h5 className={styles.componentTitle + ' mb-0'}>Add CXO LinkedIn Profiles</h5>
            <p className={styles.subHeading}>or search LinkedIn by adding names</p>
            <div className="mb-4">
                <div className="d-flex align-items-center justify-content-between">
                    <p className={styles.fieldLabel}>CEO</p>
                    <Search20Regular onClick={() => handleSearchOnWeb('CEO')} role="button" color="var(--Brand-Secondary)" />
                </div>
                <Input className={styles.inputField} value={formData?.ceo} onChange={handleChange} name="ceo" />
            </div>

            <div className="mb-4">
                <div className="d-flex align-items-center justify-content-between">
                    <p className={styles.fieldLabel}>COO</p>
                    <Search20Regular onClick={() => handleSearchOnWeb('COO')} role="button" color="var(--Brand-Secondary)" />
                </div>
                <Input className={styles.inputField} value={formData?.coo} onChange={handleChange} name="coo" />
            </div>
            <div className="mb-4">
                <div className="d-flex align-items-center justify-content-between">
                    <p className={styles.fieldLabel}>CTO / CIO</p>
                    <Search20Regular onClick={() => handleSearchOnWeb('CTO')} role="button" color="var(--Brand-Secondary)" />
                </div>
                <Input className={styles.inputField} value={formData?.cto} onChange={handleChange} name="cto" />
            </div>
            <div className="mb-5">
                <div className="d-flex align-items-center justify-content-between">
                    <p className={styles.fieldLabel}>CPO</p>
                    <Search20Regular onClick={() => handleSearchOnWeb('CPO')} role="button" color="var(--Brand-Secondary)" />
                </div>
                <Input className={styles.inputField} value={formData?.cpo} onChange={handleChange} name="cpo" />
            </div>
            <div className="d-flex align-items-center justify-content-between">
                <button onClick={handleSubmit} className={styles.submitButton}>
                    Confirm {projectDetailButtonLoading && <AiOutlineLoading3Quarters className="ms-2 rotateLoader" />}
                </button>
                <span onClick={() => setSelectedView('technologyDetails')} className={styles.linkBtn}>
                    Skip
                </span>
            </div>
        </div>
    );
};

export default AddLinkedinProfiles;
