import Pageheader from '../../utils/headerHelment';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import axios from 'axios';
import { API_BASE_URL, RESEND_ACTIVATION_LINK } from '../../apiServices/api_routes';
import styles from './css/ConfirmMail.module.css';
import Logo from '../../assets/longLogoDark.png';

const ConfirmMail = () => {
    const location = useLocation();

    const handleSubmit = async () => {
        try {
            await axios.post(`${API_BASE_URL}${RESEND_ACTIVATION_LINK}`, { email: location?.state?.email });
            toast.success('Success');
        } catch (error) {
            toast.error('Error : Something went wrong');
        }
    };

    const onClose = () => {
        window.opener = null;
        window.open('', '_self');
        window.close();
    };
    return (
        <div className=" full-height-cls  bg-light">
            <Pageheader title="LENS - Confirm Mail" />
            <header className="header ">
                <div className={styles.navbarbrand}>
                    <Link to="/">
                        <img src={Logo} alt="logo" width="100px" height="54px" />
                    </Link>
                </div>
            </header>
            <div className="container">
                <div className={`row ${styles.viewHeight}`}>
                    <div className="col-sm-12 col-md-5">
                        <div className={`${styles.formBody} `}>
                            <div className={styles.rightContent}>
                                <h2 className={styles.headingText}>Please verify your email</h2>
                                <p className={styles.emailText}>We sent an email to {location?.state?.email}</p>
                                <p className={styles.emailText}>
                                    Click on the link in that email to complete your sign up. if you don’t see it, you may need to check your spam folder or unblock
                                    duediligence@beyond-ma.com
                                </p>
                                <p className={styles.cantGetText}>Still can’t find the email?</p>

                                <div className="d-flex justify-content-between align-items-center ">
                                    <button onClick={handleSubmit} style={{ width: '175px' }} className="btn btn-primary">
                                        Resend Email
                                    </button>
                                    <div>
                                        <p onClick={onClose} className={styles.closeTab}>
                                            Close Tab
                                        </p>
                                    </div>
                                </div>
                                <div className={styles.forgotPassDiv}>
                                    <p className="mb-0">
                                        {' '}
                                        Need Help?{' '}
                                        <Link to="/">
                                            <span>Contact Us</span>
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer>
                    <p className={styles.copyright + ' ' + styles.rightContent}>
                        © Beyond M&amp;A Ltd |{' '}
                        <a className="privacy-link" href="https://www.iubenda.com/privacy-policy/15531073/legal?ifr=true&height=650&newmarkup=yes&an=no" target="_blank">
                            Privacy
                        </a>
                        {` `} &amp; {` `}
                        <a className="privacy-link" href="https://www.iubenda.com/terms-and-conditions/15531073" target="_blank">
                            terms
                        </a>
                    </p>
                </footer>
            </div>

            <div className={styles.rightImage}></div>
        </div>
    );
};

export default ConfirmMail;
