import React from 'react';
import Layout from '../components/Layout';
import Pageheader from '../utils/headerHelment';
import SoftwareInventory from '../components/SoftwareInventory';

const SoftwareInventoryPage = () => {
    return (
        <>
            <Pageheader title="LENS - Software Inventory" />
            <Layout>
                {/* <Wizard /> */}
                <SoftwareInventory />
            </Layout>
        </>
    );
};

export default SoftwareInventoryPage;
