import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../apiServices/axios';

import { USERMINFO, REGISTER_USER, REQUEST_INVITE } from '../apiServices/api_routes';

export const GetUserData = createAsyncThunk('userSlice/getUserData', async () => await axiosInstance.get(USERMINFO));

export const RegisterUser = createAsyncThunk('POST_REGISTER_USER', async body => {
    const data = await axiosInstance.post(REGISTER_USER, body);
    return data;
});

export const userSlice = createSlice({
    name: 'usersSlice',
    initialState: {
        user: {},
        status: null,
        access: '',
        loading: true,
        error: false,
        errorMessage: '',
        isLoggedIn: false,
    },
    reducers: {
        setAccessToken: (state, { payload }) => {
            return { ...state, access: payload };
        },
        logout: state => {
            for (var key in localStorage) {
                if (localStorage.hasOwnProperty(key) && key !== 'workstream' && key !== 'mobileDeviceWarn') {
                    localStorage.removeItem(key);
                }
            }
        },
    },
    extraReducers: {
        [GetUserData.pending]: (state, { payload }) => {
            state.user = payload?.data;
            state.loading = true;
        },
        [GetUserData.fulfilled]: (state, { payload }) => {
            localStorage.setItem('user_id', payload?.data[0]?.id);
            // localStorage.setItem('userType', payload?.data[0]?.types);
            state.user = { ...payload?.data[0] };
            state.isLoggedIn = true;
            state.error = false;
            state.errorMessage = '';
            state.loading = false;
        },
        [GetUserData.rejected]: (state, action) => {
            state.error = true;
            state.errorMessage = action?.error?.message;
            state.user = {};
            state.loading = false;
        },

        //RegisterUser
        [RegisterUser.pending]: (state, { payload }) => {
            state.status = 'loading';
        },
        [RegisterUser.fulfilled]: (state, { payload }) => {
            state.status = 'success';
        },
        [RegisterUser.rejected]: (state, action) => {
            state.status = 'rejected';
        },
    },
});

export const { setAccessToken, logout } = userSlice.actions;
export default userSlice.reducer;
