import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './css/index.module.css';
import { GetDataroomItemInfo, GetWorkstreams } from '../../redux/dataroomSlice';
import { AddBulkInformationRequests, GetDefaultQuestions, PostInformationRequests } from '../../redux/questionBankSlice';
import { WorkstreamSelect } from '../Observations';
import { GetIRLDoc } from '../../redux/documentsSlice';
import { IoIosAdd } from 'react-icons/io';
import { Offcanvas } from 'react-bootstrap';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { useNavigate } from 'react-router';

const QuestionBank = () => {
    const dispatch = useDispatch();
    const { selectedProject } = useSelector(state => state.projectData);
    const { workstreams } = useSelector(state => state.dataroom);
    const { questionBank, addtoIRLBtnLoading } = useSelector(state => state.questionBank);
    const { user } = useSelector(state => state.user);
    const isProjectAdmin = selectedProject?.vdr?.vdr_admin === user?.id;
    const { projectUsersAll } = useSelector(state => state.team);
    const [selectedWorkstream, setSelectedWorkstream] = React.useState();
    const [selectedTag, setSelectedTag] = React.useState('');
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [filteredData, setFilteredData] = React.useState([]);
    const [showModal, setShowModal] = React.useState(false);
    const [selectedQuestion, setSelectedQuestion] = React.useState();
    const [availableTags, setAvailableTags] = React.useState([]);
    const navigate = useNavigate();
    const handleSelectAllFilter = val => {
        if (val) {
            let ids = [];
            filteredData?.map(tab => {
                return ids.push(tab?.id);
            });
            setSelectedRows(ids);
        } else setSelectedRows([]);
    };
    const handleSelectRows = id => {
        if (selectedRows?.find(x => x === id)) {
            setSelectedRows(selectedRows.filter(x => x !== id));
        } else {
            setSelectedRows([...selectedRows, id]);
        }
    };

    const handleAddMultiple = () => {
        let data = selectedRows?.map(row => {
            let stockReq = filteredData?.find(item => item?.id === row);
            let obj = {
                request: stockReq?.question,
                vdr: selectedProject?.vdr?.id,
                tag: stockReq?.tag,
                workstream: selectedWorkstream?.id,
                priorities: 'NONE',
                request_class: 'Custom',
                added_by: projectUsersAll?.find(item => item?.value?.user_id === user?.id)?.value?.group_member_id,
            };
            if (!obj?.added_by && isProjectAdmin) {
                obj.added_by_offline = 'Project Admin';
                delete obj.added_by;
            }
            return obj;
        });
        dispatch(AddBulkInformationRequests({ items: data }));
        setSelectedRows([]);
        setSelectedQuestion();
    };

    React.useEffect(() => {
        if (selectedProject) {
            dispatch(GetWorkstreams());
        }
    }, [selectedProject]);
    React.useEffect(() => {
        if (workstreams?.length > 0) {
            if (localStorage['workstream']) {
                const wsFromLocal = workstreams?.find(item => item?.id === parseInt(localStorage['workstream']));
                if (wsFromLocal) setSelectedWorkstream(wsFromLocal);
            } else setSelectedWorkstream(workstreams[0]);
        }
    }, [workstreams]);
    React.useEffect(() => {
        if (selectedWorkstream) {
            dispatch(GetIRLDoc(selectedWorkstream?.id));
            dispatch(GetDataroomItemInfo(selectedWorkstream?.id));
            dispatch(GetDefaultQuestions(selectedWorkstream?.name));
            setSelectedRows([]);
            setSelectedQuestion();
        }
    }, [selectedWorkstream]);
    React.useEffect(() => {
        setAvailableTags([...new Set(questionBank.map(entry => entry.tag))]);
    }, [questionBank]);
    React.useEffect(() => {
        if (selectedTag) {
            setFilteredData(questionBank?.filter(item => item?.tag === selectedTag));
        }
    }, [selectedTag]);
    return (
        <div>
            <div className={styles.headerSection}>
                <div className="d-flex align-items-center justify-content-between">
                    <h5 className={styles.headerTitle}>Question Bank</h5>
                    <button onClick={() => navigate('/irl')} type="button" className={`btn btn-primary btn-sm   ${styles.responseButton} ${styles.smallButton}`}>
                        Back
                    </button>
                </div>
                <div className="row mb-3">
                    <div className="col-sm-12 col-md-2">
                        <WorkstreamSelect selectedOption={selectedWorkstream} setSelectedOption={setSelectedWorkstream} />
                    </div>
                </div>
            </div>
            {selectedWorkstream && availableTags?.length > 0 && (
                <>
                    <div>
                        <p className={styles.selectTag}>Select Tag</p>
                        <div className="d-flex flex-wrap">
                            {availableTags?.map(item => (
                                <div
                                    style={{ background: selectedTag === item && 'var(--info)', color: selectedTag === item && 'white' }}
                                    onClick={() => setSelectedTag(item)}
                                    className={styles.tagChip}>
                                    {item}
                                </div>
                            ))}
                        </div>
                    </div>
                    {selectedTag && (
                        <div>
                            <div className="text-end mt-2 mb-3">
                                <button onClick={handleAddMultiple} type="button" className={`btn btn-primary btn-sm add-irl-button ms-4 ${styles.smallButton}`}>
                                    Add to IRL {addtoIRLBtnLoading ? <AiOutlineLoading3Quarters className="rotateLoader" /> : <IoIosAdd size={16} />}
                                </button>
                            </div>
                            <div className={styles.tableHeader}>
                                <input
                                    type="checkbox"
                                    className="me-4"
                                    checked={selectedRows?.length === filteredData.length ? true : false}
                                    onChange={e => handleSelectAllFilter(e.target.checked)}
                                />

                                <p className="m-0">{selectedTag}</p>
                            </div>
                            {filteredData?.map(item => (
                                <div className={styles.tableRow}>
                                    <div className="me-4">
                                        <input
                                            className={styles.rowCheck}
                                            type="checkbox"
                                            style={{ display: selectedRows?.find(x => x === item?.id) && 'block' }}
                                            checked={selectedRows?.find(x => x === item?.id)}
                                            onChange={e => handleSelectRows(item?.id, e.target.checked)}
                                        />
                                    </div>
                                    <p
                                        onClick={() => {
                                            setSelectedQuestion(item);
                                            setShowModal(true);
                                        }}
                                        className="m-0">
                                        {item?.question}
                                    </p>
                                </div>
                            ))}
                        </div>
                    )}
                </>
            )}

            <AddFolderModal
                showModal={showModal}
                setShowModal={setShowModal}
                selectedQuestion={selectedQuestion}
                setSelectedQuestion={setSelectedQuestion}
                selectedWorkstream={selectedWorkstream}
            />
        </div>
    );
};

const AddFolderModal = ({ showModal, setShowModal, selectedQuestion, selectedWorkstream, setSelectedQuestion }) => {
    const { user } = useSelector(state => state.user);
    const { selectedProject } = useSelector(state => state.projectData);
    const isProjectAdmin = selectedProject?.vdr?.vdr_admin === user?.id;
    const { projectUsersAll } = useSelector(state => state.team);
    const { customRequestLoading } = useSelector(state => state.questionBank);
    const dispatch = useDispatch();
    const handleClose = () => {
        setShowModal(false);
        setSelectedQuestion();
    };

    const handleSubmit = async () => {
        let body = {
            request: selectedQuestion?.question,
            vdr: selectedProject?.vdr?.id,
            tag: selectedQuestion?.tag,
            workstream: selectedWorkstream?.id,
            request_class: 'Custom',
            added_by: projectUsersAll?.find(item => item?.value?.user_id === user?.id)?.value?.group_member_id,
            priorities: 'NONE',
        };
        if (!body?.added_by && isProjectAdmin) {
            body.added_by_offline = 'Project Admin';
            delete body.added_by;
        }

        await dispatch(PostInformationRequests({ body }));
        handleClose();
    };
    return (
        <>
            <Offcanvas placement="end" show={showModal} onHide={handleClose}>
                <Offcanvas.Body className={styles.sidebarBody}>
                    <div className="p-3">
                        <h5 className={styles.wsTitle}>{selectedQuestion?.workstream}</h5>
                        <p className={styles.tag}>{selectedQuestion?.tag}</p>
                        <p className={styles.questionText}>{selectedQuestion?.question}</p>
                        <button onClick={handleSubmit} type="button" className={`btn btn-primary btn-sm add-irl-button mt-4 ${styles.smallButton}`}>
                            Add to IRL &nbsp;{customRequestLoading ? <AiOutlineLoading3Quarters className="rotateLoader" /> : <IoIosAdd size={16} />}{' '}
                        </button>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

export default QuestionBank;
