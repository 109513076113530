import { Text, Tooltip } from '@fluentui/react-components';
import {
    ChevronRight20Regular,
    Home20Regular,
    DocumentFolder20Regular,
    ChatBubblesQuestion20Regular,
    ContactCardRibbon20Regular,
    PeopleSettings20Regular,
    ChevronLeft20Regular,
    Wand20Regular,
    TableSearch20Regular,
    TaskListAdd20Regular,
    Step16Regular,
    ShieldKeyhole20Regular,
    ListBar20Regular,
} from '@fluentui/react-icons';
import styles from './index.module.css';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import React from 'react';
const Sidebar = ({ expandSidebar, setExpandSidebar }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { user } = useSelector(state => state.user);
    const { selectedProject } = useSelector(state => state.projectData);
    const isProjectAdmin = selectedProject?.vdr?.vdr_admin === user?.id;
    const [links, setLinks] = React.useState([]);

    const handleChangeSidebar = val => {
        setExpandSidebar(val);
        // localStorage.setItem('expandSidebar', val);
    };
    React.useEffect(() => {
        // if (Boolean(localStorage['expandSidebar']) == true) {
        //     setExpandSidebar(Boolean(localStorage['expandSidebar']));
        // }
        var sidebarData = [
            {
                label: 'Dataroom',
                link: '/dataroom',
                icon: <DocumentFolder20Regular />,
            },
            {
                label: 'Info Requests',
                link: '/irl',
                icon: <ChatBubblesQuestion20Regular />,
            },
            {
                label: 'Wizard',
                link: '/wizard',
                icon: <Wand20Regular />,
            },
            {
                label: 'Maturity Index',
                link: '/maturity-index',
                icon: (
                    <div style={{ height: 20, width: 20 }}>
                        <Step16Regular />
                    </div>
                ),
            },
            {
                label: 'Security Index',
                link: '/security-index',
                icon: <ShieldKeyhole20Regular />,
            },
            {
                label: 'Team-Analyser',
                link: '/team-analyser',
                icon: <ContactCardRibbon20Regular />,
            },
            {
                label: 'Sec. Governance',
                link: '/security-governance',
                icon: <PeopleSettings20Regular />,
            },
            {
                label: 'Software Inv.',
                link: '/software-inventory',
                icon: <ListBar20Regular />,
            },
        ];

        if (user?.has_diligence_tools) {
            sidebarData = [
                {
                    label: 'Project Overview',
                    link: '/overview',
                    icon: <Home20Regular />,
                },

                {
                    label: 'Dataroom',
                    link: '/dataroom',
                    icon: <DocumentFolder20Regular />,
                },
                {
                    label: 'Observations',
                    link: '/observations',
                    icon: <TableSearch20Regular />,
                },
                {
                    label: 'Info Requests',
                    link: '/irl',
                    icon: <ChatBubblesQuestion20Regular />,
                },
                {
                    label: 'Tasks',
                    link: '/tasks',
                    icon: <TaskListAdd20Regular />,
                },

                {
                    label: 'Wizard',
                    link: '/wizard',
                    icon: <Wand20Regular />,
                },
                {
                    label: 'Maturity Index',
                    link: '/maturity-index',
                    icon: (
                        <div style={{ height: 20, width: 20 }}>
                            <Step16Regular />
                        </div>
                    ),
                },
                {
                    label: 'Security Index',
                    link: '/security-index',
                    icon: <ShieldKeyhole20Regular />,
                },
                {
                    label: 'Team-Analyser',
                    link: '/team-analyser',
                    icon: <ContactCardRibbon20Regular />,
                },
                {
                    label: 'Sec. Governance',
                    link: '/security-governance',
                    icon: <PeopleSettings20Regular />,
                },
                {
                    label: 'Software Inv.',
                    link: '/software-inventory',
                    icon: <ListBar20Regular />,
                },
            ];
        }

        setLinks(sidebarData?.filter(item => item));
    }, []);

    return (
        <div className={styles.sidebarBody} style={{ width: expandSidebar ? 188 : 48 }}>
            <div style={{ width: expandSidebar && 188 }} className={styles.menuBody + ' d-flex justify-content-between'}>
                {expandSidebar && (
                    <span onClick={() => handleChangeSidebar(false)} className={styles.menuLabel + ' ' + styles.currentScreenText}>
                        {location?.pathname && links?.find(item => item?.link === location?.pathname)?.label}
                    </span>
                )}
                {expandSidebar ? <ChevronLeft20Regular onClick={() => handleChangeSidebar(false)} /> : <ChevronRight20Regular onClick={() => handleChangeSidebar(true)} />}
            </div>

            {links?.map(item => (
                <div
                    onClick={() => navigate(item?.link)}
                    className={styles.menuBody}
                    style={{ width: expandSidebar ? 188 : 48, color: location?.pathname?.includes(item?.link) && 'var(--brand-secondary, #8E2DE2)' }}>
                    <Tooltip positioning="after" withArrow content={item?.label}>
                        {item?.icon}
                    </Tooltip>
                    {expandSidebar && (
                        <Text as="span" className={styles.menuLabel} style={{ color: location?.pathname?.includes(item?.link) && 'var(--brand-secondary, #8E2DE2)' }}>
                            {item?.label}
                        </Text>
                    )}
                </div>
            ))}
        </div>
    );
};

export default Sidebar;
