import React, { useRef, useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './css/index.module.css';
import AddProject from './AddProject';
import AddOrganisationDetail from './AddOrganisationDetail';
import AddBrandDetails from './AddBrandDetails';
import AddBusinessDetails from './AddBusinessDetails';
import AddPublicProfiles from './AddPublicProfiles';
import AddCompetitors from './AddCompetitors';
import AddLinkedinProfiles from './AddLinkedinProfiles';
import AddTechnologyDetails from './AddTechnologyDetails';
import { Link, Element, scrollSpy } from 'react-scroll';
const Main = () => {
    const links = ['Project details', 'Organisation details', 'Brand details', 'Business details', 'Public profiles', 'Competitors', 'CXO Profiles', 'Technology details'];
    const [selectedLink, setSelectedLink] = React.useState('Project details');
    function compareObjectsValues(obj1, obj2) {
        for (let key in obj2) {
            if (obj1?.hasOwnProperty(key)) {
                if (obj1?.[key] !== obj2[key]) {
                    return false; // Values are not equal
                }
            } else {
                return false; // Key doesn't exist in the first object
            }
        }
        return true; // All values are equal
    }

    useEffect(() => {
        scrollSpy.update();
        // console.log(elementsRefContainer);
    }, []);

    return (
        <div className={styles.editBody}>
            <div className="row m-0 p-0">
                <div className="col-sm-12 col-md-2 m-0 p-0">
                    <div className={styles.navLinkBody}>
                        {links.map(item => (
                            <div key={item} onClick={() => setSelectedLink(item)} className={(item === selectedLink && styles.selectednavItem) + ' ' + styles.navLinkItem}>
                                {item}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="col-sm-12 col-md-1 m-0 p-0"></div>
                <div className="col-sm-12 col-md-4 m-0 p-0">
                    <div id="containerElement" className={styles.formBodies}>
                        {selectedLink === 'Project details' && <AddProject compareObjectsValues={compareObjectsValues} />}
                        {selectedLink === 'Organisation details' && <AddOrganisationDetail compareObjectsValues={compareObjectsValues} />}
                        {selectedLink === 'Brand details' && <AddBrandDetails compareObjectsValues={compareObjectsValues} />}
                        {selectedLink === 'Business details' && <AddBusinessDetails compareObjectsValues={compareObjectsValues} />}
                        {selectedLink === 'Public profiles' && <AddPublicProfiles compareObjectsValues={compareObjectsValues} />}
                        {selectedLink === 'Competitors' && <AddCompetitors />}
                        {selectedLink === 'CXO Profiles' && <AddLinkedinProfiles compareObjectsValues={compareObjectsValues} />}
                        {selectedLink === 'Technology details' && <AddTechnologyDetails compareObjectsValues={compareObjectsValues} />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Main;

// const Main = () => {
//     const links = ['Project details', 'Organisation details', 'Brand details', 'Business details', 'Public profiles', 'Competitors', 'CXO Profiles', 'Technology details'];
//     const [selectedLink, setSelectedLink] = React.useState();
//     function compareObjectsValues(obj1, obj2) {
//         for (let key in obj2) {
//             if (obj1?.hasOwnProperty(key)) {
//                 if (obj1?.[key] !== obj2[key]) {
//                     return false; // Values are not equal
//                 }
//             } else {
//                 return false; // Key doesn't exist in the first object
//             }
//         }
//         return true; // All values are equal
//     }

//     useEffect(() => {
//         scrollSpy.update();
//         // console.log(elementsRefContainer);
//     }, []);

//     console.log(scrollSpy);
//     return (
//         <div className={styles.editBody}>
//             <div className="row m-0 p-0">
//                 <div className="col-sm-12 col-md-2 m-0 p-0">
//                     <div className={styles.navLinkBody}>
//                         {links.map(item => (
//                             <Link
//                                 to={item.replace(' ', '').toLocaleLowerCase()}
//                                 activeClass={styles.selectednavItem}
//                                 spy={true}
//                                 smooth={true}
//                                 key={item}
//                                 offset={-24}
//                                 duration={300}
//                                 // onSetActive={handleSetActive}
//                                 containerId="containerElement">
//                                 <div className={styles.navLinkItem}>{item}</div>
//                                 {/* (item === selectedLink && styles.selectednavItem) + ' ' +  */}
//                             </Link>
//                         ))}
//                     </div>
//                 </div>
//                 <div className="col-sm-12 col-md-1 m-0 p-0"></div>
//                 <div className="col-sm-12 col-md-4 m-0 p-0">
//                     <div id="containerElement" className={styles.formBodies}>
//                         <Element name="projectdetails">
//                             <AddProject compareObjectsValues={compareObjectsValues} />
//                         </Element>

//                         <Element name="organisationdetails">
//                             <AddOrganisationDetail compareObjectsValues={compareObjectsValues} />
//                         </Element>
//                         <Element name="branddetails">
//                             <AddBrandDetails compareObjectsValues={compareObjectsValues} />
//                         </Element>
//                         <Element name="businessdetails">
//                             <AddBusinessDetails compareObjectsValues={compareObjectsValues} />
//                         </Element>
//                         <Element name="publicprofiles">
//                             <AddPublicProfiles compareObjectsValues={compareObjectsValues} />
//                         </Element>
//                         <Element name="competitors">
//                             <AddCompetitors />
//                         </Element>
//                         <Element name="cxoprofiles">
//                             <AddLinkedinProfiles compareObjectsValues={compareObjectsValues} />
//                         </Element>
//                         <Element name="technologydetails">
//                             <AddTechnologyDetails compareObjectsValues={compareObjectsValues} />
//                         </Element>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };
