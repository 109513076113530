import React, { useState } from 'react';
import styles from './css/index.module.css';
import { Add20Regular, Globe16Regular, Edit16Regular, Info12Regular, Dismiss16Regular, MoreVertical16Regular } from '@fluentui/react-icons';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip, Menu, MenuTrigger, MenuList, MenuPopover, MenuItem } from '@fluentui/react-components';
import SoftwareInventoryForm from './SoftwareInventoryForm';
import { DeleteSoftware, GetSoftwares } from '../../redux/softwareSlice';
import DoubleConfirmationModal from '../CommonComponents/DoubleConfirmationModal/DoubleConfirmationModal';
const SecurityGovernance = () => {
    const dispatch = useDispatch();
    const { selectedProject } = useSelector(state => state.projectData);
    const { softwareInvetories } = useSelector(state => state.software);
    const [isAddView, setIsAddView] = React.useState(false);
    const [selectedSoftware, setSelectedSoftware] = React.useState();
    const [softwareMenuSelection, setMenuSelection] = React.useState('all');
    React.useEffect(() => {
        if (selectedProject) {
            dispatch(GetSoftwares());
        }
    }, [selectedProject]);
    return isAddView ? (
        <SoftwareInventoryForm setSelectedSoftware={setSelectedSoftware} selectedSoftware={selectedSoftware} setIsAddView={setIsAddView} />
    ) : (
        <div className={styles.viewBody}>
            <h3 className={styles.pageHeading}>Software Inventory</h3>
            <p className={styles.subTitle}>
                List all installed programs and applications along with their details to maintain accurate records and facilitate efficient management.
            </p>
            <div className="d-flex align-items-center justify-content-between mb-4">
                <div>
                    <select onChange={e => setMenuSelection(e.target.value)} className={'form-select ' + styles.customSelect}>
                        <option value="all">View all</option>
                        {softwareInvetories?.map(item => (
                            <option key={item?.software_name}>{item?.software_name}</option>
                        ))}
                    </select>
                </div>
                <buton onClick={() => setIsAddView(true)} className={styles.submitButton}>
                    <Add20Regular className="me-1" />
                    Add Software Record
                </buton>
            </div>
            <div className="mt-4">
                {softwareInvetories?.length === 0 && (
                    <div>
                        <div className={styles.noSoftwareDiv}>No software added</div>
                        <div className={styles.skeltonDiv}></div>
                        <div className={styles.skeltonDiv}></div>
                        <div className={styles.skeltonDiv}></div>
                        <div className={styles.skeltonDiv}></div>
                    </div>
                )}
                {softwareInvetories?.map(item => (
                    <SoftawreCard softwareMenuSelection={softwareMenuSelection} setSelectedSoftware={setSelectedSoftware} setIsAddView={setIsAddView} key={item?.id} item={item} />
                ))}
            </div>
        </div>
    );
};

const SoftawreCard = ({ item, setSelectedSoftware, setIsAddView, softwareMenuSelection }) => {
    const dispatch = useDispatch();
    const deleteNoteRef = React.useRef();
    const [viewMore, setViewMore] = React.useState(false);
    const handleOpenEditView = () => {
        setSelectedSoftware(item);
        setIsAddView(true);
    };
    const handleDelete = () => {
        dispatch(DeleteSoftware(item?.id));
    };
    React.useEffect(() => {
        if (softwareMenuSelection !== 'all') {
            setViewMore(true);
        } else setViewMore(false);
    }, [softwareMenuSelection]);
    return (
        (softwareMenuSelection === item?.software_name || softwareMenuSelection === 'all') && (
            <div className={styles.softwareBody}>
                <div className="row">
                    <div className="col-sm-12 col-md-4">
                        <p className={styles.softwareName}>
                            {item?.software_name}
                            <Globe16Regular
                                onClick={() => item?.website && window.open(item?.website, '_blank').focus()}
                                color={item?.website ? 'var(--Brand-Secondary)' : 'var(--Neutral-Gray-6)'}
                                className="ms-3"
                            />

                            {/* <Edit16Regular role="button" onClick={handleOpenEditView} color="var(--Brand-Secondary)" className="ms-2" /> */}
                            <Menu>
                                <MenuTrigger disableButtonEnhancement>
                                    <MoreVertical16Regular color="var(--Neutral-Gray-6)" role="button" className="ms-3" />
                                </MenuTrigger>
                                <MenuPopover>
                                    <MenuList>
                                        <MenuItem onClick={handleOpenEditView}>Edit</MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                deleteNoteRef.current?.click();
                                            }}>
                                            Delete
                                        </MenuItem>
                                    </MenuList>
                                </MenuPopover>
                            </Menu>
                        </p>
                        <p className={styles.softwareOrg}>{item?.vendor}</p>
                        <p className={styles.lastUpdated}>Last updated on {item?.last_updated || 'Not Available'}</p>
                        <div className="d-flex ">
                            <p
                                onClick={() => item?.tandc_url && window.open(item?.tandc_url, '_blank').focus()}
                                style={{ color: item?.tandc_url ? 'var(--Brand-Secondary)' : 'var(--Neutral-Gray-6)' }}
                                className={styles.linkText + ' me-3'}>
                                Terms & Conditions
                            </p>
                            <p
                                onClick={() => item?.privacy_policy_url && window.open(item?.privacy_policy_url, '_blank').focus()}
                                style={{ color: item?.privacy_policy_url ? 'var(--Brand-Secondary)' : 'var(--Neutral-Gray-6)' }}
                                className={styles.linkText}>
                                Data Privacy
                            </p>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-8">
                        <div className="row">
                            <div className="col-sm-12 col-md-3">
                                <div>
                                    <p className={styles.viewLabel}>
                                        No. of licenses{' '}
                                        <Tooltip positioning="above" withArrow content="Total number of licenses purchased or available for the software.">
                                            <Info12Regular role="button" className="ms-1" />
                                        </Tooltip>
                                    </p>
                                    <p className={styles.viewValue}>{item?.num_of_licenses || 'Not Available'}</p>
                                </div>
                                <div>
                                    <p className={styles.viewLabel}>
                                        License type
                                        <Tooltip positioning="above" withArrow content="Type of license (perpetual, subscription, open-source, etc.).">
                                            <Info12Regular role="button" className="ms-1" />
                                        </Tooltip>
                                    </p>
                                    <p className={styles.viewValue + ' mb-0'}>{item?.license_type || 'Not Available'}</p>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-3">
                                <div>
                                    <p className={styles.viewLabel}>
                                        Cost per license
                                        <Tooltip positioning="above" withArrow content="Individual cost of each license.">
                                            <Info12Regular role="button" className="ms-1" />
                                        </Tooltip>
                                    </p>
                                    <p className={styles.viewValue}>
                                        {item?.currency}
                                        {item?.license_cost || 'Not Available'}
                                    </p>
                                </div>
                                <div>
                                    <p className={styles.viewLabel}>
                                        Contract start date
                                        <Tooltip positioning="above" withArrow content="Date when the software license agreement starts.">
                                            <Info12Regular role="button" className="ms-1" />
                                        </Tooltip>
                                    </p>
                                    <p className={styles.viewValue + ' mb-0'}>{item?.contract_start || 'Not Available'}</p>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-3">
                                <div>
                                    <p className={styles.viewLabel}>
                                        Annual cost
                                        <Tooltip positioning="above" withArrow content="Total annual cost for all licenses, including additional fees">
                                            <Info12Regular role="button" className="ms-1" />
                                        </Tooltip>
                                    </p>
                                    <p className={styles.viewValue}>
                                        {' '}
                                        {item?.currency}
                                        {item?.yearly_cost || 'Not Available'}
                                    </p>
                                </div>
                                <div>
                                    <p className={styles.viewLabel}>
                                        Contract end date
                                        <Tooltip positioning="above" withArrow content="Date when the software license agreement ends.">
                                            <Info12Regular role="button" className="ms-1" />
                                        </Tooltip>
                                    </p>
                                    <p className={styles.viewValue + ' mb-0'}>{item?.contract_end || 'Not Available'}</p>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-3">
                                <div style={{ marginBottom: '37px', minHeight: 16.8 }}>
                                    <p onClick={() => setViewMore(!viewMore)} className={styles.linkText} style={{ float: viewMore && 'right' }}>
                                        {!viewMore ? 'View More' : <Dismiss16Regular />}
                                    </p>
                                </div>
                                <div>
                                    <p className={styles.viewLabel}>
                                        Last updated on
                                        <Tooltip positioning="above" withArrow content="Date when the software was last updated.">
                                            <Info12Regular role="button" className="ms-1" />
                                        </Tooltip>
                                    </p>
                                    <p className={styles.viewValue + ' mb-0'}>{item?.software_last_updated || 'Not Available'}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DoubleConfirmationModal
                    handleAction={handleDelete}
                    message={'Deleting a Software Record removes all associated entries from the project, and this action is irreversible.'}>
                    <div ref={deleteNoteRef} />
                </DoubleConfirmationModal>
                {viewMore && (
                    <div className={styles.viewmoreBody}>
                        <div className="row">
                            <div className="col-sm-12 col-md-4">
                                <div>
                                    <p className={styles.viewLabel}>
                                        Compliance status
                                        <Tooltip positioning="above" withArrow content="Indicates compliance with relevant standards and regulations.">
                                            <Info12Regular role="button" className="ms-1" />
                                        </Tooltip>
                                    </p>
                                    <p className={styles.viewValue}>{item?.compliance || 'Not Available'}</p>
                                </div>
                                <div>
                                    <p className={styles.viewLabel}>
                                        Support level
                                        <Tooltip positioning="above" withArrow content="Details of the support provided by the vendor.">
                                            <Info12Regular role="button" className="ms-1" />
                                        </Tooltip>
                                    </p>
                                    <p className={styles.viewValue}>{item?.support_level || 'Not Available'}</p>
                                </div>
                                <div>
                                    <p className={styles.viewLabel}>
                                        Internal contact
                                        <Tooltip positioning="above" withArrow content="Person or department managing this software.">
                                            <Info12Regular role="button" className="ms-1" />
                                        </Tooltip>
                                    </p>
                                    <p className={styles.viewValue + ' mb-0'}>{item?.internal_contact || 'Not Available'}</p>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-8">
                                <div>
                                    <p className={styles.viewLabel}>
                                        Novation terms
                                        <Tooltip positioning="above" withArrow content="Specific terms related to the transfer of the contract or license.">
                                            <Info12Regular role="button" className="ms-1" />
                                        </Tooltip>
                                    </p>
                                    <p className={styles.viewValue}>{item?.novation_terms || 'Not Available'}</p>
                                </div>
                                <div>
                                    <p className={styles.viewLabel}>
                                        Usage metrics
                                        <Tooltip positioning="above" withArrow content="Data on the frequency of software usage.">
                                            <Info12Regular role="button" className="ms-1" />
                                        </Tooltip>
                                    </p>
                                    <p className={styles.viewValue}>{item?.usage_metrics || 'Not Available'}</p>
                                </div>
                                <div>
                                    <p className={styles.viewLabel}>
                                        Notes / Comments
                                        <Tooltip positioning="above" withArrow content="Additional information, observations, or specific requirements.">
                                            <Info12Regular role="button" className="ms-1" />
                                        </Tooltip>
                                    </p>
                                    <p className={styles.viewValue}>{item?.notes || 'Not Available'}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    );
};

export default SecurityGovernance;
