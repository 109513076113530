import React from 'react';
import MaturityIndex from '../components/MaturityIndex';
import Layout from '../components/Layout';
import Pageheader from '../utils/headerHelment';

const MaturityIndexPage = () => {
    return (
        <>
            <Pageheader title="LENS - Maturity Index" />
            <Layout>
                <MaturityIndex />
            </Layout>
        </>
    );
};

export default MaturityIndexPage;
