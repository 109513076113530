import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../apiServices/axios';
import { IRL_ITEMS, AZ_SEARCH, DATAROOM_WORKSTREAM, DEFAULT_WORKSTREAMS, DEFAULT_FOLDERS, IRL_DOC } from '../apiServices/api_routes';
import { toast } from 'react-toastify';

export const GetDataroomItemInfo = createAsyncThunk('GET_IRL_ITEMS', async id => {
    const data = await axiosInstance.get(IRL_ITEMS + localStorage['project_id'] + '/' + id + '/');
    return data;
});
export const PatchWSFolder = createAsyncThunk('PATCH_IRL_ITEMS', async body => {
    const data = await axiosInstance.patch(IRL_ITEMS + localStorage['project_id'] + '/' + body?.workstream + '/' + body?.id + '/', body);
    return data;
});
export const PostWSFolder = createAsyncThunk('POST_IRL_ITEMS', async (body, { rejectWithValue }) => {
    try {
        const data = await axiosInstance.post(IRL_ITEMS + localStorage['project_id'] + '/' + body?.workstream + '/', body);
        return data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const PostAllWSFolders = createAsyncThunk('POST_WS_FODLERS_ALL', async workstream => {
    const data = await axiosInstance.post(IRL_ITEMS + localStorage['project_id'] + '/' + workstream + '/request_all/');
    return data;
});

export const DeleteWSFolder = createAsyncThunk('DELETE_IRL_ITEMS', async (body, { rejectWithValue }) => {
    try {
        const data = await axiosInstance.delete(IRL_ITEMS + localStorage['project_id'] + '/' + body?.workstream + '/' + body.id + '/');
        return data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const GetFilesSearch = createAsyncThunk('GET/GetFilesSearch', async ({ query }) => {
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('api-key', process.env.REACT_APP_AZURE_SEARCH_KEY);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
    };
    const data = await fetch(`https://${AZ_SEARCH}.search.windows.net/indexes/azureblob-index/docs?search=${localStorage['project_id']}${query}`, requestOptions);
    const res = await data.json();
    return res;
});
export const GetSearchByPost = createAsyncThunk('POST/GetSearchByPost', async ({ workstream, formData, searchText }, { rejectWithValue }) => {
    try {
        const data = await axiosInstance.post(IRL_DOC + localStorage['project_id'] + '/' + workstream + '/search/?search_term=' + searchText, formData);
        return data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const GetWorkstreams = createAsyncThunk('GET_DATAROOM_WORKSTREAM', async () => {
    const data = await axiosInstance.get(DATAROOM_WORKSTREAM + localStorage['project_id'] + '/');
    return data;
});
export const GetWorkstreamsDefaults = createAsyncThunk('GET_DEFAULT_WORKSTREAMS', async () => {
    const data = await axiosInstance.get(DEFAULT_WORKSTREAMS);
    return data;
});
export const PostWorkstream = createAsyncThunk('POST_DATAROOM_WORKSTREAM', async body => {
    const data = await axiosInstance.post(DATAROOM_WORKSTREAM + localStorage['project_id'] + '/', body);
    return data;
});
export const PatchWorkstream = createAsyncThunk('PATCH_DATAROOM_WORKSTREAM', async body => {
    const data = await axiosInstance.patch(DATAROOM_WORKSTREAM + localStorage['project_id'] + '/' + body?.id + '/', body);
    return data;
});
export const DeleteWorkstream = createAsyncThunk('DELETE_DATAROOM_WORKSTREAM', async id => {
    const data = await axiosInstance.delete(DATAROOM_WORKSTREAM + localStorage['project_id'] + '/' + id + '/');
    return data;
});
export const GetFolderDefaults = createAsyncThunk('GET_DEFAULT_FOLDERS', async id => {
    const data = await axiosInstance.get(DEFAULT_FOLDERS + id + '/');
    return data;
});
export const BulkFileDownload = createAsyncThunk('POST_BULK_FOLDER_DOWNLOAD', async ({ id, body }) => {
    try {
        const response = await axiosInstance.post(`/dataroom/bulk_download/${localStorage['project_id']}/${id}/`, body, {
            responseType: 'blob', // Handle the response as a Blob
            maxBodyLength: Infinity,
        });

        return response;
    } catch (error) {
        console.error('Bulk File Download Error:', error);
        throw error; // Rethrow the error for further handling
    }
});

export const irlSlice = createSlice({
    name: 'irlSlice',
    initialState: {
        items: [],
        accepted: 0,
        requested: 0,
        status: null,
        data: [],
        files: [],
        searchedFiles: [],
        workstreams: [],
        addWsLoading: false,
        default_workstreams: [],
        default_folders: [],
        folderBtnLoading: false,
        addAllFolderLoading: false,
        bulkFilesLoading: false,
        joyRideStepIndex: 0,
    },
    reducers: {
        setIRLItems: (state, { payload }) => {
            return { ...state, items: payload };
        },
        removeDataroomData: (state, { payload }) => {
            return { ...state, default_folders: [], default_workstreams: [], workstreams: [], data: [], files: [], items: [] };
        },
        setIRLItemsRecieved: (state, { payload }) => {
            let newArr = state.items.map(item => {
                if (item.id === payload?.id) {
                    return { ...item, received: payload?.flag };
                } else {
                    return item;
                }
            });
            return { ...state, items: newArr };
        },
        setJoyRideStepIndex: (state, { payload }) => {
            return { ...state, joyRideStepIndex: payload };
        },
        resetDataroomData: (state, { payload }) => {
            return {
                items: [],
                accepted: 0,
                requested: 0,
                status: null,
                data: [],
                files: [],
                searchedFiles: [],
                workstreams: [],
                addWsLoading: false,
                default_workstreams: [],
                default_folders: [],
                folderBtnLoading: false,
                addAllFolderLoading: false,
                bulkFilesLoading: false,
                joyRideStepIndex: 0,
                folderDataLoading: false,
                searchBtnLoading: false,
            };
        },
    },
    extraReducers: {
        //GetDataroomItemInfo
        [GetDataroomItemInfo.pending]: (state, action) => {
            state.status = 'loading';
            state.items = [];
            state.folderDataLoading = true;
        },
        [GetDataroomItemInfo.fulfilled]: (state, action) => {
            state.status = 'success';
            // state.items = action.payload?.data;
            state.folderDataLoading = false;
            state.items = action.payload?.data?.sort((a, b) => (a?.prefix > b?.prefix ? 1 : b?.prefix > a?.prefix ? -1 : 0));
        },
        [GetDataroomItemInfo.rejected]: (state, action) => {
            state.status = 'rejected';
            state.folderDataLoading = false;
        },
        //PatchWSFolder
        [PatchWSFolder.pending]: (state, action) => {
            state.status = 'loading';
            state.folderBtnLoading = true;
        },
        [PatchWSFolder.fulfilled]: (state, { payload }) => {
            state.status = 'success';
            state.items = state.items.map(item => {
                if (item?.id === payload.data?.id) {
                    return payload.data;
                } else return item;
            });
            state.folderBtnLoading = false;
            toast.success('Success');
        },
        [PatchWSFolder.rejected]: (state, action) => {
            state.status = 'rejected';
            state.folderBtnLoading = false;
            toast.error('Failed');
        },
        //PostWSFolder
        [PostWSFolder.pending]: (state, action) => {
            state.status = 'loading';
            state.folderBtnLoading = true;
        },
        [PostWSFolder.fulfilled]: (state, { meta, payload }) => {
            console.log({ meta, payload });
            state.status = 'success';
            state.items.push(payload.data);
            state.folderBtnLoading = false;
            if (payload?.data?.parent) {
                function updateFolderStructure(folderArray, parentId, newData) {
                    folderArray?.forEach(item => {
                        if (item?.id === parentId) {
                            item.children = [...item?.children, newData];
                        }
                        if (item?.children?.length > 0) {
                            updateFolderStructure(item?.children, parentId, newData);
                        }
                    });
                }
                updateFolderStructure(state?.items, payload?.data?.parent, payload?.data);
            }
        },
        [PostWSFolder.rejected]: (state, action) => {
            toast.error(action?.payload?.detail);
            state.status = 'rejected';
            state.folderBtnLoading = false;
        },
        //DeleteWSFolder
        [DeleteWSFolder.pending]: (state, action) => {
            state.status = 'loading';
        },
        [DeleteWSFolder.fulfilled]: (state, action) => {
            state.status = 'success';
            // state.items.push(payload.data);
            let data = [...state?.items];
            function removeFolderRecursively(folderArray, folderIdToRemove) {
                // Iterate through each folder in the array
                for (let i = 0; i < folderArray.length; i++) {
                    const folder = folderArray[i];

                    // If the current folder's ID matches the folderIdToRemove, remove it
                    if (folder.id === folderIdToRemove) {
                        folderArray.splice(i, 1);
                        i--; // Decrement i to adjust for the removed element
                    } else {
                        // Recursively search and remove the folder from children arrays
                        removeFolderRecursively(folder.children, folderIdToRemove);
                    }
                }
            }
            removeFolderRecursively(data, action?.meta?.arg?.id);
            state.items = data;
            // state.items = state.items?.filter(item => action?.meta?.arg?.id !== item?.id);
            toast.success('Success');
        },
        [DeleteWSFolder.rejected]: (state, action) => {
            console.log(action);
            toast.error(action?.payload?.detail);
            state.status = 'rejected';
        },

        //GetFilesSearch

        [GetFilesSearch.fulfilled]: (state, action) => {
            state.status = 'success';
            state.files = action.payload.value;
            // state.files = action.payload.value?.sort((objA, objB) => Number(new Date(objA?.metadata_creation_date)) - Number(new Date(objB?.metadata_creation_date)));
        },
        //GetSearchByPost
        [GetSearchByPost.pending]: (state, action) => {
            state.searchBtnLoading = true;
        },
        [GetSearchByPost.fulfilled]: (state, action) => {
            state.status = 'success';
            state.searchBtnLoading = false;
            console.log(action);
            state.searchedFiles = action.payload.data?.sort((objA, objB) => Number(new Date(objA?.metadata_creation_date)) - Number(new Date(objB?.metadata_creation_date)));
        },
        [GetSearchByPost.rejected]: (state, action) => {
            state.searchBtnLoading = false;
        },
        //GetWorkstreams
        [GetWorkstreams.pending]: (state, action) => {
            state.status = 'loading';
        },
        [GetWorkstreams.fulfilled]: (state, { payload }) => {
            state.workstreams = payload?.data;
            state.status = 'success';
        },
        [GetWorkstreams.rejected]: (state, action) => {
            state.status = 'rejected';
        },

        //PostWorkstream
        [PostWorkstream.pending]: (state, action) => {
            state.status = 'loading';
            state.addWsLoading = true;
        },
        [PostWorkstream.fulfilled]: (state, { payload }) => {
            // state.workstreams = payload?.data;
            state.status = 'success';
            state.workstreams.push(payload?.data);
            state.addWsLoading = false;
            toast.success('Success');
        },
        [PostWorkstream.rejected]: (state, action) => {
            state.status = 'rejected';
            state.addWsLoading = false;
            toast.error('Failed');
        },
        //PatchWorkstream
        [PatchWorkstream.pending]: (state, action) => {
            state.status = 'loading';
            state.addWsLoading = true;
        },
        [PatchWorkstream.fulfilled]: (state, { payload }) => {
            state.status = 'success';
            state.workstreams = state.workstreams?.map(item => {
                if (item?.id === payload?.data?.id) return payload?.data;
                else return item;
            });
            state.addWsLoading = false;
            toast.success('Success');
        },
        [PatchWorkstream.rejected]: (state, action) => {
            state.status = 'rejected';
            state.addWsLoading = false;
            toast.error('Failed');
        },
        //DeleteWorkstream
        [DeleteWorkstream.pending]: (state, action) => {
            state.status = 'loading';
        },
        [DeleteWorkstream.fulfilled]: (state, { meta }) => {
            state.status = 'success';
            state.workstreams = state.workstreams?.filter(item => meta?.arg !== item?.id);
            toast.success('Success');
        },
        [DeleteWorkstream.rejected]: (state, action) => {
            state.status = 'rejected';
            toast.error('Failed');
        },
        //GetWorkstreamsDefaults
        [GetWorkstreamsDefaults.pending]: (state, action) => {
            state.status = 'loading';
        },
        [GetWorkstreamsDefaults.fulfilled]: (state, { payload }) => {
            state.default_workstreams = payload.data;
            state.status = 'success';
        },
        [GetWorkstreamsDefaults.rejected]: (state, action) => {
            state.status = 'rejected';
        },

        //GetFolderDefaults
        [GetFolderDefaults.pending]: (state, action) => {
            state.status = 'loading';
        },
        [GetFolderDefaults.fulfilled]: (state, { payload }) => {
            state.status = 'success';
            state.default_folders = payload.data;
        },
        [GetFolderDefaults.rejected]: (state, action) => {
            state.status = 'rejected';
        },
        //PostAllWSFolders
        [PostAllWSFolders.pending]: (state, action) => {
            state.status = 'loading';
            state.addAllFolderLoading = true;
        },
        [PostAllWSFolders.fulfilled]: (state, { payload }) => {
            state.status = 'success';
            state.items = payload.data;
            state.addAllFolderLoading = false;
            // console.log(payload);
        },
        [PostAllWSFolders.rejected]: (state, action) => {
            state.status = 'rejected';
            state.addAllFolderLoading = false;
        },
        //BulkFileDownload
        [BulkFileDownload.fulfilled]: (state, { payload }) => {
            // const url = window.URL.createObjectURL(new Blob([payload?.data], { type: 'application/zip' }));
            // const link = document.createElement('a');
            // link.href = url;
            // link.setAttribute('download', 'fileName.zip');
            // document.body.appendChild(link);
            // link.click();
            // link.parentNode.removeChild(link);
            // state.status = 'rejected';
            // state.addAllFolderLoading = false;
        },
    },
});
export const { setIRLItems, setIRLItemsRecieved, removeDataroomData, setJoyRideStepIndex, resetDataroomData } = irlSlice.actions;
export default irlSlice.reducer;
