import React from 'react';
import RequestInvite from '../components/RequestInvite';
import Pageheader from '../utils/headerHelment';

const RequestInvitePage = () => {
    return (
        <>
            <Pageheader title="LENS - Request Invite" />
            <RequestInvite />
        </>
    );
};

export default RequestInvitePage;
