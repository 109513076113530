import React, { useState, useEffect } from 'react';
import styles from './css/Register.module.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Pageheader from '../../utils/headerHelment';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { API_BASE_URL, REGISTER_USER } from '../../apiServices/api_routes';
import { toast } from 'react-toastify';
import { isObject } from '../../utils/commonUtils';
import authService from '../../apiServices/tokenservices/auth.service';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import ReactTooltip from 'react-tooltip';
import Logo from '../../assets/longLogoBright.png';
import { Eye16Regular, EyeOff16Regular, SpinnerIos16Regular } from '@fluentui/react-icons';
import CountryCode from './CountryCode.json';

const Register = () => {
    const params = useParams();
    const user = useSelector(state => state.user);
    const [userData, setUserData] = useState();
    const [ip, setIP] = useState('');
    const navigate = useNavigate();
    const [showExpireView, setShowExpireView] = React.useState(false);
    const [showpassword, setShowpassword] = useState(false);
    const [showPasswordView, setShowPasswordView] = React.useState(false);
    const [iAgree, setIAgree] = React.useState(false);
    const [showSecure, setShowSecure] = React.useState(false);
    const [formData, setFormData] = React.useState({
        full_name: '',
        job_title: '',
        password: '',
        organisation: '',
    });
    useEffect(() => {
        user?.isLoggedIn && navigate('/project');
    }, []);

    useEffect(() => {
        handleGetIp();
    }, []);
    const handleGetIp = async () => {
        try {
            fetch('https://geolocation-db.com/json/', {
                headers: {
                    accept: 'application/json, text/plain, */*',
                    'accept-language': 'en-US,en;q=0.9,de-DE;q=0.8,de;q=0.7',
                    'sec-ch-ua': '" Not A;Brand";v="99", "Chromium";v="101", "Google Chrome";v="101"',
                    'sec-ch-ua-mobile': '?0',
                    'sec-ch-ua-platform': '"macOS"',
                    'sec-fetch-dest': 'empty',
                    'sec-fetch-mode': 'cors',
                    'sec-fetch-site': 'cross-site',
                },
                referrerPolicy: 'strict-origin-when-cross-origin',
                body: null,
                method: 'GET',
                mode: 'cors',
                credentials: 'omit',
            })
                .then(response => response.json())
                .then(data => {
                    setIP(data?.IPv4);
                });
        } catch (error) {}
    };
    const handleChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleSubmit = async e => {
        e.preventDefault();
        const { full_name, job_title, password, organisation } = formData;
        const missingFields = [];

        if (!full_name.trim()) {
            missingFields.push('Full Name');
        }

        if (!job_title.trim()) {
            missingFields.push('Job Title');
        }

        if (!password.trim()) {
            missingFields.push('Password');
        }

        if (!organisation.trim()) {
            missingFields.push('Organisation');
        }
        if (missingFields.length > 0) {
            missingFields?.map(t => toast.error(t + ' is required to proceed.'));
            return;
        }

        setShowSecure(true);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        const effectAsync = async () => {
            if (params?.key) {
                try {
                    const resp = await axios.post(`${API_BASE_URL}accounts/invitations/accept-invite/${params?.key}/`);
                    console.log({ resp });
                    if (resp?.data?.message === 'this invitation has already been accepted. Please login') {
                        toast.error(resp?.data?.message);
                        navigate('/login');
                    }
                    if (resp?.data?.message === 'This invitation has expired please request a new invitation here') setShowExpireView(true);
                    setUserData(resp?.data);
                } catch (error) {}
                // /accounts/invitations/{id}/send-invite/
            }
        };
        effectAsync();
    }, [params]);
    return showExpireView ? (
        <ExpireView />
    ) : (
        !user?.isLoggedIn && (
            <div className={styles.body}>
                <div className={styles.dotedBg}>
                    <div className={styles.formCard}>
                        <div className={styles.formHeader}>
                            <img src={Logo} width={73} alt="lens-logo" />
                        </div>
                        {showSecure && <SecureAccount userData={userData} data={formData} setShowSecure={setShowSecure} />}
                        {!showSecure && showPasswordView ? (
                            <div className={styles.formBody}>
                                <h5 className={styles.formLabel}>Set Password</h5>
                                <p className={styles.formSubtitle}>Create a unique, memorable password for your account</p>
                                <div>
                                    <p className={styles.fieldLabel}>
                                        Enter Password <span style={{ color: '#B10E1C' }}>*</span>
                                    </p>
                                    <form onSubmit={handleSubmit}>
                                        <div className="input-field">
                                            <input
                                                value={formData?.password}
                                                name="password"
                                                required
                                                type={showpassword ? 'text' : 'password'}
                                                onChange={handleChange}
                                                className={'form-control ' + styles.inputField}
                                            />
                                            {showpassword ? (
                                                <Eye16Regular onClick={() => setShowpassword(!showpassword)} className="icon icon-right svg-icons" />
                                            ) : (
                                                <EyeOff16Regular onClick={() => setShowpassword(!showpassword)} className="icon icon-right svg-icons" />
                                            )}
                                        </div>{' '}
                                        <div className="d-flex align-items-center justify-content-between">
                                            <button disabled={!formData?.password} type="submit" className={styles.submitBtn}>
                                                Save
                                            </button>
                                            <span role="button" onClick={() => setShowPasswordView(false)} className={styles.secondaryBtnLink}>
                                                Back
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        ) : (
                            !showSecure && (
                                <div className={styles.formBody}>
                                    <h5 className={styles.formLabel + ' mb-0'}>Set up account for</h5>
                                    <p className={styles.formSubtitle}>{userData?.email}</p>
                                    <form
                                        onSubmit={e => {
                                            e.preventDefault();
                                            setShowPasswordView(true);
                                        }}>
                                        <div>
                                            <p className={styles.fieldLabel}>
                                                Full Name <span style={{ color: '#B10E1C' }}>*</span>
                                            </p>
                                            <input value={formData?.full_name} name="full_name" onChange={handleChange} required className={'form-control ' + styles.inputField} />
                                        </div>
                                        <div>
                                            <p className={styles.fieldLabel}>
                                                Organisation <span style={{ color: '#B10E1C' }}>*</span>
                                            </p>
                                            <input
                                                value={formData?.organisation}
                                                name="organisation"
                                                onChange={handleChange}
                                                required
                                                className={'form-control ' + styles.inputField}
                                            />
                                        </div>
                                        <div>
                                            <p className={styles.fieldLabel}>
                                                Role <span style={{ color: '#B10E1C' }}>*</span>
                                            </p>
                                            <input value={formData?.job_title} name="job_title" onChange={handleChange} required className={'form-control ' + styles.inputField} />
                                        </div>
                                        <div onClick={() => setIAgree(!iAgree)} role="button" className={styles.rememberMeSection}>
                                            <div>
                                                <input checked={iAgree} required type="checkbox" />
                                            </div>
                                            <label className={styles.rememberBeText}>
                                                I agree to the Lens{' '}
                                                <a href="https://www.iubenda.com/terms-and-conditions/15531073" target="_blank" rel="noreferrer">
                                                    Terms
                                                </a>{' '}
                                                and acknowledge the{' '}
                                                <a
                                                    href="https://www.iubenda.com/privacy-policy/15531073/legal?ifr=true&height=650&newmarkup=yes&an=no"
                                                    target="_blank"
                                                    rel="noreferrer">
                                                    Privacy Policy.
                                                </a>{' '}
                                                for information on data usage and protection.
                                            </label>
                                        </div>
                                        <div>
                                            <button className={styles.submitBtn} type="submit">
                                                Next
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            )
                        )}
                    </div>
                </div>
                <div className={styles.footer}>
                    <a href="https://www.iubenda.com/terms-and-conditions/15531073" target="_blank" rel="noreferrer">
                        Terms of Use
                    </a>
                    <a href="https://www.iubenda.com/privacy-policy/15531073/legal?ifr=true&height=650&newmarkup=yes&an=no" target="_blank" rel="noreferrer">
                        Privacy & Cookies
                    </a>
                </div>
            </div>
        )
    );
};

const SecureAccount = ({ data, setShowSecure, userData }) => {
    const params = useParams();
    const navigate = useNavigate();
    const [registerLoading, setRegisterLoading] = React.useState(false);
    const [formData, setFormData] = React.useState({
        code: '',
        number: '',
    });
    const handleSubmit = async () => {
        setRegisterLoading(true);
        const { full_name, job_title, password, organisation } = data;
        let body = {};
        if (userData?.VDR) {
            body = {
                group_type: userData?.group_type,
                project: userData.project,
                full_name: full_name,
                email: userData?.email,
                key: params?.key,
                password: password,
                job_title: job_title,
                organisation: organisation,
                vdr: userData?.VDR,
                mfa_method: 'sms_twilio',
                phone_number: formData?.code + formData?.number,
            };
        } else {
            body = {
                email: userData?.email,
                full_name: full_name,
                key: params?.key,
                password: password,
                job_title: job_title,
                organisation: organisation,
                mfa_method: 'sms_twilio',
                phone_number: formData?.code + formData?.number,
            };
        }

        try {
            await axios.post(`${API_BASE_URL}${REGISTER_USER}`, body);
            toast.success('Success');
            setRegisterLoading(false);
            try {
                const res = await authService.login(userData?.email?.toLowerCase(), data?.password, false);
                if (res?.ephemeral_token) {
                    navigate('/authenticate', {
                        state: { ephemeral_token: res?.ephemeral_token, type: res?.method },
                    });
                }
            } catch (error) {}
            // const res = await authService.login(userData?.email, password, ip);
            // if (res?.access) {
            //     navigate('/secure', {
            //         state: {
            //             email: userData?.email,
            //             access: res?.access,
            //             password: password,
            //         },
            //     });
            // } else {
            //     navigate('/authenticate', {
            //         state: { ephemeral_token: res?.ephemeral_token },
            //     });
            // }
            // navigate('/confirm-mail', {
            //     state: {
            //         email: body.email,
            //     },
            // });
        } catch (error) {
            setRegisterLoading(false);
            const res = error?.response?.data;
            if (isObject(res)) {
                Object.entries(res).forEach(([key, value]) => {
                    key !== 'email' &&
                        key !== 'job_title' &&
                        toast.error(
                            <span
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '20px',
                                }}>
                                {<span>{value}</span>}
                            </span>,
                            {
                                position: 'bottom-right',
                                autoClose: 5000,
                                hideProgressBar: true,
                                closeOnClick: true,
                                pauseOnHover: false,
                                draggable: true,
                                progress: undefined,
                            },
                        );
                });
            }
        }
    };
    return (
        <div className={styles.formBody}>
            <h5 className={styles.formLabel}>Set up 2FA</h5>
            <p className={styles.formSubtitle}>
                For heightened security measures, add your mobile number below to receive verification code. We will use this method by default. Your mobile number will only be
                used for account security. Standard SMS charges will apply.
            </p>
            <div className="d-flex">
                <div style={{ width: '120px' }} className="me-2">
                    <div>
                        <p className={styles.fieldLabel}>Code</p>
                        {/* <input value={formData?.email} name="email" onChange={handleChange} type="email" className={'form-control ' + styles.inputField} /> */}
                        <select
                            name="swot"
                            value={formData?.code}
                            onChange={e =>
                                setFormData({
                                    ...formData,
                                    code: e.target.value,
                                })
                            }
                            className={'form-select ' + styles.inputField}
                            aria-label=".form-select-sm example">
                            <option value="">Please Select</option>
                            {CountryCode?.map(item => (
                                <option value={item?.dial_code}>{item?.dial_code}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="w-100 ">
                    <div>
                        <p className={styles.fieldLabel}>Mobile Number</p>
                        <input
                            type="number"
                            placeholder="Mobile Number"
                            value={formData?.number}
                            onChange={e =>
                                setFormData({
                                    ...formData,
                                    number: e.target.value,
                                })
                            }
                            className={'form-control ' + styles.inputField}
                        />
                    </div>
                </div>
            </div>

            <div className="d-flex align-items-center justify-content-between mt-4">
                <button onClick={handleSubmit} disabled={!formData?.code || !formData?.number || registerLoading} className={styles.submitBtn}>
                    Activate account {registerLoading && <SpinnerIos16Regular className="rotateLoader ms-1" />}
                </button>
                <span role="button" onClick={() => setShowSecure(false)} className={styles.secondaryBtnLink}>
                    Back
                </span>
            </div>
        </div>
    );
};

const ExpireView = ({ key }) => {
    // const params = useParams();
    const handleSubmit = async () => {
        // const resp = await axios.post(`${API_BASE_URL}accounts/invitations/request_reset_invitation/`, { key: params?.key });
        // console.log(resp);
    };
    // console.log(params?.key);
    return (
        <div className={styles.body}>
            <div className={styles.dotedBg}>
                <div className={styles.formCard}>
                    <div className={styles.formHeader}>
                        <img src={Logo} width={73} alt="lens-logo" />
                    </div>
                    <div className={styles.formBody}>
                        <h5 className={styles.formLabel}>Invitation Link Expired</h5>
                        <p className={styles.formSubtitle + ' mt-4'}>
                            The invitation link in your email was valid for 7 days for security reasons. As it's been more than a week since you received the email, the access key
                            has expired. To regain access, please request a new invitation link below.
                        </p>
                        <a href="mailto:duediligence@beyond-ma.com">
                            <button onClick={handleSubmit} className={styles.submitBtn}>
                                Request New Invitation
                            </button>
                        </a>
                    </div>
                </div>
            </div>
            <div className={styles.footer}>
                <a href="https://www.iubenda.com/terms-and-conditions/15531073" target="_blank" rel="noreferrer">
                    Terms of Use
                </a>
                <a href="https://www.iubenda.com/privacy-policy/15531073/legal?ifr=true&height=650&newmarkup=yes&an=no" target="_blank" rel="noreferrer">
                    Privacy & Cookies
                </a>
            </div>
        </div>
    );
};

export default Register;
