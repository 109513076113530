import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './drop-file-input.css';
import { ImageConfig } from './config/ImageConfig';
import { FaPlusCircle } from 'react-icons/fa';
import { isValidExtensionToUpload } from '../../../utils/commonUtils';
import { validExtensions } from '../../../utils/misc';

const DropFileInput = props => {
    const wrapperRef = useRef(null);
    const [fileList, setFileList] = useState([]);
    const onDragEnter = () => {
        wrapperRef.current.classList.add('dragover');
    };
    const onDragLeave = () => {
        wrapperRef.current.classList.remove('dragover');
    };
    const onDrop = () => {
        wrapperRef.current.classList.remove('dragover');
    };
    const onFileDrop = e => {
        if (props?.multiple) {
            const newFiles = e.target.files;
            const validFiles = Array.from(newFiles).filter(file => {
                const extension = file.name.split('.').pop().toLowerCase();
                return isValidExtensionToUpload(extension);
            });
            setFileList(validFiles);
            props.onFileChange(validFiles);
        } else {
            const newFile = e.target.files[0];
            const ext = newFile.name.substring(newFile.name.lastIndexOf('.') + 1);
            if (isValidExtensionToUpload(ext)) {
                if (newFile) {
                    const updatedList = [newFile];
                    setFileList(updatedList);
                    props.onFileChange(updatedList);
                }
            } else {
                alert('unSupported File Extension');
            }
        }
    };

    const fileRemove = file => {
        const updatedList = [...fileList];
        updatedList.splice(fileList.indexOf(file), 1);
        setFileList(updatedList);
        props.onFileChange(updatedList);
    };

    return (
        <>
            {props.hideOnSelect ? (
                fileList?.length === 0 && (
                    <div ref={wrapperRef} className="drop-file-input" onDragEnter={onDragEnter} onDragLeave={onDragLeave} onDrop={onDrop}>
                        <div id="logo-input-label" className="input-group-text d-flex flex-column form-control uploadWrap" htmlhtmlFor="logo">
                            <FaPlusCircle className="fas fa-plus-circle icon" />
                            <strong>{props.placeholder}</strong>
                            <small>
                                <span>
                                    Drag &amp; Drop or <u>Browse</u>{' '}
                                </span>

                                <span>(.PDF or .DOCX)</span>
                            </small>
                        </div>
                        <input type="file" accept={validExtensions.join(',')} multiple={props?.multiple} onChange={onFileDrop} />
                    </div>
                )
            ) : (
                <>
                    <div ref={wrapperRef} style={{ paddingTop: 14 }} className="drop-file-input" onDragEnter={onDragEnter} onDragLeave={onDragLeave} onDrop={onDrop}>
                        <div id="logo-input-label" className="d-flex justify-content-center align-items-center uploadWrap" htmlhtmlFor="logo">
                            <div style={{ color: 'rgb(192, 204, 216)' }} className="text-center ">
                                <FaPlusCircle size={30} className="fas fa-plus-circle icon" />
                                <br />
                                <p className="mb-0 mt-2">{props.placeholder}</p>
                                {/* <br /> */}
                                {props?.showExtension && (
                                    <>
                                        <p>(.PDF .DOCX or .PPTX)</p>
                                    </>
                                )}
                                {/*  */}
                            </div>
                        </div>
                        <input type="file" accept={validExtensions.join(',')} multiple={props?.multiple} onChange={props?.customUploadFunction ? props.onFileChange : onFileDrop} />
                    </div>
                </>
            )}

            {props?.preview && fileList?.length > 0 ? (
                <div className="drop-file-preview">
                    <p className="drop-file-preview__title">Ready to upload</p>
                    {fileList?.map((item, index) => (
                        <div key={index} className="drop-file-preview__item">
                            <img src={ImageConfig[item.type.split('/')[1]] || ImageConfig['default']} alt="" />
                            <div className="drop-file-preview__item__info">
                                <p>{item.name}</p>
                            </div>
                            <span className="drop-file-preview__item__del" onClick={() => fileRemove(item)}>
                                x
                            </span>
                        </div>
                    ))}
                </div>
            ) : null}
        </>
    );
};

DropFileInput.propTypes = {
    onFileChange: PropTypes.func,
};

export default DropFileInput;
