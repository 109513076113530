import React from 'react';
import styles from './css/index.module.css';

import {
    Folder20Regular,
    ChevronDown20Regular,
    Delete16Regular,
    TextEditStyle16Regular,
    MoreVertical20Regular,
    DataScatter20Regular,
    Search20Regular,
    Search24Regular,
    ChevronDown24Regular,
    SpinnerIos20Regular,
} from '@fluentui/react-icons';
import { Dialog, DialogBody, DialogContent, DialogSurface, DialogTrigger, Menu, MenuDivider, MenuItem, MenuList, MenuPopover, MenuTrigger } from '@fluentui/react-components';
import { DeleteWSFolder, DeleteWorkstream, GetSearchByPost } from '../../redux/dataroomSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

import DoubleConfirmationModal from '../CommonComponents/DoubleConfirmationModal/DoubleConfirmationModal';

const LeftPanel = ({
    setWorkstream,
    workstream,
    selectedFolders,
    setSelectedFolders,
    handleOpenEditWorkstream,
    expandWorkstream,
    setExpandWorkstream,
    handleOpenWorkstreamSidebar,
    setShowAnalytics,
    setFilters,
}) => {
    const dispatch = useDispatch();
    const deleteWorkstreamRef = React.useRef();

    const { workstreams, items, folderDataLoading } = useSelector(state => state?.dataroom);

    const selectedFolder = selectedFolders?.length > 0 ? selectedFolders[selectedFolders?.length - 1] : null;
    const handleSelectWorkstream = item => {
        setExpandWorkstream(expandWorkstream === item?.id ? '' : item?.id);
        setWorkstream(item);
        localStorage.setItem('workstream', item?.id);
        setSelectedFolders([]);
        setShowAnalytics(false);
        setFilters();
    };
    const { data } = useSelector(state => state?.documents);
    function getSubfolderIds(folder) {
        let ids = [folder.id];
        if (folder.children && folder.children.length > 0) {
            folder.children.forEach(child => {
                ids = ids.concat(getSubfolderIds(child));
            });
        }
        return ids;
    }
    const getFilesCount = folder => {
        let docLength = 0;
        const subfolderIds = getSubfolderIds(folder);
        subfolderIds?.forEach(subFolderId => {
            docLength += data?.filter(doc => doc?.ws_folder_id === subFolderId)?.length;
        });
        return <span style={{ color: docLength === 0 && 'var(--Neutral-Gray-5)' }}>{docLength}</span>;
    };
    const handleDeleteWorkstream = async ({ item }) => {
        await dispatch(DeleteWorkstream(item?.id));
        // setWorkstream();
        // localStorage.setItem('workstream', '');
    };
    const handleOpenWorkstreamAnalytics = () => {
        setSelectedFolders([]);
        setShowAnalytics(true);
    };
    return (
        <div className={styles.leftPanelBody}>
            <div className="d-flex  justify-content-between pe-3">
                <p className={styles.workstreamLabel}>Workstreams</p>
                <SearchBarModal setFilters={setFilters} handleSelectWorkstream={handleSelectWorkstream} workstream={workstream} setShowAnalytics={setShowAnalytics} />
            </div>
            {workstreams?.length === 0 && (
                <div className={styles.panelItems}>
                    <span onClick={handleOpenWorkstreamSidebar} className={styles.addworkstreamtext}>
                        Add a Workstream to begin
                    </span>
                </div>
            )}
            {workstreams?.map(item => (
                <div key={item?.id}>
                    <div style={{ backgroundColor: selectedFolders?.length === 0 && workstream?.id === item?.id && 'rgba(142, 45, 226, 0.05)' }} className={styles.panelItems}>
                        <div onClick={() => handleSelectWorkstream(item)} className={styles.panelName}>
                            {item?.name}
                        </div>
                        {expandWorkstream === item?.id ? (
                            <Menu>
                                <MenuTrigger disableButtonEnhancement>
                                    <MoreVertical20Regular color="var(--Neutral-Gray-6)" />
                                </MenuTrigger>
                                <MenuPopover>
                                    <MenuList>
                                        <MenuItem onClick={handleOpenEditWorkstream} icon={<TextEditStyle16Regular />}>
                                            Edit Label
                                        </MenuItem>
                                        <MenuDivider />
                                        <MenuItem onClick={handleOpenWorkstreamAnalytics} icon={<DataScatter20Regular />}>
                                            Analytics
                                        </MenuItem>
                                        <MenuDivider />
                                        <MenuItem onClick={() => deleteWorkstreamRef?.current?.click()} icon={<Delete16Regular />}>
                                            Delete Workstream
                                        </MenuItem>
                                    </MenuList>
                                </MenuPopover>
                            </Menu>
                        ) : (
                            <ChevronDown20Regular onClick={() => handleSelectWorkstream(item)} />
                        )}
                    </div>
                    <div>
                        {expandWorkstream === item?.id && folderDataLoading && (
                            <div className={styles.panelFolderItem}>
                                <AiOutlineLoading3Quarters className="rotateLoader m-auto" />
                            </div>
                        )}
                        {expandWorkstream === item?.id &&
                            items?.map(
                                folder =>
                                    !folder?.parent && (
                                        <div
                                            style={{ backgroundColor: selectedFolder?.id === folder?.id && 'rgba(142, 45, 226, 0.05)' }}
                                            onClick={() => {
                                                setSelectedFolders([folder]);
                                                setShowAnalytics(false);
                                                setFilters();
                                            }}
                                            key={folder?.id}
                                            className={styles.panelFolderItem}>
                                            <div className="d-flex align-items-center">
                                                <Folder20Regular className="me-2" />
                                                <span>
                                                    {folder?.prefix} {folder?.name}
                                                </span>
                                            </div>

                                            <span className={styles.fileCount}> {getFilesCount(folder)}</span>
                                        </div>
                                    ),
                            )}
                    </div>
                </div>
            ))}
            <DoubleConfirmationModal
                handleAction={() => handleDeleteWorkstream({ item: workstream })}
                message={
                    'Deleting a Workstream will result in the simultaneous removal of ALL related Folders and Files contained within the Workstream, alongside the user permissions associated with them.'
                }>
                <div ref={deleteWorkstreamRef}></div>
            </DoubleConfirmationModal>
        </div>
    );
};
const SearchBarModal = ({ handleSelectWorkstream, workstream, setShowAnalytics, setFilters }) => {
    const { workstreams, searchBtnLoading } = useSelector(state => state?.dataroom);
    const [show, setShow] = React.useState(false);
    const [searchText, setSearchText] = React.useState('');
    const dispatch = useDispatch();
    const { data } = useSelector(state => state?.documents);

    const handleMakeSearch = async () => {
        if (searchText) {
            let formData = new FormData();
            formData.append('workstreams', JSON.stringify([workstream?.id]));
            formData.append('files', JSON.stringify(data?.map(file => file?.id)));
            await dispatch(
                GetSearchByPost({
                    searchText,
                    workstream: workstream?.id,
                    formData,
                }),
            );
            setShow(false);
            setSearchText('');
            setShowAnalytics(true);
            setFilters({ type: 'Search Results : ', value: workstream?.name, label: searchText });
        }
    };

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            handleMakeSearch();
        }
    };

    return (
        <Dialog onOpenChange={(_, e) => setShow(e?.open)} open={show}>
            <DialogTrigger disableButtonEnhancement>
                <Search20Regular onClick={() => setShow(true)} color="var(--Brand-Secondary)" role="button" />
            </DialogTrigger>
            <DialogSurface style={{ marginTop: 200, maxWidth: '50vw' }}>
                <div className="d-flex justify-content-between">
                    <div className="d-flex">
                        <div>
                            {searchBtnLoading ? (
                                <SpinnerIos20Regular className="rotateLoader" style={{ width: 24 }} />
                            ) : (
                                <Search24Regular onClick={handleMakeSearch} color="var(--Brand-Primary)" role="button" />
                            )}
                        </div>
                        <div>
                            <input
                                onKeyDown={handleKeyPress}
                                value={searchText}
                                onChange={e => setSearchText(e.target.value)}
                                className={styles.searchInput}
                                placeholder="Search..."
                            />
                        </div>
                    </div>
                    <div>
                        <Menu>
                            <MenuTrigger disableButtonEnhancement>
                                <div className={styles.wsMenuTrigger}>
                                    {workstream?.name}
                                    <ChevronDown24Regular className="ms-2" />
                                </div>
                            </MenuTrigger>
                            <MenuPopover>
                                <MenuList>
                                    {workstreams?.map(item => (
                                        <MenuItem key={workstream?.id} onClick={() => handleSelectWorkstream(item)}>
                                            {item?.name}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </MenuPopover>
                        </Menu>
                    </div>
                </div>
            </DialogSurface>
        </Dialog>
    );
};

export default LeftPanel;
