/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import WebViewer from '@pdftron/webviewer';
import Header from '../CommonComponents/BasicHeader';
import { GetDocumentUrl } from '../../redux/ndaSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { format } from 'date-fns';
import styles from './css/NDA.module.css';
import { PatchGroupMember } from '../../redux/teamSlice';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { DeleteNda, updateProject } from '../../redux/projectDataSlice';
import DoubleConfirmationModal from '../CommonComponents/DoubleConfirmationModal/DoubleConfirmationModal';
import { ChevronRight20Regular, MoreVertical20Regular, ArrowUndo20Regular, ArrowDown20Regular, Delete20Regular, Dismiss20Regular } from '@fluentui/react-icons';
import { Menu, MenuDivider, MenuItem, MenuList, MenuPopover, MenuTrigger } from '@fluentui/react-components';
export const NDAView = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [pdfUrl, setPdfUrl] = useState(null);
    const [pdfLoading, setPdfLoading] = React.useState(false);
    const viewer = React.useRef(null);
    const { projectUsersAll } = useSelector(state => state?.team);
    const { selectedProject, ndaButtonLoading } = useSelector(state => state?.projectData);
    const { user } = useSelector(state => state.user);
    const deleteModalRef = React.useRef();
    const isProjectAdmin = selectedProject?.vdr?.vdr_admin === user?.id;
    const notAdmin = selectedProject?.vdr?.vdr_admin !== user?.id;
    const userData = selectedProject?.vdr?.nda?.signees?.find(item => item?.user === user?.id);
    const isNotSigned = !userData?.nda_signed && notAdmin;
    const instanceRef = React.useRef(null);

    useEffect(() => {
        if (pdfUrl) {
            WebViewer(
                {
                    webviewerServerURL: process.env.REACT_APP_PDF_WEBVIEWER_URL,
                    licenseKey: process.env.REACT_APP_PDF_EXPRESS_KEY,
                    path: '/webviewer/lib',
                    initialDoc: pdfUrl,
                    extension: selectedProject?.vdr?.nda?.document?.split('.')[selectedProject?.vdr?.nda?.document?.split('.')?.length - 1],
                },
                viewer.current,
            )
                .then(async instance => {
                    instance.UI.disableElements(['toolsHeader']);
                    // instance.UI.disableElements(['toolbarGroup-Annotate']);
                    instance.UI.disableElements(['ribbons']);
                    instance.UI.disableElements(['toggleNotesButton']);
                    instance.UI.disableElements(['menuButton']);
                    setPdfLoading(false);
                    instanceRef.current = instance;
                })
                .catch(err => {
                    setPdfLoading(false);
                    console.log({ err });
                });
        }
    }, [pdfUrl]);
    useEffect(() => {
        const effectAsync = async () => {
            if (selectedProject && selectedProject?.vdr?.nda?.document) {
                setPdfLoading(true);
                setPdfUrl(selectedProject?.vdr?.nda?.document);
            }
        };
        effectAsync();
    }, [selectedProject?.vdr?.nda]);
    const handleDownloadFile = () => {
        console.log(instanceRef);
        console.log('function running');
        instanceRef?.current?.UI?.downloadPdf();
    };
    const handleDeleteNda = async () => {
        const res = await dispatch(DeleteNda(selectedProject?.vdr?.nda?.id));
        if (res?.type !== 'DELETE_NDA_DOC/rejected') {
            dispatch(
                updateProject({
                    ...selectedProject,
                    vdr: {
                        ...selectedProject?.vdr,
                        nda: null,
                    },
                }),
            );
            navigate('/uploadnda');
        }
    };
    const handleAccept = async () => {
        const currentUser = projectUsersAll?.find(item => item?.value?.user_id === user?.id);
        // const userData = selectedProject?.vdr?.nda?.signees?.find(item => item?.user === user?.id);
        const res = await dispatch(PatchGroupMember({ id: currentUser?.value?.group_member_id, nda_signed: true, date_nda_signed: format(new Date(), 'yyyy-MM-dd') }));
        if (res?.type === 'PATCH_GROUP_MEMBER/fulfilled') {
            // if (!userData?.tandc) navigate('/tc');
            // else
            navigate('/dataroom');
        }
    };
    return (
        <div className={styles.ndaBody}>
            <div className={styles.ndaHeader}>
                <div className="d-flex align-items-center">
                    <p className={styles.projectName}>{selectedProject?.project_name}</p>
                    <ChevronRight20Regular color="white" className="ms-2 me-2" />
                    <p className={styles.ndaTitle}>Non Disclosure Agreement</p>
                    {!isNotSigned && (
                        <Menu style={{ marginTop: '20px' }}>
                            <MenuTrigger disableButtonEnhancement>
                                <MoreVertical20Regular className="ms-2" color="white" />
                            </MenuTrigger>
                            <MenuPopover>
                                <MenuList>
                                    {/* {isProjectAdmin && (
                                        <>
                                            <MenuItem icon={<ArrowUndo20Regular />}>Replace</MenuItem>
                                            <MenuDivider />
                                        </>
                                    )} */}
                                    <MenuItem onClick={handleDownloadFile} icon={<ArrowDown20Regular />}>
                                        {' '}
                                        Download
                                    </MenuItem>
                                    {isProjectAdmin && (!selectedProject?.vdr?.nda?.signees || selectedProject?.vdr?.nda?.signees?.length === 0) && (
                                        <>
                                            <MenuDivider />
                                            <MenuItem onClick={() => deleteModalRef?.current?.click()} icon={<Delete20Regular />}>
                                                Delete
                                            </MenuItem>
                                        </>
                                    )}
                                </MenuList>
                            </MenuPopover>
                        </Menu>
                    )}
                </div>
                <div>
                    {isNotSigned && (
                        <button onClick={handleAccept} className={styles.headerButton}>
                            Agree
                        </button>
                    )}
                    <Dismiss20Regular role="button" onClick={() => navigate(-1)} className="ms-5" color="white" />
                </div>
            </div>
            <div className={styles.docContainer}>
                <div className={'webviewer'} ref={viewer}>
                    {pdfLoading && (
                        <div className={styles.loaderBody}>
                            <AiOutlineLoading3Quarters size={50} className="rotateLoader" />
                        </div>
                    )}
                </div>
            </div>
            <DoubleConfirmationModal handleAction={handleDeleteNda} message="Deleting the NDA document will permanently remove it from the Project.">
                <div ref={deleteModalRef} />
            </DoubleConfirmationModal>
        </div>
    );
};

export const NDAReview = () => {
    const { selectedProject } = useSelector(state => state?.projectData);
    const { user } = useSelector(state => state?.user);
    const { projectUsersAll } = useSelector(state => state?.team);
    const [pdfLoading, setPdfLoading] = React.useState(false);
    const navigate = useNavigate();
    const [pdf, setPdfUrl] = useState(null);
    const [showButtons, setShowButtons] = React.useState(false);
    const viewer = React.useRef(null);
    const dispatch = useDispatch();
    const handleAccept = async () => {
        const currentUser = projectUsersAll?.find(item => item?.value?.user_id === user?.id);
        // const userData = selectedProject?.vdr?.nda?.signees?.find(item => item?.user === user?.id);
        const res = await dispatch(PatchGroupMember({ id: currentUser?.value?.group_member_id, nda_signed: true, date_nda_signed: format(new Date(), 'yyyy-MM-dd') }));
        if (res?.type === 'PATCH_GROUP_MEMBER/fulfilled') {
            // if (!userData?.tandc) navigate('/tc');
            // else
            navigate('/dataroom');
        }
    };
    useEffect(() => {
        const effectAsync = async () => {
            if (selectedProject && selectedProject?.vdr?.nda?.document) {
                setPdfLoading(true);
                setPdfUrl(selectedProject?.vdr?.nda?.document);
            }
        };
        effectAsync();
    }, [selectedProject?.vdr?.nda?.document]);

    useEffect(() => {
        if (pdf) {
            WebViewer(
                {
                    licenseKey: process.env.REACT_APP_PDF_EXPRESS_KEY,
                    webviewerServerURL: process.env.REACT_APP_PDF_WEBVIEWER_URL,
                    path: '/webviewer/lib',
                    initialDoc: pdf,
                    extension: selectedProject?.vdr?.nda?.document?.split('.')[selectedProject?.vdr?.nda?.document?.split('.')?.length - 1] || 'pdf',
                },
                viewer.current,
            )
                .then(instance => {
                    setPdfLoading(false);
                    //instance.setFitMode(instance.FitMode.FitWidth);
                    const { Core } = instance;
                    instance.UI.disableElements(['toolsHeader']);
                    // instance.UI.disableElements(['toolbarGroup-Annotate']);
                    instance.UI.disableElements(['ribbons']);
                    instance.UI.disableElements(['toggleNotesButton']);
                    instance.UI.disableElements(['menuButton']);
                    Core.documentViewer.addEventListener('documentLoaded', () => {
                        setShowButtons(true);
                    });
                    Core.documentViewer.addEventListener('pageNumberUpdated', pageNumber => {});
                })
                .catch(err => {
                    setPdfLoading(false);
                });
        }
    }, [pdf]);

    return (
        <div className="bg-light full-height-cls">
            <Header />

            <div className="container">
                <div className={styles.headingCont}>
                    <p className="m-0">
                        <b>Non Disclosure Agreement</b>
                    </p>
                    {showButtons && (
                        <div>
                            <button
                                style={{ width: 172 }}
                                onClick={() => navigate('/project')}
                                className={styles.borderBtn + ' btn btn-primary btn-sm ms-3 me-3  ' + styles.smallButton}>
                                Back to Dashboard
                            </button>

                            <button style={{ width: 104 }} onClick={handleAccept} className={'btn btn-primary btn-sm  ' + styles.smallButton}>
                                I Agree
                            </button>
                        </div>
                    )}
                </div>
                <div className={styles.dateValidity}>
                    <div className={styles.dateValidityInner}>
                        <span>Valid until {selectedProject?.vdr?.nda?.expiry && format(new Date(selectedProject?.vdr?.nda?.expiry), 'dd/MM/yyyy')}</span>
                    </div>
                </div>
                <div className={styles.docCont}>
                    <div className={styles.docContInner}>
                        <div className="webviewer" ref={viewer}>
                            {pdfLoading && (
                                <div className={styles.loaderBody}>
                                    <AiOutlineLoading3Quarters size={50} className="rotateLoader" />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
