import React, { useMemo } from 'react';
import styles from './css/index.module.css';

import { ChevronDown20Regular, Dismiss16Regular } from '@fluentui/react-icons';
import { PostIRLDocuments } from '../../redux/documentsSlice';
import { setIRLItemsRecieved } from '../../redux/dataroomSlice';
import { useDispatch, useSelector } from 'react-redux';

import { toast } from 'react-toastify';

const AddLinkModal = ({ setUploadLinkSidebar, setShowSidebar, selectedFolders, workstream }) => {
    const selectedFolder = useMemo(() => {
        return selectedFolders?.length > 0 ? selectedFolders[selectedFolders.length - 1] : {};
    }, [selectedFolders]);
    const { selectedProject } = useSelector(state => state?.projectData);
    const { projectUsersAll } = useSelector(state => state?.team);
    const { user } = useSelector(state => state?.user);
    const [buttonLoading, setButtonLoading] = React.useState(false);
    const dispatch = useDispatch();

    const [formData, setFormData] = React.useState({
        link: '',
        name: '',
    });
    const handleClose = () => {
        setShowSidebar(false);
        setUploadLinkSidebar(false);
        setFormData({
            link: '',
            name: '',
        });
    };

    const handleSubmit = async () => {
        setButtonLoading(true);
        let body = new FormData();
        body.append('ws_folder', selectedFolder?.id);
        body.append('project', selectedProject?.id);
        body.append('vdr', selectedProject?.vdr?.id);
        if (selectedProject?.vdr?.vdr_admin !== user?.id) {
            body.append('upload_by', projectUsersAll?.find(item => item?.value?.user_id === user?.id)?.value?.group_member_id);
        }
        body.append('document_link', formData?.link);
        body.append('document_name', formData?.name || formData?.link?.split('/').pop());
        try {
            let res = await dispatch(PostIRLDocuments({ body: body, ws_id: workstream?.id }));
            setButtonLoading(false);
            if (res?.type === 'POST_IRL_DOC/rejected') {
                toast.error(res?.payload?.detail);
            }
        } catch (error) {
            setButtonLoading(false);
            console.error('Error adding link as file:', error);
        }
        handleClose();
    };
    return (
        <div className={styles.sidebarWhite}>
            <div className="d-flex align-items-center justify-content-between mb-5">
                <h5 className={styles.sidebarTitle}>Add Link to File</h5>
                <Dismiss16Regular onClick={handleClose} role="button" color="var(--Neutral-Gray-6)" />
            </div>
            <div className="d-flex align-items-center">
                <span>{selectedFolder?.name}</span>
                <ChevronDown20Regular className="ms-2" />
            </div>
            <br />
            <div className="mt-3">
                <label className={styles.fieldLabel}>Hyperlink to the File</label>
                <input value={formData?.link} onChange={e => setFormData({ ...formData, link: e?.target?.value })} className={styles.inputField + ' form-control mb-3'} />
            </div>
            <div className="mb-5">
                <label className={styles.fieldLabel}>File Name</label>
                <input value={formData?.name} onChange={e => setFormData({ ...formData, name: e?.target?.value })} className={styles.inputField + ' form-control'} />
            </div>
            <button onClick={handleSubmit} disabled={buttonLoading} className={styles.saveButton}>
                Save
            </button>
        </div>
    );
};
export default AddLinkModal;
