import axiosInstance from '../apiServices/axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { SECURITY_GOVERNANCE, SECURITY_GOVERNANCE_RESPONSE, SECURITY_INDEX } from '../apiServices/api_routes';
import { toast } from 'react-toastify';
// import store from './store';

export const GetSecurityIndexData = createAsyncThunk('GET_SECURITY_INDEX', async () => {
    const data = await axiosInstance.get(SECURITY_INDEX + localStorage['project_id']);
    return data;
});
export const GetGovernanceData = createAsyncThunk('GET_SECURITY_GOVERNANCE', async () => {
    const data = await axiosInstance.get(SECURITY_GOVERNANCE + localStorage['project_id']);
    return data;
});
export const PatchSecurityIndexData = createAsyncThunk('PATCH_SECURITY_INDEX', async ({ id, body }) => {
    const data = await axiosInstance.patch(SECURITY_INDEX + localStorage['project_id'] + '/' + id + '/', body);
    return data;
});

export const PostGovernanceTopic = createAsyncThunk('POST_SECURITY_GOVERNANCE', async ({ body }) => {
    const data = await axiosInstance.post(SECURITY_GOVERNANCE + localStorage['project_id'] + '/', body);
    return data;
});
export const PatchGovernanceTopic = createAsyncThunk('PATCH_SECURITY_GOVERNANCE', async body => {
    const data = await axiosInstance.patch(SECURITY_GOVERNANCE + localStorage['project_id'] + '/' + body?.id + '/', body);
    return data;
});
export const PostGovernanceResponse = createAsyncThunk('POST_SECURITY_GOVERNANCE_RESPONSE', async ({ body }) => {
    const data = await axiosInstance.post(SECURITY_GOVERNANCE_RESPONSE + localStorage['project_id'] + '/', body);
    return data;
});
export const PatchGovernanceResponse = createAsyncThunk('PATCH_SECURITY_GOVERNANCE_RESPONSE', async ({ body }) => {
    const data = await axiosInstance.patch(SECURITY_GOVERNANCE_RESPONSE + localStorage['project_id'] + '/' + body?.id + '/', body);
    return data;
});
export const securitySlice = createSlice({
    name: 'securitySlice',
    initialState: {
        securityIndexData: {},
        governanceDataRedux: [],
        securityProjectStage: 'preSeed',
    },
    reducers: {
        setGovernanceDataRedux: (state, { payload }) => {
            let topicExist = state.governanceDataRedux?.find(item => item?.id === payload?.topic?.id);
            if (topicExist) {
                state.governanceDataRedux = state.governanceDataRedux?.map(item => {
                    if (item?.topic === payload?.topic?.topic) {
                        return {
                            ...item,
                            responses: payload?.responses,
                        };
                    } else return item;
                });
            } else {
                state.governanceDataRedux.push({
                    ...payload?.topic,
                    responses: payload?.responses,
                });
            }
        },
    },
    extraReducers: {
        // GetSecurityIndexData
        [GetSecurityIndexData.pending]: (state, action) => {
            state.status = 'loading';
        },
        [GetSecurityIndexData.fulfilled]: (state, action) => {
            state.status = 'success';
            if (action?.payload?.data?.length > 0) {
                state.securityIndexData = action?.payload?.data[0];
                state.securityProjectStage = action?.payload?.data[0]?.project_stage || 'preSeed';
            }
        },
        [GetSecurityIndexData.rejected]: (state, action) => {
            state.status = 'rejected';
        },
        // PatchSecurityIndexData
        [PatchSecurityIndexData.pending]: (state, action) => {
            state.status = 'loading';
        },
        [PatchSecurityIndexData.fulfilled]: (state, { payload }) => {
            state.status = 'success';
            state.securityProjectStage = payload?.data?.project_stage;
        },
        [PatchSecurityIndexData.rejected]: (state, action) => {
            state.status = 'rejected';
            toast.error('Something went wrong');
        },
        // GetGovernanceData
        [GetGovernanceData.pending]: (state, action) => {
            state.status = 'loading';
        },
        [GetGovernanceData.fulfilled]: (state, action) => {
            state.status = 'success';
            state.governanceDataRedux = action?.payload?.data;
        },
        [GetGovernanceData.rejected]: (state, action) => {
            state.status = 'rejected';
        },
        // PatchGovernanceTopic
        [PatchGovernanceTopic.pending]: (state, action) => {
            state.status = 'loading';
        },
        [PatchGovernanceTopic.fulfilled]: (state, { payload }) => {
            state.status = 'success';
            console.log(payload);
            state.governanceDataRedux = state.governanceDataRedux?.map(item => {
                if (item?.id === payload?.data?.id) {
                    return payload?.data;
                } else return item;
            });
        },
        [PatchGovernanceTopic.rejected]: (state, action) => {
            state.status = 'rejected';
        },
    },
});
export const { setGovernanceDataRedux } = securitySlice.actions;
export default securitySlice.reducer;
