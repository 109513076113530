import React from 'react';
import styles from './css/index.module.css';
import { Input } from '@fluentui/react-components';
import { ChevronDown16Regular, Search20Regular } from '@fluentui/react-icons';
import { PatchProjectData } from '../../redux/projectDataSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
const AddCompetitors = ({ setSelectedView, createdProjectData }) => {
    const dispatch = useDispatch();

    const { projectDetailButtonLoading } = useSelector(state => state.projectData);
    const [formData, setFormData] = React.useState(['']);

    const handleChange = (e, index) => {
        let data = [...formData];
        data[index] = e.target.value;
        setFormData(data);
    };
    const handleSubmit = async () => {
        await dispatch(
            PatchProjectData({
                data: {
                    competitors: formData,
                },
                id: createdProjectData?.id,
            }),
        );
        setFormData([]);
        setSelectedView('linkedinProfiles');
    };
    console.log(formData);
    return (
        <div className={styles.componentCard}>
            <h5 className={styles.componentTitle}>Add competitors</h5>
            <div className="mb-4">
                <p className={styles.fieldLabel}>Competitor website</p>
                {formData?.map((item, index) => (
                    <Input placeholder="Type here" className={styles.inputField + ' mb-2'} onChange={e => handleChange(e, index)} name="crunchbase" />
                ))}
                <p onClick={() => setFormData([...formData, ''])} className={styles.linkBtn}>
                    Add another
                </p>
            </div>

            <div className="d-flex align-items-center justify-content-between">
                <button onClick={handleSubmit} className={styles.submitButton}>
                    Save {projectDetailButtonLoading && <AiOutlineLoading3Quarters className="ms-2 rotateLoader" />}
                </button>
                <span onClick={() => setSelectedView('linkedinProfiles')} className={styles.linkBtn}>
                    Skip
                </span>
            </div>
        </div>
    );
};

export default AddCompetitors;
