import React from 'react';
import styles from './css/index.module.css';

import { Dismiss16Regular, Add20Regular } from '@fluentui/react-icons';
import { PatchWorkstream, PostWorkstream } from '../../redux/dataroomSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

const AddWorkstreamSidebar = ({ setShowWsSidebar, setShowSidebar, isEditWorkstream, workstream, setWorkstream }) => {
    const dispatch = useDispatch();
    const { selectedProject } = useSelector(state => state?.projectData);
    const [showCreateView, setShowCreateView] = React.useState(false);
    const [newWorkstream, setNewWorkstream] = React.useState('');
    const { workstreams, default_workstreams, addWsLoading } = useSelector(state => state?.dataroom);
    const handleClose = () => {
        setShowSidebar(false);
        setShowWsSidebar(false);
        setNewWorkstream('');
    };
    const handleSubmit = async e => {
        e.preventDefault();
        if (isEditWorkstream) {
            await dispatch(PatchWorkstream({ name: newWorkstream, id: workstream?.id, project: localStorage['project_id'] }));
            setWorkstream({ ...workstream, name: newWorkstream });
        } else await dispatch(PostWorkstream({ name: newWorkstream, project: selectedProject?.id, vdr: selectedProject?.vdr?.id }));
        handleClose();
    };
    React.useEffect(() => {
        if (isEditWorkstream && workstream) {
            setShowCreateView(true);
            setNewWorkstream(workstream?.name);
        }
    }, [isEditWorkstream, workstream]);
    return (
        <div className={styles.sidebarWhite + ' ps-0 pe-0'}>
            <div className="d-flex align-items-center justify-content-between mb-5 ps-3 pe-3">
                <h5 className={styles.sidebarTitle}>{showCreateView ? (isEditWorkstream ? 'Edit Workstream Label' : 'Create New Workstream') : 'Add Workstream'}</h5>
                <Dismiss16Regular onClick={handleClose} role="button" color="var(--Neutral-Gray-6)" />
            </div>
            {showCreateView ? (
                <div className={styles.createWsBody + ' ps-3 pe-3'}>
                    <div className="mb-4">
                        <p className={styles.fieldLabel}>{isEditWorkstream ? 'Type New Label' : 'Workstream Name'}</p>
                        <input value={newWorkstream} onChange={e => setNewWorkstream(e?.target?.value)} className={styles.inputField + ' form-control'} />
                    </div>
                    <button onClick={handleSubmit} disabled={addWsLoading} className={styles.saveButton}>
                        Save {addWsLoading && <AiOutlineLoading3Quarters className="rotateLoader" />}
                    </button>
                </div>
            ) : (
                <div>
                    <div className="d-flex align-items-center justify-content-between ps-3 pe-3">
                        {/* <p className={styles.buttonLink}>Select all</p> */}
                        <p onClick={() => setShowCreateView(true)} className={styles.buttonLink}>
                            Create new
                        </p>
                    </div>
                    <div>
                        {default_workstreams?.map(ws => {
                            let check = workstreams?.find(w => w?.name === ws.workstream);
                            if (!check) {
                                return (
                                    <div key={ws?.id} className={styles.addWorkstreamList + ' create-workstream-step'}>
                                        <span style={{ color: 'var(--muted)' }}>{ws?.workstream}</span>

                                        <Add20Regular
                                            className={styles.addIcon}
                                            onClick={() => dispatch(PostWorkstream({ name: ws?.workstream, project: selectedProject?.id, vdr: selectedProject?.vdr?.id }))}
                                            role="button"
                                            color="var(--Brand-Secondary)"
                                        />
                                    </div>
                                );
                            }
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};
export default AddWorkstreamSidebar;
