import axiosInstance from '../apiServices/axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { SOFTWARE_INVENTORY } from '../apiServices/api_routes';
import { toast } from 'react-toastify';
import store from './store';
import { capitalizeFirstLetter } from '../utils/commonUtils';

export const GetSoftwares = createAsyncThunk('GET_SOFTWARE_INVENTORY', async id => {
    const data = await axiosInstance.get(SOFTWARE_INVENTORY + localStorage['project_id'] + '/');
    return data;
});

export const PostSoftware = createAsyncThunk('POST_SOFTWARE_INVENTORY', async (body, { rejectWithValue }) => {
    try {
        const data = await axiosInstance.post(SOFTWARE_INVENTORY + localStorage['project_id'] + '/', body);
        return data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const PatchSoftware = createAsyncThunk('PATCH_SOFTWARE_INVENTORY', async (body, { rejectWithValue }) => {
    try {
        const data = await axiosInstance.patch(SOFTWARE_INVENTORY + localStorage['project_id'] + '/' + body?.id + '/', body);
        return data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});
export const DeleteSoftware = createAsyncThunk('DELETE_SOFTWARE_INVENTORY', async (id, { rejectWithValue }) => {
    try {
        const data = await axiosInstance.delete(SOFTWARE_INVENTORY + localStorage['project_id'] + '/' + id + '/');
        return data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const softwareSlice = createSlice({
    name: 'softwareSlice',
    initialState: {
        softwareInvetories: [],
        softwareFormLoading: false,
    },
    reducers: {},
    extraReducers: {
        //GetSoftwares
        [GetSoftwares.fulfilled]: (state, { payload }) => {
            state.softwareInvetories = payload?.data;
        },
        [GetSoftwares.rejected]: (state, action) => {
            state.softwareInvetories = [];
        },
        //PostSoftware
        [PostSoftware.pending]: (state, action) => {
            state.pdfUrl = null;
            state.softwareFormLoading = true;
        },
        [PostSoftware.fulfilled]: (state, { payload }) => {
            state.softwareFormLoading = false;
            state.softwareInvetories.push(payload?.data);
            toast.success('Software Added');
        },
        [PostSoftware.rejected]: (state, { payload }) => {
            Object.keys(payload).map(key => {
                payload[key].map(msg => toast.error(capitalizeFirstLetter(key) + ' : ' + msg));
            });
            state.softwareFormLoading = false;
        },
        //PatchSoftware
        [PatchSoftware.pending]: (state, action) => {
            state.pdfUrl = null;
            state.softwareFormLoading = true;
        },
        [PatchSoftware.fulfilled]: (state, { payload }) => {
            state.softwareFormLoading = false;
            state.softwareInvetories = state.softwareInvetories?.map(item => {
                if (item?.id === payload?.data?.id) return payload?.data;
                else return item;
            });
            // state.softwareInvetories.push(payload?.data);
            toast.success('Software Updated');
        },
        [PatchSoftware.rejected]: (state, { payload }) => {
            console.log(payload);
            Object.keys(payload).map(key => {
                payload[key].map(msg => toast.error(capitalizeFirstLetter(key) + ' : ' + msg));
            });
            state.softwareFormLoading = false;
        },
        //DeleteSoftware
        [DeleteSoftware.fulfilled]: (state, { meta }) => {
            state.pdfUrl = null;
            console.log(meta);
            toast.success('Deleted');
            state.softwareInvetories = state.softwareInvetories?.filter(item => item?.id !== meta?.arg);
        },
    },
});
export const {} = softwareSlice.actions;
export default softwareSlice.reducer;
