import React from 'react';
import styles from './css/index.module.css';
import { Input } from '@fluentui/react-components';
import { ChevronDown16Regular, Search20Regular } from '@fluentui/react-icons';
import { GetCurrentProjectData, PatchProjectData, selectProject } from '../../redux/projectDataSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { useNavigate } from 'react-router';
const AddTechnologyDetails = ({ compareObjectsValues }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { projectDetailButtonLoading, selectedProject } = useSelector(state => state.projectData);
    const [formData, setFormData] = React.useState({
        builtwith: '',
        securitytrails: '',
        similarweb: '',
        gtmetrix: '',
    });

    const handleChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleSubmit = async () => {
        await dispatch(
            PatchProjectData({
                data: formData,
                id: selectedProject?.id,
            }),
        );
    };

    const handleSearchOnWeb = platform => {
        let withoutSpecialChracterString = selectedProject?.company_name.replace(/[^a-zA-Z0-9\s]/g, '');
        const searchQuery = (withoutSpecialChracterString + ' ' + platform).replace(' ', '+');
        window.open(`https://www.google.com/search?q=${searchQuery}`, '_blank');
    };
    const handleSetFormValues = () => {
        setFormData({
            builtwith: selectedProject?.builtwith,
            securitytrails: selectedProject?.securitytrails,
            similarweb: selectedProject?.similarweb,
            gtmetrix: selectedProject?.gtmetrix,
        });
    };
    React.useEffect(() => {
        if (selectedProject) {
            handleSetFormValues();
        }
    }, [selectedProject]);
    return (
        <div style={{ height: '90vh' }} className={styles.componentCard}>
            <h5 className={styles.componentTitle}>Technology details</h5>

            <div className="mb-4">
                <div className="d-flex align-items-center justify-content-between">
                    <p className={styles.fieldLabel}>GTMetrix link</p>
                    <Search20Regular onClick={() => handleSearchOnWeb('GTMetrix link')} role="button" color="var(--Brand-Secondary)" />
                </div>
                <Input className={styles.inputField} value={formData?.gtmetrix} onChange={handleChange} name="gtmetrix" />
            </div>
            <div className="mb-4">
                <div className="d-flex align-items-center justify-content-between">
                    <p className={styles.fieldLabel}>BuiltWith link</p>
                    <Search20Regular onClick={() => handleSearchOnWeb('BuiltWith link')} role="button" color="var(--Brand-Secondary)" />
                </div>
                <Input className={styles.inputField} value={formData?.builtwith} onChange={handleChange} name="builtwith" />
            </div>
            <div className="mb-4">
                <div className="d-flex align-items-center justify-content-between">
                    <p className={styles.fieldLabel}>Security Trails link</p>
                    <Search20Regular onClick={() => handleSearchOnWeb('Security Trails link')} role="button" color="var(--Brand-Secondary)" />
                </div>
                <Input className={styles.inputField} value={formData?.securitytrails} onChange={handleChange} name="securitytrails" />
            </div>
            <div className="mb-5">
                <div className="d-flex align-items-center justify-content-between">
                    <p className={styles.fieldLabel}>SimilarWeb link</p>
                    <Search20Regular onClick={() => handleSearchOnWeb('SimilarWeb link')} role="button" color="var(--Brand-Secondary)" />
                </div>
                <Input className={styles.inputField} value={formData?.similarweb} onChange={handleChange} name="similarweb" />
            </div>
            <div className={styles.buttonDiv}>
                {!compareObjectsValues(selectedProject, formData) && (
                    <div className="d-flex align-items-center justify-content-between">
                        <button onClick={handleSubmit} className={styles.submitButton}>
                            Save {projectDetailButtonLoading && <AiOutlineLoading3Quarters className="ms-2 rotateLoader" />}
                        </button>
                        <span onClick={handleSetFormValues} className={styles.linkBtn}>
                            Revert
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AddTechnologyDetails;
