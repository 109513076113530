import React, { useEffect } from 'react';
import styles from './css/index.module.css';

import {
    Add20Regular,
    AppsAddIn20Regular,
    Folder20Regular,
    DocumentArrowUp20Filled,
    FolderArrowUp20Filled,
    DocumentLink20Filled,
    ChevronRight16Regular,
    DataScatter20Regular,
    ArrowTurnUpLeft20Regular,
    Dismiss20Regular,
} from '@fluentui/react-icons';
import { Menu, MenuDivider, MenuItem, MenuList, MenuPopover, MenuTrigger } from '@fluentui/react-components';
import { DeleteBulkFiles, GetIRLDoc } from '../../redux/documentsSlice';
import { BulkFileDownload, GetDataroomItemInfo, GetFolderDefaults, GetWorkstreams, GetWorkstreamsDefaults } from '../../redux/dataroomSlice';
import { GetGroupMemberPermissions, GetProjectUsers } from '../../redux/teamSlice';
import { useDispatch, useSelector } from 'react-redux';

import LeftPanel from './LeftPanel';
import FileViewerModal from './FileViewerModal';
import FileInfoSidebar from './FileInfoSidebar';
import AddFolderDocModal from './AddFolderDocModal';
import AddLinkModal from './AddLinkModal';
import AddDocumentModal from './AddDocumentModal';
import AddFolderSidebar from './AddFolderSidebar';
import AddWorkstreamSidebar from './AddWorkstreamSidebar';
import FolderDetailPanel from './FolderDetailPanel';
import Analytics from './Analytics';
import DoubleConfirmationModal from '../CommonComponents/DoubleConfirmationModal/DoubleConfirmationModal';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
const Dataroom = () => {
    const dispatch = useDispatch();
    const { selectedProject } = useSelector(state => state?.projectData);
    const { projectUsersAll } = useSelector(state => state?.team);
    const { user } = useSelector(state => state?.user);
    const { workstreams, items } = useSelector(state => state?.dataroom);
    const { data, bulkFilesLoading } = useSelector(state => state?.documents);
    const [workstream, setWorkstream] = React.useState();
    const [expandWorkstream, setExpandWorkstream] = React.useState();
    const [showAnalytics, setShowAnalytics] = React.useState(false);
    const [selectedFolders, setSelectedFolders] = React.useState([]);
    const [showSidebar, setShowSidebar] = React.useState(false);
    const [isEditWorkstream, setIsEditWorkstream] = React.useState(false);
    const [isEditFolder, setIsEditFolder] = React.useState(false);
    const [showWsSidebar, setShowWsSidebar] = React.useState(false);
    const [addFolderSidebar, setAddFolderSidebar] = React.useState(false);
    const [uploadDocumentSidebar, setUploadDocumentSidebar] = React.useState(false);
    const [uploadLinkSidebar, setUploadLinkSidebar] = React.useState(false);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [uploadFolderSidebar, setUploadFolderSidebar] = React.useState(false);
    const [fileInfoSidebar, setFileInfoSidebar] = React.useState(false);
    const [selectedFile, setSelectedFile] = React.useState();
    const [selectedFileIndex, setSelectedFileIndex] = React.useState();
    const [showFileModal, setShowFileModal] = React.useState(false);
    const [filters, setFilters] = React.useState();
    const selectedFolder = selectedFolders?.length > 0 ? selectedFolders[selectedFolders?.length - 1] : null;
    const folderFiles = data?.filter(doc => doc?.ws_folder_id === selectedFolder?.id);
    const handleRouteTo = (type, folder) => {
        if (type === 'workstream') {
            setUploadDocumentSidebar(false);
            setShowSidebar(false);
            setSelectedFolders([]);
        } else {
            setUploadDocumentSidebar(false);
            setUploadLinkSidebar(false);
            setShowSidebar(false);
            var array = [...selectedFolders];
            var indexOfSelected = selectedFolders.findIndex(item => item?.id === folder?.id);
            if (indexOfSelected !== -1 && indexOfSelected < selectedFolders.length - 1) {
                array.splice(indexOfSelected + 1);
                setSelectedFolders(array);
            }
        }
    };

    const handleOpenWorkstreamSidebar = () => {
        setShowSidebar(true);
        setIsEditWorkstream(false);
        setShowWsSidebar(true); //workstream
        setAddFolderSidebar(false); //add folder
        setUploadDocumentSidebar(false); //uploadDocumentSidebar
        setUploadFolderSidebar(false); //upload folder sidebar
        setFileInfoSidebar(false); //file info sidebar
        setUploadLinkSidebar(false); // link upload sidebar
    };
    const handleOpenFolderSidebar = () => {
        setShowSidebar(true);
        setAddFolderSidebar(true); //add folder
        setIsEditFolder(false);
        setShowWsSidebar(false); //workstream
        setUploadDocumentSidebar(false); //uploadDocumentSidebar
        setUploadFolderSidebar(false); //upload folder sidebar
        setFileInfoSidebar(false); //file info sidebar
        setUploadLinkSidebar(false); // link upload sidebar
    };
    const handleOpenUploadDocumentSidebar = () => {
        setShowSidebar(true);
        setUploadDocumentSidebar(true); //uploadDocumentSidebar
        setShowWsSidebar(false); //workstream
        setAddFolderSidebar(false); //add folder
        setUploadFolderSidebar(false); //upload folder sidebar
        setFileInfoSidebar(false); //file info sidebar
        setUploadLinkSidebar(false); // link upload sidebar
    };
    const handleOpenUploadLinkSidebar = () => {
        setShowSidebar(true);
        setUploadLinkSidebar(true); // link upload sidebar
        setUploadDocumentSidebar(false); //uploadDocumentSidebar
        setShowWsSidebar(false); //workstream
        setAddFolderSidebar(false); //add folder
        setUploadFolderSidebar(false); //upload folder sidebar
        setFileInfoSidebar(false); //file info sidebar
    };
    const handleOpenAddBulkFolderSidebar = () => {
        setShowSidebar(true);
        setUploadFolderSidebar(true); //upload folder sidebar
        setShowWsSidebar(false); //workstream
        setAddFolderSidebar(false); //add folder
        setUploadDocumentSidebar(false); //uploadDocumentSidebar
        setFileInfoSidebar(false); //file info sidebar
        setUploadLinkSidebar(false); // link upload sidebar
    };
    const handleOpenEditWorkstream = () => {
        setShowSidebar(true);
        setIsEditWorkstream(true);
        setShowWsSidebar(true); //workstream
        setAddFolderSidebar(false); //add folder
        setUploadDocumentSidebar(false); //uploadDocumentSidebar
        setUploadFolderSidebar(false); //upload folder sidebar
        setFileInfoSidebar(false); //file info sidebar
        setUploadLinkSidebar(false); // link upload sidebar
    };
    const handleOpenEditFolder = () => {
        setShowSidebar(true);
        setIsEditFolder(true);
        setAddFolderSidebar(true); //add folder
        setShowWsSidebar(false); //workstream
        setUploadDocumentSidebar(false); //uploadDocumentSidebar
        setUploadFolderSidebar(false); //upload folder sidebar
        setFileInfoSidebar(false); //file info sidebar
        setUploadLinkSidebar(false); // link upload sidebar
    };
    const handleOpenFileInfoSidebar = file => {
        setSelectedFile({ ...file, filePath: getFileLocation() });
        setShowSidebar(true);
        setFileInfoSidebar(true);
        setShowWsSidebar(false); //workstream
        setAddFolderSidebar(false); //add folder
        setUploadDocumentSidebar(false); //uploadDocumentSidebar
        setUploadFolderSidebar(false); //upload folder sidebar
        setUploadLinkSidebar(false); // link upload sidebar
    };
    const handleOpenFile = (file, index) => {
        if (file?.document_link) {
            if (!file?.document_link?.includes('youtube') && !file?.document_link?.includes('youtu.be')) {
                window.open(file?.document_link, '_blank').focus();
                return null;
            }
        }
        setSelectedFileIndex(index);
        setSelectedFile({ ...file, filePath: getFileLocation() });
        setShowFileModal(true);
    };
    const handleDeleteBulkFiles = async () => {
        await dispatch(DeleteBulkFiles({ items: selectedRows, ws_id: workstream?.id }));
        setSelectedRows([]);
    };
    const downloadFilesAsZip = async () => {
        let formData = new FormData();
        formData.append('file_ids', JSON.stringify(selectedRows));
        try {
            const response = await dispatch(BulkFileDownload({ id: workstream?.id, body: formData, selectedFolder }));

            if (response?.payload) {
                const blob = new Blob([response.payload.data], { type: 'application/zip' });

                let filename = selectedFolder?.name + '.zip'; // Default filename
                const contentDisposition = response.payload.headers['content-disposition'];
                if (contentDisposition) {
                    const filenameMatch = contentDisposition.match(/filename="?(.+?)"?$/);
                    if (filenameMatch && filenameMatch.length > 1) {
                        filename = filenameMatch[1];
                    }
                }

                // Replace spaces with underscores (or hyphens) in the filename
                // const safeFilename = filename.replace(/\s/g, '_');
                // Create and trigger a download link
                const url = window.URL.createObjectURL(blob);
                const downloadLink = document.createElement('a');
                downloadLink.href = url;
                downloadLink.download = filename; // Use the extracted filename
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);

                window.URL.revokeObjectURL(url);
                setSelectedRows([]);
            } else {
                console.error('No data received for download');
                setSelectedRows([]);
            }
        } catch (error) {
            console.error('Error while downloading file:', error);
            setSelectedRows([]);
        }
    };
    const handleBack = () => {
        if (selectedFolders?.length > 0) {
            setSelectedFolders(prevArray => prevArray.slice(0, -1));
        }
    };
    const getFileLocation = () => {
        let folderPath = selectedFolders?.map(item => item?.name).join('/');
        return workstream?.name + '/' + folderPath;
    };
    useEffect(() => {
        const effectAsync = async () => {
            if (workstream) {
                dispatch(GetIRLDoc(workstream?.id));
                await dispatch(GetDataroomItemInfo(workstream?.id));
                dispatch(GetFolderDefaults(workstream?.name));
            }
        };
        effectAsync();
    }, [dispatch, workstream, workstreams]);
    useEffect(() => {
        dispatch(GetWorkstreamsDefaults());
        // dispatch(GetFilesSearch({ query: '&searchFields=metadata_storage_path&api-version=2021-04-30-Preview' }));

        if (selectedProject?.id) {
            dispatch(GetProjectUsers(selectedProject?.id));
            dispatch(GetWorkstreams());
        }
    }, [dispatch, selectedProject]);
    React.useEffect(() => {
        if (workstreams?.length > 0) {
            console.log(workstreams);
            if (localStorage['workstream']) {
                const wsFromLocal = workstreams?.find(item => item?.id === parseInt(localStorage['workstream']));
                if (wsFromLocal) {
                    setWorkstream(wsFromLocal);
                    setExpandWorkstream(wsFromLocal?.id);
                    localStorage.setItem('workstream', wsFromLocal?.id);
                } else {
                    setWorkstream(workstreams[0]);
                    setExpandWorkstream(workstreams[0]?.id);
                    localStorage.setItem('workstream', workstreams[0]?.id);
                }
            } else {
                setWorkstream(workstreams[0]);
                setExpandWorkstream(workstreams[0]?.id);
                localStorage.setItem('workstream', workstreams[0]?.id);
            }
        } else {
            setWorkstream();
            setExpandWorkstream();
            localStorage.removeItem('workstream');
        }
    }, [workstreams]);
    React.useEffect(() => {
        const projectUser = projectUsersAll.find(item => item?.value?.user_id === user?.id);
        projectUser?.value['group_member_id'] && dispatch(GetGroupMemberPermissions(projectUser?.value['group_member_id']));
    }, [dispatch, projectUsersAll, user?.id]);
    React.useEffect(() => {
        if (items?.length > 0 && !selectedFolder) {
            console.log('Effect running');
            setSelectedFolders([items[0]]);
        }
    }, [items]);
    return (
        <div className={styles.viewHeight}>
            <div className={styles.dataroomContentBody}>
                <div className="row">
                    <div className="col-sm-12 col-md-3">
                        <LeftPanel
                            workstream={workstream}
                            setWorkstream={setWorkstream}
                            setSelectedFolders={setSelectedFolders}
                            selectedFolders={selectedFolders}
                            handleOpenEditWorkstream={handleOpenEditWorkstream}
                            handleOpenEditFolder={handleOpenEditFolder}
                            expandWorkstream={expandWorkstream}
                            setExpandWorkstream={setExpandWorkstream}
                            handleOpenWorkstreamSidebar={handleOpenWorkstreamSidebar}
                            setShowAnalytics={setShowAnalytics}
                            setFilters={setFilters}
                        />
                    </div>
                    <div className={`col-sm-12 col-md-9`}>
                        <div className="row">
                            {filters?.type !== 'Search Results : ' && (
                                <div className="d-flex align-items-center justify-content-between mb-4">
                                    <div>
                                        {workstream && (
                                            <span
                                                onClick={() => handleRouteTo('workstream')}
                                                style={{ fontWeight: selectedFolders?.length === 0 && '700' }}
                                                className={styles.routeText}>
                                                {workstream?.name}
                                            </span>
                                        )}
                                        {selectedFolders?.map(item => (
                                            <span
                                                style={{ fontWeight: selectedFolder?.id === item?.id && 700 }}
                                                onClick={() => handleRouteTo('folder', item)}
                                                className={styles.routeText}>
                                                <ChevronRight16Regular className="me-1 ms-1" />
                                                {item?.name}
                                            </span>
                                        ))}
                                    </div>
                                    {showAnalytics ? (
                                        <button
                                            onClick={() => {
                                                setShowAnalytics(false);
                                                setFilters();
                                            }}
                                            className={styles.addButton}>
                                            <Dismiss20Regular className="me-2" />
                                            Close Analytics
                                        </button>
                                    ) : (
                                        <div className="d-flex align-items-center">
                                            {selectedRows?.length > 0 ? (
                                                <div style={{ height: 32 }} className="d-flex align-items-center justify-content-end">
                                                    <p onClick={downloadFilesAsZip} className={styles.secondaryLink + ' mb-0 me-5'}>
                                                        Download
                                                    </p>
                                                    <DoubleConfirmationModal
                                                        handleAction={handleDeleteBulkFiles}
                                                        message="Deleting a File will permanently remove it from the Data Room.">
                                                        <p className={styles.secondaryLink + ' mb-0 '}>
                                                            Delete &nbsp; {bulkFilesLoading && <AiOutlineLoading3Quarters className="rotateLoader" />}
                                                        </p>
                                                    </DoubleConfirmationModal>
                                                </div>
                                            ) : (
                                                <>
                                                    {workstream && (
                                                        <>
                                                            <p
                                                                style={{ color: selectedFolders?.length === 0 && 'var(--Neutral-Gray-6)' }}
                                                                onClick={handleBack}
                                                                className={styles.secondaryLink + ' mb-0 me-5'}>
                                                                <ArrowTurnUpLeft20Regular className="me-1" />
                                                                Back
                                                            </p>
                                                            <p onClick={() => setShowAnalytics(true)} className={styles.secondaryLink + ' mb-0 me-5'}>
                                                                <DataScatter20Regular className="me-1" />
                                                                Analytics
                                                            </p>
                                                        </>
                                                    )}
                                                    <Menu>
                                                        <MenuTrigger disableButtonEnhancement>
                                                            <button className={styles.addButton}>
                                                                <Add20Regular className="me-2" />
                                                                Add new
                                                            </button>
                                                        </MenuTrigger>
                                                        <MenuPopover>
                                                            <MenuList>
                                                                <MenuItem onClick={handleOpenWorkstreamSidebar} icon={<AppsAddIn20Regular />}>
                                                                    Workstream
                                                                </MenuItem>
                                                                <MenuDivider />
                                                                <MenuItem disabled={!workstream} onClick={handleOpenFolderSidebar} icon={<Folder20Regular />}>
                                                                    Folder
                                                                </MenuItem>
                                                                <MenuDivider />
                                                                <MenuItem
                                                                    disabled={selectedFolders?.length === 0}
                                                                    onClick={handleOpenUploadDocumentSidebar}
                                                                    icon={<DocumentArrowUp20Filled />}>
                                                                    Files Upload
                                                                </MenuItem>
                                                                <MenuDivider />
                                                                <MenuItem disabled={!workstream} onClick={handleOpenAddBulkFolderSidebar} icon={<FolderArrowUp20Filled />}>
                                                                    Folder Upload
                                                                </MenuItem>
                                                                <MenuDivider />
                                                                <MenuItem
                                                                    disabled={selectedFolders?.length === 0}
                                                                    onClick={handleOpenUploadLinkSidebar}
                                                                    icon={<DocumentLink20Filled />}>
                                                                    Link
                                                                </MenuItem>
                                                            </MenuList>
                                                        </MenuPopover>
                                                    </Menu>
                                                </>
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                            <div className={`col-sm-12 col-md-${showSidebar ? 8 : 12}`}>
                                {showAnalytics ? (
                                    <Analytics
                                        selectedFolders={selectedFolders}
                                        setShowAnalytics={setShowAnalytics}
                                        workstream={workstream}
                                        filters={filters}
                                        setFilters={setFilters}
                                    />
                                ) : (
                                    <FolderDetailPanel
                                        selectedFolders={selectedFolders}
                                        setSelectedFolders={setSelectedFolders}
                                        workstream={workstream}
                                        handleOpenFileInfoSidebar={handleOpenFileInfoSidebar}
                                        handleOpenFile={handleOpenFile}
                                        selectedFolder={selectedFolder}
                                        folderFiles={folderFiles}
                                        setShowAnalytics={setShowAnalytics}
                                        setSelectedFile={setSelectedFile}
                                        selectedFile={selectedFile}
                                        handleOpenFolderSidebar={handleOpenFolderSidebar}
                                        showSidebar={showSidebar}
                                        handleRouteTo={handleRouteTo}
                                        setSelectedRows={setSelectedRows}
                                        handleOpenEditFolder={handleOpenEditFolder}
                                        selectedRows={selectedRows}
                                    />
                                )}
                            </div>
                            {showSidebar && (
                                <div className="col-sm-12 col-md-4">
                                    {showWsSidebar && (
                                        <AddWorkstreamSidebar
                                            setShowWsSidebar={setShowWsSidebar}
                                            setShowSidebar={setShowSidebar}
                                            workstream={workstream}
                                            isEditWorkstream={isEditWorkstream}
                                            setWorkstream={setWorkstream}
                                        />
                                    )}
                                    {addFolderSidebar && (
                                        <AddFolderSidebar
                                            setAddFolderSidebar={setAddFolderSidebar}
                                            selectedFolders={selectedFolders}
                                            setShowSidebar={setShowSidebar}
                                            workstream={workstream}
                                            isEditFolder={isEditFolder}
                                            setSelectedFolders={setSelectedFolders}
                                        />
                                    )}
                                    {uploadDocumentSidebar && (
                                        <AddDocumentModal
                                            selectedFolders={selectedFolders}
                                            setUploadDocumentSidebar={setUploadDocumentSidebar}
                                            setShowSidebar={setShowSidebar}
                                            workstream={workstream}
                                        />
                                    )}
                                    {uploadLinkSidebar && (
                                        <AddLinkModal
                                            selectedFolders={selectedFolders}
                                            setUploadLinkSidebar={setUploadLinkSidebar}
                                            setShowSidebar={setShowSidebar}
                                            workstream={workstream}
                                        />
                                    )}
                                    {uploadFolderSidebar && (
                                        <AddFolderDocModal
                                            selectedFolders={selectedFolders}
                                            setUploadFolderSidebar={setUploadFolderSidebar}
                                            uploadFolderSidebar={uploadFolderSidebar}
                                            setShowSidebar={setShowSidebar}
                                            workstream={workstream}
                                        />
                                    )}
                                    {fileInfoSidebar && (
                                        <FileInfoSidebar
                                            selectedFolders={selectedFolders}
                                            setFileInfoSidebar={setFileInfoSidebar}
                                            fileInfoSidebar={fileInfoSidebar}
                                            setShowSidebar={setShowSidebar}
                                            setSelectedFile={setSelectedFile}
                                            selectedFile={selectedFile}
                                            workstream={workstream}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <FileViewerModal
                openModal={showFileModal}
                setOpenModal={setShowFileModal}
                folderFiles={folderFiles}
                setSelectedFileIndex={setSelectedFileIndex}
                selectedFileIndex={selectedFileIndex}
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                workstream={workstream}
            />
        </div>
    );
};

export default Dataroom;
