import Layout from '../components/Layout';
import QuestionBank from '../components/QuestionBank';
import Pageheader from '../utils/headerHelment';

const QuestionBankView = () => {
    return (
        <Layout container={true}>
            <Pageheader title="LENS - Question Bank" />
            <QuestionBank />
        </Layout>
    );
};

export default QuestionBankView;
