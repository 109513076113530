import React from 'react';
import styles from './css/index.module.css';
import { Input, Tooltip } from '@fluentui/react-components';
import { GetCompanyDetail, GetCompanyId, PostProjectData } from '../../redux/projectDataSlice';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { Info12Filled } from '@fluentui/react-icons';
import { add } from 'date-fns';
const AddProject = ({ setSelectedView, setCreatedProjectData }) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = React.useState({
        project_name: '',
        investor_name: '',
        website: '',
        project_deadline: format(add(new Date(), { months: 1 }), 'yyyy-MM-dd'),
        company_name: '',
    });
    const { projectDetailButtonLoading } = useSelector(state => state.projectData);
    const handleSubmit = async e => {
        e.preventDefault();
        let response = await dispatch(
            PostProjectData({
                body: { ...formData, project_deadline: format(new Date(formData?.project_deadline), 'dd-MM-yyyy') },
                callback: () => {},
            }),
        );
        setSelectedView('uploadNda');
        if (response?.type === 'projectData/postProjectData/fulfilled') {
            let newProjectData = { ...response.payload?.data };
            let companyDetails = await getDetails(response.payload?.data?.website);
            if (companyDetails?.logo_url) newProjectData.logo = companyDetails?.logo_url;
            if (companyDetails?.description) newProjectData.description = companyDetails?.description;
            if (companyDetails?.employees_count) newProjectData.employees = companyDetails?.employees_count;
            if (companyDetails?.size) newProjectData.company_size = companyDetails?.size;
            if (companyDetails?.industry) newProjectData.sector = companyDetails?.industry;
            if (companyDetails?.founded) newProjectData.established_year = companyDetails?.founded;
            if (companyDetails?.url) newProjectData.linkedin = companyDetails?.url;
            if (companyDetails?.id) newProjectData.companieshouse = companyDetails?.id;
            setCreatedProjectData(newProjectData);
        }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getDetails = async website => {
        const res = await dispatch(GetCompanyId(website));
        console.log(res);
        if (res?.type === 'GET_COMPANY_SEARCH/fulfilled') {
            const firstId = getFirstId(res?.payload?.data?.companies);
            if (firstId) {
                const companyRes = await dispatch(GetCompanyDetail(firstId));
                if (companyRes?.type === 'GET_COMPANY_DETAIL/fulfilled') {
                    return companyRes?.payload?.data?.company_info;
                }
            }
        }
    };
    function getFirstId(data) {
        if (!data || data?.trim() === '') return '';
        const ids = data?.slice(1, -1).split(',');
        return ids[0];
    }
    const handleChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    function isURL(url) {
        // Regular expression for URL validation
        const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
        return urlRegex.test(url);
    }

    const disableFormBtn = !formData?.company_name || !formData?.project_name || !formData?.project_deadline || !formData?.website;
    return (
        <div className={styles.componentCard}>
            <h5 className={styles.componentTitle}>Add New Project</h5>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <p className={styles.fieldLabel}>
                        Project Name <span className={styles.required}>*</span>
                    </p>
                    <Input className={styles.inputField} required value={formData?.project_name} onChange={handleChange} name="project_name" />
                </div>
                <div className="mb-4">
                    <p className={styles.fieldLabel}>
                        Organisation Name<span className={styles.required}>*</span>
                    </p>
                    <Input className={styles.inputField} required value={formData?.company_name} onChange={handleChange} name="company_name" />
                </div>
                <div className="mb-4">
                    <div className="d-flex align-items-center justify-content-between">
                        <p className={styles.fieldLabel}>
                            Website <span className={styles.required}>*</span>
                        </p>
                        <Tooltip withArrow content="Please enter a valid company website address in the correct format, as it is used to gather additional data.">
                            <Info12Filled role="button" color="var(--Neutral-Gray-6)" />
                        </Tooltip>
                    </div>
                    <Input
                        className={styles.inputField}
                        required
                        type="url"
                        value={formData?.website}
                        onChange={handleChange}
                        onError={e => console.log(e)}
                        name="website"
                        placeholder="Please enter a valid website address"
                    />
                    {!disableFormBtn && !isURL(formData?.website) && <span className={styles.errorMessage}>Please enter a URL in https:// or http:// format</span>}
                </div>
                <div className="mb-4">
                    <p className={styles.fieldLabel}>Investor</p>
                    <Input className={styles.inputField} value={formData?.investor_name} onChange={handleChange} name="investor_name" />
                </div>
                <div className="mb-5">
                    <p className={styles.fieldLabel}>
                        Project Deadline <span className={styles.required}>*</span>
                    </p>
                    <Input className={styles.inputField + ' w-50'} required type="date" value={formData?.project_deadline} onChange={handleChange} name="project_deadline" />
                </div>

                <button disabled={disableFormBtn} type="submit" className={styles.submitButton}>
                    Next
                    {projectDetailButtonLoading && <AiOutlineLoading3Quarters className="ms-2 rotateLoader" />}
                </button>
            </form>
        </div>
    );
};

export default AddProject;
