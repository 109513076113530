import Layout from '../components/Layout';
import Permissions from '../components/Permissions';
import Pageheader from '../utils/headerHelment';

const PermissionsView = () => {
    return (
        <Layout container={true}>
            <Pageheader title="LENS - Permissions" />
            <Permissions />
        </Layout>
    );
};

export default PermissionsView;
