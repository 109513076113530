import React from 'react';
import Pageheader from '../../utils/headerHelment';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { API_BASE_URL, CHANGE_PASSWORD } from '../../apiServices/api_routes';
import styles from './css/ResetPassword.module.css';
import { isObject } from '../../utils/commonUtils';
import Logo from '../../assets/longLogoBright.png';
import { SpinnerIos16Regular, Eye16Regular, EyeOff16Regular } from '@fluentui/react-icons';

const ResetPassword = props => {
    const params = useParams();
    const navigate = useNavigate();
    const [showpassword, setShowpassword] = React.useState(false);
    var promiseToast = null;
    const [password, setPassword] = React.useState('');
    const [btnLoading, setBtnLoading] = React.useState(false);
    const [response, setResponse] = React.useState({
        message: 'Your password has been successfully reset. Click below to continue your access.',
        visible: false,
    });
    const handleSubmit = async e => {
        e.preventDefault();
        setBtnLoading(true);
        if (password) {
            let body = {
                ...params,
                new_password: password,
            };
            try {
                promiseToast = toast.loading('Please wait ...', {
                    closeButton: true,
                });
                await axios.post(`${API_BASE_URL}${CHANGE_PASSWORD}`, body);
                setResponse({ ...response, visible: true });
                toast.update(promiseToast, {
                    render: 'Password Successfully Changed',
                    type: 'success',
                    isLoading: false,
                    autoClose: 1000,
                });
            } catch (error) {
                const res = error?.response?.data;
                if (isObject(res)) {
                    Object.entries(res).forEach(([key, value]) => {
                        value?.forEach(item =>
                            toast.update(promiseToast, {
                                render: item,
                                type: 'error',
                                isLoading: false,
                                autoClose: 1000,
                            }),
                        );
                    });
                }
            }
        } else {
            toast.warn('Password Required');
        }
        setBtnLoading(false);
    };
    return (
        <div className={styles.body}>
            <div className={styles.dotedBg}>
                <div className={styles.formCard}>
                    <div className={styles.formHeader}>
                        <img src={Logo} width={73} alt="lens-logo" />
                    </div>
                    {response?.visible ? (
                        <div className={styles.formBody}>
                            <div className={styles.responseBody}>
                                <h5>Password Reset</h5>
                                <p>{response?.message}</p>
                                <button onClick={() => navigate('/')} className={styles.submitBtn + ' mt-4'}>
                                    Login
                                </button>
                            </div>
                        </div>
                    ) : (
                        <form onSubmit={handleSubmit} className={styles.formBody}>
                            <h5 className={styles.formLabel}>Set New Password</h5>
                            <p className={styles.formSubtitle}>Your new password must be different from previously used passwords.</p>
                            <div>
                                <div>
                                    <p className={styles.fieldLabel}>Enter new password</p>
                                    <div className="input-field">
                                        <input
                                            value={password}
                                            required
                                            name="password"
                                            onChange={e => setPassword(e.target.value)}
                                            className={'form-control ' + styles.inputField}
                                        />
                                        {showpassword ? (
                                            <Eye16Regular onClick={() => setShowpassword(!showpassword)} className="icon icon-right svg-icons" />
                                        ) : (
                                            <EyeOff16Regular onClick={() => setShowpassword(!showpassword)} className="icon icon-right svg-icons" />
                                        )}
                                    </div>{' '}
                                </div>
                                <button disabled={btnLoading || !password} type="submit" className={styles.submitBtn}>
                                    Save
                                    {btnLoading && <SpinnerIos16Regular className="rotateLoader ms-1" />}
                                </button>
                            </div>
                        </form>
                    )}
                </div>
            </div>
            <div className={styles.footer}>
                <a href="https://www.iubenda.com/terms-and-conditions/15531073" target="_blank" rel="noreferrer">
                    Terms of Use
                </a>
                <a href="https://www.iubenda.com/privacy-policy/15531073/legal?ifr=true&height=650&newmarkup=yes&an=no" target="_blank" rel="noreferrer">
                    Privacy & Cookies
                </a>
            </div>
        </div>
    );
};

export default ResetPassword;
