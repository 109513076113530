import React from 'react';
import Layout from '../components/Layout';
import Pageheader from '../utils/headerHelment';
import SecurityGovernance from '../components/SecurityGovernance';

const SecurityGovernancePage = () => {
    return (
        <>
            <Pageheader title="LENS - Security Governance" />
            <Layout>
                {/* <Wizard /> */}
                <SecurityGovernance />
            </Layout>
        </>
    );
};

export default SecurityGovernancePage;
