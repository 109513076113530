import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../apiServices/axios';
import { toast } from 'react-toastify';
import { GET_SCHEDULE_DATA, MEETINGS_LIST, SCHEDULER_OBSERVATIONS_ALL, SCHEDULER_OBSERVATIONS_TYPE, SCHEDULER_OBSERVATION } from '../apiServices/api_routes';

export const GetScheduleData = createAsyncThunk('GET_SCHEDULE_DATA', async id => {
    const data = await axiosInstance.get(GET_SCHEDULE_DATA + id + '/');
    return data;
});
export const GetObservationsList = createAsyncThunk('GET_SCHEDULER_OBSERVATIONS', async ({ id, type }) => {
    const data = await axiosInstance.get(SCHEDULER_OBSERVATIONS_TYPE + type + '/' + id + '/' + localStorage['project_id']);
    return data;
});
export const GetObservationsByScope = createAsyncThunk('GET_SCHEDULER_OBSERVATIONS_BY_SCOPE', async scope => {
    const data = await axiosInstance.get(SCHEDULER_OBSERVATIONS_ALL + scope + '/' + localStorage['project_id'] + '/');
    return data;
});
export const GetMeetingsList = createAsyncThunk('GET_MEETINGS_LIST', async () => {
    const data = await axiosInstance.get(MEETINGS_LIST + localStorage['project_id'] + '/');
    return data;
});

export const PatchMetting = createAsyncThunk('PATCH_MEETING', async ({ meetingId, body, scheduleId }) => {
    const data = await axiosInstance.patch(`${MEETINGS_LIST + localStorage['project_id']}/${meetingId}/`, body);
    return data;
});
export const DeleteMetting = createAsyncThunk('DELETE_MEETING', async ({ meetingId, scheduleId }) => {
    const data = await axiosInstance.delete(`${MEETINGS_LIST + localStorage['project_id']}/${meetingId}/`);
    return data;
});
export const AddMetting = createAsyncThunk('ADD_MEETING', async ({ body, id }) => {
    const data = await axiosInstance.post(MEETINGS_LIST + localStorage['project_id'] + '/' + id + '/', body);
    return data;
});

export const DeleteAttendeeFromStore = createAsyncThunk('DeleteAttendeeFromStore', async ({ userId, meetingId }) => {
    let data = { userId, meetingId };
    return data;
});

export const PostSchedulerObservations = createAsyncThunk('POST_SCHEDULER_OBSERVATIONS', async ({ id, body, type }, { rejectWithValue }) => {
    try {
        const data = await axiosInstance.post(SCHEDULER_OBSERVATIONS_TYPE + type + '/' + id + '/' + localStorage['project_id'], body);
        return data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

export const PatchObservations = createAsyncThunk('PATCH_OBSERVATION', async ({ id, body }) => {
    const data = await axiosInstance.patch(SCHEDULER_OBSERVATION + localStorage['project_id'] + '/' + id + '/', body);
    return data;
});
export const DeleteObservations = createAsyncThunk('DELETE_OBSERVATION', async id => {
    const data = await axiosInstance.delete(SCHEDULER_OBSERVATION + localStorage['project_id'] + '/' + id + '/');
    return data;
});

export const meetingSlice = createSlice({
    name: 'meetingSlice',
    initialState: {
        data: [],
        status: null,
        meetings: [],
        scheduleAttendees: [],
        selectedMeeting: null,
        observationsList: [],
        scopeObservations: [],
        selectedScope: 'all',
        selectedObservation: null,
        observationFilters: null,
        obsButtonLoading: false,
        meetingFormLoading: false,
    },
    reducers: {
        setSelectedMeeting: (state, action) => {
            return { ...state, selectedMeeting: action.payload };
        },
        setSelectedObservation: (state, action) => {
            return { ...state, selectedObservation: action.payload };
        },
        setSelectedScope: (state, action) => {
            return { ...state, selectedScope: action.payload };
        },
        resetObservationData: (state, action) => {
            return {
                data: [],
                status: null,
                meetings: [],
                scheduleAttendees: [],
                selectedMeeting: null,
                observationsList: [],
                scopeObservations: [],
                selectedScope: 'all',
                selectedObservation: null,
                observationFilters: null,
                obsButtonLoading: false,
                meetingFormLoading: false,
            };
        },
    },
    extraReducers: {
        //GetScheduleData
        [GetScheduleData.pending]: (state, action) => {
            state.status = 'loading';
        },
        [GetScheduleData.fulfilled]: (state, action) => {
            state.status = 'success';
            if (action.payload?.data.length > 0) {
                state.data = action.payload?.data[0];
            }
        },
        [GetScheduleData.rejected]: (state, action) => {
            state.status = 'rejected';
        },
        //GetMeetingsList
        [GetMeetingsList.pending]: (state, action) => {
            state.status = 'loading';
        },
        [GetMeetingsList.fulfilled]: (state, action) => {
            state.status = 'success';
            state.meetings = action.payload?.data;
        },
        [GetMeetingsList.rejected]: (state, action) => {
            state.status = 'rejected';
        },
        //PatchMetting
        [PatchMetting.pending]: (state, action) => {
            state.status = 'loading';
            state.meetingFormLoading = true;
        },
        [PatchMetting.fulfilled]: (state, action) => {
            state.status = 'success';
            state.meetingFormLoading = false;
            // state.meetings = action.payload?.data;
            if (state.selectedMeeting) {
                state.selectedMeeting = action.payload?.data;
            }
            state.meetings = state.meetings?.map(item => {
                if (item.id === action.payload?.data?.id) {
                    return action.payload?.data;
                } else return item;
            });

            toast.success('Meeting edited');
        },
        [PatchMetting.rejected]: (state, action) => {
            state.meetingFormLoading = false;
            state.status = 'rejected';
            toast.error('Meeting editing failed!');
        },
        //AddMetting
        [AddMetting.pending]: (state, action) => {
            state.status = 'loading';
            state.meetingFormLoading = true;
        },
        [AddMetting.fulfilled]: (state, action) => {
            state.status = 'success';
            toast.success('Meeting added');
            state.meetings.unshift(action.payload.data);
            state.meetingFormLoading = false;
        },
        [AddMetting.rejected]: (state, action) => {
            state.status = 'rejected';
            toast.error('Meeting adding failed!');
            state.meetingFormLoading = false;
        },

        //DeleteMetting
        [DeleteMetting.pending]: (state, action) => {
            state.status = 'loading';
        },
        [DeleteMetting.fulfilled]: (state, action) => {
            state.status = 'success';
            toast.success('Deleted Successfully');
            state.meetings = state.meetings?.filter(item => {
                if (item.id !== action.meta?.arg?.meetingId) {
                    return item;
                }
            });
            state.scheduleAttendees = state.scheduleAttendees?.filter(item => {
                if (item.meetingitem !== action.meta?.arg?.meetingId) {
                    return item;
                }
            });
            // state.meetings = action.payload?.data;
        },
        [DeleteMetting.rejected]: (state, action) => {
            state.status = 'rejected';
            toast.error('Metting deleting failed!');
        },

        //DeleteAttendeeFromStore
        [DeleteAttendeeFromStore.fulfilled]: (state, { payload }) => {
            const { userId, meetingId } = payload;
            let newData = state.meetings?.filter(item => {
                if (item.id === meetingId) {
                    item.attendee = item.attendee?.filter(item => item !== userId);
                }
                return item;
            });
            state.meetings = newData;
        },

        //PostSchedulerObservations
        [PostSchedulerObservations.pending]: (state, action) => {
            state.status = 'loading';
            state.obsButtonLoading = true;
        },
        [PostSchedulerObservations.fulfilled]: (state, { payload }) => {
            console.log(payload);
            state.status = 'success';
            state.scopeObservations.push(payload?.data);
            toast.success('Observation added');
            state.obsButtonLoading = false;
        },
        [PostSchedulerObservations.rejected]: (state, { payload }) => {
            state.status = 'rejected';
            state.obsButtonLoading = false;
            Object.keys(payload).map(key => {
                payload[key].map(msg => toast.error(key + ' : ' + msg));
            });
            state.obsButtonLoading = false;
        },
        //GetObservationsList
        [GetObservationsList.pending]: (state, action) => {
            state.status = 'loading';
        },
        [GetObservationsList.fulfilled]: (state, action) => {
            state.status = 'success';
            state.observationsList = action.payload.data;
        },
        [GetObservationsList.rejected]: (state, action) => {
            state.status = 'rejected';
        },
        //GetObservationsByScope
        [GetObservationsByScope.pending]: (state, action) => {
            state.status = 'loading';
        },
        [GetObservationsByScope.fulfilled]: (state, action) => {
            state.status = 'success';
            state.scopeObservations = action.payload.data?.observations;
            state.observationFilters = action.payload.data?.filters;
        },
        [GetObservationsByScope.rejected]: (state, action) => {
            state.status = 'rejected';
            state.scopeObservations = [];
        },

        //PatchObservations
        [PatchObservations.pending]: (state, action) => {
            state.status = 'loading';
            state.obsButtonLoading = true;
        },
        [PatchObservations.fulfilled]: (state, { payload }) => {
            state.scopeObservations = state.scopeObservations?.map(item => {
                if (item?.id === payload?.data?.id) return payload.data;
                else return item;
            });
            state.status = 'success';
            toast.success('Observation Edited');
            state.obsButtonLoading = false;
        },
        [PatchObservations.rejected]: (state, action) => {
            state.status = 'rejected';
            toast.error('Observation Editing Failed');
            state.obsButtonLoading = false;
        },
        //DeleteObservations
        [DeleteObservations.pending]: (state, action) => {
            state.status = 'loading';
        },
        [DeleteObservations.fulfilled]: (state, action) => {
            state.status = 'success';
            toast.success('Observation Deleted');
            state.scopeObservations = state.scopeObservations?.filter(item => action.meta?.arg !== item?.id);
            state.observationsList = state.observationsList?.filter(item => action.meta?.arg !== item?.id);
        },
        [DeleteObservations.rejected]: (state, action) => {
            state.status = 'rejected';
            toast.error('Observation Deleting Failed');
        },
    },
});
export const { setSelectedMeeting, setSelectedScope, setSelectedObservation, resetObservationData } = meetingSlice.actions;
export default meetingSlice.reducer;
