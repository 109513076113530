const securityIndexData = [
    {
        label: 'Multi-factor authentication (MFA) for employee email.',
        description: 'Enhances email security, reducing the risk of unauthorised access and data breaches.',
        value: 0,
        preSeed: true,
        seriesA: true,
        corp: true,
        key: 'mfa_emails',
    },
    {
        label: 'MFA for remote access.',
        description: 'Vital for protecting sensitive data in an increasingly remote workforce.',
        value: 0,
        preSeed: true,
        seriesA: true,
        corp: true,
        key: 'mfa_remote',
    },
    {
        label: 'MFA for privileged accounts/privileged access.',
        description: 'Crucial for safeguarding high-level system access against sophisticated cyber threats.',
        value: 0,
        preSeed: true,
        seriesA: true,
        corp: true,
        key: 'mfa_privileged',
    },
    {
        label: 'Off-site (preferably offline) backups of critical data',
        description: 'Ensures business continuity by protecting against data loss from onsite disasters.',
        value: 0,
        preSeed: true,
        seriesA: true,
        corp: true,
        key: 'offsite_backup',
    },
    {
        label: 'Deploy an endpoint detection and response (EDR) solution on all managed endpoints',
        description: 'Crucial for identifying and responding to cyber threats in real-time.',
        value: 0,
        preSeed: true,
        seriesA: true,
        corp: true,
        key: 'edr_enpoints',
    },
    {
        label: 'Employee cybersecurity training, including phishing simulations.',
        description: 'Reduces human error, a major factor in cybersecurity breaches',
        value: 0,
        preSeed: false,
        seriesA: true,
        corp: true,
        key: 'cyber_training',
    },
    {
        label: 'Strong email filtering tools.',
        description: 'Minimises the risk of phishing and malware by filtering malicious email content.',
        value: 0,
        preSeed: false,
        seriesA: true,
        corp: true,
        key: 'email_filtering',
    },
    {
        label: 'Privileged access account security measures.',
        description: 'Prevents unauthorised access to critical systems, protecting core business operations.',
        value: 0,
        preSeed: false,
        seriesA: true,
        corp: true,
        key: 'privileged_access_control',
    },
    {
        label: 'EOL (End-of-life/Unsupported) software and hardware segregated from the network, and plans to decommission timely.',
        description: 'Mitigates vulnerabilities associated with outdated technologies',
        value: 0,
        preSeed: false,
        seriesA: true,
        corp: true,
        key: 'eol_software',
    },
    {
        label: 'Cyber-incident disaster recovery/incident response plan.',
        description: 'Essential for rapidly responding to and recovering from cyber incidents, minimising operational impact.',
        value: 0,
        preSeed: false,
        seriesA: true,
        corp: true,
        key: 'cyber_instance_recovery',
    },
    {
        label: 'Segmentation of your computer network byoperational function, data classification and operational risk.',
        description: 'Limits the spread of breaches and reduces risks by isolating critical network segments.',
        value: 0,
        preSeed: false,
        seriesA: true,
        corp: true,
        key: 'network_segmentation',
    },
    {
        label: 'Turn off local domain control on all owned managed endpoints.',
        description: 'Reenforces centralised security management.',
        value: 0,
        preSeed: false,
        seriesA: false,
        corp: true,
        key: 'local_domain_control',
    },
    {
        label: 'Enterprise-wisde password management (creating, storing and managing) across the team',
        description: 'Robust management assures investors and buyers.',
        value: 0,
        preSeed: false,
        seriesA: false,
        corp: true,
        key: 'enterprise_wide_password',
    },
    {
        label: 'Asset register of service accounts with domain credentials, services and monitoring.',
        description: 'Critical for identifying and mitigating potential vulnerabilities',
        value: 0,
        preSeed: false,
        seriesA: false,
        corp: true,
        key: 'asset_footprint',
    },
    {
        label: 'Security information and event monitoring (SIEM) tool.',
        description: 'Real-time analysis of security alerts, essential for early threat detection',
        value: 0,
        preSeed: false,
        seriesA: false,
        corp: true,
        key: 'siem_tools',
    },
    {
        label: 'Data loss prevention (DLP) tool.',
        description: 'Protects sensitive data from leaks or unauthorized access, crucial for regulatory compliance',
        value: 0,
        preSeed: false,
        seriesA: false,
        corp: true,
        key: 'dlp_tools',
    },
    {
        label: 'Follow an information security framework.',
        description: 'Ensures consistent and comprehensive protection aligned with industry best practices.',
        value: 0,
        preSeed: false,
        seriesA: false,
        corp: true,
        key: 'info_security_framework',
    },
];

export const stages = [
    { title: 'Not Planned', description: 'Idea formation stage.' },
    { title: 'Planned', description: 'Defined plans not yet in action.' },
    { title: 'Concept', description: 'Active early-stage work.' },
    { title: 'Created', description: 'Plan in operation.' },
    { title: 'Operational', description: 'Implemented and functioning.' },
    { title: 'Optimized', description: 'Refinement and efficiency improvements stage.' },
];

export default securityIndexData;
