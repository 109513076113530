import React from 'react';
import styles from './css/index.module.css';
import { Input } from '@fluentui/react-components';
import { ChevronDown16Regular, Search20Regular } from '@fluentui/react-icons';
import { PatchProjectData } from '../../redux/projectDataSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
const AddPublicProfiles = ({ setSelectedView, compareObjectsValues }) => {
    const dispatch = useDispatch();

    const { projectDetailButtonLoading, selectedProject } = useSelector(state => state.projectData);
    const [formData, setFormData] = React.useState({
        crunchbase: '',
        endole: '',
        glassdoor: '',
    });

    const handleChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleSubmit = async () => {
        await dispatch(
            PatchProjectData({
                data: formData,
                id: selectedProject?.id,
            }),
        );
    };
    const handleSearchOnWeb = platform => {
        let withoutSpecialChracterString = selectedProject?.company_name.replace(/[^a-zA-Z0-9\s]/g, '');
        const searchQuery = (withoutSpecialChracterString + ' ' + platform).replace(' ', '+');
        window.open(`https://www.google.com/search?q=${searchQuery}`, '_blank');
    };
    const handleSetFormValues = () => {
        setFormData({
            crunchbase: selectedProject?.crunchbase,
            endole: selectedProject?.endole,
            glassdoor: selectedProject?.glassdoor,
        });
    };
    React.useEffect(() => {
        if (selectedProject) {
            handleSetFormValues();
        }
    }, [selectedProject]);
    return (
        <div className={styles.componentCard}>
            <h5 className={styles.componentTitle}>Public profiles</h5>
            <div className="mb-4">
                <div className="d-flex align-items-center justify-content-between">
                    <p className={styles.fieldLabel}>Crunchbase profile</p>
                    <Search20Regular onClick={() => handleSearchOnWeb('Crunchbase profile')} role="button" color="var(--Brand-Secondary)" />
                </div>
                <Input className={styles.inputField} value={formData?.crunchbase} onChange={handleChange} name="crunchbase" />
            </div>

            <div className="mb-4">
                <div className="d-flex align-items-center justify-content-between">
                    <p className={styles.fieldLabel}>Glassdoor profile</p>
                    <Search20Regular onClick={() => handleSearchOnWeb('Glassdoor profile')} role="button" color="var(--Brand-Secondary)" />
                </div>
                <Input className={styles.inputField} value={formData?.glassdoor} onChange={handleChange} name="glassdoor" />
            </div>
            <div className="mb-5">
                <div className="d-flex align-items-center justify-content-between">
                    <p className={styles.fieldLabel}>Endole profile</p>
                    <Search20Regular onClick={() => handleSearchOnWeb('Endole profile')} role="button" color="var(--Brand-Secondary)" />
                </div>
                <Input className={styles.inputField} value={formData?.endole} onChange={handleChange} name="endole" />
            </div>
            <div className={styles.buttonDiv}>
                {!compareObjectsValues(selectedProject, formData) && (
                    <div className="d-flex align-items-center justify-content-between">
                        <button onClick={handleSubmit} className={styles.submitButton}>
                            Save {projectDetailButtonLoading && <AiOutlineLoading3Quarters className="ms-2 rotateLoader" />}
                        </button>
                        <span onClick={handleSetFormValues} className={styles.linkBtn}>
                            Revert
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AddPublicProfiles;
