import ForgotPassword from '../components/ForgotPassword/index';
import Pageheader from '../utils/headerHelment';

const ForgotPasswordView = () => {
    return (
        <>
            <Pageheader title="LENS - Forgot Password" />
            <ForgotPassword />
        </>
    );
};

export default ForgotPasswordView;
