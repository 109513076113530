import Layout from '../components/Layout';
import MessagesComponent from '../components/Messages';
import BasicHeader from '../components/CommonComponents/BasicHeader';
import { useSelector } from 'react-redux';
import Pageheader from '../utils/headerHelment';

const Messages = () => {
    const { ndaData } = useSelector(state => state?.nda);
    const { selectedProject } = useSelector(state => state?.projectData);
    const { user } = useSelector(state => state.user);
    const userType = user?.types;
    return (userType === 'Consultant' && ndaData?.nda_signed === false) || !selectedProject ? (
        <>
            <Pageheader title="LENS - Messages" />
            <BasicHeader />
            <MessagesComponent />
        </>
    ) : (
        <Layout>
            <Pageheader title="LENS - Messages" />
            <MessagesComponent />
        </Layout>
    );
};

export default Messages;
