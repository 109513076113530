import React, { useEffect, useState } from 'react';
import styles from './css/HeaderSidebars.module.css';
import { Dropdown, Offcanvas } from 'react-bootstrap';
import { IoIosClose } from 'react-icons/io';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { BsThreeDots } from 'react-icons/bs';
import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteNote, DeleteNotePage, GetNotes, PatchNote, PatchNotesPage, PostNote, PostNotesPage } from '../../redux/dashboardSlice';
import { ObservationFormSidebar } from '../Observations/index';
import { TaskFormSidebar } from '../TasksInterface';
import { RiDeleteBinLine } from 'react-icons/ri';
import { PostInformationRequestSidebar } from '../IRL';
// import MenuItem from 'react-bootstrap/esm/DropdownItem';
import DoubleConfirmationModal from './DoubleConfirmationModal/DoubleConfirmationModal';
import { format } from 'date-fns';
import { DrawerBody, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, OverlayDrawer } from '@fluentui/react-components';
import { Dismiss20Regular, MoreVertical20Regular, ChevronLeft20Regular, ChevronRight20Regular, AddCircle20Filled, Delete20Regular } from '@fluentui/react-icons';
import ReactPaginate from 'react-paginate';
export const NotepadSidebar = ({ show, setShow }) => {
    const dispatch = useDispatch();
    const { selectedProject } = useSelector(state => state.projectData);
    const { notes } = useSelector(state => state.dashboard);
    const { user } = useSelector(state => state.user);
    const [text, setText] = React.useState('');
    const titleInputRef = React.useRef(null);
    const editInputRef = React.useRef(null);
    const [editedComment, setEditedComment] = React.useState('');
    const [selectedPage, setSelectedPage] = React.useState();
    const quilRef = React.useRef(null);
    const [selectedComment, setSelectedComment] = React.useState();
    const [editModes, setEditModes] = React.useState([]);
    const [menuPosition, setMenuPosition] = React.useState({ top: 0, left: 0 });
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const menuRef = React.useRef(null);
    const [showObsSidebar, setShowObsSidebar] = React.useState(false);
    const [showTaskSidebar, setShowTaskSidebar] = React.useState(false);
    const [showIRLSidebar, setShowIRLSidebar] = React.useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const deletePageRef = React.useRef();
    const deleteNoteRef = React.useRef();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => {
        if (selectedProject) {
            const effectAsync = async () => {
                const res = await dispatch(GetNotes());
                if (res?.payload?.data && res?.payload?.data?.length === 0) {
                    handleCreateNewPage();
                }
            };
            effectAsync();
        }
    }, [dispatch, selectedProject]);
    const [editTitle, setEditTitle] = React.useState(false);
    const handleSaveComment = () => {
        if (text !== '' && text !== '<p><br></p>')
            dispatch(
                PostNote({
                    notes: text,
                    notepad: selectedPage?.id,
                }),
            );
        // quilRef.current.state.value = '';
        setText('');
    };
    const handleOpenEditBox = (data, index) => {
        const newEditModes = [...editModes];
        newEditModes[index] = true;
        setEditModes(newEditModes);
        setEditedComment(data?.notes);
        setSelectedComment(data);
        setTimeout(() => editInputRef?.current?.focus(), 100);
    };
    const handleDoneEdit = index => {
        setEditModes(newEditModes => {
            const updatedEditModes = [...newEditModes];
            updatedEditModes[index] = false;
            return updatedEditModes;
        });
        dispatch(
            PatchNote({
                notes: editedComment,
                notepad: selectedPage?.id,
                id: selectedComment?.id,
            }),
        );
        setEditedComment('');
        setSelectedComment();
    };
    const handleContextMenu = (e, item) => {
        e.preventDefault();
        setMenuPosition({ top: e.clientY, left: e.clientX });
        setSelectedComment(item);
        setIsMenuOpen(true);
    };
    const handleCloseMenu = () => {
        setIsMenuOpen(false);
        setSelectedComment();
        setSelectedPage();
    };
    const handleCreateNewPage = async () => {
        let body = {
            notes: [],
            project: localStorage['project_id'],
            user: user?.id,
            name: 'Click to add page title',
        };
        let res = await dispatch(PostNotesPage(body));
        console.log(res);
        if (res?.type === 'POST_NOTES_PAGE/fulfilled') {
            setSelectedPage(res?.payload?.data);
            setCurrentPage(notes?.length);
        }
    };
    const handleDeletePage = async () => {
        await dispatch(DeleteNotePage(selectedPage?.id));

        if (notes?.length === 1) {
            handleCreateNewPage();
        } else {
            let updatedNotes = notes?.filter(item => item?.id !== selectedPage?.id);
            setCurrentPage(updatedNotes?.length - 1);
            setSelectedPage(updatedNotes[updatedNotes?.length - 1]);
        }
    };
    const handleChangeTitle = () => {
        dispatch(
            PatchNotesPage({
                id: selectedPage?.id,
                name: selectedPage?.name,
            }),
        );
    };

    const handleDeleteNote = () => {
        dispatch(
            DeleteNote({
                id: selectedComment?.id,
                notepad: selectedComment?.notepad,
            }),
        );
        setSelectedComment();
    };
    // console.log(selectedComment);
    React.useEffect(() => {
        if (notes?.length > 0) {
            if (!selectedPage) {
                setSelectedPage(notes[0]);
            } else {
                let data = notes?.find(item => item?.id === selectedPage?.id);
                setSelectedPage(data ? data : notes[0]);
            }
        }
    }, [notes]);
    React.useEffect(() => {
        const handleClickOutside = e => {
            if (menuRef.current && !menuRef.current.contains(e.target)) {
                setIsMenuOpen(false);
                setSelectedComment();
            }
        };
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);
    return (
        <div>
            <OverlayDrawer style={{ minWidth: 400 }} type="overlay" position="end" open={show} onOpenChange={(_, { open }) => setShow(open)}>
                <DrawerBody style={{ padding: 16 }}>
                    <div className="d-flex justify-content-between">
                        <div>
                            {editTitle ? (
                                <input
                                    onChange={e => setSelectedPage({ ...selectedPage, name: e.target.value })}
                                    value={selectedPage?.name}
                                    ref={titleInputRef}
                                    className={styles.titleInput}
                                    onBlur={handleChangeTitle}
                                />
                            ) : (
                                <b
                                    onDoubleClick={() => {
                                        setEditTitle(true);
                                        setTimeout(() => titleInputRef?.current?.focus(), 100);
                                    }}
                                    className={styles.titleInput}>
                                    {selectedPage?.name}
                                </b>
                            )}
                        </div>
                        <div>
                            <Menu>
                                <MenuTrigger disableButtonEnhancement>
                                    <MoreVertical20Regular className="me-2" color="var(--Neutral-Gray-6)" />
                                </MenuTrigger>
                                <MenuPopover>
                                    <MenuList>
                                        <MenuItem icon={<Delete20Regular />} onClick={() => deletePageRef?.current?.click()}>
                                            Delete Page
                                        </MenuItem>
                                    </MenuList>
                                </MenuPopover>
                            </Menu>
                            <Dismiss20Regular role="button" onClick={() => setShow(false)} color="var(--Neutral-Gray-6)" />
                        </div>
                    </div>
                    <br />
                    <div className={styles.notesMainBody}>
                        <div className={styles.customReactQuill + ' mb-3 mt-3'}>
                            <p style={{ marginBottom: -32 }} className={styles.fieldLabel}>
                                New Note <span style={{ color: 'var(--danger)' }}>*</span>
                            </p>
                            <ReactQuill
                                // placeholder={'Type here'}
                                modules={{
                                    toolbar: [['bold', 'italic', 'underline'], [{ list: 'ordered' }, { list: 'bullet' }], ['link']],
                                }}
                                theme="snow"
                                className="mt-1"
                                defaultValue={text}
                                value={text}
                                // ref={quilRef}
                                onChange={setText}
                            />
                            <button onClick={handleSaveComment} className={`btn btn-primary btn-sm ${styles.saveButton}`}>
                                Add Note
                            </button>
                        </div>
                        <div>
                            {selectedPage?.notes
                                ?.slice()
                                ?.reverse()
                                ?.map((item, index) => (
                                    <div key={item?.id}>
                                        {editModes[index] ? (
                                            <div className={styles.customReactQuill + ' mb-3 mt-3'}>
                                                <ReactQuill
                                                    placeholder={'Type here'}
                                                    modules={{
                                                        toolbar: [['bold', 'italic', 'underline'], [{ list: 'ordered' }, { list: 'bullet' }], ['link']],
                                                    }}
                                                    theme="snow"
                                                    // className={styles.customQuill}
                                                    value={editedComment}
                                                    onChange={t => setEditedComment(t)}
                                                    // onBlur={() => handleDoneEdit(index)}
                                                    ref={editInputRef}
                                                />
                                                <p className={styles.editSave} onClick={() => handleDoneEdit(index)}>
                                                    Save
                                                </p>
                                            </div>
                                        ) : (
                                            <>
                                                <div className={styles.richTextDiv}>
                                                    <p
                                                        key={index}
                                                        onContextMenu={e => handleContextMenu(e, item)}
                                                        onDoubleClick={() => handleOpenEditBox(item, index)}
                                                        className={styles.richText}
                                                        dangerouslySetInnerHTML={{ __html: item?.notes }}></p>
                                                </div>

                                                <div className="d-flex justify-content-end align-items-center">
                                                    <span className={styles.dateText}>{item?.created && format(new Date(item?.created), 'dd/MM/yyyy')}</span>
                                                    <Menu>
                                                        <MenuTrigger disableButtonEnhancement>
                                                            <MoreVertical20Regular className="me-2" color="var(--Neutral-Gray-6)" />
                                                        </MenuTrigger>
                                                        <MenuPopover>
                                                            <MenuList>
                                                                <MenuItem onClick={() => handleOpenEditBox(item, index)}>Edit Note</MenuItem>
                                                                <MenuItem
                                                                    onClick={() => {
                                                                        setSelectedComment(item);
                                                                        deleteNoteRef?.current?.click();
                                                                    }}>
                                                                    Delete Note
                                                                </MenuItem>
                                                                <MenuItem
                                                                    onClick={() => {
                                                                        setSelectedComment(item);
                                                                        setShowObsSidebar(true);
                                                                    }}>
                                                                    {' '}
                                                                    Convert to Observation
                                                                </MenuItem>
                                                                <MenuItem
                                                                    onClick={() => {
                                                                        setSelectedComment(item);
                                                                        setShowTaskSidebar(true);
                                                                    }}>
                                                                    {' '}
                                                                    Convert to Task
                                                                </MenuItem>
                                                                <MenuItem
                                                                    onClick={() => {
                                                                        setSelectedComment(item);
                                                                        setShowIRLSidebar(true);
                                                                    }}>
                                                                    {' '}
                                                                    Convert to Information Request
                                                                </MenuItem>
                                                            </MenuList>
                                                        </MenuPopover>
                                                    </Menu>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                ))}
                            {isMenuOpen && (
                                <div
                                    ref={menuRef}
                                    className="menu-container"
                                    style={{ top: menuPosition.top, left: -window.innerWidth + 400 + menuPosition?.left, position: 'fixed' }}>
                                    <CustomMenu
                                        onClose={handleCloseMenu}
                                        setShowObsSidebar={setShowObsSidebar}
                                        setShowTaskSidebar={setShowTaskSidebar}
                                        setShowIRLSidebar={setShowIRLSidebar}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <PaginatedItems
                        handleCreateNewPage={handleCreateNewPage}
                        notes={notes}
                        setSelectedPage={setSelectedPage}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />

                    <DoubleConfirmationModal
                        handleAction={handleDeletePage}
                        message={'Deleting a page removes associated notes from the project, and this action is irreversible.'}>
                        <div ref={deletePageRef} />
                    </DoubleConfirmationModal>
                    <DoubleConfirmationModal handleAction={handleDeleteNote} message={'Deleting a note permanently removes it from the Project, and this is irreversible.'}>
                        <div ref={deleteNoteRef} />
                    </DoubleConfirmationModal>
                </DrawerBody>
            </OverlayDrawer>

            <ObservationFormSidebar show={showObsSidebar} setShow={setShowObsSidebar} setDefaultData={setSelectedComment} defaultObservation={selectedComment?.notes} />
            <TaskFormSidebar show={showTaskSidebar} setShow={setShowTaskSidebar} setDefaultData={setSelectedComment} defaultTask={selectedComment?.notes} />
            <PostInformationRequestSidebar show={showIRLSidebar} setShow={setShowIRLSidebar} setDefaultData={setSelectedComment} defaultRequest={selectedComment?.notes} />
        </div>
    );
};
const CustomMenu = ({ onClose, setShowObsSidebar, setShowTaskSidebar, setShowIRLSidebar }) => {
    return (
        <div className={styles?.customMenu}>
            <p onClick={() => setShowObsSidebar(true)}>Convert to Observation</p>

            <p onClick={() => setShowTaskSidebar(true)}>Convert to Task</p>

            <p onClick={() => setShowIRLSidebar(true)}>Convert to Information Request</p>
        </div>
    );
};

function PaginatedItems({ handleCreateNewPage, setSelectedPage, notes, currentPage, setCurrentPage }) {
    // We start with an empty list of items.

    const handlePageClick = event => {
        console.log(`User requested page number ${event.selected},`);
        setCurrentPage(event.selected);
        setSelectedPage(notes[event.selected]);
    };
    const handleNavigate = dir => {
        if (dir === 'prev') {
            if (currentPage !== 0) {
                setCurrentPage(currentPage - 1);
                setSelectedPage(notes[currentPage - 1]);
            }
        } else {
            if (currentPage !== notes?.length - 1) {
                setCurrentPage(currentPage + 1);
                setSelectedPage(notes[currentPage + 1]);
            }
        }
    };
    return (
        <div className="d-flex align-items-center ">
            <ChevronLeft20Regular
                color={currentPage === 0 ? 'var(--Neutral-Gray-6)' : 'var(--Brand-Secondary)'}
                role="button"
                className={styles.paginationIcon}
                onClick={() => handleNavigate('prev')}
            />
            <div className={styles.paginatinoMainBody}>
                <ReactPaginate
                    nextLabel={<></>}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={1}
                    forcePage={currentPage}
                    pageCount={notes?.length}
                    previousLabel={<></>}
                    pageClassName={'page-item ' + styles.pageItem}
                    breakClassName={'page-item ' + styles.pageItem}
                    pageLinkClassName="page-link"
                    breakLabel="..."
                    breakLinkClassName="page-link"
                    containerClassName={'pagination ' + styles.paginationBody}
                    activeClassName={'active ' + styles.activePage}
                    renderOnZeroPageCount={null}
                />
            </div>
            <AddCircle20Filled onClick={handleCreateNewPage} className={styles.paginationIcon + ' me-2'} role="button" color="var(--Brand-Secondary)" />
            <ChevronRight20Regular
                onClick={() => handleNavigate('next')}
                role="button"
                color={currentPage === notes?.length - 1 ? 'var(--Neutral-Gray-6)' : 'var(--Brand-Secondary)'}
                className={styles.paginationIcon}
            />
        </div>
    );
}
