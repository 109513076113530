import React from 'react';
import Login from '../components/Login';
import Pageheader from '../utils/headerHelment';

const LoginPage = () => {
    React.useEffect(() => {
        window.Beacon('identify', {
            name: '',
            email: '',
        });
    }, []);
    return (
        <>
            <Pageheader title="LENS - Login" />
            <Login />
        </>
    );
};

export default LoginPage;
