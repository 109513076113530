import React from 'react';
import { NDAReview, NDAView } from '../components/NDA';
import { useSelector, useDispatch } from 'react-redux';
import Layout from '../components/Layout';
import Pageheader from '../utils/headerHelment';

const NDAPage = () => {
    const { user } = useSelector(state => state.user);
    const { selectedProject } = useSelector(state => state.projectData);
    const notAdmin = selectedProject?.vdr?.vdr_admin !== user?.id;
    const userData = selectedProject?.vdr?.nda?.signees?.find(item => item?.user === user?.id);
    return (
        <>
            <Pageheader title="LENS - NDA" />
            {/* {!userData?.nda_signed && notAdmin ? <NDAReview /> : <NDAView />} */}
            <NDAView />
        </>
    );
};

export default NDAPage;
