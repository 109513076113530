import React from 'react';
import Layout from '../components/Layout';
import VDRTeam from '../components/Team/VDRTeam';
import DDTeam from '../components/Team/DDTeam';
import Pageheader from '../utils/headerHelment';
import ReactJoyride from 'react-joyride';
import { useDispatch, useSelector } from 'react-redux';
import { setJoyRideStepIndex } from '../redux/teamSlice';
import Cookies from 'js-cookie';
import { AiOutlineClose } from 'react-icons/ai';
import { GetCurrentProjectData } from '../redux/projectDataSlice';
import { useParams } from 'react-router';

const TeamView = () => {
    const params = useParams();
    const { user } = useSelector(state => state.user);
    console.log(params);
    return params?.type === 'dd' ? <DDTeams /> : <VDRTeams />;
};

const VDRTeams = () => {
    const dispatch = useDispatch();

    const { joyRideStepIndex } = useSelector(state => state?.team);
    const [runJoyRide, setRunJoyRide] = React.useState(false);
    const [showAddUserSidebar, setShowAddUserSidebar] = React.useState(false);
    const [showAddGroupModal, setShowAddGroupModal] = React.useState(false);
    const [joyRideSteps] = React.useState([
        {
            // Add Group Button
            target: '.page-title',
            content: (
                <div className="ps-2 pe-2 regularFont">
                    <div className="text-end">
                        <AiOutlineClose color="var(--Brand-Secondary)" onClick={() => setRunJoyRide(false)} role="button" />
                    </div>
                    <div className="text-start">
                        Welcome to your VDR Team Management. It is imperative that you upload an NDA to this project, if you haven’t already, to be able to invite users to the
                        team.
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-3">
                        <span style={{ color: 'var(--muted)' }} role="button"></span>
                        <button onClick={() => dispatch(setJoyRideStepIndex(1))} className="btn btn-primary smallButton">
                            Next
                        </button>
                    </div>
                </div>
            ),
            hideFooter: true,
            disableBeacon: true,
            disableOverlayClose: true,
            spotlightClicks: true,
        },
        {
            // Add Group Button
            target: '.add-group-btn',
            // target: groups?.length === 0 ? '.add-group-box' : '.add-group-btn',
            content: (
                <div className="ps-2 pe-2 regularFont">
                    <div className="text-end">
                        <AiOutlineClose color="var(--Brand-Secondary)" onClick={() => setRunJoyRide(false)} role="button" />
                    </div>
                    <div className="text-start">
                        On Lens VDR, you can create groups to better manage users and permissions. <b style={{ color: 'var(--Brand-Secondary)' }}> Click the button above</b> to
                        create your first group.
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-3">
                        <span style={{ color: 'var(--muted)' }} role="button" onClick={() => dispatch(setJoyRideStepIndex(0))}>
                            Previous
                        </span>
                    </div>
                </div>
            ),
            hideFooter: true,
            disableBeacon: true,
            disableOverlayClose: true,
            spotlightClicks: true,
        },
        {
            // Add Group ADDBTN
            target: '.add-group-addBtn',
            content: (
                <div className="ps-2 pe-2 regularFont">
                    <div className="text-end">
                        <AiOutlineClose color="var(--Brand-Secondary)" onClick={() => setRunJoyRide(false)} role="button" />
                    </div>
                    <div className="text-start">
                        Start by selecting a name for the group, choose the workstreams you want the group to have access to, and then from the dropdown, select the permission type
                        for the group. Once you are done, <b style={{ color: 'var(--Brand-Secondary)' }}>click on the above button</b> to add your first group.
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-3">
                        <span
                            style={{ color: 'var(--muted)' }}
                            role="button"
                            onClick={() => {
                                dispatch(setJoyRideStepIndex(1));
                                setShowAddGroupModal(false);
                            }}>
                            Previous
                        </span>
                    </div>
                </div>
            ),
            hideFooter: true,
            disableBeacon: true,
            disableOverlayClose: true,
            spotlightClicks: true,
            disableOverlay: true,
            styles: {
                options: {
                    zIndex: 10000,
                    width: 500,
                },
            },
        },
        {
            // Add Group Button
            target: '.add-user-button',
            content: (
                <div className="ps-2 pe-2 regularFont">
                    <div className="text-end">
                        <AiOutlineClose color="var(--Brand-Secondary)" onClick={() => setRunJoyRide(false)} role="button" />
                    </div>
                    <div className="text-start">
                        {' '}
                        <b style={{ color: 'var(--Brand-Secondary)' }}>Click on the above button</b> to add a user to this group.
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-3">
                        <span
                            style={{ color: 'var(--muted)' }}
                            onClick={() => {
                                setShowAddGroupModal(true);
                                setTimeout(() => {
                                    dispatch(setJoyRideStepIndex(2));
                                }, 300);
                            }}
                            role="button">
                            Previous
                        </span>
                    </div>
                </div>
            ),
            hideFooter: true,
            disableBeacon: true,
            disableOverlayClose: true,
            spotlightClicks: true,
        },
        {
            // Add Group ADDBTN
            target: '.send-mail-step',
            content: (
                <div className="ps-2 pe-2 regularFont">
                    <div className="text-end">
                        <AiOutlineClose color="var(--Brand-Secondary)" onClick={() => setRunJoyRide(false)} role="button" />
                    </div>
                    <div className="text-start">Add the email addresses of your invitees in this window and click on the Send Email button to send them an invite.</div>
                    <div className="d-flex align-items-center justify-content-between mt-3">
                        <span
                            style={{ color: 'var(--muted)' }}
                            role="button"
                            onClick={() => {
                                dispatch(setJoyRideStepIndex(3));
                                setShowAddUserSidebar(false);
                            }}>
                            Previous
                        </span>
                    </div>
                </div>
            ),
            hideFooter: true,
            disableBeacon: true,
            disableOverlayClose: true,
            spotlightClicks: true,
            disableOverlay: true,
            styles: {
                options: {
                    zIndex: 10000,
                    width: 500,
                },
            },
        },
        {
            // Add Group Button
            target: '.permission-icon-step',
            content: (
                <div className="ps-2 pe-2 regularFont">
                    <div className="text-end">
                        <AiOutlineClose color="var(--Brand-Secondary)" onClick={() => setRunJoyRide(false)} role="button" />
                    </div>
                    <div className="text-start">You can further customize permissions for the group by click on this button. Or you can customize </div>
                    <div className="d-flex align-items-center justify-content-between mt-3">
                        <span
                            style={{ color: 'var(--muted)' }}
                            onClick={() => {
                                setShowAddUserSidebar(true);
                                setTimeout(() => {
                                    dispatch(setJoyRideStepIndex(4));
                                }, 300);
                            }}
                            role="button">
                            Previous
                        </span>
                        <button onClick={() => dispatch(setJoyRideStepIndex(6))} className="btn btn-primary smallButton">
                            Next
                        </button>
                    </div>
                </div>
            ),
            hideFooter: true,
            disableBeacon: true,
            disableOverlayClose: true,
        },
        {
            //view-tour
            target: '.view-tour',
            content: (
                <div className="ps-2 pe-2 regularFont">
                    <div className="text-end">
                        <AiOutlineClose color="var(--Brand-Secondary)" onClick={() => setRunJoyRide(false)} role="button" />
                    </div>
                    <div className="text-start">This completes the tour of the VDR Team Management. You can always access feature tours using the View Tour button.</div>
                    <div className="d-flex align-items-center justify-content-between mt-3">
                        <span style={{ color: 'var(--muted)' }} role="button" onClick={() => dispatch(setJoyRideStepIndex(5))}>
                            Previous
                        </span>
                        <button onClick={() => setRunJoyRide(false)} className="btn btn-primary smallButton">
                            Exit
                        </button>
                    </div>
                </div>
            ),
            hideFooter: true,
            disableBeacon: true,
            disableOverlayClose: true,
            spotlightClicks: true,
            styles: {
                options: {
                    zIndex: 10000,
                },
            },
        },
    ]);
    //
    React.useEffect(() => {
        dispatch(GetCurrentProjectData());
        const isFirstTimeUser = !Cookies.get('teamsJoyride');
        if (isFirstTimeUser) {
            dispatch(setJoyRideStepIndex(0));
            setRunJoyRide(true);
            Cookies.set('teamsJoyride', true);
        }
    }, []);
    return (
        <Layout container={true}>
            {runJoyRide && (
                <ReactJoyride
                    run={runJoyRide}
                    steps={joyRideSteps}
                    stepIndex={joyRideStepIndex}
                    continuous
                    hideCloseButton
                    spotlightPadding={5}
                    styles={{
                        tooltip: {
                            padding: 0,
                        },
                    }}
                />
            )}
            <Pageheader title="LENS - Team" />
            <VDRTeam
                setRunJoyRide={setRunJoyRide}
                showAddGroupModal={showAddGroupModal}
                setShowAddGroupModal={setShowAddGroupModal}
                showAddUserSidebar={showAddUserSidebar}
                setShowAddUserSidebar={setShowAddUserSidebar}
            />
        </Layout>
    );
};

const DDTeams = () => {
    return (
        <Layout container={true}>
            <Pageheader title="LENS - Team" />
            <DDTeam />
        </Layout>
    );
};

export default TeamView;
