import React from 'react';
import { Helmet } from 'react-helmet';

const Pageheader = props => {
    return (
        <Helmet>
            <title>{props.title}</title>
            <meta charset="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
            <script src="../js/app.js" type="text/javascript" />
        </Helmet>
    );
};

export default Pageheader;
