import React, { useState } from 'react';
import Header from '../CommonComponents/Header';
import Sidebar from '../CommonComponents/Sidebar';
import styles from './css/Layout.module.css';

function Layout(props) {
    const [expandSidebar, setExpandSidebar] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    React.useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            <Header />

            <div className="d-flex">
                <Sidebar expandSidebar={expandSidebar} setExpandSidebar={setExpandSidebar} />
                <div style={{ width: `calc(100vw - ${expandSidebar ? 188 : 48}px)`, transition: 'width 0.2s' }}>
                    {props.container ? (
                        <div className={styles.viewHeight}>
                            <div className="container-fluid">{props.children}</div>
                        </div>
                    ) : (
                        props.children
                    )}
                </div>
            </div>
        </div>
    );
}

export default Layout;
