import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axiosInstance from '../apiServices/axios';
import { ALERTS, NOTE, NOTES, NOTIFICATIONS, READ_NOTIFICATION, REMOVE_NOTIFICATION } from '../apiServices/api_routes';
export const GetAlerts = createAsyncThunk('Alerts', async () => {
    const data = await axiosInstance.post(ALERTS, {
        project_id: localStorage['project_id'],
    });
    return data;
});
export const GetNotifications = createAsyncThunk('Notifications', async () => {
    const data = await axiosInstance.get(NOTIFICATIONS);
    return data;
});
export const GetNotes = createAsyncThunk('GET_NOTES', async () => {
    const data = await axiosInstance.get(NOTES + localStorage['project_id'] + '/');
    return data;
});
export const PostNotesPage = createAsyncThunk('POST_NOTES_PAGE', async body => {
    const data = await axiosInstance.post(NOTES + localStorage['project_id'] + '/', body);
    return data;
});
export const DeleteNotePage = createAsyncThunk('DELETE_NOTES_PAGE', async id => {
    const data = await axiosInstance.delete(NOTES + localStorage['project_id'] + '/' + id + '/');
    return data;
});
export const PatchNotesPage = createAsyncThunk('PATCH_NOTES_PAGE', async body => {
    const data = await axiosInstance.patch(NOTES + localStorage['project_id'] + '/' + body?.id + '/', body);
    return data;
});
export const PostNote = createAsyncThunk('POST_NOTES', async body => {
    const data = await axiosInstance.post(NOTE + body?.notepad + '/' + localStorage['project_id'] + '/', body);
    return data;
});
export const PatchNote = createAsyncThunk('PATCH_NOTES', async body => {
    const data = await axiosInstance.patch(NOTE + body?.notepad + '/' + localStorage['project_id'] + '/' + body?.id + '/', { notes: body?.notes, notepad: body?.notepad });
    return data;
});
export const DeleteNote = createAsyncThunk('DELETE_NOTES', async body => {
    const data = await axiosInstance.delete(NOTE + body?.notepad + '/' + localStorage['project_id'] + '/' + body?.id + '/');
    return data;
});
export const RemoveNotification = createAsyncThunk('REMOVE_NOTIFICATION', async ({ id }) => {
    const data = await axiosInstance.delete(REMOVE_NOTIFICATION + id);
    return data;
});
export const ReadNotification = createAsyncThunk('READ_NOTIFICATION', async ({ id }) => {
    const data = await axiosInstance.post(READ_NOTIFICATION + id + '/');
    return data;
});
export const dashboardSlice = createSlice({
    name: 'dashboardSlice',
    initialState: {
        notificationsLoading: true,
        notifications: [],
        alertsLoading: true,
        alerts: [],
        status: null,
        unReadNotificationCount: 0,
        notes: [],
    },
    reducers: {
        setNotifications: (state, action) => {
            return { ...state, notifications: action.payload };
        },
        setAlerts: (state, action) => {
            return { ...state, alerts: action.payload };
        },
    },
    extraReducers: {
        [GetAlerts.fulfilled]: (state, { payload }) => {
            const data = payload?.data?.alerts?.filter((v, i, a) => a?.indexOf(v) === i);
            state.alerts = data;
            state.alertsLoading = false;
        },
        [GetAlerts.rejected]: (state, action) => {
            state.error = true;
            state.errorMessage = action?.error?.message;
            state.alerts = [];
            state.alertsLoading = false;
        },

        [GetNotifications.fulfilled]: (state, { payload }) => {
            state.notifications = payload?.data;
            state.notificationsLoading = false;
            state.unReadNotificationCount = payload?.data?.reduce((prev, next) => {
                return prev + next?.unread;
            }, 0);
        },
        [GetNotifications.rejected]: (state, action) => {
            state.error = true;
            state.errorMessage = action?.error?.message;
            state.notifications = [];
            state.notificationsLoading = false;
        },
        [RemoveNotification.fulfilled]: (state, action) => {
            // state.notifications = payload?.data;
            toast.success('Successfully Removed');
            state.notifications = state.notifications?.filter(item => {
                if (item.id !== action.meta?.arg?.id) {
                    return item;
                }
            });
        },
        [RemoveNotification.rejected]: (state, action) => {
            toast.error('Notification failed to remove ');
        },
        [ReadNotification.fulfilled]: (state, action) => {
            // state.notifications = payload?.data;
            // toast.success('Successfully Removed');
            state.notifications = state.notifications?.map(item => {
                if (item.id === action.meta?.arg?.id) {
                    return { ...item, unread: false };
                } else return item;
            });
        },
        [ReadNotification.rejected]: (state, action) => {
            // toast.error('Notification failed to remove ');
        },
        //GetNotes
        [GetNotes.fulfilled]: (state, { payload }) => {
            state.notes = payload.data;
        },
        //PostNotesPage
        [PostNotesPage.fulfilled]: (state, { payload }) => {
            state.notes.push(payload.data);
        },
        [PostNotesPage.rejected]: (state, { payload }) => {
            toast.error('Error');
        },
        //DeleteNotePage
        [DeleteNotePage.fulfilled]: (state, { meta }) => {
            state.notes = state.notes?.filter(item => item?.id !== meta?.arg);
        },
        [DeleteNotePage.rejected]: (state, { payload }) => {
            toast.error('Error');
        },
        //PatchNotesPage
        [PatchNotesPage.fulfilled]: (state, { payload }) => {
            state.notes = state.notes?.map(item => {
                if (item?.id === payload?.data?.id) return payload?.data;
                else return item;
            });
        },
        [PatchNotesPage.rejected]: (state, { payload }) => {
            toast.error('Error');
        },
        //PostNote
        [PostNote.pending]: (state, { payload }) => {},
        [PostNote.fulfilled]: (state, { payload }) => {
            state.notes = state.notes?.map(item => {
                if (item?.id === payload?.data?.notepad) {
                    return { ...item, notes: [...item?.notes, payload?.data] };
                } else return item;
            });
        },
        [PostNote.rejected]: (state, { payload }) => {
            toast.error('Error');
        },
        //PatchNote
        [PatchNote.pending]: (state, { payload }) => {},
        [PatchNote.fulfilled]: (state, { payload }) => {
            state.notes = state.notes?.map(item => {
                if (item?.id === payload?.data?.notepad) {
                    let filteredNotes = item?.notes?.map(note => {
                        if (note?.id === payload?.data?.id) return payload?.data;
                        else return note;
                    });
                    return { ...item, notes: filteredNotes };
                } else return item;
            });
        },
        [PatchNote.rejected]: (state, { payload }) => {
            toast.error('Error');
        },
        //DeleteNote
        [DeleteNote.fulfilled]: (state, { meta }) => {
            state.notes = state.notes.map(item => {
                if (item?.id === meta?.arg?.notepad) {
                    let filteredNotes = item?.notes?.filter(note => note?.id !== meta?.arg?.id);
                    return { ...item, notes: filteredNotes };
                } else return item;
            });
        },
        [DeleteNote.rejected]: (state, { payload }) => {
            toast.error('Error');
        },
    },
});
export const { setNotifications, setMessages, setAlerts } = dashboardSlice.actions;
export default dashboardSlice.reducer;
