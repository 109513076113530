// import { Navigate } from 'react-router';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
const ProtectedRoute = ({ children, allowedRoles }) => {
    const location = useLocation();
    const { user } = useSelector(state => state.user);
    return Cookies.get('refresh') ? 1 === 1 ? <Outlet /> : <Navigate to={'/project'} state={{ from: location }} replace /> : <Navigate to="/" state={{ from: location }} replace />;
};
export default ProtectedRoute;
