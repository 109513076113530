import styles from './css/MessageList.module.css';
import { useDispatch } from 'react-redux';
import { setMessageId } from '../../redux/messagesSlice';

import { format } from 'date-fns';
import { FaStar } from 'react-icons/fa';

const getProfileNameComponent = name => {
    let firstName,
        lastName,
        profileName = '',
        profileImage;

    if (name) {
        firstName = name.split(' ')[0];
        lastName = name.split(' ')[1];

        if (firstName) {
            profileName = firstName[0];
        }
        if (lastName) {
            profileName += lastName[0];
        }
    }

    profileImage = <div>{profileName ? profileName.toUpperCase() : ''}</div>;
    return profileImage;
};
const setBgcolor = type => {
    let color;
    if (type == 'Investor') {
        color = '#6f69ac';
    } else if (type == 'Consultant') {
        color = '#003149';
    } else if (type == 'Management') {
        color = '#ac69ac';
    }
    return color;
};
export const UnReadThread = ({ data, setIsNewMessage }) => {
    const dispatch = useDispatch();
    const handleOnClick = () => {
        setIsNewMessage(false);
        dispatch(setMessageId(data?.uuid));
    };

    const getProfileNameComponent = name => {
        let firstName,
            lastName,
            profileName = '',
            profileImage;

        if (name) {
            firstName = name.split(' ')[0];
            lastName = name.split(' ')[1];

            if (firstName) {
                profileName = firstName[0];
            }
            if (lastName) {
                profileName += lastName[0];
            }
        }

        profileImage = <div>{profileName ? profileName.toUpperCase() : ''}</div>;
        return profileImage;
    };

    return (
        <div onClick={handleOnClick} className={styles.listSection}>
            <div className="row">
                <div className=" col-2">
                    <div
                        style={{
                            backgroundColor: setBgcolor(data?.sender?.user_type),
                        }}
                        className={styles.avatarStyle}
                    >
                        {data?.sender?.display_name ? getProfileNameComponent(data?.sender?.display_name) : <div></div>}
                    </div>
                </div>
                <div className=" col-7">
                    <h5 className={styles.msgTitle}>{data?.sender?.is_user ? 'You' : data?.sender?.display_name}</h5>
                    <p className={`${styles.msgDescription} text-truncate`} dangerouslySetInnerHTML={{ __html: data?.last_message }}></p>
                </div>
                <div className=" col-3 text-center">
                    <p className={styles.msgTime}>{format(new Date(data?.sent_at), 'kk:mm')}</p>
                    <span className={`${styles.notificationBadge} badge rounded-pill `}>{data?.total_unread}</span>
                </div>
            </div>
        </div>
    );
};
export const ReadThread = ({ bookmark, data, setIsNewMessage }) => {
    const dispatch = useDispatch();
    const handleOnClick = () => {
        setIsNewMessage(false);
        dispatch(setMessageId(data?.uuid));
    };
    return (
        <div onClick={handleOnClick} className={styles.listSectionSimple}>
            <div className="row">
                <div className="col-2">
                    <div
                        style={{
                            backgroundColor: setBgcolor(data?.sender?.user_type),
                        }}
                        className={styles.avatarStyle}
                    >
                        {data?.sender?.display_name ? getProfileNameComponent(data?.sender?.display_name) : <div></div>}
                    </div>
                </div>
                <div className="col-7">
                    <h5 className={styles.msgTitle}>{data?.sender?.is_user ? 'You' : data?.sender?.display_name}</h5>
                    <p className={`${styles.msgDescription} text-truncate`} dangerouslySetInnerHTML={{ __html: data?.last_message }}></p>
                </div>
                <div className="col-3 text-center">
                    <p className={styles.msgTime}>{format(new Date(data?.sent_at), 'kk:mm')}</p>
                    {bookmark ? <FaStar className={styles.starIconSelected} /> : <FaStar className={styles.starIcon} />}
                </div>
            </div>
        </div>
    );
};
