import React, { useEffect, useMemo, useRef } from 'react';
import styles from './css/index.module.css';
import { ArrowSortUp16Regular, ChevronCircleLeft20Filled, ChevronCircleRight20Filled, ArrowTurnUpLeft20Regular, Dismiss16Regular } from '@fluentui/react-icons';
import { useSelector } from 'react-redux';
import { formatDateDifferenc, getExtensionIcon, getFileSize, getFileTypeByExtension, getInitials } from '../../utils/commonUtils';
import ReactApexChart from 'react-apexcharts';
import { format, getYear, getMonth } from 'date-fns';
import FileViewerModal from './FileViewerModal';
import * as d3 from 'd3';
const Analytics = ({ selectedFolders, filters, setFilters, setShowAnalytics }) => {
    const { projectUsersAll } = useSelector(state => state?.team);
    const selectedFolder = useMemo(() => {
        return selectedFolders?.length > 0 ? selectedFolders[selectedFolders.length - 1] : {};
    }, [selectedFolders]);
    const [showFileModal, setShowFileModal] = React.useState(false);
    const { data } = useSelector(state => state?.documents);
    const { selectedUserData } = useSelector(state => state?.team);
    const { user } = useSelector(state => state?.user);
    const [selectedFile, setSelectedFile] = React.useState();
    const [analyticFiles, setAnalyticFiles] = React.useState([]);
    const [selectedMember, setMember] = React.useState();
    const [teamCount, setTeamCount] = React.useState({
        teamReadByFiles: 0,
        selfReadByFiles: 0,
        totalFiles: 0,
    });

    const [uploadData, setUploadData] = React.useState({
        first: null,
        second: null,
    });

    React.useEffect(() => {
        if (selectedFolder?.id) {
            let arr = [];
            data?.forEach(doc => {
                if (doc?.ws_folder_id === selectedFolder?.id) {
                    arr.unshift(doc);
                }
            });
            arr?.sort((objA, objB) => Number(new Date(objA?.metadata_creation_date || objA?.created)) - Number(new Date(objB?.metadata_creation_date || objB?.created)));
            arr.reverse();
            setAnalyticFiles(arr);
        } else {
            setAnalyticFiles(data);
        }
    }, [selectedFolder, data]);
    const authorsArr = [...new Set(analyticFiles?.map(d => d?.metadata_author))];

    const getUserDetail = id => {
        let addedBy = projectUsersAll?.find(item => item?.value?.group_member_id === id);
        return (
            <>
                <span>{addedBy?.value?.full_name || 'Project Admin'} </span>
            </>
        );
    };
    const handleClick = (label, value) => {
        setFilters({
            type: 'Read Count / ',
            value: value,
            label: label,
        });
    };
    React.useEffect(() => {
        const documentsWithMetadata = analyticFiles.filter(doc => doc.metadata_creation_date);
        documentsWithMetadata.sort((a, b) => new Date(a.metadata_creation_date) - new Date(b.metadata_creation_date));
        setUploadData({
            first: documentsWithMetadata[0],
            second: documentsWithMetadata[documentsWithMetadata.length - 1],
        });
        setTeamCount({
            teamReadByFiles: analyticFiles?.filter(item => item?.read_by?.length > 0).length || 0,
            selfReadByFiles: analyticFiles?.filter(item => item?.read_by?.includes(selectedMember)).length || 0,
            totalFiles: analyticFiles?.length,
        });
    }, [analyticFiles, selectedMember]);
    React.useEffect(() => {
        if (selectedUserData) {
            setMember(selectedUserData?.id);
        }
    }, [selectedUserData]);
    function calculatePercentageHeight(number) {
        if (number === 0) return '0%';
        else {
            let percent = (number / analyticFiles?.length) * 100;
            return percent + '%';
        }
    }
    const teamMemberName = projectUsersAll?.find(item => item?.value?.group_member_id === selectedMember)?.value?.full_name;
    return (
        <div>
            {!filters ? (
                <div className="row">
                    <div className="col-sm-12 col-md-8">
                        <div className={styles.analyticChartBody}>
                            <CreationChart analyticFiles={analyticFiles} setFilters={setFilters} />
                        </div>
                        <div className={styles.circlesMainBody} id="circlesMainBody">
                            <KeywordUsage analyticFiles={analyticFiles} setFilters={setFilters} />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-4">
                        <div className={styles.analyticsSmallCard}>
                            <p className={styles.analyticeMenuText}>First Upload to Dataroom</p>
                            {uploadData?.first && (
                                <>
                                    <div
                                        onClick={() => {
                                            setSelectedFile(uploadData?.first);
                                            setShowFileModal(true);
                                        }}
                                        role="button"
                                        className="d-flex align-items-center"
                                        style={{ marginBottom: '17.5px' }}>
                                        {getExtensionIcon(
                                            (uploadData?.first?.document || uploadData?.first?.document_link)?.split('.').pop(),
                                            20,
                                            20,
                                            uploadData?.first?.document_link,
                                        )}
                                        <div className={styles.fileInfoName}>
                                            {uploadData?.first?.ws_folder_prefix + '.'}
                                            {uploadData?.first?.item_num} {uploadData?.first?.document_name}
                                        </div>
                                    </div>
                                    <p className={styles.dateText}>Posted by {getUserDetail(uploadData?.first?.upload_by)}</p>
                                    <p className={styles.dateText}>
                                        {uploadData?.first?.created && format(new Date(uploadData?.first?.created), 'dd/MM/yyyy')}{' '}
                                        {uploadData?.first?.created && (
                                            <span className={styles.fileSidebarLabel}>{uploadData?.first?.created && formatDateDifferenc(uploadData?.first?.created)}</span>
                                        )}
                                    </p>
                                </>
                            )}
                        </div>
                        <div className={styles.analyticsSmallCard + ' mt-4'}>
                            <p className={styles.analyticeMenuText}>Latest Upload to Dataroom</p>
                            {uploadData?.second && (
                                <>
                                    <div
                                        onClick={() => {
                                            setSelectedFile(uploadData?.second);
                                            setShowFileModal(true);
                                        }}
                                        role="button"
                                        className="d-flex align-items-center"
                                        style={{ marginBottom: '17.5px' }}>
                                        {getExtensionIcon(
                                            (uploadData?.second?.document || uploadData?.second?.document_link)?.split('.').pop(),
                                            20,
                                            20,
                                            uploadData?.second?.document_link,
                                        )}
                                        <div className={styles.fileInfoName}>
                                            {uploadData?.second?.ws_folder_prefix + '.'}
                                            {uploadData?.second?.item_num} {uploadData?.second?.document_name}
                                        </div>
                                    </div>
                                    <p className={styles.dateText}>Posted by {getUserDetail(uploadData?.second?.upload_by)}</p>
                                    <p className={styles.dateText}>
                                        {uploadData?.second?.created && format(new Date(uploadData?.second?.created), 'dd/MM/yyyy')}{' '}
                                        {uploadData?.second?.created && (
                                            <span className={styles.fileSidebarLabel}>{uploadData?.second?.created && formatDateDifferenc(uploadData?.second?.created)}</span>
                                        )}
                                    </p>
                                </>
                            )}
                        </div>
                        <div className={styles.analyticsSmallCard + ' mt-4'}>
                            <p className={styles.analyticeMenuText} style={{ marginBottom: 16 }}>
                                Document Authors
                            </p>
                            {authorsArr?.map(item => (
                                <p
                                    key={item}
                                    onClick={() => {
                                        setFilters({ type: 'Author / ', value: item, label: item });
                                    }}
                                    role="button"
                                    className={styles.authorName}>
                                    {item}
                                </p>
                            ))}
                        </div>

                        <div style={{ height: 328 }} className={styles.analyticsSmallCard + ' mt-4'}>
                            <div className="d-flex align-items-center justify-content-between" style={{ marginBottom: 38 }}>
                                <p className={styles.analyticeMenuText + ' mb-0'}>Read Count</p>
                                <select
                                    value={selectedMember}
                                    onChange={e => setMember(parseInt(e.target?.value))}
                                    style={{ width: 160 }}
                                    className={'form-select ' + styles.yearsSelect}>
                                    {/* <option>Select</option> */}
                                    {projectUsersAll?.map(
                                        item =>
                                            item?.value?.full_name && (
                                                <option key={item?.value?.user_id} value={item?.value?.group_member_id}>
                                                    {item?.value?.full_name}
                                                </option>
                                            ),
                                    )}
                                </select>
                            </div>
                            <div className="d-flex">
                                <div className="text-center">
                                    <div style={{ borderBottom: '1px solid var(--Neutral-Gray-6)', marginBottom: 9 }} className="pe-2">
                                        <div className={styles.fileCountChartBody}>
                                            <div style={{ background: '#192BC2', height: '100%' }} className={styles.fileCountBar}></div>
                                        </div>
                                    </div>
                                    <span role="button" onClick={() => handleClick('Total Documents', 'total')} className={styles.fileCountLabel}>
                                        Total
                                    </span>
                                </div>
                                <div className="text-center">
                                    <div style={{ borderBottom: '1px solid var(--Neutral-Gray-6)', marginBottom: 9 }} className="pe-2">
                                        <div className={styles.fileCountChartBody}>
                                            <div
                                                style={{ height: calculatePercentageHeight(teamCount?.teamReadByFiles), background: '#40128B' }}
                                                className={styles.fileCountBar}></div>
                                        </div>
                                    </div>
                                    <span role="button" onClick={() => handleClick('Team Read Documents', 'team')} className={styles.fileCountLabel}>
                                        Team
                                    </span>
                                </div>
                                <div className="text-center">
                                    <div style={{ borderBottom: '1px solid var(--Neutral-Gray-6)', marginBottom: 9 }}>
                                        <div className={styles.fileCountChartBody}>
                                            <div
                                                style={{ height: calculatePercentageHeight(teamCount?.selfReadByFiles), background: '#F8047E' }}
                                                className={styles.fileCountBar}></div>
                                        </div>
                                    </div>

                                    <span role="button" onClick={() => handleClick(teamMemberName + ' Read Documents', selectedMember)} className={styles.fileCountLabel}>
                                        {teamMemberName && getInitials(teamMemberName)}
                                    </span>
                                </div>
                                <div>
                                    <div role="button" onClick={() => handleClick('Total Documents', 'total')} className={styles.fileCountSection}>
                                        <span>Total Files</span>
                                        <p>{teamCount?.totalFiles}</p>
                                    </div>
                                    <div role="button" onClick={() => handleClick('Team Read Documents', 'team')} className={styles.fileCountSection}>
                                        <span>Team</span>
                                        <p style={{ color: '#40128B' }}>{teamCount?.teamReadByFiles}</p>
                                    </div>
                                    <div role="button" onClick={() => handleClick(teamMemberName + ' Read Documents', selectedMember)} className={styles.fileCountSection}>
                                        <span>{teamMemberName}</span>
                                        <p style={{ color: '#F8047E' }}>{teamCount?.selfReadByFiles}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <FilesTable
                        setSelectedFile={setSelectedFile}
                        setShowFileModal={setShowFileModal}
                        analyticFiles={analyticFiles}
                        filters={filters}
                        setFilters={setFilters}
                        setShowAnalytics={setShowAnalytics}
                    />
                </div>
            )}
            <FileViewerModal openModal={showFileModal} setOpenModal={setShowFileModal} selectedFile={selectedFile} setSelectedFile={setSelectedFile} />
        </div>
    );
};

const FilesTable = ({ filters, setFilters, setSelectedFile, setShowFileModal, setShowAnalytics }) => {
    const [filteredData, setFilteredData] = React.useState([]);
    const [uploadedSort, setUploadedSort] = React.useState('asc');
    const { projectUsersAll } = useSelector(state => state?.team);
    const { data } = useSelector(state => state?.documents);
    const { searchedFiles } = useSelector(state => state?.dataroom);
    const handleSortAdded = () => {
        setUploadedSort(uploadedSort === 'asc' ? 'desc' : 'asc');
        var data = [...filteredData];
        data.sort((a, b) => {
            let da = new Date(a?.created),
                db = new Date(b?.created);
            return uploadedSort === 'asc' ? db - da : da - db;
        });
        setFilteredData([...data]);
    };
    const getUserDetail = id => {
        let addedBy = projectUsersAll?.find(item => item?.value?.group_member_id === id);
        return addedBy?.value?.full_name || 'Project Admin';
    };
    const handleSortUploadedBy = () => {
        setUploadedSort(uploadedSort === 'asc' ? 'desc' : 'asc');
        const data = [...filteredData];
        data.sort((a, b) => {
            const nameA = getUserDetail(a?.upload_by); // ignore upper and lowercase
            const nameB = getUserDetail(b?.upload_by); // ignore upper and lowercase
            if (uploadedSort === 'asc') {
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            } else {
                if (nameA > nameB) {
                    return -1;
                }
                if (nameA < nameB) {
                    return 1;
                }
                return 0;
            }
        });
        setFilteredData([...data]);
    };
    const decodeBase64 = code => {
        try {
            var decodedString = atob(code)?.trim();
            var cleanedURL = decodedString.replace(/[^a-zA-Z]+$/, '');
            return cleanedURL;
        } catch (error) {
            console.log(error);
        }
    };
    React.useEffect(() => {
        if (filters) {
            if (filters?.type === 'Author / ') {
                setFilteredData(data?.filter(item => item?.metadata_author === filters?.value));
            }
            if (filters?.type === 'File Creation / ') {
                setFilteredData(
                    data?.filter(item => {
                        if (item?.metadata_creation_date) {
                            let metaDate = new Date(item?.metadata_creation_date);
                            // filters?.value?.year === 'all'
                            if (filters?.value?.year === 'all') {
                                if (getYear(metaDate) === filters?.label) {
                                    return item;
                                }
                            } else {
                                if (getYear(metaDate) === Number(filters?.value?.year)) {
                                    if (getMonth(metaDate) == filters?.value?.index) return item;
                                }
                            }
                        }
                    }),
                );
            }
            if (filters?.type === 'Read Count / ') {
                if (filters?.value === 'total') {
                    setFilteredData(data);
                } else if (filters?.value === 'team') {
                    setFilteredData(data?.filter(item => item?.read_by?.length > 0));
                } else {
                    setFilteredData(data?.filter(item => item?.read_by?.includes(filters?.value)));
                }
            }

            if (filters?.type === 'Keyword Mapper / ') {
                setFilteredData(data?.filter(item => item?.content?.includes(filters?.value)));
            }
            if (filters?.type === 'Search Results : ') {
                setFilteredData(
                    data?.filter(item => {
                        let check = searchedFiles?.find(f => parseInt(f?.file_id) === item?.id);
                        if (check) {
                            return item;
                        }
                    }),
                );
            }
        }
    }, [filters, data]);
    return (
        <div className={styles.analyticsFilterTableBody}>
            <div>
                <div>
                    <p className={styles.filterLabel}>{filters?.type === 'Search Results : ' ? filters?.value : 'Analytics'}</p>
                    <p className={styles.filterDesc}>
                        {filters?.type}
                        {filters?.label}
                    </p>
                </div>
                {filters?.type === 'Search Results : ' ? (
                    <div
                        onClick={() => {
                            setShowAnalytics(false);
                            setFilters();
                        }}
                        className={styles.backBtnText}>
                        <Dismiss16Regular className="me-2" />
                        <span> Close</span>
                    </div>
                ) : (
                    <div onClick={() => setFilters()} className={styles.backBtnText}>
                        <ArrowTurnUpLeft20Regular className="me-2" />
                        <span> Back</span>
                    </div>
                )}
            </div>
            <table className="w-100">
                <thead>
                    <tr className={styles.tableRow + ' border-0'}>
                        <td className={styles.tableHeadItem}>Name</td>
                        <td onClick={handleSortUploadedBy} style={{ width: 150 }} role="button" className={styles.tableHeadItem}>
                            Posted by <ArrowSortUp16Regular className="ms-1" />
                        </td>
                        <td style={{ width: 109 }} onClick={handleSortAdded} role="button" className={styles.tableHeadItem}>
                            <div style={{ width: 109 }} className="d-flex">
                                Uploaded on
                                <ArrowSortUp16Regular className="ms-1" />
                            </div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {filteredData?.map(item => (
                        <tr
                            onClick={() => {
                                setSelectedFile(item);
                                setShowFileModal(true);
                            }}
                            key={item?.id}
                            className={styles.tableRow}>
                            <td className={styles.tableItem}>
                                {' '}
                                <p className="d-flex align-items-center m-0">
                                    {getExtensionIcon((item?.document || item?.document_link)?.split('.').pop(), 15, 15, item?.document_link)}
                                    <span className="ms-2">
                                        {item?.ws_folder_prefix + '.'} {item?.item_num} {item?.document_name}
                                    </span>
                                </p>
                            </td>
                            <td className={styles.tableItem}>{getUserDetail(item?.upload_by)}</td>
                            <td className={styles.tableItem}>{item?.created && format(new Date(item?.created), 'dd/MM/yyyy')}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

const CreationChart = ({ analyticFiles, setFilters }) => {
    const [selectedYear, setSelectedYear] = React.useState('all');
    const [years, setYears] = React.useState([]);
    const [series, setSeries] = React.useState(new Array(12).fill(0));

    const options = {
        chart: {
            // height: 350,
            type: 'bar',
            toolbar: {
                show: false,
            },
            events: {
                dataPointSelection: (event, chartContext, config) => {
                    setFilters({
                        type: 'File Creation / ',
                        value: {
                            year: document.getElementById('selectedYear')?.innerText,
                            index: config?.dataPointIndex,
                        },
                        label:
                            selectedYear === 'all'
                                ? years[config?.dataPointIndex]
                                : getMonthNameByIndex(config?.dataPointIndex) + ' ' + document.getElementById('selectedYear')?.innerText,
                    });
                },
            },
        },
        plotOptions: {
            bar: {
                borderRadius: 0,
                dataLabels: {
                    position: 'top', // top, center, bottom
                },
                columnWidth: '65%',
            },
        },
        colors: ['#F8047E'],
        dataLabels: {
            enabled: false,
        },
        xaxis: {
            categories: selectedYear === 'all' ? (years?.length > 0 ? years : ['--']) : ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            position: 'bottom',
            axisBorder: {
                show: true,
            },
            axisTicks: {
                show: false,
            },
            tooltip: {
                enabled: false,
            },
            labels: {
                show: true,
                style: {
                    colors: Array(12).fill('#989BAD'), // Change this color to your desired color
                    fontSize: '12px', // Adjust font size if needed
                    fontFamily: 'Inter', // Specify the font family
                    fontWeight: 400, // Specify the font weight
                },
            },
        },
        yaxis: {
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: false,
                formatter: function (val) {
                    return val + '%';
                },
            },
        },
        grid: {
            show: false, // Hide background horizontal lines
        },
        tooltip: {
            custom: function (props) {
                console.log({ props });
                return `<div style="border: 1px solid var(--Neutral-Stroke-Transparent-Rest, rgba(255, 255, 255, 0.00)); box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 4px 8px 0px rgba(0, 0, 0, 0.14); padding: 5px 12px 7px 12px;">
                    <h5 style="color: var(--Brand-Primary, #1B2149);margin:0; font-family: Inter; font-size: 12px; font-style: normal; font-weight: 700; line-height: 120%;">${
                        selectedYear === 'all' ? years[props?.dataPointIndex] : getMonthNameByIndex(props?.dataPointIndex)
                    } ${selectedYear === 'all' ? '' : document.getElementById('selectedYear')?.innerText}</h5>
                    <p style="color: var(--Brand-Primary, #1B2149);margin:0; font-family: Inter; font-size: 12px; font-style: normal; font-weight: 400; line-height: 120%;">${
                        props?.series[0]?.[props?.dataPointIndex] + ' files'
                    }</p>
                </div>
                `;
            },
        },
    };
    const getMonthNameByIndex = index => {
        if (index < 0 || index > 11) {
            throw new Error('Invalid month index');
        }
        const date = new Date(2000, index);
        const monthName = format(date, 'MMMM');
        return monthName;
    };
    const handleNavigateYear = dir => {
        if (dir === 'next') {
            if (selectedYearIndex !== years?.length - 1) {
                setSelectedYear(years[selectedYearIndex + 1]);
            }
        } else {
            if (selectedYearIndex > 0) {
                setSelectedYear(years[selectedYearIndex - 1]);
            }
        }
    };
    React.useEffect(() => {
        let fileYears = [];
        analyticFiles?.forEach(item => {
            if (item?.metadata_creation_date) {
                fileYears.push(getYear(new Date(item?.metadata_creation_date)));
            }
        });
        fileYears = [...new Set(fileYears)].sort();
        setYears(fileYears);
        // setSelectedYear(fileYears?.[0]);
    }, [analyticFiles]);
    React.useEffect(() => {
        if (selectedYear) {
            if (selectedYear === 'all') {
                let seriesData = new Array(years?.length < 12 ? 12 : years?.length).fill(0);
                analyticFiles.forEach(item => {
                    if (item?.metadata_creation_date) {
                        const fileYear = getYear(new Date(item?.metadata_creation_date));
                        console.groupEnd();
                        if (getYear(new Date(item?.metadata_creation_date))) {
                            let yearIndex = years?.findIndex(y => fileYear === y);
                            if (yearIndex >= 0) {
                                seriesData[yearIndex] = seriesData[yearIndex] + 1;
                            }
                        }
                    }
                });
                console.log({ seriesData, analyticFiles });

                setSeries(seriesData);
            } else {
                let seriesData = new Array(12).fill(0);
                analyticFiles.forEach(item => {
                    if (item?.metadata_creation_date) {
                        if (getYear(new Date(item?.metadata_creation_date)) === selectedYear) {
                            let monthIndex = getMonth(new Date(item?.metadata_creation_date));
                            seriesData[monthIndex] = seriesData[monthIndex] + 1;
                        }
                    }
                });

                setSeries(seriesData);
            }
        }
    }, [analyticFiles, selectedYear, years]);
    const selectedYearIndex = years.findIndex(y => y === selectedYear);
    return (
        <div>
            {/* <div>
                <p className={styles.yearLabel}>Year</p>
                {years?.map(item => (
                    <p
                        key={item}
                        className={styles.yearText}
                        onClick={() => setSelectedYear(item)}
                        id={selectedYear === item && 'selectedYear'}
                        style={{ color: selectedYear === item && 'var(--Brand-Primary)', fontWeight: selectedYear === item && 700 }}>
                        {item}
                    </p>
                ))}
            </div> */}
            <div className="d-flex  justify-content-between">
                <p className={styles.analyticeMenuText} style={{ marginBottom: 15 }}>
                    File Creation
                </p>
                <span className="d-none" id={'selectedYear'}>
                    {selectedYear}
                </span>
                <div className="d-flex align-items-center">
                    <label className={styles.selectYearLabel}>Select Year</label>
                    <select
                        value={selectedYear}
                        onChange={e => setSelectedYear(e.target.value === 'all' ? 'all' : Number(e.target.value))}
                        className={'form-select ' + styles.yearsSelect}>
                        <option value={'all'}>All years</option>
                        {years?.map(year => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="d-flex justify-content-between">
                <div className="position-relative">
                    {' '}
                    <ChevronCircleLeft20Filled
                        onClick={() => handleNavigateYear('prev')}
                        color={selectedYearIndex === 0 || selectedYear === 'all' ? 'var(--Neutral-Gray-6)' : 'var(--Brand-Secondary)'}
                        role="button"
                        className={styles.arrowIconsChart}
                    />
                </div>
                <div id="chart" className="w-100 position-relative" style={{ top: 4, left: -5 }}>
                    <ReactApexChart options={options} series={[{ data: series }]} type="bar" height={270} />
                </div>
                <div className="position-relative">
                    <ChevronCircleRight20Filled
                        color={selectedYearIndex === years?.length - 1 || selectedYear === 'all' ? 'var(--Neutral-Gray-6)' : 'var(--Brand-Secondary)'}
                        role="button"
                        onClick={() => handleNavigateYear('next')}
                        style={{ left: -11 }}
                        className={styles.arrowIconsChart}
                    />
                </div>
            </div>
            <div id="html-dist"></div>
        </div>
    );
};
const KeywordUsage = ({ analyticFiles, setFilters }) => {
    const [preDefinedWordsCount, setPreDefinedWordsCount] = React.useState([]);
    React.useEffect(() => {
        if (analyticFiles) {
            var setter = [
                { id: 'Intellectual Property', value: 0 },
                { id: 'Payment Terms', value: 0 },
                { id: 'Scope', value: 0 },
                { id: 'Disclaimers', value: 0 },
                { id: 'Innovation', value: 0 },
                { id: 'Termination', value: 0 },
                { id: 'Liability', value: 0 },
                { id: 'Master Service Agreement', value: 0 },
                { id: 'Novation', value: 0 },
                // Adding the new keywords from the array
                { id: 'Term and Termination', value: 0 },
                { id: 'Renewal', value: 0 },
                { id: 'Assignment and Subcontracting', value: 0 },
                { id: 'Confidentiality/Data Protection', value: 0 },
                { id: 'Service Levels/Quality', value: 0 },
                { id: 'Pricing and Payment Terms', value: 0 },
                { id: 'Warranties', value: 0 },
                { id: 'Dispute Resolution', value: 0 },
                { id: 'Force Majeure', value: 0 },
                { id: 'Jurisdiction/ Governing Law', value: 0 },
                { id: 'Non-compete/ Exclusivity', value: 0 },
                { id: 'Survival', value: 0 },
                { id: 'Change of Control', value: 0 },
            ];
            const keywordMapping = {
                'Intellectual Property': 'Intellectual Property',
                'Payment Terms': 'Payment Terms',
                Scope: 'Scope',
                Disclaimers: 'Disclaimers',
                Innovation: 'Innovation',
                'Master Service Agreement': 'Master Service Agreement',
                // Add more keywords here
                'Term and Termination': 'Term and Termination',
                Renewal: 'Renewal',
                'Assignment and Subcontracting': 'Assignment and Subcontracting',
                'Confidentiality/Data Protection': 'Confidentiality/Data Protection',
                'Service Levels/Quality': 'Service Levels/Quality',
                'Pricing and Payment Terms': 'Pricing and Payment Terms',
                Warranties: 'Warranties',
                'Dispute Resolution': 'Dispute Resolution',
                'Force Majeure': 'Force Majeure',
                'Jurisdiction/ Governing Law': 'Jurisdiction/ Governing Law',
                'Non-compete/ Exclusivity': 'Non-compete/ Exclusivity',
                Survival: 'Survival',
                'Change of Control': 'Change of Control',
            };
            const minRadius = 20;
            const maxRadius = 90;
            let colorArr = ['#F8047E', '#DA0C81', '#40128B', '#192BC2', '#610C9F', '#B4007D'];
            const getRandomColor = () => {
                const randomIndex = Math.floor(Math.random() * colorArr.length);
                return colorArr[randomIndex];
            };
            const mapCountToRadius = (count, label) => {
                // Calculate the radius based on count and label length
                const labelLengthFactor = label ? Math.max(1, label.length / 10) : 1;
                const radius = minRadius + (count / Math.max(...updatedSetter.map(item => item.count))) * (maxRadius - minRadius) * labelLengthFactor;
                // Cap the radius at a maximum value of 80px
                return Math.min(radius, 95);
            };

            // Inside the useEffect hook

            const updatedSetter = setter.map(item => ({ ...item, value: 0 }));
            analyticFiles.forEach(item => {
                for (const keyword in keywordMapping) {
                    if (item?.content?.includes(keyword)) {
                        const index = updatedSetter.findIndex(i => i.id === keywordMapping[keyword]);
                        updatedSetter[index].value += 1;
                    }
                }
            });
            updatedSetter.forEach(item => {
                // item.radius = mapCountToRadius(item.count, item.label);
                item.color = getRandomColor();
            });
            setPreDefinedWordsCount(updatedSetter);
        }
    }, [analyticFiles]);
    return (
        <div>
            <p className={styles.analyticeMenuText} style={{ marginBottom: 40 }}>
                M&A Keywords Usage
            </p>
            {/* <BubbleChart data={preDefinedWordsCount} width={document?.getElementById('circlesMainBody')?.offsetWidth - 32} height={413} setFilters={setFilters} /> */}
            <D3Chart data={preDefinedWordsCount} width={document?.getElementById('circlesMainBody')?.offsetWidth - 32} height={413} setFilters={setFilters} />
        </div>
    );
};

const D3Chart = ({ width, height, data, setFilters }) => {
    const svgRef = useRef();
    const [tooltipData, setTooltipData] = React.useState(null); // State to store data for the tooltip

    const handleMouseOver = (e, d) => {
        setTooltipData(d); // Set the tooltip data when mouse over
    };

    const handleMouseOut = () => {
        setTooltipData(null); // Clear the tooltip data when mouse out
    };
    const handleClick = (e, d) => {
        setFilters({ type: 'Keyword Mapper / ', value: d?.data?.id, label: d?.data?.id });
    };
    useEffect(() => {
        if (!data || !width || !height) return;
        // Clear SVG contents
        d3.select(svgRef.current).selectAll('*').remove();
        const margin = 1;
        const format = d3.format(',d');
        const pack = d3
            .pack()
            .size([width - margin * 2, height - margin * 2])
            .padding(3);
        // Filter out data points with value > 0
        const filteredData = data.filter(d => d.value > 0);
        if (filteredData.length === 0) return;
        const root = pack(d3.hierarchy({ children: filteredData }).sum(d => d.value));
        const svg = d3
            .select(svgRef.current)
            .attr('width', width)
            .attr('height', height)
            .attr('viewBox', [-margin, -margin, width, height])
            .attr('style', 'max-width: 100%; height: auto; font: 12px sans-serif;')
            .attr('text-anchor', 'middle');
        const node = svg
            .selectAll('g.node')
            .data(root.leaves())
            .enter()
            .append('g')
            .attr('class', 'node')
            .attr('transform', d => `translate(${d.x},${d.y})`);
        node.append('title').text(d => `${d.data.id}\n${format(d.value)}`);
        node.append('circle')
            .attr('fill-opacity', 1)
            .attr('fill', d => d?.data?.color)
            .attr('r', d => d.r)
            .on('click', (e, d) => handleClick(e, d))
            .on('mouseover', (e, d) => handleMouseOver(e, d)) // Add mouseover event listener
            .on('mouseout', handleMouseOut); // Add mouseout event listener
        const text = node.append('text').attr('clip-path', d => `circle(${d.r})`);
        text.selectAll()
            .data(d => {
                return d.data?.id?.split(' ');
            })
            .enter()
            .append('tspan')
            .attr('x', 0)
            .attr('y', (d, i, nodes) => `${i - nodes.length / 2 + 0.75}em`)
            .attr('fill', 'white') // Set text color to white
            .style('pointer-events', 'none')
            .text(d => d);
    }, [data, width, height]);
    return (
        <div style={{ position: 'relative' }}>
            <svg ref={svgRef}></svg>
            {tooltipData && (
                <div
                    style={{
                        position: 'absolute',
                        top: tooltipData.y + 10,
                        left: tooltipData.x + 10,
                        backgroundColor: 'white',
                        padding: '5px 12px',
                        borderRadius: '4px',
                    }}
                    className={styles.bubbleTooltip}>
                    <h5> {tooltipData?.data?.id}</h5>
                    <p> {tooltipData?.data?.value} files</p>
                </div>
            )}
        </div>
    );
};

export default Analytics;
