import React, { useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BasicHeader from '../../CommonComponents/BasicHeader';
import DocDragDrop from '../../CommonComponents/DocDragDrop';
import styles from '../css/index.module.css';
import Pageheader from '../../../utils/headerHelment';
import { AiFillCloseCircle, AiOutlineLoading3Quarters } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { format, addYears } from 'date-fns';
import { updateProject, AddNda } from '../../../redux/projectDataSlice';
import { isValidNdaExtensionToUpload } from '../../../utils/commonUtils';
import DatePicker from '../../../utils/DatePicker';
function UploadNda({ onlyNda }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation(); // Get the location object using the useLocation hook

    const { selectedProject, ndaButtonLoading } = useSelector(state => state.projectData);
    const { user } = useSelector(state => state.user);
    const [validityDate, setValidityDate] = useState(addYears(new Date(), 3));
    const [selectedFile, setSelectedFile] = React.useState(null);
    const handleDate = date => {
        setValidityDate(date);
    };
    const handleSubmit = async e => {
        e.preventDefault();
        const FiletoUpload = new FormData();
        selectedFile && FiletoUpload.append('document', selectedFile);
        validityDate !== '' && FiletoUpload.append('expiry', format(validityDate, 'yyyy-MM-dd'));
        const res = await dispatch(AddNda(FiletoUpload));
        console.log(res);
        if (res?.type !== 'POST_NDA_DOC/rejected') {
            dispatch(
                updateProject({
                    ...selectedProject,
                    vdr: {
                        ...selectedProject?.vdr,
                        nda: res?.payload?.data,
                    },
                }),
            );
            handleNavigateNext();
        }
    };
    const handleNavigateNext = () => {
        const isProjectAdmin = selectedProject?.vdr?.vdr_admin === user?.id;
        console.log(isProjectAdmin);
        navigate(user?.has_diligence_tools ? '/overview' : '/dataroom');
        // if (isProjectAdmin) {
        // }
        //  else navigate('/tc');
    };
    const handleChange = e => {
        const newFiles = e.target.files;
        console.log(newFiles);
        const validFiles = Array.from(newFiles).filter(file => {
            const extension = file.name.split('.').pop().toLowerCase();
            return isValidNdaExtensionToUpload(extension);
        });
        validFiles?.length > 0 && setSelectedFile(validFiles[0]);
    };
    const handleClearFile = () => {
        setSelectedFile(null);
    };
    return (
        <>
            <BasicHeader />
            <div className="bg-light">
                <Pageheader title="LENS - Upload NDA" />
                <div className="container-fluid">
                    <div className={` ${styles.viewHeight}`}>
                        <div className={`${styles.content} `}>
                            <div className="row">
                                <div className="col-sm-12 col-md-4">
                                    <div className="d-flex justify-content-between align-items-center mb-5">
                                        <h2 className={styles.headingText + ' mb-1'}>{selectedProject?.project_name} NDA</h2>
                                        <button onClick={handleNavigateNext} className={styles.borderdButton}>
                                            Skip
                                        </button>
                                    </div>
                                    <form className="mb-5">
                                        <div className="row">
                                            {selectedFile ? (
                                                <div className="d-flex justify-content-between align-items-center mb-4 mt-4">
                                                    <p>{selectedFile?.name}</p>
                                                    <AiFillCloseCircle onClick={handleClearFile} size={20} color="#829ab1" />
                                                </div>
                                            ) : (
                                                <div className="row pe-0 mb-3">
                                                    <div className="col-12 pe-0">
                                                        <label htmlFor className="form-label mb-1 smallFont">
                                                            Upload NDA
                                                        </label>
                                                        <DocDragDrop
                                                            customUploadFunction={true}
                                                            multiple={true}
                                                            onFileChange={handleChange}
                                                            placeholder="Upload NDA"
                                                            showExtension={true}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="row">
                                            <div className=" col-sm-12 col-md-6">
                                                <div className="mb-4 customDp">
                                                    <label htmlFor className="form-label mb-1 smallFont">
                                                        Valid until
                                                    </label>
                                                    {/* <CustomDatepicker dateValue={validityDate} setDatedateValue={handleDate} /> */}
                                                    <DatePicker value={validityDate} onChange={e => setValidityDate(e)} />
                                                </div>{' '}
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-between align-items-center ">
                                            <button onClick={handleSubmit} type="submit" className="btn btn-primary smallButton ">
                                                Save {ndaButtonLoading && <AiOutlineLoading3Quarters className="rotateLoader" />}
                                            </button>
                                            <button className={styles.borderdButton} onClick={() => navigate(location?.state?.newNda ? '/AddProjectDetails' : '/dataroom')}>
                                                Back
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UploadNda;
