import React, { useState } from 'react';
import styles from './css/index.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { ChevronDown16Regular, Info12Regular, SpinnerIos16Regular } from '@fluentui/react-icons';
import { Input, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, Select, Textarea, Tooltip } from '@fluentui/react-components';
import { Link, Element, scrollSpy } from 'react-scroll';
import { GetSoftwares, PatchSoftware, PostSoftware } from '../../redux/softwareSlice';
import format from 'date-fns/format';
const SoftwareInventoryForm = ({ setIsAddView, selectedSoftware, setSelectedSoftware }) => {
    const dispatch = useDispatch();
    const { selectedProject } = useSelector(state => state.projectData);
    const { softwareFormLoading } = useSelector(state => state.software);

    const links = ['Software details', 'License details', 'Support & Terms', 'Notes / Comments'];
    const [formData, setFormData] = React.useState({
        // vendor: '',
        // software_name: '',
        // num_of_licenses: '',
        // license_cost: '',
        yearly_cost: 0,

        // contract_start: '',
        // contract_end: '',
        // novation_terms: '',
        // website: '',
        // tandc_url: '',
        // privacy_policy_url: '',
        // license_type: '',
        // support_level: '',
        // software_last_updated: '',
        // compliance: '',
        // internal_contact: '',
        // usage_metrics: '',
        // currency: '',
        // notes: '',
    });

    const handleChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleSubmit = async e => {
        e.preventDefault();
        let body = { ...formData, project: selectedProject?.id };
        let res;
        if (formData?.num_of_licenses && formData?.license_cost) {
            body.yearly_cost = Number(formData?.num_of_licenses) * Number(formData?.license_cost);
        }
        if (selectedSoftware) {
            res = await dispatch(PatchSoftware(body));
        } else res = await dispatch(PostSoftware(body));
        if (res?.type?.includes('fulfilled')) {
            setIsAddView(false);
            setFormData({});
            setSelectedSoftware();
        }
    };
    React.useEffect(() => {
        scrollSpy.update();
    }, []);
    React.useEffect(() => {
        if (selectedSoftware?.id) {
            setFormData({ ...selectedSoftware });
        }
    }, [selectedSoftware]);
    return (
        <div className={styles.viewBody}>
            <form onSubmit={handleSubmit}>
                <div style={{ float: 'right' }} className="d-flex align-items-center ">
                    <p
                        onClick={() => {
                            setIsAddView(false);
                        }}
                        className={styles.linkText + ' mb-0 me-5'}>
                        Cancel
                    </p>
                    <button disabled={softwareFormLoading} type="submit" className={styles.submitButton}>
                        Save {softwareFormLoading && <SpinnerIos16Regular className="rotateLoader ms-1" />}
                    </button>
                </div>
                <div className="row m-0 p-0">
                    <div className="col-sm-12 col-md-2 m-0 p-0">
                        <div className={styles.navLinkBody}>
                            {/* {links.map(item => (
                            <div key={item} onClick={() => setSelectedLink(item)} className={(item === selectedLink && styles.selectednavItem) + ' ' + styles.navLinkItem}>
                                {item}
                            </div>
                        ))} */}
                            {links.map(item => (
                                <Link
                                    to={item.replace(/[&/\s]/g, '').toLocaleLowerCase()}
                                    // activeClass={styles.selectednavItem}
                                    spy={true}
                                    smooth={true}
                                    key={item}
                                    offset={-24}
                                    duration={300}
                                    // onSetActive={handleSetActive}
                                    containerId="containerElement">
                                    <div className={styles.navLinkItem}>{item}</div>
                                    {/* (item === selectedLink && styles.selectednavItem) + ' ' +  */}
                                </Link>
                            ))}
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-1 m-0 p-0"></div>
                    <div className="col-sm-12 col-md-4 m-0 p-0">
                        <div id="containerElement" className={styles.formBodies}>
                            <Element name="softwaredetails">
                                <SoftwareDetails selectedSoftware={selectedSoftware} handleChange={handleChange} formData={formData} />
                            </Element>
                            <Element name="licensedetails">
                                <LicenseDetails handleChange={handleChange} formData={formData} setFormData={setFormData} />
                            </Element>
                            <Element name="supportterms">
                                <SupportTerms handleChange={handleChange} formData={formData} />
                            </Element>
                            <Element name="notescomments">
                                <NotesComments handleChange={handleChange} formData={formData} />
                            </Element>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};
const SoftwareDetails = ({ formData, handleChange, selectedSoftware }) => {
    function isURL(url) {
        // Regular expression for URL validation
        const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
        return urlRegex.test(url);
    }
    return (
        <div className={styles.componentCard}>
            <h5 className={styles.componentTitle + ' mt-0'}>{selectedSoftware ? 'Edit' : 'Add New'} Software Record</h5>
            <div>
                <div className="mb-4">
                    <div className="d-flex align-items-center justify-content-between">
                        <p className={styles.fieldLabel}>
                            Software Name <span className={styles.required}>*</span>
                        </p>
                        <Tooltip positioning="after-top" withArrow content="The name of the software product.">
                            <Info12Regular color="var(--Neutral-Gray-6)" role="button" className="ms-1" />
                        </Tooltip>
                    </div>
                    <input className={styles.inputField} required value={formData?.software_name} onChange={handleChange} name="software_name" />
                </div>
                <div className="mb-4">
                    <div className="d-flex align-items-center justify-content-between">
                        <p className={styles.fieldLabel}>
                            Vendor <span className={styles.required}>*</span>
                        </p>
                        <Tooltip positioning="after-top" withArrow content="Name of the software provider or vendor.">
                            <Info12Regular color="var(--Neutral-Gray-6)" role="button" className="ms-1" />
                        </Tooltip>
                    </div>
                    <input className={styles.inputField} required value={formData?.vendor} onChange={handleChange} name="vendor" />
                </div>
                <div className="mb-4">
                    <div className="d-flex align-items-center justify-content-between">
                        <p className={styles.fieldLabel}>Website</p>
                        <Tooltip positioning="after-top" withArrow content="Official website of the software or vendor.">
                            <Info12Regular color="var(--Neutral-Gray-6)" role="button" className="ms-1" />
                        </Tooltip>
                    </div>
                    <input className={styles.inputField} value={formData?.website} onChange={handleChange} name="website" />
                    <div style={{ height: 12 }}>
                        {formData?.website && !isURL(formData?.website) && <span className={styles.errorMessage}>Please enter a URL in https:// or http:// format</span>}
                    </div>
                </div>
            </div>
        </div>
    );
};
const LicenseDetails = ({ formData, handleChange, setFormData }) => {
    return (
        <div className={styles.componentCard}>
            <h5 className={styles.componentSmallTitle}>License details</h5>
            <div>
                <div className="row m-0 p-0">
                    <div className="col-sm-12 col-md-6 ps-0">
                        <div className="mb-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <p className={styles.fieldLabel}>Contract Start Date</p>
                                <Tooltip positioning="after-top" withArrow content="Date when the software license agreement starts.">
                                    <Info12Regular color="var(--Neutral-Gray-6)" role="button" className="ms-1" />
                                </Tooltip>
                            </div>
                            <input className={styles.inputField} value={formData?.contract_start} onChange={handleChange} type="date" name="contract_start" />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="mb-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <p className={styles.fieldLabel}>Contract End Date</p>
                                <Tooltip positioning="after-top" withArrow content="Date when the software license agreement ends.">
                                    <Info12Regular color="var(--Neutral-Gray-6)" role="button" className="ms-1" />
                                </Tooltip>
                            </div>
                            <input className={styles.inputField} value={formData?.contract_end} onChange={handleChange} type="date" name="contract_end" />
                        </div>
                    </div>
                </div>
                <div className="row m-0 p-0">
                    <div className="col-sm-12 col-md-6 ps-0">
                        <div className="mb-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <p className={styles.fieldLabel}>License Type</p>
                                <Tooltip positioning="after-top" withArrow content="Type of license (perpetual, subscription, open-source, etc.).">
                                    <Info12Regular color="var(--Neutral-Gray-6)" role="button" className="ms-1" />
                                </Tooltip>
                            </div>
                            <Select className={styles.selectField} value={formData?.license_type} onChange={handleChange} name="license_type" placeholder="">
                                <option>Select</option>
                                <option value="Proprietary">Proprietary</option>
                                <option value="Open Source">Open Source</option>
                                <option value="Freeware">Freeware</option>
                                <option value="Shareware">Shareware</option>
                                <option value="Commercial">Commercial</option>
                                <option value="Subscription">Subscription</option>
                                <option value="Perpetual">Perpetual</option>
                                <option value="Educational">Educational</option>
                            </Select>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="mb-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <p className={styles.fieldLabel}>Number of Licenses</p>
                                <Tooltip positioning="after-top" withArrow content="Total number of licenses purchased or available for the software.">
                                    <Info12Regular color="var(--Neutral-Gray-6)" role="button" className="ms-1" />
                                </Tooltip>
                            </div>
                            <input className={styles.inputField} value={formData?.num_of_licenses} onChange={handleChange} type="number" name="num_of_licenses" />
                        </div>
                    </div>
                </div>
                <div className="row m-0 p-0">
                    <div className="col-sm-12 col-md-6 ps-0">
                        <div className="mb-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <p className={styles.fieldLabel}>Cost per License</p>
                                <Tooltip positioning="after-top" withArrow content="Individual cost of each license.">
                                    <Info12Regular color="var(--Neutral-Gray-6)" role="button" className="ms-1" />
                                </Tooltip>
                            </div>
                            <div className="d-flex">
                                <Menu>
                                    <MenuTrigger disableButtonEnhancement>
                                        <div className={styles.currencyDiv}>
                                            {['£ GBP', '$ USD', '€ EUR'].find(item => item?.includes(formData?.currency))?.split(' ')[1]} <ChevronDown16Regular className="ms-1" />
                                        </div>
                                    </MenuTrigger>
                                    <MenuPopover>
                                        <MenuList>
                                            <MenuItem onClick={() => setFormData({ ...formData, currency: '£' })}>£ GBP</MenuItem>
                                            <MenuItem onClick={() => setFormData({ ...formData, currency: '$' })}>$ USD</MenuItem>
                                            <MenuItem onClick={() => setFormData({ ...formData, currency: '€' })}>€ EUR</MenuItem>
                                        </MenuList>
                                    </MenuPopover>
                                </Menu>
                                <div>
                                    <input className={styles.inputField} value={formData?.license_cost} onChange={handleChange} name="license_cost" type="number" placeholder="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row m-0 p-0">
                    <div className="col-sm-12 col-md-6 ps-0">
                        <div className="mb-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <p className={styles.fieldLabel}>Last Updated</p>
                                <Tooltip positioning="after-top" withArrow content="Date when the software was last updated.">
                                    <Info12Regular color="var(--Neutral-Gray-6)" role="button" className="ms-1" />
                                </Tooltip>
                            </div>
                            <input className={styles.inputField} value={formData?.software_last_updated} onChange={handleChange} type="date" name="software_last_updated" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const SupportTerms = ({ formData, handleChange }) => {
    function isURL(url) {
        // Regular expression for URL validation
        const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
        return urlRegex.test(url);
    }
    return (
        <div className={styles.componentCard}>
            <h5 className={styles.componentSmallTitle}>Terms and conditions</h5>
            <div>
                <div className="mb-4">
                    <div className="d-flex align-items-center justify-content-between">
                        <p className={styles.fieldLabel}>Terms & Conditions URL</p>
                        <Tooltip positioning="after-top" withArrow content="Direct links to the Terms and Conditions of the software.">
                            <Info12Regular color="var(--Neutral-Gray-6)" role="button" className="ms-1" />
                        </Tooltip>
                    </div>
                    <input className={styles.inputField} value={formData?.tandc_url} onChange={handleChange} type="url" name="tandc_url" />
                    <div style={{ height: 12 }}>
                        {formData?.tandc_url && !isURL(formData?.tandc_url) && <span className={styles.errorMessage}>Please enter a URL in https:// or http:// format</span>}
                    </div>
                </div>
                <div className="mb-4">
                    <div className="d-flex align-items-center justify-content-between">
                        <p className={styles.fieldLabel}>Data Privacy URL</p>
                        <Tooltip positioning="after-top" withArrow content="Link to the software’s data privacy policy.">
                            <Info12Regular color="var(--Neutral-Gray-6)" role="button" className="ms-1" />
                        </Tooltip>
                    </div>
                    <input className={styles.inputField} value={formData?.privacy_policy_url} type="url" onChange={handleChange} name="privacy_policy_url" />
                    <div style={{ height: 12 }}>
                        {formData?.privacy_policy_url && !isURL(formData?.privacy_policy_url) && (
                            <span className={styles.errorMessage}>Please enter a URL in https:// or http:// format</span>
                        )}
                    </div>
                </div>
                <div className="mb-4">
                    <div className="d-flex align-items-center justify-content-between">
                        <p className={styles.fieldLabel}>Novation terms</p>
                        <Tooltip positioning="after-top" withArrow content="Specific terms related to the transfer of the contract or license.">
                            <Info12Regular color="var(--Neutral-Gray-6)" role="button" className="ms-1" />
                        </Tooltip>
                    </div>
                    <textarea className={styles.textareaField} rows={4} value={formData?.novation_terms} onChange={handleChange} name="novation_terms" />
                </div>
                <div className="mb-4">
                    <div className="d-flex align-items-center justify-content-between">
                        <p className={styles.fieldLabel}>Support level</p>
                        <Tooltip positioning="after-top" withArrow content="Details of the support provided by the vendor.">
                            <Info12Regular color="var(--Neutral-Gray-6)" role="button" className="ms-1" />
                        </Tooltip>
                    </div>
                    <textarea className={styles.textareaField} rows={4} value={formData?.support_level} onChange={handleChange} name="support_level" />
                </div>
                <div className="mb-4">
                    <div className="d-flex align-items-center justify-content-between">
                        <p className={styles.fieldLabel}>Compliance status</p>
                        <Tooltip positioning="after-top" withArrow content="Indicates compliance with relevant standards and regulations.">
                            <Info12Regular color="var(--Neutral-Gray-6)" role="button" className="ms-1" />
                        </Tooltip>
                    </div>
                    <textarea className={styles.textareaField} rows={4} value={formData?.compliance} onChange={handleChange} name="compliance" />
                </div>
                <div className="mb-4">
                    <div className="d-flex align-items-center justify-content-between">
                        <p className={styles.fieldLabel}>Internal contact</p>
                        <Tooltip positioning="after-top" withArrow content="Person or department managing this software.">
                            <Info12Regular color="var(--Neutral-Gray-6)" role="button" className="ms-1" />
                        </Tooltip>
                    </div>
                    <textarea className={styles.textareaField} rows={4} value={formData?.internal_contact} onChange={handleChange} name="internal_contact" />
                </div>
                <div className="mb-4">
                    <div className="d-flex align-items-center justify-content-between">
                        <p className={styles.fieldLabel}>Usage metrics</p>
                        <Tooltip positioning="after-top" withArrow content="Data on the frequency of software usage.">
                            <Info12Regular color="var(--Neutral-Gray-6)" role="button" className="ms-1" />
                        </Tooltip>
                    </div>
                    <textarea className={styles.textareaField} rows={4} value={formData?.usage_metrics} onChange={handleChange} name="usage_metrics" />
                </div>
            </div>
        </div>
    );
};
const NotesComments = ({ formData, handleChange }) => {
    return (
        <div className={styles.componentCard} style={{ height: '90vh' }}>
            <h5 className={styles.componentSmallTitle}>Notes / Comments</h5>
            <div>
                <div className="mb-4">
                    <div className="d-flex align-items-center justify-content-between">
                        <p className={styles.fieldLabel}>Notes / Comments</p>
                        <Tooltip positioning="after-top" withArrow content="Additional information, observations, or specific requirements.">
                            <Info12Regular color="var(--Neutral-Gray-6)" role="button" className="ms-1" />
                        </Tooltip>
                    </div>
                    <textarea className={styles.textareaField} rows={3} value={formData?.notes} onChange={handleChange} name="notes" />
                </div>
            </div>
        </div>
    );
};
export default SoftwareInventoryForm;
