import React from 'react';
import styles from './css/index.module.css';
import { Delete20Regular } from '@fluentui/react-icons';
import { validExtensions } from '../../utils/misc';
import { AddNda } from '../../redux/projectDataSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import format from 'date-fns/format';
const UploadNda = ({ setSelectedView, createdProjectData, setCreatedProjectData }) => {
    const { ndaButtonLoading } = useSelector(state => state.projectData);
    const [uploadedFile, setUploadedFile] = React.useState();
    const fileInputRef = React.useRef();
    const dispatch = useDispatch();
    const handleDivClick = () => {
        fileInputRef.current.click();
    };
    const handleFileChange = e => {
        const selectedFiles = Array.from(e.target.files);
        console.log(selectedFiles);
        if (selectedFiles?.length > 0) setUploadedFile(selectedFiles[0]);
    };
    const handleSubmit = async e => {
        e.preventDefault();
        const FiletoUpload = new FormData();
        FiletoUpload.append('document', uploadedFile);
        FiletoUpload.append('expiry', format(new Date(), 'yyyy-MM-dd'));
        const res = await dispatch(AddNda(FiletoUpload));
        console.log(res);
        if (res?.type !== 'POST_NDA_DOC/rejected') {
            setSelectedView('organisationDetail');
            setCreatedProjectData({
                ...createdProjectData,
                vdr: {
                    ...createdProjectData?.vdr,
                    nda: res?.payload?.data,
                },
            });
        }
    };
    return (
        <div className={styles.componentCard}>
            <h5 className={styles.componentTitle}>Upload Project NDA</h5>
            {uploadedFile ? (
                <div className={styles.uploadItemsList}>
                    <div className={styles.fileListItem}>
                        <span>{uploadedFile?.name}</span>
                        <Delete20Regular role="button" onClick={() => setUploadedFile()} />
                    </div>
                </div>
            ) : (
                <div id="uploadDiv" className={styles.uploadBox} onClick={handleDivClick}>
                    <span>
                        Drag & drop NDA file or
                        <br />
                        click to upload
                    </span>
                    <input multiple type="file" accept={validExtensions.join(',')} ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
                </div>
            )}
            <div className="d-flex align-items-center justify-content-between">
                <button disabled={ndaButtonLoading || !uploadedFile} onClick={handleSubmit} className={styles.submitButton}>
                    Save {ndaButtonLoading && <AiOutlineLoading3Quarters className="rotateLoader" />}
                </button>
                <span onClick={() => setSelectedView('organisationDetail')} className={styles.linkBtn}>
                    Skip
                </span>
            </div>
        </div>
    );
};

export default UploadNda;
