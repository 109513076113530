import React from 'react';
import SecurityIndex from '../components/SecurityIndex';
import Layout from '../components/Layout';
import Pageheader from '../utils/headerHelment';

const SecurityIndexPage = () => {
    return (
        <>
            <Pageheader title="LENS - Security Index" />
            <Layout>
                <SecurityIndex />
            </Layout>
        </>
    );
};

export default SecurityIndexPage;
