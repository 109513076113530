import { Avatar, Text, Input, Menu, MenuTrigger, MenuList, MenuItem, MenuPopover } from '@fluentui/react-components';
import {
    GridDots20Regular,
    Settings20Regular,
    Search20Regular,
    PeopleTeam20Regular,
    ReadingModeMobile20Regular,
    AddCircle20Filled,
    ChevronDown12Regular,
} from '@fluentui/react-icons';
import styles from './index.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { GetProjectData, resetProjectData, selectProject, setProjectImg } from '../../../redux/projectDataSlice';
import { useLocation, useNavigate } from 'react-router';
import Cookies from 'js-cookie';
import { resetDocumentData, setDocs } from '../../../redux/documentsSlice';
import { logout } from '../../../redux/usersSlice';
import logo from '../../../assets/longLogoBright.png';
import { resetObservationData } from '../../../redux/meetingSlice';
import { resetDataroomData } from '../../../redux/dataroomSlice';
import { resetNdaData } from '../../../redux/ndaSlice';
import { resetQestionBankData } from '../../../redux/questionBankSlice';
import { resetTaskData } from '../../../redux/taskSlice';
import { resetTeamData } from '../../../redux/teamSlice';
import React from 'react';
import { NotepadSidebar } from '../HeaderSidebars';
import { ObservationFormSidebar } from '../../Observations';
import { PostInformationRequestSidebar } from '../../IRL';
import { TaskFormSidebar } from '../../TasksInterface';
const Header = ({ hideProjectItems }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { selectedProject } = useSelector(state => state?.projectData);
    const { user } = useSelector(state => state?.user);
    const isProjectAdmin = selectedProject?.vdr?.vdr_admin === user?.id;

    const [notepadModal, setNotepadModal] = React.useState(false);
    const [questionModal, setQuestionModal] = React.useState(false);
    const [obsModal, setObsModal] = React.useState(false);
    const [taskModal, setTaskModal] = React.useState(false);
    const handleNavigateToProject = () => {
        dispatch(selectProject({ id: null }));
        dispatch(GetProjectData());
        navigate('/project');
        dispatch(resetObservationData());
        dispatch(resetDataroomData());
        dispatch(resetDocumentData());
        dispatch(resetNdaData());
        dispatch(resetProjectData());
        dispatch(resetQestionBankData());
        dispatch(resetTaskData());
        dispatch(resetTeamData());
    };
    const handleLogout = () => {
        localStorage.removeItem('persist:root');
        Cookies.set('refresh', '');
        dispatch(setProjectImg(''));
        dispatch(setDocs([]));
        dispatch(logout());
        navigate('/');
    };
    return (
        <>
            <div className={styles.header}>
                <div className={styles.leftContent}>
                    {!hideProjectItems && <GridDots20Regular onClick={handleNavigateToProject} role="button" color="white" />}

                    <img onClick={handleNavigateToProject} role="button" src={logo} alt="lens-logo" className={styles.lensLogo} />

                    {selectedProject?.project_name && (
                        <div className={styles.inputNameDiv}>
                            {' '}
                            <Text as="p" font="Inter" className={styles.projectName}>
                                {selectedProject?.project_name}
                            </Text>
                            {/* {!hideProjectItems && (
                                <Input
                                    style={{ borderRadius: 16 }}
                                    appearance="filled-lighter"
                                    contentBefore={<Search20Regular aria-label="Enter by voice" />}
                                    className={styles.searchInput}
                                />
                            )} */}
                        </div>
                    )}
                </div>
                <div>
                    {!hideProjectItems && (
                        <>
                            <QuickAddMenu setObsModal={setObsModal} setQuestionModal={setQuestionModal} setTaskModal={setTaskModal} setNotepadModal={setNotepadModal} />
                            <Menu>
                                <MenuTrigger disableButtonEnhancement>
                                    <div style={{ height: 27 }} className="d-inline-block" role="button">
                                        <Settings20Regular className={styles.settingLogo} color="white" />
                                    </div>
                                </MenuTrigger>
                                <MenuPopover>
                                    <MenuList>
                                        {isProjectAdmin && (
                                            <MenuItem onClick={() => navigate('/team/vdr')}>
                                                <div className="ps-1 pe-1">
                                                    <PeopleTeam20Regular />
                                                    <Text as="p" font="Inter" className={styles.menuLinkText}>
                                                        Teams
                                                    </Text>
                                                </div>
                                            </MenuItem>
                                        )}

                                        {(isProjectAdmin || selectedProject?.vdr?.nda) && (
                                            <MenuItem onClick={() => navigate(selectedProject?.vdr?.nda ? '/nda' : '/uploadnda')}>
                                                <div className="ps-1 pe-1">
                                                    <ReadingModeMobile20Regular />
                                                    <Text as="p" font="Inter" className={styles.menuLinkText}>
                                                        Project NDA
                                                    </Text>
                                                </div>
                                            </MenuItem>
                                        )}
                                    </MenuList>
                                </MenuPopover>
                            </Menu>
                        </>
                    )}

                    <Menu>
                        <MenuTrigger disableButtonEnhancement>
                            <Avatar role="button" name={user?.full_name} aria-label="Guest" active="active" color="beige" />
                        </MenuTrigger>
                        <MenuPopover>
                            <MenuList>
                                <MenuItem onClick={handleLogout}>Logout </MenuItem>
                            </MenuList>
                        </MenuPopover>
                    </Menu>
                </div>
            </div>
            {notepadModal && <NotepadSidebar show={notepadModal} setShow={setNotepadModal} />}
            <ObservationFormSidebar show={obsModal} setShow={setObsModal} />
            <PostInformationRequestSidebar show={questionModal} setShow={setQuestionModal} />
            <TaskFormSidebar show={taskModal} setShow={setTaskModal} />
        </>
    );
};

export const QuickAddMenu = ({ setObsModal, setQuestionModal, setTaskModal, setNotepadModal }) => {
    const location = useLocation();
    const { selectedProject } = useSelector(state => state?.projectData);
    const { user } = useSelector(state => state?.user);
    return (
        selectedProject &&
        location?.pathname !== '/project' &&
        user?.has_diligence_tools && (
            <Menu style={{ marginTop: '20px' }}>
                <MenuTrigger disableButtonEnhancement>
                    <div style={{ height: 27 }} className="d-inline-block" role="button">
                        <AddCircle20Filled color="white" />
                        <ChevronDown12Regular color="white" />
                    </div>
                </MenuTrigger>
                <MenuPopover>
                    <MenuList>
                        <MenuItem onClick={() => setObsModal(true)}>
                            <Text as="p" font="Inter" className={styles.menuLinkText}>
                                Add Observation
                            </Text>
                        </MenuItem>
                        <MenuItem onClick={() => setQuestionModal(true)}>
                            <Text as="p" font="Inter" className={styles.menuLinkText}>
                                Add Information Request
                            </Text>
                        </MenuItem>
                        <MenuItem onClick={() => setTaskModal(true)}>
                            <Text as="p" font="Inter" className={styles.menuLinkText}>
                                Add Task
                            </Text>
                        </MenuItem>
                        <MenuItem onClick={() => setNotepadModal(true)}>
                            <Text as="p" font="Inter" className={styles.menuLinkText}>
                                Notepad
                            </Text>
                        </MenuItem>
                    </MenuList>
                </MenuPopover>
            </Menu>
        )
    );
};

export default Header;
