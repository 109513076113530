/* eslint-disable react-hooks/exhaustive-deps */
import Overview from '../components/Overview';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GetProjectData } from '../redux/projectDataSlice';
import { isObject } from '../utils/commonUtils';
import Layout from '../components/Layout';
import { useNavigate } from 'react-router';

const OverviewPage = () => {
    const { selectedProject } = useSelector(state => state?.projectData);
    const dispatch = useDispatch();
    const initData = async () => {
        if (isObject(selectedProject) && Object.keys(selectedProject).length === 0) {
            dispatch(GetProjectData());
        }
    };
    useEffect(() => {
        initData();
    }, []);

    return (
        <Layout container={true}>
            <Overview></Overview>
        </Layout>
    );
};

export default OverviewPage;
