import React, { useEffect } from 'react';
import styles from './css/index.module.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Pageheader from '../../utils/headerHelment';
import '../../../node_modules/font-awesome/css/font-awesome.min.css';
import { useSelector } from 'react-redux';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { REQUEST_INVITE } from '../../apiServices/api_routes';
import axios from 'axios';
import { API_BASE_URL } from '../../apiServices/api_routes';
import { toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';
import countryArr from '../SecureAccount/CountryCode.json';
import Logo from '../../assets/longLogoBright.png';
import { SpinnerIos16Regular } from '@fluentui/react-icons';

function RequestInvite() {
    const user = useSelector(state => state?.user);
    const [rememberMe, setRememberMe] = React.useState(false);
    const navigate = useNavigate();
    const [btnLoading, setBtnLoading] = React.useState(false);
    const [formData, setFormData] = React.useState({
        full_name: '',
        email: '',
        job_title: '',
        organisation: '',
        // body: '',
        // country: '',
    });
    const [response, setResponse] = React.useState({
        message: 'Thank you for submitting your request. Your application is now under review, and our team will get back to you shortly. We appreciate your interest in Lens.',
        visible: false,
    });
    // const [isCaptchaChecked, setIsCaptchaChecked] = React.useState(false);
    const handleChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        user?.isLoggedIn && navigate('/project');
    }, []);
    const isEmail = str => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(str);
    };
    const handleSubmit = async e => {
        e.preventDefault();
        setBtnLoading(true);
        try {
            console.log(formData);
            if (isEmail(formData?.email)) {
                let res = await axios.post(API_BASE_URL + REQUEST_INVITE, formData);
                if (res?.data?.message === 'Request sent successfully') {
                    toast.success('Request successfully sent');
                    setResponse({ ...response, visible: true });
                }
                // navigate('/login');
            } else {
                toast.error('Enter Valid Email');
            }
        } catch (error) {
            toast.error('Failed');
            console.log(error);
        }
        setBtnLoading(false);
    };
    return (
        <div className={styles.body}>
            <div className={styles.dotedBg}>
                <div className={styles.formCard}>
                    <div className={styles.formHeader}>
                        <img src={Logo} width={73} alt="lens-logo" />
                    </div>
                    {response?.visible ? (
                        <div className={styles.formBody}>
                            <div className={styles.responseBody}>
                                <h5>Request submitted successfully</h5>
                                <p>{response?.message}</p>
                            </div>
                        </div>
                    ) : (
                        <form onSubmit={handleSubmit} className={styles.formBody}>
                            <h5 className={styles.formLabel}>Request Invite</h5>
                            <div>
                                <div>
                                    <p className={styles.fieldLabel}>
                                        Full Name <span style={{ color: '#B10E1C' }}>*</span>
                                    </p>
                                    <input value={formData?.full_name} required name="full_name" onChange={handleChange} className={'form-control ' + styles.inputField} />
                                </div>
                                <div>
                                    <p className={styles.fieldLabel}>
                                        Email address <span style={{ color: '#B10E1C' }}>*</span>
                                    </p>
                                    <input value={formData?.email} name="email" required type="email" onChange={handleChange} className={'form-control ' + styles.inputField} />
                                </div>
                                <div>
                                    <p className={styles.fieldLabel}>
                                        Organisation <span style={{ color: '#B10E1C' }}>*</span>
                                    </p>
                                    <input value={formData?.organisation} name="organisation" required onChange={handleChange} className={'form-control ' + styles.inputField} />
                                </div>
                                <div>
                                    <p className={styles.fieldLabel}>
                                        Role <span style={{ color: '#B10E1C' }}>*</span>
                                    </p>
                                    <input value={formData?.job_title} name="job_title" required onChange={handleChange} className={'form-control ' + styles.inputField} />
                                </div>
                                <div onClick={() => setRememberMe(!rememberMe)} role="button" className={styles.rememberMeSection}>
                                    <div>
                                        <input checked={rememberMe} type="checkbox" />
                                    </div>
                                    <label className={styles.rememberBeText}>
                                        I agree to the Lens{' '}
                                        <a href="https://www.iubenda.com/terms-and-conditions/15531073" target="_blank" rel="noreferrer">
                                            Terms
                                        </a>{' '}
                                        and to receive electronic communications from Lens. Learn about how we use and protect your data in our{' '}
                                        <a href="https://www.iubenda.com/privacy-policy/15531073/legal?ifr=true&height=650&newmarkup=yes&an=no" target="_blank" rel="noreferrer">
                                            Privacy Policy.
                                        </a>
                                    </label>
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                    <button disabled={btnLoading || !rememberMe} type="submit" className={styles.submitBtn}>
                                        Submit Request
                                        {btnLoading && <SpinnerIos16Regular className="rotateLoader ms-1" />}
                                    </button>
                                    <span role="button" onClick={() => navigate('/login')} className={styles.secondaryBtnLink}>
                                        Cancel
                                    </span>
                                </div>
                            </div>
                        </form>
                    )}
                </div>
            </div>
            <div className={styles.footer}>
                <a href="https://www.iubenda.com/terms-and-conditions/15531073" target="_blank" rel="noreferrer">
                    Terms of Use
                </a>
                <a href="https://www.iubenda.com/privacy-policy/15531073/legal?ifr=true&height=650&newmarkup=yes&an=no" target="_blank" rel="noreferrer">
                    Privacy & Cookies
                </a>
            </div>
        </div>
    );
}
export default RequestInvite;
